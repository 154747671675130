import React from 'react'
import { withTranslation } from 'react-i18next'
import { message } from 'antd'
import common from '@/asserts/js/common'
import { 
  quoteOnImg,
  quoteOffImg,
  accountOnImg,
  accountOffImg,
  tradeOnImg,
  tradeOffImg,
  newsOnImg,
  newsOffImg,
  researchOnImg,
  researchOffImg,
  switchOnImg,
  switchOffImg,
  settingOnImg,
  settingOffImg,
  logoutOnImg,
  logoutOffImg,
  alertOnImg,
  alertOffImg,
  quoteWhiteImg,
  accountWhiteImg,
  tradeWhiteImg,
  alertWhiteImg,
  researchWhiteImg,
  newsWhiteImg,
  switchWhiteImg,
  settingWhiteImg,
  logoutWhiteImg,
  campaginOffImg,
  campaginOnImg,
  campaginWhiteImg,
} from '@/asserts/imgs/nav/index'
import Setting from '@/pages/Setting/index'
import TipsModal from '@/components/tipsModal/index'
import SwitchModal from '@/components/switch/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class SlidingPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      sliderData:[
        {
          path:'/Quote',
          name:'Quote',
          imgOn:quoteOnImg,
          imgOff:quoteOffImg,
          imgWhite:quoteWhiteImg,
          id:'quote'
        },
        {
          path:'/Account',
          name:'Account',
          imgOn:accountOnImg,
          imgOff:accountOffImg,
          imgWhite:accountWhiteImg,
          id:'account'
        },
        {
          path:'/Trade',
          name:'Trade',
          imgOn:tradeOnImg,
          imgOff:tradeOffImg,
          imgWhite:tradeWhiteImg,
          id:'trade'
        },
        {
          path:'/Alerts',
          name:'Alerts',
          imgOn:alertOnImg,
          imgOff:alertOffImg,
          imgWhite:alertWhiteImg,
          id:'alerts'
        },
        {
          path:'/Research',
          name:'Research',
          imgOn:researchOnImg,
          imgOff:researchOffImg,
          imgWhite:researchWhiteImg,
          spanColor:'#828282',
          id:'research'
        },
        {
          path:'/Campagin',
          name:'Campagin',
          imgOn:campaginOnImg,
          imgOff:campaginOffImg,
          imgWhite:campaginWhiteImg,
          spanColor:'#828282',
          id:'Campagin'
        },
        {
          path:'/News',
          name:'News',
          imgOn:newsOnImg,
          imgOff:newsOffImg,
          imgWhite:newsWhiteImg,
          id:'news'
        },
        {
          path:'/Switch',
          name:'Switch',
          imgOn:switchOnImg,
          imgOff:switchOffImg,
          imgWhite:switchWhiteImg,
          spanColor:'#828282',
          id:'switch'
        },
        {
          path:'/Settings',
          name:'Settings',
          imgOn:settingOnImg,
          imgOff:settingOffImg,
          imgWhite:settingWhiteImg,
          id:'settings'
        },
        {
          path:'/Logout',
          name:'Logout',
          imgOn:logoutOnImg,
          imgOff:logoutOffImg,
          imgWhite:logoutWhiteImg,
          id:'logout'
        }
      ],
      isPanelShow:false,
      indexData1245:[1,2,3,5,6],
      loginName:'',
      isSettingShow:false,
      isLogOutShow:false,
      chgNavId:'quote',
      idData:['trade','research','settings','logout','alerts','switch'],
      isSwitchShow:false,
      campaignVisible:false,
    }
  }
  //slider--切换不同页面
  sliderClick(data){
    const {idData}=this.state
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let id=data['id']
    if(id=='research'){
      this.setState({
        isPanelShow:false
      })
      this.getResearchPageFun()
      this.props.callbackChgNavIndex(navIdAPP)
      sessionStorage.setItem('navId',navIdAPP)
      return
    }
    sessionStorage.setItem('navId',id)
    this.props.callbackChgNavIndex(id)
    this.setState({
      chgNavId:id,
      isPanelShow:false
    })
    if(id=='settings'){
      this.setState({
        isSettingShow:true
      })
      this.props.callbackChgNavIndex(navIdAPP)
    }
    if(id=='logout'){
      this.setState({
        isLogOutShow:true
      })
      this.props.callbackChgNavIndex(navIdAPP)
    }
    if(id=='switch'){
      this.setState({
        isSwitchShow:true
      })
      this.props.callbackChgNavIndex(navIdAPP)
    }
    if(idData.includes(id)) return
    this.props.callbackPathnameApp(data['path'])// 获取路由跳转--app.js
    sessionStorage.setItem('navIdAPP',id)
  }
  //获取数据--url
  getResearchPageFun(){
    let token=sessionStorage.getItem('token')
    fetch(common.getHttpZs+'getResearchPage',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        //ios终端
        if(common.isiOS){
          window.location.href=res['url']
          return
        }
        //android终端/pc端
        window.open(res['url'],'_blank')
        return
      }
      //获取失败
      message.warning({
        content: res['message']
      })
    })
  }
  closePanelClick(){
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    this.setState({
      isPanelShow:false,
      chgNavId:navIdAPP
    })
    sessionStorage.setItem('navId',navIdAPP) 
    this.props.callbackChgNavIndex(navIdAPP, true)
  }
  onloadDataFun(){
    const {locationName}=this.props
    const {sliderData}=this.state
    let loginName=sessionStorage.getItem('loginName')
    let navIdAPP=sessionStorage.getItem('navIdAPP')

    for(let i in sliderData){
      sliderData[i]['path']='/'+locationName[1]+sliderData[i]['path']
    }
    this.setState({
      sliderData:sliderData,
      loginName:loginName
    })
    if(navIdAPP){
      let items = sliderData.find(item => item['id']==navIdAPP)
      this.setState({
        sliderData:sliderData,
        chgNavId:items['id']
      })
      return
    }
    sessionStorage.setItem('navIdAPP','quote') 
  }
  // data 为空不显示活动图标
  getApiData(){
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'marketing-campaign',{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        let data = res['data'];
        const value = (data&&JSON.stringify(data)!=='{}');
        this.setState({
          campaignVisible: value,
        });
        sessionStorage.setItem('campaignVisible',value);
      }
    })
  }
  changeCampaignVisible(){
    const value=sessionStorage.getItem('campaignVisible');
    const newValue = value ? JSON.parse(value): false;
    this.setState({
      campaignVisible: newValue,
    });
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isPC,bodyW,isSlidingPanelShow}=this.props
    if(bodyW!==newProps['bodyW']){
      this.setState({
        isPanelShow:false
      });
      !isPC && this.changeCampaignVisible();
    }
    if(isSlidingPanelShow!==newProps['isSlidingPanelShow']){
      let navIdAPP=sessionStorage.getItem('navIdAPP')
      this.setState({
        isPanelShow:true,
        chgNavId:navIdAPP
      })
    }
  }
  //初始化
  componentDidMount(){
    const {isPC} = this.props;
    const isLoginOnload=sessionStorage.getItem('isLoginOnload');
    if(!isPC && isLoginOnload==='Y'){
      this.getApiData();
    }else{
      !isPC && this.changeCampaignVisible();
    }
    this.onloadDataFun();
  }
  render(){
    const {t,ChgThemeData,bodyW,mobilebodyW,ChgLangData,isMobile}=this.props
    const {chgNavId,sliderData,isPanelShow,loginName,isSettingShow,isLogOutShow,isSwitchShow,campaignVisible}=this.state
    return(
      <div>  
        <div className={styles.slidingPanel_bg} style={{display:isPanelShow?'':'none'}} onClick={this.closePanelClick.bind(this)}></div>
        <div 
          className={[
            styles.slidingPanel_con,
            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black,
            isPanelShow?styles.ease_in:styles.ease_out
          ].join(' ')}
          style={{
            width:bodyW>mobilebodyW?414:207
          }}
        >
          <div className={styles.line}></div>
          {sliderData.map((item,index) => {
            let onClick = (item['id']==='Campagin' && !campaignVisible) ? undefined : this.sliderClick.bind(this,item)
            return  <div 
                      key={index}
                      className={[
                        APPstyles.flex_row,
                        styles.list,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        isMobile?APPstyles.fz20:''
                      ].join(' ')} 
                      style={{
                        display:loginName=='notFound'&&index==sliderData.length-1?'none':(item['id']==='Campagin'&&!campaignVisible)?'none':'',
                      }}
                      onClick={onClick}
                    >
                      <img src={chgNavId==item['id']?item['imgOn']:isMobile&&ChgThemeData=='Dark'?item['imgWhite']:item['imgOff']} className={APPstyles.img24} alt={t(item['name'])} />
                      <span style={{color:chgNavId==item['id']?'#008FC7':isMobile?ChgThemeData=='Dark'?'#E0E0E0':'#333333':'#828282'}}>{t(item['name'])}</span>
                    </div>
          })}
        </div> 
        {isSettingShow&&<Setting 
          {...this.props}
          callbackChgNavIndex={(data, isClose)=>{
            this.props.callbackChgNavIndex(data, isClose)
          }}
          callbackSetting={(data)=>{
            this.props.callbackSetting(data)
          }}
          cbCloseSetting={()=>{
            //关闭
            this.setState({
              isSettingShow:false
            })
          }}
        />}
        {isLogOutShow&&<TipsModal
          {...this.props}
          tag={'logout'}
          cancelText={ChgLangData=='zh-CN'?t('Cancel1'):t('Cancel')}
          sureText={ChgLangData=='zh-CN'?t('OK1'):t('OK')} 
          contentText={'Are you sure you want to logout?'}
          callbackPathnameApp={(data)=>{
            this.props.callbackPathnameApp(data)
          }}
          callbackChgNavIndex={(data,isClose)=>{
            this.props.callbackChgNavIndex(data, isClose)
          }}
          cbCloseLogOout={()=>{
            //关闭
            this.setState({
              isLogOutShow:false
            })
          }}
        />}
        {isSwitchShow&&<SwitchModal
          {...this.props}
          switchTag='slider'
          cancelText='Cancel'
          sureText='OK'
          switchTitle='Switch Account'
          switchName='Account1'
          cbCloseModal={()=>{
            this.setState({
              isSwitchShow:false
            })
          }}
          cbSwitchFun={()=>{
            // 切换账号更新list列表数据---navIdAPP=='quote'
            this.props.cbSwitchFun()
          }}
          cbCloseRightPageFun={()=>{
            // 切换账号成功则关闭trade页面---navIdAPP=='quote'
            this.props.cbCloseRightPageFun()
          }}
        />}
      </div> 
  )}
}
export default withTranslation()(SlidingPanel)
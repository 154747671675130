import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const POSC = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [shortPeriod, setShortPeriod] = useState(9);
  const [longPeriod, setLongPeriod] = useState(14);
  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(shortPeriod + "," + longPeriod);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.POSC_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const POSC = localChartSetting.option;

        setValue(POSC.shortPeriod + "," + POSC.longPeriod);
        props.onTAValueChange({
          ta: "POSC",
          name: `${t("chart5.TA.POSC")} (POSC)`,
          option: {
            shortPeriod: POSC.shortPeriod,
            longPeriod: POSC.longPeriod,
            color: POSC.color,
            thickness: POSC.thickness,
            style: POSC.style,
            showTracker: POSC.showTracker,
            height: POSC.height,
          },
          value: localChartSetting.value,
        });

      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const POSC = props.localChartSetting.POSC_Option.option;

    setShortPeriod(parseInt(POSC.shortPeriod) || shortPeriod);
    setLongPeriod(parseInt(POSC.longPeriod) || longPeriod);
    setColor(POSC.color);
    setThickness(parseInt(POSC.thickness) || thickness);
    setStyle(POSC.style);
    setShowTracker(POSC.showTracker);
    setHeight(POSC.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    //console.log(shortPeriod)
    if (longPeriod > shortPeriod) {
      setValue(shortPeriod + "," + longPeriod);
      props.onTAValueChange({
        ta: "POSC",
        name: `${t("chart5.TA.POSC")} (POSC)`,
        option: {
          shortPeriod: shortPeriod,
          longPeriod: longPeriod,
          color: color,
          thickness: thickness,
          style: style,
          showTracker: showTracker,
          height: height,
        },
        value: value,
      });
    }
  }, [shortPeriod, longPeriod, color, thickness, style, showTracker, height]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const POSC = props.localChartSetting.POSC_Option.option;

    setShortPeriod(parseInt(POSC.shortPeriod) || shortPeriod);
    setLongPeriod(parseInt(POSC.longPeriod) || longPeriod);
    setColor(POSC.color);
    setThickness(parseInt(POSC.thickness) || thickness);
    setStyle(POSC.style);
    setShowTracker(POSC.showTracker);
    setHeight(POSC.height);
  }, [props.isReset]);

  const resetALL = () => {
    setShortPeriod(9);
    setLongPeriod(14);
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "short") setShortPeriod(parseInt(e.target.value));
    else setLongPeriod(parseInt(e.target.value));
  };

  const onThicknessChange = (e) => {
    setThickness(e.target.value);
  };

  const onStyleChange = (e) => {
    setStyle(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const onShowTracker = () => {
    if (showTracker) setShowTracker(false);
    else setShowTracker(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "POSC":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "POSC", t("chart5.setting"));
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("POSC")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.POSC")} (POSC) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("POSC")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-POSC">
          <div className="flexbox">
            <div className="taparamlbl">
              {t("chart5.short")} {t("chart5.period")}
            </div>

            <div className="inputbox taparam">
              <input
                id="msi-ta-POSC-p0-period0"
                type="number"
                step="1"
                min="1"
                value={shortPeriod}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "short")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-POSC-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-POSC-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e)}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e)}>
              <select id="msi-ta-POSC-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-POSC-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={onShowTracker}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-POSC">
          <div className="flexbox">
            <div className="taparamlbl">
              {t("chart5.long")} {t("chart5.period")}
            </div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-POSC-p1-period0"
                type="number"
                step="1"
                min="1"
                value={longPeriod}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "long")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-POSC">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div
              className="selectbox taparam"
              defaultValue="1"
              onChange={(e) => onHeightChange(e)}
            >
              <select id="msi-ta-POSC-ps-h" value={height}>
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { Switch, Button } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./Notifications.less";
import APPstyles from "@/App.less";

const Notifications = (props) => {
  const { t } = useTranslation();
  const {
    store,
    bodyW,
    isMobile,
    minbodyW,
    mobilebodyW,
    ChgThemeData,
    ChgLangData,
    tradeData,
    isDarkMode,
    alertsStore,
  } = props;

  const [isHideAlert, setIsHideAlert] = useState(!alertsStore.isNotification);
  const [isNoticeSound, setIsNoticeSound] = useState(alertsStore.isSound);

  const onClickClose = () => {
    const { bodyW, mobilebodyW, sliderIndex, currentIndex } =
      props;
    let navIdAPP=sessionStorage.getItem('navIdAPP')

    if (bodyW <= mobilebodyW && (sliderIndex === 'quote' || sliderIndex === 'alerts')) {
      props.callbackLeftPageShow && props.callbackLeftPageShow("list");
      props.cbDetailPageShow && props.cbDetailPageShow(false);
      // props.callbackChgNavIndex && props.callbackChgNavIndex(navIdAPP, true);
    }
    //quote
    if (sliderIndex === 'quote') {
      props.callbackAlertsShow && props.callbackAlertsShow("close", "");
      return;
    }
    // trade
    if (sliderIndex === 'trade' || sliderIndex === 'alerts') {
      props.callbackChgNavIndex(navIdAPP, true);
      if (currentIndex === 3) {
        props.callbackAlertsShow && props.callbackAlertsShow("close", "");
      }
    }
    // trade/account
    if (sliderIndex === 'trade' || sliderIndex === 'account') {
      let data = [false, false, false, false];
      props.calkbackBtnStatus(data);
      // props.callbackChgNavIndex && props.callbackChgNavIndex(navIdAPP, true);
    }
  };

  const onClickSave = () => {
    alertsStore.setIsNotification(!isHideAlert);
    alertsStore.setIsSound(isNoticeSound);
    onClickClose();
  };

  return (
    <>
      <div className={styles.notification}>
        <div
          className={[
            styles.min48_con,
            styles.msg,
            APPstyles.flex_between,
            ChgThemeData === "Light"
              ? APPstyles.borderBottom_light
              : APPstyles.borderBottom_dark,
          ].join(" ")}
        >
          <span className={isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''}>{t("alerts.hideAlert")}</span>
          <Switch
            checked={isHideAlert}
            onChange={(disabled) => {
              setIsHideAlert(disabled);
            }}
          />
        </div>
        <div
          className={[
            styles.min48_con,
            styles.msg,
            APPstyles.flex_between,
            ChgThemeData === "Light"
              ? APPstyles.borderBottom_light
              : APPstyles.borderBottom_dark,
          ].join(" ")}
        >
          <span className={isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''}>{t("alerts.noticeSound")}</span>
          <Switch
            checked={isNoticeSound}
            onChange={(disabled) => {
              setIsNoticeSound(disabled);
            }}
          />
        </div>
        <div
          className={[
            styles.line,
            ChgThemeData === "Light"
              ? APPstyles.bg_list_light
              : APPstyles.bg_dark,
          ].join(" ")}
        ></div>
      </div>
      <div
        className={[
          APPstyles.flex_row,
          APPstyles.place_button,
          APPstyles.place_button_alert,
          styles.notification_botton_button_bar,
          ChgThemeData === "Light"
            ? APPstyles.bg_light
            : APPstyles.bg_page_black,
          ChgThemeData == "Light"
            ? APPstyles.borderTop_light
            : APPstyles.borderTop_dark,
        ].join(" ")}
      >
        <Button
          type="default"
          className={APPstyles.btn_gray}
          onClick={onClickClose}
        >
          {t("alerts.cancel")}
        </Button>
        <Button
          type="default"
          style={{ backgroundColor: "#008FC7" }}
          className={APPstyles.btn_blue}
          onClick={onClickSave}
        >
          {t("alerts.save")}
        </Button>
      </div>
    </>
  );
};

export default Notifications;

import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../../../../asserts/js/common'
HighchartsMore(Hightcharts)

class AnalystLine extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }
  onLoadData(){
    const {t,id,ChgThemeData,allChartData,bodyW,mobilebodyW,data,isMobile}=this.props
    let AnalystData=allChartData['AnalystPriceTarget']
    let targetPrice =AnalystData&&AnalystData['mean']
    let currentPrice = data&&data['TRDPRC_1']?data['TRDPRC_1']:0
    let high = AnalystData&&AnalystData['high']
    let low = AnalystData&&AnalystData['low']

    Hightcharts.chart(id,{
      chart: {
        type: "line",
        // width: isMobile? Number(bodyW) - 160 : undefined,
        height: 220,
        plotBorderColor: "rgba(0,0,0,0)",
        plotBorderWidth: 0,
        marginTop: 50,
        backgroundColor: "rgba(0,0,0,0)"
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false
      },
      tooltip:{
        borderWidth:0,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        style:{
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '14px'
        },
        headerFormat:'',
        valueDecimals: 2,
        pointFormatter(){
          let name=data&&data['symbol']?data['symbol']:'Stock'
          let value=this['y']==0?this['y']:common.transThousands(common.keepNum(this['y'],2))
          let str='<div><label style="fontSize:12px">'+this['series']['name']+'</label><br/><span style="color:'+this['color']+'">●</span><i style="opacity:0;color:none">.</i><span>'+name+' : '+value+'</span></div>'
          return str
        }
      },
      colors: ["#F5475B", "#008FC7", "#39C46E", "#008FC7"],
      xAxis: {
        lineColor: ChgThemeData === "Light" ? "#E0E0E0" : "#373737",
        lineWidth: 1,
        // categories: ["前日終値", "目標株価"],
        maxPadding: 0.4,
        tickColor: "rgba(0,0,0,0)",
        labels: { enabled: false },
      },
      yAxis: {
        lineColor: "#828282",
        lineWidth: 1,
        gridLineColor: ChgThemeData === "Light" ? "#E0E0E0" : "#373737",
        // alternateGridColor: "#F1F1F1",
        title: {
          text: "",
        },
        labels: {
          style: {
            color: '#828282',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '11px',
          }
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          type: "arearange",
          name: "",
          color: "#008FC7",
          fillOpacity: 0.3,
          data: [
            [Number(currentPrice), Number(currentPrice)],
            [Number(low), Number(high)],
          ],
          // showInLegend: false,
          allowPointSelect: true,
        },
        {
          type: "line",
          name: t("High"),
          data: [Number(currentPrice), Number(high)],
          dataLabels: { x: 0, y: -12 },
          showInLegend: true,
        },
        {
          type: "line",
          name: t("Avg"),
          data: [Number(currentPrice), Number(targetPrice)],
          dataLabels: { x: 0, y: 1 },
          showInLegend: true,
        },
        {
          type: "line",
          name: t("Low"),
          data: [Number(currentPrice), Number(low)],
          dataLabels: { x: 0, y: 13 },
          showInLegend: true,
        },
        {
          type: "line",
          name: "",
          data: [Number(currentPrice)],
          showInLegend: true,
        },
      ],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: "left",
            verticalAlign: "middle",
            padding: bodyW<=350?0:10,
            allowOverlap: true,
            overflow: "none",
            crop: false,
            defer:false,
            shape: 'callout',
            style: { 
              textOutline: "none", 
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontSize: bodyW<=370?'13px':'14px',
              color:ChgThemeData === "Light" ? "#828282" :'#E0E0E0'
            },
            formatter: function () {
              var point = this.point,
                series = this.series;
              if (
                series.data.length === point.index + 1 &&
                this.series.name !== "Series 4"
              ) {
                if (this.series.name === "")
                  return (
                    '<tspan style="color:' +
                    this.point.color +
                    '">' +
                    this.series.name +
                    "</tspan>"
                  );
                if (Number(high) === Number(low))
                  return this.series.name === t("avg")
                    ? `${t("high")}/${t("avg")}/${t("low")}`
                    : "";
                else {
                  if (
                    Number(high) === Number(targetPrice) &&
                    this.series.name !== t("low")
                  )
                    return this.series.name === t("avg")
                      ? `${t("high")}/${t("avg")}`
                      : "";
                  else if (
                    Number(low) === Number(targetPrice) &&
                    this.series.name !== t("high")
                  )
                    return this.series.name === t("avg")
                      ? `${t("avg")}/${t("low")}`
                      : "";
                  else {
                    let getY=common.keepNum(this.series.data[1]['y'],2)
                    let getY_len=Number(String(getY).split('.')[0].length)+2
                    return (
                      `<tspan style="color:${
                        ChgThemeData === "Light" ? "333333" : "#FFFFFF"
                      };font-weight:400;font-size:${getY_len>5?12:getY_len>7?11:bodyW<=370?13:14}px` +
                      '">' +
                      this.series.name +
                      ": " +
                      common.transThousands(common.keepNum(this.series.data[1]['y'],2))
                       +
                      "</tspan>"
                    );
                  }
                }
              } else {
                return "";
              }
            },
          },
        },
        line: {
          marker: { symbol: "circle" },
          dashStyle: "ShortDot",
        },
        arearange: { marker: { enabled: false } },
      },
      credits: {
        enabled: false,
      }
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,isRightPageShow,isToggleOn,TargetPricePremium}=this.props
    if(
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      isRightPageShow!==newProps['isRightPageShow']||
      isToggleOn!==newProps['isToggleOn']||
      TargetPricePremium!==newProps['TargetPricePremium']
    ){
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(AnalystLine)
import React from 'react';
import { Modal, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { closeImg, closeOnImg, closeOnLightImg } from '@/asserts/imgs/common/index';
import APPstyles from '@/App.less';
import styles from '../note/index.less';
import myStyles from './index.less';

class ExplainIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgCloseImg: closeImg,
    }
  }
  onMouseOver = () => {
    const {ChgThemeData} = this.props;
    let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg;
    this.updateImage(imgurl);
  };
  onMouseOut = () => {
    this.updateImage(closeImg);
  };
  updateImage(imgurl) {
    const {isPC} = this.props;
    if(!isPC) return;
    this.setState({
      chgCloseImg: imgurl,
    });
  };
  render(){
    const {t,isMobile,ChgThemeData,visible}=this.props;
    const {chgCloseImg}=this.state;
    return (
      <Modal 
        centered={true}
        closeIcon={null}
        footer={null}
        maskClosable={false}
        open={visible}
        className={[ChgThemeData==='Light'&&styles.modalLight,isMobile&&styles.modalMobile,myStyles.modalExplain].join(' ')}
      >
        <div className={[styles.noteTitle,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span>說明</span>
          <img 
            src={chgCloseImg} 
            className={APPstyles.img24} 
            onClick={()=>this.props.onCloseModal()}
            onMouseOver={() => this.onMouseOver()}
            onMouseOut={() => this.onMouseOut()}
          />
        </div>
        <div className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')} style={{padding:16}}>
          <div className={[APPstyles.flex_row,myStyles.content].join(' ')}>
            <span>1.</span>
            <span>下單可用金額=銀行可用餘額＋在途款＋今日賣出金額- 在途圈存金額</span>
          </div>
          <div className={[APPstyles.flex_row,myStyles.content].join(' ')}>
            <span>2.</span>
            <span>今日圈存金額=在途圈存金額＋銀行圈存金額如有網路交易問題，歡迎電洽本公司複委託交易室。電話：（02）8771-7416。 </span>
          </div>
          <div className={[APPstyles.flex_row,myStyles.content].join(' ')}>
            <span>3.</span>
            <span>在途款抵用原則：同國家同幣別方可抵用 例如：美股美金計價股票賣出在途款僅能使用於美股美金計價股票買進，不能使用於英股美金計價股票買進。</span>
          </div>
        </div><div className={[myStyles.btnCon,APPstyles.flex_row].join(' ')}>
          <Button 
            className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
            onClick={()=>this.props.onCloseModal()}
          >
            {t('確認')}
          </Button>
        </div>
      </Modal>
  )}
}
export default withTranslation()(ExplainIndex);
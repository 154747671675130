import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const TP = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(5);
  const [color, setColor] = useState(
    props.ChgThemeData === "Light" ? "333333" : "FFFFFF"
  );
  const [value, setValue] = useState(period);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.TP_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const TP = localChartSetting.option;

        setValue(TP.period);
        props.onOverlayValueChange({
          ta: "TP",
          name: `${t("chart5.TA.TP")} (TP)`,
          option: { period: TP.period, color: TP.color },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const TP = props.localChartSetting.TP_Option.option;
    setPeriod(parseInt(TP.period) || period);
    setColor(TP.color);
  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(period);
    props.onOverlayValueChange({
      ta: "TP",
      name: `${t("chart5.TA.TP")} (TP)`,
      option: { period: period, color: color },
      value: value,
    });
  }, [period, color]);

  // fubon requirement: text color change when theme color change
  useEffect(() => {
    if (props.ChgThemeData === "Light" && color === "FFFFFF") {
      setColor("333333");
    } else if (props.ChgThemeData === "Dark" && color === "333333") {
      setColor("FFFFFF");
    }
  }, [props.ChgThemeData]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const TP = props.localChartSetting.TP_Option.option;
    setPeriod(parseInt(TP.period) || period);
    setColor(TP.color);
  }, [props.isReset]);

  const resetALL = () => {
    setPeriod(5);
    setColor(props.ChgThemeData === "Light" ? "333333" : "FFFFFF");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  const onPeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "TP":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "TP", "Text Color");
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("TP")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.TP")} (TP) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("TP")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="floatmsibox">
          <div className="menusubitem " id="msi-ta-TP">
            <div className="taparamlbl">{t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-TP-p0-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e)}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem" id="msi-ta-TP">
            <div className="taparamlbl">{t("chart5.textColor")}</div>
            <button
              id="msi-ta-TP-c-color"
              className="taparam colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { withTranslation } from "react-i18next"
import { Spin } from 'antd'
import common from '@/asserts/js/common'
import { 
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg
} from '@/asserts/imgs/common/index'
import PaginationCon from '../Pagination/index'
import TipsCon from '../OrderHistory/Tips/index'
import Mobile from '../Mobile'
import HeaderCon from '../OrderHistory/Header/index'
import APPstyles from '@/App.less'
import styles from '@/pages/Account/index.less'

let chgPage=1

class DealHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history:[],
      isLoading:true,
      country_code:'US',
      page:1,
      stock_id:null,
      fromDate:'',
      toDate:'',
      sizeTotal:0,
      recperpage:20,
      loadMore:false,
      noMore:false,
      chgSort:'time_desc',
      onLoad:true,
      minW1700:1700,
      isSortShow:false
    }
  }
  //排序
  sortClick(name,tag,sortName){
    const {sortData,isTablet,ChgThemeData}=this.props
    const {country_code,stock_id,page,history,fromDate,toDate,onLoad,chgSort,isLoading,isSortShow}=this.state
    if(history.length<=0||(!onLoad&&chgSort==tag)||isLoading) return
    this.setState({
      isLoading: true,
      chgSort:tag,
      onLoad:false,
      loadMore:false,
      noMore:false,
      isSortShow:!isSortShow
    })
    let newData=common.getSortDataFun(sortData,ChgThemeData,name,sortName)
    chgPage=1
    let chgPn=isTablet?1:page
    this.getTableData(country_code,stock_id,tag,chgPn,fromDate,toDate,[])
    this.props.cbChgSortDataFun(newData)
  }
  //获取数据API
  getTableData(country_code,stock_id,sort,page,fromDate,toDate,listsData) {
    const {recperpage}=this.state
    fetch(common.getHttpZs+'getDealHistory', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_code: country_code,
        stock_id: stock_id,
        recperpage: recperpage,
        page: page,
        sort:sort, 
        from_date: Number(fromDate),
        to_date: Number(toDate),
        token: sessionStorage.getItem("token")
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if(res['status']==0){
        if(JSON.stringify(res['data']) !== '{}'){
          let datalist=res['data']['datalist']
          let size_total=res['data']['size_total']
          let getRicData=[]
          if(datalist.length){
            for(let i in datalist){
              getRicData.push(datalist[i]['stock_id'])
            }
            if(getRicData.length==datalist.length){
              getRicData= [...new Set(getRicData)]//去重
              this.getsimplequoteFun(getRicData,datalist,listsData,size_total,page)
            }
            return
          }
          if(listsData.length){
            this.setState({
              isLoading:false,
              loadMore:false,
              noMore:true
            })
          }else{
            this.noDataFun()
          }
          return
        }
        this.noDataFun()
        return
      }
      this.noDataFun()
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(ric,datalist,listsData,size_total,page){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      //获取数据成功
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        if(JSON.stringify(resData)!=='{}'){
          for(let i in datalist){
            for(let j in ric){
              if(datalist[i]['stock_id']==ric[j]){
                let data=resData[ric[j]]
                datalist[i]['stock_code']=data['symbol']
                datalist[i]['stock_name']=data['name']
              }
            }
          }
          this.commonDataFun(datalist,listsData,page,size_total)
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err)
      this.commonDataFun(datalist,listsData,page,size_total)
    })
  }
  commonDataFun(datalist,listsData,page,size_total){
    const {isTablet}=this.props
    let pn1=datalist.length?datalist.length>=10?false:true:false
    let pnother=listsData.length?datalist.length?false:true:true
    let noMoreData=page==1?pn1:pnother

    this.setState({
      history: isTablet?listsData.concat(datalist):datalist,
      sizeTotal:size_total,
      isLoading:false,
      loadMore:false,
      noMore:noMoreData
    })
  }
  noDataFun(){
    this.setState({
      history: [],
      sizeTotal:0,
      isLoading:false,
      loadMore:false,
      noMore:false
    })
  }
  //回传数据--input
  callbackInputData(data){
    const {fromDate,toDate,chgSort}=this.state
    this.setState({
      isLoading:true,
      stock_id:data[0],
      country_code:data[1],
      page:1
    })
    chgPage=1
    this.getTableData(data[1],data[0],chgSort,1,fromDate,toDate,[])
  }
  //回传数据--日期
  callbackDateData(data){
    const {country_code,stock_id,chgSort}=this.state
    this.setState({
      isLoading:true,
      fromDate:data[0],
      toDate:data[1],
      page:1
    })
    chgPage=1
    this.getTableData(country_code,stock_id,chgSort,1,data[0],data[1],[])
  }
  //回传数据--分页
  callbackPagekData(page){
    const {country_code,stock_id,fromDate,toDate,chgSort}=this.state
    this.setState({
      isLoading:true,
      page:page
    })
    this.getTableData(country_code,stock_id,chgSort,page,fromDate,toDate,[])
  }
  //滚动加载更多--isTablet/mobile
  cbChgDataFun(){
    const {loadMore,noMore,isLoading,country_code,stock_id,fromDate,toDate,history,chgSort}=this.state
    if(loadMore||noMore||isLoading) return
    chgPage++
    this.setState({
      page: chgPage,
      loadMore:true,
      noMore:false
    })
    this.getTableData(country_code,stock_id,chgSort,chgPage,fromDate,toDate,history)
  }
  //初始化数据
  componentDidMount() {
    const {from_date,to_date}=this.props
    const {country_code,stock_id,page,chgSort}=this.state
    chgPage=1
    this.setState({
      fromDate: from_date,
      toDate: to_date,
      page: 1,
      loadMore:false,
      noMore:false
    })
    this.getTableData(country_code,stock_id,chgSort,page,from_date,to_date,[])
    
  }
  render() {
    const {t,ChgThemeData,ChgLangData,isPC,isTablet,sortData,bodyW,isSearchPageShow} =this.props
    const {history,isLoading,sizeTotal,recperpage,page,loadMore,noMore,minW1700,isSortShow} = this.state
    return (
      <div
        className={[
          styles.account_layout,
          isPC?APPstyles.flex_column:''
        ].join(' ')}
        style={{
          position:'relative'
        }}
      >
        <div
          className={[
            isPC?APPstyles.flex1_auto:'',
            isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
            isPC&&isSearchPageShow?APPstyles.hPer_overflowH:'',
            isPC&&isSearchPageShow?'':isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'',
            isPC?'':APPstyles.hPer_overflowH
          ].join(' ')}
        >
          {/* Header*/}
          <HeaderCon
            {...this.props}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            history={history}
            page={page}
            isShow={false}
            callbackDateData={this.callbackDateData.bind(this)}
            callbackInputData={this.callbackInputData.bind(this)}
            callbackPagekData={this.callbackPagekData.bind(this)}
          />
          {/* content */}
          {
            isPC?
            <div 
              className={[
                isPC&&isSearchPageShow?APPstyles.flex1_auto:'',
                isPC&&isSearchPageShow?isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'':''
              ].join(' ')}
            >
              {/* table--header */}
              <div
                className={[
                  APPstyles.flex_row,
                  styles.account_header,
                  styles.header_pc,
                  styles.account_common,
                  styles.account_common_todayOrder,
                  ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                  ChgLangData=='zh-CN'?styles.account_common_zhcn:'',
                  ChgLangData=='zh-CN'?APPstyles.fz12_zhcn:''
                ].join(" ")}
              >
                <div style={{width: "12%"}}>
                  {t("Date/Time")}
                  <div 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <div 
                      className={APPstyles.img_dropdown_up} 
                      onClick={()=>{this.sortClick('time','time_asc','asc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                        sortData[0]['upImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[0]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                        sortData[0]['upImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }}
                    >
                      <img src={sortData[0]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('time','time_desc','desc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                        sortData[0]['downImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[0]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                        sortData[0]['downImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }}
                    >
                      <img src={sortData[0]['downImg']} />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width:'7%'
                  }}
                >
                  {t("Order Time")}
                </div>
                <div style={{width:bodyW<=minW1700?'8%':'6%'}}>
                  {t("Direction")}
                  <div 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <div 
                      className={APPstyles.img_dropdown_up} 
                      onClick={()=>{this.sortClick('direction','direction_asc','asc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                        sortData[1]['upImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[1]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                        sortData[1]['upImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }}
                    >
                      <img src={sortData[1]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('direction','direction_desc','desc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                        sortData[1]['downImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[1]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                        sortData[1]['downImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }}
                    >
                      <img src={sortData[1]['downImg']} />
                    </div>
                  </div>
                </div>
                <div style={{width:'5%'}}>{t("Country")}</div>
                <div>{t("Code")}</div>
                <div
                  className={styles.account_name}
                  style={{
                    width:bodyW<=minW1700?'30%':'37%',
                    justifyContent: "flex-start"
                  }}
                >
                  {t(ChgLangData == "zh-CN" ? "name" : "Name")}
                </div>
                <div style={{width:'7%'}}>{t("QTY Filled")}</div>
                <div style={{width:bodyW<=minW1700?'8%':'7%'}}>
                  <div className={styles.account_header_totalcost}>
                    <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                      {t("Transacted")}
                    </span>
                    <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                      {t("Price")}
                    </span>
                    <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                      {t("Transacted Price")}
                    </span>
                  </div>
                </div>
                <div style={{justifyContent: "flex-end"}}>
                  {t("Total Amount")}
                </div>
                <div style={{ paddingRight: 0,width:bodyW<=minW1700?'9%':'5%',textAlign:'right',whiteSpace:'nowrap'}}>{t("Order Type")}</div>
              </div>
              {/* table--body */}
              <div 
                className={history.length>0?'':APPstyles.minH300}
                style={{
                  position:!history.length&&!isLoading?'relative':'unset'
                }}
              >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                {
                  history.length>0?

                  history.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={[
                            APPstyles.flex_row,
                            styles.account_body,
                            styles.account_common,
                            styles.account_common_todayOrder,
                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                            ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                            ChgThemeData=='Light'?styles.account_body_light:'',
                            ChgLangData=='zh-CN'?styles.account_common_zhcn:''
                          ].join(" ")}
                          style={{cursor:"default"}}
                        >
                          <div
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{
                              width:"12%",
                              flexWrap:'wrap'
                            }}
                          >
                            <span>{common.changeOrderDate(item['deal_datetime'])}</span>&nbsp;
                            <span> {common.changeOrderTime(item['deal_datetime'])}</span>
                          </div>
                          <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{ width:'7%'}}>{common.changeOrderTime(item['order_datetime'])}</div>
                          <div 
                            style={{width:bodyW<=minW1700?'8%':"6%"}} 
                            className={item['direction']=='B'?APPstyles.color_red:APPstyles.color_green}
                          >
                            {t(common.charAtToUpperCase(item['direction']=='B'?'Buy':'Sell'))}
                          </div>
                          <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:'5%'}}>
                            {t(common.valToUpperCase(item['country']))}
                          </div>
                          <div className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,APPstyles.fw700].join(' ')}>
                            {common.valToUpperCase(item['stock_code'])}
                          </div>
                          <div
                            className={[styles.account_name,ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur].join(' ')}
                            style={{
                              width:bodyW<=minW1700?'31%':'37%',
                              justifyContent: "flex-start"
                            }}
                          >
                            {common.valToUpperCase(item['stock_name'])}
                          </div>
                          <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:'7%'}}>
                            {common.transThousands(common.keepNum(item['qty_filled'],0))}
                          </div>
                          <div
                            style={{width:bodyW<=minW1700?'8%':'7%'}}
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                          >
                            {common.transThousands(common.filterErro(item['transacted_price']))}
                          </div>
                          <div
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{justifyContent: "flex-end"}}
                          >
                            {common.transThousands(common.keepNum(item['total_amount'],2))}
                          </div>
                          <div 
                            style={{ paddingRight: 0,width:bodyW<=minW1700?'9%':'5%',textAlign:'right'}} 
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                          >
                            {t(
                              item['order_type']==0?
                                'Limit Order'
                              :item['order_type']==50?
                                'Stop Limit'
                              :
                                ''
                            )}
                          </div>
                        </div>
                      );
                    })
                  :
                  <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
                }
              </div>
              {/* 分页 */}
              <div style={{display:isPC?isSearchPageShow?'':'none':'none'}}>
              <PaginationCon
                {...this.props}
                sizeTotal={sizeTotal}
                recperpage={recperpage}
                page={page}
                isShow={true}
                history={history}
                callbackPagekData={this.callbackPagekData.bind(this)}
              />
              </div>
            </div>
            :
            // 移动端
            <Mobile 
              {...this.props}
              history={history}
              changeName={ChgLangData == "zh-CN" ? "name" : "Name"}
              isLoading={isLoading}
              loadMore={loadMore}
              noMore={noMore}
              sizeTotal={sizeTotal}
              recperpage={recperpage}
              page={page}
              isShow={true}
              isSortShow={isSortShow}
              sortClick={this.sortClick.bind(this)}
              cbChgDataFun={this.cbChgDataFun.bind(this)}
            />
          }
          {/* 分页 */}
          <div style={{display:isPC&&!isSearchPageShow?'':'none'}}>
          <PaginationCon
            {...this.props}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            page={page}
            isShow={true}
            history={history}
            callbackPagekData={this.callbackPagekData.bind(this)}
          />
           </div>
        </div>
        <div style={{display:isPC?'':'none'}}>
          <TipsCon {...this.props} />
        </div>
      </div>
    );
  }
}
export default withTranslation()(DealHistory)

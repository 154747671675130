import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import Hightcharts, { chart } from 'highcharts'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  dropUpImg,
  dropUpOnImg,
  dropUpOnLightImg,
  bellOffImg,
  bellOnImg
} from '@/asserts/imgs/common/index'
import { 
  tradeOffImg
} from '@/asserts/imgs/nav/index'
import { 
  moreImg,
  tradeOff1Img,
  starOnImg,
  starLightImg,
  starOff1Img
} from '@/asserts/imgs/quote/index'
import commonChart from '@/asserts/js/commonChart'
import Footer from '../footer/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let myTimer=null

class ListCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isLoading:true,
      newRic:'',
      listData:[],
      colorGroup:['#F5475B','#39C46E','#575757'],
      isChgVal:'PCTCHNG',
      listRic:null,
      listRicM:null,
      isMoreMenuShow:false,
      upImg:dropUpImg,
      downImg:dropDownImg,
      sortTag:'',
      indexData0123:[0,1,2,3],
      chgNum:1
    }
    this.myListRef = React.createRef()
  }
  //选择不同的ric--pc/minpc
  changeRicClick(data){
    const {isMobile}=this.props
    if(isMobile) return
    this.showDetailFun(data)
  }
  //选择不同的ric--mobile view
  changeRicMobileClick(data){
    const {isMobile}=this.props
    if(isMobile){
      this.setState({
        listRicM:null,
        isMoreMenuShow:false
      })
      this.showDetailFun(data)
      this.props.cbDetailPageShow(true)
    }
  }
  //detail页面--显示--移动端
  moreClick(data,index,e){
    const {watchListData}=this.props
    const {listData}=this.state
    //阻止点击事件冒泡
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    // 切换
    this.setState(state => ({
      isMoreMenuShow: !state.isMoreMenuShow,
      listRicM:!state.isMoreMenuShow?data['ric']:null
    }))
    //处理数据
    let getData=watchListData.length?watchListData.find(item=>item['watchlist_id']==1):[]
    let getRicData=getData&&getData['stock_id_list']
    if(getRicData&&getRicData.length){
      for(let i in getRicData){
        if(getRicData[i]==data['ric']){
          listData[index]['isstarShow']=true
        }
      }
      this.setState({
        listData:listData
      })
    }
  }
  //更换右边的数据
  showDetailFun(data){
    const {currentIndex}=this.props
    const {listRic}=this.state
    //点击了PopularStocksListAdd页面的某个history值
    let ChgAddData=sessionStorage.getItem('ChgAddData')
    if(ChgAddData){
      sessionStorage.removeItem('ChgAddData')
    }else{
      if(listRic==data['ric']) return
    }
    this.setState({
      listRic:data['ric'],
    })
    let newData=data
    newData['name']=currentIndex==1?data['stock_name']:data['name']
    newData['symbol']=currentIndex==1?data['stock_code']:data['symbol']
    this.props.callbackChgRic(newData)
  }
  //切换--显示不同的  chg% -> chg -> Volume
  changeClick(e){
    const {isChgVal}=this.state
    //阻止点击事件冒泡
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    let key=isChgVal=='PCTCHNG'?'NETCHNG_1':isChgVal=='NETCHNG_1'?'ACVOL_1':'PCTCHNG'
    this.setState({
      isChgVal: key,
      upImg:dropUpImg,
      downImg:dropDownImg,
      sortTag:'',
    })
  }
  //trade/alert页面--显示/隐藏--button--mobile view
  showPageClick(data,tag,e){
    //阻止点击事件冒泡
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      listRicM:null,
      isMoreMenuShow:false
    })
    this.showDetailFun(data)
    if(tag=='trade'){
      this.props.callbackTradeShow()
    }else{
      // alert
      this.props.callbackAlertsShow()
    }
  }
  //点亮星星并添加到watchlist_id=1，tab栏并定位到watchlist_id=1--mobile view
  startClick(ricData,index,e){
    const {currentIndex,watchListData}=this.props
    const {listData,indexData0123}=this.state
    //阻止点击事件冒泡
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    //处理数据
    let item = listData.find((item,i) => i==index)
    let index_1=watchListData.findIndex(item=>item['watchlist_id']==1)
    item['isstarShow']=!item['isstarShow']
    this.setState({
      listData:listData
    })
    this.props.callbackTab(Number(index_1)+4)
    this.props.cbChgWatchlistIdFun('1')
    //取消
    if(!ricData['isstarShow']){
      this.props.cbSetWatchListFun(ricData['ric'],'del',1)
      //刷新数据
      if(!indexData0123.includes(currentIndex)){
        this.reStarDataFun()
      }
      return
    }
    //添加
    this.props.cbSetWatchListFun(ricData['ric'],'add',1)
  }
  //排序
  sortClick(tag){
    const {ChgThemeData,currentIndex,store}=this.props
    const {listData,isChgVal,sortTag}=this.state
    if(tag==sortTag||listData.length<=0) return
    this.setState({
      isLoading: true
    })
    let key=isChgVal
    let getUpImg=tag=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
    let getDownImg=tag=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
    let getData=[],noData=[]
    //当数据为-/undefined时放在数组的后面
    for(let i in listData){
      let newData=store.getDataByRic(listData[i]['ric'])
      let tempsData=newData[key]
      listData[i]['TRDPRC_1']=newData['TRDPRC_1']
      listData[i]['NETCHNG_1']=newData['NETCHNG_1']
      listData[i]['ACVOL_1']=newData['ACVOL_1']
      listData[i]['PCTCHNG']=newData['PCTCHNG']
      tempsData=='-'||typeof(tempsData) == 'undefined'?noData.push(listData[i]):getData.push(listData[i])
    }
    let getNewData=common.sortNumber(tag,getData,key)
    getNewData.push.apply(getNewData,noData)
    let name=currentIndex==1?'portfolio':'stocks'
    this.areaChartFun(getNewData,name)
    this.setState({
      upImg:getUpImg,
      downImg:getDownImg,
      listData:getNewData,
      sortTag:tag,
      isLoading:false
    })
    clearTimeout(myTimer)
    myTimer = setInterval(()=>{
      this.areaChartFun(getNewData,name)
    }, 1000*60*5)
  }
  //图表数据--area图
  areaChartFun(listData,switchName){
    const {colorGroup}=this.state
    for(let i in listData){
      fetch(common.getHttpChart+'minichart',{
        method:'POST',
        headers:{
          'Accept':'application/json,text/plain,*/*',
          'Content-Type':'application/json'
        },
        body:JSON.stringify({
          token:sessionStorage.getItem('token'),
          ric:listData[i]['ric']
        })
      })
      .then(res => res.json())
      .then(res => {
        //获取数据成功
        if(res['status']==0){
          let resData=res['data']['datalist'];
          let chartData=[];
          const point=79;
          if(resData.length>0){
            // const dataLength = Math.max(point, resData.length);
            // const startIndex = Math.max(0, resData.length - point);
            // for (let j = startIndex; j < dataLength; j++) {
            //   if (resData[j]) {
            //     chartData.push(Number(resData[j]['close']));
            //   }
            // }
            // while (chartData.length < point) {
            //   chartData.push(null);
            // }
            for(let j in resData){
              chartData.push(Number(resData[j]['close']));
            }
            let name=switchName=='portfolio'?'percentage_change':'PCTCHNG';
            let color=Number(listData[i][name])>0?colorGroup[0]:Number(listData[i][name])<0?colorGroup[1]:colorGroup[2];
            commonChart.areaData.yAxis['min']=Math.min(...chartData);
            commonChart.areaData.series=[{
              data:chartData,
              color:color,
              lineWidth: 1,
              fillColor: { 
                linearGradient: { 
                  x1: 0, 
                  y1: 0, 
                  x2: 0, 
                  y2: 1, 
                }, 
                stops: [ 
                  [0, common.hexToRgba(color,.1)],
                  [1, common.hexToRgba(color,.01)],
                ] 
              } 
            }];
            Hightcharts.chart('containerArea'+i,commonChart.areaData);
          }else{
            commonChart.areaData.series=[{
              data:[],
            }];
            Hightcharts.chart('containerArea'+i,commonChart.areaData);
          }
        }
      })
  }
  }
  //watchlist---get--getwatchliststocks--处理数据
  getDealWatchlistsFun(){
    const {watchListData,currentIndex,store}=this.props
    let getData=watchListData.length?watchListData[Number(currentIndex)-4]:[]
    let getRicData=getData&&getData['stock_id_list']
    if(getRicData&&getRicData.length){
      //SubscribeRic
      store.addSubscribeList(getRicData)  
      store.setQuoteData(getData)
      this.getDataFun('getwatchliststocks','watchlist',getRicData)
      return
    }
    this.setState({
      listData:[],
      isLoading:false
    })
  }
  //getpopularstocks/getwatchliststocks--获取数据
  getDataFun(pathname,bodyname,ricData){
    let token=sessionStorage.getItem('token')
    this.setState({
      isLoading: true
    })
    fetch(common.getHttp+pathname,{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:bodyname=='stocks'?JSON.stringify({token:token}):JSON.stringify({token:token,ric:ricData})
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let resData=res['data']['datalist']
        if(resData.length>0){
          //初始化执行1次---每隔5分钟执行接口
          this.areaChartFun(resData,'stocks')
          clearTimeout(myTimer)
          myTimer = setInterval(()=>{
            this.areaChartFun(resData,'stocks')
          }, 1000*60*5)

          // 选择ric
          let obj={
            name:resData[0]['name'],
            ric:resData[0]['ric'],
            symbol:resData[0]['symbol'],
            index:0
          }
          //点击了Add页面的某个history值
          let ChgAddData=sessionStorage.getItem('ChgAddData')
          if(!ChgAddData){
            this.props.callbackChgRic(obj)
          }
          let flagData=Object.keys(common.imgShortNameFlag)
          //处理数据/添加startshow
          for(let i in resData){
            resData[i]['isstarShow']=false
            for(let j in flagData){
              if(flagData[j]==resData[i]['exchange_country_code']){
                resData[i]['img']=common.imgShortNameFlag[resData[i]['exchange_country_code']]   
              }
            }
          }
          // 处理数据--flag
          resData=resData.length>0?common.imgDataFlagFun(resData,'img'):[]
          //SubscribeRic
          let subRicList=[]
          for (let index = 0; index < resData.length; index++) {
            subRicList.push(resData[index]['ric'])

            let PCTCHNG=resData[index]['PCTCHNG']
            let NETCHNG_1=resData[index]['NETCHNG_1']
            let ACVOL_1=resData[index]['ACVOL_1']
            let TRDPRC_1=resData[index]['TRDPRC_1']
            
            if (PCTCHNG&&PCTCHNG !== '-') {
              resData[index]['PCTCHNG_len']=Number(String(PCTCHNG).split('.')[0].length)+2
            }
            if (NETCHNG_1&&NETCHNG_1 !== '-') {
              resData[index]['NETCHNG_1_len']=Number(String(NETCHNG_1).split('.')[0].length)+2
            }
            if (ACVOL_1&&ACVOL_1 !== '-') {
              let val=ACVOL_1/1000000
              resData[index]['ACVOL_1_len']=Number(String(val).split('.')[0].length)
            }
            if (TRDPRC_1&&TRDPRC_1 !== '-') {
              resData[index]['TRDPRC_1_len']=Number(String(TRDPRC_1).split('.')[0].length)+2
            }
          }
          this.props.store.addSubscribeList(subRicList)  
          this.props.store.setQuoteData(resData)
          // 判断是否有数据来清空页面数据--切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
          if(bodyname=='stocks'){
            sessionStorage.setItem('hasQuoteDataLen',resData.length)
          }
          this.setState({
            listData:resData,
            isLoading:false
          })
          this.getListDataFun(bodyname,resData)
          return
        }
        this.setState({
          listData:[],
          isLoading:false
        })
        this.getListDataFun(bodyname,[])
        return
      }
      //获取失败
      this.setState({
        isLoading: false
      })
      this.getListDataFun(bodyname,[])
    })
  }
  getListDataFun(name,data){
    if(name=='watchlist'){
      this.props.cbGetListDataFun(data)
    }
  }
  //portfolio--获取数据
  getPortfolioDataFun(){
    this.setState({
      isLoading: true
    })
    fetch(common.getHttpZs+'getQuotePortfolioList',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token') 
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let resData=res['data']
        let getRisData=[]
        if(resData.length>0){
          for(let i in resData){
            getRisData.push(resData[i]['stock_id'])
          }
          if(getRisData.length==resData.length){
            this.getsimplequoteFun(resData,getRisData)
          }
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(allData,ric){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      //获取数据成功
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        let tempData=[]
        if(JSON.stringify(resData)!=='{}'){
          for(let i in ric){
            let data=resData[ric[i]]
            allData[i]['country']=data['exchange_country_code']
            allData[i]['current_price']=data['TRDPRC_1']
            allData[i]['display_exchange_code']=data['display_exchange_code']
            allData[i]['net_change']=data['NETCHNG_1']
            allData[i]['percentage_change']=data['PCTCHNG']
            allData[i]['ric']=data['ric']
            allData[i]['stock_code']=data['symbol']
            allData[i]['stock_name']=data['name']
            allData[i]['volume']=data['ACVOL_1']
            tempData.push(i)
          }
          if(allData.length==tempData.length){
            this.dealPortfolioDataun(allData)
          }
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err)
      this.dealPortfolioDataun(allData)
    })
  }
  // 处理数据
  dealPortfolioDataun(resData){
    //初始化执行1次---每隔5分钟执行接口
    this.areaChartFun(resData,'portfolio')
              
    clearTimeout(myTimer)
    myTimer = setInterval(()=>{
      this.areaChartFun(resData,'portfolio')
    }, 1000*60*5)

    let ChgAddData=sessionStorage.getItem('ChgAddData')
    if(!ChgAddData){
      // 选择ric
      let obj={
        name:resData[0]['stock_name'],
        ric:resData[0]['ric'],
        symbol:resData[0]['stock_code'],
        index:0
      }
      this.props.callbackChgRic(obj)
    }
    // 处理数据
    let flagData=Object.keys(common.imgShortNameFlag)
    let subRicList=[]

    for(let i in resData){
      for(let j in flagData){
        if(flagData[j]==resData[i]['country']){
          resData[i]['img']=common.imgShortNameFlag[resData[i]['country']]   
        }
      }
      resData[i]['name']=resData[i]['stock_name']
      resData[i]['symbol']=resData[i]['stock_code']
      resData[i]['NETCHNG_1']=resData[i]['net_change']
      resData[i]['PCTCHNG']=resData[i]['percentage_change']
      resData[i]['ACVOL_1']=resData[i]['volume']
      resData[i]['TRDPRC_1']=resData[i]['current_price']
      resData[i]['isstarShow']=false

      subRicList.push(resData[i]['ric'])

      let PCTCHNG=resData[i]['PCTCHNG']
      let NETCHNG_1=resData[i]['NETCHNG_1']
      let ACVOL_1=resData[i]['ACVOL_1']
      let TRDPRC_1=resData[i]['TRDPRC_1']
      
      if (PCTCHNG&&PCTCHNG !== '-') {
        resData[i]['PCTCHNG_len']=Number(String(PCTCHNG).split('.')[0].length)+2
      }
      if (NETCHNG_1&&NETCHNG_1 !== '-') {
        resData[i]['NETCHNG_1_len']=Number(String(NETCHNG_1).split('.')[0].length)+2
      }
      if (ACVOL_1&&ACVOL_1 !== '-') {
        let val=ACVOL_1/1000000
        resData[i]['ACVOL_1_len']=Number(String(val).split('.')[0].length)
      }
      if (TRDPRC_1&&TRDPRC_1 !== '-') {
        resData[i]['TRDPRC_1_len']=Number(String(TRDPRC_1).split('.')[0].length)+2
      }
    }
    //SubscribeRic
    this.props.store.addSubscribeList(subRicList) 
    this.props.store.setQuoteData(resData)

    resData=common.imgDataFlagFun(resData,'img')
    this.setState({
      listData:resData,
      isLoading:false
    })
  }
  noDataFun(){
    this.setState({
      listData:[],
      isLoading:false
    })
  }
  reStarDataFun(){
    const {currentIndex}=this.props
    clearTimeout(myTimer)
    this.setState({
      newRic:null,
      listRic:null,
      listRicM:null,
      listData:[],
      isLoading:true,
      isChgVal:'PCTCHNG',
      isMoreMenuShow:false,
      sortTag:'',
      upImg:dropUpImg,
      downImg:dropDownImg
    })
    if(currentIndex==0){
      this.getDataFun('getpopularstocks','stocks','')
      return
    }
    if(currentIndex==1){
      this.getPortfolioDataFun()
      return
    }
    this.getListDataFun('watchlist',[])
    this.getDealWatchlistsFun()
  }
  //Add Stock/Edit Watchlist--点击按钮
  btnClick=(tag)=>{
    this.setState({
      componentsIndex:tag==='add'?1:2
    })
    this.props.callbackLeftPageShow(tag)
  }
  onLoadDataFun(){
    const {currentIndex}=this.props
    if(currentIndex==0){
      this.getDataFun('getpopularstocks','stocks','')
      return
    }
    if(currentIndex==1){
      this.getPortfolioDataFun()
      return
    }
    this.getDealWatchlistsFun()
  }
  chgNumFun(){
    let current=this.myListRef.current
    let num=parseInt((current.clientHeight / 56)-2)
    this.setState({
      chgNum:num
    })  
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {currentIndex,isSwitchStar,ChgThemeData,closeRightPageShow}=this.props
    const {sortTag}=this.state
    if(currentIndex!==newProps['currentIndex']||isSwitchStar!==newProps['isSwitchStar']){
      this.reStarDataFun()
      this.chgNumFun()
    }
    if(ChgThemeData!==newProps['ChgThemeData']){
      let getUpImg=sortTag&&sortTag=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
      let getDownImg=sortTag&&sortTag=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg

      this.setState({
        upImg:getUpImg,
        downImg:getDownImg
      })  
    }
    if(closeRightPageShow!==newProps['closeRightPageShow']){
      const handler = setTimeout(() => {
        this.onLoadDataFun()
        this.chgNumFun()
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }
  //初始化
  componentDidMount(){
    this.onLoadDataFun()
    this.chgNumFun()
  }
  componentWillUnmount() {      
    clearTimeout(myTimer)
  } 
  render(){
    const {t,store,ChgThemeData,isMobile,isPC,currentIndex} = this.props
    const {listData,isLoading,isChgVal,upImg,downImg,sortTag,chgNum,listRic,listRicM}=this.state
    return (
      <div
        className={[
          APPstyles.flex_column
        ].join(' ')}
        style={{
          height:'100%'
        }}
      >
        <div
          className={[
            styles.list_con,
            APPstyles.flex_column,
            isPC?styles.list_con_PC:''
          ].join(' ')}
        >
          {/* 加载中 */}
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {/* 列表数据--header */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.table_header,
              styles.table_common,
              isMobile?styles.table_common_moile:'',
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
              ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark,
              isMobile?styles.table_header_mobile:'',
              isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
            ].join(' ')}
          >
            <div style={{width:isMobile?'32%':'36%'}}>{t('Name')}</div>
            <div style={{width:'18%'}}>&nbsp;</div>
            <div style={{position:'relative',right:6}}>{t('Price')}</div>
            <div className={styles.upDown_con}>
              <span style={{textAlign:'right'}}>{t(isChgVal=='PCTCHNG'?'Chg%':isChgVal=='NETCHNG_1'?'Chg':'Volume')}</span>
              <img src={upImg} className={styles.upDown_con_img0} />
              <img src={downImg} className={styles.upDown_con_img1} />
              <label 
                className={styles.up_img_seat} 
                style={{right:isMobile?'-8px':'-16px'}} 
                onClick={this.sortClick.bind(this,'asc')}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                  this.setState({
                    upImg:imgurl
                  })
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  let imgurl=sortTag=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                  this.setState({
                    upImg:imgurl
                  })
                }} 
              ></label>
              <label 
                className={styles.down_img_seat} 
                style={{right:isMobile?'-8px':'-16px'}} 
                onClick={this.sortClick.bind(this,'desc')}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                  this.setState({
                    downImg:imgurl
                  })
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  let imgurl=sortTag=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                  this.setState({
                    downImg:imgurl
                  })
                }} 
              ></label>
            </div>
            <div style={{display:isMobile?'':'none',width:'6%'}}>&nbsp;</div>
          </div>
          {/* 列表数据--list */}
          <div 
            ref={this.myListRef} 
            className={[
              styles.table_body,
              APPstyles.flex1_auto,
              isPC?APPstyles.paddingBottom60:''
            ].join(' ')}
          >
            {listData.length?
              listData.map((item,index) => {
              let data=store.getDataByRic(item['ric'])
              return  <div 
                        key={index}
                        className={[
                          APPstyles.flex_row,
                          styles.body_one,
                          styles.table_common,
                          isMobile?styles.table_common_moile:'',
                          ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          listRic==item['ric']?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark:''
                        ].join(' ')} 
                        onClick={this.changeRicClick.bind(this,item)}
                      >
                        <div className={APPstyles.flex_column} style={{width:isMobile?'32%':'36%'}} onClick={this.changeRicMobileClick.bind(this,item)}>
                          <div style={{width: '100%',alignItems:'center'}} className={APPstyles.flex_row}>
                            <img src={item['img']} className={APPstyles.img16} style={{display:item['img']?'':'none'}} />
                            <span 
                              className={[
                                styles.list_name,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                isMobile?APPstyles.fz16:''
                              ].join(' ')}
                            >
                              {item['symbol']&&item['symbol'].toUpperCase()}
                            </span>
                          </div>
                          <span 
                            className={[
                              styles.list_cur,
                              isMobile?APPstyles.fz14:'',
                              ChgThemeData=='Light'?APPstyles.color_009E9C:''
                            ].join(' ')}
                          >
                            {item['name']&&item['name'].toUpperCase()}
                          </span>
                        </div>
                        <div  id={'areaID'+index} style={{width:'18%',height:56}} onClick={this.changeRicMobileClick.bind(this,item)}>
                          <div id={'containerArea'+index}></div>
                        </div>
                        <div 
                          className={[
                            styles.list_turnover,
                            Number(data&&data['NETCHNG_1'])>0?APPstyles.color_red:Number(data&&data['NETCHNG_1'])<0?APPstyles.color_green:Number(data&&data['NETCHNG_1'])==0?APPstyles.color_grey:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
                          ].join(' ')} 
                          style={{fontSize:item['TRDPRC_1_len']==6?18:item['TRDPRC_1_len']==7?17:item['TRDPRC_1_len']>=8?16:19}}
                          onClick={this.changeRicMobileClick.bind(this,item)}
                        >
                          {common.transThousands(common.keepNum(data&&data['TRDPRC_1'],2))}
                        </div>
                        <div 
                          onClick={this.changeClick.bind(this)} 
                          style={{width:'24%'}}
                        >
                          <span 
                            className={[
                              styles.list_chg,
                              Number(data&&data['PCTCHNG'])>0?APPstyles.btn_red:Number(data&&data['PCTCHNG'])<0?APPstyles.btn_green:ChgThemeData=='Light'?APPstyles.btn_gray:APPstyles.star_black_hover,
                              Number(data&&data['PCTCHNG'])>0?APPstyles.bg_red:Number(data&&data['PCTCHNG'])<0?APPstyles.bg_green:ChgThemeData=='Light'?APPstyles.bg_grey:APPstyles.bg_black,
                              APPstyles.color_white
                            ].join(' ')} 
                            style={{ 
                              display:isChgVal=='PCTCHNG'?'':'none',
                              fontSize:item['PCTCHNG_len']>=7?12:14
                            }}
                          >
                            {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(data&&data['PCTCHNG'],2))))}
                          </span>
                          <span 
                            className={[
                              styles.list_chg,
                              Number(data&&data['NETCHNG_1'])>0?APPstyles.btn_red:Number(data&&data['NETCHNG_1'])<0?APPstyles.btn_green:ChgThemeData=='Light'?APPstyles.btn_gray:APPstyles.star_black_hover,
                              Number(data&&data['NETCHNG_1'])>0?APPstyles.bg_red:Number(data&&data['NETCHNG_1'])<0?APPstyles.bg_green:ChgThemeData=='Light'?APPstyles.bg_grey:APPstyles.bg_black,
                              APPstyles.color_white
                            ].join(' ')} 
                            style={{ 
                              display:isChgVal=='NETCHNG_1'?'':'none',
                              fontSize:item['NETCHNG_1_len']>=7?12:14
                            }}
                          >
                            {common.addplusNum(common.transThousands(common.keepNum(data&&data['NETCHNG_1'],2)))}
                          </span>
                          <span 
                            className={[
                              styles.list_chg,
                              ChgThemeData=='Light'?APPstyles.btn_gray:APPstyles.star_black_hover,
                              ChgThemeData=='Light'?APPstyles.bg_grey:APPstyles.bg_black,
                              APPstyles.color_white
                            ].join(' ')}  
                            style={{
                              display:isChgVal=='ACVOL_1'?'':'none',
                              fontSize:14
                            }}
                          >
                            {
                              item['ACVOL_1_len']>=4?
                                common.addNumB((common.keepNum(common.splitNumB(data&&data['ACVOL_1']),2)))
                              :
                                common.addNumM((common.keepNum(common.splitNumM(data&&data['ACVOL_1']),2)))   
                            }
                          </span>
                        </div>
                        {/* 移动端  */}
                        {
                          isMobile?
                            <div style={{width:'6%',paddingRight:0}}>
                              <img src={moreImg} className={APPstyles.img24} style={{position:'relative',left:2}} onClick={this.moreClick.bind(this,item,index)} alt='more' />
                            </div>
                          :
                            ''  
                        }
                        {/* 移动端  */}
                        {
                          isMobile?
                            <div 
                              className={[
                                APPstyles.flex_column,
                                styles.more_con,
                                ChgThemeData=='Light'?styles.more_con_light:'',
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                              ].join(' ')}
                              style={{
                                display:listRicM==item['ric']?'':'none',
                                top:index<chgNum?8:'unset',
                                bottom:index>=chgNum?12:'unset'
                              }}
                            >
                              <div 
                                className={[
                                  APPstyles.flex_row,
                                  styles.more_con_one,
                                  ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                                ].join(' ')} 
                                onClick={this.showPageClick.bind(this,item,'trade')}
                              >
                                <img 
                                  src={ChgThemeData=='Light'?tradeOffImg:tradeOff1Img} 
                                  className={APPstyles.img20} 
                                  alt='trade' 
                                />
                                {t('Trade')}
                              </div>
                              <div 
                                className={[
                                  APPstyles.flex_row,
                                  styles.more_con_one,
                                  ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                                ].join(' ')} 
                                onClick={this.startClick.bind(this,item,index)}
                              >
                                <img 
                                  src={item['isstarShow']?starOnImg:ChgThemeData=='Light'?starLightImg:starOff1Img} 
                                  className={APPstyles.img20} 
                                  alt='star' 
                                />
                                {t('Favourite')}
                              </div>
                              <div 
                                className={[
                                  APPstyles.flex_row,
                                  styles.more_con_one
                                ].join(' ')} 
                                onClick={this.showPageClick.bind(this,item,'alert')}
                              >
                                <img 
                                  src={ChgThemeData==='Light'?bellOffImg:bellOnImg} 
                                  className={APPstyles.img20} 
                                  alt='alerts' 
                                />
                                {t('alerts.alert')}
                              </div>
                            </div>
                          :
                            ''  
                        }
                    </div>
                  })
              :
                <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t(currentIndex==0?'No datas1':'No datas')}</div>      
            }
          </div>
          <Footer 
            {...this.props} 
            callbackLeftPageShow={(data)=>{
              this.props.callbackLeftPageShow(data)
            }} 
          />
        </div>
      </div>
  )}
}
export default withTranslation()(observer(ListCon))
import React from 'react';
import {withTranslation} from 'react-i18next';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    const {t,ChgThemeData,isSearchPageShow,sizeTotal,recperpage,isLoading,history} = this.props;
    const {}=this.state;
    return (
      <div
        className={[
          isSearchPageShow?APPstyles.flex1_auto:'',
          isSearchPageShow?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'',
        ].join(' ')}
      >
        <div className={[styles.header,styles.common,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span>{t('Currency2')}</span>
          <span>{t('Buying Power')}</span>
          <span>{t('Bank Balance')}</span>
          <span>{t('Money In Transit')}</span>
          <span>{t("Today's Sales")}</span>
          <span>{t('Amount Transferred In Transit')}</span>
          <span>{t('Bank Stock Deposits')}</span>
          <span>{t("Today's Stock Deposit")}</span>
        </div>
        <div className={history.length?'':APPstyles.minH300} style={{position:!history.length&&!isLoading?'relative':'unset'}}>
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length>0?
            history.map((item,index) => {
              return  <div
                        key={index} 
                        className={[
                          styles.body,
                          styles.common,
                          APPstyles.flex_row,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                        ].join(' ')}
                      >
                        <span>{t(item['currency'])}</span>
                        <span>{t(common.transThousands(common.keepNum(item['buying_power'],2)))}</span>
                        <span>{t(common.transThousands(common.keepNum(item['bank_balance'],2)))}</span>
                        <span>{t(common.transThousands(common.keepNum(item['money_in_transit'],2)))}</span>
                        <span>{t(common.transThousands(common.keepNum(item['today_sale'],2)))}</span>
                        <span>{t(common.transThousands(common.keepNum(item['amount_transferred_in_transit'],2)))}</span>
                        <span>{t(common.transThousands(common.keepNum(item['bank_stock_deposit'],2)))}</span>
                        <span>{t(common.transThousands(common.keepNum(item['today_stock_deposit'],2)))}</span>
                      </div>
            }):
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>    
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);
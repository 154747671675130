import enUS from './en-US.json'
import zh from './zh-CN.json'

const resources = {
  'en-US': {
    translation: enUS
  },
  'zh-CN': {
    translation: zh
  },
  zh: {
    translation: zh
  }
}
export default resources
import React from 'react';
import { Button, Modal, message, Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import common from '@/asserts/js/common';
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg,
  warningImg,
  remeberOffImg,
  remeberOnImg,
} from '@/asserts/imgs/common/index';
import APPstyles from '@/App.less';
import styles from '../note/index.less';

class AgreeIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgCloseImg:closeImg,
      careData:[
        {

          num:'一、',
          title:'除簽署上述風險預告書外，買賣下列外國有價證券需符合一定資格條件:',
          data:[
            {num:'1.',title:'首次買賣具槓桿或放空效果之ETF，且以正向不超過二倍或反向不超過一倍者，或'},
            {num:'2.',title:'欲交易香港創業版股票、封閉型基金(CEF)者'}
          ],
        },
        {
          num:'二、',
          title:'委託人均需符合下列資格條件之一，始可買賣:',
          data:[
            {num:'1.',title:'已開立國內信用交易帳戶。'},
            {num:'2.',title:'最近一年內委託買賣國內或外國認購（售）權證成交達十筆（含）以上。'},
            {num:'3.',title:'最近一年內委託買賣國內或外國期貨交易契約成交達十筆(含)以上。'},
            {num:'4.',title:'委託買賣國內或外國證券交易市場掛牌之槓桿或放空效果ETF之成交紀錄。'},
          ],
        }
      ],
      riskData:[
        {
          num:'一、',
          title:'受託買賣外國有價證券風險預告書',
          subtitle0:'本風險預告書係依據「證券商受託買賣外國有價證券管理規則」第十條第二項規定辦理。',
          subtitle1:'委託人買賣外國有價證券，係於外國證券交易所或外國店頭市場，買賣股票、認股權證、受益憑證、存託憑證、債券及其他經主管機關核准投資標的，涉及「證券商受託買賣外國有價證券管理規則」相關規定、各交易市場當地法令規章、交易所及自律機構規章。委託人應瞭解開立交易帳戶從事外國有價證券投資，可能產生之潛在風險，並應詳讀及知悉下列各項事宜，以保護權益：',
          data:[
            {isChecked:false,value:'一、買賣外國有價證券之投資風險，依其投資標的及所投資交易市場而有所差異，委託人應就投資標的為股票、認股權證、受益憑證、債券及存託憑證等，分別瞭解其特性及風險，並注意所投資外國證券交易市場國家主權評等變動情形'},
            {isChecked:false,value:'二、投資外國有價證券係於國外證券市場交易，應遵照當地國家之法令及交易市場之規定辦理，其或與我國證券交易法之法規不同（如：部分外國交易所無漲跌幅之限制等），保護之程度亦有異，委託人及證券商除有義務遵守我國政府及自律機構之法律、規則及規範外，亦有義務遵守當地法令及交易市場規定、規章及慣例。'},
            {isChecked:false,value:'三、委託人投資外國有價證券，係基於獨立審慎之判斷後自行決定，並應於投資前明瞭所投資標的可能產生之（包括但不限於）國家、利率、流動性、提前解約、匯兌、通貨膨脹、交割、再投資、個別事件、稅賦、信用及受連結標的影響等風險，證券商對外國有價證券不為任何投資獲利或保本之保證。'},
            {isChecked:false,value:'四、投資外國有價證券，係以外國貨幣交易，因此，除實際交易產生損益外，尚須負擔匯率風險，且投資標的可能因利率、匯率、有價證券市價或其他指標之變動，有直接導致本金損失或超過當時本金損失之虞。'},
            {isChecked:false,value:'五、投資外國有價證券，證券商依「證券商受託買賣外國有價證券管理規則」第二十五條及第二十六條規定，提供於委託人之資料或對證券市場、產業或個別證券之研究報告，或證券發行人所交付之通知書或其他有關委託人權益事項之資料，均係依各該外國法令規定辦理，委託人應自行瞭解判斷。'},
            {isChecked:false,value:'六、證券商受託買賣外國有價證券應與委託人簽訂受託買賣外國有價證券契約。委託人就其中對交割款項及費用之幣別、匯率及其計算等事項之約定，應明確瞭解其內容，並同意承擔結匯匯率變化之風險及相關費用。'},
            {isChecked:false,value:'七、投資外國有價證券，應注意複受託機構可能因突發事件或風險考量拒絕該商品委託（如因戰爭而停止接受戰爭國家之商品交易）而造成交易不成立之買賣風險。'},
            {isChecked:false,value:'八、風險預告書之預告事項甚為簡要，亦僅為列示性質，因此對所有投資風險及影響市場行情之因素無法逐項詳述，委託人於交易前，除已對本風險預告書詳加研析外，對其他可能影響之因素亦須慎思明辨，並確實評估風險，以免因交易遭到無法承受之損失。'},
          ],
        },
        {
          num:'二、',
          title:'指數股票型基金買賣風險預告書',
          subtitle0:'本風險預告書係依據中華民國證券商業同業公會「證券商受託買賣外國有價證券管理辦法」第六條之二第二項規定訂之。',
          subtitle1:'指數股票型基金（下稱ETF）係以追蹤指數表現為目標的投資產品，而指數標的範圍廣泛包括：股票、債券、商品、原物料、能源、農產品利率…等。ETF 為追蹤標的指數的績效，或透過投資實體資產（包含股票、債券或實物商品等）、或透過投資金融衍生性商品（包含期貨、選擇權、交換合約（Swap）等）去達到接近於標的指數的風險與報酬，爰買賣ETF有可能會在短時間內產生極大利潤或極大的損失，委託人於交易前應審慎考慮本身的財務能力及經濟狀況是否適合買賣此種商品。在決定從事交易前，委託人應瞭解投資可能產生之潛在風險，並應知悉下列各項事宜，以保護權益：',
          data:[
            {isChecked:false,value:'一、買賣ETF係基於獨立審慎之判斷後自行決定，並應於投資前明瞭所投資之ETF可能有（包括但不限於）國家、利率、流動性、提前解約、匯兌、通貨膨脹、再投資、個別事件、稅賦、信用及連結標的市場影響等風險，證券商對委託人買賣之ETF不會有任何投資獲利或保本之保證。'},
            {isChecked:false,value:'二、投資外國有價證券係於國外證券市場交易，應遵照當地國家之法令及交易市場之規定辦理，其或與我國證券交易法之法規不同（如：部分外國交易所無漲跌幅之限制等），保護之程度亦有異，委託人及證券商除有義務遵守我國政府及自律機構之法律、規則及規範外，亦有義務遵守當地法令及交易市場規定、規章及慣例，倘當地法令對於交易規範有所有修改時（如美國市場針對PTP類股票新增預扣稅款規定），委託人亦須配合辦理。'},
            {isChecked:false,value:'三、ETF所投資之有價證券、商品、期貨或衍生性商品，係以外國貨幣交易，除實際交易產生損益外，尚須負擔匯率風險，且投資標的可能因利率、匯率或其他指標之變動，有直接導致本金損失之虞。'},
            {isChecked:false,value:'四、ETF所投資之有價證券、商品、期貨或衍生性商品，如無漲跌幅限制，則ETF有可能因價格大幅波動而在短時間內產生極大利潤或極大損失。'},
            {isChecked:false,value:'五、ETF所投資之有價證券、商品、期貨或衍生性商品交易時間與ETF掛牌市場交易時間可能不同，發行人依規定於網站所揭露淨值，可能因時差關係，僅係以該國外交易所最近一營業日之收盤價計算，委託人應瞭解ETF所投資之追蹤標的包括：連結實物表現、或運用衍生性金融工具(如：期貨、選擇權、交換契約（Swap）等)在全球其他市場可能會有更為即時之價格產生，故如僅參考發行人於網站揭露之淨值作為買賣ETF之依據，則可能會產生折溢價(即ETF成交價格低於或高於淨值)風險。'},
            {isChecked:false,value:'六、如依市場報價買賣ETF，有可能會出現買賣報價數量不足，或買賣報價價差較大之情況，投資前應詳細蒐集ETF買賣報價相關資訊，並注意流動性風險所可能造成之投資損失。'},
            {isChecked:false,value:'七、買賣槓桿反向型 ETF的委託人，應完全瞭解槓桿反向型 ETF之淨值與其標的指數間之正反向及倍數關係，且槓桿反向型 ETF僅以追蹤、模擬或複製每日標的指數報酬率正向倍數或反向倍數為目標，而非一段期間內指數正向倍數或反向倍數之累積報酬率。'},
            {isChecked:false,value:'八、本風險預告書之預告事項甚為簡要，亦僅為列示性質，因此對所有投資風險及影響市場行情之因素無法逐項詳述，委託人於交易前，除已對本風險預告書詳加研讀外，對其他可能影響之因素亦須慎思明辨，並確實評估風險，以免因交易遭受難以承受之損失。'},
          ],
        },
        {
          num:'三、',
          title:'封閉型基金買賣風險預告書',
          subtitle0:'本風險預告書係依據中華民國證券商業同業公會「證券商受託買賣外國有價證券管理辦法」第六條之二第二項規定訂之。',
          subtitle1:'封閉型基金(英文:Closed end Funds ,下稱CEF)係以一籃子有價證券商品之投資組合為主，以公司股票及債券為主要投資標的，投資種類包含股票型、債券型、特別股型、REITs型、市政債型等。CEF發行受益權單位數固定，當基金發行期滿、基金規模達到預定規模後，便不會再接受申購或贖回的基金。買賣CEF有可能會有市價與淨值產生折價或溢價的風險。此外，CEF也可能因流動性較差而導致價格波動大，在短時間內產生極大利潤或極大的損失，委託人於交易前應審慎考慮本身的財務能力及經濟狀況是否適合買賣此種商品。在決定從事交易前，委託人應瞭解投資可能產生之潛在風險，並應知悉下列各項事宜，以保護權益：',
          data:[
            {isChecked:false,value:'一、買賣CEF係基於獨立審慎之判斷後自行決定，並應於投資前明瞭所投資之CEF可能有（包括但不限於）國家、利率、流動性、提前解約、匯兌、通貨膨脹、再投資、個別事件、稅賦、信用及連結標的市場影響等風險，證券商對委託人買賣之CEF不會有任何投資獲利或保本之保證。'},
            {isChecked:false,value:'二、買賣CEF，即該基金所持有的投資組合證券的價值如下降，從而導致該基金的資產淨值和市場價格下降。基金投資組合中所持有之單一或全部股票的價值，可能會由於多種原因而增加或減少，其中包括股票發行人的業務活動和財務狀況，影響發行人業務或整個股市的市場和經濟狀況。'},
            {isChecked:false,value:'三、CEF可能須要承受一定程度的市場風險和信用風險。市場風險是利率上升，降低基金投資組合中的債券價值的風險。一般而言，基金投資組合所持有證券的剩餘到期時間或存續期間越長，其所面臨的利率風險越大，其資產淨值（NAV）的波動性就越大。信用風險是指基金所持有的債券發行人違約其支付本金和利息的承諾的風險。'},
            {isChecked:false,value:'四、CEF所投資之有價證券、商品、期貨或衍生性商品，係以外國貨幣交易，除實際交易產生損益外，也可能須負擔匯率風險，且投資之標的可能因利率、匯率或其他指標之變動，有直接導致本金損失之虞。'},
            {isChecked:false,value:'五、CEF所投資之有價證券、商品、期貨或衍生性商品，如無漲跌幅限制，則CEF有可能因價格波動幅度大而在短時間內產生極大利潤或極大損失。'},
            {isChecked:false,value:'六、CEF所投資之有價證券、商品、期貨或衍生性商品交易時間與CEF掛牌市場交易時間可能不同，發行人依規定於網站所揭露淨值，可能因時差關係，僅係以該國外交易所最近一營業日之收盤價計算。委託人應瞭解CEF所投資之追蹤標的，如僅參考發行人於網站揭露之淨值作為買賣CEF之依據，則可能會產生折溢價(即CEF成交價格低於或高於淨值)風險。'},
            {isChecked:false,value:'七、委託人如依市場報價買賣CEF，有可能會出現買賣報價數量不足，或買賣報價價差較大之情況，投資前應詳細蒐集CEF買賣報價相關資訊，並注意流動性風險所可能造成之投資損失。'},
            {isChecked:false,value:'八、本風險預告書之預告事項甚為簡要，亦僅為列示性質，因此對所有投資風險及影響市場行情之因素無法逐項詳述，委託人於交易前，除已對本風險預告書詳加研讀外，對其他可能影響之因素亦須慎思明辨，並確實評估風險，以免因交易遭受難以承受之損失。'},
          ],
        }
      ],
      secondsRemaining: 10,
      isButtonDisabled: true,
      isLoading:true,
      user_email:'',
      clientIP:'',
    }
  }
  onAgreeClick(sign){
    if(sign==='Y'){
      this.checkRiskData();
    }else{
      this.props.cbBtnDataFun(sign,'','');
    }
  };
  onMouseOver = () => {
    const {ChgThemeData} = this.props;
    let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg;
    this.updateImage(imgurl);
  };
  onMouseOut = () => {
    this.updateImage(closeImg);
  };
  updateImage(imgurl) {
    const {isPC} = this.props;
    if(!isPC) return;
    this.setState({
      chgCloseImg: imgurl,
    });
  };
  // 10秒倒计时
  startCountdown = () => {
    this.intervalId = setInterval(() => {
      if (this.state.secondsRemaining >= 0) {
        this.setState((prevState) => ({
          secondsRemaining: prevState.secondsRemaining - 1,
        }));
      } else {
        this.setState({ isButtonDisabled: false });
        this.clearInterval();
      }
    }, 1000);
  };
  clearInterval = () => {
    clearInterval(this.intervalId);
  };
  // 是否选中
  handleCheckClick = (index, subIndex) => {
    this.setState(prevState => {
        const newRiskData = [...prevState.riskData];
        newRiskData[index].data[subIndex].isChecked = !newRiskData[index].data[subIndex].isChecked;
        return { riskData: newRiskData };
    });
  }
  // 同意簽署--验证
  checkRiskData() {
    const {riskData} = this.state;
    let allPassed = true; 
    for (let item of riskData) {
        for (let dataItem of item.data) {
            if (!dataItem.isChecked) {
                allPassed = false;
                break;
            }
        }
        if (!allPassed) break;
    }
    if (allPassed) {
      this.setState({
        isLoading:true,
      });
      this.executeSignRiskNotice();
    } else {
      message.warning({content:'您未勾选并同意相关条款选项'});
    }
  }
  // 同意簽署--api
  signRiskNotice(signature,data,subject){
    const {user_email}=this.state;
    fetch(common.getHttpZs+'signRiskNotice',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        signature:signature,
        data:data,
        subject:subject,
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        this.props.cbBtnDataFun('Y',user_email,res['data']);
      }else{
        this.setState({
          isLoading: false,
        })
        let error = res['error'];
        if (error) {
          error = res['error']['code'] + " " + res['error']['msg'];
        } else if (res['data']) {
          error = res['data']['status'] + " " + res['data']['msg'];
        } else {
          error = res['message'];
        }
        message.warning({
          content: error,
        });
      }
      this.setState({
        isLoading:false,
      });
    })
  }
  checkUserEmail(){
    fetch(common.getHttpZs+'getUserEmail',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        let user_email=res['data']['user_email'];
        this.setState({
          user_email:user_email,
        });
        user_email&&this.startCountdown();
      }
      this.setState({
        isLoading:false,
      });
    })
  }
  // 获取客户端IP--模糊查找
  getUserIP = (onNewIP) => {
    let ipFound = false;
    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.onicecandidate = (ice) => {
        if (ice.candidate && !ipFound) {
            const ipCandidate = ice.candidate.candidate;
            // 专注于捕获通常出现在"udp"或"tcp"后面的IPv4或IPv6地址
            const simpleIpRegex = /(?:udp|tcp)\s\d+\s([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-fA-F0-9:]+)/;
            const matches = ipCandidate.match(simpleIpRegex);
            if (matches && matches[0]) {
                const ip = matches[0].trim();
                onNewIP(ip);
                ipFound = true; // 设置标志，表示已经找到IP地址
            }
        }
    };
    pc.createDataChannel("");
    pc.createOffer()
        .then((sdp) => pc.setLocalDescription(sdp))
        .catch((reason) => console.log("offer error", reason));
  }
  // Used pkcs7 as in insert order thus we need to include signature, data and subject as input, use dev to bypass webca verification
  executeSignRiskNotice() {
    const {accessData}=this.props;
    const {clientIP}=this.state;
    if (common.enableWebca) {
      const userid = accessData['uid'];
      const certFilter = "//S_CN=" + userid + "//";
      const signContent=accessData['aid']+"|17020|"+clientIP+"|0001 oversea stock risk disclosure statement";
      //執行PKCS7簽章
      window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
        if (code === 0 && data) {
          let certData = JSON.parse(data);
          this.signRiskNotice(certData.signature,signContent,certData.subdn);

        } else if (code === 5067 || code === "5067") {
          //憑證挑選
          window.twcaLib.SelectSignerFubon(userid, certFilter, "", "", "", 0x04 | 0x08, 0, null, (code, msg, t, data) => {
            if (code === 0) {
              //執行PKCS7簽章
              window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
                if (code === 0 && data) {
                  let certData = JSON.parse(data);
                  this.signRiskNotice(certData.signature,signContent,certData.subdn);
                } else {
                  this.setState({
                    isLoading: false,
                  });
                  message.warning({content: code + " " + msg});
                }
              });
            } else {
              this.setState({
                isLoading: false,
              });
              message.warning({ content: code + " " + msg});
            }
          });
        } else {
          this.setState({
            isLoading: false,
          });
          message.warning({content: code + " " + msg});
        }
      });
    } else {
      this.signRiskNotice('dev','dev','dev');
    }
  }
  componentDidMount() {
    this.checkUserEmail();
    this.getUserIP((ip) => {
      this.setState({
        clientIP:ip,
      });
    });
  };
  render(){
    const {t,isMobile,ChgThemeData,visible,accessData}=this.props;
    const {chgCloseImg,careData,riskData,isButtonDisabled,secondsRemaining,isLoading,user_email}=this.state;
    return (
      <Modal 
        centered={true}
        closeIcon={null}
        footer={null}
        maskClosable={false}
        open={visible}
        className={[styles.agreeCon,APPstyles.flex_column,ChgThemeData=='Light'?styles.modalLight:'',isMobile&&styles.modalMobile].join(' ')}
      >
        <div className={[styles.noteTitle,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,ChgThemeData==='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span>{t('複委託風險預告書')}</span>
          <img 
            src={chgCloseImg} 
            className={APPstyles.img24} 
            onClick={()=>this.onAgreeClick('N')}
            onMouseOver={() => this.onMouseOver()}
            onMouseOut={() => this.onMouseOut()}
          />
        </div>
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        <div className={[styles.messageCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span className={APPstyles.color_grey}>請選擇帳號:</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{accessData['aid']}</span>
        </div>
        <div className={[styles.messageCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span className={APPstyles.color_grey}>電子信箱:</span>
          <div className={[APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{alignItems:'center'}}>
            <img src={warningImg} alt='warning' className={APPstyles.img20} style={{marginRight:4,display:user_email?'none':''}} />
            <span>{user_email?user_email:'無留存電子信箱，請聯繫營業員或客服中心: 0800-073-588'}</span>
          </div>
        </div>
        <div className={[styles.agreeContent,APPstyles.flex1_auto,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span className={styles.lineCon}>&nbsp;</span>
          <div className={APPstyles.flex_column} style={{padding:16}}>
            <div className={APPstyles.flex_column} style={{color:'#F5475B'}}>
              <span className={styles.titleCon}>請注意：</span>
              {careData.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={[APPstyles.flex_row,styles.titleCon].join(' ')}>
                      <span>{item['num']}</span>
                      <span>{item['title']}</span>
                    </div>
                    {item['data'].map((item1, index1) => {
                      return (
                        <div key={index1} style={{padding:'0 16px'}}>
                          <div className={APPstyles.flex_row} style={{marginBottom:6}}>
                            <span>{item1['num']}&nbsp;</span>
                            <span>{item1['title']}</span>
                          </div>
                        </div>
                      )})
                    }
                  </div>
                )})
              }
            </div>
            <span className={APPstyles.fw700}>複委託風險預告書</span>
            <div style={{padding:'16px 0'}}>
              {riskData.map((item, index) => {
                return (
                  <div key={index} className={[APPstyles.color_grey,APPstyles.flex_column].join(' ')}>
                    <div className={[APPstyles.flex_row,styles.titleCon,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>
                      <span>{item['num']}</span>
                      <span>{item['title']}</span>
                    </div>
                    <span style={{marginBottom:16}}>{item['subtitle0']}</span>
                    <span style={{marginBottom:8}}>{item['subtitle1']}</span>
                    {item['data'].map((item1, index1) => {
                      return (
                        <div key={index1} className={APPstyles.flex_row} style={{alignItems:'flex-start',marginBottom:8}}>
                          <img src={item1['isChecked']?remeberOnImg:remeberOffImg} alt='remeber' className={APPstyles.img24} onClick={() => this.handleCheckClick(index, index1)} />
                          <span style={{marginLeft:6}}>{item1['value']}</span>
                        </div>
                      )})
                    }
                  </div>
                )})
              }
            </div>
            <div className={[APPstyles.color_grey,APPstyles.flex_column].join(' ')}>
              <span>依據金融監督管理委員會「證券商受託買賣外國有價證券管理規則」第十條第一項及中華民國證券商業同業公會「證券商受託買賣外國有價證券管理辦法」第六條第一項第四款規定，本人聲明：</span>
              <span>本人業於委託買賣外國有價證券前收受及詳讀本風險預告書，並經 貴公司指派專人解說，對上述說明事項及投資外國有價證券交易之風險已充分明瞭，茲承諾投資風險自行負責，包括所投資之外國有價證券在某些狀況下，將毫無價值，特此聲明。</span>
              <span style={{marginTop:16}}>此致,</span>
              <span style={{fontWeight:'700',marginBottom:40}}>富邦綜合證券股份有限公司</span>
            </div>
          </div>
        </div>
        <div className={[styles.agreeBtnCon,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark].join(' ')}>
          <Button className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} onClick={()=>this.onAgreeClick('N')}>
            {t('Cancel')}
          </Button>
          <Button 
            disabled={isButtonDisabled&&!user_email?'disabled':''}
            className={[APPstyles.bg_blue,isButtonDisabled?APPstyles.bg_backTop_black:APPstyles.btn_blue].join(' ')} 
            onClick={()=>this.onAgreeClick('Y')}
          >
            {t('同意簽署')} {user_email&&secondsRemaining>=0 ?'('+secondsRemaining+')':''}
          </Button>
        </div>
      </Modal>
  )}
}
export default withTranslation()(AgreeIndex);
import React from 'react'
import { withTranslation } from 'react-i18next'
import APPstyles from '@/App.less'
import styles from './index.less'

class Disclaimer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    const {t} = this.props
    return (
      <div className={[APPstyles.flex_column,styles.disclaimer_con].join(' ')}>
        <div className={APPstyles.flex_row} style={{marginBottom:4}}>
          <span>1.&nbsp;&nbsp;</span>
          <span>{t("Today's")}{t("profit and loss calculation for selling includes commission fees, but does not include exchange fees, transaction taxes, and other related expenses. For detailed profit and loss information, please check 'Realized Profit and Loss' on the next day.")}</span>
        </div>
        <div className={APPstyles.flex_row} style={{marginBottom:4}}>
          <span>2.&nbsp;&nbsp;</span>
          <span>{t("This page's calculation function is only applicable to customers who place orders online. If you place orders through manual commission or have special handling fees, this calculation function is not applicable. Please check the 'Realized P&L' section the next day for your information.")}</span>
        </div>
        <div className={APPstyles.flex_row} style={{marginBottom:4}}>
          <span>3.&nbsp;&nbsp;</span>
          <span>{t('The calculated profit and loss is for reference only. If you have any questions, please contact the salesperson or customer service center at 0800-073-588.')}</span>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Disclaimer)
import React from "react"
import { withTranslation } from "react-i18next"
import { Tooltip } from 'antd'
import common from '@/asserts/js/common'
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  tipImg,
  tipOnImg,
  tipOnLightImg
} from '@/asserts/imgs/common/index'
import CalendarCon from '@/components/Account/Calendar/index'
import InputCon from '@/components/Account/InputCon/index'
import DropDown from '@/components/DropDown/index'
import PaginationCon from '@/components/Account/Pagination/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let leftConW=1203

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minW520:520,
      minW370:370,
      minW340:340,
      dropDownL:0,
      dropDownR:'unset',
      dropDownT:38,
      dropDownW:'100%',
      isDropdownShow:false,
      isDownLoading:false,
      closeInput:false,
      closeCalendar:false,
      tipsImg0:tipImg,
      tipsImg1:tipImg,
      chgDropDownImg:dropDownImg
    }
  }
  dropDownClick(e){
    e.stopPropagation()
    this.setState(state => ({
      isDropdownShow: !state.isDropdownShow,
      closeInput: !state.closeInput,
      closeCalendar: !state.closeCalendar,
      isDownLoading:state.isDropdownShow?true:false
    }))
  }
  //更改下拉框--input
  changeDropDownData(){
    const {bodyW,isPC}=this.props
    const {minW520}=this.state
    let left=isPC?0:'unset'
    let right=isPC?'unset':bodyW>minW520?0:16
    let top=bodyW>minW520?38:50
    let width=isPC?'100%':bodyW>minW520?'100%':'90%'
    // 分页
    let contentId=document.getElementById('contentId').clientWidth
    let pageW=document.getElementById('pageId').clientWidth
    leftConW=contentId-pageW>1203?1203:'auto'

    this.setState({
      dropDownL: left,
      dropDownR: right,
      dropDownT:top,
      dropDownW:width
    })
  }
  //点击外部关闭下拉框
  handleClick = e => {
    if(!this.testComponent.contains(e.target)) {
      this.setState({
        isDropdownShow:false,
        chgDropDownImg:dropDownImg
      })
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,isSearchPageShow}=this.props
    if(bodyW!==newProps['bodyW']||isSearchPageShow!==newProps['isSearchPageShow']){
      this.changeDropDownData()
    }
  }
  //初始化
  componentDidMount() {
    this.changeDropDownData()
    document.addEventListener('click', this.handleClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }
  render() {
    const { t,bodyW,ChgThemeData,total_shares_dividend,isPC,isTablet,isMobile,isSearchPageShow,sizeTotal,recperpage,getDividendCur,getDividendVal,summary } = this.props
    const {minW520,dropDownL,dropDownR,dropDownT,dropDownW,isDropdownShow,isDownLoading,minW340,closeInput,closeCalendar,tipsImg0,tipsImg1,chgDropDownImg} = this.state
    return (
      <div 
        id='contentId'
        className={[
          isSearchPageShow&&Math.ceil(sizeTotal / recperpage)>1?APPstyles.flex_column:APPstyles.flex_row,
          isSearchPageShow?'':APPstyles.flex_between,
          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
        ].join(' ')}
        style={{
          padding:isPC?'10.5px 0 10.5px 8px':isMobile?0:'0 16px',
          margin:isTablet?0:'0 16px',
          minHeight:isTablet&&!isMobile?75:'auto'
        }}
      >
        <div 
          className={[
            styles.header_con,
            isMobile?styles.header_con_mobile:'',
            isMobile?APPstyles.flex_column:APPstyles.flex_row,
            APPstyles.flex_1
          ].join(' ')}
          style={{
            width:isPC?leftConW:'auto',
            maxWidth:isPC?leftConW:'unset'
          }}
        >
          <div
            className={[
              styles.header_left,
              APPstyles.flex_row,
              isMobile?styles.header_left_mobile:'',
              isMobile?ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark:''
            ].join(' ')}
          >
            <div 
              style={{
                marginRight: isMobile?0:8,
                minWidth:isPC?159:isTablet&&!isMobile?136:'auto',
                minHeight:48
              }}
            >
              <div
                className={[
                  APPstyles.flex_row,
                  styles.top
                ].join(' ')}
              >
                <div
                  className={APPstyles.flex_row}
                  style={{cursor:'pointer'}}
                  onClick={this.dropDownClick.bind(this)} 
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                    this.setState({
                      chgDropDownImg:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    this.setState({
                      chgDropDownImg:dropDownImg
                    })
                  }} 
                >
                  <span className={isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''}>{t("Total Dividend")}</span>
                  <span className={styles.cur} style={{display:getDividendCur?'':'none'}}>({t(getDividendCur)})</span>
                  <img
                    alt="dropdown"
                    src={chgDropDownImg}
                    className={styles.top_dropdown_img}
                    style={{
                      transform:!isDropdownShow?'rotate(0deg)':'rotate(-180deg)'
                    }}
                  />
                </div>
                <Tooltip 
                  title={t("The total amount of cash dividends received during the period.")} 
                  overlayClassName={[
                    ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                    isPC?'':isSearchPageShow?'':APPstyles.tooltipcon_custurm_left
                  ].join(' ')}
                  >
                  <img 
                    src={tipsImg0} 
                    className={[APPstyles.img16,styles.top_tooltip_img].join(' ')} 
                    alt='tips'
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                      this.setState({
                        tipsImg0:imgurl
                      })
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      this.setState({
                        tipsImg0:tipImg
                      })
                    }}
                  />
                </Tooltip>
              </div>
              <span
                className={[
                  styles.bottom,
                  ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                ].join(' ')}
                style={{
                  fontSize:isMobile?20:24
                }}
              >
                {common.transThousands(common.keepNum(getDividendVal,2))}
              </span>
            </div>
            <div 
              style={{
                marginRight: isMobile?0:8,
                minWidth:isPC?135:isTablet&&!isMobile?125:'auto',
                minHeight:48,
                textAlign:'left'
              }}
            >
              <div
                className={[
                  APPstyles.flex_row,
                  styles.top
                ].join(' ')}
              >
                <span className={isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''}>{t("Total Dividend Shares")}</span>
                <Tooltip 
                  title={t("The total number of stock dividends received during the period.")} 
                  overlayClassName={[
                    ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                    isMobile?APPstyles.tooltipcon_custurm_right:''
                  ].join(' ')}
                  >
                  <img 
                    src={tipsImg1}  
                    className={APPstyles.img16} 
                    style={{marginLeft:2}}
                    alt='tips'
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                      this.setState({
                        tipsImg1:imgurl
                      })
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      this.setState({
                        tipsImg1:tipImg
                      })
                    }}
                  />
                </Tooltip>
              </div>
              <span 
                className={[
                  styles.share_num,
                  ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                ].join(' ')}
                style={{
                  fontSize:isMobile?20:24
                }}
              >
                {common.transThousands(common.keepNum(total_shares_dividend,0))}
              </span>
            </div>
          </div>
          <div 
            className={[
              styles.header_right,
              APPstyles.flex_row,
              isMobile?styles.header_right_mobile:''
            ].join(' ')}
          >
            <CalendarCon
              {...this.props}
              closeCalendar={closeCalendar}
              callbackDateData={(data)=>{
                this.props.callbackDateData(data)
              }}
              cbCloseInput={()=>{
                //关闭下拉框
                this.setState(state => ({
                  closeInput:!state.closeInput,
                  isDropdownShow:false
                }))
              }}
            />
            <div 
              className={[
                isMobile?styles.input_con_mobile:''
              ].join(' ')}
              style={{
                width:'100%',
                position: bodyW>minW520?'relative':'unset'
              }}
            >
              <InputCon
                {...this.props}
                dropDownL={dropDownL}
                dropDownR={dropDownR}
                dropDownT={dropDownT}
                dropDownW={dropDownW}
                closeInput={closeInput}
                callbackInputData={(data)=>{
                  this.props.callbackInputData(data)
                }}
                cbCloseAll={()=>{
                  //关闭所有下拉框
                  this.setState(state => ({
                    closeCalendar:!state.closeCalendar,
                    isDropdownShow:false
                  }))
                }}
              />
            </div>
          </div>
          {/* 下拉框 */}
          <div ref = {testComponent => this.testComponent = testComponent}>
          {
            isDropdownShow?
              <div
                className={styles.dropsDown_con}
                style={{
                  width:bodyW>minW340?300:'90%',
                  top:isMobile?35:25,
                  left:isMobile?16:0
                }}
              >
                <DropDown
                  {...this.props}
                  tag='DividendHistory'
                  data={summary}
                  isLoading={isDownLoading}
                  cbDropDownData={(data)=>{
                    this.setState({
                      isDropdownShow:false
                    })
                    this.props.cbChgCurrencyFun(data)
                  }}
                />
              </div>
            :
              ''
          } 
          </div>
        </div> 
        {/* 分页 */}
        <div 
          id='pageId' 
          style={{
            display:isPC?'':'none',
            marginLeft:isPC?16:0
          }}
          className={[isSearchPageShow?styles.page_con:'',APPstyles.flex_row].join(' ')}
        >
          <PaginationCon
            {...this.props}
            isShow={false}
            callbackPagekData={(data)=>{
              this.props.callbackPagekData(data)
            }}
          />
        </div>
      </div>
    )
  }
}
export default withTranslation()(Header)

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";

export const Margin = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [buyColor, setBuyColor] = useState("EE3311");
  const [buyThickness, setBuyThickness] = useState(2);
  const [buyStyle, setBuyStyle] = useState("s");
  const [buyShowTracker, setBuyShowTracker] = useState(true);

  const [saleColor, setSaleColor] = useState("449922");
  const [saleThickness, setSaleThickness] = useState(2);
  const [saleStyle, setSaleStyle] = useState("s");
  const [saleShowTracker, setSaleShowTracker] = useState(true);
  const [height, setHeight] = useState("1");

  useEffect(() => {
    props.onTAValueChange({
      ta: "Margin",
      name: `${t("chart5.TA.Margin")} (Margin)`,
      option: {
        buyColor: buyColor,
        buyThickness: buyThickness,
        buyStyle: buyStyle,
        buyShowTracker: buyShowTracker,
        saleColor: saleColor,
        saleThickness: saleThickness,
        saleStyle: saleStyle,
        saleShowTracker: saleShowTracker,
        height: height,
      },
    });
  }, [
    buyColor,
    buyThickness,
    buyStyle,
    buyShowTracker,
    saleColor,
    saleThickness,
    saleStyle,
    saleShowTracker,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    resetALL();
  }, [props.isReset]);

  const resetALL = () => {
    setBuyColor("EE3311");
    setBuyThickness(2);
    setBuyStyle("s");
    setBuyShowTracker(true);

    setSaleColor("449922");
    setSaleThickness(2);
    setSaleStyle("s");
    setSaleShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "buy") {
      setBuyThickness(e.target.value);
    } else {
      setSaleThickness(e.target.value);
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "buy") {
      setBuyStyle(e.target.value);
    } else {
      setSaleStyle(e.target.value);
    }
  };

  // type: string
  const onShowTracker = (type) => {
    if (type === "buy") {
      if (buyShowTracker) setBuyShowTracker(false);
      else setBuyShowTracker(true);
    } else {
      if (saleShowTracker) setSaleShowTracker(false);
      else setSaleShowTracker(true);
    }
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "Margin-C1":
        setBuyColor(props.selectedColor);
        break;
      case "Margin-C2":
        setSaleColor(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("Margin")}></div> */}
      <div className="box-container">
        <span className="span">{t("chart5.TA.Margin")} (Margin)</span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("Margin")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-Margin">
          <div className="taparamlbl">
            {t("chart5.creditBalance")}({t("chart5.buy")})
          </div>
          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-Margin-b-color0"
              className="colorpicker"
              value={buyColor}
              style={{ backgroundColor: "#" + buyColor }}
              onClick={() =>
                onColorClick(buyColor, "Margin-C1", t("chart5.setting"))
              }
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-Margin-b-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={buyThickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "buy")}
              />
            </div>

            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "buy")}
            >
              <select id="msi-ta-Margin-b-style">
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-Margin-b-showtracker"
                className={
                  buyShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("sale")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-Margin">
          <div className="taparamlbl">
            {t("chart5.creditBalance")}({t("chart5.sell")})
          </div>
          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-Margin-s-color0"
              className="colorpicker"
              value={saleColor}
              style={{ backgroundColor: "#" + saleColor }}
              onClick={() =>
                onColorClick(saleColor, "Margin-C1", t("chart5.setting"))
              }
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-Margin-s-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={saleThickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "sale")}
              />
            </div>

            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "sale")}
            >
              <select id="msi-ta-Margin-s-style">
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-Margin-s-showtracker"
                className={
                  saleShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("sale")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-Margin">
          <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
          <div
            className="selectbox taparam"
            defaultValue="1"
            onChange={(e) => onHeightChange(e)}
          >
            <select id="msi-ta-Margin-ps-h" value={height}>
              <option value="2">{t("chart5.large")}</option>
              <option value="1">{t("chart5.normal")}</option>
              <option value="0">{t("chart5.small")}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const MACD = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(12);
  const [period2, setPeriod2] = useState(26);
  const [period3, setPeriod3] = useState(9);

  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [color3, setColor3] = useState("666666");
  const [thickness3, setThickness3] = useState(2);
  const [style3, setStyle3] = useState("s");
  const [showTracker3, setShowTracker3] = useState(true);

  const [height, setHeight] = useState("1");

  const [upColor, setUpColor] = useState("EE3311");
  const [downColor, setDownColor] = useState("449922");
  const [value, setValue] = useState(period + "," + period2 + "," + period3);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.MACD_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const MACD = localChartSetting.option;

        setValue(MACD.p1 + "," + MACD.p2 + "," + MACD.p3);
        props.onTAValueChange({
          ta: "MACD",
          name: `${t("chart5.TA.MACD")} (MACD)`,
          option: {
            p1: MACD.p1,
            p2: MACD.p2,
            p3: MACD.p3,
            color: MACD.color,
            thickness: MACD.thickness,
            style: MACD.style,
            showTracker: MACD.showTracker,
            color3: MACD.color3,
            thickness3: MACD.thickness3,
            style3: MACD.style3,
            showTracker3: MACD.showTracker3,
            upColor: MACD.upColor,
            downColor: MACD.downColor,
            height: MACD.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const MACD = props.localChartSetting.MACD_Option.option;

    setPeriod(parseInt(MACD.p1) || period);
    setPeriod2(parseInt(MACD.p2) || period2);
    setPeriod3(parseInt(MACD.p3) || period3);
    setColor(MACD.color);
    setThickness(parseInt(MACD.thickness) || thickness);
    setStyle(MACD.style);
    setShowTracker(MACD.showTracker);
    setColor3(MACD.color3);
    setThickness3(parseInt(MACD.thickness3) || thickness3);
    setStyle3(MACD.style3);
    setShowTracker3(MACD.showTracker3);

    setHeight(MACD.height);
    setUpColor(MACD.upColor);
    setDownColor(MACD.downColor);

  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(period + "," + period2 + "," + period3);
    props.onTAValueChange({
      ta: "MACD",
      name: `${t("chart5.TA.MACD")} (MACD)`,
      option: {
        p1: period,
        p2: period2,
        p3: period3,
        color: color,
        thickness: thickness,
        style: style,
        showTracker: showTracker,
        color3: color3,
        thickness3: thickness3,
        style3: style3,
        showTracker3: showTracker3,
        upColor: upColor,
        downColor: downColor,
        height: height,
      },
      value: value,
    });
  }, [
    period,
    period2,
    period3,
    color,
    thickness,
    style,
    showTracker,
    color3,
    thickness3,
    style3,
    showTracker3,
    upColor,
    downColor,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const MACD = props.localChartSetting.MACD_Option.option;

    setPeriod(parseInt(MACD.p1) || period);
    setPeriod2(parseInt(MACD.p2) || period2);
    setPeriod3(parseInt(MACD.p3) || period3);
    setColor(MACD.color);
    setThickness(parseInt(MACD.thickness) || thickness);
    setStyle(MACD.style);
    setShowTracker(MACD.showTracker);
    setColor3(MACD.color3);
    setThickness3(parseInt(MACD.thickness3) || thickness3);
    setStyle3(MACD.style3);
    setShowTracker3(MACD.showTracker3);

    setHeight(MACD.height);
    setUpColor(MACD.upColor);
    setDownColor(MACD.downColor);

  }, [props.isReset]);

  const resetALL = () => {
    setPeriod(12);
    setPeriod2(26);
    setPeriod3(9);
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);
    setColor3("666666");
    setThickness3(2);
    setStyle3("s");
    setShowTracker3(true);

    setHeight("1");
    setUpColor("EE3311");
    setDownColor("449922");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "p1") setPeriod(e.target.value);
    else if (type === "p2") setPeriod2(e.target.value);
    else setPeriod3(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "p1") setThickness(e.target.value);
    else setThickness3(e.target.value);
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "p1") setStyle(e.target.value);
    else setStyle3(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // type: string
  const onShowTracker = (type) => {
    if (type === "p1")
      if (showTracker) setShowTracker(false);
      else setShowTracker(true);
    else if (showTracker3) setShowTracker3(false);
    else setShowTracker3(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "MACD-C1":
        setColor(props.selectedColor);
        break;
      case "MACD-C3":
        setColor3(props.selectedColor);
        break;
      case "MACD-C4":
        setUpColor(props.selectedColor);
        break;
      case "MACD-C5":
        setDownColor(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("MACD")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.MACD")} (MACD) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("MACD")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-MACD">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.leading")} MACD</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-UI-p0-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() =>
                onColorClick(color, "MACD-C1", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-UI-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-MACD">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.lagging")} MACD</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-MACD-p1-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-MACD">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.signal")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-UI-p0-period0"
                type="number"
                step="1"
                min="1"
                value={period3}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color3}
              style={{ backgroundColor: "#" + color3 }}
              onClick={() =>
                onColorClick(color3, "MACD-C3", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p3")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p3")}>
              <select id="msi-ta-UI-p0-style" value={style3}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker3 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("p3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
        <div className="floatmsibox">
          <div className="menusubitem" id="msi-ta-MACD">
            <div className="taparamlbl">
              MACD {t("chart5.histogram2")}: {t("chart5.rise")}
            </div>
            <button
              id="msi-ta-MACD-above-color"
              className="taparam colorpicker"
              value={upColor}
              style={{ backgroundColor: "#" + upColor }}
              onClick={() =>
                onColorClick(
                  upColor,
                  "MACD-C4",
                  `MACD ${t("chart5.histogram2")}: ${t("chart5.rise")}`
                )
              }
            ></button>
          </div>
          <div className="menusubitem" id="msi-ta-MACD">
            <div className="taparamlbl">
              MACD {t("chart5.histogram2")}: {t("chart5.fall")}
            </div>
            <button
              id="msi-ta-MACD-below-color"
              className="taparam colorpicker"
              value={downColor}
              style={{ backgroundColor: "#" + downColor }}
              onClick={() =>
                onColorClick(
                  downColor,
                  "MACD-C5",
                  `MACD ${t("chart5.histogram2")}: ${t("chart5.fall")}`
                )
              }
            ></button>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                defaultValue="1"
                onChange={(e) => onHeightChange(e)}
                value={height}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

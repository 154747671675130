import React from 'react';
import { Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { warningImg } from '@/asserts/imgs/common/index';
import { Button } from 'antd';
import APPstyles from '@/App.less';
import styles from '../note/index.less';
import myStyles from './index.less';

class TipsIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render(){
    const {t,ChgThemeData,isMobile,visible,tipsTitle}=this.props;
    return (
      <Modal 
        centered={true}
        closeIcon={null}
        footer={null}
        maskClosable={false}
        open={visible}
        className={[ChgThemeData==='Light'&&styles.modalLight,isMobile&&styles.modalMobile].join(' ')}
      >
        <div 
          className={[
            myStyles.tipsCon,
            APPstyles.flex_column,
            APPstyles.flex_1,
            ChgThemeData=='Light'?styles.price_con_light:'',
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
          ].join(' ')} 
        >
          <div className={APPstyles.flex_1}>
            <div className={[myStyles.tipsTitleCon,APPstyles.flex_row].join(' ')}>
              <img src={warningImg} className={[APPstyles.img24].join(' ')} style={{cursor:'default'}} alt='warning' />
              <span className={APPstyles.flex_row} style={{alignItems:'center'}}>{t(tipsTitle)}</span>
            </div>
          </div>
          <div className={[myStyles.tipsBtnCon,APPstyles.flex_row].join(' ')}>
            <Button 
              type='default' 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
              onClick={()=>{ this.props.onCloseModal()}}
            >
              {t('OK')}
            </Button>
          </div>
        </div>
      </Modal>
  )}
}
export default withTranslation()(TipsIndex);
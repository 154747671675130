import React from 'react';
import { Button, message, Input } from 'antd';
import { withTranslation } from 'react-i18next';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import common from '@/asserts/js/common';
import { 
  remeberOffImg,
  remeberOnImg,
  bgImg,
  pwdImg,
  passwordImg,
  eyeOnImg,
  eyeOffImg
} from '@/asserts/imgs/login/index';
import { refreshImg,logoLightImg } from '@/asserts/imgs/common/index';
import { researchOnImg } from '@/asserts/imgs/nav/index';
import ApplyComponents from '@/components/App/ApplyComponents';
import UpdatePwdComponents from './updatePwd/index';
import NoteComponents from '@/components/modal/note/index';
import RiskComponents from '@/components/modal/risk/index';
import AgreeComponents from '@/components/modal/agree/index';
import AgreeNoticeComponents from '@/components/modal/agreeNotice/index';
import Appstyles from '@/App.less';
import styles from './index.less';

class Login extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      usernameVal:'',
      userpwdVal:'',
      isLoading:false,
      isApplyShow:'login',
      uid:'',
      idCardNum:'',
      coreVal:'',
      isAdminShow:false,
      rememberImg:remeberOffImg,
      isRemember:false,
      minW320:320,
      minW450:450,
      minW550:550,
      minW570:570,
      minW350:350,
      minW650:650,
      minW600:600,
      newUrl:'https://www.fubon.com/securities/open-now-02/index.html',
      forgetUrl:'https://www.fbs.com.tw/PasswordArea/ReissuePW1',
      isPwdShow:false,
      modalRemindData:{sign:'remind',title:'Password Change Reminder',content:'Your login password for Fubon Electronics Platform has been more than 90 days since the last password change. It is recommended that you change your password immediately and make sure it is not the same as the previous one to ensure the security of your transactions.',cancelText:'Remind in 90 Days',sureText:'Change Password'},
      modalRiskData:{sign:'risk',title:'Sign The Re-entrustment Risk Notice',content:'Dear customer, you have not signed the Risk Disclosure Statement  yet. You are currently unable to trade overseas stocks. Would you like to proceed to sign it immediately?',sureText:'Continue'},
      modalOnlineData:{sign:'online',title:'線上簽署',content:'',sureText:'複委託風險預告書'},
      remindkVisible:false,
      riskVisible:false,
      agreeVisible:false,
      agreeNoticeVisible:false,
      accessData:'',
      updateData:'',
      user_email:'',
      riskNoticeData:'',
    }
  }
  // idCardNum
  changePasswordClick(e){
    this.setState({
      idCardNum: e.target.value
    })
  }
  // username
  changeNameClick(e){
    this.setState({
      usernameVal: e.target.value
    })
  }
  // pwd
  changePwdClick(e){
    this.setState({
      userpwdVal: e.target.value
    })
  }
  // 密码是否可见
  chgEysClick(e){
    const {isPwdShow}=this.state
    e.stopPropagation();
    this.setState({
      isPwdShow: !isPwdShow
    })
  }
  // core
  changeCoreClick(e){
    this.setState({
      coreVal: e.target.value
    })
  }
  //是否记住
  rememberClick(){
    this.setState(state => ({
      isRemember: !state.isRemember,
      rememberImg: !state.isRemember?remeberOnImg:remeberOffImg
    }))
  }
  // 忘記密碼
  forgetPwdClick(){
    const {forgetUrl}=this.state
    //ios终端
    if(common.isiOS){
      window.location.href=forgetUrl
      return
    }
    //android终端/pc端
    window.open(forgetUrl,'_blank') 
  }
  handleLoginKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.loginClick();
    }
  }
  handleAdminKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.adminClick();
    }
  }
  // admin
  adminClick(){
    const {usernameVal,userpwdVal,isAdminShow,idCardNum}=this.state
    if(isAdminShow&&idCardNum==''){
      message.warning({
        content:'請輸入身份證號碼'
      })
      return
    }
    if(usernameVal==''){
      let contentTips=isAdminShow?'請輸入帳號號碼':'請輸入用戶名'
      message.warning({
        content:contentTips
      })
      return
    }
    if(userpwdVal==''){
      message.warning({
        content: '請輸入密碼'
      })
      return
    }
    this.setState({
      isLoading: true
    })
    let pathname=isAdminShow?'login2':'login'
    let body=isAdminShow?
          JSON.stringify({
            uid:idCardNum,
            accountid:usernameVal,
            password:userpwdVal
          })
        :
          JSON.stringify({
            userid:usernameVal,
            password:userpwdVal
          })  
    //登录
    fetch(common.getHttp+pathname,{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:body 
    })
    .then(res => res.json())
    .then(res => {
      //登录成功---获取--txid/data
      if(res['status']==0){
        let data=res['data'];
        this.accessFun(data);
      }else{
        //登录失败
        this.setState({
          isLoading: false,
        });
        let contentErr=isAdminShow?'請輸入正確的身份證號碼/帳號號碼/密碼':'請輸入正確的用戶名或者密碼'
        message.warning({
          content: contentErr,
        });
      }
    })
  }
  accessFun(data){
    fetch(common.getHttpZs+'access',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        txid:data['txid'],
        data:data['data'],
      })
    })
    .then(res1 => res1.json())
    .then(res1 => {
      //本地存储token/uid
      if(res1['status']==0){
        let resData=res1['data'];
        sessionStorage.setItem('token',resData['token']);
        this.accessAdmin(resData);
      } else {
        //获取失败
        this.setState({
          isLoading: false,
        });
        if(res1['status']==1001) {
          message.warning({
            content: "即將上線，敬請期待!!"
          })
        } else {
          message.warning({
            content: res1['error']?(res1['error']['Code'] + " " + res1['error']['Msg']):res1['message']
          })
        }
      }
    })
  }
  // login
  loginClick(){
    let {userpwdVal,idCardNum,coreVal,newUrl,isRemember}=this.state      
    idCardNum=idCardNum.trim()
    if(idCardNum==''){
      message.warning({
        content:'請輸入身份證號碼'
      });
      return
    }
    if(userpwdVal==''){
      message.warning({
        content: '請輸入密碼'
      });
      return
    }
    if(coreVal==''){
      message.warning({
        content: '請輸入驗證碼'
      });
      return
    }
    if(!validateCaptcha(coreVal)){
      message.warning({
        content: '請輸入正確的驗證碼'
      });
      this.setState({
        coreVal:'',
      });
      return
    }
    this.setState({
      isLoading: true,
    })
    //WebCA
    if (common.enableWebca) {
      const memberid = idCardNum.charAt(0).toUpperCase() + idCardNum.slice(1);
      const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
      window.WebCAQueryCertState(memberid, filter, (code, msg, token, data) => {
        if (code === 0 && data) {
          let d = JSON.parse(data);
          let rafunc = d.suggestAction;
          if (rafunc !== "None") { // Need to apply/renew cert
            if (rafunc === "ApplyCert") {
              window.alert("您未安裝CA憑證，為保障您的權益及交易安全，建議安裝「有效之電子憑證」，始能使用該功能。");
            }
            const webcaInfoRequestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ uid: memberid, rafunc: rafunc })
            };
            //Get parameters for calling WebCAInvoke
            fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
              .then(response => response.json())
              .then(data => {
                if (data.status === 0) {
                  data = data.data;
                  window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                  //呼叫WebCA執行對應raFunc的憑證作業
                  window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                    loadCaptchaEnginge(6,'#000000','#FFFFFF','numbers')
                    this.setState({
                      isLoading: false,
                      userpwdVal: '',
                      coreVal:''
                    })
                    if (code === 0 || code === "0000" || code === 7000) {
                      //success
                      message.success({
                        content: "請重新登入"
                      })
                    } else {
                      message.warning({
                        content: code + " " + msg
                      })
                    }
                  });
                } else {
                  loadCaptchaEnginge(6,'#000000','#FFFFFF','numbers')
                  this.setState({
                    isLoading: false,
                    userpwdVal: '',
                    coreVal:''
                  })
                  message.warning({
                    content: data.message
                  })
                }
              })
          } else {
            //憑證挑選
            window.twcaLib.SelectSignerFubon(memberid, filter, "", "", "", 0x04 | 0x08, 0, null, (code, msg, t, data) => {
              if (code === 0) {
                this.loginAPI();
              } else {
                loadCaptchaEnginge(6,'#000000','#FFFFFF','numbers')
                this.setState({
                  isLoading: false,
                  userpwdVal: '',
                  coreVal:''
                })
                message.warning({
                  content: code + " " + msg
                })
              }
            });
          }
        } else {
          loadCaptchaEnginge(6,'#000000','#FFFFFF','numbers')
          this.setState({
            isLoading: false,
            userpwdVal: '',
            coreVal:''
          })
          message.warning({
            content: code + " " + msg
          })
        }
      });
    } else {
      this.loginAPI();
    }
  }
  // 登录 / 密碼變更提醒---to_remind_change_password - Remind user to change password if true
  loginAPI(){
    const {userpwdVal,idCardNum,newUrl,isRemember}=this.state 
    const memberid = idCardNum.charAt(0).toUpperCase() + idCardNum.slice(1);
    fetch(common.getHttpZs+'login3',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
      body:JSON.stringify({
        uid:memberid,
        password:userpwdVal,
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        let to_remind_change_password=res['to_remind_change_password'];
        sessionStorage.setItem('token',res['token']);
        if(isRemember){
          localStorage.setItem('idCardNum',memberid);
        }else{
          localStorage.removeItem('idCardNum');
        }
        this.setState({
          accessData:res,
        });
        if(to_remind_change_password){
          this.setState({
            remindkVisible:true,
          });
        }else{
          // this.riskNoticeFun(res);
          this.checkeagreementFun(res);
        }
      }else if(Number(res['status'])==1001){
        //ios终端
        if(common.isiOS){
          window.location.href=newUrl;
        }else{
          //android终端/pc端
          window.open(newUrl,'_self'); 
        }
      } else {
        //登录失败--密码/验证码清空/刷新驗證碼
        loadCaptchaEnginge(6,'#000000','#FFFFFF','numbers');
        this.setState({
          isLoading: false,
          userpwdVal: '',
          coreVal:'',
        });
        message.warning({
          content: res['error']?res['error']['message']:res['message'],
        });
      }
    })
  }
  // 90天後再提醒
  remainPasswordFun(){
    const {accessData}=this.state;
    fetch(common.getHttpZs+'remainPassword',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        message.warning({
          content: res['extra_message'],
        });
        // this.riskNoticeFun(accessData);
        this.checkeagreementFun(accessData);
      } else {
        message.warning({
          content:res['message'],
        })
      }
    })
  }
  // 登入後檢查是否簽署
  riskNoticeFun(resData){
    fetch(common.getHttpZs+'getSignRiskNotice',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:resData['token'],
      })
    })
    .then(res1 => res1.json())
    .then(res1 => {
      if(res1['status']==0){
        // sign_status: [0]已签署，[1]未签署，[10]签署中
        let sign_status=res1['data']['sign_status'];
        if(sign_status==1){
          this.setState({
            riskVisible:true,
          });
        }else if(sign_status==0){
          this.checkeagreementFun(resData);
        }
      } else {
        //获取失败
        this.setState({
          isLoading: false,
        });
        message.warning({
          content: res1['message'],
        });
      }
    })
  }
  //WebCA
  accessAdmin(resData){
    if (common.enableWebca && !window.inAppDetector.isInApp()) {
      const memberid = resData['uid'];
      const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
      window.WebCAQueryCertState(memberid, filter, (code, msg, token, data) => {
        if (code === 0 && data) {
          let d = JSON.parse(data);
          let rafunc = d.suggestAction;
          if (rafunc !== "None") { // Need to apply/renew cert
            const webcaInfoRequestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json'},
              body: JSON.stringify({ token: resData['token'], rafunc: rafunc })
            };
            //Get parameters for calling WebCAInvoke
            fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.status === 0) {
                data = data.data;
                // window.setWebCAinWebview(true);
                window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                //呼叫WebCA執行對應raFunc的憑證作業
                window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                  if (code===0 || code==="0000" || code===7000) {
                    this.checkeagreementFun(resData)
                  } else {
                    this.setState({
                      isLoading: false
                    })
                    message.warning({
                      content: code + " " + msg
                    })
                  }
                });
              } else {                 
                this.setState({
                  isLoading: false
                })
                message.warning({
                  content: data.message
                })
              }
            })
          } else {
            this.checkeagreementFun(resData)
          }
        } else {
          this.setState({
            isLoading: false
          })
          message.warning({
            content: code + " " + msg
          })
        }
      });
    } else {
      this.checkeagreementFun(resData)
    }
  }
  //判断是否需要进入免责声明页面
  checkeagreementFun(item){
    const {locationName}=this.props
    let uid=item['uid']
    let aid=item['aid']
    fetch(common.getHttpAgree+'checkeagreement',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:item['token']
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        //已填写申请，则显示所有的页面
        if(res['data']['signed']){
          sessionStorage.setItem('uid',uid)
          sessionStorage.setItem('aidListId',aid)
          this.props.callbackUid(uid)
          let name='/'+locationName[1]+'/Quote'
          this.accountDataFun(name)
          return
        }
        //未填写申请，则显示免责声明页面
        this.setState({
          isApplyShow:'apply',
          isLoading:false,
          uid:uid,
          aid:aid
        })
      }
    })
  }
  //根据地址栏login/admin显示对应的登录页面
  showLoginFun(){
    const {locationName} = this.props
    let getidCardNum=localStorage.getItem('idCardNum')
    this.setState({
      isAdminShow:locationName[2]=='login2'?true:false,
      rememberImg:getidCardNum?remeberOnImg:remeberOffImg,
      idCardNum:locationName[2]=='login'&&getidCardNum?getidCardNum:'',
      isRemember:getidCardNum?true:false
    })
    sessionStorage.setItem('loginName',locationName[2])
  }
  // 获取账号列表
  accountDataFun(item){
    fetch(common.getHttpZs+'getAccountIdList',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']['aid_obj_list']
        let aidDataLen=Object.keys(data)
        let isLen=aidDataLen.length>1?'Y':'N'
        sessionStorage.setItem('aidListData',JSON.stringify(data))
        sessionStorage.setItem('isSwitchShow',isLen)
        sessionStorage.setItem('isSwitchFirstShow',isLen)
        sessionStorage.setItem('isLoginOnload','Y')
        this.props.callbackpathname(item)
      }
      this.setState({
        isLoading:false,
      });
    })
  }
  // 密碼變更提醒--弹框
  cbRemindDataFun(sign){
    const {accessData}=this.state;
    this.setState({
      remindkVisible:false,
    });
    if(sign==='Y'){
      this.setState({
        isApplyShow:'updatePwd',
      });
    }else if(sign==='remind'){
      this.remainPasswordFun();
    }else{
      // this.riskNoticeFun(accessData);
      this.checkeagreementFun(accessData);
    }
  }
  // 簽署複委託風險預告書 / 線上簽署
  cbRiskDataFun(btn){
    const {modalOnlineData,modalRiskData,updateData,accessData}=this.state;
    let sign=updateData['sign'];
    if(btn==='Y'){
      if(sign==='risk'){
        modalOnlineData['content']=accessData['aid'];
        this.setState({
          updateData:modalOnlineData,
        });
      }else{
        this.setState({
          riskVisible:false,
          agreeVisible:true,
        });
      }
    }else{
      if(sign==='risk'){
        this.setState({
          riskVisible:false,
        });
        this.checkeagreementFun(accessData);
      }else{
        this.setState({
          updateData:modalRiskData,
        });
      }
    }
  }
  // cancel/update
  cbApplyDataFun(sign){
    const {accessData}=this.state;
    this.setState({
      isApplyShow:'login',
    });
    if(sign==='update'){
      // this.riskNoticeFun(accessData);
      this.checkeagreementFun(accessData);
    }else{
      this.setState({
        remindkVisible:true,
      });
    }
  }
  // 是否同意簽署
  cbAgreeDataFun(btn,user_email,data){
    if(btn==='Y'){
      this.setState({
        agreeNoticeVisible:true,
        agreeVisible:false,
        user_email:user_email,
        riskNoticeData:data,
      });
    }else{
      this.setState({
        riskVisible:true,
        agreeVisible:false,
      });
    }
  }
  // 已簽署--登录
  cbAgreeNoticeDataFun(){
    const {accessData}=this.state;
    this.setState({
      agreeNoticeVisible:false,
    });
    this.checkeagreementFun(accessData);
  }
  //初始化
  componentDidMount(){
    const {locationName} = this.props
    const {modalRiskData}=this.state;
    if(locationName[2]=='login'){
      loadCaptchaEnginge(6,'#000000','#FFFFFF','numbers')
    }
    let lang=localStorage.getItem('lang')
    let theme=localStorage.getItem('theme')
    let loginName=sessionStorage.getItem('loginName')
    
    sessionStorage.clear() //清空存储数据
    sessionStorage.setItem('loginName',loginName)
    localStorage.setItem('lang',lang?lang:'zh-CN')
    localStorage.setItem('theme',theme?theme:'Dark')
    this.showLoginFun();
    this.setState({
      updateData:modalRiskData,
    });
  }
  render(){
    const {bodyW,bodyH,locationName,isPC,isMobile,isTablet} = this.props
    const{usernameVal,userpwdVal,isLoading,isApplyShow,uid,aid,idCardNum,coreVal,isAdminShow,rememberImg,minW320,minW450,minW550,minW350,minW650,minW600,minW570,isPwdShow,modalRemindData,updateData,remindkVisible,riskVisible,agreeVisible,agreeNoticeVisible,accessData,user_email,riskNoticeData}=this.state
    return (
      <div className={[Appstyles.WHPer100,isApplyShow==='apply'?'':styles.App_loginout].join(' ')}>
        {/* 免责声明页面 */}
        {isApplyShow==='apply' &&
          <ApplyComponents 
            {...this.props}
            uid={uid}
            aid={aid}
            callbackUid={(data)=>{
              this.props.callbackUid(data)
            }} 
            callbackpathname={(data)=>{
              this.accountDataFun(data)
            }} 
          />
        }
        {/* 登录页面 */}
        {isApplyShow==='login' &&
          <div className={Appstyles.WHPer100}>
          {
            locationName[2]=='login'?
              <div 
                className={[
                  Appstyles.WHPer100,
                  (isTablet&&!isMobile&&bodyH<=minW600)?'':Appstyles.flex_center,
                  isMobile||(isTablet&&!isMobile&&bodyH<=minW600)||(isPC&&bodyH<=minW650)?Appstyles.flex_column:'',
                  isMobile||(isTablet&&!isMobile&&bodyH<=minW600)||(isPC&&bodyH<=minW650)?Appstyles.hPer_overflowH:''
                ].join(' ')}
                style={{
                  backgroundImage:common.isTestShow?'':'url('+bgImg+')',
                  backgroundSize:isPC?'100% 100%':isMobile?'210% 100%':'152% 100%',
                  justifyContent:isMobile?'center':'flex-start',
                  backgroundColor:common.isTestShow?'#222429':''
                }}
              >
                <div 
                  className={[
                    Appstyles.flex_column,
                    Appstyles.wPer100,
                    isTablet||(isPC&&bodyH<=minW650)?Appstyles.flex1_auto:'',
                    styles.App_login,
                    isMobile?styles.App_login_mobile:'',
                  ].join(' ')}
                >
                  <img 
                    src={logoLightImg} 
                    alt='logo' 
                    className={[
                      styles.App_login_logo,
                      isMobile?styles.App_login_logo_mobile:'',
                    ].join(' ')}
                    style={{
                      marginLeft:isMobile?0:141,
                      display:common.isTestShow?'none':''
                    }}
                  />
                  <div 
                    className={[
                      Appstyles.flex_column,
                      styles.App_login_content
                    ].join(' ')}
                    style={{
                      marginBottom:isMobile||(isTablet&&!isMobile&&bodyH<=minW650)?50:0,
                      marginLeft:isMobile?0:141,
                      width:isMobile?'100%':456
                    }} 
                  >
                    <div className={[Appstyles.flex_between,styles.title_con].join(' ')}>
                      <span className={styles.App_login_title}>客戶登入</span>
                      <span className={styles.subtitle} style={{display:common.isTestShow?'':'none'}}>測試環境</span>
                    </div>
                    <Input 
                      autoComplete='off' 
                      placeholder='請輸入身份證號碼'
                      value={idCardNum} 
                      onChange={this.changePasswordClick.bind(this)}
                    />
                    <div className={styles.App_login_pwd}>
                      <Input 
                        type={isPwdShow?'text':'password'}
                        autoComplete='off' 
                        placeholder='請輸入密碼'
                        value={userpwdVal} 
                        onChange={this.changePwdClick.bind(this)}
                      />
                      <img src={isPwdShow?eyeOnImg:eyeOffImg} className={[Appstyles.img24,Appstyles.transformY].join(' ')} onClick={this.chgEysClick.bind(this)} />
                    </div>
                    <div className={[Appstyles.flex_row,styles.App_login_core].join(' ')}>
                      <Input 
                        autoComplete='off' 
                        placeholder='請輸入驗證碼'
                        value={coreVal} 
                        onChange={this.changeCoreClick.bind(this)}
                        onKeyDown={this.handleLoginKeyPress}
                      />
                      <div className={[Appstyles.flex_row,styles.App_login_core_right].join(' ')}>
                        <LoadCanvasTemplate reloadText='reload' />
                        <img src={refreshImg} className={Appstyles.transformY} />
                      </div>
                    </div>
                    <div className={[Appstyles.flex_row,styles.App_login_remember].join(' ')}>
                      <div className={[Appstyles.flex_row,styles.App_login_remember_left].join(' ')}>
                        <img src={rememberImg} className={isPC?Appstyles.img16:Appstyles.img24} onClick={this.rememberClick.bind(this)}  />
                        <span>記住身份證號碼</span>
                      </div>
                      <span className={styles.App_login_remember_right} onClick={this.forgetPwdClick.bind(this)}>忘記密碼?</span>
                    </div>
                    <Button type='default' className={Appstyles.btn_blue} loading={isLoading} onClick={this.loginClick.bind(this)}>登入</Button>
                  </div>
                  <span 
                    className={[
                      styles.App_login_sign,
                      (isTablet&&!isMobile&&bodyH<=minW600)||isMobile?styles.App_login_sign_1:'',
                      (isTablet&&!isMobile&&bodyH>minW600)?Appstyles.transformX:'',
                      (isPC&&bodyH<=minW600)?styles.App_login_sign_2:''
                    ].join(' ')}
                    style={{
                      bottom:bodyW<=minW320?10:23,
                      position:(((isTablet&&!isMobile)||isPC)&&bodyH>minW600)||(isMobile&&bodyH>minW570)?'absolute':(isTablet&&!isMobile&&bodyH<=minW600)?'relative':'unset',
                      textAlign:isPC?'left':'center',
                      paddingBottom:(isPC&&bodyH<=minW600)||(isMobile&&bodyH<=minW570)?30:0,
                      width:isMobile?'100%':'auto'
                    }}
                  >
                    {common.isTestShow?'':'© 富邦綜合證券股份有限公司'}
                  </span>
                </div>
              </div>
            :
              <div className={[Appstyles.WHPer100,Appstyles.flex_center,styles.App_adminout].join(' ')}>
                <div 
                  className={[styles.App_admin].join(' ')}
                  style={{
                    width:bodyW>minW550?490:'80%'
                  }}
                >
                  <img 
                    src={logoLightImg} 
                    alt='logo' 
                    className={styles.App_login_logo} 
                    style={{
                      width:bodyW<=minW450?bodyW<=minW350?150:190:234,
                      height:bodyW<=minW450?30:35,
                      display:common.isTestShow?'none':''
                    }}
                  />
                  <div className={[Appstyles.flex_row,styles.App_login_username].join(' ')} style={{marginBottom:10,display:isAdminShow?'':'none'}}>
                    <img src={passwordImg} alt='idCardNum' />
                    <Input 
                      autoComplete='off' 
                      placeholder='請輸入身份證號碼'
                      value={idCardNum} 
                      onChange={this.changePasswordClick.bind(this)}
                    />
                  </div>
                  <div className={[Appstyles.flex_row,styles.App_login_username].join(' ')}>
                    <img src={researchOnImg} alt='username' />
                    <Input 
                      autoComplete='off' 
                      placeholder={isAdminShow?'請輸入帳號號碼':'請輸入用戶名'}
                      value={usernameVal} 
                      onChange={this.changeNameClick.bind(this)}
                    />
                  </div>
                  <div className={[Appstyles.flex_row,styles.App_login_username,styles.App_login_userpwd].join(' ')}>
                    <img src={pwdImg} alt='password' />
                    <Input 
                      type='password'
                      autoComplete='off' 
                      placeholder='請輸入密碼'
                      value={userpwdVal} 
                      onChange={this.changePwdClick.bind(this)}
                      onKeyDown={this.handleAdminKeyPress}
                    />
                  </div>
                  <div className={[Appstyles.flex_row,styles.App_login_username].join(' ')}>
                    <Button type='default' className={Appstyles.btn_blue} loading={isLoading} onClick={this.adminClick.bind(this)}>登入</Button>
                  </div>
                </div>
              </div>
          }
          </div>
        }
        {/* 密碼變更提醒 */}
        {isApplyShow==='updatePwd' &&
          <UpdatePwdComponents
            {...this.props}
            cbApplyData={(sign)=>this.cbApplyDataFun(sign)}
          />
        }
        {remindkVisible&&
          <NoteComponents 
            {...this.props} 
            visible={remindkVisible}
            modalData={modalRemindData} 
            cbBtnDataFun={(sign)=>this.cbRemindDataFun(sign)}
          />
        }
        {riskVisible&&
          <RiskComponents 
            {...this.props} 
            visible={riskVisible}
            modalData={updateData} 
            cbBtnDataFun={(sign)=>this.cbRiskDataFun(sign)}
          />
        }
        {agreeVisible&&
          <AgreeComponents 
            {...this.props}
            accessData={accessData} 
            visible={agreeVisible}
            cbBtnDataFun={(sign,user_email,data)=>this.cbAgreeDataFun(sign,user_email,data)}
          />
        }
        {agreeNoticeVisible&&
          <AgreeNoticeComponents 
            {...this.props} 
            accessData={accessData} 
            user_email={user_email}
            riskNoticeData={riskNoticeData}
            visible={agreeNoticeVisible}
            cbBtnDataFun={()=>this.cbAgreeNoticeDataFun()}
          />
        }
      </div>
  )}
}
export default withTranslation()(Login);
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { observer } from "mobx-react";

import common from "@/asserts/js/common";
import useIsFirstRender from "@/components/Hooks/useIsFirstRender";
import { ReactComponent as IconArrow } from "@/asserts/imgs/svg/Icon_arrow.svg";

import styles from "./AlertsModal.less";
import APPstyles from "@/App.less";
import { 
  closeImg
} from '@/asserts/imgs/common/index'

const AlertsModal = (props) => {
  const { t } = useTranslation();
  const isFirstRender = useIsFirstRender();
  const { bodyW, minbodyW, ChgThemeData, isMobile, ChgLangData } =
    props;

  const [isLoading, setIsLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [sortType, setSortType] = useState("trigger_dt_desc"); // from active alert api: trigger_cond_asc, trigger_cond_desc, trigger_dt_asc, trigger_dt_desc

  const refSortType = useRef("trigger_dt_desc");

  const ARR_HEADER = [
    t("alerts.code"),
    t("alerts.name"),
    // <div
    //   className={styles.sort_button}
    //   onClick={() => {
    //     onClickSort("condition");
    //   }}
    // >
    //   {t("alerts.condition")}
    //   <IconArrowDown />
    // </div>,
    t("alerts.condition"),
    <>
      {t("alerts.targetPrice")}/{<br />}
      {t("alerts.percentChg")}
    </>,
    <div
      className={styles.sort_button}
      onClick={() => {
        onClickSort("dateTime");
      }}
    >
      {t("alerts.triggered")} {t("alerts.date")}/{t("alerts.time")}
      {/* {sortType === "trigger_dt_asc" ? <IconArrowUp /> : <IconArrowDown />} */}
      <IconArrow
        className={[
          sortType === "trigger_dt_asc" ? styles.asc : "",
          sortType === "trigger_dt_desc" ? styles.desc : "",
          ChgThemeData === "Light" ? styles.light : "",
        ].join(" ")}
      />
    </div>,
  ];

  const ARR_HEADER_MOBILE = [
    t("alerts.name"),
    // <div
    //   className={styles.sort_button}
    //   onClick={() => {
    //     onClickSort("condition");
    //   }}
    // >
    //   {t("alerts.condition")}
    //   <IconArrowDown />
    // </div>,
    t("alerts.condition"),
    <>
      {t("alerts.targetPrice")}/{<br />}
      {t("alerts.percentChg")}
    </>,
    <div
      className={styles.sort_button}
      onClick={() => {
        onClickSort("dateTime");
      }}
    >
      {t("alerts.triggered")}
      {<br />}
      {t("alerts.date")}/{t("alerts.time")}
      {/* {sortType === "trigger_dt_asc" ? <IconArrowUp /> : <IconArrowDown />} */}
      <IconArrow
        className={[
          sortType === "trigger_dt_asc" ? styles.asc : "",
          sortType === "trigger_dt_desc" ? styles.desc : "",
          ChgThemeData === "Light" ? styles.light : "",
        ].join(" ")}
      />
    </div>,
  ];

  let selectedHeader = isMobile ? ARR_HEADER_MOBILE : ARR_HEADER;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (sortType !== refSortType.current) {
      let arr = [...listData];
      arr.reverse();
      setListData(arr);
      refSortType.current = sortType;
    }
  }, [sortType]);

  const fetchData = () => {
    let token = window.sessionStorage.getItem("token");
    let sort = "trigger_dt_desc";

    setIsLoading(true);

    fetch(common.getHttp + "alert/history-alert", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        type: "notification",
        stockid: undefined,
        sort: sort,
        recperpage: 100,
        // pn: page,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          let datalist = res.datalist.map((item) => ({
            alertId: item.alert_id,
            ric: item.stock_code,
            symbol: item.stock_symbol,
            name: item.stock_name,
            condition: item.condition,
            targetPrice: item.price,
            tiggerDateTime: item.trigger_datetime,
          }));

          setListData(datalist);
          setIsLoading(false);
        } else {
          setListData([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setListData([]);
        setIsLoading(false);
      });
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3}){0,10}(?!\d))/g, ",");
  };

  const onClickCancel = () => {
    props.alertsStore.setCount(0);
    props.setIsOpen(false);
  };

  const onClickSave = () => {
    props.alertsStore.setCount(0);
    if (checked) {
      props.alertsStore.setIsNotification(!checked);
    }
    props.setIsOpen(false);
  };

  const onClickCheckbox = () => {
    setChecked(!checked);
  };

  const onClickSort = (type) => {
    if (type === "condition") {
      setSortType(
        sortType === "trigger_cond_desc"
          ? "trigger_cond_asc"
          : "trigger_cond_desc"
      );
    } else {
      setSortType(
        sortType === "trigger_dt_desc" ? "trigger_dt_asc" : "trigger_dt_desc"
      );
    }
  };

  // console.log("AlertModal");
  // console.log(props.alertsStore.count);
  // props.alertsStore.setCount(999);

  return (
    <div
      className={[
        styles.alert_modal_mask,
        isMobile ? styles.mobile : "",
        ChgLangData !== "en-US" ? styles.chinese : "",
      ].join(" ")}
      style={{ display: listData.length === 0 ? "none" : "flex" }}
    >
      <div
        className={[
          styles.alert_modal,
          ChgThemeData === "Light"
            ? APPstyles.bg_light
            : APPstyles.bg_page_black,
        ].join(" ")}
      >
        <div
          className={[
            styles.title,
            ChgThemeData === "Light"
              ? APPstyles.color_theme_black
              : APPstyles.color_white,
            ChgThemeData === "Light"
              ? APPstyles.borderBottom_light
              : APPstyles.borderBottom_dark,
          ].join(" ")}
        >
          {t("alerts.alerts")}({listData.length})
          <img
            src={closeImg}
            onClick={onClickCancel}
            alt="close"
            className={[APPstyles.img24].join(" ")}
          />
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.header,
            styles.common,
            bodyW > minbodyW ? "" : styles.common_min,
            ChgThemeData === "Light"
              ? APPstyles.borderBottom_light
              : APPstyles.borderBottom_dark,
            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:'' 
          ].join(" ")}
        >
          {selectedHeader.map((item, index) => {
            return (
              <span
                key={index}
                // style={{
                //   display: bodyW > minbodyW ? "" : index == 1 ? "none" : "",
                // }}
              >
                {item}
              </span>
            );
          })}
        </div>
        <div className={styles.content}>
          {listData.map((item, index) => {
            return (
              <div
                className={[
                  APPstyles.flex_row,
                  styles.body_one,
                  styles.common,
                  bodyW > minbodyW ? "" : styles.common_min,
                  bodyW > minbodyW ? "" : styles.body_one_min,
                  ChgThemeData === "Light"
                    ? APPstyles.borderBottom_light
                    : APPstyles.borderBottom_dark,
                  bodyW > minbodyW
                    ? ChgThemeData === "Light"
                      ? APPstyles.bg_event_light
                      : APPstyles.bg_dark_hover
                    : "",
                ].join(" ")}
                key={index}
              >
                <span className={bodyW > minbodyW ? "" : APPstyles.flex_column}>
                  <label
                    className={[
                      ChgThemeData === "Light"
                        ? APPstyles.color_theme_black
                        : APPstyles.color_white,
                      styles.bold,
                    ].join(" ")}
                  >
                    {common.valToUpperCase(item.symbol)}
                  </label>
                  {isMobile && (
                    <span
                      className={[
                        APPstyles.ellipsis,
                        APPstyles.color_cur,
                        APPstyles.fw500,
                        styles.name_mobile,
                      ].join(" ")}
                    >
                      {common.valToUpperCase(item.name)}
                    </span>
                  )}
                </span>
                {!isMobile && (
                  <span
                    className={[
                      APPstyles.ellipsis,
                      APPstyles.color_cur,
                      APPstyles.fw500,
                    ].join(" ")}
                  >
                    {common.valToUpperCase(item.name)}
                  </span>
                )}
                <span
                  className={
                    ChgThemeData === "Light"
                      ? APPstyles.color_theme_black
                      : APPstyles.color_white
                  }
                >
                  {t(
                    `alerts.${
                      item.targetPrice[item.targetPrice.length - 1] === "%"
                        ? `${item.condition}Percent`
                        : item.condition
                    }`
                  )}
                  {/* {isMobile &&
                    t(
                      `alerts.${
                        item.targetPrice[item.targetPrice.length - 1] === "%"
                          ? `${item.condition}PercentModal`
                          : item.condition
                      }`
                    )} */}
                </span>
                <span
                  className={
                    Number(item["NETCHNG_1"]) > 0
                      ? APPstyles.color_red
                      : Number(item["NETCHNG_1"]) < 0
                      ? APPstyles.color_green
                      : ChgThemeData === "Light"
                      ? APPstyles.color_theme_black
                      : APPstyles.color_white
                  }
                >
                  {numberWithCommas(item.targetPrice)}
                </span>
                <span
                  className={[
                    styles.datetime_mobile,
                    ChgThemeData === "Light"
                      ? APPstyles.color_theme_black
                      : APPstyles.color_white,
                  ].join(" ")}
                >
                  {item.tiggerDateTime}
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <div className={styles.checkbox_container}>
            <input
              type="checkbox"
              id="alert_modal_mask_checkbox"
              className={styles.checkbox}
              onClick={onClickCheckbox}
              checked={checked}
            />
            <label for="alert_modal_mask_checkbox"></label>
            <label
              for="alert_modal_mask_checkbox"
              className={[
                styles.text,
                ChgThemeData === "Light"
                  ? APPstyles.color_theme_black
                  : APPstyles.color_white,
              ].join(" ")}
            >
              {t("alerts.hideAlert")}
            </label>
          </div>
          <div
            className={[
              APPstyles.flex_row,
              APPstyles.place_button,
              APPstyles.place_button_alert,
              styles.botton_button_bar,
            ].join(" ")}
          >
            <Button
              type="default"
              className={APPstyles.btn_gray}
              onClick={onClickCancel}
            >
              {t("alerts.cancel")}
            </Button>
            <Button
              type="default"
              style={{ backgroundColor: "#008FC7" }}
              className={APPstyles.btn_blue}
              onClick={onClickSave}
            >
              {t("alerts.ok")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsModal;

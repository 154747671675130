import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const KRI = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(10);
  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [enable2, setEnable2] = useState(false);
  const [period2, setPeriod2] = useState(3);
  const [color2, setColor2] = useState("0080FF");
  const [thickness2, setThickness2] = useState(2);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(true);

  const [method, setMethod] = useState("sma");
  const [module, setModule] = useState("close");

  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(
    method +
    "," +
    (module === "close" ? t("chart5.last") : t("chart5.vol")) +
    "," +
    period +
    "," +
    period2
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.KRI_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const KRI = localChartSetting.option;

        const o1 = KRI.options[0];
        const o2 = KRI.options[1];

        let option1 = "";
        let option2 = "";

        option1 = {
          period: o1.period,
          color: o1.color,
          thickness: o1.thickness,
          style: o1.style,
          showTracker: o1.showTracker,
          enabled: o1.enabled,
        };

        option2 = {
          period: o2.period,
          color: o2.color,
          thickness: o2.thickness,
          style: o2.style,
          showTracker: o2.showTracker,
          enabled: o2.enabled,
        };

        let v =
          KRI.method +
          "," +
          (KRI.module === "close" ? t("chart5.last") : t("chart5.vol")) +
          ",";
        if (KRI.enable) {
          v = v + o1.period + ",";
        }
        if (KRI.enable2) {
          v = v + o2.period;
        }

        setValue(v);

        let options = [option1, option2];
        props.onTAValueChange({
          ta: "KRI",
          name: `${t("chart5.TA.KRI")} (KRI)`,
          option: {
            options: options,
            method: KRI.method,
            module: KRI.module,
            height: KRI.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const KRI = props.localChartSetting.KRI_Option;

    const o1 = KRI.option.options[0];
    const o2 = KRI.option.options[1];

    setEnable(o1.enabled);
    setPeriod(parseInt(o1.period) || period);
    setColor(o1.color);
    setThickness(parseInt(o1.thickness) || thickness);
    setStyle(o1.style);
    setShowTracker(o1.showTracker);

    setEnable2(o2.enabled);
    setPeriod2(parseInt(o2.period) || period2);
    setColor2(o2.enabled);
    setThickness2(parseInt(o2.thickness) || thickness2);
    setStyle2(o2.style);
    setShowTracker2(o2.showTracker);

    setMethod(KRI.option.method);
    setModule(KRI.option.module);

    setHeight(KRI.option.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    let option1 = "";
    let option2 = "";

    option1 = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    option2 = {
      period: period2,
      color: color2,
      thickness: thickness2,
      style: style2,
      showTracker: showTracker2,
      enabled: enable2,
    };

    let v =
      method +
      "," +
      (module === "close" ? t("chart5.last") : t("chart5.vol")) +
      ",";
    if (enable) {
      v = v + period + ",";
    }
    if (enable2) {
      v = v + period2;
    }

    setValue(v);

    let options = [option1, option2];
    props.onTAValueChange({
      ta: "KRI",
      name: `${t("chart5.TA.KRI")} (KRI)`,
      option: {
        options: options,
        method: method,
        module: module,
        height: height,
      },
      value: v,
    });
  }, [
    enable,
    period,
    period2,
    color,
    thickness,
    style,
    showTracker,
    enable2,
    color2,
    thickness2,
    style2,
    showTracker2,
    method,
    module,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const KRI = props.localChartSetting.KRI_Option;

    const o1 = KRI.option.options[0];
    const o2 = KRI.option.options[1];

    setEnable(o1.enabled);
    setPeriod(parseInt(o1.period) || period);
    setColor(o1.color);
    setThickness(parseInt(o1.thickness) || thickness);
    setStyle(o1.style);
    setShowTracker(o1.showTracker);

    setEnable2(o2.enabled);
    setPeriod2(parseInt(o2.period) || period2);
    setColor2(o2.enabled);
    setThickness2(parseInt(o2.thickness) || thickness2);
    setStyle2(o2.style);
    setShowTracker2(o2.showTracker);

    setMethod(KRI.option.method);
    setModule(KRI.option.module);

    setHeight(KRI.option.height);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(10);
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setEnable2(false);
    setPeriod2(3);
    setColor2("0080FF");
    setThickness2(2);
    setStyle2("s");
    setShowTracker2(true);

    setMethod("sma");
    setModule("close");

    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // type: string
  const onEnableChange = (type) => {
    if (type === "p1")
      if (enable) setEnable(false);
      else setEnable(true);
    else if (enable2) setEnable2(false);
    else setEnable2(true);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "p1") setPeriod(e.target.value);
    else setPeriod2(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "p1") setThickness(e.target.value);
    else setThickness2(e.target.value);
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "p1") setStyle(e.target.value);
    else setStyle2(e.target.value);
  };

  const onMethodChange = (e) => {
    console.log(e.target.value)
    setMethod(e.target.value);
  };

  const onModuleChange = (e) => {
    setModule(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // type: string
  const onShowTracker = (type) => {
    if (type === "p1")
      if (showTracker) setShowTracker(false);
      else setShowTracker(true);
    else if (showTracker2) setShowTracker2(false);
    else setShowTracker2(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "KRI-C1":
        setColor(props.selectedColor);
        break;
      case "KRI-C2":
        setColor2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("KRI")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.KRI")} (KRI) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("KRI")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
          id="msi-ta-KRI"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KRI-p0-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p1")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 1</div>

            <div className="inputbox taparam">
              <input
                id="msi-ta-UI-p0-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "KRI-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-UI-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enable2 ? {} : { opacity: "0.5" }}
          id="msi-ta-KRI"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KRI-p1-enabled"
              className={enable2 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p2")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 2</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-UI-p0-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "KRI-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-UI-p0-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="floatmsibox" style={{ display: "flex" }}>
          <div className="menusubitem" id="msi-ta-KRI">
            <div className="taparamlbl">{t("chart5.averagePeriod")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-KRI-ma"
                value={method}
                onChange={(e) => onMethodChange(e)}
              >
                <option value="ema">EMA</option>
                <option value="sma">SMA</option>
                <option value="mema">MEMA</option>
                <option value="wma">WMA</option>
              </select>
            </div>
          </div>
          <div className="menusubitem" id="msi-ta-KRI">
            <div className="taparamlbl">{t("chart5.coefficient")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-KRI-c"
                defaultValue={module}
                onChange={(e) => onModuleChange(e)}
                value={module}
              >
                <option value="close">{t("chart5.last")}</option>
                <option value="volume">{t("chart5.volume")}</option>
              </select>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                defaultValue={height}
                onChange={(e) => onHeightChange(e)}
                value={height}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import resources from './asserts/locales/resources'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

let localLang = localStorage.getItem('lang')
const browserLang = navigator.language

if (!localLang) {
  localLang = browserLang === 'zh-CN' ? 'zh-CN' : 'en-US'
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localLang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
export default i18n

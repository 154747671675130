import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react";
import APPstyles from '@/App.less'
import styles from './index.less'

class Tabs extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            hoverStatus: this.props.tabData.map(() => false),
            tabIndex:this.props.currentIndex ? this.props.currentIndex : 0,
            tabItemData:[],
            disableIndex2: this.props.disableIndex2 !== undefined ? this.props.disableIndex2 : true
        }
    }
    //tab栏切换
    tabClick(item,index){
        const {tag}=this.props
        this.setState({
            tabIndex:index
        })
        this.props.callbackTabIndex(index)
        if(tag=='quote'){
            let itemData={'item-0':'1','item-1':'2','item-2':'3','item-3':'4'}
            let id=item['id']
            let chgId=typeof(id)=='undefined'?1:itemData[id]
            this.props.cbWatchlistId(chgId)
        }
    }
    handleMouseOver = (index) => {
        this.setState(prevState => ({
            hoverStatus: prevState.hoverStatus.map((status, i) => (i === index ? true : status))
        }));
    };
    handleMouseOut = (index) => {
        this.setState(prevState => ({
            hoverStatus: prevState.hoverStatus.map((status, i) => (i === index ? false : status))
        }));
    };
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {tabData,currentIndex,tag,isDetailPageShow,isMobile,isTablet,closeRightPageShow,chgTabData,isGotoPageShow}=this.props  
        //更改tab的数据
        if(tabData!==newProps['tabData']){
            this.setState({
                tabItemData:tabData
            });
        }
        if(tag=='quote'&&currentIndex!==newProps['currentIndex']){
            this.setState({
                tabIndex:currentIndex
            })
        }
        if(tag=='detail'&&isDetailPageShow!==newProps['isDetailPageShow']&&isMobile){
            this.setState({
                tabIndex:0
            })
        }
        if(tag=='detail'&&chgTabData!==newProps['chgTabData']&&isTablet){
            this.setState({
                tabIndex:0
            })
        }
        // 切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
        if(closeRightPageShow!==newProps['closeRightPageShow']){
            this.setState({
                tabIndex: 0
            })
        }
        if(tag=='account'&&isGotoPageShow!==newProps['isGotoPageShow']){
            this.setState({
                tabIndex: 1
            })
        }
    }
    //初始化
    componentDidMount(){
        const {tabData}=this.props
        this.setState({
            tabIndex:0
        })
        this.setState({
            tabItemData:tabData
        });
    }
    render(){
        const {t,tag,ChgThemeData,chgTabNumData,bodyW,mobilebodyW,isMobile} = this.props
        const {tabItemData,tabIndex,hoverStatus}=this.state 
        return (
            <div
                className={[
                    APPstyles.flex_row,
                    styles.tabs_con,
                    tag=='quote'||tag=='account'?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                    tag=='quote'||tag=='account'?styles.tabs_con_account:''
                ].join(' ')}
                style={{
                    paddingRight:tag=='quote'||(tag=='account'&&isMobile)?0:16,
                    paddingLeft:tag=='quote'||tag=='account'||tag=='news'?0:16
                }}
            >
                <div className={[APPstyles.flex_row,styles.tab_one].join(' ')}>
                {
                    tabItemData.map((item,index) => {
                        return  <div key={index}>
                                    <span 
                                        className={[
                                            styles.tab_seatnot,
                                            tag=='quote'?styles.tab_quote:'',
                                            index===tabIndex||hoverStatus[index]?styles.active:'',
                                            (tag=='quote'||tag=='account')&&bodyW<=mobilebodyW?APPstyles.fz16:'',
                                            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:'',
                                            tag=='detail'&&isMobile?APPstyles.fz16:''
                                            
                                        ].join(' ')}
                                        onClick={this.tabClick.bind(this,item,index)} 
                                        onMouseOver={this.handleMouseOver.bind(this,index)} 
                                        onMouseOut={this.handleMouseOut.bind(this,index)} 
                                    >
                                        {
                                            tag=='account'?
                                                <label>{t(item['name'])}{index==0?'('+chgTabNumData[0]+')':index==1?'('+chgTabNumData[1]+')':index==2?'('+chgTabNumData[2]+')':''}</label>
                                            :
                                                t(item['name'])
                                        }
                                    </span>
                                    <span 
                                        className={[
                                            styles.tab_seat,
                                            (tag=='quote'||tag=='account')&&bodyW<=mobilebodyW?APPstyles.fz16:''
                                        ].join(' ')}
                                    >
                                        {
                                            tag=='account'?
                                                <label>{t(item['name'])}{index==0?'('+chgTabNumData[0]+')':index==1?'('+chgTabNumData[1]+')':index==2?'('+chgTabNumData[2]+')':''}</label>
                                            :
                                                t(item['name'])
                                        }
                                    </span>
                                </div>
                    })
                 }
                </div>
            </div>
        )}
}
export default withTranslation()(observer(Tabs))
import React from 'react'
import { withTranslation } from 'react-i18next'
import { message,Spin } from 'antd'
import common from '@/asserts/js/common'
import { 
  closeImg,
  deleteImg
} from '@/asserts/imgs/common/index'
import TipsModal from '@/components/tipsModal/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let chgPage=1

class Notclosed extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      history:[],
      isLoading:false,
      country_code:'US',
      stock_id:null,
      delData:[],
      page:1,
      isonLoadShow:true,
      isDelShow:false,
      statusData:[
        {
          status:10,
          content:'Sending order in progress'
        },
        {
          status:15,
          content:'Order received'
        },
        {
          status:16,
          content:'Order pending'
        },
        {
          status:30,
          content:'Order placement successful'
        },
        {
          status:50,
          content:'Cancelling order in progress'
        },
        {
          status:70,
          content:'Cancelling order in progress'
        },
        {
          status:75,
          content:'Cancellation order received'
        },
        {
          status:76,
          content:'Pending cancellation'
        },
        {
          status:96,
          content:'Order placement failed'
        },
        {
          status:97,
          content:'Partial transaction completed'
        },
        {
          status:98,
          content:'Transaction completed'
        },
        {
          status:99,
          content:'Order cancellation successful'
        },
        {
          status:0,
          content:'Order sent'
        }
      ],
      lastStatus22:'Order cancellation failed',
      isdelStatusShow:false,
      errMsgData:['H7','K7','LB','LC'],
      loadMore:false,
      noMore:false,
      chgSort:'time_desc',
      minW1600:1600,
      getorderSource:'',
      isDelData:'N',
      oneData:[],
      orderData:[],
      dealData:[]
    }
  }
  //modal--open--是否删除数据
  openModalClick(data,e) {
    //阻止点击事件冒泡
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      isDelShow:true,
      delData:data,
      getorderSource:data['order_source']
    })
  }
  //排序
  sortClick(name,tag,sortName){
    const {sortData,ChgThemeData}=this.props
    const {country_code,stock_id,history,chgSort,isLoading}=this.state
    if(history.length<=0||chgSort==tag||isLoading) return
    this.setState({
      isLoading: true,
      chgSort:tag,
      loadMore:false,
      noMore:false,
      page:1
    })
    let newData=common.getSortDataFun(sortData,ChgThemeData,name,sortName)
    chgPage=1
    this.getTableData(country_code,stock_id,tag,1,[])
    this.props.cbChgSortDataFun(newData)
  }
  executeRemoveOrder() {
    const {delData,country_code,stock_id,chgSort,getorderSource}=this.state
    chgPage=1
    this.setState({
      page:1
    })
    if (common.enableWebca) {
      //SIGN WITH CERT WHEN CLICKED SIGN BUTTON
      const userid = window.sessionStorage.getItem('uid');
      const certFilter = "//S_CN=" + userid + "//";
      const signContent = "C|" + (getorderSource=='gtc_order'?delData['gtc_order_no']:delData['oid']) + "|" + delData['stock_id'] + "|" + delData['mapping_id'] + "|" + delData['direction'];

      if (window.inAppDetector.isInApp()) {
        //IF IT IS IN WEBVIEW, SIGN WITH CERT IN APP
        window.mtklib.sign({
          "idno": userid,
          "tosign": signContent,
          "method": "P1",
          "cb": (cbobj) => {
            if (cbobj.code == "0") {
              let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
              let obj=getorderSource=='gtc_order'?
                        {
                          country_code: delData['country'],
                          gtc_order_no: delData['gtc_order_no'],
                          mapping_id: delData['mapping_id'],
                          stock_id: delData['stock_id'],
                          buysell: delData['direction'],
                          token: window.sessionStorage.getItem("token"),
                          sign_type: "pkcs1",
                          signature: cbobj.signed,
                          src: signContent,
                          sn: cbobj.casno
                        }
                      :
                        {
                          country_code: delData['country'],
                          oid: delData['oid'],
                          stock_id: delData['stock_id'],
                          buysell: delData['direction'],
                          token: window.sessionStorage.getItem("token"),
                          sign_type: "pkcs1",
                          signature: cbobj.signed,
                          src: signContent,
                          sn: cbobj.casno
                        }
              
              fetch(common.getHttpZs + api, {
                method: "POST",
                headers: {
                  'Accept': 'application/json,text/plain,*/*',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
              })
                .then((res) => res.json())
                .then((result) => {
                  if (result['status'] == 0) {
                    this.getTableData(country_code, stock_id, chgSort,1,[])
                    return
                  }
                  //获取失败
                  this.setState({
                    delData: [],
                    isLoading: false
                  })
                  let error = result['error'];
                  if (error) {
                    if (error['code']) {
                      error = error['code'] + " " + error['msg']
                    } else {
                      error = error['Status'] + " " + error['ErrMsg']
                    }
                  } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
                    error = result['data']['status'] + " " + result['data']['msg']
                  } else {
                    error = result['message'];
                  }
                  message.warning({
                    content: error
                  })
                });
            } else {
              //获取失败
              this.setState({
                delData: [],
                isLoading: false
              })
              message.warning({
                content: cbobj.code + " " + cbobj.desc
              })
            }
          }
        })
      } else {
        //執行PKCS7簽章
        window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
          if (code === 0 && data) {
            var certData = JSON.parse(data);
            let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
            let obj=getorderSource=='gtc_order'?
                      {
                        country_code: delData['country'],
                        gtc_order_no: delData['gtc_order_no'],
                        mapping_id: delData['mapping_id'],
                        stock_id: delData['stock_id'],
                        buysell: delData['direction'],
                        token: window.sessionStorage.getItem("token"),
                        signature: certData.signature,
                        data: signContent,
                        subject: certData.subdn
                      }
                    :
                      {
                        country_code: delData['country'],
                        oid: delData['oid'],
                        stock_id: delData['stock_id'],
                        buysell: delData['direction'],
                        token: window.sessionStorage.getItem("token"),
                        signature: certData.signature,
                        data: signContent,
                        subject: certData.subdn
                      }
            
            fetch(common.getHttpZs + api, {
              method: "POST",
              headers: {
                'Accept': 'application/json,text/plain,*/*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(obj)
            })
              .then((res) => res.json())
              .then((result) => {
                if (result['status'] == 0) {
                  this.getTableData(country_code, stock_id, chgSort,1,[])
                  return
                }
                //获取失败
                this.setState({
                  delData: [],
                  isLoading: false
                })
                let error = result['error'];
                if (error) {
                  if (error['code']) {
                    error = error['code'] + " " + error['msg']
                  } else {
                    error = error['Status'] + " " + error['ErrMsg']
                  }
                } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
                  error = result['data']['status'] + " " + result['data']['msg']
                } else {
                  error = result['message'];
                }
                message.warning({
                  content: error
                })
              });
          } else if (code === 5067 || code === "5067") {
            //憑證挑選
            window.twcaLib.SelectSignerFubon(userid, certFilter, "", "", "", 0x04 | 0x08, 0, null, (code, msg, t, data) => {
              if (code === 0) {
                //執行PKCS7簽章
                window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
                  if (code === 0 && data) {
                    var certData = JSON.parse(data);
                    let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
                    let obj=getorderSource=='gtc_order'?
                              {
                                country_code: delData['country'],
                                gtc_order_no: delData['gtc_order_no'],
                                mapping_id: delData['mapping_id'],
                                stock_id: delData['stock_id'],
                                buysell: delData['direction'],
                                token: window.sessionStorage.getItem("token"),
                                signature: certData.signature,
                                data: signContent,
                                subject: certData.subdn
                              }
                            :
                              {
                                country_code: delData['country'],
                                oid: delData['oid'],
                                stock_id: delData['stock_id'],
                                buysell: delData['direction'],
                                token: window.sessionStorage.getItem("token"),
                                signature: certData.signature,
                                data: signContent,
                                subject: certData.subdn
                              }
                    
                    fetch(common.getHttpZs + api, {
                      method: "POST",
                      headers: {
                        'Accept': 'application/json,text/plain,*/*',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(obj)
                    })
                      .then((res) => res.json())
                      .then((result) => {
                        if (result['status'] == 0) {
                          this.getTableData(country_code, stock_id, chgSort,1,[])
                          return
                        }
                        //获取失败
                        this.setState({
                          delData: [],
                          isLoading: false
                        })
                        let error = result['error'];
                        if (error) {
                          if (error['code']) {
                            error = error['code'] + " " + error['msg']
                          } else {
                            error = error['Status'] + " " + error['ErrMsg']
                          }
                        } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
                          error = result['data']['status'] + " " + result['data']['msg']
                        } else {
                          error = result['message'];
                        }
                        message.warning({
                          content: error
                        })
                      });
                  } else {
                    // this.setState({
                    //   isLoading: false
                    // })
                    message.warning({
                      content: code + " " + msg
                    })
                  }
                });
              } else {
                // this.setState({
                //   isLoading: false
                // })
                message.warning({
                  content: code + " " + msg
                })
              }
            });
          } else {
            // this.setState({
            //   isLoading: false
            // })
            message.warning({
              content: code + " " + msg
            })
          }
        });
      }
    } else {
      let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
      let obj=getorderSource=='gtc_order'?
                {
                  country_code: delData['country'],
                  gtc_order_no: delData['gtc_order_no'],
                  mapping_id: delData['mapping_id'],
                  stock_id: delData['stock_id'],
                  buysell: delData['direction'],
                  token: window.sessionStorage.getItem("token"),
                  signature: "dev",
                  data: "dev",
                  subject: "dev"
                }
              :
                {
                  country_code: delData['country'],
                  oid: delData['oid'],
                  stock_id: delData['stock_id'],
                  buysell: delData['direction'],
                  token: window.sessionStorage.getItem("token"),
                  signature: "dev",
                  data: "dev",
                  subject: "dev"
                }
      
      fetch(common.getHttpZs + api, {
        method: "POST",
        headers: {
          'Accept': 'application/json,text/plain,*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      })
      .then((res) => res.json())
      .then((result) => {
        if(result['status']==0){
          this.getTableData(country_code,stock_id,chgSort,1,[])
          return
        }
        //获取失败
        this.setState({
          delData: [],
          isLoading:false
        })
        let error = result['error'];
        if (error) {
          if (error['code']) {
            error = error['code'] + " " + error['msg']
          } else {
            error = error['Status'] + " " + error['ErrMsg']
          }
        } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
          error = result['data']['status'] + " " + result['data']['msg']
        } else {
          error = result['message'];
        }
        message.warning({
          content: error
        })
      });
    }
  }
  //删除
  removeOrder() {
    //WebCA
    if (common.enableWebca && !window.inAppDetector.isInApp()) {
      const memberid = window.sessionStorage.getItem('uid');
      const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
      window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
        if (code === 0 && data) {
          let d = JSON.parse(data);
          let rafunc = d.suggestAction;

          if (rafunc !== "None") { // Need to apply/renew cert
            const webcaInfoRequestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ token: window.sessionStorage.getItem('token'), rafunc: rafunc })
            };

            //Get parameters for calling WebCAInvoke
            fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
              .then(response => response.json())
              .then(data => {
                if (data.status === 0) {
                  data = data.data;
                  // window.setWebCAinWebview(true);
                  window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                  //呼叫WebCA執行對應raFunc的憑證作業
                  window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                    if (code===0 || code==="0000" || code===7000) {
                      this.executeRemoveOrder();
                    } else {
                      // this.setState({
                      //   isLoading: false
                      // })
                      message.warning({
                        content: code + " " + msg
                      })
                    }
                    // window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
                    //   d = JSON.parse(data);
                    //   rafunc = d.suggestAction;
                    //   if (rafunc === "None") {
                    //     this.executeRemoveOrder();
                    //   } else {
                    //     // this.setState({
                    //     //   isLoading: false
                    //     // })
                    //     message.warning({
                    //       content: "WebCA failed"
                    //     })
                    //   }
                    // });
                  });
                } else {
                  // this.setState({
                  //   isLoading: false
                  // })
                  message.warning({
                    content: data.message
                  })
                }
              })
          } else {
            this.executeRemoveOrder();
          }
        } else {
          // this.setState({
          //   isLoading: false
          // })
          message.warning({
            content: code + " " + msg
          })
        }
      });
    } else {
      this.executeRemoveOrder();
    }
  }
  //获取数据API
  getTableData(country_code,stock_id,sort,page,listsData,tag) {
    const {isonLoadShow}=this.state
    page=Number(page)
    fetch(common.getHttpZs+'getTodayOrder', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_code: country_code,
        stock_id: stock_id,
        recperpage: 20,
        page: page,
        sort:sort,
        token: sessionStorage.getItem("token")
      })
    })
    .then((res) => res.json())
    .then((result) => {
      this.setState({
        isDelData: 'N'
      })
      if(result['status']==0){
        if(JSON.stringify(result['data']) !== '{}'){
          let datalist=result['data']['datalist']
          let getRicData=[]
          // 初始化执行
          if(isonLoadShow){
            this.setState({
              isonLoadShow: false,
              oneData:datalist
            })
          }
          if(datalist.length){
            for(let i in datalist){
              getRicData.push(datalist[i]['stock_id'])
            }
            if(getRicData.length==datalist.length){
              getRicData= [...new Set(getRicData)]//去重
              this.getsimplequoteFun(getRicData,datalist,listsData,page,tag)
            }
          }else{
            if(listsData.length){
              this.setState({
                isLoading:false,
                loadMore:false,
                noMore:true
              })
            }else{
              this.noDataFun()
            }
          }
        }else{
          this.noDataFun()
        }
      }else{
        this.noDataFun()
      }
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(ric,datalist,listsData,page,tag){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      //获取数据成功
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        if(JSON.stringify(resData)!=='{}'){
          for(let i in datalist){
            for(let j in ric){
              if(datalist[i]['stock_id']==ric[j]){
                let data=resData[ric[j]]
                datalist[i]['stock_code']=data['symbol']
                datalist[i]['stock_name']=data['name']
                datalist[i]['country']=data['exchange_country_code']
              }
            }
          }
          this.commonDataFun(datalist,listsData,page,tag)
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err)
      this.commonDataFun(datalist,listsData,page,tag)
    })
  }
  commonDataFun(datalist,listsData,page,tag){
    const {ChgLangData,isTablet}=this.props
    const {statusData,lastStatus22,errMsgData}=this.state
    let pn1=datalist.length?datalist.length>=10?false:true:false
    let pnother=listsData.length?datalist.length?false:true:true
    let noMoreData=page==1?pn1:pnother
    //更改status为对应的文字
    if(datalist.length){
      for(let i in datalist){
        datalist[i]['closeImg']=closeImg
        let error_msg=datalist[i]['error_msg']
        if(error_msg){
          let get_error_msg=error_msg.split(' ')
          let msgTag=get_error_msg[1].slice(4,6)

          if(errMsgData.includes(msgTag)){
            let datamsg1=error_msg.slice(17)
            let datamsg2=datamsg1.slice(0,-1)
            datalist[i]['new_error_msg']=datamsg2
          }else{
            let datamsg=error_msg.split(' ')[2]
            let datamsg1=datamsg.slice(9)
            let datamsg2=datamsg1.slice(0,-1)
            let datamsg3=datamsg.slice(4,8)
            datalist[i]['new_error_msg']=datamsg3+': '+datamsg2
          }
        }
        for(let j in statusData){
          if(Number(datalist[i]['status'])==statusData[j]['status']){

            datalist[i]['status_content']=Number(datalist[i]['status'])==30&&Number(datalist[i]['last_status'])==22?lastStatus22:statusData[j]['content']
            datalist[i]['status_content']=Number(datalist[i]['status'])==70?ChgLangData=='zh-CN'?'Cancelling order in progress1':statusData[j]['content']:statusData[j]['content']
          }
        }
        let qty=datalist[i]['qty']
        let qty_filled=datalist[i]['qty_filled']
        if (qty&& qty!== '-') {
          datalist[i]['qty_len']=Number(String(qty).split('.')[0].length+2)
        }
        if (qty_filled&&qty_filled !== '-') {
          datalist[i]['qty_filled_len']=Number(String(qty_filled).split('.')[0].length+2)
        }
      }
    }
    let newData=isTablet?listsData.concat(datalist):datalist
    this.dealDataFun(newData,noMoreData,tag)
  }
  // 获取失败
  noDataFun(){
    this.setState({
      history: [],
      isLoading:false,
      loadMore:false,
      noMore:false
    })
    this.props.callbackTabNumData([0,0])
  }
  //重新整理
  refreshDataFun(tag){
    this.setState({
      isLoading:true,
      country_code:'US',
      chgSort:'time_desc',
      stock_id:null,
      page:1
    })
    chgPage=1
    this.getTableData('US',null,'time_desc',1,[],tag)
    this.props.cbChgSortDataFun([])
  }
  //滚动加载更多数据
  scrollMoreDataFun(){
    const {loadMore,noMore,isLoading,country_code,stock_id,history,chgSort}=this.state
    if(loadMore||noMore||isLoading) return
    chgPage++
    this.setState({
      page: chgPage,
      loadMore:true,
      noMore:false
    })
    this.getTableData(country_code,stock_id,chgSort,chgPage,history)
  }
  // 恢复最初的数据
  reSetDataFun(){
    chgPage=1
    this.setState({
      isLoading:true,
      page:1,
      country_code:'US',
      chgSort:'time_desc',
      stock_id:null
    })
    this.getTableData('US',null,'time_desc',1,[])
    this.props.cbChgSortDataFun([])
  }
  dealDataFun(data,noMoreData,tag){
    const {chgTab,checkedData}=this.props
    let orderDatas=[],dealDatas=[]
    for(let i in data){
      // dealDatas
      if(Number(data[i]['status'])==98||Number(data[i]['status'])==97){
        dealDatas.push(data[i])
      }
      // orderDatas
      if(Number(data[i]['status'])==97){
        if(Number(data[i]['qty'])>Number(data[i]['qty_filled'])){
          orderDatas.push(data[i])
        }
      }else{
        if(Number(data[i]['status'])!==98){
          orderDatas.push(data[i])
        }
      }
    }
    let newData=chgTab=='N'?orderDatas:dealDatas
    if(tag=='reload'){
      this.checkedDataFun(checkedData,newData,tag)
    }else{
      this.setState({
        history:newData
      })
      this.isdelStatusShowFun(newData)
    }
    this.setState({
      isLoading:false,
      loadMore:false,
      noMore:noMoreData,
      orderData:orderDatas,
      dealData:dealDatas
    })
    this.props.callbackTabNumData([orderDatas.length,dealDatas.length])
  }
  // 筛选
  checkedDataFun(checkedData,newData,tag){
    const {chgTab}=this.props
    const {isLoading,orderData,dealData}=this.state
    if(tag!=='reload'&&isLoading) return
    let allData=tag=='reload'?newData:chgTab=='N'?orderData:dealData
    let getData=[]
    if(checkedData.length==0){
      this.setState({
        history:allData
      })
      this.isdelStatusShowFun(allData)
      return
    }
    let chkeckDataL=checkedData&&checkedData['chkeckDataL']
    let chkeckDataR=checkedData&&checkedData['chkeckDataR']
    let chkeckDataLen=chkeckDataL.length+chkeckDataR.length
    if(chkeckDataL.length==0||chkeckDataR.length==0){
      this.setState({
        history:allData
      })
      this.isdelStatusShowFun(allData)
      return
    }
    // 全选/全不选
    if(chkeckDataLen==0||chkeckDataLen==6){
      this.setState({
        history:allData
      })
      this.isdelStatusShowFun(allData)
    // 单选
    }else{
      for(let i in allData){
        for(let j in chkeckDataL){
          for(let k in chkeckDataR){
            if(Number(allData[i]['status'])==chkeckDataR[k]&&allData[i]['direction']==chkeckDataL[j]){
              if(Number(allData[i]['status'])==30&&Number(allData[i]['last_status'])!==22){
                getData.push(allData[i])
              }else{
                getData.push(allData[i])
              }
            }
          }
        }
      }
      getData= [...new Set(getData)]//去重
      this.setState({
        history:getData
      })
      this.isdelStatusShowFun(getData)
    }
  }
  // 顯示刪單按鈕
  isdelStatusShowFun(data){
    this.reSetDelStatusFun()
    for(let i in data){
      if(data[i]['can_cancel']=='Y'){
        this.setState({
          isdelStatusShow:true
        })
      }
    }
  }
  reSetDelStatusFun(){
    this.setState({
      isdelStatusShow:false
    })
  }
  // 数组去重
  newDataFun(arr){
    let map = new Map()
    let result = []
    for (let item of arr) {
      if (!map.has(item['status'])) {
        map.set(item['status'], true)
        result.push(item)
      }
    }
    return result
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {ChgLangData,isReload,isScrollShow,isShowPageData,IsSubmitOrder,checkedData,isCheck,chgTab,isTablet,isPC,currentIndex,isTradeMinbody,accountTradeData,isReLoadOrder}=this.props
    const {history,statusData,isLoading,oneData,isonLoadShow,orderData,dealData}=this.state
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    //更改语言
    if(ChgLangData!==newProps['ChgLangData']){
      //更改status为对应的文字
      if(history.length){
        for(let i in history){
          for(let j in statusData){
            if(Number(history[i]['status'])==statusData[j]['status']){
              history[i]['status_content']=Number(history[i]['status'])==70?ChgLangData=='zh-CN'?'Cancelling order in progress1':statusData[j]['content']:statusData[j]['content']
            }
          }
        }
        this.setState({
          history: history
        })
      }
    }
    //未完成/已完成
    if(chgTab!==newProps['chgTab']){
      this.setState({
        history:chgTab=='N'?orderData:dealData
      })
    }
    // 筛选
    if(isCheck!==newProps['isCheck']){
      if(checkedData['chkeckDataL'].length>0&&checkedData['chkeckDataR'].length>0){
        this.checkedDataFun(checkedData,'','')
      }else{
        this.setState({
          history:chgTab=='N'?orderData:dealData
        })
      }
    }
    // 重新整理
    if(isReload!==newProps['isReload']){
      if(!isLoading){
        this.refreshDataFun('reload')
      }
    }
    // 提交订单刷新数据
    if(IsSubmitOrder!==newProps['IsSubmitOrder']){
      if(!isLoading&&!isonLoadShow){
        this.reSetDataFun()
      }
    }
    // 滚动加载更多
    if(isScrollShow!==newProps['isScrollShow']){
      if(!isLoading&&oneData.length>20){
        this.scrollMoreDataFun()
      }
    }
    // trade--弹框
    if(navId=='trade'){
      if(isShowPageData&&(isShowPageData[0]!==newProps['isShowPageData'][0])){
        if(isShowPageData[0]){
          this.setState({
            history:[]
          })
          this.reSetDataFun()
        }
      }
    }
    //quote页面--isTablet
    if(navIdAPP=='quote'&&isTradeMinbody!==newProps['isTradeMinbody']){
      if(isTablet||(isPC&&(currentIndex==2||currentIndex==3))){
        this.setState({
          isLoading:true
        })
        this.reSetDataFun()
      }
    }
    //account --trade弹框
    if(navIdAPP=='account'&&accountTradeData!==newProps['accountTradeData']){
      if(JSON.stringify(accountTradeData[0])!=='{}'){
        this.reSetDataFun()
      }
    }
    // 切换账号成功则重新刷新trade页面里的order数据
    if(isReLoadOrder!==newProps['isReLoadOrder']){
      this.reSetDataFun()
    }
  }
  //初始化
  componentDidMount() {
    const {isRightPageShow,isTablet,currentIndex,isShowPageData}=this.props
    let navId=sessionStorage.getItem('navId')
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    
    if(navIdAPP=='quote'){
      if(navId=='quote'){
        if(isRightPageShow=='trade'||isTablet||(currentIndex==2||currentIndex==3)){
          this.reSetDataFun()
        }
      }else{
        this.reSetDataFun()
      }
    }else{
      if(isShowPageData&&isShowPageData[0]){
        this.reSetDataFun()
      }
    }
  }
  render(){
    const {t,ChgThemeData,ChgLangData,sortData}=this.props
    const {history,isLoading,isdelStatusShow,isDelShow,delData,loadMore,noMore}=this.state
    return (
      <div 
        className={[
          styles.list_con,
          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
        ].join(' ')}
      >
        <div
          className={[
            APPstyles.flex_row,
            APPstyles.flex_topcenter,
            styles.list_header,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          {/* 第1列 */}
          <div style={{width:'28%',alignItems:'center'}} className={APPstyles.flex_row}>
            <div className={APPstyles.flex_column}>
              <div style={{marginBottom:2}}>{t('Direction')}</div>
              <div>{t('Status')}</div>
            </div>
            <div 
              className={[
                APPstyles.upDown_con,
                APPstyles.flex_column
              ].join(' ')}
            >
              <div 
                className={APPstyles.img_dropdown_up}   
                onClick={()=>{this.sortClick('direction','direction_asc','asc')}}
              >
                <img src={sortData[1]['upImg']} style={{bottom:3}} />
              </div>
              <div 
                className={APPstyles.img_dropdown_down} 
                onClick={()=>{this.sortClick('direction','direction_desc','desc')}}
              >
                <img src={sortData[1]['downImg']} style={{bottom:5}} />
              </div>
            </div>
          </div>
          {/* 第2列 */}
          <div style={{width:isdelStatusShow?'22%':'29%'}}>{t(ChgLangData == 'zh-CN' ? 'name' : 'Name')}</div>
          {/* 第3列 */}
          <div style={{width:'24%',textAlign:'right'}}>{t('Order Price')}</div>
          {/* 第4列 */}
          <div className={APPstyles.flex_column} style={{width:'19%',textAlign:'right'}}>
            <span>{t('QTY')}</span>
            <span>{t('QTY Filled1')}</span>
          </div>
          {/* 第5列 */}
          <div style={{width:'7%',display:isdelStatusShow?'':'none'}}>&nbsp;</div>
        </div>
        <div style={{position:'relative',minHeight:history.length?'auto':100}}>
          {/* 加载中 */}
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length?
            history.map((item,index) => {
              return  <div
                        key={index}
                        className={[
                          APPstyles.flex_row,
                          APPstyles.flex_topcenter,
                          styles.list_body,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                        ].join(' ')}
                      >
                      {/* 第1列 */}
                      <div style={{width:'28%'}} className={APPstyles.flex_column}>
                          <span className={item['direction']=='B'?APPstyles.color_red:APPstyles.color_green} style={{marginBottom:3}}>
                            {t(item['direction']=='B'?'Buy':item['direction']=='S'?'Sell':'')}
                          </span>
                          <span className={APPstyles.fz14}>{t(item['status_content'])}</span>
                        </div>
                        {/* 第2列 */}
                        <div style={{width:isdelStatusShow?'22%':'29%'}} className={APPstyles.flex_column}>
                          <span style={{marginBottom:2}} className={APPstyles.fw700}>
                            {item['stock_code']}
                          </span>
                          <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.ellipsis,APPstyles.fz14].join(' ')}>{item['stock_name']}</span>
                        </div>
                        {/* 第3列 */}
                        <div style={{width:'24%',textAlign:'right'}}>
                          {common.transThousands(common.filterErro(item['order_price']))}
                        </div>
                        {/* 第4列 */}
                        <div style={{width:'19%',textAlign:'right'}} className={APPstyles.flex_column}>
                          <span 
                            style={{marginBottom:2}}
                            className={
                              item['qty_len']>6?APPstyles.fz12:item['qty_len']==6?APPstyles.fz13:APPstyles.fz14
                            }
                          >
                            {common.transThousands(common.keepNum(item['qty'],0))}
                          </span>
                          <span
                            className={
                              item['qty_filled_len']>6?APPstyles.fz12:item['qty_filled_len']==6?APPstyles.fz13:APPstyles.fz14
                            }
                          >
                            {common.transThousands(common.keepNum(item['qty_filled'],0))}
                          </span>
                        </div>
                        {/* 第5列 */}
                        <div style={{width:'7%',textAlign:'right',display:isdelStatusShow?'':'none'}}>
                          <img 
                            src={deleteImg} 
                            alt='del' 
                            className={APPstyles.img24} 
                            style={{
                              display:item['can_cancel']=='Y'?'':'none'
                            }}
                            onClick={this.openModalClick.bind(this, item)} 
                          />
                        </div>
                      </div>
              })
            :
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>     
          }
          <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
          <div className={APPstyles.loading_more} style={{display:noMore&&history.length>20?'':'none'}}>- {t('No More Data Available')} -</div>
        </div>
        {isDelShow&&
          <TipsModal
            {...this.props}
            delData={delData}
            tag={'todayorder'}
            cancelText={'Cancel'}
            sureText={'Confirm'} 
            cbCloseBtnFun={(data)=>{
              this.setState({
                isDelShow:false,
                isDelData:data
              })
              //删除某条数据
              if(data=='Y'){
                this.setState({
                  isLoading: true
                })
                this.removeOrder()
              }
            }}
          />
        }
      </div>
  )}
}
export default withTranslation()(Notclosed)
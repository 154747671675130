import React from 'react'
import { withTranslation } from 'react-i18next'
import { 
  leftImg,
  leftLightImg
} from '../../../../asserts/imgs/common/index'
import PieCon from '../Pie/index'
import MsgCon from '../msg/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPieShow:false
    }
  }
  moreClick(){
    const {isPieShow}=this.state
    this.setState({
      isPieShow:!isPieShow
    })
    this.props.cbGobackTop(isPieShow)
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,isMobile}=this.props
    if(bodyW!==newProps['bodyW']){
      this.setState({
        isPieShow:isMobile?false:true
      })
    }
  }
  render() {
    const {isTablet,isMobile,isSearchPageShow,ChgThemeData,isPC} = this.props
    const {isPieShow}=this.state
    return (
        <div className={styles.header}>
          <div
            className={isTablet||(isPC&&isSearchPageShow)?APPstyles.flex_column:APPstyles.flex_row}
            style={{
              position:'relative',
              alignItems:'flex-start'
            }}
          >
            <MsgCon
              {...this.props}
              isPieShow={isPieShow}
              cbChgMarketDataFun={(data)=>{
                this.props.cbChgMarketDataFun(data)
              }}
              cbPortDetailShow={(data)=>{
                this.props.cbPortDetailShow(data)
              }}
            />
            <div className={[styles.header_line,ChgThemeData=='Light'?styles.header_line_light:''].join(' ')} style={{display:(isTablet&&!isMobile)||(isPieShow&&isMobile)||(isPC&&isSearchPageShow)?'':'none'}}><div></div></div>
            <div className={[styles.header_more,isPieShow?styles.header_more_up:''].join(' ')} style={{display:isMobile?'':'none'}}>
              <div onClick={this.moreClick.bind(this)}>
                <img 
                  src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                  className={[APPstyles.img24,isPieShow?styles.header_up:styles.header_down].join(' ')} 
                  alt='back' 
                />
              </div>
            </div>
            <PieCon
              {...this.props}
              id='PieId'
              isPieShow={isPieShow}
            />
          </div>
        </div>
    )
  }
}
export default withTranslation()(Header)
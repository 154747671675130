import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  refreshOnImg,
  refreshOnLightImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  dropUpImg,
  dropUpOnImg,
  dropUpOnLightImg
} from '@/asserts/imgs/common/index'
import DropDown from './DropDown/index'
import Order from './Order/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class List extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      sortData:[
        {
          name:'time',
          upImg:dropUpImg,
          downImg:dropDownImg
        },
        {
          name:'direction',
          upImg:dropUpImg,
          downImg:dropDownImg
        },
        {
          name:'status',
          upImg:dropUpImg,
          downImg:dropDownImg
        },
        {
          name:'payable_date',
          upImg:dropUpImg,
          downImg:dropDownImg
        }
      ],
      chgTab:'N',
      isDropDownShow:false,
      isRefresh:false,
      chgRefreshImg:refreshOnImg,
      isReload:false,
      chgTabNumData:[0,0],
      checkedData:[],
      isCheck:false
    }
  }
  // 跳转到委托回报页面
  gotoPageClick(){
    const {locationName}=this.props
    let pathName='/'+locationName[1]+'/Account'
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    this.props.callbackChgNavIndex(navIdAPP, true)

    if(navIdAPP=='account'){
      this.props.cbGotoPage_1()
      sessionStorage.setItem('navId','account')
    }else{
      this.props.callbackPathnameApp(pathName)// 获取路由跳转--app.js
      sessionStorage.setItem('navId','account')
      sessionStorage.setItem('navIdAPP','account')
      sessionStorage.setItem('isGotoPage','Y')
    }
  }
  // 切换列表
  switchClick(tag,e){
    e.stopPropagation()
    this.setState({
      chgTab:tag
    })
    this.resetSortDataFun()
  }
  //更新状态
  refreshClick(){
    const {ChgThemeData}=this.props
    const {isReload}=this.state
    let imgurl=ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg
    this.setState({
      isRefresh:true,
      chgRefreshImg:imgurl,
      isReload:!isReload
    })
    setTimeout(() => {
      this.setState({
        isRefresh:false
      })
    }, 1000)
  }
  // 筛选
  dropDownClick(e){
    const {isDropDownShow}=this.state
    e.stopPropagation()
    this.setState({
      isDropDownShow:!isDropDownShow
    })
  }
  // 更新对应的图片
  chgRefreshImgFun(){
    const {ChgThemeData}=this.props
    let imgUrl=ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg
      this.setState({
        chgRefreshImg:imgUrl
      })
  }
  //点击外部关闭下拉框
  handleClick = e => {
    if(!this.testComponent.contains(e.target)) {
      this.setState ({
        isDropDownShow:false
      })
    }
  }
  // 恢复原来的数据
  resetSortDataFun(){
    const {sortData}=this.state
    for(let i in sortData){
      sortData[i]['upImg']=dropUpImg 
      sortData[i]['downImg']=dropDownImg
      sortData[i]['sortName']=''
      sortData[i]['sortKey']='' 
    }
    this.setState({
      sortData:sortData
    })
  }
  // 更改对应排序的图片--ChgThemeData--更改
  chgSortDataImgFun(){
    const {ChgThemeData}=this.props
    const {sortData}=this.state
    for(let i in sortData){
      if(sortData[i]['sortName']==sortData[i]['name']){
        let getUpImg=sortData[i]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
        let getDownImg=sortData[i]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
        sortData[i]['upImg']=getUpImg
        sortData[i]['downImg']=getDownImg
      }
    }
    this.setState({
      sortData:sortData
    })
  }
  resetDataFun(){
    this.setState({
      chgTab:'N',
      checkedData:[],
      chgTabNumData:[0,0]
    })
    this.chgSortDataImgFun()
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {ChgThemeData,isShowPageData,IsSubmitOrder,accountTradeData,isTradeMinbody,isPC,isTablet,isMobile,currentIndex}=this.props
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')

    if(ChgThemeData!==newProps['ChgThemeData']){
      this.chgRefreshImgFun()
      this.chgSortDataImgFun()
    }
    // 提交订单刷新数据
    if(IsSubmitOrder!==newProps['IsSubmitOrder']){
      this.setState({
        chgTab:'N'
      })
      this.chgSortDataImgFun()
    }
    // trade--弹框
    if(navId=='trade'){
      if(isShowPageData&&(isShowPageData[0]!==newProps['isShowPageData'][0])){
        if(isShowPageData[0]){
          this.resetDataFun()
        }
      }
    }
    //quote页面
    if(navIdAPP=='quote'&&isTradeMinbody!==newProps['isTradeMinbody']){
      if((isTablet&&!isMobile)||(isPC&&(currentIndex==2||currentIndex==3))){
        this.resetDataFun()
      }
    }
    //account --trade弹框
    if(navIdAPP=='account'&&accountTradeData!==newProps['accountTradeData']){
      if(JSON.stringify(accountTradeData[0])!=='{}'){
        this.resetDataFun()
      }
    }
  }
  //初始化
  componentDidMount(){
    this.chgRefreshImgFun()
    document.addEventListener('click', this.handleClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }
  render(){
    const {t,ChgThemeData} = this.props
    const {chgTab,isDropDownShow,isRefresh,chgRefreshImg,isReload,sortData,chgTabNumData,checkedData,isCheck}=this.state
    return (
      <div style={{position: 'relative'}}>
        <div
          className={[
            styles.list_tab,
            APPstyles.flex_row,
            APPstyles.flex_between,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <Button 
            type='primary' 
            className={[APPstyles.btn_blue,APPstyles.bg_blue].join(' ')}
            onClick={this.gotoPageClick.bind(this)}
          >
            {t("To Today's Order")}
          </Button>
          <span className={chgTab=='N'&&APPstyles.color_blue} onClick={this.switchClick.bind(this,'N')}>
            {t('Order')}({chgTabNumData[0]}){chgTab=='N'&&<label />}
          </span>
          <span className={chgTab=='Y'&&APPstyles.color_blue} onClick={this.switchClick.bind(this,'Y')}>
            {t('Deal')}({chgTabNumData[1]}){chgTab=='Y'&&<label />}
          </span>
          <span onClick={this.refreshClick.bind(this)}>
            {t('Refresh')} 
            <img 
              alt='refresh'
              src={chgRefreshImg} 
              className={[
                APPstyles.img18,
                isRefresh?APPstyles.refresh_img:''
              ].join(' ')} 
            />
          </span>
          <span style={{marginRight:0}} onClick={this.dropDownClick.bind(this)}>
            {t('Filter')} 
            {isDropDownShow&&<img src={ChgThemeData=='Light'?dropUpImg:dropUpOnImg} className={APPstyles.img18} />}
            {!isDropDownShow&&<img src={ChgThemeData=='Light'?dropDownImg:dropDownOnImg} className={APPstyles.img18} />}
          </span>
        </div>
        <div style={{display:isDropDownShow?'':'none'}} ref = {testComponent => this.testComponent = testComponent}>
          <DropDown 
            {...this.props}
            chgTab={chgTab}
            isReload={isReload}
            cbCloseDropDownFun={()=>{
              this.setState({
                isDropDownShow:false
              })
            }}
            cbCheckedDataFun={(data)=>{
              this.setState({
                checkedData:data,
                isCheck:!isCheck
              })
            }}
          />
        </div>
        <Order
          {...this.props}
          sortData={sortData}
          isReload={isReload}
          chgTab={chgTab}
          isCheck={isCheck}
          checkedData={checkedData}
          cbChgSortDataFun={(data)=>{
            if(data.length==0){
              this.resetSortDataFun()
              return
            }
            this.setState({
              sortData:data
            })
          }}
          callbackTabNumData={(data)=>{
            this.setState({
              chgTabNumData:data
            })
          }}
        />
      </div>
  )}
}
export default withTranslation()(List)
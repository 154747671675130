import React from 'react'
import { withTranslation } from 'react-i18next'
import APPstyles from '@/App.less'
import styles from './index.less'

class Disclaimer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    const {t,isTablet,isMobile,isSearchPageShow} = this.props
    return (
      <div 
        className={[APPstyles.flex_column,styles.disclaimer_con].join(' ')}
        style={{paddingTop:(isTablet&&!isMobile&&isSearchPageShow)?0:30}} 
      >
        <div className={APPstyles.flex_row}>
          <span>1.&nbsp;&nbsp;</span>
          <span>{t('All market costs for overseas stock markets are calculated using the moving average method.')}</span>
        </div>
        <div className={APPstyles.flex_row}>
          <span>2.&nbsp;&nbsp;</span>
          <span>{t('The US stock market shows real-time unrealized gains and losses, including the buying commission fees, but does not include exchange fees, transaction taxes, and other related expenses. The content of gains and losses is for reference only.')}</span>
        </div>
        <div className={APPstyles.flex_row}>
          <span>3.&nbsp;&nbsp;</span>
          <span>{t('The profit and loss calculated for stocks in Japan, Hong Kong, Singapore, the United Kingdom, Germany and China’s Shanghai and Shenzhen stock markets are the unrealized profit and loss of the previous trading day. The profit and loss content is for reference only. If you have any questions, please contact the salesperson or customer service center: 0800-073-588.')}</span>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Disclaimer)
import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import axios from 'axios'
import common from '../../../../asserts/js/common'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class Profile extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isLoading:false,
            profileData:''
        }
    }
    //打开链接
    openLinkClick(){
        const {profileData}=this.state
        if(!profileData) return
        //ios终端
        if(common.isiOS){
            window.location.href=profileData['weblink']
            return
        }
        //android终端/pc端
        window.open(profileData['weblink'],'_blank')
    }
    getData(ric){
        let token=sessionStorage.getItem('token')
        axios
        .post(common.getHttp+'profile', {
            token: token,
            ric: ric
        })
        .then((res) => {
            //获取数据成功
            let getData=res['data']['data']
            if(res['data']['status']==0&&getData&&JSON.stringify(getData) !== '{}'){
                this.setState({
                    profileData:getData['Profile'],
                    isLoading: false
                })
                return
            }
            this.onErrData()
        }).catch(err => {
            this.onErrData()
        })
    }
    onErrData(){
        this.setState({
            profileData:'',
            isLoading: false
        })
    }
    //更改数据
    chgDataFun(){
        const {chgRic} = this.props
        if(chgRic&&typeof(chgRic)!=='undefined'&&chgRic!==''){
            this.setState({
                isLoading: true
            })
            this.getData(chgRic)
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {chgRic} = this.props
        if(chgRic&&chgRic!==newProps['chgRic']){
            this.chgDataFun()
        }
    }
    //初始化
    componentDidMount(){
        this.chgDataFun()  
    }
    render(){
        const {t,ChgThemeData,ChgLangData,isRightPageShow,isPC,currentIndex,isTablet} = this.props
        const {isLoading,profileData}=this.state
        let biz_summary=ChgLangData=='zh-CN'?profileData&&profileData['biz_summary_cht']:profileData&&profileData['biz_summary_en']
        return (
            <div 
                className={[
                    styles.profile_con,
                    isPC&&isRightPageShow==''&&currentIndex!==3?APPstyles.flex_row:APPstyles.flex_column
                ].join(' ')}
                style={{
                    paddingBottom:isPC&&isRightPageShow==''&&currentIndex!==3?40:0
                }}
            >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                <div 
                    className={[
                        styles.profile_left,
                        isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.profile_left_min,
                        isPC&&isRightPageShow==''&&currentIndex!==3?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
                        isPC&&isRightPageShow==''&&currentIndex!==3?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                    ].join(' ')}
                    style={{
                        minHeight:(isPC&&isRightPageShow==''||isTablet)&&biz_summary?'auto':200
                    }}
                >
                    <span className={styles.profile_title}>{t('Business Summary')}</span>
                    {
                        biz_summary?
                            <div style={{lineHeight:ChgLangData=='zh-CN'?'150%':'16px'}}>{t(biz_summary)}</div>
                        :
                            <div className={[APPstyles.isLoading,APPstyles.color_grey].join(' ')} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
                    }
                </div>
                <div 
                    className={[
                        styles.profile_right,
                        isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.profile_right_min,
                        ChgLangData=='zh-CN'?styles.profile_right_zh:''
                    ].join(' ')}
                >
                    <span 
                        className={[
                            styles.profile_title,
                            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')}
                    >
                        {t('Company Description')}
                    </span>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Primary Market')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.isValShow(profileData&&profileData['display_exchange_code'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Listed Date')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.onlyFormatDate(profileData&&profileData['public_since'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Incorp Date')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.onlyFormatDate(profileData&&profileData['incorporated_in_date'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Period End')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.chgEntozhData(profileData&&profileData['period_end_date'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Sector')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.isValShow(ChgLangData=='zh-CN'?profileData&&profileData['trbc_sector_desc_cht']:profileData&&profileData['trbc_sector_desc'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Industry')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.isValShow(ChgLangData=='zh-CN'?profileData&&profileData['trbc_industry_group_desc_cht']:profileData&&profileData['trbc_industry_group_desc'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Score Officer')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.isValShow(profileData&&profileData['officer1_name_en'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Title of Officer')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.isValShow(profileData&&profileData['officer1_long_title_en'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Phone')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.isValShow(profileData&&profileData['phone_number'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                        style={{
                            flexWrap:'wrap'
                        }}
                    >
                        <span style={{marginBottom:2}}>{t('Homepage URL')}</span>
                        <span  
                            style={{
                                borderBottom:profileData&&profileData['weblink']?ChgThemeData=='Light'?'1px solid #008fc7':'1px solid #56CCF2':0,
                                color:ChgThemeData=='Light'?'#008fc7':'#56CCF2',
                                cursor:profileData&&profileData['weblink']?'pointer':'default'
                            }}
                            onClick={this.openLinkClick.bind(this)}
                        >
                            {common.isValShow(profileData&&profileData['weblink'])}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Employees')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.transThousands(common.keepNum(profileData&&profileData['employees'],0))}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Floating Shares(x1M)')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.transThousands(common.keepNum(profileData&&profileData['total_float'],0))}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Outstanding Shares')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.transThousands(common.keepNum(profileData&&profileData['total_share_outstanding'],0))}
                        </span>
                    </div>
                    <div
                        className={[
                            styles.profile_msg,
                            APPstyles.flex_between
                        ].join(' ')}
                    >
                        <span>{t('Last Update')}</span>
                        <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.chgEventDate(profileData&&profileData['total_share_outstanding_date'],'ymd')}
                        </span>
                    </div>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(Profile))
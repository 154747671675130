import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import APPstyles from '@/App.less'
import styles from './index.less'

class List extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            headerData:['Code','Name','Price','Chg','Chg%'],
            listIndex:0
        }
    }
    //选择不同的ric
    changeRicClick(data,index){
        this.setState({
            listIndex: index
        })
        this.props.cbCghRicData(data)
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {agg_code,listData}=this.props
        if(
            agg_code!==newProps['agg_code']||
            listData!==newProps['listData']
        ){
            this.setState({
                listIndex: 0
            })
        }
    }
    render(){
        const {t,ChgThemeData,counts,isLoading,listData,loadMore,noMore,isPC,store,isMobile} = this.props
        const {headerData,listIndex}=this.state
        return (
            <div className={styles.stocks} style={{minHeight:listData.length?'auto':300}}>
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                <span 
                    className={[
                        styles.title,
                        isPC?'':styles.title_min,
                        ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
                    ].join(' ')}
                    style={{padding:'0 8px'}}
                >{t('Constituents')}: {counts} {t('Stocks')}</span>
                <div 
                    className={[
                        APPstyles.flex_row,
                        styles.header,
                        styles.common,
                        isPC?'':styles.common_min,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
                    ].join(' ')}
                >
                    {
                        headerData.map((item,index) => {
                            return  <span key={index} style={{display:isPC?'':index==1?'none':''}}>{t(item)}</span>
                        })
                    }
                </div>
                <div className={styles.body}>
                    {
                        listData.length?
                            listData.map((item,index) => {
                                let data=store.getDataByRic(item['ric'])
                                return  <div 
                                            className={[
                                                APPstyles.flex_row,
                                                styles.body_one,
                                                styles.common,
                                                isPC?'':styles.common_min,
                                                isPC?'':styles.body_one_min,
                                                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                                listIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark:'',
                                                ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                                            ].join(' ')}
                                            key={index}
                                            onClick={this.changeRicClick.bind(this,item,index)}
                                        >
                                            <span className={isPC?'':APPstyles.flex_column}>
                                                <label className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white}>{common.valToUpperCase(item['symbol'])}</label>
                                                <label 
                                                    className={[
                                                        APPstyles.ellipsis,
                                                        APPstyles.fw500,
                                                        ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur
                                                    ].join(' ')} 
                                                    style={{
                                                        display:isPC?'none':'',
                                                        marginTop:2
                                                    }}
                                                >
                                                    {common.valToUpperCase(item['name'])}
                                                </label>
                                            </span>
                                            <span 
                                                className={[
                                                    APPstyles.ellipsis,
                                                    APPstyles.fw500,
                                                    ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur
                                                ].join(' ')} 
                                                style={{display:isPC?'':'none'}}
                                            >
                                                {common.valToUpperCase(item['name'])}
                                            </span>
                                            <span 
                                                className={
                                                    Number(data&&data['NETCHNG_1'])>0?APPstyles.color_red:Number(data&&data['NETCHNG_1'])<0?APPstyles.color_green:Number(data&&data['NETCHNG_1'])==0?APPstyles.color_grey:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
                                                }
                                            >
                                                {common.transThousands(common.keepNum(data&&data['TRDPRC_1'],2))}
                                            </span>
                                            <span
                                                className={Number(data&&data['NETCHNG_1'])>0?APPstyles.color_red:Number(data&&data['NETCHNG_1'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:Number(data&&data['NETCHNG_1'])==0?APPstyles.color_grey:APPstyles.color_white}  
                                            >
                                                {common.addplusNum(common.transThousands(common.keepNum(data&&data['NETCHNG_1'],2)))}
                                            </span>
                                            <span
                                                className={Number(data&&data['PCTCHNG'])>0?APPstyles.color_red:Number(data&&data['PCTCHNG'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:Number(data&&data['PCTCHNG'])==0?APPstyles.color_grey:APPstyles.color_white} 
                                            >
                                                {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(data&&data['PCTCHNG'],2))))}
                                            </span>
                                        </div>
                            })
                        :
                            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
                    }
                    <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
                    <div className={APPstyles.loading_more} style={{display:noMore?'':'none'}}>- {t('No More Data Available')} -</div>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(List))
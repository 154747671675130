import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import { 
    dotImg
} from '@/asserts/imgs/quote/index'
import Gauge from '@/asserts/js/charts/gauge'
import APPstyles from '@/App.less'
import styles from './index.less'

class Fundamentals extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    render(){
        const {t,ChgThemeData,RatiosData,gaugeLoading,tooltipData,stockData,gaugeId,stockName,isRightPageShow,currentIndex,isTablet,isMobile,subName} = this.props
        return (
            <div 
                className={[
                    gaugeId=='Ratios0Id'||gaugeId=='Ratios2Id'?styles.chart_left:styles.chart_right,
                    gaugeId=='Ratios0Id'||gaugeId=='Ratios2Id'?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
                    APPstyles.flex_row,
                    styles.minH134
                ].join(' ')}
                style={{
                    paddingBottom:0,
                    marginBottom:gaugeId=='Ratios0Id'||gaugeId=='Ratios1Id'?8:isRightPageShow||currentIndex==3||isTablet?8:0,
                    overflow:'hidden',
                    alignItems:'flex-end',
                    width:'100%',
                    height:RatiosData.length?isMobile?130:97:111.5,
                    position:'relative',
                }}
            >
                <div className={styles.chart_msg} style={{left:gaugeId=='Ratios1Id'||gaugeId=='Ratios3Id'?8:0}}>
                    <span className={ChgThemeData=='Light'?APPstyles.color_black:APPstyles.color_white}>{t(stockName)} ({subName})</span>
                    <span style={{display:RatiosData.length?'':'none'}}>{common.keepNum(stockData,2)}{gaugeId=='Ratios0Id'||gaugeId=='Ratios1Id'?'x':'%'}</span>
                </div>
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={gaugeLoading} /></div>
                {
                    RatiosData.length?
                        <div style={{height:'100%',width:'100%',position:'relative'}}>
                            <img 
                                src={dotImg} 
                                className={APPstyles.isLoading} 
                                style={{
                                    width:10,
                                    height:10,
                                    top:isMobile?'69.5%':'72%',
                                    left:isMobile?'49.5%':'54%'
                                }}
                                alt='dot' 
                            />
                            <Gauge {...this.props} id={gaugeId} seriesData={RatiosData} tooltipData={tooltipData} />
                        </div>
                    :

                        <div className={[APPstyles.isLoading,styles.guage_con,APPstyles.color_grey].join(' ')} style={{display:gaugeLoading?'none':'',zIndex:9}}>{t('Chart not available')}</div>  
                }
            </div>
        )}
}
export default withTranslation()(observer(Fundamentals))
import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  warningImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class BuyPowerModal extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render(){
    const {t,isMobile,ChgThemeData,ChgLangData}=this.props
    return (
      <div className={APPstyles.mask_con_fixed}>
        <div 
          className={[
            styles.buyPower_con,
            APPstyles.flex_column,
            ChgThemeData=='Light'?styles.buyPower_con_light:'',
            isMobile?styles.buyPower_con_mobile:'',
          ].join(' ')} 
        >
          <div className={APPstyles.flex_1}>
            <div className={[APPstyles.flex_row,styles.buyPower_title].join(' ')}>
              <img src={warningImg} className={APPstyles.img24} style={{cursor:'default'}}/>&nbsp;&nbsp;
              {ChgLangData=='zh-CN'?
                <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>請先輸入<label className={APPstyles.color_blue}>股票名稱</label> ，輸入後將依您的市場交割幣別顯示下單可用餘額。</span>
              :
                <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>Please enter the <label className={APPstyles.color_blue}>stock name</label> first. After entering, the available balance for ordering will be displayed according to your market delivery currency.</span>  
              }
            </div>
          </div>
          <div className={[APPstyles.flex_row,styles.buyPower_btn].join(' ')}>
            <Button 
              type='default' 
              className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} 
              onClick={()=>{
                this.props.cbCloseModal('N')
              }}
            >
              {t('Cancel')}
            </Button>
            <Button 
              type='default' 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
              onClick={()=>{
                this.props.cbCloseModal('Y')
              }}
            >
              {t('Confirm')}
            </Button>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(BuyPowerModal)
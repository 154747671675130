import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import { 
    dropUpOnLightImg,
    dropUpOnImg,
    dropUpImg,
    dropDownImg,
    dropDownOnImg,
    dropDownOnLightImg,
    leftImg,
    leftLightImg,
} from '@/asserts/imgs/common/index'
import { 
    foldImg,
    foldOnImg,
    foldOnLightImg
} from '@/asserts/imgs/quote/index'
import ListCon from './list/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let page=1

class RightCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            headerData:[],
            listData:[],
            foldImgUrl:foldImg,
            isToggleOn: true,
            sizeTotal:0,
            recperpage:20,
            page:1,
            isLoading:false,
            getObj:{},
            loadMore:false,
            noMore:false,
            titlesData:{
                MKTCAP_USD:'Market Cap',
                AVG_SCORE:'Company Score (StockReports+)',
                ESGScoreGrade:'ESG Score',
                APENORM:'P/E Normalized',
                APRICE2BK:'Price to Book',
                APRICE2TOTEQ:'Price to Equity',
                AGROSMGN:'Gross Margin (%)',
                YIELD:'Dividend Yield (%)',
                AROEPCT:'Return On Equity (ROE) (%)',
                AQUICKRATI:'Quick Ratio',
                ACURRATIO:'Current Ratio',
                QTOTD2AST:'Total Debt/Total Assets (%)',
                AREVCHG:'Revenue Growth Rate (%)',
                ADIVCHG:'Dividend Yield Growth Rate (%) / 1 Yr ago',
                DIVGRPCT:'Dividend Yield Growth Rate (%) / 3 Yrs ago',
                DIVTRENDGR:'Dividend Yield Growth Rate (%) / 5 Yrs ago',
                DIVTREND10:'Dividend Yield Growth Rate (%) / 10 Yrs ago',
                AEPSCHG:'EPS Growth Rate (%) / 1Y',
                EPSGRPCT:'EPS Growth Rate (%) / 3Y',
                EPSTRENDGR:'EPS Growth Rate (%) / 5Y',
                EPSTREND10:'EPS Growth Rate (%) / 10Y',
                EPSCHNGIN:'EPS Growth Rate (%) / 1Q',
                t_short_goldx:'Golden Cross',
                t_short_deadx:'Dead Cross',
                t_boll_bull:'Bollinger Breakout (Bullish)',
                t_boll_bear:'Bollinger Breakout (Bearish)',
                t_macd_bullx:'MACD (Bullish)',
                t_macd_bearx:'MACD (Bearish)',
                t_fast_sto:'Fast Stochastic',
                t_slow_sto:'Slow Stochastic',
                t_dev_sma5:'Simple Moving Average Deviation Rate (%) / 5 business days',
                t_dev_sma25:'Simple Moving Average Deviation Rate (%) / 25 business days',
                t_dev_sma75:'Simple Moving Average Deviation Rate (%) / 75 business days',
                t_rsi:'Relative Strength Index (RSI)',
                rt_pctchng:'Percentage Change1 / 1D',
                pricePctChg1W:'Percentage Change1 / 1W',
                pricePctChg1M:'Percentage Change1 / 1M',
                pricePctChg3M:'Percentage Change1 / 3M',
                pricePctChg1Y:'Percentage Change1 / 1Y',
                pricePctChg5Y:'Percentage Change1 / 5Y',
                VOL10DAVG:'Volume - avg. trading vol. (x1M)1 / Last 10 Days',
                VOL3MAVG:'Volume - avg. trading vol. (x1M)1 / Last 3 Months',
                rt_high52w_ind:'52 Week High',
                rt_low52w_ind:'52 Week Low'
            },
            isChgResultShow:false,
            sortTag:'',
            indexData234:[2,3,4],
            sortKey:'',
            t_dev_smaData:['t_dev_sma5','t_dev_sma25','t_dev_sma75'],
            ADIVCHGData:['ADIVCHG','DIVGRPCT','DIVTRENDGR','DIVTREND10'],
            VOLData:['VOL10DAVG','VOL3MAVG'],
            AEPData:['AEPSCHG','EPSGRPCT','EPSTRENDGR','EPSTREND10','EPSCHNGIN'],
            pctchngData:['rt_pctchng','pricePctChg1W','pricePctChg1M','pricePctChg3M','pricePctChg1Y','pricePctChg5Y'],
            checkData:['t_short_goldx','t_short_deadx','t_boll_bull','t_boll_bear','t_macd_bullx','t_macd_bearx','rt_high52w_ind','rt_low52w_ind'],
            isChgDataShow:false,
            isSortShow:false,
            keyDataUS:['AVG_SCORE','AROEPCT','QTOTD2AST','AREVCHG','ADIVCHG','DIVGRPCT','DIVTRENDGR','DIVTREND10','t_boll_bull','t_boll_bear','t_dev_sma5','t_dev_sma25','t_dev_sma75','t_rsi','VOL10DAVG','VOL3MAVG','rt_pctchng','pricePctChg1W','pricePctChg1M','pricePctChg3M','pricePctChg1Y','pricePctChg5Y','AEPSCHG','EPSGRPCT','EPSTRENDGR','EPSTREND10','EPSCHNGIN','APENORM'],
            keyDataZN:['AVG_SCORE','AROEPCT','AEPSCHG','EPSGRPCT','EPSTRENDGR','EPSTREND10','EPSCHNGIN','t_boll_bull','t_boll_bear','t_dev_sma5','t_dev_sma25','t_dev_sma75','rt_pctchng','pricePctChg1W','pricePctChg1M','pricePctChg3M','pricePctChg1Y','pricePctChg5Y'],
            isHasKey:0
        }
    }
    //折叠--点击
    foldClick() {
        const {ChgThemeData}=this.props
        const {isToggleOn}=this.state
        this.setState(state => ({
            isToggleOn: !state.isToggleOn,
            foldImgUrl:!state.isToggleOn||ChgThemeData=='Light'?foldImg:foldOnImg
        }))
        this.props.cbIsToggleOn(isToggleOn)
    }
    // 排序
    sortClick(data,index,tag){
        const {ChgThemeData}=this.props
        const {headerData,listData,getObj,sortTag,sortKey,isSortShow}=this.state
        if((tag==sortTag&&sortKey==data['key'])||listData.length<=0) return
        this.setState({
            isLoading:true,
            loadMore:false,
            noMore:false,
            isSortShow:!isSortShow
        })
        for(let i in headerData){
            if(index==i){
                let getUpImg=tag=='ASC'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                let getDownImg=tag=='DESC'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                headerData[i]['upImg']=getUpImg
                headerData[i]['downImg']=getDownImg
                headerData[i]['sortName']=tag
                headerData[i]['sortKey']=data['key']
            }else{
                headerData[i]['upImg']=dropUpImg
                headerData[i]['downImg']=dropDownImg
                headerData[i]['sortName']=''
                headerData[i]['sortKey']=''
            }
        }
        page=1
        getObj['pageno']=1
        getObj['sort']=tag
        getObj['order']=data['key']=='MKTCAP_USD'?'MarketCap':data['key']

        this.setState({
            headerData:headerData,
            getObj:getObj,
            sortTag:tag,
            sortKey:data['key']
        })
        this.getDataFun(getObj,[],true)
    }
    // 滚动加载更多
    cbChgListDataFun(){
        const {loadMore,noMore,isLoading,getObj,listData}=this.state
        if(loadMore||noMore||isLoading) return
        page++
        getObj['pageno']=page
        this.setState({
            page: page,
            loadMore:true,
            noMore:false,
            getObj:getObj
        })
        this.getDataFun(getObj,listData,false,false)
    }
    //处理数据--key并传给api
    dealListDataFun(data,page){
        const {keys3Data}=this.props
        const {recperpage}=this.state
        let keyData0=[]
        let obj={
            pageno:page,
            recperpage:recperpage,
            order:'name_en',
            sort: 'ASC',
            token:sessionStorage.getItem('token')
        }
        for(let i in data){
            // keys3Data
            if(keys3Data.includes(data[i]['key'])){
                keyData0[i]=common.dealCheckedKeyData(data[i]['data'])
                if(keyData0[i].length){
                    obj[data[i]['key']]=keyData0[i]
                }
            }else{
                let getData=data[i]['data']
                for(let j in getData){
                    if(typeof(getData[j]['min'])=='undefined'){
                        if(getData[j]['checked']){
                            // ESGScoreGrade
                            if(getData[j]['key']=='ESGScoreGrade'){
                                obj[getData[j]['key']]=[getData[j]['leftValue'],getData[j]['rightValue']]
                            }else{
                                obj[getData[j]['key']]=getData[j]['checked'] 
                            }
                        }
                    }else{
                        let dropDownData=getData[j]['dropDownData']
                        let trueValue0=getData[j]['trueValue']
                        let key=0

                        if(typeof(dropDownData)=='undefined'){
                            key=getData[j]['key']
                        }else{
                            let index=getData[j]['isDropDownIndex']
                            key=dropDownData[index]['key'] 
                        }
                        if(trueValue0.length){
                            let min=Number(trueValue0[0]) 
                            let max=Number(trueValue0[1])
                            obj[key]=[min,max]
                        }
                    }
                }
            }
        }
        this.setState({
            getObj:obj
        })
        this.getDataFun(obj,[],false,true)
    }
    //获取API数据
    getDataFun(obj,listData,isSortShow,isOnLoad){
        const {ChgLangData}=this.props
        const {titlesData,t_dev_smaData,ADIVCHGData,VOLData,AEPData,pctchngData,checkData,isChgDataShow,headerData,keyDataUS,keyDataZN}=this.state
        fetch(common.getHttp+'screener',{
            method:'POST',
            headers:{
                'Accept':'application/json,text/plain,*/*',
                'Content-Type':'application/json'
            },
            body:JSON.stringify(obj)
        })
        .then(res => res.json())
        .then(res => {
            let headerDataTemp=[
                {
                    name:'Code',
                    upImg:dropUpImg,
                    downImg:dropDownImg,
                    key:'ticker'
                },
                {
                    name:'Name',
                    upImg:dropUpImg,
                    downImg:dropDownImg,
                    key:'name_en'
                },
                {
                    name:'Price',
                    upImg:dropUpImg,
                    downImg:dropDownImg,
                    key:'TRDPRC_1'
                },
                {
                    name:'Chg',
                    upImg:dropUpImg,
                    downImg:dropDownImg,
                    key:'NETCHNG_1'
                },
                {
                    name:'Chg(%)',
                    upImg:dropUpImg,
                    downImg:dropDownImg,
                    key:'PCTCHNG'
                }
            ]
            let monthsData=[
                {
                    val:'',
                    bgColor:'',
                    key:'performance1M'
                },
                {
                    val:'',
                    bgColor:'',
                    key:'performance3M'
                },
                {
                    val:'',
                    bgColor:'',
                    key:'performance6M'
                },
                {
                    val:'',
                    bgColor:'',
                    key:'performance1Y'
                }
            ]
            this.setState({
                isHasKey:0
            })
            let keyDataAll=ChgLangData=='zh-CN'?keyDataZN:keyDataUS
            if(res['status']==0){
                let resData=res['data']
                let pn1=resData.length?resData.length>=10?false:true:false
                let pnother=listData.length?resData.length?false:true:true
                let noMoreData=page==1?pn1:pnother
                let getHeaderData=[],getAdvData=[],chgIndex=0
                if(resData.length){
                    let oneData=Object.keys(resData[0])
                    let newTitleData=Object.keys(titlesData)
                    for(let i in newTitleData){
                        for(let j in oneData){
                            if(newTitleData[i]==oneData[j]){
                                // 在header添加对应的数据
                                let name=titlesData[newTitleData[i]]
                                let newName='',newName1='',subName='',nameAll=''
                                // 文字太多换行
                                if(name.indexOf('/')!==-1&&(t_dev_smaData.includes(oneData[j])||ADIVCHGData.includes(oneData[j])||VOLData.includes(oneData[j])||AEPData.includes(oneData[j])||pctchngData.includes(oneData[j]))){
                                    let datas=name.split('/')
                                    newName=datas[0].trim()
                                    nameAll=datas[0].trim()
                                    // t_dev_smaData
                                    if(t_dev_smaData.includes(oneData[j])){
                                        newName='Simple Moving Average'
                                        newName1='Deviation Rate (%)'
                                    // ADIVCHG
                                    }else if(ADIVCHGData.includes(oneData[j])){
                                        newName='Dividend Yield1'
                                        newName1='Growth Rate (%)'
                                    // ADIVCHG
                                    }else if(VOLData.includes(oneData[j])){
                                        newName='Volume - avg.'
                                        newName1='trading vol. (x1M)'
                                    }
                                    subName=datas[1].trim()
                                }
                                let obj={
                                    name:newName?newName:name,
                                    name1:newName1,
                                    nameAll:nameAll,
                                    subName:subName,
                                    upImg:dropUpImg,
                                    downImg:dropDownImg,
                                    key:newTitleData[i],
                                    check:checkData.includes(oneData[j])?true:false
                                }
                                getHeaderData.push(obj)
                            }
                        }
                    }
                    for(let i in keyDataAll){
                        for(let j in oneData){
                            if(oneData[j]==keyDataAll[i]){
                                chgIndex++
                            }
                        }
                    }
                    // 列表添加对应的数据
                    for(let i in resData){
                        for(let j in getHeaderData){
                            let key=getHeaderData[j]['key']
                            let val=resData[i][key]
                            let newVal=key=='ESGScoreGrade'?val:common.transThousands(common.keepNum(val,2))
                            resData[i]['adv'+j]= newVal
                            resData[i]['symbol']= resData[i]['ticker']
                            getAdvData.push(getHeaderData[j])
                            resData[i]['advData']=this.noRepeat1(getAdvData)
                            resData[i]['isHoverShow']=false
                        }
                    }
                }
                // 排序
                if(isSortShow){
                    this.setState({
                        listData:listData.concat(resData),
                        isLoading: false,
                        isChgDataShow:!isChgDataShow,
                        isHasKey:chgIndex
                    })
                    this.props.cbCloseGetResultBtn(false)
                    return
                }
                // 未排序
                headerDataTemp.push.apply(headerDataTemp,getHeaderData)
                this.setState({
                    listData:listData.concat(resData),
                    headerData:resData.length?headerDataTemp:headerData,
                    sizeTotal:res['total'],
                    isLoading: false,
                    loadMore:false,
                    noMore:noMoreData,
                    isChgDataShow:!isChgDataShow,
                    isHasKey:chgIndex
                })
                this.setState(state => ({
                    isChgResultShow: !state.isChgResultShow
                }))
                this.props.cbSizeTotal(res['total'])
                this.props.cbCloseGetResultBtn(false)
                isOnLoad&&this.props.chgOnLoadListData(resData[0])
                // monthsData
                let performance=res['performance']
                if(performance&&JSON.stringify(performance)!=='{}'){
                    if(performance['performance1M']==null&&performance['performance3M']==null&&performance['performance6M']==null&&performance['performance1Y']==null){
                        this.props.chgMonthsDataFun([])
                        return
                    }
                    for(let i in monthsData){
                        let key=performance[monthsData[i]['key']]
                        monthsData[i]['val']=key
                        monthsData[i]['bgColor']=common.chgBgColorData(key)
                    }
                    this.props.chgMonthsDataFun(monthsData)
                    return   
                }
                this.props.chgMonthsDataFun([])
                return
            }
            this.resetDataFun()
        }).catch(err => {
            this.resetDataFun()
        })
    }
    resetDataFun(){
        const {isChgDataShow}=this.state
        this.setState({
            listData:[],
            sizeTotal:0,
            isLoading: false,
            loadMore:false,
            noMore:false,
            isChgDataShow:!isChgDataShow,
            isHasKey:0
        })
        this.props.cbCloseGetResultBtn(false)
    }
    // 数组去重
    noRepeat1(arr) {
        for (var i = 0; i < arr.length - 1; i++) {
            for (var j = i + 1; j < arr.length; j++) {
                if (arr[i] === arr[j]) {
                    arr.splice(j, 1)
                    j--
                }
            }
        }
        return arr
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {ChgThemeData,isGetResultShow,getListData,getStrategy,isChgToggleOn,ChgLangData}=this.props
        const {isToggleOn,headerData,getObj}=this.state
        if(ChgThemeData!==newProps['ChgThemeData']){
            let imgurl=isToggleOn?foldImg:ChgThemeData=='Light'?foldOnLightImg:foldOnImg
            for(let i in headerData){
                if(headerData[i]['sortKey']==headerData[i]['key']){
                    let getUpImg=headerData[i]['sortName']=='ASC'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                    let getDownImg=headerData[i]['sortName']=='DESC'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                    headerData[i]['upImg']=getUpImg
                    headerData[i]['downImg']=getDownImg
                }
            }
            this.setState({
                foldImgUrl:imgurl,
                headerData:headerData
            })
        }
        if(isGetResultShow!==newProps['isGetResultShow']){
            page=1
            this.setState({
                isLoading:true,
                page:1,
                sortTag:'',
                sortKey:''
            })
            this.props.cbCloseGetResultBtn(true)
            // 没有数据
            if(getStrategy=='strategyNoData'){
                this.setState({
                    listData:[],
                    headerData:[],
                    isLoading:false,
                    isHasKey:0
                })
                return
            }
            // 有数据
            this.dealListDataFun(getListData,1)
        }
        if(isChgToggleOn!==newProps['isChgToggleOn']||ChgLangData!==newProps['ChgLangData']){
            this.setState({
                isLoading:true
            })
            this.getDataFun(getObj,[],true)
        }
    }
    render(){
        const {t,isPC,isTablet,isMobile,ChgThemeData,isListPageShow} = this.props
        const {foldImgUrl,isToggleOn,sizeTotal,recperpage,page,headerData,listData,isLoading,loadMore,noMore,isChgResultShow,indexData234,VOLData,ADIVCHGData,isChgDataShow,isSortShow,isHasKey} = this.state  
        return (
            <div
                className={[
                    styles.right_con,
                    APPstyles.flex_column,
                    isMobile&&isListPageShow?APPstyles.position_fixed:'',
                    isMobile&&isListPageShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                ].join(' ')}
            >
                
                {/* 加载中 */}
                <div className={APPstyles.isLoading} style={{zIndex:9}}><Spin spinning={isLoading} /></div>
                <div 
                    className={[
                        styles.fold_con,
                        APPstyles.flex_topcenter,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                    ].join(' ')}
                    style={{
                        display:isMobile?'none':''
                    }}
                >
                    <img 
                        src={foldImgUrl} 
                        className={APPstyles.img16} 
                        onClick={this.foldClick.bind(this)} 
                        onMouseOver={()=>{
                            if(!isPC) return
                            let imgurl=ChgThemeData=='Light'?foldOnLightImg:foldOnImg
                            this.setState({
                                foldImgUrl:imgurl
                            })
                        }} 
                        onMouseOut={()=>{
                            if(!isPC) return
                            let imgurl=isToggleOn?foldImg:ChgThemeData=='Light'?foldOnLightImg:foldOnImg
                            this.setState({
                                foldImgUrl:imgurl
                            })
                        }} 
                        alt='fold' 
                    />
                </div>
                <div 
                    className={[
                        APPstyles.flex_row,
                        styles.criteria_title_con,
                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                        ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
                    ].join(' ')}
                    style={{
                        display:isMobile?'':'none'
                    }}
                > 
                    <img 
                        src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                        alt='back' 
                        className={APPstyles.img24} 
                        onClick={()=>{
                            this.props.cbIsListPageShow(false)  
                        }} 
                    />
                    {t('Screener Results')}
                </div>
                <span 
                    className={[
                        styles.papge_msg,
                        APPstyles.flex_topcenter,
                        isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
                    ].join(' ')} 
                    style={{
                        display:isMobile&&listData.length?'':'none',
                        minHeight:isMobile&&listData.length?37:0,
                        margin:'0 16px'
                    }}
                >
                    {t('Displaying')} {page} -  {sizeTotal>0?Math.ceil(sizeTotal / recperpage):1} {t('of')} {common.transThousands(sizeTotal)} {t('matching stocks')}
                </span>
                {/* list_body */}
                    <div className={[APPstyles.flex_column,APPstyles.hPer_overflowH].join(' ')} style={{display:listData.length?'':'none'}}>
                        <div 
                            className={[
                                styles.page_top_con,
                                APPstyles.flex_between,
                                isMobile?APPstyles.sticky_top_mobile:'',
                                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                isMobile?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                            ].join(' ')}
                            style={{
                                minHeight:isPC?54:isMobile?0:37,
                                top:isTablet&&!isMobile?common.isiOS?48:0:'unset'
                            }}
                        >
                            <span className={styles.papge_msg} style={{display:isMobile?'none':''}}>
                                {t('Displaying')} {page} -  {sizeTotal>0?Math.ceil(sizeTotal / recperpage):1} {t('of')} {common.transThousands(sizeTotal)} {t('matching stocks')}
                            </span>
                        </div>
                        <ListCon
                            {...this.props}
                            headerData={headerData}
                            listData={listData}
                            isChgResultShow={isChgResultShow}
                            indexData234={indexData234}
                            loadMore={loadMore}
                            noMore={noMore}
                            VOLData={VOLData}
                            ADIVCHGData={ADIVCHGData}
                            isChgDataShow={isChgDataShow}
                            isSortShow={isSortShow}
                            isHasKey={isHasKey}
                            cbSortClick={this.sortClick.bind(this)}
                            cbChgListDataFun={this.cbChgListDataFun.bind(this)}
                            cbChgListData={(data)=>{
                                this.props.chgListData(data) 
                                //ios终端
                                isTablet&&this.props.cbIsShowHeaderPage(false)
                            }}
                            cbChgTabIndex={(index,data)=>{
                                this.props.cbChgTabIndex(index,data)
                            }}
                            cgChgheaderDataFun={(data)=>{
                                this.setState({
                                    headerData:data
                                })
                            }}
                        />
                    </div>
                    <div className={APPstyles.isLoading} style={{display:listData.length?'none':isLoading?'none':'',zIndex:9}}>{t('No datas')}</div> 
            </div>
        )}
}
export default withTranslation()(observer(RightCon))
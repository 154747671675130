import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { Spin,Tooltip } from 'antd'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg,
  tipImg,
  tipOnImg,
  tipOnLightImg,
  leftImg,
  leftLightImg
} from '@/asserts/imgs/common/index'
import Header from './Header/index'
import Mobile from './Mobile/index'
import Disclaimer from './disclaimer/index'
import Tabs from '@/components/Tabs2/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible:false,
      tableData:[],
      isLoading:true,
      folioInfoData:[],
      tabsData:[],
      tabIndex:0,
      countryData:[],
      stockLData:[],
      stockRData:[],
      colorData:['#008FC7','#009E9C','#38925A','#FFC94F','#F2994A','#F2724A','#E03E57','#B0347F','#8D48A2','#5D4DBB'],
      chartData:[{
        name:'no stock',
        y:100,
        color:'#828282'
      }],
      isUSCur:'US',
      ratio:'',
      tipsImg0:tipImg,
      tipsImg1:tipImg,
      minW1370:1370,
      isScrollShow:false,
      isOnLoad:true,
      headerStickyShow:false,
      sortData:[
        {
          name:'totalPlRatio',
          upImg:dropUpImg,
          downImg:dropDownImg,
          id:'total_pl_ratio'
        },
        {
          name:'unrealised_pl',
          upImg:dropUpImg,
          downImg:dropDownImg,
          id:'unrealised'
        }
      ],
      getResultData:[],
      marketpDownData:[],
      sortId:null,
      chgCur:'',
      allData:'',
      chgTab:'US'
    }
    this.myRef = React.createRef()
    this.myMobileRef = React.createRef()
    this.myHeaderRef = React.createRef()
    this.myTabRef = React.createRef()
  }
  //切换tab
  cbTabsIndex(index){
    const {countryData,tabIndex,allData,tabsData}=this.state
    if(index==tabIndex) return
    let key=countryData[index]
    let currency=common.imgShortNameChgCur[key]
    let UKkey=Object.keys(allData[key])
    let UKCur=UKkey[0]=='default'?currency:UKkey
    let newCur=key=='UK'?UKCur:currency
    let countryCode=tabsData[index]['name']
    this.setState({
      tabIndex:index,
      chgCur:newCur,
      isLoading:true,
      chgTab:key
    })
    this.resetSortDataFun()
    this.props.cbtodaysCurFun(currency,countryCode)
    this.dealGetRicDataFun(allData,key,newCur,countryData,index)
  }
  //获取数据API--列表数据
  getTableData() {
    fetch(common.getHttpZs+"getPortfolioDataBy1080", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: sessionStorage.getItem("token")
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if(res['status']==0){
        let resData=res['data']['portfolioHoldings']
        let tabData=common.sortDescLetter(Object.keys(resData))
        let newTabsData=[],size_current=0
        for(let i in tabData){
          newTabsData.push({
            name:tabData[i],
            isShow:false
          })
          let data=resData[tabData[i]]
          if(JSON.stringify(data)!=='{}'){
            for(let j in data){
              size_current+=data[j]['size_current']
            }
          }
        }
        this.getTabNumData(size_current)
        let key=tabData[0]
        let currency=common.imgShortNameChgCur[key]
        this.setState({
          chgCur:currency,
          allData:resData,
          tabsData:newTabsData,
          countryData:tabData
        })
        // 处理数据
        this.dealGetRicDataFun(resData,key,currency,tabData,0)
        return
      }
      // 获取失败
      this.noDataFun('N')
    })
  }
  dealGetRicDataFun(resData,key,currency,tabData,index){
    let getRicData=[]
    let allResKeyData=resData[key]
    let keysData=Object.keys(allResKeyData)
    for(let i in keysData){
      let datas=allResKeyData[keysData[i]]
      let datalist=datas['datalist']
      if(datalist.length){
        for(let j in datalist){
          datalist[j]['isShow']=false
          getRicData.push(datalist[j]['stock_id'])
        }
      }
    }
    if(getRicData.length){
      getRicData= [...new Set(getRicData)]//去重
      this.getsimplequoteFun(tabData,index,currency,getRicData,key,allResKeyData,keysData)
    }else{
      this.dealDropDownDataFun(allResKeyData)
      this.noDataFun('Y',tabData,index,currency)
    }
  }
  //getsimplequote--获取数据
  getsimplequoteFun(tabData,index,currency,ric,key,allResKeyData,keysData){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        let newRatioData=[]
        this.props.cbSimplequoteDataFun(JSON.stringify(resData)=='{}'?'':resData)
        if(JSON.stringify(resData)!=='{}'){
          // US
          if(key=='US'){
            for(let i in keysData){
              let datas=allResKeyData[keysData[i]]
              let datalist=datas['datalist']
              let total=datas['total']
              let total_amount=0,unrealised_pl=0,ratio_sum=0,ratioData=[]
              if(datalist.length>0){
                for(let j in datalist){
                  for(let k in ric){
                    if(datalist[j]['stock_id']==ric[k]){
                      let data=resData[ric[k]]
                      let last_price=typeof(data['TRDPRC_1'])=='undefined'||data['TRDPRC_1']==null?0:Number(data['TRDPRC_1'])
                      datalist[j]['last_price']=last_price
                      datalist[j]['total_amount']=last_price*Number(datalist[j]['current_qty'])*(1-Number(datalist[j]['fee_rate']))
                      datalist[j]['unrealised_pl']=(last_price*(1-Number(datalist[j]['fee_rate']))-Number(datalist[j]['purchase_price']))*Number(datalist[j]['current_qty'])
                      total_amount+=Number(datalist[j]['total_amount'])
                      unrealised_pl+=Number(datalist[j]['unrealised_pl'])
                    }
                  }
                }
              }
              // ratio
              if(total_amount>0){
                for(let j in datalist){
                  let new_amount=datalist[j]['total_amount']/total_amount*100
                  let name=resData[datalist[j]['stock_id']]['name']
                  ratioData.push({
                    ratio_total_amount:common.keepNum(new_amount,2),
                    stock_id:datalist[j]['stock_id'],
                    stock_name:name,
                    total_amount:datalist[j]['total_amount']
                  })
                }
                // 排序--降序
                ratioData=common.sortNumber('desc',ratioData,'ratio_total_amount')
                if(newRatioData.length>0){
                  for(let k in ratioData){
                    if(k<=8&&newRatioData.length<10){
                      newRatioData.push(ratioData[k])
                    }else{
                      ratio_sum+=Number(ratioData[k]['ratio_total_amount'])
                    }
                  }
                }else{
                  for(let k in ratioData){
                    if(k<=8){
                      newRatioData.push(ratioData[k])
                    }else{
                      ratio_sum+=Number(ratioData[k]['ratio_total_amount'])
                    }
                  }
                }
                if(ratio_sum>0&&keysData.length-1==i){
                  newRatioData.push({
                    ratio_total_amount:common.keepNum(ratio_sum,2),
                    stock_name:'OTHERS'
                  })
                }
              }
              datas['ratio']=newRatioData
              // total
              total['total_amount']=total_amount
              total['unrealised_pl']=unrealised_pl
            }
          }
          this.dealDropDownDataFun(allResKeyData)
          this.dealListDataFun(tabData,index,currency,keysData,allResKeyData,ric,resData,key)
          return
        }
        this.noDataFun('Y',tabData,index,currency)
        return
      }
      //获取失败
      this.noDataFun('Y',tabData,index,currency)
    })
    .catch((err) => {
      console.log(err)
      this.dealListDataCatchFun(tabData,index,currency,keysData,allResKeyData)
    })
  }
  //处理数据--列表
  dealListDataFun(tabData,index,currency,keysData,resData,ricData,resRicData,key){
    const {isPC,store}=this.props
    const {colorData}=this.state
    let getData=[],getStockLData=[],getStockRData=[],getChartData=[],ratio=[],getRicData=[],curlist=[],listData=[],getFolioInfoData=[],getLen=0
    for(let i in tabData){
      if(tabData[i]==tabData[index]){
        for(let j in keysData){
          let datas=resData[keysData[j]]
          let datalist=datas['datalist']
          let total=datas['total']
          let ratioData=datas['ratio']
          getLen+=datalist.length
          getFolioInfoData.push(total)
          if(datalist.length){
            for(let k in datalist){
              for(let n in ricData){
                if(datalist[k]['stock_id']==ricData[n]){
                  let newdata=resRicData[ricData[n]]
                  datalist[k]['ric']=newdata['ric']
                  datalist[k]['stock_code']=newdata['symbol']
                  datalist[k]['stock_name']=newdata['name']
                  // 计算
                  let total_amount=datalist[k]['total_amount']
                  let unrealised_pl=datalist[k]['unrealised_pl']
                  if (total_amount&& total_amount!== '-') {
                    datalist[k]['total_amount_len']=Number(String(total_amount).split('.')[0].length+2)
                  }
                  if (unrealised_pl&&unrealised_pl !== '-') {
                    datalist[k]['unrealised_pl_len']=Number(String(unrealised_pl).split('.')[0].length+2)
                  }
                  if (tabData[i]==='US') {
                    getRicData.push(datalist[k]['stock_code'] + '.NB')
                    curlist.push({'ric':datalist[k]['stock_code'] + '.NB', 'TRDPRC_1':datalist[k]['last_price']})
                  } else {
                    curlist.push({'ric':datalist[k]['stock_id'], 'TRDPRC_1':datalist[k]['last_price']})
                  }
                }
              }
              if(ratioData.length&&key!=='US'){
                for(let n in ratioData){
                  if(ratioData[n]['stock_id']==datalist[k]['stock_id']){
                    ratioData[n]['stock_name']=datalist[k]['stock_name']
                  }
                }
              }
            }
          }
          listData.push({
            datalist:datalist,
            total_amount:total['total_amount'],
            unrealised_pl:total['unrealised_pl'],
            currency:datalist.length?datalist[0]['currency']:currency,
            len:datalist.length
          })
        }
        store.addSubscribeList(getRicData);
        store.setQuoteData(curlist);
        getData.push({
          country:tabData[index],
          cur:currency,
          len:getLen,
          img:common.imgShortNameFlag[tabData[index]],
          data:listData
        })
        ratio=typeof(resData[currency])=='undefined'?resData[keysData[0]]:resData[currency]['ratio']
        if(ratio.length){
          for(let i in ratio){
            ratio[i]['bgColor']=colorData[i]
            getChartData.push({
              name:ratio[i]['stock_id'],
              y:Number(ratio[i]['ratio_total_amount']),
              color:colorData[i],
              stock_name:ratio[i]['stock_name']
            })
            if(i<5){
              getStockLData.push(ratio[i])
            }else{
              getStockRData.push(ratio[i])
            }
          }
        }
      }
    }
    //图表没有数据
    if(getChartData.length<=0){
      getChartData.push({
        name:'no stock',
        y:100,
        color:'#828282'
      })
    }
    //处理国旗--flag
    getData=common.imgDataFlagFun(getData,'img')
    this.setState({
      tableData:getData,
      getResultData:getData,
      stockLData:getStockLData,
      stockRData:getStockRData,
      chartData:getChartData,
      folioInfoData:getFolioInfoData,
      isLoading:false,
      isUSCur:tabData[index],
      ratio:ratio,
      isOnLoad:false,
      sortId:null
    })
    if(isPC){
      setTimeout(()=>{
        this.handleScroll()
      }, 10)
    }
    setTimeout(()=>{
      this.cbGobackTopFun()
    }, 10)
  }
  //处理数据--列表--获取不到  getsimplequote
  dealListDataCatchFun(tabData,index,currency,keysData,resData){
    const {isPC,store}=this.props
    const {colorData}=this.state
    let getData=[],getStockLData=[],getStockRData=[],getChartData=[],ratio=[],getRicData=[],curlist=[],listData=[],getFolioInfoData=[],getLen=0
    for(let i in tabData){
      if(tabData[i]==tabData[index]){
        for(let j in keysData){
          let datas=resData[keysData[j]]
          let datalist=datas['datalist']
          let total=datas['total']
          getLen+=datalist.length
          getFolioInfoData.push(total)
          if(datalist.length){
            for(let k in datalist){
              // 计算
              let total_amount=datalist[k]['total_amount']
              let unrealised_pl=datalist[k]['unrealised_pl']
              if (total_amount&& total_amount!== '-') {
                datalist[k]['total_amount_len']=Number(String(total_amount).split('.')[0].length+2)
              }
              if (unrealised_pl&&unrealised_pl !== '-') {
                datalist[k]['unrealised_pl_len']=Number(String(unrealised_pl).split('.')[0].length+2)
              }
              if (tabData[i]==='US') {
                getRicData.push(datalist[k]['stock_code'] + '.NB')
                curlist.push({'ric':datalist[k]['stock_code'] + '.NB', 'TRDPRC_1':datalist[k]['last_price']})
              } else {
                curlist.push({'ric':datalist[k]['stock_id'], 'TRDPRC_1':datalist[k]['last_price']})
              }
            }
          }
          listData.push({
            datalist:datalist,
            total_amount:total['total_amount'],
            unrealised_pl:total['unrealised_pl'],
            currency:datalist.length?datalist[0]['currency']:currency,
            len:datalist.length
          })
          
        }
        store.addSubscribeList(getRicData);
        store.setQuoteData(curlist);
        getData.push({
          country:tabData[index],
          cur:currency,
          len:getLen,
          img:common.imgShortNameFlag[tabData[index]],
          data:listData
        })
        ratio=typeof(resData[currency])=='undefined'?resData[keysData[0]]:resData[currency]['ratio']
        if(ratio.length){
          for(let i in ratio){
            ratio[i]['bgColor']=colorData[i]
            getChartData.push({
              name:ratio[i]['stock_id'],
              y:Number(ratio[i]['ratio_total_amount']),
              color:colorData[i],
              stock_name:ratio[i]['stock_name']
            })
            if(i<5){
              getStockLData.push(ratio[i])
            }else{
              getStockRData.push(ratio[i])
            }
          }
        }
      }
    }
    //图表没有数据
    if(getChartData.length<=0){
      getChartData.push({
        name:'no stock',
        y:100,
        color:'#828282'
      })
    }
    //处理国旗--flag
    getData=common.imgDataFlagFun(getData,'img')
    this.setState({
      tableData:getData,
      getResultData:getData,
      stockLData:getStockLData,
      stockRData:getStockRData,
      chartData:getChartData,
      folioInfoData:getFolioInfoData,
      isLoading:false,
      isUSCur:tabData[index],
      ratio:ratio,
      isOnLoad:false,
      sortId:null
    })
    if(isPC){
      setTimeout(()=>{
        this.handleScroll()
      }, 10)
    }
    setTimeout(()=>{
      this.cbGobackTopFun()
    }, 10)
  }
  //处理数据--信息
  dealHeaderDataFun(newData,tabData,index){
    const {colorData}=this.state
    let getStockLData=[],getStockRData=[],getChartData=[],ratio=[]
    for(let i in tabData){
      if(tabData[i]==tabData[index]){
        ratio=newData['ratio']
        if(ratio.length){
          for(let i in ratio){
            ratio[i]['bgColor']=colorData[i]
            getChartData.push({
              name:ratio[i]['stock_id'],
              y:Number(ratio[i]['ratio_total_amount']),
              color:colorData[i],
              stock_name:ratio[i]['stock_name']
            })
            if(i<5){
              getStockLData.push(ratio[i])
            }else{
              getStockRData.push(ratio[i])
            }
          }
        }
      }
    }
    //图表没有数据
    if(getChartData.length<=0){
      getChartData.push({
        name:'no stock',
        y:100,
        color:'#828282'
      })
    }
    this.setState({
      stockLData:getStockLData,
      stockRData:getStockRData,
      chartData:getChartData,
      isLoading:false,
      isUSCur:tabData[index],
      ratio:ratio
    })
  }
  noDataFun(tag,tabData,index,currency){
    const {isPC}=this.props
    let getData=[],getFolioInfoData=[]
    if(tag=='Y'){
      let listData=[{
        datalist:[],
        total_amount:0,
        unrealised_pl:0,
        total_pl_ratio:0,
        currency:currency,
        len:0
      }]
      getData=[{
        country:tabData[index],
        cur:currency,
        len:0,
        img:common.imgShortNameFlag[tabData[index]],
        data:listData
      }]
      //处理国旗--flag
      getData=common.imgDataFlagFun(getData,'img')
      getFolioInfoData=[{
        total_amount:0,
        total_security_assets:0,
        unrealised_pl:0,
        total_pl_ratio:0,
        total_today_profit:0
      }]
    }
    this.setState({
      tableData:getData,
      getResultData:[],
      folioInfoData:getFolioInfoData,
      stockLData:[],
      stockRData:[],
      isLoading:false,
      isOnLoad:false,
      sortId:null,
      chartData:[{
        name:'no stock',
        y:100,
        color:'#828282'
      }]
    })
    if(isPC){
      setTimeout(()=>{
        this.handleScroll()
      }, 10)
    }
  }
  // market 下拉框返回的数据
  chgMarketDataFun(data){
    const {countryData,tabIndex,tabsData}=this.state
    let newData=data[0]['data']
    let currency=data[0]['cur']
    let countryCode=tabsData[tabIndex]['name']
    this.setState({
      chgCur:currency
    })
    this.props.cbtodaysCurFun(currency,countryCode)
    this.dealHeaderDataFun(newData,countryData,tabIndex)
  }
  // 处理数据--下拉框
  dealDropDownDataFun(resData){
    let dropDownData=[]
    let keysData=Object.keys(resData)
    for(let i in keysData){
      let datas=resData[keysData[i]]
      let total_amount=datas['total']['total_amount']
      let obj={
        cur:keysData[i],
        num:total_amount?total_amount:0,
        data:datas,
        img:common.imgArrFlag[keysData[i]]
      }
      dropDownData.push(obj)
    }
    dropDownData=common.imgDataFlagFun(dropDownData,'img')
    this.setState({
      marketpDownData:dropDownData
    })
  }
  // 恢复默认的
  resetSortDataFun(){
    const {sortData}=this.state
    for(let i in sortData){
      sortData[i]['upImg']=dropUpImg
      sortData[i]['downImg']=dropDownImg
    }
    this.setState({
      sortData:sortData,
      sortId:null
    })
  }
  //获取数据API--显示在tab栏的数据
  getTabNumData(size_current) {
    fetch(common.getHttpZs+"getNavTotal",{
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: sessionStorage.getItem("token"),
        tab:1
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if(res['status']==0){
        let count=res['data']['total_count']
        let portfolio=size_current?size_current:0
        let today_order=count['today_order']?count['today_order']:0
        let today_deal=count['today_deal']?count['today_deal']:0
        let data=[portfolio,today_order,today_deal]
        this.props.callbackTabNumData(data)
      }
    })
  }
  // 排序
  sortClick(key,sort,id){
    const {ChgThemeData}=this.props
    const {tableData,sortData}=this.state
    if(tableData[0]['len']==0) return
    this.resetSortDataFun()
    let resData=tableData[0]['data']
    for(let i in resData){
      let getData=[],noData=[]
      let datalist=resData[i]['datalist']
      for(let j in datalist){//当数据为-/undefined时放在数组的后面
        let tempsData=datalist[j][key]
        tempsData=='-'||typeof(tempsData) == 'undefined'?noData.push(datalist[j]):getData.push(datalist[j])
      }
      let newData=''
      if(id=='code'||id=='name'){
        newData=common.sortLetter(sort,getData,key)
      }else{
        newData=common.sortNumber(sort,getData,key)
      }
      resData[i]['datalist']=newData
    }
    // 排序--img
    let imgurl=sort=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
    let chgImg=sort=='asc'?'upImg':'downImg'
    let items=sortData.find(item=>item['id']==id)
    items[chgImg]=imgurl
    items['sortKey']=sort
    this.setState({
      tableData:tableData,
      sortData:sortData,
      sortId:id
    })
  }
  dropDownClick(itemIndex,index){
    const {tableData}=this.state
    for(let i in tableData){
      let data=tableData[i]['data']
      for(let j in data){
        if(itemIndex==j){
          let datalist=data[j]['datalist']
          for(let k in datalist){
            if(index==k){
              datalist[k]['isShow']=!datalist[k]['isShow']
            }
          }
        }
      }
    }
    this.setState({
      tableData:tableData
    })
  }
  // 跳转到quote页面
  toQuoteClick(data){
    const {locationName}=this.props
    const {isUSCur}=this.state
    if(isUSCur!=='US') return
    let pathName='/'+locationName[1]+'/Quote'
    data['ric']=typeof(data['ric'])=='undefined'||data['ric']==null?data['stock_code']+'.NB':data['ric']
    data['name']=data['stock_name']
    data['symbol']=data['stock_code']

    this.props.callbackPathnameApp(pathName)// 获取路由跳转--app.js
    sessionStorage.setItem('accountAddData',JSON.stringify(data))
    sessionStorage.setItem('navId','quote')
    sessionStorage.setItem('navIdAPP','quote')
  }
  // pc
  handleScroll(){
    const {isPC}=this.props
    let current=this.myRef.current
    if(current&&isPC){
      this.setState({
        isScrollShow:current.scrollHeight>current.clientHeight?true:false
      })
    } 
  }
  // mobile--滚动到一定位置头部粘住
  handleMobileScroll(){
    const {isMobile}=this.props
    const {headerStickyShow}=this.state
    if(!isMobile) return
    let current=this.myMobileRef.current
    let headerH=this.myHeaderRef.current.clientHeight
    if(headerStickyShow){
      if(current.scrollTop<20){
        this.setState({
          headerStickyShow:false
        })
      }
    }else{
      this.setState({
        headerStickyShow:current.scrollTop>headerH?true:false
      })
    }
  }
  // mobile--回到顶部
  cbGobackTopFun(){
    const {isPC}=this.props
    if(isPC){
      let current=this.myRef.current
      if(current){
        current.scrollTop=0
      }
    }else{
      let current=this.myMobileRef.current
      if(current){
        current.scrollTop=0
      }
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {ChgThemeData}=this.props
    const {sortId,sortData}=this.state
    if(ChgThemeData!==newProps['ChgThemeData']){
      if(sortId){
        let items=sortData.find(item=>item['id']==sortId)
        let imgurl=items['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
        let chgImg=items['sortKey']=='asc'?'upImg':'downImg'
        items[chgImg]=imgurl
        this.setState({
          sortData:sortData
        })
      }
    }
  }
  // 初始化
  componentDidMount(){ 
    this.getTableData()
  }
  render() {
    const {t,ChgThemeData,ChgLangData,isPC,store,bodyW,isTablet,isMobile,isSearchPageShow} = this.props
    const {tableData,isLoading,folioInfoData,tabsData,stockLData,stockRData,chartData,countryData,tabIndex,isUSCur,tipsImg0,tipsImg1,minW1370,isScrollShow,chgTab,isOnLoad,headerStickyShow,sortData,getResultData,marketpDownData,chgCur}=this.state
    return (
      <div 
        className={[
          APPstyles.flex_column,
          APPstyles.hPer_overflowH,
          styles.portfolio_con
        ].join(' ')}
      >
        <div
          ref={this.myTabRef}
          className={[
            styles.tabs_con,
            isMobile?APPstyles.flex_between:''
          ].join(' ')}
          style={{
            padding:isTablet?isMobile?0:'16px 16px 12px 16px':'12px 16px 0 16px',
            display:isOnLoad?'none':'',
            height:isMobile&&tabsData.length?52:'auto'
          }}
        >
          {
            tabsData.length&&
              <Tabs
                {...this.props}
                tag='portfolio'
                tabData={tabsData}
                callbackTabIndex={this.cbTabsIndex.bind(this)} 
              />
          }
        </div>
        <div 
          ref={this.myMobileRef} 
          onScroll={() => this.handleMobileScroll()}
          className={[
            APPstyles.flex_column,
            isMobile||(isTablet&&!isMobile&&isSearchPageShow)?APPstyles.flex1_auto:APPstyles.flex1_hidden,
            isMobile||(isTablet&&!isMobile&&isSearchPageShow)?APPstyles.touch:''
          ].join(' ')} 
          style={{
            paddingTop:isOnLoad&&isTablet?16:0,
            position:isMobile?'relative':'static'
          }}
        >
          {/* header */}
          <div ref={this.myHeaderRef}>
            <Header 
              {...this.props} 
              folioInfoData={folioInfoData}
              stockLData={stockLData}
              stockRData={stockRData}
              chartData={chartData}
              tabIndex={tabIndex}
              countryData={countryData}
              tableData={tableData}
              marketpDownData={marketpDownData}
              chgCur={chgCur}
              cbGobackTop={this.cbGobackTopFun.bind(this)} 
              cbChgMarketDataFun={this.chgMarketDataFun.bind(this)} 
              cbPortDetailShow={(data)=>{
                this.props.cbPortDetailShow(data)
              }}
            />
          </div>
          {/* table */}
          {isPC?
              <div
                className={[
                  styles.account_table,
                  isPC?APPstyles.hPer_overflowH:''
                ].join(' ')}
                style={{
                  padding:isPC?0:' 0 8px 30px 16px'
                }}
              >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                {tableData.length?
                  tableData.map((item,index) => {
                  return  <div 
                            key={index}
                            className={[
                              isPC?APPstyles.flex_column:'',
                              isPC?APPstyles.hPer_overflowH:''
                            ].join(' ')}
                          >
                            <div 
                              className={[
                                APPstyles.flex_between,
                                styles.account_table_oneheader
                              ].join(' ')}
                            >
                              <div className={[APPstyles.flex_topcenter,APPstyles.flex_1,ChgThemeData=='Light'?styles.table_oneheader_light:styles.table_oneheader_dark].join(' ')} style={{margin:isPC?'0 16px':'0 6px',padding:'0 16px'}}>
                                <img src={item['img']} className={APPstyles.img16} style={{marginLeft:0,marginRight:8,cursor:'default'}} alt='flag' />
                                <span>{t(item['country'])}{ChgLangData=='zh-CN'?'':' '}{t('Securities')} {'('+item['len']+')'}</span>
                              </div>
                            </div>
                            <div className={[styles.account_table_body,isPC?APPstyles.flex_column:'',isPC?APPstyles.hPer_overflowH:''].join(' ')}>
                              <div 
                                className={[
                                  APPstyles.flex_row,
                                  styles.account_table_bodyheader,
                                  styles.header_pc,
                                  styles.account_table_bodycommon,
                                  ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                  ChgLangData=='zh-CN'?APPstyles.fz12_zhcn:''
                                ].join(' ')}
                                style={{
                                  display:index==0?'':'none',
                                  margin: isPC?isScrollShow?'0 25px 0 16px':'0 16px':0
                                }}
                              >
                                <div>{t('Code')}</div>
                                <div style={{width:bodyW<=minW1370?item['len']==0?'42%':'32%':item['len']==0?'46%':'36%'}}>
                                  {t(ChgLangData=='zh-CN'?'name':'Name')}
                                </div>
                                <div className={styles.account_header_totalcost} style={{position:'relative'}}>
                                  <span style={{marginBottom:2}}>{t('Prev Day QTY')}</span>
                                  <span>{t('Current QTY')}</span>
                                  <Tooltip 
                                    title={t("Previous day quantity is the number of stock held in the portfolio as of previous day, excluding the number of stocks bought or sold today. If you have any questions, please contact customer service center: 0800-073-588")} 
                                    overlayClassName={ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm}
                                  >
                                    <img
                                      src={tipsImg0}
                                      alt="tips"
                                      className={[APPstyles.img16,APPstyles.transformY].join(' ')}
                                      style={{position:'absolute',right:-10,zIndex:9,display:ChgLangData==='zh-CN'?'none':''}}
                                      onMouseOver={()=>{
                                        let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                                        this.setState({
                                          tipsImg0:imgurl
                                        })
                                      }} 
                                      onMouseOut={()=>{
                                        this.setState({
                                          tipsImg0:tipImg
                                        })
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <div className={APPstyles.flex_row} style={{position:'relative',width:'10%',justifyContent:'flex-end',alignItems:'center'}}>
                                  <span>{t('Purchase Price1')}</span>
                                  <Tooltip 
                                    title={t("Purchase price displays the average purchase as of previous day, not taking into consideration for stocks bought/sold today. If you have any questions, please contact customer service center: 0800-073-588")} 
                                    overlayClassName={ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm}
                                  >
                                    <img
                                      src={tipsImg1}
                                      alt="tips"
                                      className={[APPstyles.img16,APPstyles.transformY].join(' ')}
                                      style={{
                                        marginLeft:2,
                                        position:'absolute',
                                        right:-12
                                      }}
                                      onMouseOver={()=>{
                                        let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                                        this.setState({
                                          tipsImg1:imgurl
                                        })
                                      }} 
                                      onMouseOut={()=>{
                                        this.setState({
                                          tipsImg1:tipImg
                                        })
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <div className={APPstyles.flex_row} style={{justifyContent:'flex-end',alignItems:'center'}}>
                                  {t('Last Price2')}
                                </div>
                                <div className={APPstyles.flex_row} style={{width:bodyW<=minW1370?'10%':'8%',justifyContent:'flex-end',alignItems:'center'}}>
                                  {t(chgTab=='US'?'Unrealised P/L1':'Unrealised P/L')}
                                  <div 
                                    className={[
                                      APPstyles.upDown_con,
                                      APPstyles.flex_column
                                    ].join(' ')}
                                  >
                                    <div 
                                      className={APPstyles.img_dropdown_up} 
                                      onClick={()=>{this.sortClick('unrealised_pl','asc','unrealised')}}
                                      onMouseOver={()=>{
                                        if(!isPC) return
                                        let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                                        let items=sortData.find(item=>item['id']=='unrealised')
                                        items['upImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }} 
                                      onMouseOut={()=>{
                                        if(!isPC) return
                                        let items=sortData.find(item=>item['id']=='unrealised')
                                        let imgurl=items['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                                        items['upImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }}
                                    >
                                      <img src={sortData[1]['upImg']} />
                                    </div>
                                    <div 
                                      className={APPstyles.img_dropdown_down} 
                                      onClick={()=>{this.sortClick('unrealised_pl','desc','unrealised')}}
                                      onMouseOver={()=>{
                                        if(!isPC) return
                                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                        let items=sortData.find(item=>item['id']=='unrealised')
                                        items['downImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }} 
                                      onMouseOut={()=>{
                                        if(!isPC) return
                                        let items=sortData.find(item=>item['id']=='unrealised')
                                        let imgurl=items['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg 
                                        items['downImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }}
                                    >
                                      <img src={sortData[1]['downImg']} />
                                    </div>
                                  </div>
                                </div>
                                <div className={APPstyles.flex_row} style={{width:bodyW<=minW1370?'10%':'8%',justifyContent:'flex-end',alignItems:'center'}}>
                                  {t('P/L Ratio')}
                                  
                                  <div 
                                    className={[
                                      APPstyles.upDown_con,
                                      APPstyles.flex_column
                                    ].join(' ')}
                                  >
                                    <div 
                                      className={APPstyles.img_dropdown_up} 
                                      onClick={()=>{this.sortClick('total_pl_ratio','asc','total_pl_ratio')}}
                                      onMouseOver={()=>{
                                        if(!isPC) return
                                        let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                                        let items=sortData.find(item=>item['id']=='total_pl_ratio')
                                        items['upImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }} 
                                      onMouseOut={()=>{
                                        if(!isPC) return
                                        let items=sortData.find(item=>item['id']=='total_pl_ratio')
                                        let imgurl=items['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                                        items['upImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }}
                                    >
                                      <img src={sortData[0]['upImg']} />
                                    </div>
                                    <div 
                                      className={APPstyles.img_dropdown_down} 
                                      onClick={()=>{this.sortClick('total_pl_ratio','desc','total_pl_ratio')}}
                                      onMouseOver={()=>{
                                        if(!isPC) return
                                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                        let items=sortData.find(item=>item['id']=='total_pl_ratio')
                                        items['downImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }} 
                                      onMouseOut={()=>{
                                        if(!isPC) return
                                        let items=sortData.find(item=>item['id']=='total_pl_ratio')
                                        let imgurl=items['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                                        items['downImg']=imgurl
                                        this.setState({
                                          sortData:sortData
                                        })
                                      }}
                                    >
                                      <img src={sortData[0]['downImg']} />
                                    </div>
                                  </div>
                                </div>
                                <div style={{paddingLeft:6,display:item['len']==0?'none':''}}>&nbsp;</div>
                              </div>
                              <div 
                                ref={this.myRef} 
                                onScroll={() => this.handleScroll()}
                                className={isPC?APPstyles.flex1_auto:''} 
                                style={{padding:isPC?'0 16px 30px 16px':0}}
                              >
                                {item['data'].map((itemData,itemIndex) => {
                                  let total = 0,upl = 0,pl_ratio = 0,cost = 0 
                                  folioInfoData[itemIndex]['total_amount']=total
                                  folioInfoData[itemIndex]['unrealised_pl']=upl
                                  folioInfoData[itemIndex]['total_pl_ratio']=pl_ratio
                                  store.folioInfoData=folioInfoData
                                  return  <div key={itemIndex}> 
                                            {itemData['datalist'].map((list,i) => {
                                            let data=store.getDataByRic(list['stock_id'])
                                            if (item['country']==='US') {
                                              data=store.getDataByRic(list['stock_code'] + '.NB')
                                            }
                                            let TRDPRC_1=data&&(typeof(data['TRDPRC_1'])=='undefined'||data['TRDPRC_1']==null?0:Number(data['TRDPRC_1']))
                                            let idv_total=data&&(list['current_qty']*TRDPRC_1*(1-list['fee_rate']))
                                            let idv_upl=data&&(list['current_qty']*(TRDPRC_1*(1-list['fee_rate'])-list['purchase_price']))
                                            let idv_cost=data&&(list['current_qty'] * list['purchase_price'])
                                            let idv_ratio=idv_cost==0?0:data&&(idv_upl / idv_cost * 100)
                                            idv_ratio=idv_ratio=='Infinity'||isNaN(idv_ratio)?0:idv_ratio
                                            total+=idv_total
                                            upl+=idv_upl
                                            cost+=idv_cost
                                            pl_ratio=cost==0?0:(upl/cost*100)
                                            folioInfoData[itemIndex]['total_amount']=total
                                            folioInfoData[itemIndex]['unrealised_pl']=upl
                                            folioInfoData[itemIndex]['total_pl_ratio']=pl_ratio
                                            list['total_pl_ratio']=idv_ratio
                                            store.folioInfoData=folioInfoData
                                            return <div key={i}>
                                                      <div 
                                                        className={[
                                                          APPstyles.flex_row,
                                                          styles.account_table_bodyBody,
                                                          styles.account_table_bodycommon,
                                                          ChgThemeData=='Light'?styles.account_table_bodyBody_light:'',
                                                          ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover
                                                        ].join(' ')}
                                                        style={{
                                                          borderBottom:list['isShow']?
                                                                        ChgThemeData=='Light'?'1px solid #FFFFFF':'1px solid #222429'
                                                                      :
                                                                        ChgThemeData=='Light'?'1px solid #E0E0E0':'1px solid #373737'
                                                        }}
                                                      >
                                                        <div 
                                                          className={[styles.account_cur,APPstyles.flex_row].join(' ')} 
                                                          style={{cursor:isUSCur=='US'?'pointer':'default'}} 
                                                          onClick={this.toQuoteClick.bind(this,list)}
                                                        >
                                                          <label className={APPstyles.fw700}>{common.valToUpperCase(list['stock_code'])} </label>
                                                          <label className={styles.account_name_cur} style={{display:list['currency']?'':'none'}}>{t(list['currency'])}</label>
                                                        </div>
                                                        <div 
                                                          className={[
                                                            ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,
                                                            styles.account_cur,
                                                            APPstyles.flex_row
                                                          ].join(' ')} 
                                                          style={{width:bodyW<=minW1370?'32%':'36%',cursor:isUSCur=='US'?'pointer':'default'}} 
                                                          onClick={this.toQuoteClick.bind(this,list)}
                                                        >
                                                          {common.valToUpperCase(list['stock_name'])}
                                                        </div>
                                                        <div className={styles.account_header_totalcost}>
                                                          <span style={{marginBottom:2}}>{common.transThousands(common.keepNum(list['current_qty'],0))}</span>
                                                          <span>{common.transThousands(common.keepNum(list['current_avail_qty'],0))}</span>
                                                          <img 
                                                            src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                                                            className={[
                                                              APPstyles.img18,
                                                              list['isShow']?styles.dropDown_img_up:styles.dropDown_img_down
                                                            ].join(' ')} 
                                                            onClick={this.dropDownClick.bind(this,itemIndex,i)}
                                                          />
                                                        </div>
                                                        <div style={{width:'10%'}}>{common.transThousands(common.keepNum(list['purchase_price'],2))}</div>
                                                        <div>{common.transThousands(common.keepNum(TRDPRC_1,2))}</div>
                                                        <div
                                                          className={Number(idv_upl)>0?APPstyles.color_red:Number(idv_upl)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                                                          style={{width:bodyW<=minW1370?'10%':'8%'}}
                                                        >
                                                          {common.addplusNum(common.transThousands(common.keepNum(idv_upl,2)))}
                                                        </div>
                                                        <div className={Number(idv_ratio)>0?APPstyles.color_red:Number(idv_ratio)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:bodyW<=minW1370?'10%':'8%'}}>{common.changeRate(common.addplusNum(common.transThousands(common.keepNum(idv_ratio,2))))}</div>
                                                        <div style={{paddingLeft:6}}>
                                                          <Button 
                                                            type='default' 
                                                            className={APPstyles.btn_blue} 
                                                            onClick={(e)=>{
                                                              e.stopPropagation()
                                                              this.props.tradeShowClick(list)
                                                            }}
                                                          >
                                                            {t('Trade')}
                                                          </Button>
                                                        </div>
                                                      </div>
                                                      {/* dropdown内容 */}
                                                      <div
                                                        className={[
                                                          APPstyles.flex_row,
                                                          styles.account_table_bodyBody,
                                                          styles.account_table_bodycommon,
                                                          ChgThemeData=='Light'?styles.account_table_bodyBody_light:''
                                                        ].join(' ')}
                                                        style={{
                                                          display:list['isShow']?'':'none'
                                                        }}
                                                      >
                                                        <span>&nbsp;</span>
                                                        <div className={APPstyles.flex_column} style={{width:bodyW<=minW1370?'42%':'46%',textAlign:'right'}}>
                                                          <span className={[APPstyles.color_grey,APPstyles.fz12].join(' ')} style={{marginBottom:2}}>{t('Currency1')}</span>
                                                          <span>{t(list['currency'])}</span>
                                                        </div>
                                                        <div className={APPstyles.flex_column} style={{width:'10%',textAlign:'right'}}>
                                                          <span className={[APPstyles.color_grey,APPstyles.fz12].join(' ')} style={{marginBottom:2}}>{t('Total Cost1')}</span>
                                                          <span>{common.transThousands(common.keepNum(idv_cost,2))}</span>
                                                        </div>
                                                        <div className={APPstyles.flex_column} style={{textAlign:'right'}}>
                                                          <span className={[APPstyles.color_grey,APPstyles.fz12].join(' ')} style={{marginBottom:2}}>{t('Total Amount')}</span>
                                                          <span>{common.transThousands(common.keepNum(idv_total,2))}</span>
                                                        </div>
                                                        <span style={{width:bodyW<=minW1370?'10%':'8%'}}>&nbsp;</span>
                                                        <span style={{width:bodyW<=minW1370?'10%':'8%'}}>&nbsp;</span>
                                                        <span style={{paddingLeft:6}}>&nbsp;</span>
                                                      </div>
                                                    </div>
                                            })
                                          }
                                          <div 
                                            className={itemIndex==(item['data'].length-1)?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark}
                                            style={{
                                              paddingBottom:itemIndex==item['data'].length-1?0:24
                                            }}
                                          >
                                          <div 
                                            className={[
                                              APPstyles.flex_row,
                                              styles.account_table_bodycommon,
                                              styles.account_table_bodyLast,
                                              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                            ].join(' ')}
                                          >
                                            <div style={{width:bodyW<=minW1370?item['len']==0?'80%':'70%':item['len']==0?'84%':'74%'}}>{t('Total')} ({t(itemData['currency'])})</div>
                                            
                                            <div 
                                              className={folioInfoData[itemIndex]['unrealised_pl']>0?APPstyles.color_red:folioInfoData[itemIndex]['unrealised_pl']<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                                              style={{width:bodyW<=minW1370?'10%':'8%'}}
                                            >
                                              {common.addplusNum(common.transThousands(common.keepNum(folioInfoData[itemIndex]['unrealised_pl'],2)))}
                                            </div>
                                            <div className={Number(folioInfoData[itemIndex]['total_pl_ratio'])>0?APPstyles.color_red:Number(folioInfoData[itemIndex]['total_pl_ratio'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:bodyW<=minW1370?'10%':'8%'}}>{common.changeRate(common.addplusNum(common.transThousands(common.keepNum(folioInfoData[itemIndex]['total_pl_ratio'],2))))}</div>
                                            <div style={{paddingLeft:6,display:item['len']==0?'none':''}}>&nbsp;</div>
                                          </div>
                                          </div>
                                        </div>
                                })}
                              </div>
                            </div>
                          </div>
                  })
                  :
                  <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
                }
              </div>
            :
              // 移动端
              <Mobile
                {...this.props}
                folioInfoData={folioInfoData}
                tableData={tableData}
                getResultData={getResultData}
                changeName={ChgLangData=='zh-CN'?'name':'Name'}
                isLoading={isLoading}
                isScrollShow={isScrollShow}
                tabIndex={tabIndex}
                headerStickyShow={headerStickyShow}
                sortData={sortData}
                chgTab={chgTab}
                cbGotoQuotePageFun={this.toQuoteClick.bind(this)} 
                dropDownClick={this.dropDownClick.bind(this)} 
                cbSortClickFun={this.sortClick.bind(this)} 
                detailShowClick={(data)=>{
                  this.props.tradeShowClick(data)
                }}
                cbHeaderStickyShowFun={()=>{
                  this.setState({
                    headerStickyShow:false
                  })
                }}
              />
          }
          {(isTablet&&!isMobile&&isSearchPageShow)||(isMobile)?
            <Disclaimer {...this.props} />
          :
              ''
          }
        </div>
        {isPC&&
          <Disclaimer {...this.props} />
        }
      </div>
    )
  }
}
export default withTranslation()(observer(Portfolio))
import React from 'react'
import { message } from 'antd'
import common from '@/asserts/js/common'
import ApplyComponents from '@/components/App/ApplyComponents'
import styles from './Login/index.less'

class NotFound extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uid:'',
      isApplyShow:false
    }
  }
  //登录---这个页面是给客户在网址输入对应的参数进行登录的，免除登录页面 
  loginFun(txid,data){
    fetch(common.getHttpZs+'access',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({txid:txid,data:data})
    })
    .then(res1 => res1.json())
    .then(res1 => {
      //本地存储token/uid
      if(res1['status']==0){
        sessionStorage.setItem('token',res1['data']['token'])
        sessionStorage.setItem('loginName','notFound')

        //WebCA
        if (common.enableWebca && !window.inAppDetector.isInApp()) {
          const memberid = res1['data']['uid'];
          const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
          window.WebCAQueryCertState(memberid, filter, (code, msg, token, data) => {
            if (code === 0 && data) {
              let d = JSON.parse(data);
              let rafunc = d.suggestAction;
              
              if (rafunc !== "None") { // Need to apply/renew cert
                const webcaInfoRequestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json'},
                  body: JSON.stringify({ token: res1['data']['token'], rafunc: rafunc })
                };
                
                //Get parameters for calling WebCAInvoke
                fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
                .then(response => response.json())
                .then(data => {
                  if (data.status === 0) {
                    data = data.data;
                    // window.setWebCAinWebview(true);
                    window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                    //呼叫WebCA執行對應raFunc的憑證作業
                    window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                      if (code===0 || code==="0000" || code===7000) {
                        this.checkeagreementFun(res1['data'])
                      } else {
                        this.setState({
                          isLoading: false
                        })
                        message.warning({
                          content: code + " " + msg
                        })
                      }
                      // window.WebCAQueryCertState(memberid, filter, (code, msg, token, data) => {
                      //   d = JSON.parse(data);
                      //   rafunc = d.suggestAction;
                      //   if (rafunc === "None") {
                      //     this.checkeagreementFun(res1['data'])
                      //   } else {
                      //     this.setState({
                      //       isLoading: false
                      //     })
                      //     message.warning({
                      //       content: "WebCA failed"
                      //     })
                      //   }
                      // });
                    });
                  } else {
                    this.setState({
                      isLoading: false
                    })
                    message.warning({
                      content: data.message
                    })
                  }
                })
              } else {
                this.checkeagreementFun(res1['data'])
              }
            } else {
              this.setState({
                isLoading: false
              })
              message.warning({
                content: code + " " + msg
              })
            }
          });
        } else {
          this.checkeagreementFun(res1['data'])
        }
      } else {
        //获取失败
        this.setState({
          isLoading: false
        })
        if(res1['status']==1001) {
          message.warning({
            content: "即將上線，敬請期待!!"
          })
        } else {
          message.warning({
            content: res1['error']?(res1['error']['Code'] + " " + res1['error']['Msg']):res1['message']
          })
        }
      }
    })
  }
  //登录---这个页面是给客户用POST输入对应的参数进行登录的，免除登录页面 
  loginFun2(token,uid,aid){
        //WebCA
        if (common.enableWebca && !window.inAppDetector.isInApp()) {
          const memberid = uid;
          const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
          window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
            if (code === 0 && data) {
              let d = JSON.parse(data);
              let rafunc = d.suggestAction;
              
              if (rafunc !== "None") { // Need to apply/renew cert
                const webcaInfoRequestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json'},
                  body: JSON.stringify({ token: token, rafunc: rafunc })
                };
                
                //Get parameters for calling WebCAInvoke
                fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
                .then(response => response.json())
                .then(data => {
                  if (data.status === 0) {
                    data = data.data;
                    // window.setWebCAinWebview(true);
                    window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                    //呼叫WebCA執行對應raFunc的憑證作業
                    window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                      if (code===0 || code==="0000" || code===7000) {
                        this.checkeagreementFun({token, uid, aid})
                      } else {
                        this.setState({
                          isLoading: false
                        })
                        message.warning({
                          content: code + " " + msg
                        })
                      }
                    });
                  } else {
                    this.setState({
                      isLoading: false
                    })
                    message.warning({
                      content: data.message
                    })
                  }
                })
              } else {
                this.checkeagreementFun({token, uid, aid})
              }
            } else {
              this.setState({
                isLoading: false
              })
              message.warning({
                content: code + " " + msg
              })
            }
          });
        } else {
          this.checkeagreementFun({token, uid, aid})
        }
  }
  //判断是否需要进入免责声明页面
  checkeagreementFun(item){
    const {locationName}=this.props
    let uid=item['uid']
    let aid=item['aid']
    fetch(common.getHttpAgree+'checkeagreement',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:item['token']
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        //已填写申请，则显示所有的页面
        if(res['data']['signed']){
          sessionStorage.setItem('uid',uid)
          sessionStorage.setItem('aidListId',aid)
          this.props.callbackUid(uid)
          let name='/'+locationName[1]+'/Quote'
          this.accountDataFun(name)
          return
        }
        //未填写申请，则显示免责声明页面
        this.setState({
          isApplyShow:true,
          isLoading:false,
          uid:uid,
          aid:aid
        })
      }
    })
  }
  // 获取uid
  callbackUid(data){
    this.props.callbackUid(data)
  }
  // 登录跳转到quote页面
  callbackpathname(data){
    this.props.callbackpathname(data)
  }
  // 获取账号列表
  accountDataFun(item){
    fetch(common.getHttpZs+'getAccountIdList',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']['aid_obj_list']
        let aidDataLen=Object.keys(data)
        let isLen=aidDataLen.length>1?'Y':'N'
        sessionStorage.setItem('aidListData',JSON.stringify(data))
        sessionStorage.setItem('isSwitchShow',isLen)
        sessionStorage.setItem('isSwitchFirstShow',isLen)
        sessionStorage.setItem('isLoginOnload','Y')
        this.props.callbackpathname(item)
      }
      this.setState({
        isApplyShow:null,
        isLoading:false
      })
    })
  }
  componentDidMount(){
    let uid = sessionStorage.getItem('uid')
    let token = sessionStorage.getItem('token')
    let aid = sessionStorage.getItem('aidListId')
    let target = sessionStorage.getItem('target')
    let lang=localStorage.getItem('lang')
    let theme=localStorage.getItem('theme')
    sessionStorage.clear() //清空存储数据
    localStorage.setItem('lang',lang?lang:'zh-CN')
    localStorage.setItem('theme',theme?theme:'Dark')

    if (uid!=null && token!=null && aid!=null) {
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('loginName','notFound')
      if(target!==null){
        sessionStorage.setItem('target',target)
        sessionStorage.setItem('navId',target=='trade'?'trade':'quote')
      }
      this.loginFun2(token,uid,aid)
    }
    //在地址栏输入对应的参数进行登录，免登录页面
    let txid=common.getUrlSearch('txid')
    let data=common.getUrlSearch('data')
    if((!txid && typeof(txid)!='undefined' && txid!=0)||(!data && typeof(data)!='undefined' && data!=0)||txid==''||data=='') return
    txid = decodeURIComponent(txid)
    data = decodeURIComponent(data)
    this.loginFun(txid,data)
  }
  render() {
    const {isApplyShow,uid,aid}=this.state
    return (
      <div className={isApplyShow?'':styles.notFound}>
        {
          //免责声明页面
          isApplyShow?
          <ApplyComponents 
            {...this.props}
            uid={uid}
            aid={aid}
            callbackUid={this.callbackUid.bind(this)} 
            callbackpathname={(data)=>{this.accountDataFun(data)}} 
          />
        :
          <span style={{display:isApplyShow?'none':''}}></span>
        }
      </div>
    )
  }
}
export default NotFound
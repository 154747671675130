import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const RSI = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(14);
  const [ta, setTa] = useState("ema");
  const [color, setColor] = useState("008020");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [enable2, setEnable2] = useState(false);
  const [period2, setPeriod2] = useState(9);
  const [ta2, setTa2] = useState("ema");
  const [color2, setColor2] = useState("9933FF");
  const [thickness2, setThickness2] = useState(2);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(true);

  const [buy, setBuy] = useState(25);
  const [sale, setSale] = useState(75);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(
    ta + "-" + period + "," + ta2 + "-" + period2
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.RSI_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const RSI = localChartSetting.option;

        const atr1 = RSI.atr[0]
        const atr2 = RSI.atr[1]

        let p1 = {
          enabled: atr1.enabled,
          period: atr1.period,
          ta: atr1.ta,
          color: atr1.color,
          thickness: atr1.thickness,
          style: atr1.style,
          showTracker: atr1.showTracker,
        };
        let p2 = {
          enabled: atr2.enabled,
          period: atr2.period,
          ta: atr2.ta,
          color: atr2.color,
          thickness: atr2.thickness,
          style: atr2.style,
          showTracker: atr2.showTracker,
        };
        let atr = [p1, p2];
    
        let v = "";
        if (atr1.enabled) {
          v = v + atr1.ta + "-" + atr1.period + ",";
        }
        if (atr2.enabled) {
          v = v + atr2.ta + "-" + atr2.period;
        }
    
        setValue(v);
    
        props.onTAValueChange({
          ta: "RSI",
          name: `${t("chart5.TA.RSI")} (RSI)`,
          option: {
            buy: RSI.buy,
            sale: RSI.sale,
            atr,
            height: RSI.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const RSI = props.localChartSetting.RSI_Option.option;
    const atr1 = RSI.atr[0]
    const atr2 = RSI.atr[1]

    setEnable(atr1.enabled);
    setPeriod(parseInt(atr1.period) || period);
    setTa(atr1.ta);
    setColor(atr1.color);
    setThickness(parseInt(atr1.thickness) || thickness);
    setStyle(atr1.style);
    setShowTracker(atr1.showTracker);

    setEnable2(atr2.enabled);
    setPeriod2(parseInt(atr2.period) || period2);
    setTa2(atr2.ta);
    setColor2(atr2.color);
    setThickness2(parseInt(atr2.thickness) || thickness2);
    setStyle2(atr2.style);
    setShowTracker2(atr2.showTracker);

    setBuy(parseInt(RSI.buy) || buy);
    setSale(parseInt(RSI.sale) || sale);
    setHeight(RSI.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    let p1 = {
      enabled: enable,
      period: period,
      ta: ta,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
    };
    let p2 = {
      enabled: enable2,
      period: period2,
      ta: ta2,
      color: color2,
      thickness: thickness2,
      style: style2,
      showTracker: showTracker2,
    };
    let atr = [p1, p2];

    let v = "";
    if (enable) {
      v = v + ta + "-" + period + ",";
    }
    if (enable2) {
      v = v + ta2 + "-" + period2;
    }

    setValue(v);

    props.onTAValueChange({
      ta: "RSI",
      name: `${t("chart5.TA.RSI")} (RSI)`,
      option: {
        buy: buy,
        sale: sale,
        atr,
        height: height,
      },
      value: value,
    });
  }, [
    enable,
    period,
    ta,
    color,
    thickness,
    style,
    showTracker,
    enable2,
    period2,
    ta2,
    color2,
    thickness2,
    style2,
    showTracker2,
    buy,
    sale,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const RSI = props.localChartSetting.RSI_Option.option;
    const atr1 = RSI.atr[0]
    const atr2 = RSI.atr[1]

    setEnable(atr1.enabled);
    setPeriod(parseInt(atr1.period) || period);
    setTa(atr1.ta);
    setColor(atr1.color);
    setThickness(parseInt(atr1.thickness) || thickness);
    setStyle(atr1.style);
    setShowTracker(atr1.showTracker);

    setEnable2(atr2.enabled);
    setPeriod2(parseInt(atr2.period) || period2);
    setTa2(atr2.ta);
    setColor2(atr2.color);
    setThickness2(parseInt(atr2.thickness) || thickness2);
    setStyle2(atr2.style);
    setShowTracker2(atr2.showTracker);

    setBuy(parseInt(RSI.buy) || buy);
    setSale(parseInt(RSI.sale) || sale);
    setHeight(RSI.height);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(14);
    setTa("ema");
    setColor("008020");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setEnable2(false);
    setPeriod2(9);
    setTa2("ema");
    setColor2("9933FF");
    setThickness2(2);
    setStyle2("s");
    setShowTracker2(true);

    setBuy(25);
    setSale(75);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "P1":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "P2":
        if (enable2) setEnable2(false);
        else setEnable2(true);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "P1") setPeriod(e.target.value);
    else setPeriod2(e.target.value);
  };

  // e: any, type: string
  const onTAChange = (e, type) => {
    if (type === "P1") setTa(e.target.value);
    else setTa2(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "P1") setThickness(e.target.value);
    else setThickness2(e.target.value);
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "P1") setStyle(e.target.value);
    else setStyle2(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "P1":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "P2":
        if (showTracker2) setShowTracker2(false);
        else setShowTracker2(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "RSI-C1":
        setColor(props.selectedColor);
        break;
      case "RSI-C2":
        setColor2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  // e: any, type: string
  const onValueChange = (e, type) => {
    if (type === "buy") setBuy(e.target.value);
    else setSale(e.target.value);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("RSI")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.RSI")} (RSI) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("RSI")}
        />
      </div>

      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("P1")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 1</div>
            <div
              className="selectbox taparam"
              onChange={(e) => onTAChange(e, "P1")}
            >
              <select defaultValue={ta} style={{ width: "60px" }} value={ta}>
                <option value="ema">EMA</option>
                <option value="sma">SMA</option>
                <option value="mema">MEMA</option>
                <option value="wma">WMA</option>
              </select>
            </div>

            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "30px" }}
                onChange={(e) => onPeriodChange(e, "P1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "RSI-C1", t("chart5.setting"))}
            ></button>

            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P1")}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e, "P1")}>
              <select id="msi-ta-RSI-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("P1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enable2 ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              className={enable2 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("P2")}
            ></div>
            <div className="taparamlbl">{t("chart5.showValue")} 2</div>
            <div
              className="selectbox taparam"
              onChange={(e) => onTAChange(e, "P2")}
            >
              <select defaultValue={ta2} style={{ width: "60px" }} value={ta2}>
                <option value="ema">EMA</option>
                <option value="sma">SMA</option>
                <option value="mema">MEMA</option>
                <option value="wma">WMA</option>
              </select>
            </div>

            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "30px" }}
                onChange={(e) => onPeriodChange(e, "P2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "RSI-C2", t("chart5.setting"))
              }
            ></button>

            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P2")}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e, "P2")}>
              <select id="msi-ta-RSI-p1-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("P2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="floatmsibox" style={{ display: "flex" }}>
          <div className="menusubitem">
            <div className="taparamlbl">
              {t("chart5.buy")} {t("chart5.signal")}
            </div>
            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="0"
                max="100"
                value={buy}
                maxLength={4}
                style={{ width: "30px" }}
                onChange={(e) => onValueChange(e, "buy")}
              />
            </div>
          </div>
          <div className="menusubitem">
            <div className="taparamlbl">
              {t("chart5.sell")} {t("chart5.signal")}
            </div>
            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="0"
                max="100"
                value={sale}
                maxLength={4}
                style={{ width: "30px" }}
                onChange={(e) => onValueChange(e, "sale")}
              />
            </div>
          </div>
        </div>

        <div className="menusubitem">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>

            <div className="selectbox taparam">
              <select defaultValue="1" onChange={(e) => onHeightChange(e)} value={height}>
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

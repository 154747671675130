import React from 'react'
import { withTranslation } from 'react-i18next'
import { Spin } from 'antd'
import common from '../../asserts/js/common'
import APPstyles from '../../App.less'
import styles from './index.less'

class DropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  //下拉框--选中某个
  choiceOneClick(data,index,e){
    e.stopPropagation()
    this.props.cbDropDownData([data,index])
  }
  // 初始化
  componentDidMount(){
    
  }
  render() {
    const {t,ChgThemeData,data,isLoading,tag,tag2,isHasBuySelll} = this.props
    return (
      <div 
        className={[
          APPstyles.flex_row,
          styles.select_con,
          ChgThemeData=='Light'?tag2=='selectCurSimple'&&isHasBuySelll?'':styles.select_con_light:''
        ].join(' ')} 
        style={{
          top:tag2=='selectCurSimple'?45:0
        }}
      >
        <div className={[styles.select_con_out].join(' ')}>
          {
            data.length>0?
            
            data.map((item,index) => {
              return  <div 
                        className={[
                          APPstyles.flex_row,
                          styles.options_one,
                          ChgThemeData=='Light'?tag2=='selectCurSimple'&&isHasBuySelll?APPstyles.color_white:APPstyles.color_theme_black:APPstyles.color_white,
                          tag=='selectCurrency'?'':ChgThemeData=='Light'?tag2=='selectCurSimple'&&isHasBuySelll?APPstyles.bg_1b1b1b_hover:APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover
                        ].join(' ')} 
                        style={{cursor:tag=='selectCurrency'?'default':'pointer'}}
                        key={index} 
                        onClick={this.choiceOneClick.bind(this,item,index)}
                      >
                        <img src={item['img']} className={APPstyles.img20} style={{display:item['img']?'':'none'}} alt='flag' />
                        {
                          tag=='inputcon'?
                            <span className={ChgThemeData=='Light'?tag2=='selectCurSimple'&&isHasBuySelll?APPstyles.color_white:APPstyles.color_theme_black:APPstyles.color_white}>{item['symbol']}</span>
                          :
                            <span className={tag=='DividendHistory'?ChgThemeData=='Light'?tag2=='selectCurSimple'&&isHasBuySelll?APPstyles.color_cur:APPstyles.color_009E9C:APPstyles.color_cur:''}>{item[tag=='selectCurrency'?'symbol':'cur']}</span>
                        }
                        {
                          tag=='inputcon'?
                            <span className={ChgThemeData=='Light'?tag2=='selectCurSimple'&&isHasBuySelll?APPstyles.color_cur:APPstyles.color_009E9C:APPstyles.color_cur}>{item['name']}</span>
                          :
                          <span>{common.transThousands(common.keepNum(item['num'],2))}</span>
                        }
                      </div>
              })
            :
              <div className={[APPstyles.isLoading,styles.options_nodata].join(' ')} style={{display:isLoading?'none':'',color:'#CCC'}}>
                {t(tag=='portfolio'||tag=='selectCurrency'?'System busy, please try later':'No datas')}
              </div> 
          }
          {/* 加载中 */}
          {
            tag=='portfolio'||tag=='selectCurrency'?
              <div className={[APPstyles.isLoading,APPstyles.flex_column,APPstyles.flex_center,styles.options_loading].join(' ')} style={{display:isLoading?'':'none'}}>
                <span style={{position: 'relative',top: 20,color:'#CCC'}}>{t('Loading, please wait')}</span>
                <Spin spinning={isLoading} />
              </div>
            :
            <div style={{display:isLoading?'':'none'}}><Spin spinning={isLoading} /></div> 
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(DropDown)
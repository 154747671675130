import { makeAutoObservable } from "mobx";

class AlertsStore {
  count = 0;
  isNotification = localStorage.getItem("isNotification")
    ? localStorage.getItem("isNotification") === "true"
      ? true
      : false
    : true;
  isSound = localStorage.getItem("isSound")
    ? localStorage.getItem("isSound") === "true"
      ? true
      : false
    : true;
  isFirstFetch = true;

  constructor(value) {
    makeAutoObservable(this);
    if (!localStorage.getItem("isNotification")) {
      localStorage.setItem("isNotification", "true");
    }
    if (!localStorage.getItem("isSound")) {
      localStorage.setItem("isSound", "true");
    }
  }

  setCount(num) {
    this.count = num;
  }

  setIsNotification(bool) {
    this.isNotification = bool;
    localStorage.setItem("isNotification", `${bool}`);
  }

  setIsSound(bool) {
    this.isSound = bool;
    localStorage.setItem("isSound", `${bool}`);
  }

  setIsFirstFetch() {
    this.isFirstFetch = false;
  }
}

export default AlertsStore;

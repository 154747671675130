import React from 'react';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import APPstyles from '@/App.less';
import styles from './index.less';
import PaginationCon from '../../Pagination/index';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollShow:false,
    }
    this.myRef = React.createRef()
  }
  isScrollShowFun(){
    let current=this.myRef.current;
    if(current){
      this.setState({
        isScrollShow:current.scrollHeight>current.clientHeight?true:false,
      });
    }
  }
  componentDidUpdate(newProps){
    const {isLoading} = this.props
    if(isLoading!==newProps['isLoading']){
      setTimeout(()=>{
        this.isScrollShowFun();
      }, 100);
    }
  }
  render() {
    const {t,history,isLoading,ChgThemeData,isMobile} = this.props
    const {isScrollShow}=this.state
    return (
      <div className={[APPstyles.flex_column,APPstyles.flex1_hidden].join(' ')}>
        {isMobile&&
          <div className={[APPstyles.flex_topcenter,styles.subTitleCon,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
            <span className={styles.childYear}>&nbsp;</span>
            <span className={APPstyles.flex_1}>{t('Overseas Income1')}</span>
            <span className={APPstyles.flex_1}>{t('Mainland China Income')}</span>
          </div>
        }
        <div
          className={[
            styles.header,
            styles.common,
            APPstyles.flex_row,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile&&(ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white),
          ].join(' ')}
          style={{padding:isScrollShow?'3px 25px 3px 16px':'3px 16px'}}
        >
          <span className={[styles.childYear,APPstyles.flex_topcenter].join(' ')}>{t('Year')}</span>
          <div className={[styles.child,APPstyles.flex_column,APPstyles.flex_1].join(' ')}>
              <span>{t('Profit Income')}</span>
              <span>{t('Interest Income')}</span>
              <span>{t('Transaction Proceeds')}</span>
          </div>
          <div className={[styles.child,APPstyles.flex_column,APPstyles.flex_1].join(' ')}>
              <span>{t('Profit Income')}</span>
              <span>{t('Interest Income')}</span>
              <span>{t('Transaction Proceeds')}</span>
          </div>
        </div>
        <div
          ref={this.myRef}
          className={[APPstyles.flex1_auto,APPstyles.paddingBottom20].join(' ')} 
          style={{position:isLoading?'static':'relative'}}
        >
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length>0?
            history.map((item,index) => {
              return (
                <div
                  key={index}
                  className={[
                    styles.body,
                    styles.common,
                    APPstyles.flex_row,
                    ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                  ].join(' ')}
                >
                  <span className={[styles.childYear,APPstyles.flex_topcenter].join(' ')}>{t(item['year'])}</span>
                  <div className={[styles.child,APPstyles.flex_column,APPstyles.flex_1].join(' ')}>
                    <span>{t(common.transThousands(common.keepNum(item['overseas_profit_income'],0)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['overseas_interest_income'],2)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['overseas_transaction_proceeds'],0)))}</span>
                  </div>
                  <div className={[styles.child,APPstyles.flex_column,APPstyles.flex_1].join(' ')}>
                    <span>{t(common.transThousands(common.keepNum(item['china_profit_income'],2)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['china_interest_income'],2)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['china_transaction_proceeds'],2)))}</span>
                  </div>
                </div>
              );
            }):
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);
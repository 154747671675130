import React, { useEffect, useState, useRef } from "react";
import { Spin, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";

import common from "@/asserts/js/common";
import useIsFirstRender from "@/components/Hooks/useIsFirstRender";
import DropDown from "@/components/DropDown";
import { ReactComponent as IconEdit } from "@/asserts/imgs/svg/square_edit_outline_24px.svg";
import { ReactComponent as IconTrash } from "@/asserts/imgs/svg/trash_can_outline_24px.svg";
import { ReactComponent as IconHelp } from "@/asserts/imgs/svg/help-circle.svg";
// import { ReactComponent as IconInfo } from "@/asserts/imgs/svg/info_24px.svg";
// import { ReactComponent as IconArrowDown } from "@/asserts/imgs/svg/Icon_arrow_down.svg";

import styles from "./ActiveAlerts.less";
import APPstyles from "@/App.less";
import { 
  searchImg,
  closeImg
} from '@/asserts/imgs/common/index'

const ActiveAlerts = (props) => {
  const { t } = useTranslation();
  const isFirstRender = useIsFirstRender();
  const {
    store,
    bodyW,
    minbodyW,
    mobilebodyW,
    bodyH,
    ChgThemeData,
    ChgLangData,
    tradeData,
    isTablet,
    isMobile,
  } = props;

  const [fetchSignal, setFetchSignal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isFinishFetch, setIsFinishFetch] = useState(false); // condition for showing pagination
  const [menuTag, setMenuTag] = useState();
  const [counts, setCounts] = useState(0);
  const [listData, setListData] = useState([]);
  const [listIndex, setListIndex] = useState();
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState();
  const [sortType, setSortType] = useState("create_dt_desc"); // from active alert api: cond_asc, cond_desc, create_dt_asc, create_dt_desc

  // powersearch state & ref
  const [arrPowersearchData, setArrPowersearchData] = useState([]);
  const [isLoadingPowersearch, setIsLoadingPowersearch] = useState(false);
  const [isShowPowersearch, setIsShowPowersearch] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState();
  const [selectedRIC, setSelectedRIC] = useState();
  const refIsClickedPowersearchResult = useRef(false);

  const refScrollArea = useRef();

  const ARR_HEADER = [
    t("alerts.code"),
    t("alerts.name"),
    // <div
    //   className={styles.sort_button}
    //   onClick={() => {
    //     onClickSort("condition");
    //   }}
    // >
    //   {t("alerts.condition")}
    //   <IconArrowDown />
    // </div>,
    t("alerts.condition"),
    <>
      {t("alerts.targetPrice")}/<br />
      {t("alerts.percentChg")}
    </>,
    "",
  ];

  const ARR_HEADER_MOBILE = [
    t("alerts.name"),
    // <div
    //   className={styles.sort_button}
    //   onClick={() => {
    //     onClickSort("condition");
    //   }}
    // >
    //   {t("alerts.condition")}
    //   <IconArrowDown />
    // </div>,
    t("alerts.condition"),
    <>
      {t("alerts.targetPrice")}/<br />
      {t("alerts.percentChg")}
    </>,
    "",
  ];

  const DEFAULT_PAGE_SIZE = 10;
  let fromSlice = (page - 1) * DEFAULT_PAGE_SIZE;
  let toSlice = page * DEFAULT_PAGE_SIZE;
  let selectedHeader = isMobile ? ARR_HEADER_MOBILE : ARR_HEADER;
  let isMaxPage = page * DEFAULT_PAGE_SIZE >= counts;
  let isHidePaginate = counts <= 10 ? true : false;

  useEffect(() => {
    fetchData();
  }, [fetchSignal]);

  useEffect(() => {
    // clear timeout
    currentTimeout && clearTimeout(currentTimeout);

    // exit condition
    if (isFirstRender || inputValue === "") {
      isShowPowersearch && setIsShowPowersearch(false);
      return;
    }

    // setTimeout to fetch data
    setCurrentTimeout(
      setTimeout(() => {
        fetchPowersearchData();
      }, 500)
    );
    setSelectedRIC();
  }, [inputValue]);

  const fetchData = () => {
    let token = window.sessionStorage.getItem("token");

    setIsLoading(true);

    fetch(common.getHttp + "alert/active-alert", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        stockid: selectedRIC ? selectedRIC : undefined,
        sort: sortType,
        recperpage: DEFAULT_PAGE_SIZE,
        pn: page,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          let datalist = res.datalist.map((item) => ({
            alertId: item.alert_id,
            ric: item.stock_code,
            symbol: item.stock_symbol,
            name: item.stock_name,
            condition: item.condition,
            targetPrice: item.price,
            goodUntil: item.good_until,
          }));

          if (isMobile && page !== 1) {
            setListData([...listData, ...datalist]);
          } else {
            setListData(datalist);
          }
          setCounts(Number(res.total));
          setIsLoading(false);

          // get one more page to fit ui in mobile ( bug fix for long height phone like iphone 12 pro)
          if (isMobile && page === 1 && res.total >= 10) {
            setPage(2);
            setFetchSignal(fetchSignal + 1);
          }
        } else {
          setListData([]);
          setCounts(0);
          setPage(1);
          setIsLoading(false);
        }

        // first fetch only
        if (!isFinishFetch) {
          setIsFinishFetch(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setListData([]);
        setCounts(0);
        setPage(1);
        setIsLoading(false);

        // first fetch only
        if (!isFinishFetch) {
          setIsFinishFetch(true);
        }
      });
  };

  const fetchDeleteAlert = () => {
    let token = window.sessionStorage.getItem("token");

    setIsLoading(true);

    fetch(common.getHttp + "alert/remove", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        alertid: selectedDeleteItem.alertId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
        }

        setIsLoadingDelete(false);
        setIsShowDeleteModal(false);
        if (isMobile) {
          setListData([]);
          setPage(1);
        }
        setFetchSignal(fetchSignal + 1);
      })
      .catch((err) => {
        setIsLoadingDelete(false);
        setIsShowDeleteModal(false);
      });
  };

  const fetchPowersearchData = () => {
    let token = window.sessionStorage.getItem("token");

    // return if re-search after clcik item
    if (refIsClickedPowersearchResult.current) {
      refIsClickedPowersearchResult.current = false;
      return;
    }

    setIsLoadingPowersearch(true);
    setIsShowPowersearch(true);

    fetch(common.getHttp + "powersearch", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        k: inputValue,
        limit: DEFAULT_PAGE_SIZE,
        tradable: false,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          let resData = res["data"]["datalist"];
          let getData =
            resData.length > 0
              ? common.imgDataFlagFun(resData, "exchange_country_desc")
              : [];

          setArrPowersearchData(getData);
          setIsLoadingPowersearch(false);
          return;
        }

        setArrPowersearchData([]);
        setIsLoadingPowersearch(false);
      })
      .catch((err) => {
        console.error(err);
        setArrPowersearchData([]);
        setIsLoadingPowersearch(false);
      });
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3}){0,10}(?!\d))/g, ",");
  };

  const onChangeInput = (evt) => {
    setPage(1);
    setInputValue(evt.target.value);
    if (evt.target.value === "") {
      setSelectedRIC("");
      setFetchSignal(fetchSignal + 1);
    }
  };

  const onClickClear = () => {
    setInputValue("");
    if (selectedRIC) {
      setSelectedRIC("");
      setFetchSignal(fetchSignal + 1);
    }
  };

  const onClickTrash = (item) => {
    setSelectedDeleteItem(item);
    setIsShowDeleteModal(true);
  };

  const onClickEdit = (item) => {
    props.setAlertDetail(item);
    props.setPage(0);
  };

  const onClickPowersearchResult = (data) => {
    refIsClickedPowersearchResult.current = true;
    setIsShowPowersearch(false);
    setInputValue(data[0].symbol);
    setSelectedRIC(data[0].ric);
    setPage(1);
    setFetchSignal(fetchSignal + 1);
  };

  // const onClickSort = (type) => {
  //   if (type === "condition") {
  //     setSortType(sortType === "cond_desc" ? "cond_asc" : "cond_desc");
  //   }

  //   if (isMobile) {
  //     setPage(1);
  //   }
  //   setFetchSignal(fetchSignal + 1);
  // };

  const onScroll = () => {
    // exit condition, onScroll (i.e. infinite scroll) is for mobile only
    if (!isMobile || isMaxPage || isLoading) {
      return;
    }

    if (
      refScrollArea.current.scrollHeight -
        refScrollArea.current.scrollTop -
        refScrollArea.current.clientHeight <
      10
    ) {
      setPage(page + 1);
      setFetchSignal(fetchSignal + 1);
    }
  };

  // obsoleted, filter is done in api call now
  // const filterListData = () => {
  //   return listData.filter((item) => {
  //     return (
  //       item.symbol.toLowerCase().match(inputValue.toLowerCase()) ||
  //       item.name.toLowerCase().match(inputValue.toLowerCase())
  //     );
  //   });
  // };

  return (
    <div style={{ overflowY: isMobile ? undefined : "auto" }}>
      <div
        className={[
          styles.active_alerts,
          isTablet ? styles.tablet : "",
          isMobile ? styles.mobile : "",
          ChgThemeData === "Light" ? styles.light : "",
          ChgLangData !== "en-US" ? styles.chinese : "",
        ].join(" ")}
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}>
          <Spin spinning={isLoading} />
        </div>
        <span
          className={[
            styles.title,
            menuTag === "list"
              ? ChgThemeData === "Light"
                ? APPstyles.borderTop_light
                : APPstyles.borderTop_dark
              : "",
            ChgThemeData === "Light"
              ? APPstyles.borderBottom_light
              : APPstyles.borderBottom_dark,
          ].join(" ")}
          // style={{ padding: menuTag == "list" ? "0 8px" : "8.5px 8px" }}
        >
          <img
            src={searchImg}
            className={[APPstyles.img16, styles.icon_search].join(" ")}
            style={{
              display: inputValue ? "none" : "",
            }}
            alt="search"
          />
          <input
            type="text"
            className={[
              styles.input,
              inputValue ? styles.typing : "",
              ChgThemeData === "Light"
                ? APPstyles.inputcon_light
                : APPstyles.inputcon_dark,
            ].join(" ")}
            placeholder={`${t("alerts.name")}/${t("alerts.symbol")}`}
            value={inputValue}
            onChange={onChangeInput}
          />
          <img
            src={closeImg}
            className={[APPstyles.img16, styles.icon_clear].join(" ")}
            style={{
              display: inputValue ? "" : "none",
            }}
            alt="clear"
            onClick={onClickClear}
          />
          {isShowPowersearch && (
            <div className={styles.dropdown}>
              <DropDown
                {...props}
                tag="inputcon"
                data={arrPowersearchData}
                isLoading={isLoadingPowersearch}
                cbDropDownData={onClickPowersearchResult}
              />
            </div>
          )}
        </span>

        <div
          className={[
            APPstyles.flex_row,
            styles.header,
            styles.common,
            bodyW > minbodyW ? "" : styles.common_min,
            ChgThemeData === "Light"
              ? APPstyles.borderBottom_light
              : APPstyles.borderBottom_dark,
              isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
          ].join(" ")}
        >
          {selectedHeader.map((item, index) => {
            return (
              <span
                key={index}
                // style={{
                //   display: bodyW > minbodyW ? "" : index == 1 ? "none" : "",
                // }}
              >
                {item}
              </span>
            );
          })}
        </div>
        <div
          ref={refScrollArea}
          className={styles.body}
          style={{
            // height: isMobile ? bodyH - 155 : undefined,
            height: isMobile ? bodyH - 180 : undefined,
            overflowY: isMobile ? "overlay" : undefined,
          }}
          onScroll={onScroll}
        >
          {!isFinishFetch && <div className={styles.empty_area}></div>}
          {
            // filterListData()?.length ? (
            //   filterListData()
            // .slice(fromSlice, toSlice)
            listData.length ? (
              listData.map((item, index) => {
                return (
                  <div
                    className={[
                      APPstyles.flex_row,
                      styles.body_one,
                      styles.common,
                      bodyW > minbodyW ? "" : styles.common_min,
                      bodyW > minbodyW ? "" : styles.body_one_min,
                      ChgThemeData === "Light"
                        ? APPstyles.borderBottom_light
                        : APPstyles.borderBottom_dark,
                      listIndex === index
                        ? ChgThemeData === "Light"
                          ? APPstyles.bg_list_light
                          : APPstyles.bg_dark
                        : "",
                      bodyW > minbodyW
                        ? ChgThemeData === "Light"
                          ? APPstyles.bg_event_light
                          : APPstyles.bg_1b1b1b_hover
                        : "",
                    ].join(" ")}
                    key={index}
                  >
                    <span
                      className={bodyW > minbodyW ? "" : APPstyles.flex_column}
                    >
                      <label
                        className={[
                          ChgThemeData === "Light"
                            ? APPstyles.color_theme_black
                            : APPstyles.color_white,
                          styles.bold,
                        ].join(" ")}
                      >
                        {common.valToUpperCase(item.symbol)}
                      </label>
                      {isMobile && (
                        <span
                          className={[
                            APPstyles.ellipsis,
                            APPstyles.fw500,
                            styles.name_mobile,
                            ChgThemeData === "Light"
                              ? APPstyles.color_009E9C
                              : APPstyles.color_cur,
                          ].join(" ")}
                        >
                          {common.valToUpperCase(item.name)}
                        </span>
                      )}
                    </span>
                    {!isMobile && (
                      <span
                        className={[
                          APPstyles.ellipsis,
                          APPstyles.fw500,
                          ChgThemeData === "Light"
                            ? APPstyles.color_009E9C
                            : APPstyles.color_cur,
                        ].join(" ")}
                      >
                        {common.valToUpperCase(item.name)}
                      </span>
                    )}
                    <span
                      className={
                        ChgThemeData === "Light"
                          ? APPstyles.color_theme_black
                          : APPstyles.color_white
                      }
                    >
                      {t(
                        `alerts.${
                          item.targetPrice[item.targetPrice.length - 1] === "%"
                            ? `${item.condition}Percent`
                            : item.condition
                        }`
                      )}
                    </span>
                    <span
                      className={
                        Number(item["NETCHNG_1"]) > 0
                          ? APPstyles.color_red
                          : Number(item["NETCHNG_1"]) < 0
                          ? APPstyles.color_green
                          : ChgThemeData === "Light"
                          ? APPstyles.color_theme_black
                          : APPstyles.color_white
                      }
                    >
                      {numberWithCommas(item.targetPrice)}
                    </span>
                    <span
                      className={
                        Number(item["PCTCHNG"]) > 0
                          ? APPstyles.color_red
                          : Number(item["PCTCHNG"]) < 0
                          ? APPstyles.color_green
                          : ChgThemeData === "Light"
                          ? APPstyles.color_grey
                          : APPstyles.color_white
                      }
                    >
                      <IconTrash
                        className={[
                          styles.click_icon,
                          ChgThemeData === "Light"
                            ? APPstyles.hover_icon_light
                            : APPstyles.hover_icon,
                        ].join(" ")}
                        onClick={() => onClickTrash(item)}
                      />
                      <IconEdit
                        className={[
                          styles.click_icon,
                          ChgThemeData === "Light"
                            ? APPstyles.hover_icon_light
                            : APPstyles.hover_icon,
                        ].join(" ")}
                        onClick={() => onClickEdit(item)}
                      />
                    </span>
                  </div>
                );
              })
            ) : (
              <div
                className={[APPstyles.loading_more, styles.padding120].join(
                  " "
                )}
                style={{ display: isLoading ? "none" : "" }}
              >
                {t("No datas")}
              </div>
            )
          }
          {isShowDeleteModal && (
            <div
              className={[
                styles.delete_modal_mask,
                ChgThemeData === "Light" ? styles.light : "",
              ].join(" ")}
            >
              <div
                className={[
                  styles.delete_modal,
                  ChgThemeData === "Light" ? APPstyles.bg_light : "",
                  ChgThemeData === "Light" ? APPstyles.color_theme_black : "",
                ].join(" ")}
              >
                <div className={styles.message}>
                  <IconHelp />
                  <div>
                    {t("alerts.deleteMsg1")}{" "}
                    <span className={styles.name}>
                      {selectedDeleteItem?.name}
                    </span>{" "}
                    {t("alerts.deleteMsg2")}
                  </div>
                </div>

                <div
                  className={[
                    APPstyles.flex_row,
                    APPstyles.place_button,
                    APPstyles.place_button_alert,
                    styles.active_alert_botton_button_bar,
                  ].join(" ")}
                >
                  <Button
                    type="default"
                    className={APPstyles.btn_gray}
                    onClick={() => setIsShowDeleteModal(false)}
                  >
                    {t("alerts.cancel")}
                  </Button>
                  <Button
                    type="default"
                    style={{ backgroundColor: "#008FC7" }}
                    className={APPstyles.btn_blue}
                    // disabled={disabledData[2]}
                    onClick={fetchDeleteAlert}
                    loading={isLoadingDelete}
                  >
                    {t("alerts.delete")}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {/* only in mobile view */}
          {isMobile && (
            <>
              <div
                className={APPstyles.loading_more}
                style={{ display: !isMaxPage || isLoading ? "" : "none" }}
              >
                {t("Load more")}...
              </div>
              <div
                className={APPstyles.loading_more}
                style={{
                  display:
                    isMaxPage && listData.length > 0 && !isLoading
                      ? ""
                      : "none",
                }}
              >
                - {t("No More Data Available")} -
              </div>
            </>
          )}
        </div>
        {isFinishFetch && counts !== 0 && page && !isMobile && !isHidePaginate && (
          <div className={styles.bottom_pagination}>
            <div className={styles.page_number}>
              {t("alerts.display")} {fromSlice + 1}-
              {fromSlice + listData.length} {t("alerts.of")} {counts}
            </div>
            {/* <div className={styles.page_number}>
              {t("alerts.displayPage")} {page} {t("alerts.of")}{" "}
              {Math.ceil(counts / DEFAULT_PAGE_SIZE)
                ? Math.ceil(counts / DEFAULT_PAGE_SIZE)
                : 1}
            </div> */}
            <Pagination
              className={APPstyles.pagination}
              current={page}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={counts}
              onChange={(page, pageSize) => {
                setPage(page);
                setFetchSignal(fetchSignal + 1);
              }}
              showSizeChanger={false}
              showLessItems={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveAlerts;

import React from "react";
import { withTranslation } from "react-i18next"
import { Spin } from 'antd'
import common from '@/asserts/js/common'
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg
} from '@/asserts/imgs/common/index'
import DropDown from '@/components/DropDown/index'
import PaginationCon from '../Pagination/index'
import CalendarCon from '../Calendar/index'
import InputCon from '../InputCon/index'
import TipsCon from './Tips/index'
import Mobile from '../Mobile'
import APPstyles from '@/App.less'
import styles from '@/pages/Account/index.less'
import myStyles from './index.less'

let leftConW=878,chgPage=1

class RealisedPL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history:[],
      isLoading:true,
      page:1,
      stock_id:null,
      sort:'',
      fromDate:'',
      toDate:'',
      sizeTotal:0,
      total_pl:'',
      recperpage:20,
      closeInput:false,
      minW500:500,
      dropDownL:0,
      dropDownR:'unset',
      dropDownT:38,
      dropDownW:'100%',
      closeCalendar:false,
      loadMore:false,
      noMore:false,
      minW1360:1360,
      chgDropDownImg:dropDownImg,
      isDropdownShow:false,
      dropDownData:[],
      chgCur:'TWD',
      isDownLoading:false,
      minW510:510,
      minW328:328,
      currency:'TWD',
      isOnLoad:true,
      dropDownIndex:0
    }
    this.myRPRef = React.createRef()
  }
  //获取数据API
  getTableData(stock_id,page,fromDate,toDate,listsData,currency,index,onload) {
    const {recperpage}=this.state
    fetch(common.getHttpZs+"getRealizedPL", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: sessionStorage.getItem("token"),
        stock_id: stock_id,
        from_date: Number(fromDate),
        to_date: Number(toDate),
        recperpage: recperpage,
        page: page,
        currency:currency
      }),
    })
    .then((res) => res.json())
    .then((result) => {
      if(result['status']==0){
        if(JSON.stringify(result['data']) !== '{}'){
          let datalist=result['data']['datalist']
          let incomelist=result['data']['incomelist']
          let size_total=result['data']['size_total']
          let incomelistData=Object.keys(incomelist)
          let getRicData=[],getDownData=[],getCur=''
          // 下拉框
          // if(onload=='onload'){
            if(incomelistData.length){
              for(let i in incomelistData){
                let obj={
                  cur:incomelistData[i],
                  num:incomelist[incomelistData[i]],
                  img:common.imgArrFlag[incomelistData[i]]
                }
                getDownData.push(obj)
              }
              getDownData=common.imgDataFlagFun(getDownData,'img')
              getCur=getDownData[index]['cur']
              this.setState({
                total_pl:incomelist[currency],
                dropDownData:getDownData,
                chgCur:getCur
              })
            }else{
              this.setState({
                dropDownData:[],
                chgCur:'TWD',
                total_pl:''
              })
            }
          // }
          // 数据
          if(datalist.length){
            for(let i in datalist){
              getRicData.push(datalist[i]['stock_id'])
            }
            if(getRicData.length==datalist.length){
              getRicData= [...new Set(getRicData)]//去重
              this.getsimplequoteFun(getRicData,datalist,listsData,page,size_total)
            }
            return
          }
          if(listsData.length){
            this.setState({
              isLoading:false,
              loadMore:false,
              noMore:true
            })
          }else{
            this.noDataFun2()
          }
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(ric,datalist,listsData,page,size_total){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      //获取数据成功
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        if(JSON.stringify(resData)!=='{}'){
          for(let i in datalist){
            for(let j in ric){
              if(datalist[i]['stock_id']==ric[j]){
                let data=resData[ric[j]]
                datalist[i]['stock_code']=data['symbol']
                datalist[i]['stock_name']=data['name']
                datalist[i]['country']=data['exchange_country_code']
              }
            }
          }
          this.commonDataFun(datalist,listsData,page,size_total)
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err)
      this.commonDataFun(datalist,listsData,page,size_total)
    })
  }
  commonDataFun(datalist,listsData,page,size_total){
    const {isTablet}=this.props
    let pn1=datalist.length?datalist.length>=10?false:true:false
    let pnother=listsData.length?datalist.length?false:true:true
    let noMoreData=page==1?pn1:pnother 
    this.setState({
      history: isTablet?listsData.concat(datalist):datalist,
      sizeTotal:size_total,
      isLoading:false,
      loadMore:false,
      noMore:noMoreData
    })
  }
  // 获取失败
  noDataFun(){
    this.setState({
      history:[],
      total_pl:'',
      sizeTotal:0,
      isLoading:false,
      loadMore:false,
      noMore:false,
      dropDownData:[],
      chgCur:'TWD'
    })
  }
  // 获取失败
  noDataFun2(){
    this.setState({
      history:[],
      sizeTotal:0,
      isLoading:false,
      loadMore:false,
      noMore:false
    })
  }
  //回传数据--input
  callbackInputData(data){
    const {fromDate,toDate,currency,dropDownIndex}=this.state
    this.setState({
      isLoading:true,
      stock_id:data[0],
      page:1
    })
    chgPage=1
    this.getTableData(data[0],1,fromDate,toDate,[],currency,dropDownIndex,'onload')
  }
  //回传数据--日期
  callbackDateData(data){
    const {stock_id,currency,dropDownIndex}=this.state
    this.setState({
      isLoading:true,
      fromDate:data[0],
      toDate:data[1],
      page:1
    })
    chgPage=1
    this.getTableData(stock_id,1,data[0],data[1],[],currency,dropDownIndex,'onload')
  }
  //回传数据--分页
  callbackPagekData(page){
    const {stock_id,fromDate,toDate,currency,dropDownIndex}=this.state
    this.setState({
      isLoading:true,
      page:page
    })
    this.getTableData(stock_id,page,fromDate,toDate,[],currency,dropDownIndex,'noload')
  }
  //更改下拉框
  changeDropDownData(){
    const {bodyW,isPC}=this.props
    const {minW500}=this.state
    let left=isPC?0:'unset'
    let right=isPC?'unset':bodyW>minW500?0:16
    let top=bodyW>minW500?38:50
    let width=isPC?'100%':'90%'
    // 分页
    let contentId=document.getElementById('contentId').clientWidth
    let pageW=document.getElementById('pageId').clientWidth
    leftConW=contentId-pageW>878?878:'auto'

    this.setState({
      dropDownL: left,
      dropDownR: right,
      dropDownT:top,
      dropDownW:width
    })
  }
  //滚动加载更多--isTablet/mobile
  cbChgDataFun(){
    const {loadMore,noMore,isLoading,stock_id,fromDate,toDate,history,currency,dropDownIndex}=this.state
    if(loadMore||noMore||isLoading) return
    chgPage++
    this.setState({
      page: chgPage,
      loadMore:true,
      noMore:false
    })
    this.getTableData(stock_id,chgPage,fromDate,toDate,history,currency,dropDownIndex,'noload')
  }
  //点击外部关闭弹框
  handleClick = e => {
    if(!this.testComponent.contains(e.target)){
      this.setState({
        isDropdownShow:false,
        chgDropDownImg:dropDownImg
      })
    }
  }
  //下拉框--显示/隐藏
  dropDownClick(e){
    e.stopPropagation()
    this.setState(state => ({
      isDropdownShow:!state.isDropdownShow
    }))
  }
  // 下拉框返回的数据
  chgDropDownDataFun(data){
    const {stock_id,fromDate,toDate}=this.state
    let currency=data[0]['cur']
    let index=data[1]
    chgPage=1
    this.setState({
      isLoading:true,
      isDropdownShow:false,
      chgCur:currency,
      currency:currency,
      dropDownIndex:index,
      page:1
    })
    this.getTableData(stock_id,1,fromDate,toDate,[],currency,index,'noload')
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,isSearchPageShow}=this.props
    if(bodyW!==newProps['bodyW']||isSearchPageShow!==newProps['isSearchPageShow']){
      this.changeDropDownData()
    }
  }
  //初始化
  componentDidMount() {
    const {from_date,to_date}=this.props
    const {stock_id,page,currency,dropDownIndex}=this.state
    chgPage=1
    this.setState({
      fromDate: from_date,
      toDate: to_date,
      page: 1,
      loadMore:false,
      noMore:false
    })
    this.getTableData(stock_id,page,from_date,to_date,[],currency,dropDownIndex,'onload')
    this.changeDropDownData()
    document.addEventListener('click', this.handleClick) 
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }
  render() {
    const { t,bodyW,ChgThemeData,ChgLangData,isPC,isTablet,isMobile,isSearchPageShow } = this.props
    const {history,isLoading,sizeTotal,total_pl,recperpage,page,closeInput,minW500,dropDownL,dropDownR,dropDownT,dropDownW,closeCalendar,loadMore,noMore,minW1360,chgDropDownImg,isDropdownShow,dropDownData,isDownLoading,minW510,minW328,chgCur} = this.state
    return (
      <div
        className={[
          styles.account_layout,
          APPstyles.flex_column
        ].join(' ')}
        style={{
          position:'relative'
        }}
      >
        <div 
          className={[
            isPC&&!isSearchPageShow?APPstyles.flex1_auto:'',
            isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
            isPC&&isSearchPageShow||isTablet?APPstyles.hPer_overflowH:'',
            isPC&&isSearchPageShow?'':isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':''
          ].join(' ')}
        >
          {/* Header */}
          <div
            id='contentId'
            className={[
              APPstyles.flex_row,
              APPstyles.flex_between,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              margin:isPC?'0 16px':0
            }}
          >
            <div 
              className={[
                myStyles.header_con,
                isMobile?myStyles.header_con_mobile:'',
                isMobile?APPstyles.flex_column:APPstyles.flex_row,
                APPstyles.flex_1
              ].join(' ')}
              style={{
                padding:isPC?'0 8px':isMobile?0:'0 16px',
                width:isPC?leftConW:'auto',
                maxWidth:isPC?leftConW:'unset'
              }}
            >
              <div 
                className={[
                  myStyles.msg_con,
                  APPstyles.flex_column,
                  isMobile?myStyles.msg_con_mobile:'',
                  isMobile?ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark:''
                ].join(' ')}
              >
                <div 
                  className={myStyles.msg_dropdown_con}
                  style={{
                    position: bodyW<=minW510?'unset':'relative'
                  }}
                  onClick={this.dropDownClick.bind(this)}
                  onMouseOver={()=>{
                    let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                    this.setState({
                      chgDropDownImg:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    this.setState({
                      chgDropDownImg:dropDownImg
                    })
                  }}
                >
                  <span className={isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''}>{t('Total Unrealised P/L')} <label style={{display:chgCur?'':'none'}}>({t(chgCur)}) </label></span>
                  <img 
                    alt='dropdown' 
                    src={chgDropDownImg}
                    className={myStyles.msg_dropdown_img}
                    style={{
                      transform:isDropdownShow?'rotate(-180deg)':'rotate(0deg)'
                    }}
                  />
                  <div
                    ref = {testComponent => this.testComponent = testComponent}
                    className={myStyles.dropsDown_con}
                    style={{
                      width:bodyW<=minW328?'90%':300,
                      top:bodyW<=minW510?30:20,
                      left:bodyW<=minW510?'unset':-1,
                      right:bodyW<=minW510?15:'unset',
                      display:isDropdownShow?'':'none'
                    }}
                  >
                    <DropDown
                      {...this.props}
                      tag='portfolio'
                      data={dropDownData}
                      isLoading={isDownLoading}
                      cbDropDownData={this.chgDropDownDataFun.bind(this)}
                    />
                  </div>
                </div>
                <span 
                  className={[
                    Number(total_pl)>0?APPstyles.color_red:Number(total_pl)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                    myStyles.msg_dropdown_val
                  ].join(' ')}
                  style={{
                    fontSize:isMobile?20:24
                  }}
                >
                  {common.addplusNum(common.transThousands(common.keepNum(total_pl,2)))}
                </span>
              </div>
              <div 
                className={[
                  myStyles.header_right,
                  APPstyles.flex_row,
                  isMobile?myStyles.header_right_mobile:''
                ].join(' ')}
                >
                <CalendarCon
                  {...this.props}
                  closeCalendar={closeCalendar}
                  callbackDateData={this.callbackDateData.bind(this)}
                  cbCloseInput={()=>{
                    //关闭下拉框
                    this.setState(state => ({
                      closeInput:!state.closeInput,
                      isDropdownShow:false
                    }))
                  }}
                />
                <div 
                  className={[
                    isMobile?myStyles.input_con_mobile:''
                  ].join(' ')}
                  style={{
                    width:'100%',
                    position: bodyW>minW500?'relative':'unset'
                  }}
                >
                  <InputCon
                    {...this.props}
                    closeInput={closeInput}
                    dropDownL={dropDownL}
                    dropDownR={dropDownR}
                    dropDownT={dropDownT}
                    dropDownW={dropDownW}
                    callbackInputData={this.callbackInputData.bind(this)}
                    cbCloseAll={()=>{
                      //关闭所有下拉框
                      this.setState(state => ({
                        closeCalendar:!state.closeCalendar,
                        isDropdownShow:false
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
            {/* 分页 */}
            <div id='pageId' style={{display:isPC?'':'none'}}>
              <PaginationCon
                {...this.props}
                sizeTotal={sizeTotal}
                recperpage={recperpage}
                page={page}
                isShow={false}
                history={history}
                callbackPagekData={this.callbackPagekData.bind(this)}
              />
            </div>       
          </div>
          {/* content */}
          {
          isPC?
              <div 
                className={[
                  isPC&&isSearchPageShow?APPstyles.flex1_auto:'',
                  isPC&&isSearchPageShow?isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'':''
                ].join(' ')}
              >
                {/* header */}
                <div
                  className={[
                    APPstyles.flex_row,
                    styles.account_header,
                    styles.header_pc,
                    styles.account_common,
                    ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                    ChgLangData=='zh-CN'?styles.account_common_zhcn:'',
                    ChgLangData=='zh-CN'?APPstyles.fz12_zhcn:''
                  ].join(" ")}
                >
                  <div style={{width:bodyW<=minW1360?'9%':"7%"}}>{t("Date")}</div>
                  <div>{t("Country")}</div>
                  <div>{t("Code")}</div>
                  <div className={styles.account_name} style={{ width: bodyW<=minW1360?'31%':"33%" }}>
                    {t(ChgLangData == "zh-CN" ? "name" : "Name")}
                  </div>
                  <div style={{ justifyContent: "flex-end" }}>{t("QTY Filled1")}</div>
                  <div style={{ justifyContent: "flex-end" }}>
                    <div
                      className={styles.account_header_totalcost}
                      style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}
                    >
                      <span>{t("Avg Cost")}</span>
                      <span>{t("Price")}</span>
                    </div>
                    <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                      {t("Avg Cost Price")}
                    </span>
                  </div>
                  <div style={{ justifyContent: "flex-end" }}>
                    <div
                      className={styles.account_header_totalcost}
                      style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}
                    >
                      <span>{t("Transacted")}</span>
                      <span>{t("Price")}</span>
                    </div>
                    <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                      {t("Transacted Price")}
                    </span>
                  </div>
                  <div
                    className={styles.account_header_totalcost}
                    style={{ width: "7%" }}
                  >
                    <span>{t("Total Cost")}</span>
                    <span>
                      {t("Total Cost")}({t("TWD")})
                    </span>
                  </div>
                  <div
                    className={styles.account_header_totalcost}
                    style={{ width: "9%" }}
                  >
                    <span>{t("Total Amount")}</span>
                    <span>
                      {t("Total Amount")}({t("TWD")})
                    </span>
                  </div>
                  <div
                    className={styles.account_header_totalcost}
                    style={{ width: "7%" }}
                  >
                    <span>{t("P/L")}</span>
                    <span>
                      {t("P/L")}({t("TWD")})
                    </span>
                  </div>
                  <div
                    className={styles.account_header_totalcost}
                    style={{ width: "7%" }}
                  >
                    <span>{t("P/L%")}</span>
                    <span>{t("Exchange P/L")}</span>
                  </div>
                  <div style={{ justifyContent: "flex-end" }}>{t("Exchange Rate")}</div>
                </div>
                {/* body */}
                <div 
                  className={history.length>0?'':APPstyles.minH300}
                  style={{
                    position:!history.length&&!isLoading?'relative':'unset'
                  }}
                >
                  {/* 加载中 */}
                  <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                  {history.length>0?
                    history.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={[
                              APPstyles.flex_row,
                              styles.account_body,
                              styles.account_common,
                              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                              ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                              ChgThemeData=='Light'?styles.account_body_light:'',
                              ChgLangData=='zh-CN'?styles.account_common_zhcn:''
                            ].join(" ")}
                          >
                            <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{ width: bodyW<=minW1360?'9%':"7%"}}>
                              {common.onlyOrderDate(item['date'])}
                            </div>
                            <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t(item['country'])}</div>
                            <div className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,APPstyles.fw700].join(' ')}>{item['stock_code']}</div>
                            <div
                              className={[styles.account_name,ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur].join(' ')}
                              style={{ width: bodyW<=minW1360?'31%':"33%" }}
                            >
                              {item['stock_name']}
                            </div>
                            <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{justifyContent: "flex-end" }}>
                              {common.transThousands(common.keepNum(item['qty_filled'],0))}
                            </div>
                            <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{justifyContent: "flex-end" }}>
                              {common.transThousands(common.keepNum(item['avg_cost_price'],2))}
                            </div>
                            <div>{common.transThousands(common.keepNum(item['transacted_price'],2))}</div>
                            <div
                              className={styles.account_header_totalcost}
                              style={{ width: "7%" }}
                            >
                              <span>{common.transThousands(common.keepNum(item['total_cost'],2))}</span>
                              <span>{common.transThousands(common.keepNum(item['total_cost_twd'],2))}</span>
                            </div>
                            <div
                              className={[
                                styles.account_header_totalcost,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                              ].join(' ')}
                              style={{ width: "9%" }}
                            >
                              <span>{common.transThousands(common.keepNum(item['total_amount'],2))}</span>
                              <span>{common.transThousands(common.keepNum(item['total_amount_twd'],2))}</span>
                            </div>
                            <div
                              className={styles.account_header_totalcost}
                              style={{ width: "7%" }}
                            >
                              <span className={Number(item['pl'])>0?APPstyles.color_red:Number(item['pl'])<0?APPstyles.color_green:APPstyles.color_white}>
                                {common.addplusNum(common.transThousands(common.keepNum(item['pl'],2)))}
                              </span>
                              <span className={Number(item['pl_twd'])>0?APPstyles.color_red:Number(item['pl_twd'])<0?APPstyles.color_green:APPstyles.color_white}>
                                {common.addplusNum(common.transThousands(common.keepNum(item['pl_twd'],2)))}
                              </span>
                            </div>
                            <div
                              className={styles.account_header_totalcost}
                              style={{ width: "7%" }}
                            >
                              <span className={Number(item['pl_perc'])>0?APPstyles.color_red:Number(item['pl_perc'])<0?APPstyles.color_green:APPstyles.color_white}>
                                {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(item['pl_perc'],2))))}
                              </span>
                              <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                                {common.transThousands(common.keepNum(item['exchg_gl_twd'],2))}
                              </span>
                            </div>
                            <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{ justifyContent: "flex-end" }}>
                              {common.transThousands(common.keepNum(item['exchg_rate'],2))}
                            </div>
                          </div>
                        );
                      })
                    :
                      <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>    
                  }
                </div>
                {/* 分页 */}
                <div style={{display:isPC?isSearchPageShow?'':'none':'none'}}>
                <PaginationCon
                  {...this.props}
                  sizeTotal={sizeTotal}
                  recperpage={recperpage}
                  page={page}
                  isShow={true}
                  history={history}
                  callbackPagekData={this.callbackPagekData.bind(this)}
                />
                </div>
              </div>
            :
            // 移动端
            <Mobile 
              {...this.props}
              history={history}
              changeName={ChgLangData == "zh-CN" ? "name" : "Name"}
              isLoading={isLoading}
              loadMore={loadMore}
              noMore={noMore}
              sizeTotal={sizeTotal}
              recperpage={recperpage}
              page={page}
              isShow={true}
              cbChgDataFun={this.cbChgDataFun.bind(this)}
            />  
          }
          {/* 分页 */}
          <div style={{display:isPC&&!isSearchPageShow?'':'none'}}>
          <PaginationCon
            {...this.props}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            page={page}
            isShow={true}
            history={history}
            callbackPagekData={this.callbackPagekData.bind(this)}
          />
          </div>
        </div>
        <div style={{display:isPC?'':'none'}}>
          <TipsCon {...this.props} />
        </div>
      </div>
    );
  }
}
export default withTranslation()(RealisedPL)

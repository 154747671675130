import React from "react";
import { withTranslation } from "react-i18next"
import { Spin } from 'antd'
import PaginationCon from './Pagination/index'
import OrderHistoryTipsCon from './OrderHistory/Tips/index'
import RealisedPLTipsCon from './RealisedPL/Tips/index'
import common from '@/asserts/js/common'
import { 
  deleteImg,
  tipImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from "@/pages/Account/index.less"

class MobileCon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexData12:[1,2],
      indexData45:[4,5],
      indexData1345:[1,3,4,5],
      indexData345:[3,4,5],
      indexData126:[1,2,6],
      allWidth_1:'14%',
      allWidth_2:'40%',
      allWidth_3:'24%',
      allWidth_4:'22%',
      isScrollShow:false,
      chgTipImg:tipImg
    }
    this.myRef = React.createRef()
  }
  //detail---show
  detailClick(data){
    const {accountIndex}=this.props
    if(accountIndex==1){
      this.props.detailClick(data)
    }
  }
  //modal--open--是否删除数据
  openModalClick(data, e){
    this.props.openModalClick(data, e)
  }
  //排序
  sortClick(name,tag,sortName){
    this.props.sortClick(name,tag,sortName)
  }
  resizeWidthFun(){
    const {accountIndex,isdelStatusShow} = this.props
    const {indexData45,indexData1345}=this.state
    
    let getWidth_1=indexData1345.includes(accountIndex)?'28%':accountIndex==2?'22%':'18%'
    let getWidth_2=accountIndex==3?'18%':accountIndex==1?isdelStatusShow?'20%':'27%':accountIndex==5?'20%':accountIndex==4||accountIndex==2?'26%':'28%'
    let getWidth_3=accountIndex==5?'30%':accountIndex==4?'24%':'26%'
    let getWidth_4=accountIndex==1?'19%':indexData45.includes(accountIndex)?'22%':accountIndex==2?'26%':'28%'

    this.setState({
      allWidth_1: getWidth_1,
      allWidth_2: getWidth_2,
      allWidth_3: getWidth_3,
      allWidth_4: getWidth_4
    })
  }
  //滚动加载更多
  handleScroll(){
    const {isDelData}=this.props
    let current=this.myRef.current
    if(current){
      if(Number(current.scrollHeight - current.scrollTop- current.clientHeight) < 10){
        if(isDelData=='Y') return
        this.props.cbChgDataFun()
      }
    }
  }
  isScrollShowFun(){
    let current=this.myRef.current
    if(current){
      this.setState({
        isScrollShow:current.scrollHeight>current.clientHeight?true:false
      })
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,accountIndex,isLoading,isSortShow} = this.props
    if(bodyW!==newProps['bodyW']||accountIndex!==newProps['accountIndex']){
      this.resizeWidthFun()
    }
    if(isLoading!==newProps['isLoading']){
      setTimeout(()=>{
        this.isScrollShowFun()
      }, 10)
    }
    // 回到顶部
    if(isSortShow!==newProps['isSortShow']){
      this.myRef.current.scrollTop=0
    }
  }
  //初始化
  componentDidMount(){
    this.resizeWidthFun()
  }
  render() {
    const { t,history,changeName,isLoading,accountIndex,ChgThemeData,isdelStatusShow,loadMore,noMore,sortData,isMobile} = this.props
    const {indexData12,indexData126,indexData45,allWidth_1,allWidth_2,allWidth_3,allWidth_4,indexData345,isScrollShow,chgTipImg}=this.state
    return (
      <div 
        className={[
          APPstyles.flex_column,
          APPstyles.flex1_hidden
        ].join(' ')}
      >
        {/* header */}
        <div
          className={[
            APPstyles.flex_row,
            APPstyles.margin0,
            styles.account_header,
            styles.account_common,
            styles.account_header_mobile,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile?styles.account_header_mobile2:'',
            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
          ].join(" ")}
          style={{
            padding:isScrollShow?'4px 25px 4px 16px':'4px 16px'
          }}
        >
          {/* 第1列 */}
          <div 
            className={styles.account_common_left_Mobile} 
            style={{
              width:allWidth_1,
              position:'relative'
            }}
          >
            {
              accountIndex==6?
                <span>{t("Type")}</span>
              :indexData12.includes(accountIndex)?
                <div style={{width:'100%',alignItems:'center'}} className={APPstyles.flex_row}>
                  <div className={APPstyles.flex_column}>
                    <div 
                      style={{width:'100%',marginBottom:2,alignItems:'center'}} 
                      className={APPstyles.flex_row} 
                    >
                      {t('Direction')}
                    </div>
                    {
                      accountIndex==1?
                        <div 
                          style={{width:'100%',alignItems:'center'}} 
                          className={APPstyles.flex_row} 
                        >
                          {t('Status')}
                        </div>
                      :
                        ''
                    }
                  </div>
                  <div 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <div 
                      className={APPstyles.img_dropdown_up}   
                      onClick={()=>{this.sortClick('direction','direction_asc','asc')}}
                    >
                      <img src={sortData[1]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('direction','direction_desc','desc')}}
                    >
                      <img src={sortData[1]['downImg']} />
                    </div>
                  </div>
                </div>
              :accountIndex==3?
                <span>{t(changeName)}</span>
              :
                <div style={{width:'100%'}} className={APPstyles.flex_column}>
                  <div style={{width:'100%',alignItems:'center',textAlign:'left'}} className={APPstyles.flex_row}>
                    <span style={{whiteSpace: 'nowrap',width: 'auto'}}>{t('Date/Time')}</span>
                    <div 
                      className={[
                        APPstyles.upDown_con,
                        APPstyles.flex_column
                      ].join(' ')}
                    >
                      <div 
                      className={APPstyles.img_dropdown_up}  
                      onClick={()=>{this.sortClick('time','time_asc','asc')}}
                    >
                      <img src={sortData[0]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('time','time_desc','desc')}}
                    >
                      <img src={sortData[0]['downImg']} />
                    </div>
                    </div>
                  </div>
                </div>
            }
          </div>
          {/* 第2列 */}
          <div 
            className={accountIndex==3?styles.account_common_right_Mobile:styles.account_common_left_Mobile} 
            style={{ 
              width:allWidth_2
            }}
          >
            {
              indexData126.includes(accountIndex)?
                <span>{t(changeName)}</span>
              :accountIndex==3?
                <span>{t('QTY')}</span>
              :
                <div style={{width:'100%',alignItems:'center'}} className={APPstyles.flex_row}>
                  <div className={APPstyles.flex_column}>
                    <span style={{width:'100%',marginBottom:2,alignItems:'center'}}>
                      {t('Direction')}
                    </span>
                    <span style={{width:'100%',alignItems:'center',display:accountIndex==4?'':'none'}}>
                      {t('Status')}
                    </span>
                  </div>
                  <div 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <div 
                      className={APPstyles.img_dropdown_up}    
                      onClick={()=>{this.sortClick('direction','direction_asc','asc')}}
                    >
                      <img src={sortData[1]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('direction','direction_desc','desc')}}
                    >
                      <img src={sortData[1]['downImg']} />
                    </div>
                  </div>
                </div>
            }
          </div>
          {/* 第3列 */}
          <div 
            className={indexData12.includes(accountIndex)?styles.account_common_right_Mobile:styles.account_common_left_Mobile} 
            style={{ 
              width:allWidth_3,
              cursor:'pointer'
            }} 
          >
            {
              accountIndex==6?
                <div style={{width:'100%',alignItems:'center'}} className={APPstyles.flex_row}>
                  {t("Payable Date")}
                  <div 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <div 
                      className={APPstyles.img_dropdown_up}   
                      onClick={()=>{this.sortClick('payable_date','payable_date_asc','asc')}}
                    >
                      <img src={sortData[3]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('payable_date','payable_date_desc','desc')}}
                    >
                      <img src={sortData[3]['downImg']} />
                    </div>
                  </div>
                </div>
              :accountIndex==2?
                <span>{t('QTY Filled1')}</span>
              :accountIndex==1?
                <span>{t('Order Price')}</span>
              :accountIndex==3?
                <div style={{width:'100%'}} className={[APPstyles.flex_column,styles.account_common_right_Mobile].join(' ')}>
                  <span style={{marginBottom:2}}>{t('Avg Cost Price')}</span>
                  <span>{t('Transcated Price')}</span>
                </div>
              :
                <span>{t(changeName)}</span>
            }
          </div>
          {/* 第4列 */}
          <div 
            className={styles.account_common_right_Mobile}
            style={{ 
              width:allWidth_4,
              // flexDirection:'column',
              textAlign:'right',
              marginRight:accountIndex==1&&isdelStatusShow?6:0 
            }}
          >
            {
              accountIndex==6?
                <div style={{width:'100%'}}>
                  <span style={{display:'block',width:'100%',marginBottom:2}}>{t("Net dividend")}</span>
                  <span style={{display:'block',width:'100%'}}>{t("Dividend in Shares")}</span>
                </div>
              :accountIndex==2?
                <span>{!isMobile?t('Total Amount'):t('Order Price')}</span>
              :accountIndex==3?
                <div style={{width:'100%'}}>
                  <span style={{display:'block',width:'100%',marginBottom:2}}>{t("P&L")}</span>
                  <span style={{display:'block',width:'100%'}}>{t("P/L")}({t("TWD")})</span>
                </div>
              :
                <div style={{width:'100%'}}>
                  {
                    accountIndex==1?
                      <span style={{display:'block',width:'100%',marginBottom:2}}>{t("QTY")}</span>
                    :accountIndex==4?
                      <span style={{display:'block',width:'100%',marginBottom:2}}>{t("Order Price")}</span>
                    :
                      <span style={{display:'block',width:'100%',marginBottom:2}}>{t("Transacted Price")}</span>
                  }
                  <span style={{display:'block',width:'100%'}}>{t(accountIndex==1||accountIndex==4?"QTY Filled1":"QTY Filled")}</span>
                </div> 
            }
          </div>
          {/* 第5列--accountIndex=1 */}
          {
            accountIndex==1&&isdelStatusShow?
              <div style={{width:'7%'}}>&nbsp;</div>
            :
              ''
          }
        </div>
        {/* body */}
        <div
          className={[
            APPstyles.flex1_auto,
            indexData345.includes(accountIndex)?'':APPstyles.paddingBottom20
          ].join(' ')} 
          style={{
            position:isLoading?'static':'relative'
          }}
          ref={this.myRef}
          onScroll={() => this.handleScroll()}
        >
          {/* 加载中 */}
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {
            history.length>0?
            history.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className={[
                        APPstyles.flex_row,
                        styles.account_body,
                        styles.account_common,
                        styles.account_common_mobile,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        ChgThemeData=='Light'?styles.account_body_light:'',
                        APPstyles.margin0,
                        isMobile?APPstyles.fz16:''
                      ].join(" ")}
                      onClick={this.detailClick.bind(this,item)}
                    >
                      {/* 第1列 */}
                      <div 
                        className={[
                          styles.account_common_left_Mobile,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')} 
                        style={{
                          width:allWidth_1
                        }}
                      >
                        {
                          accountIndex==6?
                            <span>{common.charAtToUpperCase(t(item['type']))}</span>
                          :accountIndex==3?
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}} className={APPstyles.fw700}>
                                {common.valToUpperCase(item['stock_code'])}
                              </span>
                              <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.ellipsis,isMobile?APPstyles.fz14:''].join(' ')}>{common.valToUpperCase(item['stock_name'])}</span>
                            </div>
                          :accountIndex==4?
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}}>
                                {common.changeOrderDate(item['datetime'])}
                              </span>
                              <span>{common.changeOrderTime(item['datetime'])}</span>
                            </div>
                          :accountIndex==5?
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}}>
                                {common.changeOrderDate(item['deal_datetime'])}
                              </span>
                              <span >{common.changeOrderTime(item['deal_datetime'])}</span>
                            </div>
                          :
                            <div className={APPstyles.flex_column}>
                              <span className={item['direction']=='B'?APPstyles.color_red:APPstyles.color_green} style={{marginBottom:2}}>
                                {t(item['direction']=='B'?'Buy':item['direction']=='S'?'Sell':'')}
                              </span>
                              <span className={isMobile?APPstyles.fz14:''} style={{display:accountIndex==1?'':'none'}}>{t(item['status_content'])}</span>
                            </div>
                        }
                      </div>
                      {/* 第2列 */}
                      <div
                        style={{
                          width: allWidth_2,
                          flexDirection:'column',
                          justifyContent: "flex-start",
                          overflow:'hidden'
                        }}
                        className={[
                          accountIndex==3?styles.account_common_right_Mobile:'',
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')}
                      >
                        {
                          accountIndex==3?
                            <span>
                              {common.transThousands(common.keepNum(item['qty_filled'],0))}
                            </span>
                          :indexData45.includes(accountIndex)? 
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span 
                                style={{marginBottom:2}} 
                                className={item['direction']=='B'?APPstyles.color_red:APPstyles.color_green}
                              >
                                {t(common.charAtToUpperCase(item['direction']=='B'?'Buy':'Sell'))}
                              </span>
                              <span className={isMobile?APPstyles.fz14:''}>{t(item['status_content'])}</span>
                            </div>
                          :
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}} className={APPstyles.fw700}>
                                {common.valToUpperCase(item['stock_code'])}
                              </span>
                              <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.ellipsis,isMobile?APPstyles.fz14:''].join(' ')}>{common.valToUpperCase(item['stock_name'])}</span>
                            </div>
                        }
                      </div>
                      {/* 第3列 */}
                      <div 
                        style={{width:allWidth_3}} 
                        className={[
                          indexData12.includes(accountIndex)?styles.account_common_right_Mobile:styles.account_common_left_Mobile,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')} 
                      >
                        {
                          accountIndex==6?
                            <span>
                              {common.onlyOrderDate(item['payable_date'])}
                            </span>
                          :accountIndex==1?
                            <span>
                              {common.transThousands(common.filterErro(item['order_price']))}
                            </span>
                          :accountIndex==2?
                            <span>
                              {common.transThousands(common.keepNum(item['qty_filled'],0))}
                            </span>
                          :accountIndex==3?
                            <div style={{width:'100%'}} className={styles.account_common_right_Mobile}>
                              <span style={{marginBottom:2}}>
                                {common.transThousands(common.keepNum(item['avg_cost_price'],2))}
                              </span>
                              <span>
                                {common.transThousands(common.keepNum(item['transacted_price'],2))}
                              </span>
                            </div>
                          :indexData45.includes(accountIndex)?
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}} className={APPstyles.fw700}>
                                {common.valToUpperCase(item['stock_code'])}
                              </span>
                              <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.ellipsis,isMobile?APPstyles.fz14:''].join(' ')}>{common.valToUpperCase(item['stock_name'])}</span>
                            </div>
                          :
                            ''  
                        }
                      </div>
                      {/* 第4列 */}
                      <div 
                        className={[
                          styles.account_common_right_Mobile,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')} 
                        style={{ 
                          width:allWidth_4,
                          flexDirection:'column',
                          marginRight:accountIndex==1&&isdelStatusShow?6:0  
                        }}
                      >
                        {
                          accountIndex==6?
                            <div style={{width:'100%'}} className={styles.account_common_right_Mobile}>
                              <span 
                                className={[
                                  Number(item['net_dividend'])>0?APPstyles.color_red:Number(item['net_dividend'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                  isMobile?item['net_dividend_len']>5?APPstyles.fz11:item['net_dividend_len']==5?APPstyles.fz12:APPstyles.fz16:''
                                ].join(' ')}
                                style={{
                                  marginBottom:2
                                }} 
                              >
                                {common.dealAddCurData(item['currency'],item['country'],common.transThousands(common.keepNum(item['net_dividend'],2)))}
                              </span>
                              <span>
                                {common.transThousands(common.keepNum(item['dividend_in_shares'],0))}
                              </span>
                            </div>
                          :accountIndex==1?
                          <div style={{width:'100%'}} className={styles.account_common_right_Mobile}>
                            <span 
                              style={{marginBottom:2}}
                              className={
                                item['qty_len']>6?APPstyles.fz12:item['qty_len']==6?APPstyles.fz13:APPstyles.fz14
                              }
                            >
                              {common.transThousands(common.keepNum(item['qty'],0))}
                            </span>
                            <span
                              className={
                                item['qty_filled_len']>6?APPstyles.fz12:item['qty_filled_len']==6?APPstyles.fz13:APPstyles.fz14
                              }
                            >
                              {common.transThousands(common.keepNum(item['qty_filled'],0))}
                            </span>
                          </div>
                          :accountIndex==2?
                            <span>
                              {
                                !isMobile?
                                  common.transThousands(common.keepNum(item['total_amount'],2))
                                :
                                  common.transThousands(common.filterErro(item['order_price']))
                              }
                            </span>
                          :accountIndex==4? 
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}}>
                                {common.transThousands(common.filterErro(item['order_price']))}
                              </span>
                              <span>{common.transThousands(common.keepNum(item['qty_filled'],0))}</span>
                            </div>
                          :accountIndex==5? 
                            <div style={{width:'100%'}} className={styles.account_common_left_Mobile}>
                              <span style={{marginBottom:2}}>
                              {common.transThousands(common.filterErro(item['transacted_price']))}
                              </span>
                              <span>{common.transThousands(common.keepNum(item['qty_filled'],0))}</span>
                            </div>
                          :accountIndex==3?
                            <div style={{width:'100%'}} className={styles.account_common_right_Mobile}>
                              <span 
                                style={{marginBottom:2}} 
                                className={Number(item['pl'])>0?APPstyles.color_red:Number(item['pl'])<0?APPstyles.color_green:APPstyles.color_white}
                              >
                              {common.addplusNum(common.transThousands(common.keepNum(item['pl'],2)))}
                              </span>
                              <span className={Number(item['pl_twd'])>0?APPstyles.color_red:Number(item['pl_twd'])<0?APPstyles.color_green:APPstyles.color_white}>
                                {common.addplusNum(common.transThousands(common.keepNum(item['pl_twd'],2)))}
                              </span>
                            </div>
                          :
                            <div style={{width:'100%'}} className={styles.account_common_right_Mobile}>
                              <span style={{marginBottom:2}}>
                                {common.transThousands(common.keepNum(item['total_amount'],2))}
                              </span>
                              <span>
                                {common.transThousands(common.keepNum(item['total_amount_twd'],2))}
                              </span>
                            </div>
                        }
                      </div>
                      {/* 第5列--accountIndex=1 */}
                      {
                        accountIndex==1&&isdelStatusShow?
                          <div style={{justifyContent:'center',width:'7%'}}>
                            <img 
                              src={deleteImg} 
                              className={APPstyles.img24} 
                              style={{
                                display:item['can_cancel']=='Y'?'':'none'
                              }}
                              onClick={this.openModalClick.bind(this, item)} 
                              alt='del' 
                            />
                          </div>
                        :
                          ''
                      }
                    </div>
                    {/* 分页 */}
                    <div style={{marginTop:14,marginLeft:isMobile?0:8,display:history.length-1==index?'':'none'}}>
                      <PaginationCon
                        {...this.props}
                      />
                    </div>
                  </div>
                );
              })
            :
              <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
          }
          <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
          <div className={APPstyles.loading_more} style={{display:noMore?'':'none'}}>- {t('No More Data Available')} -</div>
        </div>
        <div style={{display:indexData45.includes(accountIndex)?'':'none'}}>
          <OrderHistoryTipsCon {...this.props} />
        </div>
        <div style={{display:accountIndex==3?'':'none'}}>
          <RealisedPLTipsCon {...this.props} />
        </div>
      </div>
    )
  }
}
export default withTranslation()(MobileCon)

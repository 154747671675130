import React from 'react'
import { withTranslation } from 'react-i18next'
import { 
  closeImg
} from '../../asserts/imgs/common/index'
import Appstyles from '../../App.less'
import styles from './Apply.less'

class Apply extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  //关闭
  closeClick(){
    this.props.callbackClose()
  }
  render(){
    const {bodyW,mobilebodyW} = this.props
    return (
      <div 
        className={[
          styles.trade_orderComfirm,
          styles.trade_apply_con,
          styles.trade_apply_DisclaimerRead
        ].join(' ')} 
        style={{
          minHeight:280,
          width:bodyW>mobilebodyW?480:'80%'
        }}
      >
        <img src={closeImg} className={Appstyles.img24} onClick={this.closeClick.bind(this)} alt='close' />
        <div className={styles.trade_apply_title}>免責聲明</div>
        <div className={styles.trade_apply_subtitle}>本網站資料內容僅供參考，資料來源於第三方金融市場數據機構，客戶應審慎考量本身之需求與投資風險，本公司恕不負任何法律責任，亦不作任何保證。所載資料乃秉持誠信原則所提供，並取自相信為可靠及準確之資料來源，但並不保證其真實性或完整性；內容中所有資訊或預估，變更時本公司將恕不另行通知。非經本公司同意，禁止以任何形式之抄襲及轉寄他人。</div>
      </div>
  )}
}
export default withTranslation()(Apply)
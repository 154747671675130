import React from 'react'
import { Button,Spin,Tooltip } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  leftImg,
  leftLightImg,
  tipImg,
  tipOnImg,
  tipOnLightImg,
  closeMinOnLightImg,
  closeMinOnImg,
  upImg,
  downImg,
  remeberOffImg,
  remeberOnImg
} from '@/asserts/imgs/common/index'
import PriceModal from '../PriceModal/index'
import DropDown from '../../DropDown/index'
import NumberCon from '../Number/index'
import CalculatorCon from '../Calculator/index'
import BuyPowerModal from '../BuyPowerModal/index'
import ListCon from '../List/index'
import DropDownSimple from '../Simple/DropDown/index'
import NoteCon from '@/components/modal/note/index';
import APPstyles from '@/App.less'
import styles from './index.less'

let timeoutId=null

class TradeComponents extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      accountNameVal:'',
      isBuyingPowerShow:false,
      selectCur:'Select Currency',
      BuyingPowerSymbol:'',
      BuyingPowerNum:'',
      optionsData:[],
      inputNameVal:'',
      optonsNameShow:false,
      optionsNameData:[],
      optonsNameIndex:null,
      isDownLoading:false,
      optionsName:'',
      optionsSymbol:'',
      isnameShow:false,
      changeImg:'',
      buySellIndex:null,
      typeIndex:null,
      typeData:[
        {
          name:'Limit',
          key:0,
          key1:0,
          disabled:'disabled'
        },
        {
          name:'Stop Limit',
          key:50,
          key1:0.01,
          disabled:'disabled'
        }
      ],
      quantityNums:1,
      priceNums:'',
      untilIndex:null,
      untilData:[
        {
          name:'Today',
          key:'today',
          disabled:'disabled'
        },
        {
          name:'30D',
          key:'30d',
          disabled:'disabled'
        },
        {
          name:'60D',
          key:'60d',
          disabled:'disabled'
        },
        {
          name:'90D',
          key:'90d',
          disabled:'disabled'
        }
      ],
      isLoading:false,
      listData:[],
      QtyHeld:0,
      Amount:'0',
      isBuyingPowerLoading:true,
      isADR:0,
      countryCode:'US',
      fbstockid:'',
      isSelectCurShow:true,
      isPriceModalShow:false,
      priceModalTag:'',
      directionData:[
        {
          name:'Buy',
          name1:'Buy Price',
          key:'B',
          disabled:'disabled'
        },
        {
          name:'Sell',
          name1:'Sell Price',
          key:'S',
          disabled:'disabled'
        }
      ],
      allData:[],
      curreny:'',
      share:'',
      chgStopPrice:'Stop Price',
      chgLimitNum:0,
      chgLossNum:0,
      chgStopPricePholderName:'Enter Stop Price',
      chgCloseImg:closeImg,
      clearImg:closeImg,
      chgDropDownImg:dropDownImg,
      tipsImg0:tipImg,
      tipsImg1:tipImg,
      tipsImg2:tipImg,
      comfirmDisabled:'disabled',
      currency_1:'',
      currency_cn:'',
      minW400:400,
      minW450:450,
      isScrollShow:false,
      isRemember:false,
      isSimpleShow:false,
      chgKeySimple:{
        name:'General',
        key:'general'
      },
      simpleImg:dropDownImg,
      isTipBuyPowerShow:false,
      isCalculatorShow:false,
      chgBuyPowerText:'Check buying power',
      isBuyPowerLoading:false,
      UNITValue:'',
      isTWShow:false,
      isDropDownIconShow:true,
      isDeliveryShow:false,
      deliverySymbol:'',
      SKIND:'',
      noteData:{sign:'note',title:'Please Note',content:'This is a GEM stock in Hong Kong, and its stock price is easily manipulated by interested parties. Please be reminded to pay attention to related investment risks and information sources',cancelText:'Cancel',sureText:'Confirm Order'},
      noteVisible:false,
    }
    this.timer = null
    this.myRef = React.createRef()
  }
  selectCurClick(e){
    const {isnameShow,isTWShow,isBuyPowerLoading,chgBuyPowerText,isSelectCurShow}=this.state;
    e.stopPropagation();
    if((chgBuyPowerText=='Check buying power'||chgBuyPowerText=='System busy, please try later')&&isSelectCurShow&&isnameShow&&!isBuyPowerLoading){
      this.buyPowerShowClick();
      return;
    }
    if(!isnameShow){
      this.setState({
        isTipBuyPowerShow:true,
      });
      return;
    }
    if(!isTWShow) return;
    if(isBuyPowerLoading) return;

    this.setState(prevState => ({
      isBuyingPowerShow:!prevState.isBuyingPowerShow,
    }));
  }
  //input---Name
  changeInputClick(e) {
    let value=e['target']['value']
    this.setState({
      inputNameVal:value,
      optonsNameShow:value?true:false,
      isDownLoading:value?true:false
    })
    if(value=='') return
    clearTimeout(this.timer);
    this.timer = setTimeout(()=>{
      this.getPowersearch(value)
    }, 500)
  }
  //下拉菜单--Name
  dropDownNameClick(data){
    this.setState({
      optonsNameIndex:data[1],
      optonsNameShow:false,
      isnameShow:false,
      isLoading:true,
      optionsNameData:[],
      isSelectCurShow:true,
      isBuyingPowerShow:false,
    })
    let ric=data[0]['ric']==null?data[0]['fbstockid']:data[0]['ric'];
    this.changeRicFun(ric);
  }
  //clear--Name
  clearClick() {
    const {directionData,typeData,untilData}=this.state
    for(let i in directionData){
      directionData[i]['disabled']='disabled'
      typeData[i]['disabled']='disabled'
    }
    for(let i in untilData){
      untilData[i]['disabled']='disabled'
    }
    this.setState({
      inputNameVal: '',
      optonsNameIndex:null,
      optonsNameShow:false,
      buySellIndex:null,
      isnameShow:false,
      changeImg:'',
      priceNums:'',
      Amount:0,
      isSelectCurShow:true,
      curreny:'',
      share:'',
      quantityNums:1,
      directionData:directionData,
      typeData:typeData,
      untilData:untilData,
      comfirmDisabled:'disabled',
      isSimpleShow:false,
      chgkeyMarket:{
        name:'Choose Market',
        key:''
      },
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'Check buying power',
      isBuyPowerLoading:false,
      isDropDownIconShow:true,
      UNITValue:'',
      isTWShow:false,
      isDeliveryShow:false,
      deliverySymbol:'',
      BuyingPowerSymbol:'',
      BuyingPowerNum:'',
    })
  }
  //change value
  changeNumClick(data){
    const {priceNums,quantityNums,buySellIndex,typeData}=this.state;
    let value=data['name']=='quantity'?Number(data['value']):data['value'];
    value=value?value:'';
    if(data['name']=='quantity'){
      this.setState({
        quantityNums:value,
        Amount:value*priceNums
      })
      return
    }
    if(data['name']=='price'){
      this.setState({
        priceNums: value,
        Amount:value*quantityNums
      })
      return
    }
    // stopprice
    typeData[1]['key1']=value
    if(buySellIndex==0){
      this.setState({
        chgLimitNum:value,
        typeData:typeData
      })
    }else{
      this.setState({
        chgLossNum:value,
        typeData:typeData
      })
    }
  }
  //清空数据
  clearData(){
    const {typeData,directionData,untilData}=this.state
    for(let i in directionData){
      directionData[i]['disabled']='disabled'
      typeData[i]['disabled']='disabled'
    }
    for(let i in untilData){
      untilData[i]['disabled']='disabled'
    }
    typeData[1]['name']='Stop Limit'
    typeData[0]['key1']=0
    typeData[1]['key1']=0.01
    this.setState({
      isBuyingPowerShow:false,
      selectCur:'Select Currency',
      BuyingPowerSymbol:'',
      BuyingPowerNum:'',
      inputNameVal:'',
      buySellIndex:null,
      typeIndex:null,
      optonsNameIndex:null,
      optonsNameShow:false,
      isnameShow:false,
      isSelectCurShow:true,
      quantityNums:1,
      priceNums:'',
      Amount:0,
      QtyHeld:0,
      isPriceModalShow:false,
      priceModalTag:'',
      countryCode:'US',
      isADR:0,
      curreny:'',
      share:'',
      typeData:typeData,
      untilIndex:'',
      directionData:directionData,
      typeData:typeData,
      untilData:untilData,
      comfirmDisabled:'disabled',
      listData:[],
      isSimpleShow:false,
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'Check buying power',
      isBuyPowerLoading:false,
      isDropDownIconShow:true,
      UNITValue:'',
      isTWShow:false,
      isDeliveryShow:false,
      deliverySymbol:'',
    })
  }
  //页面--关闭
  closeClick(){
    const {sliderIndex,currentIndex}=this.props
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let target=sessionStorage.getItem('target')
    sessionStorage.setItem('navId',navIdAPP)
    if(target=='trade'){
      sessionStorage.setItem('target','quote')
    }
    this.setState({
      isRemember:false
    })
    this.backTopFun()
    this.clearData()
    this.props.cbChgPageShow()

    if(navIdAPP=='quote'&&navId=='quote'){
      sessionStorage.removeItem('isRememberQuote')
    }else{
      sessionStorage.removeItem('isRememberTrade')
    }
    //quote
    if(sliderIndex=='quote'){
      this.props.callbackTradeShow('close','')
      let data=[false,false,false,false]
      this.props.calkbackBtnStatus(data)
    }
    // trade
    if(sliderIndex=='trade'){
      this.props.callbackChgNavIndex(navIdAPP, true)
      sessionStorage.setItem('navId',navIdAPP)
      if(currentIndex==3){
        this.props.callbackTradeShow('close','')
      }
    }
    // trade/account/news
    if(sliderIndex=='trade'||sliderIndex=='account'||sliderIndex=='news'){
      let data=[false,false,false,false]
      this.props.calkbackBtnStatus(data)
    }
  }
  //Confirm Order
  confirmClick(){
    let {quantityNums,priceNums,isLoading,typeData,typeIndex,SKIND}=this.state
    if(quantityNums==''||Number(quantityNums)==0||quantityNums=='-'||priceNums==''||Number(priceNums)==0||priceNums=='-'||(typeData[1]['key1']==''&&typeIndex==1)){
      let tag=quantityNums==''||Number(quantityNums)==0?'quantity':priceNums==''||Number(priceNums)==0?'price':'stopPrice'
      this.setState({
        isPriceModalShow:true,
        priceModalTag:tag
      })
      return
    }
    if(isLoading) return;
    // 港股創業板(8開頭) FSMB檔的SKIND欄位值=”B”出现提示
    if(SKIND=='B'){
      this.setState({
        noteVisible:true,
      });
    }else{
      this.confirmFun();
    }
  }
  confirmFun(){
    const {directionData,buySellIndex,isADR,quantityNums,priceNums,optionsSymbol,optionsName,untilData,untilIndex,countryCode,fbstockid,typeData,typeIndex}=this.state
    let getUnit=[]
    for(let i in untilData){
      if(Number(i)>0){
        getUnit.push(untilData[i]['key'])
      }
    }
    let typeName=typeData[typeIndex]['name']
    let untilKey=untilData[untilIndex]['key']
    let isShowGTC=getUnit.includes(untilKey)||typeName=='Stop Limit'||typeName=='Stop Loss'?true:false
    let data=String(isADR)=='0'?isShowGTC?[true,false,true,false]:[true,false,false,true]
            :countryCode=='US'?[true,false,true,false]
              :isShowGTC?[true,false,true,false]:[true,false,false,true]
    
    let resData=[{
      symbol:optionsSymbol,
      name:optionsName,
      price:String(priceNums),
      Quantity:quantityNums,
      priceType:typeData[typeIndex],
      GoodUntil:untilData[untilIndex],
      buySellIndex:directionData[buySellIndex],
      countryCode:countryCode,
      fbstockid:fbstockid,
      isShowGTC:isShowGTC,
      isADR:isADR,
      isClickADR:false
    }]
    this.props.calkbackBtnStatus(data)
    this.props.calkbackOrderData(resData)
    this.backTopFun()
    let target=sessionStorage.getItem('target')
    if(target=='trade'){
      sessionStorage.setItem('target','quote')
    }
  }
  cbNoteConFun(sign){
    this.setState({
      noteVisible:false,
    });
    sign=='Y'&&this.confirmFun();
  }
  //powersearch--获取数据
  getPowersearch(value){
    fetch(common.getHttp+'powersearch',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        k:value,
        limit:10,
        tradable:true
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        //flag
        let resData=res['data']['datalist']
        if(resData.length>0){
          for(let i in resData){
            resData[i]['img']=common.imgShortNameFlag[resData[i]['exchange_country_code']]
          }
          resData=common.imgDataFlagFun(resData,'img')
        } 
        this.setState({
          optionsNameData:resData,
          isDownLoading:false
        })
        return
      }
      //获取失败
      this.setState({
        isLoading: false
      })
    })
  }
  //获取数据--all
  changeRicFun(ric){
    const {untilIndex}=this.props
    const {typeData,directionData,untilData,quantityNums}=this.state;
    let token=sessionStorage.getItem('token');
    this.backTopFun();
    this.setState({
      isLoading:true,
      QtyHeld:0,
      isDeliveryShow:false,
      chgBuyPowerText:'Check buying power',
    });
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token,
        ric:[ric]
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']
        if(data && typeof(data)!='undefined' && data!=0&&typeof(data[ric]['fbstockid']) !== 'undefined'){
          let country_code=data[ric]['exchange_country_code'];
          let UNITValue=data[ric]['UNIT'];
          this.dealDropDownDataFun([],country_code,UNITValue,'onload');
          if(typeof(data[ric]['fbstockid']) !== 'undefined'){
            this.getStockHoldingInfo(token,data[ric]['fbstockid'],country_code)
          }
          for(let i in directionData){
            directionData[i]['disabled']=''
            typeData[i]['disabled']=''
          }
          for(let i in untilData){
            untilData[i]['disabled']=''
          }
          // 处理数据--flag
          for(let i in data){
            data[i]['img']=common.imgShortNameFlag[data[i]['exchange_country_code']]
          }
          data=common.imgDataFlagFun(data,'img')

          this.props.store.addSubscribeRic(data[ric]['ric'])  
          this.props.store.setQuoteData([data[ric]]) 
         
          let chgUntilIndex=typeof(untilIndex)=='undefined'?0:untilIndex
          let TRDPRC_1=typeof(data[ric]['TRDPRC_1'])=='undefined'?0:common.keepNum(data[ric]['TRDPRC_1'],2)
          typeData[1]['key1']=TRDPRC_1
          this.setState({
            listData:data[ric],
            changeImg:data[ric]['img'],
            optionsName:data[ric]['name'],
            optionsSymbol:data[ric]['symbol'],
            isADR:data[ric]['isADR'],
            isnameShow:true,
            buySellIndex:null,
            typeIndex:0,
            priceNums:TRDPRC_1,
            countryCode:country_code,
            fbstockid:data[ric]['fbstockid'],
            Amount:Number(TRDPRC_1)*Number(quantityNums),
            isLoading: false,
            share:data[ric]['share'],
            quantityNums:typeof(data[ric]['share'])=='undefined'?1:data[ric]['share'],
            curreny:typeof(data[ric]['UNIT'])=='undefined'?common.imgShortNameChgCur[country_code]:data[ric]['UNIT'],
            untilIndex:chgUntilIndex,
            typeData:typeData,
            chgLimitNum:TRDPRC_1,
            chgLossNum:TRDPRC_1,
            directionData:directionData,
            typeData:typeData,
            untilData:untilData,
            comfirmDisabled:'disabled',
            UNITValue:UNITValue,
            SKIND:data[ric]['SKIND']?data[ric]['SKIND']:'',
          });
          return
        }
        this.setState({
          isLoading: false
        })
        return
      }
      //获取失败
      this.setState({
        isLoading: false
      })
    }).catch(err => {
      //获取失败
      this.setState({
        isLoading: false
      })
    })
  }
  //获取数据--QtyHeld
  getStockHoldingInfo(token,stock_id,country_code){
    fetch(common.getHttpZs+'getStockHoldingInfo',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token,
        stock_id:stock_id,
        country_code:country_code
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        this.setState({
          QtyHeld:res['data']['qty'],
          currency_1:res['data']['currency'],
          currency_cn:res['data']['currency_cn']
        })
      }
    })
  }
  // 不同的国家对应不同的数据
  dealDropDownDataFun(data,country_code,UNITValue,sign){
    let getValue='';
    let getDataOne='';
    let getTW='';
    let getData=[];
    let newData=[];
    let isCNShow=true;//但選了中國股要特別處理，如市場是使用台幣，就不顯示任何貨幣
    let customerCurrency=sessionStorage.getItem('customerCurrency');
    customerCurrency=JSON.parse(customerCurrency);

    if(customerCurrency.length){
      getValue=country_code=='GB'?'UK':country_code;
      let UK_code=country_code=='DE'?'GB':country_code;
      const itemData = customerCurrency.find((item) => item === UK_code);
      if(itemData){// 台币
        getTW='TW';
        isCNShow=country_code=='CN'?false:true;
      }else{//原币
        if(UNITValue){
          getValue=common.shortNameChgCode[UNITValue];
          getValue=getValue=='GB'?'UK':getValue;
        }
      }
    }else{//原币
      if(UNITValue){
        getValue=common.shortNameChgCode[UNITValue];
        getValue=getValue=='GB'?'UK':getValue;
      }
    }
    if(sign=='onload'&&isCNShow){
      this.setState({
        isDeliveryShow:true,
        deliverySymbol:common.imgShortNameChgCur[getTW?getTW:getValue],
      });
      return;
    }
    let countryOne=data[getValue];
    let resData=Object.keys(countryOne);
    let flagData=Object.keys(common.imgArrFlag);
    if(getTW=='TW'){//台幣排第一
      let withoutTWD = resData.filter(currency => currency !== 'TWD');
      resData = ['TWD', ...withoutTWD];
    }
    if(resData.length>0){
      for(let i in resData){
        for(let j in flagData){
          if(flagData[j]==resData[i]){
            let obj={
              symbol:resData[i],
              num:countryOne[resData[i]],
              img:common.imgArrFlag[resData[i]],
            };
            let value=getTW?getTW:getValue;
            if(resData[i]==common.imgShortNameChgCur[value]){
              getDataOne=obj;
            }else{
              if(getTW !== 'TW' && (UNITValue === 'CNY' || UNITValue === 'EUR' || UNITValue === 'HKD' || UNITValue === 'GBP' || UNITValue === 'JPY' || UNITValue === 'TWD' || UNITValue === 'USD' || UNITValue === 'SGD')){
                getDataOne = this.dealValueData(data, UNITValue);
              }
            }
            getData.push(obj);
          }
        }
      }
      newData=common.imgDataFlagFun(getData,'img');
    }
    this.setState({
      optionsData:newData,
      isTWShow:getTW=='TW'?true:false,
      isBuyPowerLoading:false,
      isDropDownIconShow:false,
      isBuyingPowerLoading:false,
    });
    if(getDataOne&&isCNShow){
      this.setState({
        isSelectCurShow:false,
        BuyingPowerNum:getDataOne['num'],
        BuyingPowerSymbol:getDataOne['symbol'],
      });
    }
  }
  buyPowerShowClick(){
    const {chgBuyPowerText}=this.state;
    this.setState({
      isBuyPowerLoading:true,
      chgBuyPowerText:'Loading, please wait',
    });
    this.getAccountInfoFun();
    const handler = setTimeout(() => {
      chgBuyPowerText=='Loading, please wait'&&this.noDataFun2();
    }, 15000);
    return () => {
      clearTimeout(handler);
    };
  }
  dealValueData(data,UNITValue){
    let val=data['ALL'][UNITValue]?data['ALL'][UNITValue]:'0.00';
      return {
      symbol:UNITValue,
      num:val,
      img:common.imgArrFlag[UNITValue],
    }
  }
  //获取数据--Buying Power
  getAccountInfoFun(){
    const {countryCode,UNITValue}=this.state;
    fetch(common.getHttpZs+'getAccountInfo',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      clearTimeout(timeoutId)
      if(res['status']==0){
        let allData=res['data']['buying_power'];
        this.setState({
          allData:allData,
          chgBuyPowerText:'',
        })
        this.dealDropDownDataFun(allData,countryCode,UNITValue);
        return
      }
      //获取失败
      this.noDataFun2();
    }).catch(err => {
      //获取失败
      console.log(err)
      this.noDataFun2();
    })
  }
  noDataFun2(){
    clearTimeout(timeoutId);
    this.setState({
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'System busy, please try later',
      isBuyPowerLoading:false,
    });
  }
  //获取 Account Number
  getAccountIdFun(){
    fetch(common.getHttpZs+'getAccountId',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let aidListData=JSON.parse(sessionStorage.getItem('aidListData'))
        let getData=aidListData[res['account_id']] 
        this.setState({
          accountNameVal:getData['BranchName']+' '+getData['AID']+' '+getData['Name']
        })
        return
      }
      //获取失败
      this.setState({
        accountNameVal:''
      })
    })
  }
  //回到顶部
  backTopFun(){
    let newId=document.getElementById('getTradeID')
    if(newId){
      setTimeout(()=>{
        newId.scrollTop=0
      }, 10)
    }
  }
  //滚动加载更多
  handleScroll(){
    const {isScrollShow}=this.state
    let current=this.myRef.current
    if(current){
      if(Number(current.scrollHeight - current.scrollTop- current.clientHeight) < 10){
        this.setState({
          isScrollShow:!isScrollShow
        })
      }
    }
  }
  //点击外部关闭下拉框--cur
  handleCurClick = e => {
    if(!this.CurComponent.contains(e.target)){
      this.setState ({
        isBuyingPowerShow:false,
        chgDropDownImg:dropDownImg
      })
    }
  }
  //点击外部关闭下拉框--Code
  handleCodeClick = e => {
    if(!this.CodeComponent.contains(e.target)) {
      this.setState ({
        optonsNameShow:false,
        inputNameVal:'',
        optionsNameData:[],
        optonsNameIndex:null,
        isDownLoading:false
      })
    }
  }
  //关闭所有的下拉框 trade弹框
  closeAllClick(){
    let navId=sessionStorage.getItem('navId')
    if(navId=='trade'){
      this.setState({
        isBuyingPowerShow:false,
        optonsNameShow:false,
        inputNameVal:'',
        optionsNameData:[],
        optonsNameIndex:null,
        isDownLoading:false,
        isSimpleShow:false
      })
    }
  }
  simpleClick(e){
    const {isSimpleShow}=this.state
    e.stopPropagation()
    this.setState({
      isSimpleShow:!isSimpleShow
    })
  }
  //点击外部关闭下拉框--simple
  handleSimpleClick = e => {
    if(!this.simpleComponent.contains(e.target)){
      this.setState ({
        isSimpleShow:false,
        simpleImg:dropDownImg
      })
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ricData,accountTradeData,IsSubmitOrder,isShowPageData,isBackTopShow,isTradeMinbody,currentIndex,isMobile,isCghTradeData,closeAllDropDown,isTablet,isPC,isSwitchShow}=this.props
    let navId=sessionStorage.getItem('navId')
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    //关闭所有的下拉框
    if(closeAllDropDown!==newProps['closeAllDropDown']&&typeof(closeAllDropDown)!=='undefined'){
      this.closeAllClick()
    }
    //清空数据
    if(IsSubmitOrder!==newProps['IsSubmitOrder']){
      if(navIdAPP=='quote'&&navId=='quote'){
        let isRemember=sessionStorage.getItem('isRememberQuote')
        if(!isRemember){
          this.clearData()
        }
      }else{
        let isRemember=sessionStorage.getItem('isRememberTrade')
        if(!isRemember){
          this.clearData()
        }
      }
    }
    //回到顶部
    if(isBackTopShow!==newProps['isBackTopShow']){
      this.backTopFun()
    }
    if(isSwitchShow!==newProps['isSwitchShow']){
      this.getAccountIdFun()
    }
    // currentIndex==2/3更改trade数据---isMobile
    if(navIdAPP=='quote'&&isMobile&&isCghTradeData!==newProps['isCghTradeData']){
      if(!ricData||JSON.stringify(ricData) == '{}') return
      this.setState({
        isLoading:true
      })
      this.changeRicFun(ricData['ric']);
      this.getAccountIdFun();
    }
    //account --trade弹框
    if(navIdAPP=='account'&&accountTradeData!==newProps['accountTradeData']){
      if(JSON.stringify(accountTradeData[0])!=='{}'){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(accountTradeData[0]['stock_id']);
        this.getAccountIdFun();
      }
    }
    // trade--弹框
    if(navId=='trade'){
      if(isShowPageData&&(isShowPageData[0]!==newProps['isShowPageData'][0])){
        if(isShowPageData[0]){
          this.clearData()
          this.getAccountIdFun();
        }
      }
    }
    //quote页面--isTablet
    if(isTradeMinbody!==newProps['isTradeMinbody']){
      if((isTablet&&!isMobile&&ricData&&JSON.stringify(ricData)!=='{}')||(isPC&&(currentIndex==2||currentIndex==3))){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(ricData['ric']);
        this.getAccountIdFun();
      }
    }
    //quote页面--pc端
    if(isPC&&navIdAPP=='quote'&&ricData&&JSON.stringify(ricData)!=='{}'){
      if(isPC&&(currentIndex==2||currentIndex==3)) return
      if(ricData!==newProps['ricData']){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(ricData['ric']);
      }
    }
  }
  //初始化
  componentDidMount(){
    const {ricData,accountTradeData,isChgPageOn,isTablet,isMobile}=this.props
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let target=sessionStorage.getItem('target')
    if(isChgPageOn||target=='trade'){
      this.getAccountIdFun();
    }
    // Quote页面
    if(navIdAPP=='quote'&&ricData&&JSON.stringify(ricData) !== '{}'){
      this.changeRicFun(ricData['ric']);
      if(navId=='trade'&&isTablet&&!isMobile) return
      this.getAccountIdFun();
    }
    // account
    if(navIdAPP=='account'&&typeof(accountTradeData)!=='undefined'){
      if(accountTradeData.length>0&&JSON.stringify(accountTradeData[0])!=='{}'){;
        this.setState({
          isLoading:true
        })
        this.changeRicFun(accountTradeData[0]['stock_id'])
        this.getAccountIdFun()
      }
    }
    document.addEventListener('click', this.handleCurClick)
    document.addEventListener('click', this.handleCodeClick)
    document.addEventListener('click', this.handleSimpleClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleCurClick)
    document.removeEventListener('click', this.handleCodeClick)
    document.removeEventListener('click', this.handleSimpleClick)
  }
  render(){
    const {t,store,ChgThemeData,ChgLangData,isMobile,isTablet,isPC,bodyW,simpleData} = this.props
    const {accountNameVal,isBuyingPowerShow,BuyingPowerSymbol,BuyingPowerNum,inputNameVal,optonsNameShow,optionsData,changeImg,buySellIndex,typeData,typeIndex,quantityNums,priceNums,optonsNameIndex,optionsNameData,optionsName,optionsSymbol,listData,isnameShow,isLoading,isDownLoading,QtyHeld,Amount,isBuyingPowerLoading,isSelectCurShow,isPriceModalShow,priceModalTag,directionData,untilData,untilIndex,curreny,share,chgStopPrice,chgStopPricePholderName,chgLimitNum,chgLossNum,chgCloseImg,clearImg,chgDropDownImg,tipsImg0,tipsImg1,tipsImg2,comfirmDisabled,countryCode,currency_1,currency_cn,minW400,minW450,isScrollShow,isRemember,chgKeySimple,simpleImg,isSimpleShow,isTipBuyPowerShow,isCalculatorShow,chgBuyPowerText,isBuyPowerLoading,isTWShow,isDropDownIconShow,isDeliveryShow,deliverySymbol,noteData,noteVisible}=this.state;
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let streamingData=store.getDataByRic(listData['ric'])
    let NETCHNG_1=streamingData&&streamingData['NETCHNG_1']
    let PCTCHNG=streamingData&&streamingData['PCTCHNG']
    let BID=streamingData&&streamingData['BID']
    let ASK=streamingData&&streamingData['ASK']
    return (
      <div
        className={[
          styles.trade_layout,
          isMobile?styles.trade_layout_mobile:'',
          APPstyles.flex_column,
          APPstyles.flex1_hidden,
          ChgThemeData=='Light'?APPstyles.borderLeft_light:APPstyles.borderLeft_dark,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark,
          isMobile?ChgThemeData=='Dark'?styles.trade_layout_mobileD:styles.trade_layout_mobileW:''
        ].join(' ')}
        onClick={this.closeAllClick.bind(this)}
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        {/* title */}
        <div 
          className={[
            APPstyles.flex_between,
            isMobile?APPstyles.flex_row:'',
            styles.name,
            styles.tradeflow_con,
            isMobile?styles.name_mobile:'',
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <div>
            <img 
              src={ChgThemeData=='Light'?leftLightImg:leftImg} 
              alt='back' 
              className={APPstyles.img24} 
              style={{
                display:isMobile?'':'none'
              }} 
              onClick={this.closeClick.bind(this)}  
            />
            <span>{t('Trade')}</span>
          </div>
          <div className={APPstyles.flex_topcenter}>
            <div 
              className={[styles.trade_choicecon,APPstyles.flex_topcenter].join(' ')}
              onClick={this.simpleClick.bind(this)}
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                this.setState({
                  simpleImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  simpleImg:dropDownImg
                })
              }} 
            >
              {t(chgKeySimple['name'])}
              <img 
                src={simpleImg} 
                alt='dropDown' 
                className={styles.img_dropDown} 
                style={{transform:!isSimpleShow?'rotate(0deg)':'rotate(-180deg)',marginRight:isMobile?8:15}}
              />
              {/* 下拉框 */}
              <div 
                className={styles.dropsDown_con} 
                style={{display:isSimpleShow?'':'none'}} 
                ref = {testComponent => this.simpleComponent = testComponent}
              >
                <DropDownSimple
                  {...this.props}
                  tag='simple'
                  choiceData={simpleData}
                  chgkeyData={chgKeySimple['key']}
                  cbDropDownData={(data)=>{
                    this.setState({
                      chgKeySimple:data,
                      isSimpleShow:false
                    })
                    if(data['key']=='simple'){
                      this.props.cbChgPageShow(data['key'])
                    }
                  }}
                />
              </div>
            </div>
            <img 
              src={chgCloseImg}
              alt='close'
              className={APPstyles.img24}
              style={{
                display:isMobile?'none':''
              }}  
              onClick={this.closeClick.bind(this)}   
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  chgCloseImg:closeImg
                })
              }} 
            />
          </div>
        </div>
        <div 
          id='getTradeID'
          ref={this.myRef}
          onScroll={() => this.handleScroll()}
          className={APPstyles.flex1_auto}
        >
          <div 
            className={[
              APPstyles.flex_row,
              styles.accountNumber,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Account Number')} : </span>
            <span style={{cursor:'pointer'}} className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} onClick={()=>{this.props.cbSwitchMoalShow()}}>{accountNameVal}</span>
          </div>
          {/* Buying Power */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.buyingPower,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <div className={styles.spanW}>
              <span>{t('Buying Power')}</span>
              <Tooltip 
                title={t("Buying power = Bank available balance + in-transit payment  + today's selling amount - in-transit transfer amount. (Buying power is for reference only. If you have any questions, please contact customer service center: 0800-073-588)")} 
                overlayClassName={[
                  ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                  !isTablet?'':APPstyles.tooltipcon_custurm_left
                ].join(' ')}
              >
                <img 
                  src={tipsImg0} 
                  alt='tips'
                  className={[APPstyles.img16,styles.img_help].join(' ')} 
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                    this.setState({
                      tipsImg0:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    this.setState({
                      tipsImg0:tipImg
                    })
                  }} 
                />
              </Tooltip>
            </div>
            <div 
              className={[APPstyles.flex_between,styles.buySelected,bodyW<minW400?styles.buySelected_name:''].join(' ')} 
              style={{cursor:isDropDownIconShow?'pointer':isTWShow?'pointer':'default'}}
              onClick={this.selectCurClick.bind(this)}
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                this.setState({
                  chgDropDownImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  chgDropDownImg:dropDownImg,
                })
              }} 
            >
              <div className={styles.buySelected_right}>
                <div 
                  style={{display:isSelectCurShow?'':'none',opacity:1,alignItems:'center'}} 
                  className={[
                      ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                      styles.buySelected_right_name,
                      APPstyles.flex_row,
                      chgBuyPowerText=='System busy, please try later'?styles.buySelected_right_name1:''
                  ].join(' ')}
                >
                  <Spin spinning={isBuyPowerLoading} style={{position:'static',transform: 'unset',marginRight:8}} />
                  <label style={{cursor:isDropDownIconShow?'pointer':isTWShow?'pointer':'default'}}>{t(chgBuyPowerText)}</label>
                </div>
                <div className={[styles.buySelected_right_symbol,APPstyles.flex_row,isSelectCurShow?APPstyles.display_none:''].join(' ')}>
                  <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                    {common.transThousands(common.keepNum(BuyingPowerNum,2))}
                  </span>
                  <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                    {t(BuyingPowerSymbol)}
                  </span>
                </div>
                <span className={[styles.Delivery,isDeliveryShow?'':APPstyles.display_none].join(' ')}>{t(deliverySymbol)}{t('Delivery')}</span>
              </div>
              <img 
                src={chgDropDownImg} 
                alt='dropDown' 
                className={styles.img_dropDown} 
                style={{transform:!isBuyingPowerShow?'rotate(0deg)':'rotate(-180deg)',display:isDropDownIconShow?'':isTWShow?'':'none'}}
              />
              {/* 下拉框 */}
              <div 
                className={styles.dropsDown_con} 
                style={{display:isBuyingPowerShow?'':'none'}} 
                ref = {testComponent => this.CurComponent = testComponent}
              >
                <DropDown
                  {...this.props}
                  tag='selectCurrency'
                  data={optionsData}
                  isLoading={isBuyingPowerLoading}
                  cbDropDownData={{}}
                />
              </div>
            </div>
          </div>
          <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark].join(' ')}></div>
          {/* Name */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.buyingPower,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Name')}</span>
            <div className={[APPstyles.flex_between,styles.buySelected,bodyW<minW450?styles.buySelected_name:''].join(' ')}>
              <div 
                className={[
                  APPstyles.flex_row,
                  styles.entername,
                  ChgThemeData=='Light'?styles.entername_light:''
                ].join(' ')}
              >
                <input 
                  autoComplete='off' 
                  placeholder={t('Enter the Name/Symbol')} 
                  style={{
                    paddingLeft:0,
                    display:isnameShow?'none':optonsNameIndex==null?'':'none',
                    width:'80%'
                  }} 
                  value={inputNameVal} 
                  onChange={this.changeInputClick.bind(this)}
                />
                <div className={[APPstyles.flex_row,styles.getNamecon].join(' ')} style={{display:isnameShow?'':optonsNameIndex==null?'none':''}}>
                  <img src={changeImg} className={isMobile?APPstyles.img18:APPstyles.img16} style={{display:changeImg?'':'none'}} alt='flag' />
                  <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{optionsSymbol}</span>
                  <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:''}>{optionsName}</span>
                </div>
              </div>
              <img 
                src={clearImg} 
                alt='clear' 
                className={APPstyles.img16} 
                onClick={this.clearClick.bind(this)}  
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?closeMinOnLightImg:closeMinOnImg
                  this.setState({
                    clearImg:imgurl
                  })
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  this.setState({
                    clearImg:closeImg
                  })
                }}  
              /> 
              {/* 下拉框 */}
              <div ref = {testComponent => this.CodeComponent = testComponent}>
              {optonsNameShow&&
                <div className={styles.dropsDown_con}>
                  <DropDown
                    {...this.props}
                    tag='inputcon'
                    data={optionsNameData}
                    isLoading={isDownLoading}
                    cbDropDownData={this.dropDownNameClick.bind(this)}
                  />
                </div>
              }
              </div>
            </div>
          </div>
          {/* Quote */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.quote_con,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')} 
          >
            <span className={styles.spanW}>{t('Quote')}</span>
            <div className={[APPstyles.flex_row,styles.quote_con_turnover].join(' ')}>
              <span 
                className={[
                  !isnameShow?
                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                  :NETCHNG_1>0?APPstyles.color_red
                    :NETCHNG_1<0?APPstyles.color_green
                    :NETCHNG_1==0?APPstyles.color_grey
                    :ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                  APPstyles.fw700
                ].join(' ')}
                style={{marginRight:(NETCHNG_1>0||NETCHNG_1<0)?0:10}}
              >
                {isnameShow?common.transThousands(common.keepNum(streamingData&&streamingData['TRDPRC_1'],2)):'-'}
              </span>
              <img 
                src={NETCHNG_1>0?upImg:downImg} 
                style={{
                    width:13.86,
                    height:12,
                    display:(NETCHNG_1>0||NETCHNG_1<0)&&isnameShow?'':'none'
                }} 
              />
              <span 
                className={NETCHNG_1>0?APPstyles.color_red:NETCHNG_1<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:NETCHNG_1==0?APPstyles.color_grey:APPstyles.color_white}
                style={{ 
                  marginRight:10,
                  display:isnameShow?'':'none'
                }}
              >
                {common.addplusNum(common.transThousands(common.keepNum(NETCHNG_1,2)))}
              </span>
              <span 
                className={PCTCHNG>0?APPstyles.color_red:PCTCHNG<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:PCTCHNG==0?APPstyles.color_grey:APPstyles.color_white}
                style={{display:isnameShow?'':'none'}}
              >
                {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(PCTCHNG,2))))}
              </span>
            </div>
          </div>
          {/* Buy/Sell */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.position,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')} 
          >
            <div className={styles.spanW}>&nbsp;</div>
            <div>
              <span
                className={APPstyles.color_red}
                style={{marginRight:20}}
              >
                {t('Bid')} &nbsp;{common.transThousands(common.keepNum(BID,2))}
              </span>
              <span className={APPstyles.color_green}>
                {t('Ask')} &nbsp;{common.transThousands(common.keepNum(ASK,2))}
              </span>
            </div>
          </div>
          <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark].join(' ')}></div>
          {/* direction */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.direction,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
              isMobile?styles.direction_mobile:''
            ].join(' ')}
          >
            <span className={styles.spanW}>{t(ChgLangData=='zh-CN'?'direction':'Direction')}</span>
            <div 
              className={[
                APPstyles.flex_row,
                styles.direction_right,
                ChgThemeData=='Light'?styles.direction_right_light:'',
                isMobile?styles.direction_right_mobile:''
              ].join(' ')}
            >
              {directionData.map((item,index) => {
                return  <Button 
                          type='default' 
                          disabled={item['disabled']} 
                          key={index}
                          className={[
                            buySellIndex==index?buySellIndex==0?APPstyles.btn_red:APPstyles.btn_green:item['disabled']?'':ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black,
                            buySellIndex==index?buySellIndex==0?APPstyles.bg_red:APPstyles.bg_green:ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_backTop_black
                          ].join(' ')}
                          style={{fontSize:!isMobile?12:14}}
                          onClick={()=>{
                            typeData[1]['name']=index==0?'Stop Limit':'Stop Loss'
                            typeData[1]['key1']=index==0?chgLimitNum:chgLossNum

                            let chgUntilIndex=typeof(untilIndex)=='undefined'?0:untilIndex
                            let stopPrice=index==0?'Limit Price':'Stop Price'
                            let holder=index==0?'Enter Limit Price':'Enter Stop Price'
                            
                            this.setState({
                              buySellIndex:index,
                              typeData:typeData,
                              untilIndex:chgUntilIndex,
                              chgStopPrice:stopPrice,
                              chgStopPricePholderName:holder,
                              comfirmDisabled:''
                            })
                          }}
                        >
                          {t(item['name'])}
                        </Button>
                })
              }
            </div>
          </div>
          <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark].join(' ')}></div>
          {/* Type */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.direction,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <div className={styles.spanW}>{t('Type')}</div>
            <div 
              className={[
                APPstyles.flex_row,
                styles.direction_right,
                ChgThemeData=='Light'?styles.direction_right_light:'',
                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
              ].join(' ')}
            >
            {
              countryCode!=='US'?
                t('Limit')
              :
                typeData.map((item,index) => {
                return <Button 
                        type='default' 
                        key={index} 
                        disabled={item['disabled']}
                        className={[
                          ChgThemeData=='Light'?'':APPstyles.bg_backTop_black,
                          item['disabled']?'':typeIndex===index?styles.typebutton_active:'',
                          item['disabled']?'':ChgThemeData=='Light'?APPstyles.btn_hover_light:typeIndex===index?APPstyles.bg_C8C7C7_hover:APPstyles.btn_black
                        ].join(' ')}
                        onClick={()=>{
                          let stopPrice=buySellIndex==0?'Limit Price':'Stop Price'
                          let holder=buySellIndex==0?'Enter Limit Price':'Enter Stop Price'
                          this.setState({
                            typeIndex:index,
                            chgStopPrice:stopPrice,
                            chgStopPricePholderName:holder
                          })
                        }}
                      >
                        {t(item['name'])}
                      </Button>
                })
            }
            </div>
          </div>
          {/* Price */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Price1')}</span>
            <NumberCon
              {...this.props}
              tag='price'
              placeholderName='Enter Price'
              value={priceNums}
              cbInputValue={this.changeNumClick.bind(this)}
              cbNumberWarning={()=>{
                this.setState({
                  isPriceModalShow:true,
                  priceModalTag:'price'
                })
              }}
            />
          </div>
          {/* Stop Limit / Stop Loss */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              display:typeIndex==1?'':'none'
            }}
          >
            <span className={styles.spanW}>{t(chgStopPrice)}</span>
            <NumberCon
              {...this.props}
              tag='stopPrice'
              placeholderName={chgStopPricePholderName}
              value={typeData[1]['key1']}
              cbInputValue={this.changeNumClick.bind(this)}
              cbNumberWarning={()=>{
                this.setState({
                  isPriceModalShow:true,
                  priceModalTag:'stopPrice'
                })
              }}
            />
          </div>
          <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark].join(' ')}></div>
          {/* Quantity */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
              
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Quantity')}</span>
            <NumberCon
              {...this.props}
              tag='quantity'
              placeholderName='Enter Quantity'
              value={quantityNums}
              share={share}
              cbInputValue={this.changeNumClick.bind(this)}
              cbNumberWarning={()=>{
                this.setState({
                  isPriceModalShow:true,
                  priceModalTag:'quantity'
                })
              }}
              chgValueFun={(data)=>{
                this.setState({
                  quantityNums:data
                })
              }}
              cbCalculatorFun={()=>{
                this.setState({
                  isCalculatorShow:true
                })
              }}
            />
          </div>
          {/* Qty Held */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.position,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')} 
            style={{display:isnameShow?'':'none'}}
          >
            <div className={styles.spanW}>
              <span>{t(ChgLangData=='zh-CN'?'QtyHeld':'Qty Held')}</span>
              <Tooltip 
                title={t("Quantity held is for reference only. If you have any questions, please contact customer service center: 0800-073-588")} 
                overlayClassName={[
                  ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                  !isTablet?'':APPstyles.tooltipcon_custurm_left
                ].join(' ')}
              >
                <img 
                  src={tipsImg1}  
                  alt='tips'  
                  className={[APPstyles.img16,styles.img_help].join(' ')}
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                    this.setState({
                      tipsImg1:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    this.setState({
                      tipsImg1:tipImg
                    })
                  }}
                />
              </Tooltip>
            </div>
            <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{common.transThousands(common.keepNum(QtyHeld,2))}</span>
          </div>
          {/* Amount */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.amount,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <div className={styles.spanW}>
              <span>{t(ChgLangData=='zh-CN'?'amount':'Amount')}</span>
              <Tooltip 
                title={t("The amount is indicative only, it excludes handling fees, commission, transaction taxes and other related expenses.")} 
                overlayClassName={[
                  ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                  !isTablet?'':APPstyles.tooltipcon_custurm_left
                ].join(' ')}
              >
                <img 
                  src={tipsImg2} 
                  alt='tips'
                  className={[APPstyles.img16,styles.img_help].join(' ')} 
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                    this.setState({
                      tipsImg2:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    this.setState({
                      tipsImg2:tipImg
                    })
                  }} 
                />
              </Tooltip>
            </div>
            <div>
              <span className={[styles.amountNum,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>{common.transThousands(common.keepNum(Amount,2))}</span>
              <span 
                className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} 
                style={{display:isnameShow?'':'none',marginLeft:6}}
              >
                {
                  currency_1&&currency_cn?
                    ChgLangData=='zh-CN'?currency_cn:currency_1
                  :
                  t(curreny)  
                }
              </span>
            </div>
          </div>
          {/* Good Until */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.until_con,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              display:countryCode=='US'?'':'none'
            }}
          >
            <span className={styles.spanW}>{t('Good Until')}</span>
            <div 
            className={[
              APPstyles.flex_row,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?styles.direction_right_light:''
            ].join(' ')} 
            >
            {
            countryCode!=='US'?
              t('Today')
            :
              untilData.map((item,index) => {
                return <Button 
                        type='default' 
                        key={index} 
                        disabled={item['disabled']}
                        className={[
                          ChgThemeData=='Light'?'':APPstyles.bg_backTop_black,
                          item['disabled']?'':untilIndex===index?styles.typebutton_active:'',
                          item['disabled']?'':ChgThemeData=='Light'?APPstyles.btn_hover_light:untilIndex===index?APPstyles.bg_C8C7C7_hover:APPstyles.btn_black
                        ].join(' ')}
                        onClick={()=>{
                          this.setState({
                            untilIndex:index
                          })
                        }}
                      >
                        {t(item['name'])}
                      </Button>
                })
            }
            </div>
          </div>
          {/*  Order Memory */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              styles.trade_tips,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              display:isnameShow?'':'none'
            }}
          >
            <span className={styles.spanW}>{t('Order Memory')}</span>
            <div 
              className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} 
              style={{cursor:'pointer'}}
              onClick={()=>{
                this.setState({
                  isRemember:!isRemember
                })
                if(isRemember){
                  if(navIdAPP=='quote'&&navId=='quote'){
                    sessionStorage.removeItem('isRememberQuote')
                  }else{
                    sessionStorage.removeItem('isRememberTrade')
                  }
                }else{
                  if(navIdAPP=='quote'&&navId=='quote'){
                    sessionStorage.setItem('isRememberQuote',true)
                  }else{
                    sessionStorage.setItem('isRememberTrade',true)
                  }
                }
              }}
            >
              <img src={isRemember?remeberOnImg:remeberOffImg} className={APPstyles.img24} style={{position:'relative',top:-1,marginRight:4}} /> 
              {t('Remember')}
            </div> 
          </div>
          {/* Details */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              styles.trade_tips,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              display:isnameShow?'':'none'
            }}
          >
            <span className={styles.spanW}>{t('Details')}</span>
            <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
              {t("After placing an order, please be sure to confirm your order status in")} {t(ChgLangData=='zh-CN'?'':'"Today’s Order"')}{ChgLangData=='zh-CN'?'':'.'}
            </div> 
          </div> 
          <div 
            className={[
              styles.line,
              ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          ></div>
          <ListCon 
            {...this.props}
            isScrollShow={isScrollShow}
            callbackPathnameApp={(data)=>{
              this.props.callbackPathnameApp(data)
            }}
            cbGotoPage_1={()=>{
              this.props.cbGotoPage_1()
            }}
          />
        <div 
          className={[
            styles.line,
            ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark
          ].join(' ')}
        ></div>
          
        </div>
        {/* Button */}
        <div 
          className={[
            APPstyles.flex_row,
            APPstyles.place_button,
            ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark
          ].join(' ')}
          style={{paddingTop:16}}
        >
          <Button 
            type='default' 
            className={APPstyles.btn_gray} 
            onClick={this.closeClick.bind(this)}
          >
            {t('Cancel')}
          </Button>
          <Button 
            type='default' 
            style={{background:buySellIndex==0?'#F5475B':buySellIndex===1?'#39C46E':'#393939'}}
            className={ buySellIndex==0?APPstyles.btn_red:buySellIndex==1?APPstyles.btn_green:''} 
            disabled={comfirmDisabled}
            onClick={this.confirmClick.bind(this)}
          >
            {t(buySellIndex===null?'Place An Order':'Confirm Order')}
          </Button>
        </div>
        {isPriceModalShow&&
          <PriceModal
            {...this.props}
            tag={priceModalTag}
            chgStopPricePholderName={chgStopPricePholderName}
            cbClosePriceModal={()=>{
              this.setState({
                isPriceModalShow: false
              })
            }}
          />
        }
        {isTipBuyPowerShow&&
          <BuyPowerModal
            {...this.props}
            cbCloseModal={(data)=>{
              this.setState({
                isTipBuyPowerShow: false
              })
            }}
          />
        }
        {isCalculatorShow&&
          <CalculatorCon
            {...this.props}
            currency_1={currency_1}
            currency_cn={currency_cn}
            curreny={curreny}
            priceNums={priceNums}
            quantityNums={quantityNums}
            cbCloseModal={(data)=>{
              this.setState({
                isCalculatorShow: false
              })
              if(data['tag']=='Y'){
                this.setState({
                  priceNums: data['price'],
                  quantityNums: data['quatity'],
                  Amount:data['price']*data['quatity']
                })
              }
            }}
            cbNumberWarning={()=>{
              this.setState({
                isPriceModalShow:true,
                priceModalTag:'price'
              })
            }}
          />
        }
        {noteVisible&&
          <NoteCon 
            {...this.props} 
            visible={noteVisible}
            modalData={noteData}
            directionData={directionData[buySellIndex]} 
            cbBtnDataFun={(sign)=>this.cbNoteConFun(sign)}
          />
        }
      </div>
  )}
}
export default withTranslation()(observer(TradeComponents));
import Login from '../pages/Login/index'
import Quote from '../pages/Quote'
import Account from '../pages/Account/index'
import NotFound from '../pages/NotFound'
import News from '../pages/News/index'
import Chart from "../components/Chart/TechnicalChart/TechnicalChart"
import Campagin from '../pages/Campagin/index'

// 动态更换地址栏链接
let locationName=window['location']['pathname']
let routesName=locationName.split('/')
let loginName='login'

if(routesName[2]&&routesName[2].toLocaleLowerCase()=='login'){
  loginName='login'
}else if(routesName[2]&&routesName[2].toLocaleLowerCase()=='admin'){
  loginName='admin'
}else if(routesName[2]&&routesName[2].toLocaleLowerCase()=='login2'){
  loginName='login2'
}

export default [
  { path: '/'+routesName[1]+'/'+loginName, name: loginName, component: Login, auth: false },
  { path: '/'+routesName[1]+'/Quote', name: 'Quote', component: Quote, auth: true },
  { path: '/'+routesName[1]+'/Account', name: 'Account', component: Account, auth: true },
  { path: '/'+routesName[1]+'/News', name: 'News', component: News, auth: true },
  { path: '/'+routesName[1]+'/App', name: 'App', component: NotFound, auth: false },
  { path: '/'+routesName[1]+'/Chart', name: 'Chart', component: Chart, auth: false }, // test purpose
  { path: '/'+routesName[1]+'/Campagin', name: 'Campagin', component: Campagin, auth: true },
]

import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import APPstyles from '../../../../../App.less'
import styles from './index.less'

class PresetList extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    // 点击presetIndex
    dealPresetClick(item,index){
        this.props.cbChgPresetIndexFun(item,index)
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        
    }
    //初始化
    componentDidMount(){
        
    }
    render(){
        const {t,ChgThemeData,presetList,presetIndex} = this.props  
        return (
            <div className={[styles.quick_con,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
                <div className={[styles.preset_con,APPstyles.flex_row].join(' ')}>
                    {presetList.map((item,index) => {
                        return  <div 
                                    key={index} 
                                    className={[
                                        styles.preset_item,
                                        APPstyles.flex_center,
                                        APPstyles.flex_column,
                                        presetIndex==index?APPstyles.bg_blue:ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.btn_black,
                                        presetIndex==index?APPstyles.bg_blue:ChgThemeData=='Light'?styles.preset_item_light:'',
                                        presetIndex==index?APPstyles.btn_blue:''
                                    ].join(' ')}
                                    onClick={this.dealPresetClick.bind(this,item,index)} 
                                >
                                    <span className={APPstyles.fz14}>{t(item['name'])}</span>
                                    <span className={styles.num} style={{display:item['isShow']&&item['num']?'':'none'}}>({item['num']})</span>
                                </div>
                    })}
                </div>
            </div>
    )}
}
export default withTranslation()(observer(PresetList))
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const OBV = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(10);
  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);
  const [height, setHeight] = useState("1");

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.OBV_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const OBV = localChartSetting.option;

        props.onTAValueChange({
          ta: "OBV",
          name: `${t("chart5.TA.OBV")} (OBV)`,
          option: {
            period: OBV.period,
            color: OBV.color,
            thickness: OBV.thickness,
            style: OBV.style,
            showTracker: OBV.showTracker,
            height: OBV.height,
          },
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const OBV = props.localChartSetting.OBV_Option.option;

    setPeriod(parseInt(OBV.period) || period);
    setColor(OBV.color);
    setThickness(parseInt(OBV.thickness) || thickness);
    setStyle(OBV.style);
    setShowTracker(OBV.showTracker);
    setHeight(OBV.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    props.onTAValueChange({
      ta: "OBV",
      name: `${t("chart5.TA.OBV")} (OBV)`,
      option: {
        period: period,
        color: color,
        thickness: thickness,
        style: style,
        showTracker: showTracker,
        height: height,
      },
    });
  }, [period, color, thickness, style, showTracker, height]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const OBV = props.localChartSetting.OBV_Option.option;

    setPeriod(parseInt(OBV.period) || period);
    setColor(OBV.color);
    setThickness(parseInt(OBV.thickness) || thickness);
    setStyle(OBV.style);
    setShowTracker(OBV.showTracker);
    setHeight(OBV.height);
  }, [props.isReset]);

  const resetALL = () => {
    setPeriod(10);
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  const onThicknessChange = (e) => {
    setThickness(e.target.value);
  };

  const onStyleChange = (e) => {
    setStyle(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const onShowTracker = () => {
    if (showTracker) setShowTracker(false);
    else setShowTracker(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "OBV":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "OBV", t("chart5.setting"));
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("OBV")}></div> */}
      <div className="box-container">
        <span className="span">{t("chart5.TA.OBV")} (OBV)</span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("OBV")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem">
          <div className="taparamlbl">{t("chart5.technicalAnalysisLine")}</div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>
            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e)}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e)}>
              <select value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={onShowTracker}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
        <div className="menusubitem">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select defaultValue="1" onChange={(e) => onHeightChange(e)} value={height}>
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react'
import { Tooltip } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  tipImg,
  tipOnImg,
  tipOnLightImg
} from '@/asserts/imgs/common/index'
import DropDown from '@/components/DropDown/index'
import SecurityAssets from './securityAssets/index'
import Unrealised from './Unrealised/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let getCountry='US',timeoutId=null

class MsgCon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      BuyingPowerNum:'',
      BuyingPowerCur:'',
      dropDownData:[],
      dropDownIndex:0,
      isDownLoading:false,
      isDropdownShow:false,
      minW640:640,
      minW320:320,
      minW570:570,
      minW500:500,
      minW403:403,
      minW336:336,
      minW479:479,
      getTop:0,
      getLeft:0,
      getDownW:300,
      getRIght:'unset',
      allData:[],
      tipsImg0:tipImg,
      tipsImg1:tipImg,
      tipsImg2:tipImg,
      chgBuyingImg:dropDownImg,
      chgMarketImg:dropDownImg,
      isBuyingShow:false,
      getImgTag:'buying',
      buyingData:[],
      isGetPortfolioBuyingPower:true
    }
  }
  //下拉框--显示/隐藏--buying--可用餘額查詢 呼叫要等上一個呼叫的回覆已返回才执行接口，還有如１０秒沒有收到回覆就當作收到失敗回覆
  buyingClick(tags,e){
    const {getImgTag,isDropdownShow,isGetPortfolioBuyingPower}=this.state
    if(tags=='tooltip') return
    e.stopPropagation()
    let tag='buying'
    this.setState(state => ({
      isDropdownShow:getImgTag==tag?!state.isDropdownShow:true,
      getImgTag:tag,
      isDownLoading:true,
      buyingData:[],
      dropDownData:[]
    }))
    this.chgDropDownFun(tag)
    if((!isDropdownShow&&isGetPortfolioBuyingPower)||(getImgTag=='market'&&isDropdownShow)){
      this.setState({
        isGetPortfolioBuyingPower:false
      })
      this.getBuyingPower()
      clearTimeout(timeoutId)
      timeoutId = setTimeout(()=>{
        this.noDataFun()
      },15000)
    }
  }
  //下拉框--显示/隐藏--market
  marketClick(e){
    const {marketpDownData}=this.props
    const {getImgTag}=this.state
    if(marketpDownData.length<=1) return
    e.stopPropagation()
    let tag='market'
    this.setState(state => ({
      isDropdownShow:getImgTag==tag?!state.isDropdownShow:true,
      getImgTag:tag,
      dropDownData:marketpDownData,
      isDownLoading:false
    }))
    this.chgDropDownFun(tag)
  }
  // 下拉框自适应
  chgDropDownFun(tag){
    const {bodyW,isPC,isTablet,isMobile,isSearchPageShow}=this.props
    const {minW320,minW640,minW500}=this.state
    let getLeftID=document.getElementById('getLeftID').clientWidth
    let getDownWVal=bodyW>minW320?300:'90%'
    let getLeftVal=0
    let getRIghtVal=0
    if(tag=='buying'){
      getLeftVal=isPC?
                  getLeftID+20+36
                :isTablet&&!isMobile?
                  isSearchPageShow?getLeftID+24:getLeftID+20+36
                :bodyW<=minW500?'unset':bodyW>minW640?getLeftID+24:getLeftID+24+16
      getRIghtVal=bodyW<=minW500?10:'unset'
      }else{
      getLeftVal=isMobile?14:20
      getRIghtVal='unset'
    }
    this.setState({
      getTop:tag=='buying'?48:26,
      getLeft:getLeftVal,
      getDownW:getDownWVal,
      getRIght:getRIghtVal
    })
  }
  // 切换tab--显示对应的数据
  dealDropDownDataFun(data){
    if(data.length<=0){
      this.setState({
        isGetPortfolioBuyingPower:true
      })
      return
    }
    let countryOne=data[getCountry]
    let resData=Object.keys(countryOne)
    let flagData=Object.keys(common.imgArrFlag)
    let getData=[],newData=[]

    if(resData.length>0){
      for(let i in resData){
        for(let j in flagData){
          if(resData[i]==flagData[j]){
            getData.push({
              cur:resData[i],
              num:countryOne[resData[i]],
              img:common.imgArrFlag[resData[i]]
            })
          }
        }
      }
      newData=common.imgDataFlagFun(getData,'img')
    }
    this.setState({
      buyingData:newData,
      BuyingPowerNum:newData[0]['num'],
      BuyingPowerCur:newData[0]['cur'],
      isDownLoading:false,
      isGetPortfolioBuyingPower:true,
      dropDownData:newData
    })
  }
  //获取数据API /getPortfolioBuyingPower
  getBuyingPower(){
    fetch(common.getHttpZs+"getPortfolioBuyingPower", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: sessionStorage.getItem("token")
      })
    })
    .then((response) => {
      clearTimeout(timeoutId)
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then((res) => {
      res=JSON.parse(res)
      if(res['status']==0){
        let allData=res['data']['buying_power']
        this.setState({
          allData:allData
        })
        this.dealDropDownDataFun(allData)
        return
      }
      this.noDataFun()
    })
    .catch((err) => {
      clearTimeout(timeoutId)
      console.log(err)
      this.setState({
        dropDownData:[]
      })
      this.noDataFun()
    })
  }
  noDataFun(){
    //获取失败
    this.setState({
      isDownLoading: false,
      buyingData:[],
      BuyingPowerNum:'',
      BuyingPowerCur:'',
      allData:[],
      isGetPortfolioBuyingPower:true,
      dropDownData:[]
    })
  }
  //点击外部关闭弹框
  handleClick = e => {
    if(!this.testComponent.contains(e.target)){
      this.setState({
        isDropdownShow:false,
        chgBuyingImg:dropDownImg,
        chgMarketImg:dropDownImg
      })
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,isCloseShow,countryData,tabIndex,isSearchPageShow}=this.props
    const {allData,getImgTag}=this.state
    if(bodyW!==newProps['bodyW']||isSearchPageShow!==newProps['isSearchPageShow']){
      this.chgDropDownFun(getImgTag)
    }
    //关闭下拉框
    if(isCloseShow!==newProps['isCloseShow']){
      this.setState({
        isDropdownShow:false
      })
    }
    //初始化
    if(countryData!==newProps['countryData']){
      getCountry=countryData[tabIndex]
    }
    // 切换tab
    if(tabIndex!==newProps['tabIndex']){
      this.setState({
        dropDownIndex:0
      })
      getCountry=countryData[tabIndex]
      this.dealDropDownDataFun(allData) 
    }
  }
  // 初始化
  componentDidMount(){ 
    const {getImgTag}=this.state
    this.chgDropDownFun(getImgTag)
    document.addEventListener('click', this.handleClick) 
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }
  render() {
    const {t,bodyW,ChgThemeData,ChgLangData,isPC,isTablet,isMobile,isSearchPageShow,isPieShow,store,marketpDownData,chgCur} = this.props
    const {BuyingPowerNum,minW640,BuyingPowerCur,dropDownData,isDownLoading,isDropdownShow,getTop,getLeft,getDownW,getRIght,minW570,tipsImg0,tipsImg1,tipsImg2,chgBuyingImg,chgMarketImg,getImgTag,dropDownIndex,minW403,minW479,minW336}=this.state
    let hasData=store.folioInfoData[dropDownIndex]
    return (
      <div
        className={[
          styles.msg_con,
          APPstyles.flex_row,
          isPC&&!isSearchPageShow?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
          isMobile?ChgThemeData=='Dark'?styles.msg_con_mobileD:styles.msg_con_mobileW:'',
          isTablet||(isPC&&isSearchPageShow)?APPstyles.wPer100:''
        ].join(' ')}
        style={{
          marginBottom:isPC&&!isSearchPageShow?0:isMobile&&!isPieShow?10:22,
          marginTop:isPC?18:4
        }}
      >
        {/* left */}
        <div 
          className={[
            styles.marginLeft24,
            (!isMobile&&isTablet&&isSearchPageShow)||isMobile?'':styles.marginRight36,
            (isPC&&isSearchPageShow)||(!isMobile&&isTablet&&!isSearchPageShow)?styles.width_per5:'',
            (!isMobile&&isTablet&&isSearchPageShow)||isMobile?styles.width_per3:'',
            bodyW>minW640?'':styles.marginRight16,
            (isPC&&!isSearchPageShow)?styles.min_width124:''
          ].join(' ')}
        >
          {/* Total Market Value */}
          <div 
            id='getLeftID' 
            className={[
              styles.msg_market,
              APPstyles.flex_column,
              isMobile&&ChgLangData=='zh-CN'?styles.min_height62:'',
              isMobile&&ChgLangData=='en-US'?
                bodyW<=minW479&&bodyW>minW403?
                  styles.min_height70
                :
                  bodyW<=minW403?
                    bodyW>minW336?styles.min_height80:styles.min_height100
                  :
                    styles.min_height62
              :
                ''
            ].join(' ')}
          >
            <div 
              className={[APPstyles.flex_row,styles.min_height24].join(' ')}
              style={{
                cursor:marketpDownData.length<=1?'default':'pointer',
                alignItems:'center',
                marginBottom:isMobile?2:0
              }}
              onClick={this.marketClick.bind(this)}
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                this.setState({
                  chgMarketImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                this.setState({
                  chgMarketImg:dropDownImg
                })
              }}
            >
              <span className={styles.title}>{t('Total Market Value')} <label style={{display:chgCur?'':'none',cursor:marketpDownData.length<=1?'default':'pointer'}}>({t(chgCur)})</label></span>
              <img 
                alt='dropdown'
                src={chgMarketImg}  
                style={{
                  transform:isDropdownShow&&getImgTag=='market'?'rotate(-180deg)':'rotate(0deg)',
                  display:marketpDownData.length>1?'':'none'
                }}
              />
            </div>
            <span 
              className={[
                styles.num,
                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
              ].join(' ')}
              style={{
                fontSize:isMobile?16:24,
                lineHeight:isMobile?'18px':'28px'
              }}
            >
              {common.transThousands(common.keepNum(hasData&&hasData['total_amount'],2))}
            </span>
          </div>
          {/* Total Security Assets */}
          <SecurityAssets 
          {...this.props}
          tipsImg1={tipsImg1}
          isAssets={true}
          hasData={store.folioInfoData[dropDownIndex]}
          cbImgurlFun={(url)=>{
            this.setState({
              tipsImg1:url
            })
          }}
          />
        </div>
        {/* center */}
        <div 
          className={[
            (!isMobile&&isTablet&&isSearchPageShow)||isMobile?'':styles.marginRight36,
            (isPC&&isSearchPageShow)||(!isMobile&&isTablet&&!isSearchPageShow)?styles.width_per5:'',
            (!isMobile&&isTablet&&isSearchPageShow)||isMobile?styles.width_per3:'',
            bodyW>minW640?'':styles.marginRight16,
            (isPC&&!isSearchPageShow)?styles.min_width124:''
          ].join(' ')}>
          {/* Buying Power */}
          <div
            className={[
              styles.msg_buying,
              isMobile&&ChgLangData=='zh-CN'?styles.min_height62:'',
              isMobile&&ChgLangData=='en-US'?
                bodyW<=minW479&&bodyW>minW403?
                  styles.min_height70
                :
                  bodyW<=minW403?
                    bodyW>minW336?styles.min_height80:styles.min_height100
                  :
                    styles.min_height62
              :
                ''
            ].join(' ')}
            style={{minHeight:52}}
          >
            <div
              className={[
                APPstyles.flex_row,
                styles.tooltip_con,
                styles.min_height24
              ].join(' ')}
              style={{marginBottom:isMobile?2:0}}
              onClick={this.buyingClick.bind(this,'tooltip')} 
            >
              <div className={[APPstyles.flex_row,APPstyles.flex_topcenter].join(' ')}>
                <span className={styles.title} style={{marginBottom:0}}>{t('Buying Power1')}</span>
                <span className={styles.tooltip_cur} style={{display:BuyingPowerCur?'':'none'}}>({t(BuyingPowerCur)})</span>
              </div>
              <Tooltip 
                title={t("Buying power = Bank available balance + in-transit payment  + today's selling amount - in-transit transfer amount. (Buying power is for reference only. If you have any questions, please contact customer service center: 0800-073-588)")} 
                overlayClassName={[
                  ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                  bodyW>minW570?'':APPstyles.tooltipcon_custurm_right
                ].join(' ')}
              >
                <img 
                  src={tipsImg0}  
                  alt='tips' 
                  className={APPstyles.img16} 
                  style={{
                    bottom:1
                  }}
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                    this.setState({
                      tipsImg0:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    this.setState({
                      tipsImg0:tipImg
                    })
                  }}
                />
              </Tooltip>
            </div>
            <div 
              className={[
                APPstyles.flex_row,
                styles.num
              ].join(' ')}
              style={{
                cursor:'pointer',
                fontSize:BuyingPowerNum=='-'||BuyingPowerNum==''||!BuyingPowerNum?16:isMobile?16:24,
                lineHeight:BuyingPowerNum=='-'||BuyingPowerNum==''||!BuyingPowerNum?'18px':isMobile?'18px':'26px'
              }}
              onClick={this.buyingClick.bind(this,'dropdown')} 
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                this.setState({
                  chgBuyingImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  chgBuyingImg:dropDownImg
                })
              }}
            >
              <div>
                <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                  {BuyingPowerNum=='-'||BuyingPowerNum==''||!BuyingPowerNum?t('Check buying power'):common.transThousands(common.keepNum(BuyingPowerNum,2))}
                </span>
                <img 
                  alt='dropdown'
                  src={chgBuyingImg}
                  className={styles.dropDown_img} 
                  style={{
                    transform:isDropdownShow&&getImgTag=='buying'?'rotate(-180deg)':'rotate(0deg)',
                    bottom:0
                  }}
                />
              </div>
            </div>
          </div>
          {/* Total Unrealised P/L */}
          <Unrealised
            {...this.props}
            minW570={minW570}
            tipsImg2={tipsImg2}
            isUnrealised={true}
            hasData={store.folioInfoData[dropDownIndex]}
            cbImgurlFun={(url)=>{
              this.setState({
                tipsImg2:url
              })
            }}
          />
        </div>
        {/* Total Security Assets -----  isPC&&!isSearchPageShow  ------- */}
        <SecurityAssets 
          {...this.props}
          tipsImg1={tipsImg1}
          isAssets={false}
          hasData={store.folioInfoData[dropDownIndex]}
          cbImgurlFun={(url)=>{
            this.setState({
              tipsImg1:url
            })
          }}
        />
        {/* Total Unrealised P/L -----  isPC&&!isSearchPageShow  ------- */}
        <Unrealised
          {...this.props}
          minW570={minW570}
          tipsImg2={tipsImg2}
          isUnrealised={false}
          hasData={store.folioInfoData[dropDownIndex]}
          cbImgurlFun={(url)=>{
            this.setState({
              tipsImg2:url
            })
          }}
        />
        {/* right */}
        {/* Today’s P/L */}
        <div
          className={[
            APPstyles.flex_column,
            (isPC&&!isSearchPageShow)?styles.min_width124:'',
            isTablet||(isPC&&isSearchPageShow)?'':styles.marginRight36,
            (isPC&&isSearchPageShow)||(!isMobile&&isTablet&&!isSearchPageShow)?styles.marginRight24:'',
            (isPC&&isSearchPageShow)||(!isMobile&&isTablet&&!isSearchPageShow)?styles.width_per5:'',
            (!isMobile&&isTablet&&isSearchPageShow)||isMobile?styles.width_per3:''
          ].join(' ')}
        >
          <div className={APPstyles.flex_row}>
            <div 
              className={[
                styles.min_height24,
                styles.title
              ].join(' ')}
              onClick={()=>{
                this.props.cbPortDetailShow(true)
              }}
              style={{marginBottom:0}}
            >
              <span className={styles.title_info_btn}>{t('Today’s P/L1')} </span>
            </div>
          </div>
          <span 
            className={[
              styles.num,
              Number(hasData&&hasData['total_today_profit'])>0?APPstyles.color_red:Number(hasData&&hasData['total_today_profit'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
            ].join(' ')}
            style={{
              fontSize:isMobile?16:24,
              lineHeight:isMobile?'18px':'28px',
              marginTop:isMobile?4:0
            }}
          >
            {common.addplusNum(common.transThousands(common.keepNum(hasData&&hasData['total_today_profit'],2)))}
          </span>
        </div>
        {/* DropDown */}
        <div
          ref = {testComponent => this.testComponent = testComponent}
          className={styles.dropsDown_con}
          style={{
            width:getDownW,
            top:getTop,
            left:getLeft,
            right:getRIght,
            display:isDropdownShow?'':'none'
          }}
        >
          <DropDown
            {...this.props}
            tag='portfolio'
            data={dropDownData}
            isLoading={isDownLoading}
            cbDropDownData={(data)=>{
              if(getImgTag=='market'){
                this.setState({
                  isDropdownShow:false,
                  dropDownIndex:data[1]
                })
                this.props.cbChgMarketDataFun(data)
                return
              }
              this.setState({
                BuyingPowerNum:data[0]['num'],
                BuyingPowerCur:data[0]['cur'],
                isDropdownShow:false
              })
            }}
          />
        </div>
      </div>
    )
  }
}
export default withTranslation()(observer(MsgCon))
import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import { 
  minusImg,
  minusOnImg,
  minusLightImg,
  minusOnLightImg,
  plusImg,
  plusLightImg,
  plusOnLightImg,
  plusOnImg,
  calculatorImg,
  calculatorOnImg,
  calculatorLightImg,
  calculatorLightOnImg,
} from '@/asserts/imgs/trade/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class NumberCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgMinusImg:minusImg,
      chgPlusImg:plusImg,
      chgCalculImg:calculatorImg,
    }
  }
  //input值的变化
  changeNumClick(e){
    const {tag,cbInputValue} = this.props;
    let value = e.target.value;
    if(tag=='quantity'){
      // 仅允许正整数
      if (!/^\d*$/.test(value)) {
        return;
      }
      // 处理输入值，去除前导0，但允许单个0
      value = value.replace(/^0+/, '') || '0';
      cbInputValue({ name: tag, value });
    }else{
      // 如果输入为空或仅为0，允许并直接返回
      if (value === '' || value === '0') {
        cbInputValue({ name: tag, value });
        return;
      }
      // 确保输入是数字，允许小数点的存在
      if (!/^(\d+\.?\d*|\.\d+)$/.test(value)) {
        return;
      }
      // 格式化输入，确保小数点前至少有一位数字
      if (value.startsWith('.')) {
        value = '0' + value;
      }
      // 如果是整数，去除前导0
      if (/^0\d+$/.test(value)) {
        value = value.replace(/^0+/, '');
      }
      cbInputValue({ name: tag, value });
    }
  }
  // 按钮的变化
  btnClick(name){
    const {value,tag,share} = this.props
    if(value===''||(typeof(value)!=='number'&&isNaN(value))){
      this.props.cbNumberWarning()
      return
    }
    if(typeof(share)=='undefined'||!share||share==''){ 
      this.numsFun(name,value,tag)
      return
    }
    if(tag=='price'||tag=='stopPrice') return
    this.getShareFun(name,Number(value),tag,Number(share))
  }
  // share有值时进行运算--value的倍数
  getShareFun(name,value,tag,share){
    let newVal=''
    if(name==='minus'){//mins
     if(value%share==0){
        newVal=value-share
      }else{
        newVal=value-(value%share)
      }
    }else{//puls
      newVal=value+share-(value%share)
    }
    let obj={
      name:tag,
      value:newVal
    }
    this.props.cbInputValue(obj)
  }
  //加/减--运算----Quantity/Price
  numsFun(name,getNum,tag){
    if(name==='minus'){//mins
      if(String(getNum).indexOf('.')===-1&&getNum>0){//整数--不能小于0
        getNum--
      }else{//小数
        if(getNum> 0){//不能小于0
          let len = getNum.toString().split('.')[1].length
          let num = '0.'
          for (let i = 0;i<len-1;i++){
            num += 0
          }
          num+='1'
          getNum=Number(getNum-num).toFixed(len)
        }
      }
    }else{//puls
      if(String(getNum).indexOf('.')===-1){//整数
        getNum++
      }else{//小数
        let len = getNum.toString().split('.')[1].length
        let num = '0.'
        for (let i = 0;i<len-1;i++){
          num += 0
        }
        num+='1'
        getNum=Number(Number(getNum)+Number(num)).toFixed(len)
      }
    }
    let obj={
      name:tag,
      value:getNum
    }
    this.props.cbInputValue(obj)
  }
  // 更改为对应的图片
  chgImgFun(){
    const {ChgThemeData}=this.props
    let imgurl_0=ChgThemeData=='Light'?minusLightImg:minusImg
    let imgurl_1=ChgThemeData=='Light'?plusLightImg:plusImg
    this.setState({
      chgMinusImg:imgurl_0,
      chgPlusImg:imgurl_1,
      chgCalculImg:ChgThemeData=='Light'?calculatorLightImg:calculatorImg,
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData}=this.props
    if(ChgThemeData!==newProps['ChgThemeData']){
      this.chgImgFun();
    }
  }
  //初始化
  componentDidMount(){
    this.chgImgFun()
  }
  render(){
    const {t,ChgThemeData,placeholderName,value,isPC,tag} = this.props
    const {chgMinusImg,chgPlusImg,chgCalculImg}=this.state
    return (
      <div 
      className={[
        APPstyles.flex_row,
        styles.number_con,
        value==''?styles.number_con_not:'',
        ChgThemeData=='Light'?styles.number_con_light:''
      ].join(' ')}
      >
        <img 
          src={chgMinusImg} 
          alt='minus' 
          className={APPstyles.img24} 
          onClick={this.btnClick.bind(this,'minus')}   
          onMouseOver={()=>{
            if(!isPC) return
            let imgurl=ChgThemeData=='Light'?minusOnLightImg:minusOnImg
            this.setState({
              chgMinusImg:imgurl
            })
          }} 
          onMouseOut={()=>{
            if(!isPC) return
            let imgurl=ChgThemeData=='Light'?minusLightImg:minusImg
            this.setState({
              chgMinusImg:imgurl
            })
          }}  
        />
        <input 
          autoComplete='off'
          type='number' 
          placeholder={t(placeholderName)}  
          value={value} 
          onChange={this.changeNumClick.bind(this)} 
        />
        <img 
          src={chgCalculImg} 
          alt='calculator'
          style={{opacity:tag=='quantity'?'1':'0',cursor:tag=='quantity'?'poiner':'default'}} 
          className={[APPstyles.img24,styles.number_img_calculator].join(' ')} 
          onMouseOver={()=>{
            if(!isPC) return
            let imgurl=ChgThemeData=='Light'?calculatorLightOnImg:calculatorOnImg;
            this.setState({
              chgCalculImg:imgurl,
            })
          }} 
          onMouseOut={()=>{
            if(!isPC) return
            let imgurl=ChgThemeData=='Light'?calculatorLightImg:calculatorImg;
            this.setState({
              chgCalculImg:imgurl,
            })
          }}
          onClick={()=>{
            if(tag=='quantity'){
              this.props.cbCalculatorFun(true);
            }
          }} 
        />
        <img 
          src={chgPlusImg} 
          alt='plus' 
          className={APPstyles.img24} 
          onClick={this.btnClick.bind(this,'plus')} 
          onMouseOver={()=>{
            if(!isPC) return
            let imgurl=ChgThemeData=='Light'?plusOnLightImg:plusOnImg
            this.setState({
              chgPlusImg:imgurl
            })
          }} 
          onMouseOut={()=>{
            if(!isPC) return
            let imgurl=ChgThemeData=='Light'?plusLightImg:plusImg
            this.setState({
              chgPlusImg:imgurl
            })
          }}
        />
      </div>
    )}
}
export default withTranslation()(observer(NumberCon))

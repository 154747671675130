import React from 'react';
import {withTranslation} from 'react-i18next';
import common from '@/asserts/js/common';
import {
  fileExcelImg,
  fileExcelOnImg,
  fileExcelLightImg,
  fileExcelLightOnImg,
} from '@/asserts/imgs/common/index';
import Mobile from './mobile/index';
import List from './list/index';
import DropDown from './dropDownCom/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history:[],
      isLoading:true,
      from_year:'2020',
      to_year:'2024',
      isExport:false,
      excelImg:fileExcelImg,
    }
  }
  //获取数据API
  getTableData(from_year,to_year) {
    fetch(common.getHttpZs+'getOverseasIncome', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from_year: from_year,
        to_year: to_year,
        token: sessionStorage.getItem("token"),
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if(res['status']==0){
        if(res['data'].length>0&&JSON.stringify(res['data']) !== '{}'){
          let data=res['data'];
          this.setState({
            isLoading:false,
            history:data,
          });
        }else{
          this.noDataFun();
        }
        return;
      }
      this.noDataFun();
    })
    .catch((err) => {
      console.log('Request failed', err);
      this.setState({
        history: [],
        isLoading: false,
      });
    })
  }
  noDataFun(){
    this.setState({
      history: [],
      isLoading:false,
    });
  }
  handleMouse(isHover) {
    const { ChgThemeData,isPC } = this.props;
    const {history}=this.state;
    if (!isPC || history.length<=0) return;
    let imgurl;
    if (isHover) {
      imgurl = ChgThemeData === 'Light' ? fileExcelLightOnImg : fileExcelOnImg;
    } else {
      imgurl = ChgThemeData === 'Light' ? fileExcelLightImg : fileExcelImg;
    }
    this.setState({ 
      excelImg: imgurl, 
    });
  }
  // 以文档的形式导出table
  exportClick(){
    const {isExport,history}=this.state;
    if(history.length<=0) return;
    this.setState({
      isExport: !isExport,
    });
  }
  changeExcelImg(){
    const {ChgThemeData}=this.props;
    this.setState({ 
      excelImg: ChgThemeData === 'Light' ? fileExcelLightImg : fileExcelImg,
    });
  }
  // 选中年份
  cbDateFun(sign,year){
    const {from_year,to_year}=this.state;
    let fromYear=sign=='left'?year:from_year;
    let toYear=sign=='right'?year:to_year;
    this.setState({
      isLoading:true,
      from_year:fromYear,
      to_year:toYear,
    });
    this.getTableData(fromYear,toYear);
  }
  componentDidUpdate(newProps){
    const {ChgThemeData}=this.props;
    if(ChgThemeData!==newProps['ChgThemeData']){
      this.changeExcelImg();
    }
  }
  componentDidMount() {
    const currentYear = new Date().getFullYear(); 
    let fromYear=String(currentYear - 11);
    let toYear=String(currentYear);
    this.setState({
      from_year:fromYear,
      to_year: toYear,
    });
    this.changeExcelImg();
    this.getTableData(fromYear,toYear);
  }
  render() {
    const {ChgThemeData,isPC,isTablet,isSearchPageShow} =this.props;
    const {history,isLoading,isExport,excelImg} = this.state;
    return (
      <div 
        className={[
          styles.BankBalancePage,
          isPC&&isSearchPageShow?'':APPstyles.flex1_auto,
          isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
          isPC&&isSearchPageShow||isTablet?APPstyles.hPer_overflowH:'',
          isPC&&isSearchPageShow?'':isPC?history.length?APPstyles.paddingBottom40:'':'',  
        ].join(' ')}
        style={{padding:isPC?'0 16px':0}}
      >
        <div 
          className={[styles.headerCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}
          style={{padding:isPC?0:16,minHeight:isPC?59:64}}
        >
          <DropDown
            {...this.props}
            cbDateFun={(sign,data)=>this.cbDateFun(sign,data)}
          /> 
          {isPC&&
            <span 
              className={[
                styles.btnWords,
                APPstyles.flex_center,
                ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black,
              ].join(' ')} 
              onClick={()=>{this.exportClick()}}
              onMouseOver={() => this.handleMouse(true)} 
              onMouseOut={() => this.handleMouse(false)}
              style={{cursor:history.length>0?'pointer':'not-allowed'}}
            >
              <img src={excelImg} className={APPstyles.img24} style={{cursor:history.length>0?'pointer':'not-allowed'}} />
            </span>
          }
        </div>
        {isPC&&
          <List 
            {...this.props} 
            isLoading={isLoading}
            history={history}
            isExport={isExport}
          />
        }
        {!isPC&&
          <Mobile 
            {...this.props}
            history={history}
            isLoading={isLoading}
          />
        }
      </div>
    );
  }
}
export default withTranslation()(Index);
import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '../../../../asserts/js/common'
import { 
    dropDownImg,
    dropDownOnImg,
    dropDownOnLightImg,
    remeberOnImg,
    remeberOffImg
} from '../../../../asserts/imgs/common/index'
import TipsModal from '../tipsModal/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

let count = 0,chgNum = 0
let chgNumData=[
    {
        key:'MarketCap',
        num:0
    },
    {
        key:'InstrumentType',
        num:0
    },
    {
        key:'Sectors',
        num:0
    }
]

class CriteriaCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            tipsData:[],
            newNum:0,
            isTipsModalShow:false
        }
    }
    //是否折叠
    dropDownClick(index){
        const {listData}=this.props
        
        listData[index]['isShowAll']=!listData[index]['isShowAll']
        this.props.cbCheckboxData(listData)
    }
    // 判断总共有多少个--限制所有的checkbox只能选中10个--newNum
    chgNumDataFun(item,newData,i,key,num){
        if(item['key']==key){
            if(newData[i]['checked']&&chgNumData[num]['num']==0){
                chgNumData[num]['num']++
            }
            let marketCapLen=common.dealCheckedData(newData)
            if(!marketCapLen.length){
                chgNumData[num]['num']=0
            }
        }
    }
    //全选/全不选
    isSelectAllClick(index,item){
        const {listData}=this.props
        const {tipsData}=this.state
        let newData=listData[index]['data']
        let getNum=0

        listData[index]['isSelcetAll']=!listData[index]['isSelcetAll']
        listData[index]['isSelcetAllTemp']=!listData[index]['isSelcetAllTemp']

        for(let i in newData){
            newData[i]['checked']=listData[index]['isSelcetAll']?true:false
            newData[i]['checkedTemp']=listData[index]['isSelcetAll']?true:false
            tipsData[index]=listData[index]['isSelcetAll']?newData:[]
        }
        this.chgNumDataFun(item,newData,index,'MarketCap',0)
        this.chgNumDataFun(item,newData,index,'InstrumentType',1)
        this.chgNumDataFun(item,newData,index,'Sectors',2)
        for(let j in chgNumData){
            getNum+=Number(chgNumData[j]['num'])
        }
        let getNumAll=Number(chgNum)+Number(getNum)

        this.setState({
            tipsData:tipsData
        })
        this.props.cbCheckboxData(listData)
        this.props.cbCriteriaNum(getNumAll)
    }
    //单选
    checkedOneClick(index,index0,item){
        const {listData,keys3Data,CriteriaTag}=this.props
        const {newNum,tipsData}=this.state
        count = 0//定义一个计数器
        let newData=listData[index]['data'],getNum=0,tempNum=10
       
        if(CriteriaTag=='screener'&&!keys3Data.includes(item['key'])&&newData[index0]['checked']){
            tempNum--
            this.setState({
                newNum:tempNum
            })
        }else{
            if(CriteriaTag=='screener'&&!keys3Data.includes(item['key'])){
                this.setState({
                    isTipsModalShow:newNum>=10?true:false
                })
                if(newNum==10) return
            }
        }
        for(let i in newData){
             // 单选
            if(index0==i){
                newData[i]['checked']=!newData[i]['checked']
                newData[i]['checkedTemp']=!newData[i]['checkedTemp']

                if(CriteriaTag=='screener'&&!keys3Data.includes(item['key'])){
                    newData[i]['checked']?chgNum++:chgNum--
                }
                this.chgNumDataFun(item,newData,i,'MarketCap',0)
                this.chgNumDataFun(item,newData,i,'InstrumentType',1)
                this.chgNumDataFun(item,newData,i,'Sectors',2)
            }
            // 全选/全不选
            if(!newData[i]['checked']){
                listData[index]['isSelcetAll']=false
                listData[index]['isSelcetAllTemp']=false
            }else{
                count++
            }
            //判断是否都是选中状态/如果是则自动选中全选按钮
            if(count == newData.length){
                listData[index]['isSelcetAll']=true
                listData[index]['isSelcetAllTemp']=true
            }
        }
        for(let j in chgNumData){
            getNum+=Number(chgNumData[j]['num'])
        }
        let getNumAll=Number(chgNum)+Number(getNum)
        tipsData[index]=common.dealCheckedData(newData)
        this.setState({
            tipsData:tipsData
        })
        if(CriteriaTag=='screener'&&!keys3Data.includes(item['key'])){
            this.setState({
                newNum:chgNum
            })
        }
        this.props.cbCheckboxData(listData)
        this.props.cbCriteriaNum(getNumAll)
    }
    // 恢复为默认的数据
    resetDataFun(){
        chgNum=0
        chgNumData=[
            {
                key:'MarketCap',
                num:0
            },
            {
                key:'InstrumentType',
                num:0
            },
            {
                key:'Sectors',
                num:0
            }
        ]
    }
    // 动态更改tipsData数据
    chgTipsDataFun(){
        const {listData}=this.props
        const {tipsData}=this.state
        for(let i in listData){
            let newData=listData[i]['data']
            tipsData[i]=common.dealCheckedData(newData)
            this.setState({
                tipsData:tipsData
            })
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isResetAll,isDeleteIitem,listData,keys3Data,isgetPresetIndex,ischeckAll,CriteriaTag}=this.props
        //重置
        if(isResetAll!==newProps['isResetAll']){
            if(CriteriaTag=='news'){
                this.tipsDataFun()
            }else{
                this.resetDataFun()
                this.setState({
                    tipsData:[],
                    newNum:0
                }) 
            }
        }
        // 删除某条数据--重新计算个数---newNum
        if(isDeleteIitem!==newProps['isDeleteIitem']){
            let getNum=0
            for(let j in chgNumData){
                getNum+=Number(chgNumData[j]['num'])
            }
            chgNum--
            let getNumAll=Number(chgNum)+Number(getNum)

            
            this.setState({
                newNum:chgNum,
                isTipsModalShow:chgNum>=10?true:false
            })
            this.props.cbCriteriaNum2(getNumAll)
            this.chgTipsDataFun()
            
        } 
        // 选中某个preset--重新计算个数---newNum
        if(isgetPresetIndex!==newProps['isgetPresetIndex']){
            let getNum=0
            this.resetDataFun()
            for(let i in listData){
                let newData=listData[i]['data']
                if(keys3Data.includes(listData[i]['key'])){
                    let len=common.dealCheckedData(newData)
                    let num=len.length==0?0:1
                    chgNumData[i]['num']=num
                    getNum+=num
                }else{
                    let len=common.dealCheckedData(newData)
                    chgNum+=len.length
                }
            }
            let getNumAll=Number(chgNum)+Number(getNum)
            this.setState({
                newNum:chgNum,
                isTipsModalShow:chgNum>=10?true:false
            })
            this.props.cbCriteriaNum2(getNumAll)
            this.chgTipsDataFun()
        } 
        // 是否全选
        if(ischeckAll!==newProps['ischeckAll']){
            this.chgTipsDataFun()
            for(let i in listData){
                if(keys3Data.includes(listData[i]['key'])){
                    let isSelcetAll=listData[i]['isSelcetAll']
                    chgNumData[i]['num']=isSelcetAll?1:0
                }
            }
        }
    }
    tipsDataFun(){
        const {listData}=this.props
        let getTipsData=[]
        for(let i=0;i<listData.length;i++){
            getTipsData.push([])
        }
        for(let i in listData){
            let data=listData[i]['data']
            for(let j in data){
                getTipsData[i].push(data[j])
              }
        }
        this.setState({
            tipsData:getTipsData
        })
    }
    //初始化
    componentDidMount(){
        const {CriteriaTag}=this.props
        this.resetDataFun()
        CriteriaTag=='news'&&this.tipsDataFun()
    }
    render(){
        const {t,ChgThemeData,listData,keys3Data,isMobile,isPC,CriteriaTag} = this.props 
        const {tipsData,newNum,isTipsModalShow}=this.state
        return (
            <div>
                {
                    listData.map((item,index) => {
                    return <div 
                                key={index}
                                className={[
                                    styles.marketCap,
                                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                ].join(' ')} 
                                style={{
                                    paddingBottom:listData.length-1==index?8:0
                                }}
                            >
                                <div 
                                    className={[
                                        styles.marketCap_header,
                                        APPstyles.flex_between
                                    ].join(' ')}
                                    style={{
                                        marginBottom:!item['isShowAll']&&!(tipsData[index]&&tipsData[index].length)?16:8,
                                        marginTop:isMobile&&index==0?0:16
                                    }}
                                >
                                    {t(item['title'])}
                                    <div 
                                        className={styles.marketCap_img}
                                        onClick={this.dropDownClick.bind(this,index)}
                                        onMouseOver={()=>{
                                            if(!isPC) return
                                            let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                            listData[index]['dropDownImg']=imgurl
                                            this.props.cbCheckboxData(listData)
                                        }} 
                                        onMouseOut={()=>{
                                            if(!isPC) return
                                            listData[index]['dropDownImg']=dropDownImg
                                            this.props.cbCheckboxData(listData)
                                        }}
                                    >
                                        <img 
                                            src={item['dropDownImg']}  
                                            alt='dropdown' 
                                            className={item['isShowAll']?'':APPstyles.rotate_img}
                                        />
                                    </div>
                                </div>
                                <div className={[styles.market_data_con,ChgThemeData=='Light'?APPstyles.color_009E9C:''].join(' ')} style={{display:item['isShowAll']?'none':tipsData[index]&&tipsData[index].length?'':'none'}}>
                                {
                                    tipsData[index]&&tipsData[index].map((item1,index1) => {
                                    return  <span key={index1} style={{display:index1<2?'':'none'}}>
                                                {t(item1['name'])}{index1==tipsData[index].length-1?'':', '}
                                            </span>
                                    })
                                }
                                <span style={{display:tipsData[index]&&tipsData[index].length>2?'':'none'}}>&nbsp;+ {tipsData[index]&&tipsData[index].length-2} {t('more')}</span>
                                </div>
                                <div style={{display:item['isShowAll']?'':'none'}}>
                                    <div 
                                        className={[
                                            styles.marketCap_checkbox,
                                            APPstyles.flex_topcenter,
                                            ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black
                                        ].join(' ')}
                                        style={{
                                            display:CriteriaTag=='screener'?keys3Data.includes(item['key'])?'':'none':'',
                                            marginBottom:8
                                        }}
                                        onClick={this.isSelectAllClick.bind(this,index,item)}
                                    >
                                        <img
                                            src={item['isSelcetAll']?remeberOnImg:remeberOffImg}
                                            className={APPstyles.img24}
                                            alt='checkbox'
                                        />
                                        {t('Select All')}
                                    </div>
                                    {
                                        item['data'].map((list,index0) => {
                                        return  <div  
                                                    key={index0}
                                                    className={[
                                                        styles.marketCap_checkbox,
                                                        APPstyles.flex_topcenter,
                                                        ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black,
                                                        CriteriaTag=='screener'&&newNum>9&&!keys3Data.includes(item['key'])&&!list['checked']?styles.criteria_btn_notallowed:''
                                                    ].join(' ')}
                                                    style={{
                                                        marginBottom:item['data'].length-1==index0?16:8
                                                    }}
                                                    onClick={this.checkedOneClick.bind(this,index,index0,item)}
                                                >
                                                    <img
                                                        src={list['checked']?remeberOnImg:remeberOffImg}
                                                        className={[
                                                            APPstyles.img24,
                                                            CriteriaTag=='screener'&&newNum>9&&!keys3Data.includes(item['key'])&&!list['checked']?styles.criteria_btn_notallowed:''
                                                        ].join(' ')}
                                                        alt='checkbox'
                                                    />
                                                    {t(list['name'])}
                                                </div>
                                        })
                                    }
                                </div>
                                <div 
                                    className={[
                                        styles.checkbox_line,
                                        ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_grey
                                    ].join(' ')} 
                                    style={{display:listData.length-1==index?'none':''}}
                                ></div>
                            </div>
                    })
                } 
                {
                    isTipsModalShow?
                        <TipsModal
                            {...this.props}
                            tag='checkbox'
                            cbCloseModal={()=>{
                                this.setState({
                                    isTipsModalShow: false
                                })
                                this.props.cbCriteriaShow()
                            }}
                        /> 
                    :
                        ''
                }       
            </div>
        )}
}
export default withTranslation()(observer(CriteriaCon))
import React from "react";
import RealNumberFormat from "react-number-format";

// type NumberFormatProps = {
//   value?: number | string | null;
//   className?: string;
//   style?: React.CSSProperties;
//   defaultValue?: number | string;
//   specialDefaultValue?: number | string;
//   prefix?: any;
//   suffix?: any;
//   displayType?: "input" | "text";
//   thousandSeparator?: boolean | string;
//   fixedDecimalScale?: boolean;
//   decimalScale?: number;
//   isZeroDefaultValue?: boolean;
//   isZeroSpecialDefaultValue?: boolean; // named by Happy Chiu
//   isUnitSmaller?: boolean;
// };

const NumberFormat = (props) => {
  const {
    value,
    className,
    style,
    defaultValue,
    specialDefaultValue,
    prefix,
    suffix,
    displayType,
    thousandSeparator,
    fixedDecimalScale,
    decimalScale,
    isZeroDefaultValue,
    isZeroSpecialDefaultValue,
    isUnitSmaller,
  } = props;

  const isNumberic =
    !isNaN(
      parseFloat(typeof value === "string" ? value.replace(/,/g, "") : value)
    ) && isFinite(typeof value === "string" ? value.replace(/,/g, "") : value);

  // val: : number | string | null | undefined
  const roundUp = (val) => {
    if (val === null || val === undefined || props.decimalScale !== undefined) {
      return val;
    } else {
      return Number(val).toFixed(props.decimalScale);
    }
  };

  return (
    <>
      {(isZeroDefaultValue && parseFloat(value) === 0) || !isNumberic ? (
        <span className={className} style={style}>
          {defaultValue}
        </span>
      ) : (isZeroSpecialDefaultValue && (value === 0 || value === "0")) ||
        !isNumberic ? (
        <span className={className} style={style}>
          {specialDefaultValue.slice(-1) === "%" && isUnitSmaller
            ? specialDefaultValue.slice(0, -1)
            : specialDefaultValue}
          {specialDefaultValue.slice(-1) === "%" && isUnitSmaller ? (
            <span style={{ fontSize: 12 }}>%</span>
          ) : null}
        </span>
      ) : (
        <>
          {/* {isUnitSmaller && 
        <span style={{ fontSize: 12 }}>
          {prefix}
        </span>} */}
          <RealNumberFormat
            className={className}
            style={style}
            value={
              typeof value === "string"
                ? roundUp(parseFloat(value.replace(/,/g, "")))
                : roundUp(value)
            }
            defaultValue={defaultValue}
            prefix={prefix}
            suffix={!isUnitSmaller ? suffix : ""}
            displayType={displayType}
            thousandSeparator={thousandSeparator}
            fixedDecimalScale={fixedDecimalScale}
            decimalScale={decimalScale}
          />
          {isUnitSmaller && <span style={{ fontSize: 12 }}>{suffix}</span>}
        </>
      )}
    </>
  );
};

export default NumberFormat;

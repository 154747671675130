import React from 'react';
import {withTranslation} from 'react-i18next';
import { 
  dropDownImg,
  // dropDownOnImg,
  // dropDownOnLightImg,
} from '@/asserts/imgs/common/index';
import ExplainComponents from '@/components/modal/explain/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chgImg: dropDownImg,
      accountName: '',
      visible: false,
    }
  }
  // accountClick(){

  // };
  // handleMouse(isHover) {
  //   const { ChgThemeData,isPC } = this.props;
  //   if (!isPC) return;
  //   let imgurl;
  //   if (isHover) {
  //     imgurl = ChgThemeData === 'Light' ? dropDownOnLightImg : dropDownOnImg;
  //   } else {
  //     imgurl = dropDownImg;
  //   }
  //   this.setState({ 
  //     chgImg: imgurl, 
  //   });
  // }
  explainClick(){ 
    this.setState ({
      visible:true,
    });
  }
  componentDidMount(){
    let aidListData=JSON.parse(sessionStorage.getItem('aidListData'));
    let aidListId=sessionStorage.getItem('aidListId');
    let getData=aidListData[aidListId];
    this.setState ({
      accountName:getData['BranchName']+' '+getData['AID']+' '+getData['Name'],
    });
  }
  render() {
    const {t,isPC,isMobile,ChgThemeData,sign} = this.props;
    const {chgImg,accountName,visible}=this.state;
    return (
      <div 
        className={[
          APPstyles.flex_topcenter,
          styles.dropDownSwitch,
          isMobile&&sign=='Statement'&&styles.dropDownSwitchM,
          isMobile&&sign=='campagin'&&styles.dropDownCampagin,
          !isPC&&sign=='BankBalance'&&styles.switchBank,

        ].join(' ')}
      >
        <div 
          className={[styles.account,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black].join(' ')}
          style={{margin:(sign=='Statement'||sign=='BankBalance')&&!isPC?'0 8px 0 0':'0 8px'}}
          // onClick={()=>this.accountClick()}
          // onMouseOver={() => this.handleMouse(true)} 
          // onMouseOut={() => this.handleMouse(false)}
        >
          <div className={APPstyles.flex_row} style={{alignItems:'center'}}>
            <span className={[APPstyles.fz12,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>{accountName}</span>
            {sign==='BankBalance'&&
              <span className={[ChgThemeData=='Light'?styles.label_light:'',styles.label_span].join(' ')} onClick={()=>this.explainClick()}>{t('INFO')}</span>
            }
          </div>
          <img src={chgImg} className={styles.dropDownImg} />
        </div>
        {
          <ExplainComponents 
            {...this.props} 
            visible={visible}
            onCloseModal={()=>{
              this.setState ({
                visible:false,
              });
            }}
          />
        }
      </div>
    )
  }
}
export default withTranslation()(Index);
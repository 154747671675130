import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import common from '@/asserts/js/common';
import DropDownSwitch from '@/components/dropDownSwitch/index';
import Mobile from './mobile/index';
import List from './list/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class BankBalanceIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      isLoading: false,
      isbtnLoading: false,
    }
  }
  searchClick(){
    const {isbtnLoading,isLoading}=this.state;
    if(isbtnLoading||isLoading) return;
    this.setState({
      isLoading: true,
      isbtnLoading: true,
    });
    this.btnLoadFun();
    this.getTableData();
  }
  getTableData() {
    fetch(common.getHttpZs+'getBankBalance', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: sessionStorage.getItem("token"),
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if(res['status']==0){
        if(res['data'].length>0&&JSON.stringify(res['data']) !== '{}'){
          let data=res['data'];
          this.setState({
            history: data,
            isLoading: false,
          });
        }else{
          this.noDataFun();
        }
        return;
      }
      this.noDataFun();
    })
    .catch((err) => {
      console.log('Request failed', err);
      this.setState({
        history: [],
        isLoading: false,
      });
    })
  }
  noDataFun(){
    this.setState({
      history: [],
      isLoading: false,
    });
  }
  // 点击查询按钮后10秒过后才可以再次查询
  btnLoadFun(){
    setTimeout(() => {
      this.setState({
        isbtnLoading: false,
      });
    }, 10000);
  }
  render() {
    const {t,ChgThemeData,isMobile,isPC,isTablet,isSearchPageShow} =this.props;
    const {history,isLoading,isbtnLoading} = this.state;
    return (
      <div 
        className={[
          styles.BankBalancePage,
          isPC&&isSearchPageShow?'':APPstyles.flex1_auto,
          isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
          isPC&&isSearchPageShow||isTablet?APPstyles.hPer_overflowH:'',
          isPC&&isSearchPageShow?'':isPC?history.length?APPstyles.paddingBottom40:'':'',  
        ].join(' ')}
        style={{padding:isPC?'0 16px':0}}
      >
        <div 
          className={[styles.headerCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}
          style={{padding:isPC?0:16,minHeight:isMobile?64:59}}
        >
          <DropDownSwitch {...this.props} sign='BankBalance' />
          <Button 
            disabled={isbtnLoading} 
            onClick={()=>this.searchClick()}
            className={[
              isbtnLoading?APPstyles.bg_grey:APPstyles.bg_blue,
              isbtnLoading?APPstyles.btn_gray:APPstyles.btn_blue,
            ].join(' ')} 
          >
            {t('Search1')}
          </Button>
        </div>
        {isPC&&
          <List 
            {...this.props} 
            isLoading={isLoading}
            history={history}
          />
        }
        {!isPC&&
          <Mobile 
            {...this.props}
            history={history}
            isLoading={isLoading}
          />
        }
      </div>
    );
  }
}
export default withTranslation()(BankBalanceIndex);
import React from 'react'
import { withTranslation } from 'react-i18next'
import { 
  remeberOnImg,
  remeberOffImg,
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let countL = 0,countR = 0

class DropDown extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
     listData:[],
     buysellData:[
      {
        name:'Buy',
        checked:false,
        key:['B']
      },
      {
        name:'Sell',
        checked:false,
        key:['S']
      }
     ],
     orderData:[
      {
        name:'Order Sent',
        checked:false,
        key:[0,15]
      },
      {
        name:'Order placement successful',
        checked:false,
        key:[30]
      },
      {
        name:'Partial transaction completed',
        checked:false,
        key:[97]
      }
     ],
     dealData:[
      {
        name:'Partial transaction completed',
        checked:false,
        key:[97]
      },
      {
        name:'Transaction completed',
        checked:false,
        key:[98]
      }
     ],
     isSelcetAllL:false,
     isSelcetAllR:false,
     nums:0,
     chkeckData:[],
     chkeckDataL:[],
     chkeckDataR:[]
    }
  }
  //全选/全不选
  isSelectAllClick(tag,isSelcetAll,e){
    const {listData,buysellData,chkeckDataL,chkeckDataR}=this.state
    e.stopPropagation()
    let newCheck='',countN=0
    let getData=this.checkDataFun(tag=='L'?buysellData:listData,isSelcetAll)
    let numL=getData['checkData'].length?getData['checkData'].includes(0)?getData['checkData'].length-1:getData['checkData'].length:0
    let numR=chkeckDataR.length?chkeckDataR.includes(0)?chkeckDataR.length-1:chkeckDataR.length:0
    if(tag=='L'){
      if(getData['isSelcetAll']){
        countN=getData['checkData'].length+numR
        newCheck=getData['checkData'].concat(chkeckDataR)
        this.sendCheckDataFun(getData['checkData'],chkeckDataR)
      }else{
        countN=numR
        newCheck=chkeckDataR
        this.sendCheckDataFun([],chkeckDataR)
      }
      this.setState({
        buysellData: getData['data'],
        chkeckDataL:getData['isSelcetAll']?getData['checkData']:[],
        isSelcetAllL:getData['isSelcetAll']
      })
    }else{
      if(getData['isSelcetAll']){
        countN=chkeckDataL.length+numL
        newCheck=getData['checkData'].concat(chkeckDataL)
        this.sendCheckDataFun(chkeckDataL,getData['checkData'])
      }else{
        countN=chkeckDataL.length
        newCheck=chkeckDataL
        this.sendCheckDataFun(chkeckDataL,[])
      }
      this.setState({
        listData: getData['data'],
        chkeckDataR:getData['isSelcetAll']?getData['checkData']:[],
        isSelcetAllR:getData['isSelcetAll']
      })
    }
    this.setState({
      chkeckData:newCheck,
      nums:countN
    })
  }
  sendCheckDataFun(chkeckDataL,chkeckDataR){
    let obj={
      chkeckDataL:chkeckDataL,
      chkeckDataR:chkeckDataR
    }
    this.props.cbCheckedDataFun(obj)
  }
  // 筛选
  checkDataFun(listData,isSelcetAll){
    let checkData=[]
    for(let i in listData){
      listData[i]['checked']=isSelcetAll?false:true
      let keyData=listData[i]['key']
      for(let j in keyData){
        checkData.push(keyData[j])
      }
    }
    let obj={
      data:listData,
      checkData:checkData,
      isSelcetAll:!isSelcetAll
    }
    return obj
  }
  //单选
  checkedOneClick(data,index,tag,resData,e){
    const {chkeckDataL,chkeckDataR}=this.state
    let newCheckData=[]
    countL = 0
    countR = 0
    e.stopPropagation()
    let resCheckData=tag=='L'?chkeckDataL:chkeckDataR
    // 单选
    for(let i in resData){
      if(index==i){
        resData[i]['checked']=resData[i]['checked']?false:true 
        //传值
        newCheckData=this.dealOneFun(data,resData,i,resCheckData)
      }
      if(tag=='L'){
        //全选/全不选
        if(!resData[i]['checked']){
          this.setState({
            isSelcetAllL: false
          })
        }else{
          countL++
        }
        //判断是否都是选中状态/如果是则自动选中全选按钮
        if(countL == resData.length){
          this.setState({
            isSelcetAllL: true
          })
        }
      }else{
        //全选/全不选
        if(!resData[i]['checked']){
          this.setState({
            isSelcetAllR: false
          })
        }else{
          countR++
        }
        //判断是否都是选中状态/如果是则自动选中全选按钮
        if(countR == resData.length){
          this.setState({
            isSelcetAllR: true
          })
        }
      }
    }
    if(tag=='L'){
      let getNum=chkeckDataR.length?chkeckDataR.includes(0)?chkeckDataR.length-1:chkeckDataR.length:0
      this.setState({
        buysellData: resData,
        chkeckDataL:newCheckData,
        nums:getNum+newCheckData.length,
        chkeckData:newCheckData.concat(chkeckDataR)
      })
      this.sendCheckDataFun(newCheckData,chkeckDataR)
    }else{
      let getNum=newCheckData.length?newCheckData.includes(0)?newCheckData.length-1:newCheckData.length:0
      this.setState({
        listData: resData,
        chkeckDataR:newCheckData,
        nums:getNum+chkeckDataL.length,
        chkeckData:newCheckData.concat(chkeckDataL)
      })
      this.sendCheckDataFun(chkeckDataL,newCheckData)
    }
  }
  dealOneFun(data,newData,i,checkData){
    if(newData[i]['checked']){//add
      for(let j in data['key']){
        checkData.push(data['key'][j])
      }
    }else{//del
      for(let j in checkData){
        for(let k in data['key']){
          if(data['key'][k]==checkData[j]){
            checkData.splice(j,1)
          }
        }
      }
    }
    return checkData
  }
  resetDataFun(){
    const {chgTab}=this.props
    const {buysellData,orderData,dealData}=this.state
    countL = 0
    countR = 0
    this.setState({
      nums:0,
      listData:chgTab=='N'?this.resetCheckedData(orderData):this.resetCheckedData(dealData),
      buysellData:this.resetCheckedData(buysellData),
      orderData:this.resetCheckedData(orderData),
      dealData:this.resetCheckedData(dealData),
      isSelcetAllL:false,
      isSelcetAllR:false,
      chkeckData:[],
      chkeckDataL:[],
      chkeckDataR:[]
    })
  }
  resetCheckedData(data){
    for(let i in data){
      data[i]['checked']=false
    }
    return data
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {isShowPageData,IsSubmitOrder,chgTab}=this.props
    const {orderData,dealData}=this.state
    let navId=sessionStorage.getItem('navId')
    // 提交订单刷新数据
    if(IsSubmitOrder!==newProps['IsSubmitOrder']){
      this.resetDataFun()
    }
    // trade--弹框
    if(navId=='trade'||navId=='quote'){
      if(isShowPageData&&(isShowPageData[0]!==newProps['isShowPageData'][0])){
        if(isShowPageData[0]){
          this.resetDataFun()
        }
      }
    }
    if(chgTab!==newProps['chgTab']){
      if(chgTab=='N'){
        this.setState({
          listData: orderData
        })
      }else{
        this.setState({
          listData: dealData
        })
      }
      this.resetDataFun()
    }
  }
  //初始化
  componentDidMount() {
    const {orderData}=this.state
    this.setState({
      listData: orderData
    })
  }
  render(){
    const {t,ChgThemeData,ChgLangData}=this.props
    const {isSelcetAllL,isSelcetAllR,nums,listData,buysellData}=this.state
    return (
      <div 
        className={[
          styles.dropDown_content,
          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
          ChgThemeData=='Light'?APPstyles.dropDown_con_light:APPstyles.bg_star_off
        ].join(' ')}
      >
        <span 
          className={[
            styles.dropDown_content_title,
            APPstyles.flex_row,
            APPstyles.flex_topcenter,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          {t(ChgLangData=='zh-CN'? '選擇 '+nums+' 個項目' : nums+' item(s) selected')}
        </span>
        <div className={APPstyles.flex_row}>
          <div>
            {buysellData.map((item,index) => {
              return  <div
                        key={index}
                        className={[
                          APPstyles.flex_row,
                          APPstyles.flex_topcenter,
                          styles.dropDown_content_child,
                          ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black
                        ].join(' ')}
                        onClick={this.checkedOneClick.bind(this,item,index,'L',buysellData)} 
                      >
                        <img
                          src={item['checked']?remeberOnImg:remeberOffImg}
                          className={APPstyles.img24}
                          alt='checkbox'
                      />
                        {t(item['name'])}
                      </div>
              })
            } 
            <div
              className={[
                APPstyles.flex_row,
                APPstyles.flex_topcenter,
                styles.dropDown_content_child,
                ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black
              ].join(' ')}
              onClick={this.isSelectAllClick.bind(this,'L',isSelcetAllL)} 
            >
              <img
                src={isSelcetAllL?remeberOnImg:remeberOffImg}
                className={APPstyles.img24}
                alt='checkbox'
              />
              {t('Select All')}
            </div>
          </div>
          <div>
            {listData.length&&listData.map((item,index) => {
              return  <div
                        key={index}
                        className={[
                          APPstyles.flex_row,
                          APPstyles.flex_topcenter,
                          styles.dropDown_content_child,
                          ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black
                        ].join(' ')}
                        onClick={this.checkedOneClick.bind(this,item,index,'R',listData)} 
                      >
                        <img
                          src={item['checked']?remeberOnImg:remeberOffImg}
                          className={APPstyles.img24}
                          alt='checkbox'
                      />
                        {t(item['name'])}
                      </div>
              })
            } 
            <div
              className={[
                APPstyles.flex_row,
                APPstyles.flex_topcenter,
                styles.dropDown_content_child,
                ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black
              ].join(' ')}
              onClick={this.isSelectAllClick.bind(this,'R',isSelcetAllR)} 
            >
              <img
                src={isSelcetAllR?remeberOnImg:remeberOffImg}
                className={APPstyles.img24}
                alt='checkbox'
              />
              {t('Select All')}
            </div>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(DropDown)
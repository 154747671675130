import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import APPstyles from '@/App.less'
import styles from './index.less'

class List extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            listData:[],
            listIndex:0,
            isLoading:false
        }
    }
    changeDataClick(data,index){
        this.setState({
            listIndex:index
        })
        this.props.chgSectorData(data)
    }
    //获取数据
    getData(data,key){
        if(data.length){
            for(let i in data){
                let time=data[i][key]
                data[i]['last']=data[i]['TRDPRC_1']
                data[i]['date']=time
                data[i]['bgColor']=common.chgBgColorData(time)
            }
            this.setState({
                listData:data,
                isLoading:false
            })
            return
        }
        this.setState({
            listData:[],
            isLoading:false
        })
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {gridData,timeKey}=this.props
        if(timeKey!==newProps['timeKey']){
            if(gridData.length<=0) return
            this.setState({
                isLoading:true
            })
            this.getData(gridData,timeKey)  
        }
    }
    //初始化
    componentDidMount(){
        const {gridData,timeKey,agg_code}=this.props
        if(gridData.length<=0) return
        
        this.setState({
            isLoading:true
        })
        this.getData(gridData,timeKey) 
        let keyData = gridData.find((x) => x['agg_code'] == agg_code)
        this.setState({
            listIndex:keyData['index']
        })
    }
    render(){
        const {t,ChgThemeData,timeTitle,ChgLangData,isMobile} = this.props
        const {listData,listIndex,isLoading}=this.state
        return (
            <div className={[APPstyles.flex_column,styles.left_list].join(' ')}>
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                <div 
                    className={[
                        APPstyles.flex_row,
                        styles.header,
                        styles.common,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
                    ].join(' ')} 
                    style={{display:listData.length?'':'none'}}
                >
                    <span>{t('Sector Name')}</span>
                    <span>{t('Last')}</span>
                    <span>{t(timeTitle)}</span>
                </div>
                <div className={[styles.body].join(' ')} style={{paddingBottom:isMobile?0:40}}>
                    {
                        listData.length?
                            listData.map((item,index) => {
                                return  <div 
                                            className={[
                                                APPstyles.flex_row,
                                                styles.body_one,styles.common,
                                                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                                ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white,
                                                listIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark:'',
                                                ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover
                                            ].join(' ')}
                                            key={index}
                                            onClick={this.changeDataClick.bind(this,item,index)}
                                        >
                                            <span className={APPstyles.ellipsis}>{ChgLangData=='zh-CN'?item['name_cht']:common.charAtToUpperCase(item['name_en'])}</span>
                                            <span
                                                className={Number(item['date'])>0?APPstyles.color_red:Number(item['date'])<0?APPstyles.color_green:Number(item['date'])==0?APPstyles.color_grey:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white}
                                            >
                                                {common.transThousands(common.keepNum(item['last'],2))}
                                            </span>
                                            <span style={{backgroundColor:item['bgColor']}}>
                                                {
                                                    common.changeRate(common.addplusNum(common.transThousands(common.keepNum(item['date'],2))))=='-'?
                                                        '-%'
                                                    :
                                                        common.changeRate(common.addplusNum(common.transThousands(common.keepNum(item['date'],2))))
                                                }
                                            </span>
                                        </div>
                            })
                        :
                            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
                    }
                </div>
            </div>
        )}
}
export default withTranslation()(observer(List))
import React from 'react'
import { withTranslation } from 'react-i18next'
import { 
  addImg,
  editOffImg
} from '../../../asserts/imgs/quote/index'
import APPstyles from '../../../App.less'
import styles from '../List/index.less'

class Footer extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      componentsIndex:0,
      indexData01:[0,1],
      isWitchModuleShow:true
    }
  }
  //Add Stock/Edit Watchlist--点击按钮
  btnClick=(tag)=>{
    this.setState({
      isWitchModuleShow:false,
      componentsIndex:tag==='add'?1:2
    })
    this.props.callbackLeftPageShow(tag)
    window.sessionStorage.removeItem('ChgAddData')
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {currentIndex,bodyW,mobilebodyW,isWitchModuleShow,isbackStatus}=this.props
    if(currentIndex!==newProps['currentIndex']){
      this.setState({
        componentsIndex:0,
        isWitchModuleShow:true
      })
    }
    if(isWitchModuleShow!==newProps['isWitchModuleShow']){
      this.setState({
        isWitchModuleShow:isWitchModuleShow
      })
    }
    if(isbackStatus!==newProps['isbackStatus']){
      if(bodyW>mobilebodyW) return
      this.setState({
        componentsIndex:0,
        isWitchModuleShow:true
      })
    }
  }
  //初始化
  componentDidMount(){
    
  }
  render(){
    const {t,currentIndex,ChgThemeData,isMobile} = this.props
    const {componentsIndex,indexData01,isWitchModuleShow}=this.state
    return (
      <div 
          className={[
            APPstyles.flex_row,
            styles.footer_con,
            ChgThemeData=='Light'?styles.footer_con_light:'',
            !isMobile?'':styles.footer_env_mobile,
            !isMobile?'':APPstyles.sticky_bottom_mobile
          ].join(' ')} 
          style={{
            display:indexData01.includes(currentIndex)||(isMobile&&!isWitchModuleShow)?'none':componentsIndex===0?'':'none',
            bottom:isMobile?0:56,
            fontSize:14
          }}
        >
          <div className={APPstyles.flex_row} onClick={this.btnClick.bind(this,'add')}>
            <img src={addImg} className={[styles.all_img,APPstyles.img16].join(' ')} alt='add' />
            <span>{t('Add Stock')}</span>
          </div>
          <div className={APPstyles.flex_row} onClick={this.btnClick.bind(this,'stocksEdit')}>
            <img src={editOffImg} className={[styles.all_img,APPstyles.img16].join(' ')} alt='edit' />
            <span>{t('Edit Watchlist')}</span>
          </div>
        </div>
  )}
}
export default withTranslation()(Footer)
import React from 'react'
import { withTranslation } from 'react-i18next'
import CalendarCon from '../../Calendar/index'
import InputCon from '../../InputCon/index'
import PaginationCon from '../../Pagination/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

let leftConW=878

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownL:0,
      dropDownR:'unset',
      dropDownT:38,
      dropDownW:'100%',
      closeInput:false,
      minW400:400,
      closeCalendar:false
    }
  }
  //更改下拉框
  changeDropDownData(){
    const {bodyW,isPC}=this.props
    const {minW400}=this.state
    let left=isPC?0:'unset'
    let right=isPC?'unset':0
    let top=isPC?38:50
    let width=isPC?'100%':bodyW>minW400?'77%':'100%'
    // 分页
    let contentId=document.getElementById('contentId').clientWidth
    let pageW=document.getElementById('pageId').clientWidth
    leftConW=contentId-pageW>878?878:'auto'
    
    this.setState({
      dropDownL: left,
      dropDownR: right,
      dropDownT:top,
      dropDownW:width
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,isSearchPageShow}=this.props
    if(bodyW!==newProps['bodyW']||isSearchPageShow!==newProps['isSearchPageShow']){
      this.changeDropDownData()
    }
  }
  //初始化
  componentDidMount() {
    this.changeDropDownData()
  }
  render() {
    const { isPC,ChgThemeData } = this.props
    const {dropDownL,dropDownR,dropDownT,dropDownW,closeInput,closeCalendar} = this.state
    return (
      <div
        id='contentId'
        className={[
          APPstyles.flex_row,
          APPstyles.flex_between,
          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
        ].join(' ')}
        style={{
          margin:isPC?'0 16px':0
        }}
      >
        <div
          className={[
            APPstyles.flex_row,
            styles.header_con
          ].join(' ')}
          style={{
            padding:isPC?'0 8px':0,
            width:isPC?leftConW:'auto',
            maxWidth:isPC?leftConW:'unset',
            margin:isPC?0:'0 16px'
          }}
        >
          <CalendarCon
            {...this.props}
            closeCalendar={closeCalendar}
            callbackDateData={(data)=>{
              this.props.callbackDateData(data)
            }}
            cbCloseInput={()=>{
              //关闭下拉框
              this.setState(state => ({
                closeInput:!state.closeInput
              }))
            }}
          />
          <div 
            className={isPC?'':APPstyles.flex1_hidden}
            style={{
              width:'100%',
              position:isPC?'relative':'unset'
            }}
          >
            <InputCon
              {...this.props}
              closeInput={closeInput}
              dropDownL={dropDownL}
              dropDownR={dropDownR}
              dropDownT={dropDownT}
              dropDownW={dropDownW}
              callbackInputData={(data)=>{
                this.props.callbackInputData(data)
              }}
              cbCloseAll={()=>{
                //关闭所有下拉框
                this.setState(state => ({
                  closeCalendar:!state.closeCalendar
                }))
              }}
            />
          </div>
        </div>
        {/* 分页 */}
        <div id='pageId' style={{display:isPC?'':'none'}}>
          <PaginationCon
            {...this.props}
            isShow={false}
            callbackPagekData={(data)=>{
              this.props.callbackPagekData(data)
            }}
          />
        </div>
      </div>
    )
  }
}
export default withTranslation()(Header)

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollShow:false,
    }
    this.myRef = React.createRef()
  }
  isScrollShowFun(){
    let current=this.myRef.current;
    if(current){
      this.setState({
        isScrollShow:current.scrollHeight>current.clientHeight?true:false,
      });
    }
  }
  componentDidUpdate(newProps){
    const {isLoading} = this.props
    if(isLoading!==newProps['isLoading']){
      setTimeout(()=>{
        this.isScrollShowFun();
      }, 100);
    }
  }
  render() {
    const {t,history,isLoading,ChgThemeData,isMobile} = this.props
    const {isScrollShow}=this.state
    return (
      <div className={[APPstyles.flex_column,APPstyles.flex1_hidden].join(' ')}>
        <div
          className={[
            styles.header,
            styles.common,
            APPstyles.flex_row,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile&&(ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white),
          ].join(' ')}
          style={{padding:isScrollShow?'3px 25px 3px 16px':'3px 16px'}}
        >
          <span className={[styles.child,styles.childCurrency].join(' ')}>{t('Currency2')}</span>
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('Buying Power')}</span>
            <span>{t('Bank Balance')}</span>
          </div>
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('Money In Transit')}</span>
            <span>{t("Today's Sales")}</span>
          </div>
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('Bank Stock Deposits')}</span>
            <span>{t("Today's Stock Deposit")}</span>
          </div>
        </div>
        <div
          ref={this.myRef}
          className={[APPstyles.flex1_auto,APPstyles.paddingBottom20].join(' ')} 
          style={{position:isLoading?'static':'relative'}}
        >
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length>0?
            history.map((item,index) => {
              return (
                <div
                  key={index}
                  className={[
                    styles.body,
                    styles.common,
                    APPstyles.flex_row,
                    ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                  ].join(' ')}
                >
                  <span className={[styles.child,styles.childCurrency].join(' ')} style={{fontSize:12}}>{t(item['currency'])}</span>
                  <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                    <span>{t(common.transThousands(common.keepNum(item['buying_power'],2)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['bank_balance'],2)))}</span>
                  </div>
                  <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                    <span>{t(common.transThousands(common.keepNum(item['money_in_transit'],2)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['today_sale'],2)))}</span>
                  </div>
                  <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                    <span>{t(common.transThousands(common.keepNum(item['bank_stock_deposit'],2)))}</span>
                    <span>{t(common.transThousands(common.keepNum(item['today_stock_deposit'],2)))}</span>
                  </div>
                </div>
              );
            }):
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const MEMA = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(3);
  const [color, setColor] = useState("0080FF");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [enable2, setEnable2] = useState(true);
  const [period2, setPeriod2] = useState(5);
  const [color2, setColor2] = useState("991100");
  const [thickness2, setThickness2] = useState(2);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(true);

  const [enable3, setEnable3] = useState(true);
  const [period3, setPeriod3] = useState(25);
  const [color3, setColor3] = useState("9933FF");
  const [thickness3, setThickness3] = useState(2);
  const [style3, setStyle3] = useState("s");
  const [showTracker3, setShowTracker3] = useState(true);

  const [enable4, setEnable4] = useState(true);
  const [period4, setPeriod4] = useState(75);
  const [color4, setColor4] = useState("FFB0B0");
  const [thickness4, setThickness4] = useState(2);
  const [style4, setStyle4] = useState("s");
  const [showTracker4, setShowTracker4] = useState(true);

  const [enable5, setEnable5] = useState(true);
  const [period5, setPeriod5] = useState(200);
  const [color5, setColor5] = useState("449999");
  const [thickness5, setThickness5] = useState(2);
  const [style5, setStyle5] = useState("s");
  const [showTracker5, setShowTracker5] = useState(true);
  const [value, setValue] = useState(
    period + "," + period2 + "," + period3 + "," + period4 + "," + period5
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.MEMA_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      const MEMA = localChartSetting.option;

      const m1 = MEMA[0]
      const m2 = MEMA[1]
      const m3 = MEMA[2]
      const m4 = MEMA[3]
      const m5 = MEMA[4]

      let option1 = "";
      let option2 = "";
      let option3 = "";
      let option4 = "";
      let option5 = "";
  
      option1 = {
        period: m1.period,
        color: m1.color,
        thickness: m1.thickness,
        style: m1.style,
        showTracker: m1.showTracker,
        enabled: m1.enabled,
      };
  
      option2 = {
        period: m2.period,
        color: m2.color,
        thickness: m2.thickness,
        style: m2.style,
        showTracker: m2.showTracker,
        enabled: m2.enabled,
      };
  
      option3 = {
        period: m3.period,
        color: m3.color,
        thickness: m3.thickness,
        style: m3.style,
        showTracker: m3.showTracker,
        enabled: m3.enabled,
      };
  
      option4 = {
        period: m4.period,
        color: m4.color,
        thickness: m4.thickness,
        style: m4.style,
        showTracker: m4.showTracker,
        enabled: m4.enabled,
      };
  
      option5 = {
        period: m5.period,
        color: m5.color,
        thickness: m5.thickness,
        style: m5.style,
        showTracker: m5.showTracker,
        enabled: m5.enabled,
      };
  
      let options = [option1, option2, option3, option4, option5];
  
      let v = "";
      if (m1.enabled) {
        v = v + m1.period + ",";
      }
      if (m2.enabled) {
        v = v + m2.period + ",";
      }
      if (m3.enabled) {
        v = v + m3.period + ",";
      }
      if (m4.enabled) {
        v = v + m4.period + ",";
      }
      if (m5.enabled) {
        v = v + m5.period;
      }
  
      setValue(v);
  
      props.onOverlayValueChange({
        ta: "MEMA",
        name: `${t("chart5.TA.MEMA")} (MEMA)`,
        option: options,
        value: localChartSetting.value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const MEMA = props.localChartSetting.MEMA_Option.option;

    const m1 = MEMA[0]
    const m2 = MEMA[1]
    const m3 = MEMA[2]
    const m4 = MEMA[3]
    const m5 = MEMA[4]

    setEnable(m1.enabled);
    setPeriod(parseInt(m1.period) || period);
    setColor(m1.color);
    setThickness(parseInt(m1.thickness) || thickness);
    setStyle(m1.style);
    setShowTracker(m1.showTracker);

    setEnable2(m2.enabled);
    setPeriod2(parseInt(m2.period) || period2);
    setColor2(m2.color);
    setThickness2(parseInt(m2.thickness) || thickness2);
    setStyle2(m2.style);
    setShowTracker2(m2.showTracker);

    setEnable3(m3.enabled);
    setPeriod3(parseInt(m3.period) || period3);
    setColor3(m3.color);
    setThickness3(parseInt(m3.thickness) || thickness3);
    setStyle3(m3.style);
    setShowTracker3(m3.showTracker);

    setEnable4(m4.enabled);
    setPeriod4(parseInt(m4.period) || period4);
    setColor4(m4.color);
    setThickness4(parseInt(m4.thickness) || thickness4);
    setStyle4(m4.style);
    setShowTracker4(m4.showTracker);

    setEnable5(m5.enabled);
    setPeriod5(parseInt(m5.period) || period5);
    setColor5(m5.color);
    setThickness5(parseInt(m5.thickness) || thickness5);
    setStyle5(m5.style);
    setShowTracker5(m5.showTracker);

  }, [props.localChartSetting]);


  useEffect(() => {
    let option1 = "";
    let option2 = "";
    let option3 = "";
    let option4 = "";
    let option5 = "";

    option1 = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    option2 = {
      period: period2,
      color: color2,
      thickness: thickness2,
      style: style2,
      showTracker: showTracker2,
      enabled: enable2,
    };

    option3 = {
      period: period3,
      color: color3,
      thickness: thickness3,
      style: style3,
      showTracker: showTracker3,
      enabled: enable3,
    };

    option4 = {
      period: period4,
      color: color4,
      thickness: thickness4,
      style: style4,
      showTracker: showTracker4,
      enabled: enable4,
    };

    option5 = {
      period: period5,
      color: color5,
      thickness: thickness5,
      style: style5,
      showTracker: showTracker5,
      enabled: enable5,
    };

    let options = [option1, option2, option3, option4, option5];

    let v = "";
    if (enable) {
      v = v + period + ",";
    }
    if (enable2) {
      v = v + period2 + ",";
    }
    if (enable3) {
      v = v + period3 + ",";
    }
    if (enable4) {
      v = v + period4 + ",";
    }
    if (enable5) {
      v = v + period5;
    }

    setValue(v);

    props.onOverlayValueChange({
      ta: "MEMA",
      name: `${t("chart5.TA.MEMA")} (MEMA)`,
      option: options,
      value: value,
    });
  }, [
    period,
    color,
    thickness,
    style,
    showTracker,
    enable,
    period2,
    color2,
    thickness2,
    style2,
    showTracker2,
    enable2,
    period3,
    color3,
    thickness3,
    style3,
    showTracker3,
    enable3,
    period4,
    color4,
    thickness4,
    style4,
    showTracker4,
    enable4,
    period5,
    color5,
    thickness5,
    style5,
    showTracker5,
    enable5,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const MEMA = props.localChartSetting.MEMA_Option.option;

    const m1 = MEMA[0]
    const m2 = MEMA[1]
    const m3 = MEMA[2]
    const m4 = MEMA[3]
    const m5 = MEMA[4]

    setEnable(m1.enabled);
    setPeriod(parseInt(m1.period) || period);
    setColor(m1.color);
    setThickness(parseInt(m1.thickness) || thickness);
    setStyle(m1.style);
    setShowTracker(m1.showTracker);

    setEnable2(m2.enabled);
    setPeriod2(parseInt(m2.period) || period2);
    setColor2(m2.color);
    setThickness2(parseInt(m2.thickness) || thickness2);
    setStyle2(m2.style);
    setShowTracker2(m2.showTracker);

    setEnable3(m3.enabled);
    setPeriod3(parseInt(m3.period) || period3);
    setColor3(m3.color);
    setThickness3(parseInt(m3.thickness) || thickness3);
    setStyle3(m3.style);
    setShowTracker3(m3.showTracker);

    setEnable4(m4.enabled);
    setPeriod4(parseInt(m4.period) || period4);
    setColor4(m4.color);
    setThickness4(parseInt(m4.thickness) || thickness4);
    setStyle4(m4.style);
    setShowTracker4(m4.showTracker);

    setEnable5(m5.enabled);
    setPeriod5(parseInt(m5.period) || period5);
    setColor5(m5.color);
    setThickness5(parseInt(m5.thickness) || thickness5);
    setStyle5(m5.style);
    setShowTracker5(m5.showTracker);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(3);
    setColor("0080FF");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setEnable2(true);
    setPeriod2(5);
    setColor2("991100");
    setThickness2(2);
    setStyle2("s");
    setShowTracker2(true);

    setEnable3(true);
    setPeriod3(25);
    setColor3("9933FF");
    setThickness3(2);
    setStyle3("s");
    setShowTracker3(true);

    setEnable4(true);
    setPeriod4(75);
    setColor4("FFB0B0");
    setThickness4(2);
    setStyle4("s");
    setShowTracker4(true);

    setEnable5(true);
    setPeriod5(200);
    setColor5("449999");
    setThickness5(2);
    setStyle5("s");
    setShowTracker5(true);
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "p1":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "p2":
        if (enable2) setEnable2(false);
        else setEnable2(true);
        break;
      case "p3":
        if (enable3) setEnable3(false);
        else setEnable3(true);
        break;
      case "p4":
        if (enable4) setEnable4(false);
        else setEnable4(true);
        break;
      case "p5":
        if (enable5) setEnable5(false);
        else setEnable5(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "p1":
        setStyle(e.target.value);
        break;
      case "p2":
        setStyle2(e.target.value);
        break;
      case "p3":
        setStyle3(e.target.value);
        break;
      case "p4":
        setStyle4(e.target.value);
        break;
      case "p5":
        setStyle5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "p1":
        setPeriod(e.target.value);
        break;
      case "p2":
        setPeriod2(e.target.value);
        break;
      case "p3":
        setPeriod3(e.target.value);
        break;
      case "p4":
        setPeriod4(e.target.value);
        break;
      case "p5":
        setPeriod5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "p1":
        setThickness(e.target.value);
        break;
      case "p2":
        setThickness2(e.target.value);
        break;
      case "p3":
        setThickness3(e.target.value);
        break;
      case "p4":
        setThickness4(e.target.value);
        break;
      case "p5":
        setThickness5(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "p1":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "p2":
        if (showTracker2) setShowTracker2(false);
        else setShowTracker2(true);
        break;
      case "p3":
        if (showTracker3) setShowTracker3(false);
        else setShowTracker3(true);
        break;
      case "p4":
        if (showTracker4) setShowTracker4(false);
        else setShowTracker4(true);
        break;
      case "p5":
        if (showTracker5) setShowTracker5(false);
        else setShowTracker5(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "MEMA-C1":
        setColor(props.selectedColor);
        break;
      case "MEMA-C2":
        setColor2(props.selectedColor);
        break;
      case "MEMA-C3":
        setColor3(props.selectedColor);
        break;
      case "MEMA-C4":
        setColor4(props.selectedColor);
        break;
      case "MEMA-C5":
        setColor5(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("MEMA")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.MEMA")} (MEMA) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("MEMA")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
          id="msi-ta-MEMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p1")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 1</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() =>
                onColorClick(color, "MEMA-C1", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-KC-mid-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable2 ? {} : { opacity: "0.5" }}
          id="msi-ta-MEMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable2 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p2")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 2</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "MEMA-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-KC-mid-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable3 ? {} : { opacity: "0.5" }}
          id="msi-ta-MEMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable3 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p3")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 3</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period3}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color3}
              style={{ backgroundColor: "#" + color3 }}
              onClick={() =>
                onColorClick(color3, "MEMA-C3", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness3}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p3")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p3")}>
              <select id="msi-ta-KC-mid-style" value={style3}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker3 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable4 ? {} : { opacity: "0.5" }}
          id="msi-ta-MEMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable4 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p4")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 4</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period4}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p4")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color4}
              style={{ backgroundColor: "#" + color4 }}
              onClick={() =>
                onColorClick(color4, "MEMA-C4", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness4}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p4")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p4")}>
              <select id="msi-ta-KC-mid-style" value={style4}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker4 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p4")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable5 ? {} : { opacity: "0.5" }}
          id="msi-ta-MEMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable5 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p5")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 5</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period5}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p5")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color5}
              style={{ backgroundColor: "#" + color5 }}
              onClick={() =>
                onColorClick(color5, "MEMA-C5", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness5}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p5")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p5")}>
              <select id="msi-ta-KC-mid-style" value={style5}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker5 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p5")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//App.js
import React from 'react'
import i18n from './lang'
import { Route,Switch,Redirect} from 'react-router-dom'
import Routers from './routes/router'
import NotFound from './pages/NotFound'
import * as streamingjs from 'fubonstreamingjs';
import QuoteBox from './components/Streaming/QuoteBox'
import { observer } from "mobx-react";
import DataStore from "./components/Streaming/DataStore"
import AlertsStore from "./mobx/AlertsStore"

const store=new DataStore()
const alertsStore=new AlertsStore()

class App extends React.Component {
  subscribeRicListIsUpdated = true
  constructor(props) {
    super(props)
    window.streamingUpdate = element => this.streamingData(element);
    window.checkStreamingUpdate = element => this.checkStreamingUpdate(element);
    window.subscribeRicListIsUpdated = element => { return this.subscribeRicListIsUpdated }
    this.timer = setInterval(this.checkInitStreaming, 1000)
 
    this.state = {
      uid:'',
      bodyH:Number(document.body.clientHeight),
      bodyW: Number(document.body.clientWidth),
      minbodyW:1280,
      mobilebodyW:768
    }
  }
  //初始化
  onloadDataFun(){
    // 语言
    let lang=localStorage.getItem('lang')
    i18n.changeLanguage(lang?lang:'zh-CN')
    localStorage.setItem('lang',lang?lang:'zh-CN')
    // 获取uid
    let uid=sessionStorage.getItem('uid')
    this.setState({
      uid:uid
    })
  }
  // 获取路由跳转
  callbackPathnameApp(data){
    this.props.history.push(data)
  }
  // 获取uid--login页面
  callbackUid(data){
    this.setState({
      uid:data
    })
  }
  //登录跳转到quote页面--login页面
  callbackpathname(data){
    this.props.history.push(data)
  }
  //监听窗口大小改变
  resizeBodyFun(){ 
    this.setState({
      bodyW:Number(document.body.clientWidth),
      bodyH:Number(document.body.clientHeight) 
    })
  }
  //更改本地存储数据的长度
  chgLocalStorageFun(){
    let wlData=localStorage.getItem('watchlistDataAll')
    let slData=localStorage.getItem('symbolDataAll')
    let tabData=localStorage.getItem('tabDataAll')
    // watchlistDataAll
    if(wlData){
      wlData=JSON.parse(wlData)
      this.dealwatchlistDataFun('watchlistDataAll',wlData)
    }
    // symbolDataAll
    if(slData){
      slData=JSON.parse(slData)
      this.dealwatchlistDataFun('symbolDataAll',slData)
    }
    // tabDataAll
    if(tabData){
      tabData=JSON.parse(tabData)
      this.dealLocalStorageTabFun('tabDataAll',tabData)
    }
  }
  // watchlistData
  dealwatchlistDataFun(name,data){
    let newData=[[],[],[],[],[],[],[],[]]
    if(data.length==6){
      for(let i in data){
        newData[Number(i)+2]=data[i]
      }
      localStorage.setItem(name,JSON.stringify(newData))
    }
  }
  // tabData
  dealLocalStorageTabFun(name,data){
    if(data.length==6){
      let obj2={
        name:'Screener',
        disabled:'',
        index:2
      }
      let obj3={
        name:'Sector1',
        disabled:'',
        index:3
      }
      data.splice(2, 0, obj2)
      data.splice(3, 0, obj3)
      localStorage.setItem(name,JSON.stringify(data))
      return
    }
    if(data[3]['name']!=='Sector1'){
      data[3]['name']='Sector1'
      localStorage.setItem(name,JSON.stringify(data))
    }
  }
  handleResize(){
    this.resizeBodyFun()
  }
  handleBack(){
    window.history.pushState(null, null, document.URL)
  }
  //监听浏览器回退事件
  popstateFun(){
    if (window.history && window.history.pushState) {
      window.addEventListener('popstate', this.handleBack, false)
    }
  }
  componentDidMount(){
    window.addEventListener('resize', this.handleResize.bind(this))
    this.onloadDataFun()
    this.popstateFun()
    this.chgLocalStorageFun()

    //init Streaming
    if (!this.TestgroundPageObj) {
      this.TestgroundPageObj = streamingjs.AbstractPageObj.extend("demo1", {

        showImpl: function (statedata) {
          // Register for streaming :)
          streamingjs.desktop.register(this);

          // For chaining...
          return this;
        },

        hideImpl: function () {
          // Unregister for streaming :)
          streamingjs.desktop.unregister(this);

          // For chaining...
          return this;
        },

        // If registered for streaming, this standard method helps to distribute the
        // streaming data in this $pageobj
        stream: function (data) {
          if (!this._holdstreaming) {
            streamingjs.Streaming.stream(this, data); // default processing to UI rendering of the data
          }
        }
      });
  
    }
  }
  componentWillUnmount() {       
    window.removeEventListener('resize', this.handleResize.bind(this))
    window.removeEventListener('popstate', this.handleBack, false)
  }
  //以下都是 Streaming
  checkInitStreaming = () => {
    if (this.TestgroundPageObj && (streamingjs.$body !== null && streamingjs.$body !== undefined)) {
      this.initStreaming();
    }
  }
  checkInitStreaming = () => {
    if (this.TestgroundPageObj && (streamingjs.$body !== null && streamingjs.$body !== undefined)) {
      this.initStreaming();
    }
  }
  componentDidUpdate(){
    if (store.subscribeRicListIsUpdated &&this.TestgroundPageObj && (streamingjs.$body !== null && streamingjs.$body !== undefined)) {
      // streamingjs.Streaming.unsubscribe(store.subscribeRicList,store.subscribeFidList);
      streamingjs.Streaming.subscribe(store.subscribeRicList,store.subscribeFidList);
      store.setSubscribeRicListIsUpdated(false)
    }
  }
  initStreaming = () => {
    clearInterval(this.timer)
    streamingjs.initStreaming();
    this.TestgroundPageObj.init().show();
    streamingjs.Streaming.subscribe(store.subscribeRicList,store.subscribeFidList);
  }
  streamingData = (element) => {
    // console.log('streamingData', element)
    if(element.IDN_RDF){
      store.setStreamingData(element.IDN_RDF)
    }
  }
  checkStreamingUpdate = (element) => {
    // console.log('checkStreamingUpdate', element)
  }

  render() {
    const {uid,bodyW,minbodyW,mobilebodyW,bodyH}=this.state
    const quoteboxes = store.subscribeRicList.map((ric, seq) => {
      return (
        <QuoteBox 
          key={seq}
          ric={ric} 
          seq={seq}
        />
      )
    })

    // 动态更换地址栏链接
    let locationName=window['location']['pathname'].split('/')
    return (
      <div style={{height:'100%'}}>
        <Switch>
          {Routers.map((item, index) => {
            return <Route key={index} path={item.path} exact render={props =>
                    !item.auth? 
                      <item.component 
                        {...props}
                        locationName={locationName}
                        bodyH={bodyH}
                        bodyW={bodyW}
                        minbodyW={minbodyW}
                        mobilebodyW={mobilebodyW}
                        isPC={bodyW>minbodyW}
                        isTablet={bodyW<=minbodyW}
                        isMobile={bodyW<=mobilebodyW}
                        callbackUid={this.callbackUid.bind(this)} 
                        callbackpathname={this.callbackpathname.bind(this)} 
                      />
                    : sessionStorage.getItem('uid')||uid? 
                        <item.component 
                          {...props} 
                          store={store}
                          alertsStore={alertsStore}
                          bodyH={bodyH}
                          bodyW={bodyW}
                          minbodyW={minbodyW}
                          mobilebodyW={mobilebodyW}
                          isPC={bodyW>minbodyW}
                          isTablet={bodyW<=minbodyW}
                          isMobile={bodyW<=mobilebodyW}
                          locationName={locationName}
                          callbackPathnameApp={this.callbackPathnameApp.bind(this)} 
                        /> 
                      : 
                        <Redirect 
                          to={{
                            pathname:('/'+locationName[1]+'/login'),
                            state: { from: props.location }
                          }} 
                        />
                    } 
                  />
            })}
            <Route render={()=>404}/>
          </Switch>
          <div className="workspacebox" hidden>
          <div id="demo1">
            <div>{quoteboxes}</div>
          </div>
        </div>
      </div>
    )
  }
}
export default observer(App)
import React from "react"
import { Pagination } from "antd"
import { withTranslation } from "react-i18next"
import common from '@/asserts/js/common'
import APPstyles from '@/App.less'
import styles from "./index.less"

class PaginationCon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chgCurrent: 1
    }
  }
  itemRender(current, type, originalElement) {
    const {sizeTotal,recperpage} = this.props
    const {chgCurrent} = this.state
    let getSizeTotal=sizeTotal>0?Math.ceil(sizeTotal / recperpage):1
    
    if (type === 'prev'&&(chgCurrent==1)) {
      return ''
    }
    if (type === 'next'&&chgCurrent==getSizeTotal) {
      return ''
    }
    return originalElement
  }
  //分页变化
  paginationClick(page) {
    this.setState({
      chgCurrent: page
    })
    this.props.callbackPagekData(page)
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {accountIndex,isRefresh,page} = this.props
    if(accountIndex!==newProps['accountIndex']){
      this.setState({
        chgCurrent: 1
      })
    }
    if(isRefresh!==newProps['isRefresh']){
      if(isRefresh&&accountIndex==1){
        this.setState({
          chgCurrent: 1
        })
      }
    }
    if(page!==newProps['page']){
      this.setState({
        chgCurrent: page
      })
    }
  }
  render() {
    const { t,sizeTotal,recperpage,isMobile,isTablet,isPC,isShow,history,tagMsg,page } = this.props
    const {chgCurrent} = this.state
    return (
      <div
        className={[
          styles.pagination_con,
          !isMobile?APPstyles.flex_row:APPstyles.flex_column,
          sizeTotal==0?styles.prevnext_none:'',
          sizeTotal>0&&(Math.ceil(sizeTotal / recperpage)==chgCurrent)?styles.next_none:'',
          chgCurrent==1?styles.prev_none:'',
          isPC&&isShow?styles.pagination_PC:''
        ].join(' ')}
        style={{
          display:history.length&&Math.ceil(sizeTotal / recperpage)>1?'':'none',
          paddingLeft:isTablet&&!isMobile?8:0
        }}
      >
        <div style={{display:isShow?'':'none'}}>
          {t('Displaying')} {page>(sizeTotal>0&&Math.ceil(sizeTotal / recperpage))?Math.ceil(sizeTotal / recperpage):chgCurrent} -  {sizeTotal>0?Math.ceil(sizeTotal / recperpage):1} {t('of')} {common.transThousands(sizeTotal)} {tagMsg=='screener'?t('matching stocks'):''}
        </div>
        <div className={[isMobile? styles.pagination_con_mobile : ""].join(' ')} style={{display:isPC?'':'none'}}>
          <Pagination
            defaultCurrent={1}
            pageSize={recperpage}
            current={sizeTotal==0?1:chgCurrent}
            total={sizeTotal==0?1:sizeTotal}
            showSizeChanger={false}
            itemRender={this.itemRender.bind(this)}
            onChange={this.paginationClick.bind(this)}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(PaginationCon)

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const VAP = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("AA2211");
  const [color2, setColor2] = useState("0080FF");

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.VAP_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const VAP = localChartSetting.option;

        props.onOverlayValueChange({
          ta: "VAP",
          name: `${t("chart5.TA.VAP")} (VAP)`,
          option: { color: VAP.color, color2: VAP.color2 },
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const VAP = props.localChartSetting.VAP_Option.option;

    setColor(VAP.color);
    setColor2(VAP.color2);
  }, [props.localChartSetting]);

  useEffect(() => {
    props.onOverlayValueChange({
      ta: "VAP",
      name: `${t("chart5.TA.VAP")} (VAP)`,
      option: { color: color, color2: color2 },
    });
  }, [color, color2]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const VAP = props.localChartSetting.VAP_Option.option;

    setColor(VAP.color);
    setColor2(VAP.color2);
  }, [props.isReset]);

  const resetALL = () => {
    setColor("AA2211");
    setColor2("0080FF");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "VAP-C1":
        setColor(props.selectedColor);
        break;
      case "VAP-C2":
        setColor2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("VAP")}></div> */}
      <div className="box-container">
        <span className="span">{t("chart5.TA.VAP")} (VAP)</span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("VAP")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="floatmsibox">
          <div className="menusubitem" id="msi-ta-VAP">
            <div className="taparamlbl">{t("chart5.whenThePriceRises")}</div>
            <button
              id="msi-ta-VAP-c0-color"
              className="taparam colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() =>
                onColorClick(color, "VAP-C1", t("chart5.whenThePriceRises"))
              }
            ></button>
          </div>

          <div className="menusubitem" id="msi-ta-VAP">
            <div className="taparamlbl">{t("chart5.whenThePriceFalls")}</div>
            <button
              id="msi-ta-VAP-c1-color"
              className="taparam colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "VAP-C2", t("chart5.whenThePriceFalls"))
              }
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

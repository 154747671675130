import React from 'react'
import { Spin,Input,Slider } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '../../../../asserts/js/common'
import { 
    closeImg,
    remeberOnImg,
    remeberOffImg,
    dropDownImg,
    dropDownOnImg,
    dropDownOnLightImg
} from '../../../../asserts/imgs/common/index'
import { 
    delImg,
    delOnImg,
    delOnLightImg
} from '../../../../asserts/imgs/quote/index'
import DropDown from './dropDown/index'
import TipsModal from '../../../tipsModal/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class resultsCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            presetList:[
                {
                    name:'Preset 1',
                    num:'310'
                },
                {
                    name:'Preset 2',
                    num:'310'
                },
                {
                    name:'Preset 3',
                    num:'310'
                },
                {
                    name:'Preset 4',
                    num:'310'
                }
            ],
            isCriteriaShow:false,
            isClickBtn:false,
            headerData:['1 Month','3 Month',,'6 Month','1 Year'],
            checkBoxData:[],
            isCheckboxShow:false,
            chgDelImg:delImg,
            isDelModalShow:false
        }
    }
    //是否选中
    isSelectClick(index){
        const {keys3Data,criteriaNum}=this.props
        const {checkBoxData}=this.state
        this.props.cbIscheckAllFun()
        let chgNum=criteriaNum

        checkBoxData[index]['isResultChecked']=!checkBoxData[index]['isResultChecked']
        //选中
        if(checkBoxData[index]['isResultChecked']){
            let getData0=checkBoxData[index]['data']
            checkBoxData[index]['isSelcetAll']=checkBoxData[index]['isSelcetAllTemp']
            checkBoxData[index]['len']=checkBoxData[index]['lenTemp']

            for(let j in getData0){
                getData0[j]['checked']=getData0[j]['checkedTemp']

                if(!keys3Data.includes(checkBoxData[index]['key'])){
                    getData0[j]['trueValue']=getData0[j]['trueValueTemp']
                    let dropDownData=getData0[j]['dropDownData']

                    if(typeof(dropDownData)!=='undefined'){
                        for(let k in dropDownData){
                            dropDownData[k]['trueValue']=dropDownData[k]['trueValueTemp']
                        }
                    }
                }
            }
            chgNum++
            this.props.cbChgNumFun(chgNum)
        }else{//未选中
            let getData1=checkBoxData[index]['data']

            for(let j in getData1){
                getData1[j]['checked']=false
                checkBoxData[index]['isSelcetAll']=false
                checkBoxData[index]['len']=0

                if(!keys3Data.includes(checkBoxData[index]['key'])){
                    getData1[j]['trueValue']=[]
                    let dropDownData=getData1[j]['dropDownData']

                    if(typeof(dropDownData)!=='undefined'){
                        for(let k in dropDownData){
                            dropDownData[k]['trueValue']=[]
                        }
                    }
                }
            }
            chgNum--
            this.props.cbChgNumFun(chgNum)
        }
        this.setState({
            checkBoxData:checkBoxData
        })
        this.props.cbResultData(checkBoxData)
    }
    // 更改input里的值
    chgInputValueFun(value,index,num){
        const {rangeListData}=this.props
        
        let logValue=value?common._truevalue2logvalue(value):num==0?0:10
        
        rangeListData[index]['trueValue'][num]=value?value:''
        rangeListData[index]['trueValueTemp'][num]=value?value:''
        rangeListData[index]['logValue'][num]=logValue
        
        let dropDownData=rangeListData[index]['dropDownData']
        let index0=rangeListData[index]['isDropDownIndex']
        
        if(typeof(dropDownData)!=='undefined'){
            dropDownData[index0]['trueValue'][num]=value?value:''
            dropDownData[index0]['trueValueTemp'][num]=value?value:''
            dropDownData[index0]['logValue'][num]=logValue
        }
        this.props.cbChgRangeListData(rangeListData)
    }
    // input--min值变化
    chgMinClick(index,e){
        let value=e['target']['value']
       this.chgInputValueFun(value,index,0) 
    }
    // input--max值变化
    chgMaxClick(index,e){
        let value=e['target']['value']
        this.chgInputValueFun(value,index,1) 
    }
    //滑块值的变化
    sliderClick(index,value,e){
        const {rangeListData}=this.props
        let min=common._logvalue2truevalue(value[0])
        let max=common._logvalue2truevalue(value[1])
        
        rangeListData[index]['trueValue']=[min,max]
        rangeListData[index]['trueValueTemp']=[min,max]
        rangeListData[index]['logValue']=[Number(value[0]),Number(value[1])]

        let dropDownData=rangeListData[index]['dropDownData']
        let index0=rangeListData[index]['isDropDownIndex']

        if(typeof(dropDownData)!=='undefined'){
            dropDownData[index0]['trueValue']=[min,max]
            dropDownData[index0]['trueValueTemp']=[min,max]
            dropDownData[index0]['logValue']=[Number(value[0]),Number(value[1])]
        }
        this.props.cbChgRangeListData(rangeListData)
    }
    // tooltip 显示对应trueValue的值
    tipFormatterClick(data,value){
        let trueValue=data['trueValue']
        let min=data['logValue'][0]
        let newVal=value==min?trueValue[0]:trueValue[1]
        return newVal
    }
    //点击外部关闭下拉框
    handleDropClick = e => {
        const {rangeListData}=this.props
        if(!this.testDropComponent.contains(e.target)){
            for(let i in rangeListData){
                rangeListData[i]['isDropDownShow']=false
                rangeListData[i]['dropDownShowLeft']=false
                rangeListData[i]['dropDownShowRight']=false
            }
            this.props.cbChgRangeListData_1(rangeListData)
        }
    }
    // 关闭下拉框
    closeDropDownClick(){
        const {rangeListData}=this.props
        for(let i in rangeListData){
            rangeListData[i]['isDropDownShow']=false
        }
        this.props.cbChgRangeListData_1(rangeListData)
    }
    // 是否删除对应的存储数据
    dealTipsModalDataFun(tag){
        this.setState({
            isDelModalShow:false
        })
        this.props.cbEditTitleFun('N')
        if(tag=='Y'){
            this.props.cbDelListDataFun()
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isSaveCriteria,isResetAll,listData}=this.props
        if(isSaveCriteria!==newProps['isSaveCriteria']){
            for(let i in listData){
                listData[i]['isResultChecked']=listData[i]['lenTemp']>0?true:false
            }
            this.setState({
                checkBoxData:listData,
                isCheckboxShow:true
            })
        }
        if(isResetAll!==newProps['isResetAll']){
            this.setState({
                isCheckboxShow:false
            })
        }
    }
    //初始化
    componentDidMount(){
        document.addEventListener('click', this.handleDropClick)     
    }
    //卸载
    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropClick)
    }
    render(){
        const {t,isPC,ChgThemeData,criteriaNum,rangeListData,isLoading,getTotal,keys3Data,isMobile,getMonthsData,quickIndex,strategyIndex,resultTitle,isDelImgShow} = this.props
        const {headerData,checkBoxData,isCheckboxShow,chgDelImg,isDelModalShow} = this.state
        return (
            <div className={styles.results_con}>
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                {/* tips--title */}
                <div 
                    className={[
                        styles.filters_con,
                        APPstyles.flex_between
                    ].join(' ')}
                    style={{
                        paddingBottom:quickIndex==null&&strategyIndex==null?8:14
                    }}
                >
                    <div className={APPstyles.flex_column}>
                        <span className={[APPstyles.fw700,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>
                            {t(resultTitle)}
                        </span>  
                        <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_56CCF2}>
                            {getTotal==null?'':common.transThousands(getTotal)} {t(getTotal==null?'':'Results')}
                        </span>
                    </div> 
                    <img 
                        src={chgDelImg} 
                        style={{display:isDelImgShow?'':'none'}}
                        onClick={()=>{
                            this.setState({
                                isDelModalShow:true
                            }) 
                            this.props.cbEditTitleFun('Y')
                        }}
                        onMouseOver={()=>{
                            if(!isPC) return
                            this.setState({
                                chgDelImg:ChgThemeData=='Light'?delOnLightImg:delOnImg
                            }) 
                        }} 
                        onMouseOut={()=>{
                            if(!isPC) return
                            this.setState({
                                chgDelImg:delImg
                            }) 
                        }}
                    />
                </div>
                {/* month */}
                <div 
                    className={[
                        styles.month_con_out,
                        getMonthsData.length?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                    ].join(' ')} 
                    style={{display:getMonthsData.length?'':'none'}}
                >
                    <div className={[APPstyles.flex_row,styles.month_con].join(' ')}>
                        {
                            headerData.map((item,index) => {
                                return  <span key={index}>{t(item)}</span>
                            })
                        }
                    </div>
                    <div className={[APPstyles.flex_row,styles.month_con].join(' ')}>
                        {
                            getMonthsData.length?
                                getMonthsData.map((item,index) => {
                                    return  <span 
                                                key={index} 
                                                style={{backgroundColor:item['bgColor']}} 
                                                className={[
                                                    styles.values,
                                                    ChgThemeData=='Light'?styles.borderRight_light:styles.borderRight_dark
                                                ].join(' ')}
                                            >
                                                {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(item['val'],2))))}
                                            </span>
                                })
                            :
                                ''
                        }
                    </div>
                </div>
                {/* checkbox */}
                <div 
                    className={[
                        styles.checkbox_con,
                        isCheckboxShow&&rangeListData.length?ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark:''
                    ].join(' ')} 
                    style={{display:isCheckboxShow?'':'none'}}
                >
                {
                    checkBoxData.map((item,index) => {
                        return  <div 
                                    key={index}
                                    className={[styles.results_list,APPstyles.flex_row].join(' ')} 
                                    style={{
                                        display:item['lenTemp']>0&&keys3Data.includes(item['key'])?'':'none'
                                    }}
                                >
                                    <img
                                        src={item['isResultChecked']?remeberOnImg:remeberOffImg}
                                        className={isMobile?APPstyles.img24:APPstyles.img16}
                                        alt='checkbox'
                                        onClick={this.isSelectClick.bind(this,index)} 
                                    /> 
                                    <div className={[APPstyles.flex_column,styles.results_item].join(' ')}>
                                        <span className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,styles.results_title].join(' ')}>{t(item['title'])}</span>
                                        <div className={APPstyles.flex_row} style={{display:item['isResultChecked']?'':'none',flexWrap:'wrap'}}>
                                            {
                                                item['resultData'][index].map((item1,index1) => {
                                                    return  <span 
                                                                key={index1} 
                                                                className={[
                                                                    styles.results_subtitle,
                                                                    ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur
                                                                ].join(' ')}
                                                                style={{display:item['title']=='Sectors'?index1<2?'':'none':''}}
                                                            >
                                                                {t(item1['name'])}{index1==item['resultData'][index].length-1?'':', '}
                                                            </span>
                                                })
                                            }
                                            <span
                                                className={[
                                                    styles.results_subtitle,
                                                    ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur
                                                ].join(' ')}
                                                style={{
                                                    display:item['title']=='Sectors'&&item['resultData'][index].length>2?'':'none'
                                                }}
                                            >
                                                &nbsp;+ {item['resultData'][index].length-2} {t('more')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                    })
                }
                </div>
                {/* range */}
                <div ref = {testComponent => this.testDropComponent = testComponent}>
                    <div style={{display:criteriaNum==0?'none':''}}>
                        {rangeListData.length?
                            rangeListData.map((item,index) => {
                            return  <div 
                                        key={index}
                                        className={[
                                            styles.range_con,
                                            isMobile&&rangeListData.length-1==index?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                            ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover
                                        ].join(' ')}
                                        style={{
                                            padding:typeof(item['range'])=='undefined'?'8px 16px':'16px 16px 21px 16px'
                                        }}
                                        onClick={this.closeDropDownClick.bind(this)}
                                    >
                                        <div 
                                            style={{
                                                marginBottom:typeof(item['range'])=='undefined'?0:16
                                            }}
                                        >
                                            <div 
                                                className={[
                                                    styles.range_title,
                                                    APPstyles.flex_between,
                                                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                                ].join(' ')}
                                            >
                                                {t(item['name'])}
                                                <img
                                                    src={closeImg}
                                                    className={isMobile?APPstyles.img24:APPstyles.img16}
                                                    alt='delete'
                                                    onClick={(e)=>{
                                                        e.stopPropagation()
                                                        this.props.cbDeleteData(item,index)
                                                    }} 
                                                /> 
                                            </div>
                                        </div>
                                        {typeof(item['range'])=='undefined'?
                                            item['key']=='ESGScoreGrade'?
                                            <div
                                                className={[
                                                    styles.inputs_con,
                                                    APPstyles.flex_between,
                                                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                    ChgThemeData=='Light'?styles.inputs_con_light:''
                                                ].join(' ')}
                                                style={{marginTop:16}}
                                            >
                                                <div 
                                                    className={[
                                                        APPstyles.flex_between,
                                                        styles.inputs_con_one,
                                                        ChgThemeData=='Light'?APPstyles.calendar_light:'',
                                                        ChgThemeData=='Light'?APPstyles.border_light:''
                                                    ].join(' ')}
                                                    onClick={(e)=>{
                                                        e.stopPropagation()
                                                        for(let i in rangeListData){
                                                            rangeListData[i]['dropDownShowRight']=false
                                                            if(i==index){
                                                                rangeListData[index]['dropDownShowLeft']=!rangeListData[index]['dropDownShowLeft']
                                                            }else{
                                                                rangeListData[i]['isDropDownShow']=false
                                                            }
                                                        }
                                                        this.props.cbChgRangeListData_1(rangeListData)
                                                    }} 
                                                    onMouseOver={(e)=>{
                                                        if(!isPC) return
                                                        e.stopPropagation()
                                                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                                        rangeListData[index]['imgLeft']=imgurl
                                                        this.props.cbChgRangeListData_1(rangeListData)
                                                    }} 
                                                    onMouseOut={(e)=>{
                                                        if(!isPC) return
                                                        e.stopPropagation()
                                                        rangeListData[index]['imgLeft']=dropDownImg
                                                        this.props.cbChgRangeListData_1(rangeListData)
                                                    }} 
                                                >
                                                    {item['leftValue']}
                                                    <img 
                                                        src={item['imgLeft']}
                                                        className={item['dropDownShowLeft']?APPstyles.rotate_img:''}  
                                                        alt='dropdown' 
                                                    />
                                                    <DropDown
                                                        {...this.props}
                                                        tag='ESGScoreGrade'
                                                        isDropDownShow={item['dropDownShowLeft']}
                                                        dropDownData={item['dropDownData']}
                                                        dropDownIndex={rangeListData[index]['leftIndex']}
                                                        cbDropDownValue={(data,index0,e)=>{
                                                            e.stopPropagation()
                                                            rangeListData[index]['dropDownShowLeft']=false
                                                            rangeListData[index]['leftValue']=data['key']
                                                            rangeListData[index]['leftIndex']=index0
                                                            this.props.cbChgRangeListData(rangeListData)
                                                        }} 
                                                    />
                                                </div>
                                                <span>{t('to')}</span>
                                                <div 
                                                    className={[
                                                        APPstyles.flex_between,
                                                        styles.inputs_con_one,
                                                        ChgThemeData=='Light'?APPstyles.calendar_light:'',
                                                        ChgThemeData=='Light'?APPstyles.border_light:''
                                                    ].join(' ')}
                                                    onClick={(e)=>{
                                                        e.stopPropagation()
                                                        for(let i in rangeListData){
                                                            rangeListData[i]['dropDownShowLeft']=false
                                                            if(i==index){
                                                                rangeListData[index]['dropDownShowRight']=!rangeListData[index]['dropDownShowRight']
                                                            }else{
                                                                rangeListData[i]['isDropDownShow']=false
                                                            }
                                                        }
                                                        this.props.cbChgRangeListData_1(rangeListData)
                                                    }} 
                                                    onMouseOver={()=>{
                                                        if(!isPC) return
                                                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                                        rangeListData[index]['imgRight']=imgurl
                                                        this.props.cbChgRangeListData_1(rangeListData)
                                                    }} 
                                                    onMouseOut={()=>{
                                                        if(!isPC) return
                                                        rangeListData[index]['imgRight']=dropDownImg
                                                        this.props.cbChgRangeListData_1(rangeListData)
                                                    }} 
                                                >
                                                    {item['rightValue']}
                                                    <img 
                                                        src={item['imgRight']}
                                                        className={item['dropDownShowRight']?APPstyles.rotate_img:''}  
                                                        alt='dropdown' 
                                                    />
                                                    <DropDown
                                                        {...this.props}
                                                        tag='ESGScoreGrade'
                                                        isDropDownShow={item['dropDownShowRight']}
                                                        dropDownData={item['dropDownData']}
                                                        dropDownIndex={rangeListData[index]['rightIndex']}
                                                        cbDropDownValue={(data,index0,e)=>{
                                                            e.stopPropagation()
                                                            rangeListData[index]['dropDownShowRight']=false
                                                            rangeListData[index]['rightValue']=data['key']
                                                            rangeListData[index]['rightIndex']=index0
                                                            this.props.cbChgRangeListData(rangeListData)
                                                        }} 
                                                    />
                                                </div>
                                            </div>
                                            :''
                                        :
                                            <div>
                                                {typeof(item['dropDownData'])=='undefined'?
                                                    ''
                                                :
                                                    <div 
                                                        className={styles.range_dropDown}
                                                        style={{display:item['key']=='ESGScoreGrade'?'none':''}}
                                                    >
                                                        <div 
                                                            className={[
                                                                styles.range_dropDown_title,
                                                                APPstyles.flex_between,
                                                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                                ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_star_off
                                                            ].join(' ')}
                                                            onClick={(e)=>{
                                                                e.stopPropagation()
                                                                for(let i in rangeListData){
                                                                    rangeListData[i]['dropDownShowLeft']=false
                                                                    rangeListData[i]['dropDownShowRight']=false
                                                                    if(i==index){
                                                                        rangeListData[index]['isDropDownShow']=!rangeListData[index]['isDropDownShow']
                                                                    }else{
                                                                        rangeListData[i]['isDropDownShow']=false
                                                                    }
                                                                }
                                                                this.props.cbChgRangeListData_1(rangeListData)
                                                            }} 
                                                            onMouseOver={(e)=>{
                                                                if(!isPC) return
                                                                e.stopPropagation()
                                                                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                                                rangeListData[index]['chgDropDownImg']=imgurl
                                                                this.props.cbChgRangeListData_1(rangeListData)
                                                            }} 
                                                            onMouseOut={(e)=>{
                                                                if(!isPC) return
                                                                e.stopPropagation()
                                                                rangeListData[index]['chgDropDownImg']=dropDownImg
                                                                this.props.cbChgRangeListData_1(rangeListData)
                                                            }} 
                                                        >
                                                            {t(item['dropDownTitle'])}
                                                            <img 
                                                                src={item['chgDropDownImg']}
                                                                className={item['isDropDownShow']?APPstyles.rotate_img:''}  
                                                                alt='dropdown' 
                                                            />
                                                            {typeof(item['dropDownData'])!=='undefined'&&
                                                            <DropDown
                                                                {...this.props}
                                                                isDropDownShow={item['isDropDownShow']}
                                                                dropDownData={item['dropDownData']}
                                                                dropDownIndex={rangeListData[index]['isDropDownIndex']}
                                                                cbDropDownValue={(data,index0,e)=>{
                                                                    e.stopPropagation()
                                                                    rangeListData[index]['isDropDownShow']=false
                                                                    rangeListData[index]['dropDownTitle']=data['name']
                                                                    rangeListData[index]['isDropDownIndex']=index0
                                                                    rangeListData[index]['logValue']=data['logValue']
                                                                    rangeListData[index]['minMax']=data['minMax']
                                                                    rangeListData[index]['range']=data['range']
                                                                    rangeListData[index]['trueValue']=data['trueValue']
                                                                    rangeListData[index]['trueValueTemp']=data['trueValue']
                                                                    this.props.cbChgRangeListData(rangeListData)
                                                                }} 
                                                            />}
                                                        </div>
                                                    </div>
                                                }
                                                <div
                                                    className={[
                                                        styles.inputs_con,
                                                        APPstyles.flex_between,
                                                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                        ChgThemeData=='Light'?styles.inputs_con_light:''
                                                    ].join(' ')}
                                                    style={{display:item['key']=='ESGScoreGrade'?'none':''}}
                                                >
                                                    <Input 
                                                        type='number'
                                                        autoComplete='off' 
                                                        value={item['trueValue'][0]} 
                                                        onChange={this.chgMinClick.bind(this,index)}
                                                    />
                                                    <span>{t('to')}</span>
                                                    <Input 
                                                        type='number'
                                                        autoComplete='off' 
                                                        value={item['trueValue'][1]} 
                                                        onChange={this.chgMaxClick.bind(this,index)}
                                                    />
                                                </div>
                                                
                                                <div 
                                                    className={[styles.slider_con,ChgThemeData=='Light'?styles.slider_con_light:''].join(' ')}
                                                    style={{display:item['key']=='ESGScoreGrade'?'none':''}}
                                                >
                                                    <Slider 
                                                        range 
                                                        value={item['logValue']}
                                                        min={item['minMax'][0]}
                                                        max={item['minMax'][1]}
                                                        step={0.01}
                                                        onChange={this.sliderClick.bind(this,index)} 
                                                        tipFormatter={this.tipFormatterClick.bind(this,item)} 
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            })
                        :
                            ''
                        }
                    </div>
                </div>
                {isDelModalShow&&<TipsModal
                    {...this.props}
                    tag='screener'
                    cancelText='Cancel'
                    sureText='Delete' 
                    contentText='Are you sure you want to delete'
                    cbCloseBtnFun={this.dealTipsModalDataFun.bind(this)} 
                />}
            </div>
         )}
}
export default withTranslation()(observer(resultsCon))
import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  leftImg,
  leftLightImg,
  closeImg
} from '@/asserts/imgs/common/index'
import { 
  delImg,
  delOnImg,
  delOnLightImg,
  searchMinImg,
  starOnImg,
  starLightImg,
  starOffImg
} from '@/asserts/imgs/quote/index'
import TipsModal from '@/components/tipsModal/index'
import APPstyles from '@/App.less'
import comStyles from '../List/index.less'
import styles from './index.less'

let symbolData=[],
    symbolDataAll=[[],[],[],[],[],[],[],[]]

class AddCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      inputValue:'',
      listData:[],
      historyListData:[],
      searchTitle:'Search History',
      isLoading:false,
      choiceOneIndex:null,
      currentData23:[2,3],
      clearImg:delImg,
      isTipModalShow:false,
      indexData0123:[0,1,2,3]
    }
    this.timer = null
  }
  //search
  changeInputClick(e) {
    let value= e['target']['value']
    this.setState({
      inputValue:value,
      searchTitle:value==''?'Search History':'Search Results',
      isLoading:value==''?false:true,
      choiceOneIndex:null
    })
    if(value=='') return
    clearTimeout(this.timer);
    this.timer = setTimeout(()=>{
      this.getPowersearch(value)
    }, 500)
  }
  //input--clear
  clearInputClick() {
    this.setState({
      inputValue:'',
      searchTitle:'Search History',
      listData:[],
      isLoading:false
    })
    clearTimeout(this.timer)
  }
  //添加数据到列表--Watchlist1/2/3/4
  addOneClick(data,index,e){
    //阻止点击事件冒泡
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    //处理数据
    const {currentIndex,watchListData}=this.props
    const {listData}=this.state
    let chgIndex=Number(currentIndex)<=4?0:Number(currentIndex)-4
    let id=watchListData[chgIndex]['watchlist_id']
    for(let i in listData){
      listData[index].isAdd=true
    }
    this.setState({
      listData:listData
    })
    this.props.cbSetWatchListFun(data['ric'],'add',id)
  }
  //删除某个
  removeOneClick(item,index,e){
    //阻止点击事件冒泡
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    //处理数据
    const {currentIndex,watchListData}=this.props
    const {listData}=this.state
    let chgIndex=Number(currentIndex)<=4?0:Number(currentIndex)-4
    let id=watchListData[chgIndex]['watchlist_id']
    for(let i in listData){
      listData[index].isAdd=false
    }
    this.setState({
      listData:listData
    })
    this.props.cbSetWatchListFun(item['ric'],'del',id)
  }
  //选择某个值改变右面的detail数据--关闭search页面--History
  searchOneClick(data){
    const {sliderIndex,locationName,currentIndex,isMobile,isTablet}=this.props
    const {currentData23}=this.state
    if(sliderIndex=='quote'){
      this.props.callbackLeftPageShow('list')
      this.props.callbackChgRic(data)
      sessionStorage.setItem('ChgAddData',true)
      //ios终端
      if(currentData23.includes(currentIndex)&&isMobile){
        this.props.cbTagIshowHeader('add')
      }
      if(!currentData23.includes(currentIndex)){
        this.props.cbDetailPageShow(true)
      }
      if(currentData23.includes(currentIndex)&&isTablet&&!isMobile){
        this.props.cbIsShowHeaderPage(false)
      }
    }
    // account--跳转到quote页面
    if(sliderIndex=='account'){
      this.gotoQuote(data,locationName)
    }
    // news页面
    if(sliderIndex=='news'){
      this.props.cbNewsDataPageFun(data)
    }
  }
  // account--跳转到quote页面并更改为对应的右边数据detail
  gotoQuote(data,locationName){
    let pathName='/'+locationName[1]+'/Quote'
    this.props.callbackPathnameApp(pathName)// 获取路由跳转--app.js
    sessionStorage.setItem('navId','quote')
    sessionStorage.setItem('navIdAPP','quote')
    sessionStorage.setItem('accountAddData',JSON.stringify(data))
  }
  //clear History
  clearHistoryFun(){
    const {currentIndex}=this.props
    const {historyListData,indexData0123}=this.state
    if(historyListData.length==0) return
    this.setState({
      historyListData:[]
    })
    symbolData=[]
    let listindex=indexData0123.includes(currentIndex)?4:currentIndex
    symbolDataAll[listindex]=symbolData
    localStorage.setItem('symbolDataAll',JSON.stringify(symbolDataAll))
  }
  //处理数据--过滤相同的
  contains(arr,ric,obj) {
    let i = arr.length
    while (i--) {
      if (arr[i][ric] === obj) {
        return true
      }
    }
    return false
  }
  //选择某个--改变detail/存储值
  choiceOneClick(item,changeIndex){
    const {currentIndex,sliderIndex,locationName,isMobile,isTablet}=this.props
    const {choiceOneIndex,currentData23,indexData0123}=this.state
    if(choiceOneIndex==changeIndex) return
    this.setState({
      choiceOneIndex:changeIndex
    })
    // news页面
    if(sliderIndex=='news'){
      this.props.cbNewsDataPageFun(item)
    }
    // account--跳转到quote页面
    if(sliderIndex=='account'){
      this.gotoQuote(item,locationName)
    }
    // quote页面
    if((currentIndex!==3&&(sliderIndex=='quote'))||currentData23.includes(currentIndex)){
      this.props.callbackChgRic(item)
      if(!currentData23.includes(currentIndex)){
        this.props.cbDetailPageShow(true)
      }
    }
    if(isMobile&&currentData23.includes(currentIndex)){
      this.props.callbackLeftPageShow('list')
    }
    //ios终端
    if(currentData23.includes(currentIndex)&&isMobile){
      this.props.cbTagIshowHeader('add')
    }
    if(currentData23.includes(currentIndex)&&isTablet&&!isMobile){
      this.props.cbIsShowHeaderPage(false)
    }
    //处理数据--过滤相同的
    let getsymbolDataAll=localStorage.getItem('symbolDataAll')
    let data1=JSON.parse(getsymbolDataAll)
    let listindex=indexData0123.includes(currentIndex)?4:currentIndex

    if(getsymbolDataAll&&data1[listindex].length>0){
      let getData=data1[listindex]
      let getStatus=this.contains(getData,'ric',item['ric'])
      
      if(!getStatus){
        symbolData.unshift(item)
        symbolDataAll[listindex]=symbolData
        localStorage.setItem('symbolDataAll',JSON.stringify(symbolDataAll))

        this.setState({
          historyListData:symbolData
        })
      }
      return
    }
    symbolData.unshift(item)
    symbolDataAll[listindex]=symbolData
    localStorage.setItem('symbolDataAll',JSON.stringify(symbolDataAll))

    this.setState({
      historyListData:symbolData
    })
  }
  //powersearch--获取数据
  getPowersearch(value){
    const {currentIndex,watchListData}=this.props
    fetch(common.getHttp+'powersearch',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        k:value,
        limit:10,
        tradable:false
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        // 处理数据--flag
        let resData=res['data']['datalist']
        if(resData.length>0){
          for(let i in resData){
            resData[i]['img']=common.imgShortNameFlag[resData[i]['exchange_country_code']]
          }
          resData=common.imgDataFlagFun(resData,'img')
          // 处理数据--已在watchListData则显示已添加
          let getData=watchListData.length?watchListData[Number(currentIndex)-4]:[]
          let getRicData=getData&&getData['stock_id_list']
          if(getRicData&&getRicData.length){
            for(let i in resData){
              resData[i]['isAdd']=false
              for(let j in getRicData){
                if(resData[i]['ric']==getRicData[j]){
                  resData[i]['isAdd']=true
                }
              }
            }
          }
        }
        this.setState({
          listData:resData,
          isLoading:false
        })
        return
      }
      //获取失败
      this.setState({
        listData:[],
        isLoading: false
      })
    })
  }
  //初始化数据
  onLoadataFun(){
    const {currentIndex}=this.props
    const {indexData0123}=this.state
    let listindex=indexData0123.includes(currentIndex)?4:currentIndex
    //获取对应的数组数据---symbolDataAll
    symbolData=[]
    symbolDataAll=[[],[],[],[],[],[],[],[]]
    let getSymbolDatas=localStorage.getItem('symbolDataAll')
    symbolDataAll=JSON.parse(getSymbolDatas)?JSON.parse(getSymbolDatas):symbolDataAll

    if(getSymbolDatas&&JSON.parse(getSymbolDatas)[listindex].length>0){
      let getData=JSON.parse(getSymbolDatas)[listindex]
      this.setState({
        historyListData:getData
      })
      symbolData=getData
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isClearSearch}=this.props
    // news页面
    if(isClearSearch!==newProps['isClearSearch']){
      this.setState({
        inputValue:'',
        listData:[],
        searchTitle:'Search History',
        isLoading:false,
        choiceOneIndex:null,
        clearImg:delImg
      })
    }
  }
  //初始化
  componentDidMount(){
    this.onLoadataFun()
  }
  render(){
    const {t,ChgThemeData,isLeftPageShow,isMobile,sliderIndex,isSearchPageShow,currentIndex,isPC} = this.props
    const {listData,inputValue,historyListData,searchTitle,isLoading,choiceOneIndex,clearImg,isTipModalShow}=this.state
    return (
      <div 
        className={[
          APPstyles.flex_column,
          comStyles.list_con,
          isMobile&&(isLeftPageShow=='add'||isSearchPageShow)?APPstyles.position_fixed:'',
          isMobile&&(isLeftPageShow=='add'||isSearchPageShow)?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
        ].join(' ')}
        style={{
          position:'relative'
        }}
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        <div 
          className={[
            APPstyles.flex_row,
            styles.add_stocks,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        > 
          <img 
            src={ChgThemeData=='Light'?leftLightImg:leftImg} 
            className={APPstyles.img24} 
            onClick={()=>{
              // account
              if(sliderIndex=='account'||sliderIndex=='news'){
                this.props.cbCloseSearchPage(false)
                return
              }
              this.props.callbackLeftPageShow('list')
              //ios终端
              if((currentIndex==2||currentIndex==3)&&isMobile){
                this.props.cbIsShowHeaderPage(true)
              }
            }} 
            alt='back' 
          />
          <div 
            className={[
              styles.add_inputcon,
              ChgThemeData=='Light'?styles.add_inputcon_light:''
            ].join(' ')}
          >
            <input 
              autoComplete='off'
              placeholder={t('Search')} 
              value={inputValue} 
              style={{paddingLeft:inputValue===''?34:16}}
              onChange={this.changeInputClick.bind(this)} 
            />
            <img 
              src={searchMinImg} 
              className={[APPstyles.img16,styles.close_img,APPstyles.transformY].join(' ')} 
              style={{display:inputValue===''?'':'none',left:16}} 
              alt='search' 
            />
            <img 
              src={closeImg} 
              className={[APPstyles.img16,styles.close_img,APPstyles.transformY].join(' ')} 
              style={{display: inputValue===''?'none':''}} 
              onClick={this.clearInputClick.bind(this)}
              alt='clear' 
            />
          </div>
        </div>
        <div 
          className={[
            APPstyles.flex_row,
            comStyles.history_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
          style={{
            top:56
          }}
        >
          <span className={isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''}>{t(searchTitle)}</span>
          <img 
            src={clearImg} 
            alt='clear' 
            className={APPstyles.img16} 
            style={{display:inputValue?'none':''}}
            onClick={()=>{
              if(!historyListData.length) return
              this.setState({
                isTipModalShow: true
              })
            }} 
            onMouseOver={()=>{
              if(!isPC) return
              let imgurl=ChgThemeData=='Light'?delOnLightImg:delOnImg
              this.setState({
                clearImg:imgurl
              })
            }} 
            onMouseOut={()=>{
              if(!isPC) return
              this.setState({
                clearImg:delImg
              })
            }}
          />
        </div>
        {
          historyListData.length?
            <div 
              className={[
                APPstyles.flex_row,
                styles.history_list_con,
                ChgThemeData=='Light'?styles.history_list_con_light:''
              ].join(' ')} 
              style={{display:inputValue?'none':''}}
            >
              {
                historyListData.map((item,index) => {
                  return <span key={index} className={ChgThemeData=='Light'?APPstyles.btn_gray:APPstyles.btn_black} onClick={this.searchOneClick.bind(this,item)}>{item['symbol']}</span>
                  })
              }
            </div>
          :''
        }
        {/* 列表数据--list */}
        <div 
          className={[
            comStyles.table_body,
            APPstyles.flex1_auto,
            APPstyles.paddingBottom60
          ].join(' ')}
        >
        {
          listData.length?
            listData.map((item,index) => {
            return  <div 
                      className={[
                        APPstyles.flex_row,
                        comStyles.body_one,
                        comStyles.table_common,
                        choiceOneIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark:'',
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                        ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover
                      ].join(' ')} 
                      key={index}
                    >
                      <div className={APPstyles.flex_column} style={{width: '60%'}} onClick={this.choiceOneClick.bind(this,item,index)}>
                          <div style={{width: '100%',alignItems:'center'}} className={APPstyles.flex_row}>
                          <img src={item['img']} className={APPstyles.img16} style={{display:item['img']?'':'none'}} />  
                            <span 
                              className={[
                                comStyles.list_name,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                              ].join(' ')}
                            >
                              {item['symbol']&&item['symbol'].toUpperCase()}
                            </span>
                          </div>
                          <span className={[comStyles.list_cur,ChgThemeData=='Light'?APPstyles.color_009E9C:''].join(' ')}>{item['name']}</span>
                      </div>
                      <div style={{width: '40%'}}>
                        <span 
                          className={[
                            styles.table_icons,
                            isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:'',
                            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_grey
                          ].join(' ')} 
                          style={{display: item['isAdd']?'':'none'}}
                        >
                          {t('Already Added')} 
                          <img 
                            src={starOnImg} 
                            className={APPstyles.img24} 
                            alt='check' 
                            onClick={this.removeOneClick.bind(this,item,index)} 
                          />
                        </span>
                        <img 
                          src={ChgThemeData=='Light'?starLightImg:starOffImg} 
                          className={APPstyles.img24}  
                          style={{display: item['isAdd']?'none':''}} 
                          onClick={this.addOneClick.bind(this,item,index)}  
                          alt='plus'
                        />
                      </div>
                  </div>
                })
            :
              <div className={APPstyles.isLoading} style={{display:isLoading?'none':inputValue?'':'none'}}>{t('No datas')}</div>  
        }
        </div>
        {
          isTipModalShow?
            <TipsModal 
              {...this.props}
              tag={'add'}
              cancelText={'Cancel'}
              sureText={'Delete'} 
              contentText={'Are you sure you want to delete all search history?'}
              cbCloseBtnFun={(data)=>{
                this.setState({
                  isTipModalShow: false
                })
                if(data=='Y'){
                  this.clearHistoryFun()
                }
              }}
            />
          :
            ''
        }
        
      </div>
  )}
}
export default withTranslation()(AddCon)
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const BB = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(25);
  const [color, setColor] = useState("666666");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("d");
  const [showTracker, setShowTracker] = useState(true);

  const [v1enable, setV1Enable] = useState(true);
  const [v1value, setV1Value] = useState(1);
  const [v1color, setV1Color] = useState("FFB0B0");
  const [v1color2, setV1Color2] = useState("449999");
  const [v1thickness, setV1Thickness] = useState(2);
  const [v1style, setV1Style] = useState("s");
  const [v1showTracker, setV1ShowTracker] = useState(true);

  const [v2enable, setV2Enable] = useState(true);
  const [v2value, setV2Value] = useState(2);
  const [v2color, setV2Color] = useState("AA2211");
  const [v2color2, setV2Color2] = useState("66CCCC");
  const [v2thickness, setV2Thickness] = useState(2);
  const [v2style, setV2Style] = useState("s");
  const [v2showTracker, setV2ShowTracker] = useState(true);

  const [v3enable, setV3Enable] = useState(true);
  const [v3value, setV3Value] = useState(3);
  const [v3color, setV3Color] = useState("FFB0B0");
  const [v3color2, setV3Color2] = useState("449999");
  const [v3thickness, setV3Thickness] = useState(2);
  const [v3style, setV3Style] = useState("s");
  const [v3showTracker, setV3ShowTracker] = useState(true);
  const [value, setValue] = useState(
    period + "," + v1value + "σ," + v2value + "σ," + v3value + "σ"
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.BB_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      const v1_option = localChartSetting.option.deviation[0]
      const v2_option = localChartSetting.option.deviation[1]
      const v3_option = localChartSetting.option.deviation[2]
      const bb_option = localChartSetting.option.BB

      let BB = "";
      let v1 = "";
      let v2 = "";
      let v3 = "";
  
      v1 = {
        value: v1_option.value,
        color: v1_option.color,
        color2: v1_option.color2,
        thickness: v1_option.thickness,
        style: v1_option.style,
        showTracker: v1_option.showTracker,
        enabled: v1_option.enabled,
      };
  
      v2 = {
        value: v2_option.value,
        color: v2_option.color,
        color2: v2_option.color2,
        thickness: v2_option.thickness,
        style: v2_option.style,
        showTracker: v2_option.showTracker,
        enabled: v2_option.enabled,
      };
  
      v3 = {
        value: v3_option.value,
        color: v3_option.color,
        color2: v3_option.color2,
        thickness: v3_option.thickness,
        style: v3_option.style,
        showTracker: v3_option.showTracker,
        enabled: v3_option.enabled,
      };
  
      BB = {
        period: bb_option.period,
        color: bb_option.color,
        thickness: bb_option.thickness,
        style: bb_option.style,
        showTracker: bb_option.showTracker,
        enabled: bb_option.enabled,
      };
  
      let deviations = [v1, v2, v3];
  
      let v = "";
      if (enable) {
        v = v + period + ",";
      }
      if (v1enable) {
        v = v + v1value + "σ,";
      }
      if (v2enable) {
        v = v + v2value + "σ,";
      }
      if (v3enable) {
        v = v + v3value + "σ";
      }
  
      props.onOverlayValueChange({
        ta: "BB",
        name: `${t("chart5.TA.BB")} (BB)`,
        option: { BB: BB, deviation: deviations },
        value: v,
      });

    }
  }, [localChartSetting]);

  useEffect(() => {
    const BB = props.localChartSetting.BB_Option.option

    const v1_option = BB.deviation[0]
    const v2_option = BB.deviation[1]
    const v3_option = BB.deviation[2]
    const bb_option = BB.BB

    setEnable(bb_option.enabled);
    setPeriod(parseInt(bb_option.value) || period);
    setColor(bb_option.color);
    setThickness(parseInt(bb_option.thickness) || thickness);
    setStyle(bb_option.style);
    setShowTracker(bb_option.showTracker);

    setV1Enable(v1_option.enabled);
    setV1Value(parseInt(v1_option.value) || v1value);
    setV1Color(v1_option.color);
    setV1Color2(v1_option.color2);
    setV1Thickness(parseInt(v1_option.thickness) || v1thickness);
    setV1Style(v1_option.style);
    setV1ShowTracker(v1_option.showTracker);

    setV2Enable(v2_option.enabled);
    setV2Value(parseInt(v2_option.value) || v2value);
    setV2Color(v2_option.color);
    setV2Color2(v2_option.color2);
    setV2Thickness(parseInt(v2_option.thickness) || v2thickness);
    setV2Style(v2_option.style);
    setV2ShowTracker(v2_option.showTracker);

    setV3Enable(v3_option.enabled);
    setV3Value(parseInt(v3_option.value) || v3value);
    setV3Color(v3_option.color);
    setV3Color2(v3_option.color2);
    setV3Thickness(parseInt(v3_option.thickness) || v3thickness);
    setV3Style(v3_option.style);
    setV3ShowTracker(v3_option.showTracker);

  }, [props.localChartSetting]);

  useEffect(() => {
    let BB = "";
    let v1 = "";
    let v2 = "";
    let v3 = "";

    v1 = {
      value: v1value,
      color: v1color,
      color2: v1color2,
      thickness: v1thickness,
      style: v1style,
      showTracker: v1showTracker,
      enabled: v1enable,
    };

    v2 = {
      value: v2value,
      color: v2color,
      color2: v2color2,
      thickness: v2thickness,
      style: v2style,
      showTracker: v2showTracker,
      enabled: v2enable,
    };

    v3 = {
      value: v3value,
      color: v3color,
      color2: v3color2,
      thickness: v3thickness,
      style: v3style,
      showTracker: v3showTracker,
      enabled: v3enable,
    };

    BB = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    let deviations = [v1, v2, v3];

    let v = "";
    if (enable) {
      v = v + period + ",";
    }
    if (v1enable) {
      v = v + v1value + "σ,";
    }
    if (v2enable) {
      v = v + v2value + "σ,";
    }
    if (v3enable) {
      v = v + v3value + "σ";
    }

    setValue(v);

    props.onOverlayValueChange({
      ta: "BB",
      name: `${t("chart5.TA.BB")} (BB)`,
      option: { BB: BB, deviation: deviations },
      value: value,
    });
  }, [
    period,
    color,
    thickness,
    style,
    showTracker,
    enable,
    value,
    v1value,
    v1color,
    v1color2,
    v1thickness,
    v1style,
    v1showTracker,
    v1enable,
    v2value,
    v2color,
    v2color2,
    v2thickness,
    v2style,
    v2showTracker,
    v2enable,
    v3value,
    v3color,
    v3color2,
    v3thickness,
    v3style,
    v3showTracker,
    v3enable,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const BB = props.localChartSetting.BB_Option.option

    const v1_option = BB.deviation[0]
    const v2_option = BB.deviation[1]
    const v3_option = BB.deviation[2]
    const bb_option = BB.BB

    setEnable(bb_option.enabled);
    setPeriod(parseInt(bb_option.value) || period);
    setColor(bb_option.color);
    setThickness(parseInt(bb_option.thickness) || thickness);
    setStyle(bb_option.style);
    setShowTracker(bb_option.showTracker);

    setV1Enable(v1_option.enabled);
    setV1Value(parseInt(v1_option.value) || v1value);
    setV1Color(v1_option.color);
    setV1Color2(v1_option.color2);
    setV1Thickness(parseInt(v1_option.thickness) || v1thickness);
    setV1Style(v1_option.style);
    setV1ShowTracker(v1_option.showTracker);

    setV2Enable(v2_option.enabled);
    setV2Value(parseInt(v2_option.value) || v2value);
    setV2Color(v2_option.color);
    setV2Color2(v2_option.color2);
    setV2Thickness(parseInt(v2_option.thickness) || v2thickness);
    setV2Style(v2_option.style);
    setV2ShowTracker(v2_option.showTracker);

    setV3Enable(v3_option.enabled);
    setV3Value(parseInt(v3_option.value) || v3value);
    setV3Color(v3_option.color);
    setV3Color2(v3_option.color2);
    setV3Thickness(parseInt(v3_option.thickness) || v3thickness);
    setV3Style(v3_option.style);
    setV3ShowTracker(v3_option.showTracker);

  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(25);
    setColor("666666");
    setThickness(2);
    setStyle("d");
    setShowTracker(true);
    setV1Enable(true);
    setV1Value(1);
    setV1Color("FFB0B0");
    setV1Color2("449999");
    setV1Thickness(2);
    setV1Style("s");
    setV1ShowTracker(true);

    setV2Enable(true);
    setV2Value(2);
    setV2Color("AA2211");
    setV2Color2("66CCCC");
    setV2Thickness(2);
    setV2Style("s");
    setV2ShowTracker(true);

    setV3Enable(true);
    setV3Value(3);
    setV3Color("FFB0B0");
    setV3Color2("449999");
    setV3Thickness(2);
    setV3Style("s");
    setV3ShowTracker(true);
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "p1":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "v1":
        if (v1enable) setV1Enable(false);
        else setV1Enable(true);
        break;
      case "v2":
        if (v2enable) setV2Enable(false);
        else setV2Enable(true);
        break;
      case "v3":
        if (v3enable) setV3Enable(false);
        else setV3Enable(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "p1":
        setStyle(e.target.value);
        break;
      case "v1":
        setV1Style(e.target.value);
        break;
      case "v2":
        setV2Style(e.target.value);
        break;
      case "v3":
        setV3Style(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "p1":
        setPeriod(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onValueChange = (e, type) => {
    switch (type) {
      case "v1":
        setV1Value(e.target.value);
        break;
      case "v2":
        setV2Value(e.target.value);
        break;
      case "v3":
        setV3Value(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "p1":
        setThickness(e.target.value);
        break;
      case "v1":
        setV1Thickness(e.target.value);
        break;
      case "v2":
        setV2Thickness(e.target.value);
        break;
      case "v3":
        setV3Thickness(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "p1":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "v1":
        if (v1showTracker) setV1ShowTracker(false);
        else setV1ShowTracker(true);
        break;
      case "v2":
        if (v2showTracker) setV2ShowTracker(false);
        else setV2ShowTracker(true);
        break;
      case "v3":
        if (v3showTracker) setV3ShowTracker(false);
        else setV3ShowTracker(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "BB-C1":
        setColor(props.selectedColor);
        break;
      case "BB-C2":
        setV1Color(props.selectedColor);
        break;
      case "BB-C3":
        setV1Color2(props.selectedColor);
        break;
      case "BB-C4":
        setV2Color(props.selectedColor);
        break;
      case "BB-C5":
        setV2Color2(props.selectedColor);
        break;
      case "BB-C6":
        setV3Color(props.selectedColor);
        break;
      case "BB-C7":
        setV3Color2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("BB")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.BB")} (BB) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("BB")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          id="msi-ta-BB"
          style={enable ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p1")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 1</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "BB-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-KC-mid-style" defaultValue={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          id="msi-ta-BB"
          style={v1enable ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              id="msi-ta-BB-p1-enabled"
              className={
                v1enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("v1")}
            ></div>
            <div className="taparamlbl">σ</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-BB-p1-period0"
                value={v1value}
                onChange={(e) => onValueChange(e, "v1")}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">σ+</div>
            <button
              id="msi-ta-BB-p1-color0"
              className="colorpicker"
              value={v1color}
              style={{ backgroundColor: "#" + v1color }}
              onClick={() => onColorClick(v1color, "BB-C2", "σ+")}
            ></button>
            <div className="styleboxlbl">σ-</div>
            <button
              id="msi-ta-BB-p1-color1"
              className="colorpicker"
              value={v1color2}
              style={{ backgroundColor: "#" + v1color2 }}
              onClick={() => onColorClick(v1color2, "BB-C3", "σ-")}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-p1-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={v1thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "v1")}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e, "v1")}>
              <select id="msi-ta-KC-p1-style" value={v1style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-KC-p1-showtracker"
                className={
                  v1showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("v1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          id="msi-ta-BB"
          style={v2enable ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              id="msi-ta-BB-p2-enabled"
              className={
                v2enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("v2")}
            ></div>
            <div className="taparamlbl">σ</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-BB-p1-period0"
                value={v2value}
                onChange={(e) => onValueChange(e, "v2")}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">σ+</div>
            <button
              id="msi-ta-BB-p2-color0"
              className="colorpicker"
              value={v2color}
              style={{ backgroundColor: "#" + v2color }}
              onClick={() => onColorClick(v2color, "BB-C4", "σ+")}
            ></button>

            <div className="styleboxlbl">σ-</div>
            <button
              id="msi-ta-BB-p2-color1"
              className="colorpicker"
              value={v2color2}
              style={{ backgroundColor: "#" + v2color2 }}
              onClick={() => onColorClick(v2color2, "BB-C5", "σ-")}
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-KC-p1-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={v2thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "v2")}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e, "v2")}>
              <select id="msi-ta-KC-p1-style" value={v2style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-KC-p1-showtracker"
                className={
                  v2showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("v2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          id="msi-ta-BB"
          style={v3enable ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              id="msi-ta-BB-p3-enabled"
              className={
                v3enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("v3")}
            ></div>
            <div className="taparamlbl">σ</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-BB-p1-period0"
                value={v3value}
                onChange={(e) => onValueChange(e, "v3")}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">σ+</div>
            <button
              id="msi-ta-BB-p3-color0"
              className="colorpicker"
              value={v3color}
              style={{ backgroundColor: "#" + v3color }}
              onClick={() => onColorClick(v3color, "BB-C6", "σ+")}
            ></button>

            <div className="styleboxlbl">σ-</div>
            <button
              id="msi-ta-BB-p3-color1"
              className="colorpicker"
              value={v3color2}
              style={{ backgroundColor: "#" + v3color2 }}
              onClick={() => onColorClick(v3color2, "BB-C7", "σ-")}
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-KC-p1-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={v3thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "v3")}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e, "v3")}>
              <select id="msi-ta-KC-p1-style" value={v3style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-KC-p1-showtracker"
                className={
                  v3showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("v3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

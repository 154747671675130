import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  warningImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class PriceModal extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
     
    }
  }
  render(){
    const {t,bodyW,mobilebodyW,ChgThemeData,tag}=this.props
    return (
      <div className={styles.price_con_fixed}>
        <div 
          className={[
            styles.price_con,
            APPstyles.flex_column,
            ChgThemeData=='Light'?styles.price_con_light:'',
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
          ].join(' ')} 
          style={{
            width:bodyW>mobilebodyW?525:'80%'
          }}
        >
          <div className={APPstyles.flex_1}>
            <div className={[styles.price_title,APPstyles.flex_row].join(' ')}>
              <img src={warningImg} className={[APPstyles.img24].join(' ')} style={{cursor:'default'}} alt='warning' />
              <span>{t(
                tag=='price'?
                  'Please enter the price.'
                :tag=='percentage'?
                  'Please enter percentage change.'
                :tag=='market'?
                  'Please select a market.'
                :  
                  'Please enter the quantity.'
              )}</span>
            </div>
          </div>
          <div className={[styles.price_con_btn,APPstyles.flex_row].join(' ')}>
            <Button 
              type='default' 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
              onClick={()=>{
                this.props.cbClosePriceModal()
              }}
            >
              {t('OK')}
            </Button>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(PriceModal)
import React from 'react';
class QuoteBox extends React.Component {
  componentDidMount() {
  }

  componentDidUpdate() {
    //		// console.log("QuoteBox.componentDidUpdate(): ric=" + this.props.ric);
  }

  componentWillUnmount() {
    //		// console.log("QuoteBox.componentWillUnmount(): ric=" + this.props.ric);
  }
  // TRDPRC_1,NETCHNG_1,PCTCHNG,ACVOL_1,HST_CLOSE
  render() {
    return (
      <div>
        <div className="stream flash price" ric={this.props.ric} dp="3" fid="6" service="IDN_RDF">-</div>
        <div className="stream flash change" ric={this.props.ric} dp="3" fid="11" service="IDN_RDF">-</div>
        <div className="stream time" ric={this.props.ric} fid="56" service="IDN_RDF">-</div>
        <div className="stream" ric={this.props.ric} fid="32" service="IDN_RDF">-</div>
        <div className="stream" ric={this.props.ric} fid="21" service="IDN_RDF">-</div>
      </div>
    );
  }
}
export default QuoteBox
import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import LeftCon from './left/index'
import RightCon from './right/index'
import QuoteDetail from '../QuoteDetail/index'
import AddCon from '../Add/index'
import WatchlistEdit from '../WatchlistEdit/index'
import APPstyles from '../../../App.less'
import styles from './index.less'

class Screener extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            getLeftW:400,
            getCriteriaH:600,
            isToggleOn: false,
            isListPageShow:false,
            isDetailShow:false,
            tagIshowHeader:'detail',
            ricData:{},
            isLeftPageShow:'',
            isGetResultShow:false,
            getListData:[],
            keys3Data:['MarketCap','InstrumentType','Sectors'],
            getTotal:null,
            getMonthsData:[],
            chgTabData:false,
            isGetResultBtnShow:false,
            isOnLoad:true,
            getStrategy:''
        }
    }
    chgLeftWFun(){
        let getW=document.getElementById('screenerLeftId').clientWidth
        this.setState({
            getLeftW:getW
        })
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {bodyW,bodyH,isPC,isMobile,isLeftPageShow} = this.props
        if(bodyW!==newProps['bodyW']){
            this.chgLeftWFun() 
        }
        if(bodyH!==newProps['bodyH']&&!isMobile){
            this.setState({
                getCriteriaH:bodyH>850?600:isPC?'70%':'60%'
            }) 
        }
        if(isLeftPageShow!==newProps['isLeftPageShow']){
            this.setState({
                isLeftPageShow:isLeftPageShow
            })
        } 
    }
    //初始化
    componentDidMount(){
        this.setState({
            chgTabData:false
        })
       this.chgLeftWFun() 
    }
    render(){
        const {isPC,bodyH,isTablet,isMobile,ChgThemeData} = this.props
        const {getLeftW,getCriteriaH,isToggleOn,isListPageShow,isDetailShow,ricData,tagIshowHeader,isLeftPageShow,isGetResultShow,getListData,keys3Data,getTotal,getMonthsData,chgTabData,isGetResultBtnShow,isOnLoad,getStrategy} = this.state
        return (
            <div
                className={[
                    APPstyles.flex1_hidden,
                    APPstyles.flex_row,
                    isPC?APPstyles.marginLeft72:APPstyles.marginLeft0
                ].join(' ')}
                style={{
                    position:'relative'
                }}
            >
                <div 
                    id='screenerLeftId'
                    className={[
                        APPstyles.flex_column,
                        isMobile?APPstyles.wPer100:isTablet&&!isMobile?APPstyles.wPer40:styles.w288,
                        ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark
                    ].join(' ')}
                    style={{
                        display:isMobile?isListPageShow?'none':'':isToggleOn?'none':''
                    }}
                >
                    {isLeftPageShow=='add'&&<AddCon  
                        {...this.props}
                        cbIsShowHeaderPage={(data)=>{
                            this.setState({
                                isOnLoad:false
                            })
                            this.props.cbIsShowHeaderPage(data)
                        }}
                        cbTagIshowHeader={(data)=>{
                            this.setState({
                                tagIshowHeader:data
                            })
                        }}
                        callbackChgRic={(data)=>{
                            //选择某个ric
                            this.setState({
                                ricData:data,
                                isDetailShow:true,
                                chgTabData:!chgTabData
                            })
                        }} 
                        callbackLeftPageShow={(data)=>{
                            this.setState({
                                isLeftPageShow:''
                            })
                            this.props.callbackLeftPageShow(data)
                        }}
                    />}
                    {isLeftPageShow=='edit'&&<WatchlistEdit 
                        {...this.props}
                        callbackChgTabName={(data)=>{
                            this.props.callbackChgTabName(data)
                        }} 
                        callbackLeftPageShow={(data)=>{
                            this.setState({
                                isLeftPageShow:''
                            })
                            this.props.callbackLeftPageShow(data)
                        }}
                        cbIsShowHeaderPage={(data)=>{
                            this.setState({
                                isOnLoad:false
                            })
                            this.props.cbIsShowHeaderPage(data)
                        }}
                        cbOnLoadGetWatchlistFun={()=>{
                          this.props.cbOnLoadGetWatchlistFun()
                        }}
                    />}
                    <div 
                        className={[APPstyles.flex_column,APPstyles.HPer100].join(' ')} 
                        style={{display:isLeftPageShow==''||isLeftPageShow=='list'?'':'none'}}
                    >
                        <LeftCon 
                            {...this.props}
                            getLeftW={getLeftW}
                            getCriteriaH={getCriteriaH}
                            keys3Data={keys3Data}
                            getTotal={getTotal}
                            getMonthsData={getMonthsData}
                            isGetResultBtnShow={isGetResultBtnShow}
                            cbIsShowHeaderPage={(data)=>{
                                this.props.cbIsShowHeaderPage(data)
                            }} 
                            cbIsGetResultShow={(data,tag,name)=>{
                                this.setState({
                                    getListData:data,
                                    isGetResultShow:!isGetResultShow,
                                    getStrategy:name
                                })
                                if(isMobile){
                                    this.setState({
                                        isListPageShow: tag=='btn'?true:false,
                                        tagIshowHeader:isOnLoad?'detail':tagIshowHeader
                                    })
                                }
                            }}
                            cbResetFun={()=>{
                                this.setState({
                                    getTotal: null,
                                    getMonthsData:[]
                                })
                            }}
                            cbCriteriaShow={()=>{
                                if(!isMobile){
                                    this.setState({
                                        getCriteriaH:bodyH>850?600:isPC?'70%':'60%'
                                    }) 
                                }
                            }}
                        /> 
                    </div> 
                </div>
                <div 
                    className={[
                        isPC?
                            isToggleOn?styles.center_w0:styles.center_w1
                        :isMobile?
                            APPstyles.wPer100
                        :
                            isToggleOn?APPstyles.wPer100:APPstyles.wPer60,
                        APPstyles.flex1_hidden,
                        ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark
                    ].join(' ')}
                    style={{
                        display:isMobile?isListPageShow?'':'none':''
                    }}
                >
                    <RightCon 
                        {...this.props}
                        isListPageShow={isListPageShow}
                        isGetResultShow={isGetResultShow}
                        getListData={getListData}
                        keys3Data={keys3Data}
                        getStrategy={getStrategy}
                        isChgToggleOn={isToggleOn}
                        cbIsToggleOn={(data)=>{
                            this.setState({
                                isToggleOn: data
                            })
                        }} 
                        cbIsListPageShow={(data)=>{
                            this.setState({
                                isListPageShow: data
                            })
                            //ios终端
                            isMobile&&this.props.cbIsShowHeaderPage(true)
                        }}
                        chgListData={(data)=>{
                            this.setState({
                                isDetailShow: true,
                                ricData:data,
                                chgTabData:!chgTabData
                            })
                        }}
                        chgOnLoadListData={(data)=>{
                            this.setState({
                                ricData:data,
                                chgTabData:!chgTabData
                            })
                        }}
                        cbSizeTotal={(data)=>{
                            this.setState({
                                getTotal: data
                            })
                        }}
                        chgMonthsDataFun={(data)=>{
                            this.setState({
                                getMonthsData: data
                            })
                        }}
                        cbChgTabIndex={(index,data)=>{
                            this.props.cbChgTabIndex(index,data)
                        }}
                        cbCloseGetResultBtn={(data)=>{
                            this.setState({
                                isGetResultBtnShow: data
                            })
                        }}
                    />
                </div>
                <div 
                    className={[
                        isPC?styles.w440:APPstyles.wPer100,
                        isTablet&&isDetailShow?APPstyles.position_fixed:'',
                        isTablet&&isDetailShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                    ].join(' ')}
                    style={{
                        display:(isTablet&&isDetailShow)||isPC?'':'none'
                    }}
                >
                    <QuoteDetail 
                        {...this.props} 
                        isSector={true} // check if the quote page is in the sector page
                        ricData={ricData} 
                        tagIshowHeader={tagIshowHeader}
                        chgTabData={chgTabData}
                        callbackTradeShow={()=>{
                            this.props.callbackTradeShow('tag',ricData)
                        }}
                        callbackFbstockid={(data)=>{
                            // 判断fbstockid是否有值，没有则隐藏isTradeShow
                            this.props.callbackFbstockid(data)
                        }}
                        callbackTab={(data)=>{
                            //top--tab栏切换
                            this.props.callbackTab(data)
                        }}
                        cbCloseDetailPage={(data)=>{
                            this.setState({
                                isDetailShow:data
                            })
                            this.props.cbDetailPageShow(false)
                        }}
                        cbCghTradeData={(data)=>{
                            this.props.cbCghTradeData(data)
                        }}
                        cbIsShowHeaderPage={(data)=>{
                            this.props.cbIsShowHeaderPage(data)
                        }}
                        cbShowDetailData={(data)=>{
                            this.props.cbShowDetailData(data)
                        }}
                        cbRightDetailShowFun={(data,loading)=>{
                          this.props.cbRightDetailShowFun(data,loading)
                        }}
                        cbSetWatchListFun={(data,tag,id)=>{
                          this.props.cbSetWatchListFun(data,tag,id)
                        }}
                    />
                </div>
            </div>
        )}
}
export default withTranslation()(observer(Screener))
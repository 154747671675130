import React from 'react'
import { Button,Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import { 
    bellOffImg,
    bellOnImg,
    upImg,
    downImg
} from '@/asserts/imgs/common/index'
import { 
    starOn1Img,
    starLightImg,
    starOffImg
} from '@/asserts/imgs/quote/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let timer=null

class MsgCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isSwitchStar:false,
            isStartShow:false,
            isCghTradeData:false,
            indexData:[0,1,4,5,6,7]
        }
    }
    bellClick(){
        const {ricData}=this.props
        this.props.callbackAlertsShow('alerts', ricData)
    }
    //点亮星星并添加到watchlist_id=1/取消，tab栏并定位到 watchlist_id=1
    startClick(){
        const {ricData,currentIndex,isLoading,watchListData}=this.props
        const {isStartShow,isSwitchStar,indexData}=this.state
        if(isLoading||!ricData||JSON.stringify(ricData) == '{}') return
        let index=watchListData.findIndex(item=>item['watchlist_id']==1)
        // 取消
        if(isStartShow){
            this.setState({
                isStartShow:false
            })
            this.props.cbSetWatchListFun(ricData['ric'],'del',1)
        }else{
            // 添加
            this.setState({
                isStartShow:true
            })
            this.props.cbSetWatchListFun(ricData['ric'],'add',1)
        }
        // 处理数据
        this.props.callbackTab(Number(index)+4)
        this.props.cbChgWatchlistIdFun('1')
        this.setState(state => ({
            isSwitchStar: !state.isSwitchStar
        }))
        if(indexData.includes(currentIndex)){
            sessionStorage.setItem('ChgAddData',true)
            this.props.cbSwitchStar(isSwitchStar)
        }
    }
    // 更改star状态---Favourite button是用來加顯示中的股票到watchlist_id=1,如顯示中的股票已經在watchlist_id=1, 那就會亮著,如顯示中的股票不在watchlist_id=1, 按下去就會變成亮著,並加到watchlist_id=1
    getWatchlist1DataFun(ricData){
        const {watchListData}=this.props
        let getData=watchListData.length?watchListData.find(item=>item['watchlist_id']==1):[]
        let getRicData=getData&&getData['stock_id_list']
        if(getRicData&&getRicData.length){
            for(let i in getRicData){
                if(getRicData[i]==ricData['ric']){
                    this.setState({
                        isStartShow:true
                    })
                    break
                }else{
                    this.setState({
                        isStartShow:false
                    })
                } 
            }
           return 
        }
        this.setState({
            isStartShow:false
        })
    }
    //trade页面--显示
    tradeShowClick(){
        const {ricData,currentIndex}=this.props
        const {isCghTradeData}=this.state
        this.props.callbackTradeShow('trade',ricData)
        if(currentIndex==2||currentIndex==3){
            this.setState(state => ({
                isCghTradeData: !state.isCghTradeData
            }))
            this.props.cbCghTradeData(isCghTradeData)
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {ricData,closeRightPageShow,isGetWatchListShow}=this.props
        //更改数据
        if(
            ricData!==newProps['ricData']||
            isGetWatchListShow!==newProps['isGetWatchListShow']
        ){
            if(ricData&&JSON.stringify(ricData) !== '{}'){
                this.setState({
                    isStartShow:false
                })
                this.getWatchlist1DataFun(ricData)
            }
        }
        // 切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
        if(closeRightPageShow!==newProps['closeRightPageShow']){
            this.setState({
                isStartShow: false
            })
        }
    }
    //卸载
    componentWillUnmount() {
      clearInterval(timer)
    }
    render(){
        const {t,ChgThemeData,ChgLangData,data,isRightPageShow,store,isLoading,chgTabName,isMobile,isTablet,currentIndex} = this.props
        const {isStartShow}=this.state
        let streamingData=data&&store.getDataByRic(data['ric'])
        return (
            <div 
                className={[
                    styles.msg_con,
                    ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                ].join(' ')}
                style={{
                    paddingTop:isMobile?0:15
                }}
            >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                <div className={APPstyles.flex_row}>
                    <div 
                        className={[
                            styles.msg_con_left,
                            APPstyles.flex_1
                        ].join(' ')}
                    >
                        <span className={[styles.msg_con_cur,APPstyles.color_blue].join(' ')}>{data['name']}</span>
                        <div className={[styles.msg_con_name,APPstyles.flex_row,isMobile?APPstyles.fz13:'',ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{width:isTablet?'94%':'96%'}}>
                            <span style={{marginBottom:ChgLangData=='en-US'?2:4}}>
                                {data['display_exchange_code']}: {data['symbol']}
                            </span>
                            <span 
                                className={[
                                    isTablet?APPstyles.ellipsis:'',
                                    isTablet?APPstyles.flex_1:''
                                ].join(' ')}
                            >
                                {t(ChgLangData=='zh-CN'?data['trbc_name_cht']:data['trbc_name_en'])}
                            </span>
                        </div>
                    </div>
                    <div 
                        className={[
                            APPstyles.flex_row,
                            styles.msg_con_btncon
                        ].join(' ')}
                        style={{
                            minWidth:!isMobile?typeof(data['fbstockid'])=='undefined'||data['fbstockid']==null?'auto':isRightPageShow=='trade'?124:32:50
                        }}
                    >
                        {/* hide phase 2b item */}
                        <div 
                            className={[
                                APPstyles.flex_center,
                                styles.quote_bellicon,
                                ChgThemeData=='Light'?APPstyles.bg_star_light:APPstyles.bg_star_off,
                                ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.star_black_hover
                            ].join(' ')}
                            onClick={this.bellClick.bind(this)} 
                        >
                            <img 
                                src={ChgThemeData=='Light'?bellOffImg:bellOnImg} 
                                className={APPstyles.img24} 
                                alt='start' 
                            />
                        </div>
                        <div 
                            className={[
                                APPstyles.flex_center,
                                styles.quote_startcon,
                                isStartShow?APPstyles.bg_star_on:ChgThemeData=='Light'?APPstyles.bg_star_light:APPstyles.bg_star_off,
                                isStartShow?APPstyles.star_green_hover:ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.star_black_hover
                            ].join(' ')}
                            style={{
                                marginRight:(isTablet&&!isMobile&&(currentIndex==2||currentIndex==3))||isMobile?0:typeof(data['fbstockid'])=='undefined'||data['fbstockid']==null?0:8
                            }}
                            onClick={this.startClick.bind(this)} 
                        >
                            <img 
                                src={isStartShow?starOn1Img:ChgThemeData=='Light'?starLightImg:starOffImg} 
                                className={APPstyles.img24} 
                                alt='start' 
                            />
                        </div>
                        <div 
                            className={[
                                ((currentIndex!==2||currentIndex!==3)&&isMobile)||((currentIndex==2||currentIndex==3)&&isTablet)?styles.msg_con_tradebtn:'',
                                ((currentIndex!==2||currentIndex!==3)&&isMobile)||((currentIndex==2||currentIndex==3)&&isTablet)?ChgThemeData=='Light'?styles.msg_con_tradebtn_light:'':''
                            ].join(' ')}
                            style={{
                                display:typeof(data['fbstockid'])=='undefined'||data['fbstockid']==null||(isMobile&&chgTabName!=='quote')||((currentIndex==2||currentIndex==3)&&isTablet&&!isMobile&&chgTabName!=='quote')?'none':''
                            }}
                        >
                            <Button 
                                type='primary' 
                                style={{
                                    width:((currentIndex!==2||currentIndex!==3)&&isMobile)||((currentIndex==2||currentIndex==3)&&isTablet)?'94%':84,
                                    height:((currentIndex!==2||currentIndex!==3)&&isMobile)||((currentIndex==2||currentIndex==3)&&isTablet)?40:32,
                                    margin:'0 auto',
                                    fontSize:14
                                }}
                                className={[APPstyles.btn_blue,APPstyles.bg_blue].join(' ')}
                                onClick={this.tradeShowClick.bind(this)}
                            >
                                {t('Trade')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={[APPstyles.flex_row,styles.msg_con_turnover].join(' ')}>
                    <span 
                        className={
                            Number(streamingData&&streamingData['NETCHNG_1'])>0?APPstyles.color_red:Number(streamingData&&streamingData['NETCHNG_1'])<0?APPstyles.color_green:Number(streamingData&&streamingData['NETCHNG_1'])==0?APPstyles.color_grey:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        }
                    >
                        {common.transThousands(common.keepNum(streamingData&&streamingData['TRDPRC_1'],2))}
                    </span>
                    <img 
                        src={Number(streamingData&&streamingData['NETCHNG_1'])>0?upImg:downImg} 
                        style={{
                            width:13.86,
                            height:12,
                            cursor:'default',
                            display:Number(streamingData&&streamingData['NETCHNG_1'])>0||String(streamingData&&streamingData['NETCHNG_1'])<0?'':'none'
                        }} 
                    />
                    <span 
                        className={
                            Number(streamingData&&streamingData['NETCHNG_1'])>0?APPstyles.color_red:Number(streamingData&&streamingData['NETCHNG_1'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:Number(streamingData&&streamingData['NETCHNG_1'])==0?APPstyles.color_grey:APPstyles.color_white
                        }
                    >
                        {common.addplusNum(common.transThousands(common.keepNum(streamingData&&streamingData['NETCHNG_1'],2)))}
                    </span>
                    <span 
                        className={
                            Number(streamingData&&streamingData['PCTCHNG'])>0?APPstyles.color_red:Number(streamingData&&streamingData['PCTCHNG'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:Number(streamingData&&streamingData['PCTCHNG'])==0?APPstyles.color_grey:APPstyles.color_white
                        }
                    >
                        {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(streamingData&&streamingData['PCTCHNG'],2))))}
                    </span>
                    <span 
                        style={{
                            display:isMobile?'none':'',
                            minWidth:64
                        }}
                    >
                        {common.changeFormatDate(streamingData&&streamingData['TRADE_DATE'])}
                    </span>
                </div>
                <div 
                    style={{
                        display:isMobile?'':'none',
                        marginTop:4
                    }}
                >
                    {common.changeFormatDate(streamingData&&streamingData['TRADE_DATE'])}
                </div>
            </div>
        )}
}
export default withTranslation()(observer(MsgCon))
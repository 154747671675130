import React from 'react';
import {withTranslation} from 'react-i18next';
import * as XLSX from 'xlsx';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  // 以excel的格式把api数据下载下来
  exportToExcel = (apiData) => {
    const {ChgLangData}=this.props;
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = XLSX.utils.book_new();
    // 向工作簿添加工作表，并命名
    XLSX.utils.book_append_sheet(wb, ws, 'Income Data');
    // 定义导出的文件名
    let exportFileName = ChgLangData === 'zh-CN' ? '海外所得' : 'Overseas Income';
    // 写出工作簿（这里以二进制字符串形式）
    XLSX.writeFile(wb, exportFileName+'.xlsx');
  }
  componentDidUpdate(newProps){
    const {isExport, history, ChgLangData}=this.props;
    if(isExport !== newProps['isExport']){
      if(history && history.length>0){
        let translatedHistoryData;
        if(ChgLangData === 'zh-CN'){
          // 定义键的翻译
          const keyTranslation = {
            year: '年',
            overseas_profit_income: '營利所得',
            overseas_interest_income: '利息所得',
            overseas_transaction_proceeds: '財產交易所得',
            china_profit_income: '營利所得',
            china_interest_income: '利息所得',
            china_transaction_proceeds: '財產交易所得',
          };
          // 重新映射数据
          translatedHistoryData = history.map(record => {
            let newRecord = {};
            for(let key in record){
              newRecord[keyTranslation[key]] = record[key];
            }
            return newRecord;
          });
        } else {
          translatedHistoryData = history;
        }
        this.exportToExcel(translatedHistoryData);
      }
    } 
  }  
  render() {
    const {t,ChgThemeData,isSearchPageShow,isLoading,history} = this.props;
    return (
      <div
        ref={el => this.container = el}
        className={[
          styles.OverseasIncomeCon,
          isSearchPageShow?APPstyles.flex1_auto:'',
          isSearchPageShow?history.length?APPstyles.paddingBottom40:'':'',
        ].join(' ')}
      >
        <div className={[styles.header,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <div className={[styles.dateY,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark].join(' ')}>{t('Year')}</div>
          <div className={[styles.overseasL,APPstyles.flex_1,ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark].join(' ')}>
            <div className={[styles.title,APPstyles.flex_center,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
              {t('Overseas Income1')}
            </div>
            <div className={[APPstyles.flex_row,styles.subTitle].join(' ')}>
              <span>{t('Profit Income')}</span>
              <span>{t('Interest Income')}</span>
              <span>{t('Transaction Proceeds')}</span>
            </div>
          </div>
          <div className={[styles.overseasL,APPstyles.flex_1].join(' ')}>
            <div className={[styles.title,APPstyles.flex_center,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
              {t('Mainland China Income')}
            </div>
            <div className={[APPstyles.flex_row,styles.subTitle].join(' ')}>
              <span>{t('Profit Income')}</span>
              <span>{t('Interest Income')}</span>
              <span>{t('Transaction Proceeds')}</span>
            </div>
          </div>
        </div>
        <div className={history.length?'':APPstyles.minH300} style={{position:!history.length&&!isLoading?'relative':'unset'}}>
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length>0?
            history.map((item,index) => {
              return  <div
                        key={index} 
                        className={[
                          styles.body,
                          APPstyles.flex_row,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                        ].join(' ')}
                      >
                        <span className={[styles.dateY,APPstyles.flex_row].join(' ')}>{t(item['year'])}</span>
                        <div className={[styles.OverseasCommon,APPstyles.flex_topcenter,APPstyles.flex_1].join(' ')}>
                          <span>{t(common.transThousands(common.keepNum(item['overseas_profit_income'],0)))}</span>
                          <span>{t(common.transThousands(common.keepNum(item['overseas_interest_income'],2)))}</span>
                          <span>{t(common.transThousands(common.keepNum(item['overseas_transaction_proceeds'],0)))}</span>
                        </div>
                        <div className={[styles.OverseasCommon,APPstyles.flex_topcenter,APPstyles.flex_1].join(' ')}>
                          <span>{t(common.transThousands(common.keepNum(item['china_profit_income'],2)))}</span>
                          <span>{t(common.transThousands(common.keepNum(item['china_interest_income'],2)))}</span>
                          <span>{t(common.transThousands(common.keepNum(item['china_transaction_proceeds'],2)))}</span>
                        </div>
                      </div>
            }):
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>    
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);
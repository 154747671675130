import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import common from '@/asserts/js/common'
import { 
  leftLightImg,
  leftImg,
  checkOffImg
} from '@/asserts/imgs/common/index'
import { 
  menuOffImg
} from '@/asserts/imgs/nav/index'
import { 
  checkOnImg,
  arrowDownImg,
  arrowDownOnImg,
  arrowDownOnLightImg,
  arrowUpImg,
  arrowUpOnImg,
  arrowUpOnLightImg,
  menuLightOnImg,
  menuHoverImg,
  delBigImg,
  delBigOnImg
} from '@/asserts/imgs/quote/index'
import APPstyles from '@/App.less'
import comStyles from '../List/index.less'
import styles from './index.less'

class EditCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isToggleOn: false,
      listData:[],
      detNum:0,
      isLoading:true,
      editTitle:'Watchlist1',
      currentIndexData:[0,1,2,3]
    }
  }
  reOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items = this.reOrder(
      this.state.listData,
      result.source.index,
      result.destination.index
    )
    this.setState({
      listData:items
    })
  }
  //上移到第一位/下移到最后一位
  moveClick(data,index,status){
    const {listData}=this.state
    if(status=='up'){
      listData.splice(index,1)
      listData.unshift(data)
    }else{
      listData.splice(index,1)
      listData.push(data)
    }
    this.setState({
      listData:listData
    })
  }
  //back/save
  backClick(msg){
    const {currentIndex,watchListData} = this.props
    const {listData,isLoading}=this.state
    let getRicData=[]
    //处理数据
    if(msg=='save'){
      if(isLoading) return
      this.setState({
        isLoading:true
      })
      let getData=watchListData[Number(currentIndex)-4]
      // 删除选中的数据
      if(listData.length){
        for(let i in listData){
          getRicData.push(listData[i]['ric'])
        }
        getData['stock_id_list']=getRicData
        this.setWatchListDataFun(watchListData)
      // 全删除
      }else{
        getData['stock_id_list']=[]
        this.setWatchListDataFun(watchListData)
      }
      return
    }
    // 取消/返回
    this.props.callbackLeftPageShow('list')
  }
  setWatchListDataFun(data){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => {
      this.props.callbackLeftPageShow('list')
      this.props.cbOnLoadGetWatchlistFun()
      this.setState({
        isLoading:false
      })
    })
  }
  //单选
  checkedClick(index){
    const {listData}=this.state
    let count = 0 //定义一个计数器
    for(let i in listData){
      if(index==i){
        listData[i]['checked']=listData[i]['checked']===false?true:false
      }
      //全选/全不选
      if(!listData[i]['checked']){
        this.setState({
          isToggleOn: false,
          detNum:count
        })
      }else{
        count++
        this.setState({
          detNum:count
        })
      }
      //判断是否都是选中状态/如果是则自动选中全选按钮
      if(count === listData.length){
        this.setState({
          isToggleOn: true,
          detNum:count
        })
      }
    }
    this.setState({
      listData: listData
    })
  }
  //全选/全不选
  checkedAllClick(){
    const {isToggleOn,listData}=this.state
    if(listData.length<=0) return
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }))
    for(let i in listData){
      listData[i]['checked']=isToggleOn?false:true
    }
    this.setState({
      listData: listData,
      detNum:isToggleOn?0:listData.length
    })
  }
  //删除
  delClick(){
    const {listData,detNum}=this.state
    if(listData.length<=0||detNum==0) return
    if(listData.length==detNum){
      this.setState({
        listData: [],
        detNum:0,
        isToggleOn:false
      })
      return
    }
    let newData=[]
    for(let i in listData){
      if(!listData[i]['checked']){
        newData.push(listData[i])
      }
    }
    this.setState({
      listData: newData,
      detNum:0,
    })
  }
  //更改拖拽背景色
  getItemStyle = (isDragging, draggableStyle) => (
    {
      userSelect: 'none',
      background: isDragging ? this.props.ChgThemeData=='Light'?'#E0E0E0':'#000000' : 'none',
      ...draggableStyle
    }
  )
  //获取数据
  onLoadFun(){
    const {currentIndex,getListData,tabsData}=this.props
    //处理数据
    if(getListData.length){
      let getResData=[]
      for(let i in getListData){
        getResData.push({
          symbol:typeof(getListData[i]['symbol'])=='undefined'?getListData[i]['ticker']:getListData[i]['symbol'],
          name:typeof(getListData[i]['name'])=='undefined'?getListData[i]['name_en']:getListData[i]['name'],
          checked:false,
          id: 'item-'+i,
          imgUp:arrowUpImg,
          imgDown:arrowDownImg,
          imgMove:menuOffImg,
          ric:getListData[i]['ric']
        })
      }
      this.setState({
        listData: getResData,
        isLoading:false,
        editTitle:tabsData[currentIndex]['name']
      })
      return
    }
    this.setState({
      listData: [],
      isLoading:false,
      editTitle:tabsData[currentIndex]['name']
    })
  }
  //初始化
  componentDidMount(){
    this.onLoadFun()
  }
  render(){
    const {t,ChgThemeData,isLeftPageShow,isMobile,isPC} = this.props
    const {listData,isToggleOn,detNum,isLoading,editTitle}=this.state
    return (
      <div 
        className={[
          APPstyles.flex_column,
          comStyles.list_con,
          isMobile&&isLeftPageShow=='stocksEdit'?APPstyles.position_fixed:'',
          isMobile&&isLeftPageShow=='stocksEdit'?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
        ].join(' ')} 
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        <div 
          className={[
            APPstyles.flex_row,
            styles.edit_title,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <img 
            src={ChgThemeData=='Light'?leftLightImg:leftImg} 
            className={APPstyles.img24} 
            onClick={this.backClick.bind(this,'back')} 
            alt='back' 
          />
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t(editTitle)}</span>
        </div>
        <div 
          className={[
            comStyles.history_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
          ].join(' ')}
          style={{
            top:48
          }}
        >
          {t('Edit Stock')}
        </div>
        {/* 列表---拖拽排序 */}
        <div 
          className={[
            comStyles.table_body,
            APPstyles.flex1_auto,
            isPC?APPstyles.paddingBottom60:''
          ].join(' ')}
        >
        {
          listData.length?
        
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable' direction='vertical'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {listData.map((item, index) => (
                      <Draggable key={item['id']} draggableId={item['id']} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className={[
                              APPstyles.flex_row,
                              comStyles.body_one,
                              styles.body_one_edit,
                              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                              ChgThemeData=='Light'?APPstyles.bg_event_light:''
                            ].join(' ')}
                          >
                            <img 
                              src={item['checked']?checkOnImg:checkOffImg} 
                              alt='check'
                              className={isPC?APPstyles.img16:APPstyles.img24} 
                              onClick={this.checkedClick.bind(this,index)}
                            />
                            <div className={APPstyles.flex_column} style={{width: '',flex:1}}>
                              <span 
                                className={[
                                  comStyles.list_name,
                                  ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                ].join(' ')}
                                style={{
                                  marginLeft:0
                                }}
                              >
                                {item['symbol']}
                              </span>
                              <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:'',comStyles.list_cur].join(' ')}>{t(item['name'])}</span>
                            </div>
                            <img 
                              src={item['imgUp']} 
                              alt='up'
                              className={isPC?APPstyles.img16:APPstyles.img24} 
                              style={{
                                display:Number(index)==0?'none':'',
                                marginRight:20
                              }}
                              onClick={this.moveClick.bind(this,item,index,'up')} 
                              onMouseOver={()=>{
                                if(!isPC) return
                                let imgurl=ChgThemeData=='Light'?arrowUpOnLightImg:arrowUpOnImg
                                listData[index]['imgUp']=imgurl
                                this.setState({
                                  listData:listData
                                })
                              }} 
                              onMouseOut={()=>{
                                if(!isPC) return
                                listData[index]['imgUp']=arrowUpImg
                                this.setState({
                                  listData:listData
                                })
                              }}
                            />
                            <img 
                              src={item['imgDown']} 
                              alt='down'
                              className={isPC?APPstyles.img16:APPstyles.img24} 
                              style={{
                                display:Number(index)==Number(listData.length-1)?'none':'',
                                marginRight:20
                              }}
                              onClick={this.moveClick.bind(this,item,index,'down')}  
                              onMouseOver={()=>{
                                if(!isPC) return
                                let imgurl=ChgThemeData=='Light'?arrowDownOnLightImg:arrowDownOnImg
                                listData[index]['imgDown']=imgurl
                                this.setState({
                                  listData:listData
                                })
                              }} 
                              onMouseOut={()=>{
                                if(!isPC) return
                                listData[index]['imgDown']=arrowDownImg
                                this.setState({
                                  listData:listData
                                })
                              }}
                            />
                            <img 
                              src={item['imgMove']} 
                              alt='move' 
                              className={isPC?APPstyles.img16:APPstyles.img24}  
                              onMouseOver={()=>{
                                if(!isPC) return
                                let imgurl=ChgThemeData=='Light'?menuLightOnImg:menuHoverImg
                                listData[index]['imgMove']=imgurl
                                this.setState({
                                  listData:listData
                                })
                              }} 
                              onMouseOut={()=>{
                                if(!isPC) return
                                listData[index]['imgMove']=menuOffImg
                                this.setState({
                                  listData:listData
                                })
                              }} 
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          :
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>  
        }
        </div>
        {/* footer */}
        <div 
          className={[
            APPstyles.flex_row,
            comStyles.footer_con,
            styles.footer_con_edit,
            ChgThemeData=='Light'?comStyles.footer_con_light:'',
            isMobile?comStyles.footer_env_mobile:'',
            // isMobile?APPstyles.sticky_bottom_mobile:''
          ].join(' ')}
        >
          <div className={APPstyles.flex_row} onClick={this.checkedAllClick.bind(this)}>
            <img src={isToggleOn?checkOnImg:checkOffImg} className={[comStyles.all_img,isPC?APPstyles.img16:APPstyles.img24].join(' ')} alt='add' />
            <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t('Select All')}</span>
          </div>
          <div className={APPstyles.flex_row} onClick={this.delClick.bind(this)}>
            <img src={ChgThemeData=='Light'?delBigImg:delBigOnImg} className={[comStyles.all_img,isPC?APPstyles.img16:APPstyles.img24].join(' ')} alt='edit' />
            <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t('Delete')}({detNum})</span>
          </div>
          <div className={APPstyles.flex_row} onClick={this.backClick.bind(this,'save')}>
            <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_blue}>{t('Save Changes')}</span>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(EditCon)
import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import Candlestick from '@/asserts/js/charts/candlestick'
import APPstyles from '@/App.less'
import styles from './index.less'

class ChartCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            headerData:['1 Month','3 Month','6 Month','1 Year'],
            isLoadingChart:false,
            chartData:[[],[],[]],
            title:''
        }
    }
    //获取数据--图表
    getChartData(ricdata){
        let token=window.sessionStorage.getItem('token')
        let SMA1=5,SMA2=25
        // 数据清空
        this.setState({
            chartData:[[],[],[]]
        })
        fetch(common.getHttpChart+'bigchart',{
            method:'POST',
            headers:{
                'Accept':'application/json,text/plain,*/*',
                'Content-Type':'application/json'
            },
            body:JSON.stringify({token:token,ric:ricdata['ric']})
        })
        .then(res => res.json())
        .then(res => {
            //获取数据成功
            if(res['status']==0){
                let resData=res['data']['datalist']
                let chartData=[],closeData=[],candlestickData=[],line0Data=[],line1Data=[]
                if(resData.length>0){
                    for(let i in resData){
                        let time=new Date(resData[i]['time'])
                        let newTime=time.getTime()
                        //完整的蜡烛图数组
                        chartData.push([
                            resData[i]['time']=newTime,
                            Number(resData[i]['open']),
                            Number(resData[i]['high']),
                            Number(resData[i]['low']),
                            Number(resData[i]['close'])
                        ])
                        //收盘价数组
                        closeData.push(Number(resData[i]['close']))
                    }
                    let MA1=common.calculateMA(SMA1,closeData)
                    let MA2=common.calculateMA(SMA2,closeData)
                    
                    // 如果均线长度一致，则是正常的，若长度不一致，则从后面加入数据
                    if(chartData.length-SMA2+1==MA2.length && chartData.length>50){
                        MA1=MA1.slice(SMA2-SMA1)
                        chartData= chartData.slice(SMA2-1)
                        for(let i=0;i<chartData.length;i++){
                            candlestickData.push(chartData[i])
                            line0Data.push([chartData[i][0],MA1[i]])
                            line1Data.push([chartData[i][0],MA2[i]])
                        }
                    }else{
                        // 先数组颠倒
                        MA1.reverse()
                        MA2.reverse()
                        chartData.reverse()
                        for(let i=0;i<chartData.length;i++){
                            candlestickData.push(chartData[i])
                            line0Data.push([chartData[i][0],MA1[i]])
                            line1Data.push([chartData[i][0],MA2[i]])
                        }
                        candlestickData.reverse()
                        line0Data.reverse()
                        line1Data.reverse()
                        //如果数组长度大于50,则只显示50个数据点
                        if(candlestickData.length>50){
                            candlestickData.slice(candlestickData.length-50)
                            line0Data.slice(candlestickData.length-50)
                            line1Data.slice(candlestickData.length-50)
                        }
                    }
                    this.get3chartDataFun(candlestickData.reverse(),line0Data.reverse(),line1Data.reverse())
                    return
                }
                this.setState({
                    isLoadingChart:false,
                    chartData:[[],[],[]]
                })
                return
            }
            //获取失败
            this.setState({
                isLoadingChart: false,
                chartData:[[],[],[]]
            })
        }).catch(err => {
            this.setState({
                isLoadingChart: false,
                chartData:[[],[],[]]
            })
        })
    }
    //获取前3个月的数据
    get3chartDataFun(ckData,line0Data,line1Data){
        let newCkData=[],newLine0Data=[],newLine1Data=[]
        for(let i in ckData){
            newCkData[i]=ckData[i]
            newLine0Data[i]=line0Data[i]
            newLine1Data[i]=line1Data[i]
            if(ckData[i][0]==common.minDateFun(3)){
                break 
            }
        }
        this.setState({
            isLoadingChart:false,
            chartData:[newCkData.reverse(),newLine0Data.reverse(),newLine1Data.reverse()]
        })
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {msgData}=this.props
        //更改数据
        if(msgData!==newProps['msgData']){
            this.setState({
                isLoadingChart: true,
                title:msgData['name_en']
            })
            this.getChartData(msgData)
        } 
    }
    render(){
        const {t,ChgThemeData,id,monthsData,isMobile} = this.props
        const {isLoadingChart,chartData,title,headerData}=this.state
        return (
            <div>
                <div className={styles.chart}>
                    {/* 加载中 */}
                    <div className={APPstyles.isLoading}><Spin spinning={isLoadingChart} /></div>
                    {
                        chartData[0].length?
                            <Candlestick chartData={chartData} id={id} title={title} {...this.props} />
                        :
                            <div className={[APPstyles.isLoading,APPstyles.color_grey].join(' ')} style={{display:isLoadingChart?'none':'',zIndex:9}}>{t('Chart not available')}</div>  
                    }
                </div>
                <div>
                    <div className={[APPstyles.flex_row,styles.month_con,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''].join(' ')} style={{paddingBottom:0}}>
                        {
                            headerData.map((item,index) => {
                                return  <span key={index}>{t(item)}</span>
                            })
                        }
                        
                    </div>
                    <div className={[APPstyles.flex_row,styles.month_con].join(' ')}>
                        {
                            monthsData.map((item,index) => {
                                return  <span 
                                            key={index} 
                                            style={{backgroundColor:item['bgColor']}} 
                                            className={[
                                                styles.values,
                                                ChgThemeData=='Light'?styles.borderRight_light:styles.borderRight_dark
                                            ].join(' ')}
                                        >
                                            {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(item['val'],2))))}
                                        </span>
                            })
                        }
                        
                    </div>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(ChartCon))
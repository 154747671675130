import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Spin } from 'antd'
import common from "../../../../asserts/js/common";
import { 
  upImg,
  downImg
} from '../../../../asserts/imgs/common/index'
import ButtonBar from "../../ButtonBar";
import ScoreArea from "./charts/ScoreArea";
import PeerColumn from "./charts/PeerColumn";
import AnalystLine from "./charts/AnalystLine";
import RefinitivColumn from "./charts/RefinitivColumn";
import APPstyles from '../../../../App.less'
import styles from "./index.less";

const CompanyScore = (props) => {
  const { t } = useTranslation();
  const { ChgThemeData,allChartData,currentIndex,isRightPageShow,isPC,bodyW } = props;
  const [chartsData, setChartsData] = useState([])
  const [msg, setMsg] = useState('')
  const [getTitle, setGetTitle] = useState('')
  const [score, setScore] = useState('-')

  useEffect(() => {
    setChartsData(props['allChartData']['ScoreData']['StockData'])
    if(allChartData['AverageScore']&&allChartData['AverageScore']!==''&&typeof(allChartData['AverageScore'])!=='undefined'){
      let outlook=allChartData['AverageScore']['AVG_SCORE_OUTLOOK']
      if(outlook&&outlook.indexOf(':')==-1){
        setMsg(outlook)
        setGetTitle('')
      }else{
        let str0=outlook.split(':')
        let str1=common.valToUpperCase(str0[0])
        setGetTitle(str1)
        setMsg(str0[1])
      }
      let getScore=allChartData['AverageScore']['AVG_SCORE']?allChartData['AverageScore']['AVG_SCORE']:'-'
      setScore(getScore)
    }else{
      setMsg('')
      setScore('-')
      setGetTitle('')
    }
  }, [props['allChartData']['ScoreData']['StockData']])

  return (
    <div className={styles.company_score} style={{position:'relative'}}>
      {/* 加载中 */}
      <div className={APPstyles.isLoading}><Spin spinning={allChartData['loadingData'][0]} /></div>
      <div className={styles.stock_report_plus_header}>{t("Company Score1")}</div>
      <div
        className={[styles.stock_report_plus_content, styles.flex].join(" ")}
      >
        <div className={styles.left}>
          <div className={styles.rect}>
            <div className={[styles.number,score&&score!=='-'?styles.number_h:''].join(' ')} style={{marginBottom:score&&score!=='-'?10:8}}>{score}</div>
            <div className={styles.rect_text}>{t("overallScore")}</div>
          </div>
          <div className={[APPstyles.flex_column,styles.text].join(' ')}>
            <span 
              className={
                getTitle=='POSITIVE OUTLOOK'?APPstyles.color_red
                :getTitle=='NEGATIVE OUTLOOK'?APPstyles.color_green
                :APPstyles.color_AFADAD
              }
              style={{
                marginBottom:4,
                fontWeight:700
              }}
            >
              {t(getTitle)} {getTitle?':':''}
            </span>
            <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
              {msg}
            </span>
          </div>
        </div>

        <div 
          className={styles.right} 
          style={{
            position:'relative',
            minHeight:isRightPageShow!==''||currentIndex==3||!chartsData.length?200:'auto',
            right:isPC?bodyW<=1600?bodyW<=1560?20:10:0:0
          }}
        >
          {
            allChartData['ScoreData']['StockData'].length||allChartData['ScoreData']['SectorData'].length?
              <ScoreArea
                {...props}
                id='ScoreId'
                chartsData={chartsData}
              />
            :
              <div className={APPstyles.isLoading} style={{display:allChartData['loadingData'][0]?'none':'',zIndex:9}}>{t('Chart not available')}</div> 
          }
        </div>
      </div>
    </div>
  );
};

const PeerComparison = (props) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("1D"); // 1D, 1W, 3M, 6M
  const { dateData,allChartData,data,ChgThemeData } = props;
  const [chartsData, setChartsData] = useState([])
  let symbolName=data&&data['symbol']?data&&data['symbol']:'Stock'

  useEffect(() => {
    setChartsData(props['allChartData']['PeerComData'])
  }, [props['allChartData']['PeerComData']])

  return (
    <div className={styles.peer_comparison}>
      {/* 加载中 */}
      <div className={APPstyles.isLoading}><Spin spinning={allChartData['loadingData'][1]} /></div>
      <div className={styles.stock_report_plus_header}>
        {t("Peer Comparison")}
      </div>
      <div 
        className={styles.peer_comparison_legend}
        style={{
          display:chartsData.length?'':'none'
        }}
      >
        <div className={styles.item_wrapper}>
          <div className={[styles.legend_rect, styles.ric].join(" ")}></div>
          <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{symbolName}</span>
        </div>
        <div className={styles.item_wrapper}>
          <div className={styles.legend_rect}></div>
          <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{t('Peers')}</span>
        </div>
      </div>
      <ButtonBar
        arr={chartsData.length?[
          { label: "1D", id: "1D"},
          { label: "1W", id: "1W"},
          { label: "3M", id: "3M"},
          { label: "6M", id: "6M"},
        ]:[]}
        selected={tab}
        onClick={(evt) => {
          if(allChartData['loadingData'][1]||!chartsData.length) return
          setTab(evt.target.id)
          let keyData = dateData.find((x) => x['name'] == evt['target']['id'])
          props.cbTimeData(allChartData['PeerComAlltData'],keyData['key'],symbolName)
        }}
      />
      {
        chartsData.length?
          <PeerColumn
            {...props}
            id='PeerComId'
            chartsData={chartsData}
          />
        :
          <div className={APPstyles.isLoading} style={{display:allChartData['loadingData'][1]?'none':'',zIndex:9}}>{t('Chart not available')}</div> 
      }
    </div>
  );
};

const AnalystPriceTarget = (props) => {
  const { t } = useTranslation();
  const { ChgThemeData, ChgLangData,allChartData,data,isMobile } = props;
  const AnalystData=allChartData['AnalystPriceTarget']
  const symbolName=data&&data['symbol']?data['symbol']:'Stock'
  const [TargetPricePremium, setTargetPricePremium] = useState(0)

  useEffect(() => { 
    let TargetPricePremium=AnalystData&&typeof(AnalystData)!=='undefined'?AnalystData['TargetPricePremium']:0
    setTargetPricePremium(TargetPricePremium)
  }, [props['allChartData']['AnalystPriceTarget']])

  return (
    <div className={styles.analyst_price_target}>
      {/* 加载中 */}
      <div className={APPstyles.isLoading}><Spin spinning={allChartData['loadingData'][2]} /></div>
      <div className={styles.stock_report_plus_header}>
        {t("Analyst Price Target")}
      </div>
      <div className={styles.stock_report_plus_content}>
        <div 
          className={styles.data_section}
          style={{
            display:TargetPricePremium?'':'none'
          }}
        >
          <span className={[styles.data_section_price,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>
            {common.transThousands(common.keepNum(AnalystData&&AnalystData['mean'],2))}
          </span>
          <img 
            src={Number(TargetPricePremium)>0?upImg:downImg} 
            style={{
              width:12,
              height:11,
              display:Number(TargetPricePremium)>0||String(TargetPricePremium)<0?'':'none'
            }} 
          />
          <div
            className={[
              styles.data_section_netchange,
              Number(TargetPricePremium)>0?APPstyles.color_red:Number(TargetPricePremium)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
            ].join(' ')}
          >
            {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(TargetPricePremium,2))))}&nbsp; 
            {Number(TargetPricePremium)>0?t('Upside'):Number(TargetPricePremium)<0?t('Downside'):''}
          </div>
        </div>
        <div style={{minHeight:180,paddingRight:isMobile?0:16,paddingBottom:10}}>
          {
            TargetPricePremium?
              <AnalystLine
                {...props}
                id='AnalystId'
                TargetPricePremium={TargetPricePremium}
              />
            :
              <div className={[APPstyles.flex_center,styles.noDatas].join(' ')} style={{display:allChartData['loadingData'][2]?'none':'',zIndex:9}}>{t('Chart not available')}</div> 
          }
        </div>
        <div className={[styles.text,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{display:TargetPricePremium?'':'none',paddingTop:5}}>
          {
            ChgLangData=='zh-CN'?
              <div>
                {symbolName} {Number(TargetPricePremium)<0?'高於':'低於'}均價，有&nbsp;  
                <span className={Number(TargetPricePremium)>0?APPstyles.color_red:Number(TargetPricePremium)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white}>
                  {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(TargetPricePremium,2))))}
                  </span> 
                  &nbsp; 的{Number(TargetPricePremium)<0?'下降':'上升'}潛力。
              </div>
            :
              <div>
                {symbolName} has a potential {Number(TargetPricePremium)<0?'downside':'upside'} of&nbsp; 
                <span className={Number(TargetPricePremium)>0?APPstyles.color_red:Number(TargetPricePremium)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white}>
                  {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(TargetPricePremium,2))))}
                </span> 
                &nbsp; trading {Number(TargetPricePremium)<0?'above':'below'} average price.
              </div>
          }
        </div>
      </div>
    </div>
  );
};

const RefinitivBrokerRating = (props) => {
  const { t } = useTranslation();
  const { ChgThemeData, ChgLangData,allChartData,ratingColor } = props;
  const [chartsData, setChartsData] = useState([])
  let REC_CNT=allChartData['REC_CNT']

  useEffect(() => {
    setChartsData(props['allChartData']['RefinitivData'])
  }, [props['allChartData']['RefinitivData']])

  return (
    <div className={styles.broker_rating} style={{position:'relative'}}>
      {/* 加载中 */}
      <div className={APPstyles.isLoading}><Spin spinning={allChartData['loadingData'][3]} /></div>
      <div className={styles.stock_report_plus_header}>
        {t("REFINITIV Broker Rating")}
      </div>
      <div className={styles.stock_report_plus_content}>
        <div style={{minHeight:180}}>
          {
            chartsData.length?
              <RefinitivColumn
                {...props}
                id='REFINITIVId'
                chartsData={chartsData}
              />
            :
              <div className={[APPstyles.flex_center,styles.noDatas].join(' ')} style={{display:allChartData['loadingData'][3]?'none':'',zIndex:9}}>{t('Chart not available')}</div> 
          }
        </div>
        <div className={[styles.text,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{display:chartsData.length?'':'none'}}>
          {
            ChgLangData=='zh-CN'?
              <div>
                市場共&nbsp; 
                <span style={{color:ratingColor}}>
                  {common.showZero(common.transThousands(REC_CNT))}
                </span> 
                &nbsp;位分析師提供評級，採用5種標準化分級制。
              </div> 
            :
              <div>
                Base on &nbsp;  
                <span style={{color:ratingColor}}>
                  {common.transThousands(REC_CNT)}
                  </span> 
                  &nbsp; Analyst Rating. Mean recommendation from all analysis covering the company on a standardized 5-point scale.
              </div>
          }
        </div>
      </div>
    </div>
  );
};

const QuoteStockReportPlus = (props) => {
  const { t,ChgThemeData,currentIndex,isRightPageShow,isTablet,isPC,isMobile } = props;
  return (
    <div
      className={[
        styles.stock_report_plus,
        ChgThemeData === "Light" ? styles.light : "",
        isTablet||(isPC&&(isRightPageShow!==''||currentIndex==3)) ? styles.tablet : "",
        isMobile||(isPC&&(isRightPageShow!==''||currentIndex==3)) ? styles.mobile : "",
      ].join(" ")}
    >
      <div className={styles.section}>
        <CompanyScore {...props} />
        <PeerComparison {...props} />
      </div>
      <div className={styles.section}>
        <AnalystPriceTarget {...props} />
        <RefinitivBrokerRating {...props} />
      </div>
      <div className={styles.disclaimer_con}>
        {t('Disclaimer')}
        <div style={{paddingTop:6}}>
        {t('The information contained herein was obtained from third parties , which we believe to be reliable, but whose accuracy and completeness we do not guarantee. The opinions and recommendations herein do not take into account the investment objectives, financial situation and particular needs of the reader. Opinions expressed are subject to change without notice. Any duplication or redistribution of the information is prohibited. All rights reserved by Fubon Securities.')}</div>
      </div>
    </div>
  );
};

CompanyScore.propTypes =
  PeerComparison.propTypes =
  AnalystPriceTarget.propTypes =
  RefinitivBrokerRating.propTypes =
    {
      //one more: fetch data object
      bodyW: PropTypes.number.isRequired,
      minbodyW: PropTypes.number.isRequired,
      mobilebodyW: PropTypes.number.isRequired,
      ChgThemeData: PropTypes.string.isRequired,
      ChgLangData: PropTypes.string.isRequired,
    };

QuoteStockReportPlus.propTypes = {
  bodyW: PropTypes.number.isRequired,
  minbodyW: PropTypes.number.isRequired,
  mobilebodyW: PropTypes.number.isRequired,
  ChgThemeData: PropTypes.string.isRequired,
  ChgLangData: PropTypes.string.isRequired,
};

export default QuoteStockReportPlus;

import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import common from "@/asserts/js/common";
import useIsFirstRender from "@/components/Hooks/useIsFirstRender";
import DropDown from "@/components/DropDown";
import NumberCon from "@/components/Trade/Number";
import PriceModal from "@/components/Trade/PriceModal";
// import { ReactComponent as IconHelp } from "@/asserts/imgs/svg/help-circle.svg";
import { ReactComponent as IconInfo } from "@/asserts/imgs/svg/info_24px.svg";
import { ReactComponent as IconClose } from "@/asserts/imgs/svg/close_24px.svg";
import { ReactComponent as IconUp } from "@/asserts/imgs/svg/Polygon_up.svg";
import { ReactComponent as IconDown } from "@/asserts/imgs/svg/Polygon_down.svg";

import styles from "./CreateAlert.less";
import APPstyles from "@/App.less";
import { downImg, upImg } from "@/asserts/imgs/common/index";

const CreateAlert = (props) => {
  const { t } = useTranslation();
  const isFirstRender = useIsFirstRender();
  const {
    store,
    bodyW,
    minbodyW,
    mobilebodyW,
    ChgThemeData,
    ChgLangData,
    tradeData,
    isTablet,
    isMobile,
    sliderIndex,
    alertDetail, // for edit alert
  } = props;

  const [inputNameVal, setInputNameVal] = useState("");
  const [optonsNameShow, setOptonsNameShow] = useState("");
  const [isDownLoading, setIsDownLoading] = useState("");
  const [optonsNameIndex, setOptonsNameIndex] = useState("");
  const [disabledData, setDisabledData] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [isnameShow, setIsnameShow] = useState("");
  const [changeImg, setChangeImg] = useState("");
  const [priceNums, setPriceNums] = useState("");
  const [targetPercentage, setTargetPercentage] = useState("");
  // const [Amount, setAmount] = useState("");
  const [quantityNums, setQuantityNums] = useState("");
  const [isPriceModalShow, setIsPriceModalShow] = useState();
  const [priceModalTag, setPriceModalTag] = useState("");
  // const [listData, setListData] = useState({});
  const [ricData, setRicData] = useState();
  const [typeIndex, setTypeIndex] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [optionsNameData, setOptionsNameData] = useState([]);
  const [QtyHeld, setQtyHeld] = useState();
  const [timer, setTimer] = useState();
  // button selections
  const [buySellIndex, setBuySellIndex] = useState("");
  const [selectedAlert, setSelectedAlert] = useState(-1);
  const [selectedCondition, setSelectedCondition] = useState(-1);
  const [selectedGoodUntil, setSelectedGoodUntil] = useState(-1);

  const [isShowMaxAlertModal, setIsShowMaxAlertModal] = useState(false);

  const minW450 = 450;
  const ARR_ALERT = [
    {
      name: t("alerts.priceChange"),
      key: t("alerts.priceChange"),
    },
    {
      name: t("alerts.percentageChange"),
      key: t("alerts.percentageChange"),
    },
  ];

  const ARR_CONDITION = [
    {
      name: selectedAlert === 1 ? t("alerts.abovePercent") : t("alerts.above"),
      key: selectedAlert === 1 ? t("alerts.abovePercent") : t("alerts.above"),
    },
    {
      name: selectedAlert === 1 ? t("alerts.belowPercent") : t("alerts.below"),
      key: selectedAlert === 1 ? t("alerts.belowPercent") : t("alerts.below"),
    },
  ];

  const ARR_GOOD_UNTIL = [
    {
      name: t("alerts.today"),
      key: t("alerts.today"),
    },
    {
      name: t("alerts.cancelled"),
      key: t("alerts.cancelled"),
    },
  ];

  let streamingData = store.getDataByRic(ricData?.listData["ric"]);
  let isNotSelectedAll =
    selectedAlert === -1 ||
    selectedCondition === -1 ||
    selectedGoodUntil === -1 ||
    (selectedAlert === 0 && !priceNums) ||
    (selectedAlert === 1 && !targetPercentage);

  useEffect(() => {
    if (alertDetail) {
      let isPrice =
        alertDetail.targetPrice[alertDetail.targetPrice.length - 1] !== "%";
      changeRicFun(alertDetail.ric);
      setSelectedAlert(isPrice ? 0 : 1);
      setSelectedCondition(alertDetail.condition === "above" ? 0 : 1);
      setSelectedGoodUntil(alertDetail.goodUntil === "today" ? 0 : 1);
      if (isPrice) {
        setPriceNums(Number(alertDetail.targetPrice));
      } else {
        setTargetPercentage(Number(alertDetail.targetPrice.split("%")[0]));
      }
    } else {
      changeRicFun(props.ricData?.ric);
    }
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (isDisable) {
      setSelectedAlert(-1);
      setSelectedCondition(-1);
      setSelectedGoodUntil(-1);
    }
  }, [isDisable]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    changeRicFun(props.ricData?.ric);
  }, [props.ricData?.ric]);

  //input---Name
  const changeInputClick = (e) => {
    let value = e["target"]["value"];

    setInputNameVal(value);
    setOptonsNameShow(value ? true : false);
    setIsDownLoading(value ? true : false);

    if (value == "") return;
    clearTimeout(timer);
    let timeout = setTimeout(() => {
      getPowersearch(value);
    }, 500);
    setTimer(timeout);
  };

  //下拉菜单--Name
  const dropDownNameClick = (data) => {
    setOptonsNameIndex(data[1]);
    setOptonsNameShow(false);
    setIsnameShow(false);
    setIsLoading(true);
    setOptionsNameData([]);
    // this.setState({
    //   optonsNameIndex: data[1],
    //   optonsNameShow: false,
    //   isnameShow: false,
    //   isLoading: true,
    //   optionsNameData: [],
    // });
    changeRicFun(data[0]["ric"]);
  };

  //获取数据--all
  const changeRicFun = (ric) => {
    if (ric === null || ric === undefined) {
      clearClick();
      return;
    }
    setIsLoading(true);
    setQtyHeld(0);

    let token = window.sessionStorage.getItem("token");
    fetch(common.getHttp + "getsimplequote", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        ric: [ric],
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        //获取数据成功
        if (res["status"] == 0) {
          let data = res["data"];
          if (
            data &&
            typeof data != "undefined" &&
            data != 0 &&
            typeof data[ric]["fbstockid"] !== "undefined"
          ) {
            if (typeof data[ric]["fbstockid"] !== "undefined") {
              getStockHoldingInfo(token, data[ric]["fbstockid"]);
            }
            for (let i in disabledData) {
              disabledData[i] = "";
            }
            // 处理数据--flag
            let flagData = common.imgCountryFullName;
            for (let i in flagData) {
              if (
                flagData[i] == data[ric]["exchange_country_desc"].toLowerCase()
              ) {
                data[ric]["img"] = common.imgFlagUrl(i);
              }
            }
            store.addSubscribeRic(data[ric]["ric"]);
            store.setQuoteData([data[ric]]);

            setRicData({
              listData: data[ric],
              changeImg: data[ric]["img"],
              optionsName: data[ric]["name"],
              optionsSymbol: data[ric]["symbol"],
              isADR: data[ric]["isADR"],
              // isnameShow: true,
              // buySellIndex: 0,
              // typeIndex: 0,
              // disabledData: disabledData,
              // priceNums: common.keepNum(data[ric]["TRDPRC_1"], 2),
              countryCode: data[ric]["exchange_country_code"],
              fbstockid: data[ric]["fbstockid"],
              // Amount:
              //   Number(common.keepNum(data[ric]["TRDPRC_1"], 2)) *
              //   Number(quantityNums),
              // isLoading: false,
            });
            setIsnameShow(true);
            // setBuySellIndex(0);
            setTypeIndex(0);
            setDisabledData(disabledData);
            setIsDisable(false);
            !alertDetail &&
              setPriceNums(common.keepNum(data[ric]["TRDPRC_1"], 2));
            // setAmount(
            //   Number(common.keepNum(data[ric]["TRDPRC_1"], 2)) *
            //     Number(quantityNums)
            // );
            setIsLoading(false);

            // this.setState({
            //   listData: data[ric],
            //   changeImg: data[ric]["img"],
            //   optionsName: data[ric]["name"],
            //   optionsSymbol: data[ric]["symbol"],
            //   isADR: data[ric]["isADR"],
            //   isnameShow: true,
            //   buySellIndex: 0,
            //   typeIndex: 0,
            //   disabledData: disabledData,
            //   priceNums: common.keepNum(data[ric]["TRDPRC_1"], 2),
            //   countryCode: data[ric]["exchange_country_code"],
            //   fbstockid: data[ric]["fbstockid"],
            //   Amount:
            //     Number(common.keepNum(data[ric]["TRDPRC_1"], 2)) *
            //     Number(quantityNums),
            //   isLoading: false,
            // });
            return;
          }
          setIsLoading(false);
          return;
        }
        //获取失败
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //获取数据--QtyHeld
  const getStockHoldingInfo = (token, stock_id) => {
    fetch(common.getHttpZs + "getStockHoldingInfo", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        stock_id: stock_id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        //获取数据成功
        if (res["status"] == 0) {
          setQtyHeld(res["data"]["qty"]);

          return;
        }
        //获取失败
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //clear--Name
  const clearClick = () => {
    let arr = [...disabledData];
    for (let i in arr) {
      arr[i] = "disabled";
    }

    setInputNameVal("");
    setOptonsNameIndex(null);
    setOptonsNameShow(false);
    setDisabledData(arr);
    setIsDisable(true);
    // setBuySellIndex(null);
    setIsnameShow(false);
    setChangeImg("");
    setPriceNums("");
    // setAmount(0);
    props.setAlertDetail();
  };

  //change value----Quantity/Price
  const changeNumClick = (data) => {
    if (data[1] == "quantity") {
      setQuantityNums(data.value);
      // setAmount(Number(data[0]) * Number(priceNums));
      setPriceModalTag(data.name);
      return;
    }
    // price
    if (selectedAlert === 0) {
      setPriceNums(data.value);
    } else {
      setTargetPercentage(data.value);
    }
    // setAmount(Number(data[0]) * Number(quantityNums));
    setPriceModalTag(data.name);
  };

  //powersearch--获取数据
  const getPowersearch = (value) => {
    let token = window.sessionStorage.getItem("token");
    fetch(common.getHttp + "powersearch", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        k: value,
        limit: 10,
        tradable: false, // false: us only
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        //获取数据成功
        if (res["status"] == 0) {
          //flag
          let resData = res["data"]["datalist"];
          let getData =
            resData.length > 0
              ? common.imgDataFlagFun(resData, "exchange_country_desc")
              : [];

          setOptionsNameData(getData);
          setIsDownLoading(false);

          return;
        }
        //获取失败
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLeftValue = (value) => {
    let leftValue = "70%";
    let textLength = value.length;

    switch (isMobile) {
      case true:
        if (textLength >= 3) {
          leftValue = "75%";
        }
        if (textLength >= 6) {
          leftValue = "80%";
        }
        if (textLength >= 9) {
          leftValue = "85%";
        }

        break;
      default:
      case false:
        if (textLength >= 5) {
          leftValue = "75%";
        }
        if (textLength >= 10) {
          leftValue = "80%";
        }
        if (textLength >= 15) {
          leftValue = "85%";
        }
        if (textLength >= 20) {
          leftValue = "90%";
        }
    }
    return leftValue;
  };

  const onClickConfirm = () => {
    let token = window.sessionStorage.getItem("token");
    let stockid = ricData.listData.ric;
    let type = selectedAlert === 0 ? "price" : "percentage";
    let condition = selectedCondition === 0 ? "above" : "below";
    let price =
      selectedAlert === 0 ? Number(priceNums) : Number(targetPercentage);
    let good_until = selectedGoodUntil === 0 ? "today" : "cancelled";

    setIsLoadingConfirm(true);

    fetch(common.getHttp + "alert/create", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        stockid: stockid,
        type: type,
        condition: condition,
        price: price,
        good_until: good_until,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          // switch to active alert
          props.setPage(1);
        } else if (res.status === 706) {
          // max alert (20 alerts) reached
          setIsShowMaxAlertModal(true);
        }

        setIsLoadingConfirm(false);
      })
      .catch((err) => {
        setIsLoadingConfirm(false);
      });
  };

  const onClickEdit = () => {
    let token = window.sessionStorage.getItem("token");
    let stockid = ricData.listData.ric;
    let type = selectedAlert === 0 ? "price" : "percentage";
    let condition = selectedCondition === 0 ? "above" : "below";
    let price =
      selectedAlert === 0 ? Number(priceNums) : Number(targetPercentage);
    let good_until = selectedGoodUntil === 0 ? "today" : "cancelled";

    setIsLoadingConfirm(true);

    fetch(common.getHttp + "alert/edit", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        alertid: alertDetail.alertId,
        stockid: stockid,
        type: type,
        condition: condition,
        price: price,
        good_until: good_until,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          // switch to active alert
          props.setPage(1);
        }
        setIsLoadingConfirm(false);
      })
      .catch((err) => {
        setIsLoadingConfirm(false);
      });
  };

  const onClickClose = () => {
    const { bodyW, mobilebodyW, sliderIndex, currentIndex } = props;
    let navIdAPP = sessionStorage.getItem("navIdAPP");
    if (
      bodyW <= mobilebodyW &&
      (sliderIndex === "quote" || sliderIndex === "alerts")
    ) {
      props.cbDetailPageShow && props.callbackLeftPageShow("list");
      props.cbDetailPageShow && props.cbDetailPageShow(false);

      // fix SlideingPanel alert button bug
      // props.callbackChgNavIndex && props.callbackChgNavIndex(navIdAPP, true);
    }
    //quote
    if (sliderIndex === "quote") {
      props.callbackAlertsShow && props.callbackAlertsShow("close", "");
      return;
    }
    // trade
    if (sliderIndex === "trade" || sliderIndex === "alerts") {
      props.callbackChgNavIndex(navIdAPP, true);
      if (currentIndex === 3) {
        props.callbackAlertsShow && props.callbackAlertsShow("close", "");
      }
    }
    // trade/account
    if (sliderIndex === "trade" || sliderIndex === "account") {
      let data = [false, false, false, false];
      props.calkbackBtnStatus(data);
      // props.callbackChgNavIndex && props.callbackChgNavIndex(navIdAPP, true);
    }
  };

  return (
    <>
      <div
        className={[
          styles.create_alert,
          isTablet ? styles.tablet : "",
          isMobile ? styles.mobile : "",
        ].join(" ")}
      >
        <div>
          {/* Name */}
          <div
            className={[
              APPstyles.flex_row,
              styles.buyingPower,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
          >
            <span className={[styles.spanW,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>{t("Name")}</span>
            <div
              className={[APPstyles.flex_between, styles.buySelected].join(" ")}
            >
              <div
                className={[
                  APPstyles.flex_row,
                  styles.entername,
                  ChgThemeData == "Light" ? styles.entername_light : "",
                ].join(" ")}
              >
                <input
                  autoComplete="off"
                  placeholder={t("Enter the Name/Symbol")}
                  style={{
                    paddingLeft: 0,
                    display: isnameShow
                      ? "none"
                      : optonsNameIndex == null
                      ? ""
                      : "none",
                    width: "80%",
                  }}
                  value={inputNameVal}
                  onChange={changeInputClick}
                />
                <div
                  className={[APPstyles.flex_row, styles.getNamecon].join(" ")}
                  style={{
                    display: isnameShow
                      ? ""
                      : optonsNameIndex == null
                      ? "none"
                      : "",
                  }}
                >
                  <img
                    src={ricData?.changeImg}
                    className={APPstyles.img16}
                    style={{ display: ricData?.changeImg ? "" : "none" }}
                    alt="flag"
                  />
                  <span
                    className={
                      ChgThemeData == "Light"
                        ? APPstyles.color_theme_black
                        : APPstyles.color_white
                    }
                  >
                    {ricData?.optionsSymbol}
                  </span>
                  <span
                    className={
                      ChgThemeData == "Light"
                        ? APPstyles.color_009E9C
                        : APPstyles.color_cur
                    }
                  >
                    {ricData?.optionsName}
                  </span>
                </div>
              </div>
              <IconClose
                className={[
                  styles.name_close_icon,
                  ChgThemeData === "Light"
                    ? APPstyles.hover_icon_light
                    : APPstyles.hover_icon,
                ].join(" ")}
                onClick={clearClick}
              />
              {/* <img
                src={closeImg}
                className={APPstyles.img16}
                onClick={clearClick}
                alt="clear"
              /> */}
              {/* 下拉框 */}
              {optonsNameShow ? (
                <div className={styles.dropsDown_con}>
                  <DropDown
                    {...props}
                    tag="inputcon"
                    data={optionsNameData}
                    isLoading={isDownLoading}
                    cbDropDownData={dropDownNameClick}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* Old Quote */}
          {/* <div
            className={[
              APPstyles.flex_row,
              styles.quote_con,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
            style={{
              display: isnameShow ? "" : optonsNameIndex == null ? "none" : "",
            }}
          >
            <span className={styles.spanW}>{t("Quote")}</span>
            <div
              className={[APPstyles.flex_row, styles.quote_con_turnover].join(
                " "
              )}
            >
              <span
                className={
                  ChgThemeData == "Light"
                    ? APPstyles.color_theme_black
                    : APPstyles.color_white
                }
                style={{
                  marginRight:
                    Number(streamingData && streamingData["NETCHNG_1"]) > 0 ||
                    String(streamingData && streamingData["NETCHNG_1"]) < 0
                      ? 0
                      : 10,
                }}
              >
                {common.transThousands(
                  common.keepNum(streamingData && streamingData["TRDPRC_1"], 2)
                )}
              </span>
              <img
                src={
                  Number(streamingData && streamingData["NETCHNG_1"]) > 0
                    ? upImg
                    : downImg
                }
                style={{
                  width: 13.86,
                  height: 12,
                  display:
                    Number(streamingData && streamingData["NETCHNG_1"]) > 0 ||
                    String(streamingData && streamingData["NETCHNG_1"]) < 0
                      ? ""
                      : "none",
                }}
              />
              <span
                className={
                  Number(streamingData && streamingData["NETCHNG_1"]) > 0
                    ? APPstyles.color_red
                    : Number(streamingData && streamingData["NETCHNG_1"]) < 0
                    ? APPstyles.color_green
                    : ChgThemeData == "Light"
                    ? APPstyles.color_grey
                    : APPstyles.color_white
                }
                style={{ marginRight: 10 }}
              >
                {common.addplusNum(
                  common.transThousands(
                    common.keepNum(
                      streamingData && streamingData["NETCHNG_1"],
                      2
                    )
                  )
                )}
              </span>
              <span
                className={
                  Number(streamingData && streamingData["PCTCHNG"]) > 0
                    ? APPstyles.color_red
                    : Number(streamingData && streamingData["PCTCHNG"]) < 0
                    ? APPstyles.color_green
                    : ChgThemeData == "Light"
                    ? APPstyles.color_grey
                    : APPstyles.color_white
                }
              >
                {common.changeRate(
                  common.addplusNum(
                    common.transThousands(
                      common.keepNum(
                        streamingData && streamingData["PCTCHNG"],
                        2
                      )
                    )
                  )
                )}
              </span>
            </div>
          </div> */}
          {/* Quote */}
          <div
            className={[
              APPstyles.flex_row,
              styles.quote_con,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
          >
            <span className={[styles.spanW,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>{t("Quote")}</span>
            <div
              className={[APPstyles.flex_row, styles.quote_con_turnover].join(
                " "
              )}
            >
              <span
                className={[
                  Number(streamingData && streamingData["NETCHNG_1"]) > 0
                    ? APPstyles.color_red
                    : Number(streamingData && streamingData["NETCHNG_1"]) < 0
                    ? APPstyles.color_green
                    : ChgThemeData == "Light"
                    ? APPstyles.color_grey
                    : Number(streamingData && streamingData["NETCHNG_1"]) == 0
                    ? APPstyles.color_grey
                    : APPstyles.color_white,
                  APPstyles.fw700,
                ].join(" ")}
                style={{
                  marginRight:
                    Number(streamingData && streamingData["NETCHNG_1"]) > 0 ||
                    String(streamingData && streamingData["NETCHNG_1"]) < 0
                      ? 0
                      : 10,
                }}
              >
                {buySellIndex == null
                  ? "-"
                  : common.transThousands(
                      common.keepNum(
                        streamingData && streamingData["TRDPRC_1"],
                        2
                      )
                    )}
              </span>
              <img
                src={
                  Number(streamingData && streamingData["NETCHNG_1"]) > 0
                    ? upImg
                    : downImg
                }
                style={{
                  width: 13.86,
                  height: 12,
                  display:
                    (Number(streamingData && streamingData["NETCHNG_1"]) > 0 ||
                      String(streamingData && streamingData["NETCHNG_1"]) <
                        0) &&
                    buySellIndex !== null
                      ? ""
                      : "none",
                }}
              />
              <span
                className={
                  Number(streamingData && streamingData["NETCHNG_1"]) > 0
                    ? APPstyles.color_red
                    : Number(streamingData && streamingData["NETCHNG_1"]) < 0
                    ? APPstyles.color_green
                    : ChgThemeData == "Light"
                    ? APPstyles.color_grey
                    : Number(streamingData && streamingData["NETCHNG_1"]) == 0
                    ? APPstyles.color_grey
                    : APPstyles.color_white
                }
                style={{
                  marginRight: 10,
                  display: buySellIndex === null ? "none" : "",
                }}
              >
                {common.addplusNum(
                  common.transThousands(
                    common.keepNum(
                      streamingData && streamingData["NETCHNG_1"],
                      2
                    )
                  )
                )}
              </span>
              <span
                className={
                  Number(streamingData && streamingData["PCTCHNG"]) > 0
                    ? APPstyles.color_red
                    : Number(streamingData && streamingData["PCTCHNG"]) < 0
                    ? APPstyles.color_green
                    : ChgThemeData == "Light"
                    ? APPstyles.color_grey
                    : Number(streamingData && streamingData["PCTCHNG"]) == 0
                    ? APPstyles.color_grey
                    : APPstyles.color_white
                }
                style={{
                  display: buySellIndex === null ? "none" : "",
                }}
              >
                {common.changeRate(
                  common.addplusNum(
                    common.transThousands(
                      common.keepNum(
                        streamingData && streamingData["PCTCHNG"],
                        2
                      )
                    )
                  )
                )}
              </span>
            </div>
          </div>
          <div
            className={[
              styles.line,
              ChgThemeData == "Light"
                ? APPstyles.bg_list_light
                : APPstyles.bg_dark,
            ].join(" ")}
          ></div>
          {/* alert */}
          <div
            className={[
              APPstyles.flex_row,
              styles.direction,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
          >
            <span className={[styles.spanW,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>{t("alerts.alert")}</span>
            <div
              className={[
                APPstyles.flex_row,
                styles.direction_right,
                styles.alert_button_container,
                ChgThemeData == "Light" ? styles.direction_right_light : "",
                isMobile?styles.buttonFz14:''
              ].join(" ")}
            >
              {ARR_ALERT.map((item, index) => {
                return (
                  <Button
                    type="default"
                    className={[
                      styles.button,
                      selectedAlert === index ? styles.selected : "",
                      ChgThemeData === "Light" ? styles.light : "",
                      selectedAlert === index
                        ? ""
                        : ChgThemeData === "Light"
                        ? APPstyles.btn_hover_light
                        : APPstyles.btn_black
                    ].join(" ")}
                    style={{ fontSize: bodyW > mobilebodyW ? 12 : 14 }}
                    // disabled={disabledData[index]}
                    disabled={isDisable}
                    key={index}
                    onClick={() => {
                      setSelectedAlert(index);
                    }}
                  >
                    {t(item["name"])}
                  </Button>
                );
              })}
            </div>
          </div>
          {/* condition */}
          <div
            className={[
              APPstyles.flex_row,
              styles.direction,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
          >
            <span className={[styles.spanW,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>{t("alerts.condition")}</span>
            <div
              className={[
                APPstyles.flex_row,
                styles.direction_right,
                ChgThemeData == "Light" ? styles.direction_right_light : "",
                isMobile?styles.buttonFz14:''
              ].join(" ")}
            >
              {ARR_CONDITION.map((item, index) => {
                return (
                  <Button
                    type="default"
                    className={[
                      styles.button,
                      selectedCondition === index ? styles.selected : "",
                      ChgThemeData === "Light" ? styles.light : "",
                      selectedCondition === index
                        ? ""
                        : ChgThemeData === "Light"
                        ? APPstyles.btn_hover_light
                        : APPstyles.btn_black,
                    ].join(" ")}
                    style={{ fontSize: bodyW > mobilebodyW ? 12 : 14 }}
                    // disabled={disabledData[index]}
                    disabled={isDisable}
                    key={index}
                    onClick={() => {
                      setSelectedCondition(index);
                    }}
                  >
                    <span style={{ width: "100%" }}>
                      {t(item["name"])}
                      {index === 0 ? (
                        <IconUp
                          className={[
                            styles.up_icon,
                            selectedCondition === index ? styles.selected : "",
                          ].join(" ")}
                        />
                      ) : (
                        <IconDown
                          className={[
                            styles.down_icon,
                            selectedCondition === index ? styles.selected : "",
                          ].join(" ")}
                        />
                      )}
                    </span>
                  </Button>
                );
              })}
            </div>
          </div>
          <div
            className={[
              styles.line,
              ChgThemeData == "Light"
                ? APPstyles.bg_list_light
                : APPstyles.bg_dark,
            ].join(" ")}
          ></div>
          {/* target price */}
          <div
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
          >
            <span className={[styles.spanW,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>
              {selectedAlert === 0
                ? t("alerts.targetPrice")
                : t("alerts.targetPercentage")}
            </span>
            <NumberCon
              {...props}
              tag="price"
              placeholderName={
                selectedAlert === 0 ? "Enter Price" : "Enter % Change"
              }
              value={selectedAlert === 0 ? priceNums : targetPercentage}
              cbInputValue={changeNumClick}
              cbNumberWarning={(data) => {
                setIsPriceModalShow(true);
                setPriceModalTag(data);
              }}
              chgValueFun={(data) => {
                selectedAlert === 0
                  ? setPriceNums(data)
                  : setTargetPercentage(data);
              }}
            />
            {selectedAlert === 1 && targetPercentage && (
              <span
                className={[
                  styles.percent_symbol,
                  ChgThemeData === "Light" ? styles.light : "",
                ].join(" ")}
                style={{ left: getLeftValue(targetPercentage) }}
              >
                %
              </span>
            )}
          </div>
          {/* good until */}
          <div
            className={[
              APPstyles.flex_row,
              styles.direction,
              styles.tradeflow_con,
              ChgThemeData == "Light"
                ? APPstyles.borderBottom_light
                : APPstyles.borderBottom_dark,
            ].join(" ")}
          >
            <span className={[styles.spanW,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>{t("alerts.goodUntil")}</span>
            <div
              className={[
                APPstyles.flex_row,
                styles.direction_right,
                ChgThemeData == "Light" ? styles.direction_right_light : "",
                isMobile?styles.buttonFz14:''
              ].join(" ")}
            >
              {ARR_GOOD_UNTIL.map((item, index) => {
                return (
                  <Button
                    type="default"
                    className={[
                      styles.button,
                      selectedGoodUntil === index ? styles.selected : "",
                      ChgThemeData === "Light" ? styles.light : "",
                      selectedGoodUntil === index
                        ? ""
                        : ChgThemeData === "Light"
                        ? APPstyles.btn_hover_light
                        : APPstyles.btn_black,
                    ].join(" ")}
                    style={{ fontSize: bodyW > mobilebodyW ? 12 : 14 }}
                    // disabled={disabledData[index]}
                    disabled={isDisable}
                    key={index}
                    onClick={() => {
                      setSelectedGoodUntil(index);
                    }}
                  >
                    {t(item["name"])}
                  </Button>
                );
              })}
            </div>
          </div>
          <div
            className={[
              styles.line,
              ChgThemeData == "Light"
                ? APPstyles.bg_list_light
                : APPstyles.bg_dark,
            ].join(" ")}
          ></div>

          {/* target price modal */}
          {isPriceModalShow ? (
            <PriceModal
              {...props}
              tag={selectedAlert === 0 ? "price" : "percentage"}
              cbClosePriceModal={() => {
                setIsPriceModalShow(false);
              }}
            />
          ) : (
            ""
          )}
        </div>
        {isShowMaxAlertModal && (
          <div className={styles.modal_mask}>
            <div className={styles.max_alert_modal}>
              <div className={styles.message}>
                <IconInfo />
                <div>{t("alerts.maxAlertMsg")}</div>
              </div>
              <div
                className={[
                  APPstyles.flex_row,
                  APPstyles.place_button,
                  styles.botton_button_bar,
                ].join(" ")}
              >
                <Button
                  type="default"
                  style={{ backgroundColor: "#008FC7" }}
                  className={APPstyles.btn_blue}
                  // disabled={disabledData[2]}
                  onClick={() => setIsShowMaxAlertModal(false)}
                  // loading={isLoadingDelete}
                >
                  {t("alerts.ok")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* button bar: close / create alert */}
      <div
        className={[
          APPstyles.flex_row,
          APPstyles.place_button,
          APPstyles.place_button_alert,
          styles.create_alert_botton_button_bar,
          ChgThemeData == "Light"
            ? APPstyles.bg_light
            : APPstyles.bg_content_dark,
          ChgThemeData == "Light"
            ? APPstyles.borderTop_light
            : APPstyles.borderTop_dark,
        ].join(" ")}
      >
        <Button
          type="default"
          className={APPstyles.btn_gray}
          onClick={onClickClose}
        >
          {t("alerts.cancel")}
        </Button>
        <Button
          type="default"
          style={{ backgroundColor: "#008FC7" }}
          className={[
            APPstyles.btn_blue,
            isNotSelectedAll ? APPstyles.btn_disabled : "",
          ].join(" ")}
          disabled={isNotSelectedAll}
          onClick={(evt) =>
            alertDetail ? onClickEdit(evt) : onClickConfirm(evt)
          }
          loading={isLoadingConfirm}
        >
          {alertDetail ? t("alerts.editAlert") : t("alerts.createAlert")}
        </Button>
      </div>
    </>
  );
};

export default observer(CreateAlert);

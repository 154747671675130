import React from 'react'
import { message,Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import common from '@/asserts/js/common'
import { 
  leftImg,
  leftLightImg
} from '@/asserts/imgs/common/index'
import { 
  menuLightOnImg,
  menuHoverImg,
  menuImg,
  editOnImg,
  editHoverImg,
  editOffImg,
  editOnLightImg
} from '@/asserts/imgs/quote/index'
import APPstyles from '@/App.less'
import comStyles from '../List/index.less'
import styles from './index.less'

let myTimer=null

class watchlistEdit extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      itemData:[
        {
          name:'Popular Stocks',
          disabled:'',
          index:0
        },
        {
          name:'Portfolio',
          disabled:'',
          index:1
        },
        {
          name:'Screener',
          disabled:'',
          index:2
        },
        {
          name:'Sector1',
          disabled:'',
          index:3
        }
      ],
      listData: [],
      editIndex:null,
      isBottomShow:true,
      watchlistData:[],
      isLoading:false,
      indexData0123:[0,1,2,3]
    }
  }
  reOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  onDragEnd = (result) => {
    if (!result.destination) return
    const items = this.reOrder(
      this.state.listData,
      result.source.index,
      result.destination.index
    )
    this.setState({
      listData:items,
      editIndex:this.state.editIndex==null?null:result.destination.index
    })
  }
  //返回/Cancel
  backClick(){
    const {currentIndex,isMobile}=this.props
    this.props.callbackLeftPageShow('list')
    clearTimeout(myTimer)
    //ios终端
    if((currentIndex==2||currentIndex==3)&&isMobile){
      this.props.cbIsShowHeaderPage(true)
    }
  }
  //edit
  editClick(index){
    const {listData}=this.state
    for(let i in listData){
      listData[i]['disabled']='disabled'
      listData[i]['isEdit']=false
      listData[i]['imgEdit']=editOffImg
      if(index==i){
        listData[i]['isEdit']=!listData[i]['isEdit']
        listData[i]['disabled']=listData[i]['isEdit']?'':'disabled'
        listData[i]['imgEdit']=editOnImg
      }
    }
    this.setState({
      editIndex:index,
      listData:listData
    })
    myTimer=setTimeout(function (){
      document.getElementById('inputName'+index).focus()
    }, 10) 
  }
  //input值的变化
  changeInputClick(e) {
    const {listData}=this.state
    for(let i in listData){
      if(Number(e['target']['name'])==i){
        listData[i]['name']=e['target']['value']
        this.setState({
          listData: listData
        })
      }
    }
    // 有bug，无法解决，只能这样解决了
    let newTabData=JSON.parse(sessionStorage.getItem('newTabData'));
    this.props.callbackChgTabName(newTabData);
  }
  //Save Changes
  saveChangesClick(){
    const {ChgLangData,currentIndex,isMobile,watchListData}=this.props
    const {listData,itemData,isLoading,indexData0123}=this.state
    let symbolDataAll=localStorage.getItem('symbolDataAll')
    const specialCharsRegex = /[：:*?"<>|'&\\/'"’“”]/;
    for(let i in listData){
      if(listData[i]['name']==''){
        message.warning({
          content: ChgLangData=='zh-CN'?'請輸入內容':'please add content',
        })
        return;
      }else if(specialCharsRegex.test(listData[i]['name'])){
        message.warning({
          content: ChgLangData == 'zh-CN' ? 
                   '名稱中不可以包含下列字元 : * ? " < > | \' & \\ /' :
                   'The name cannot contain the following characters: * ? " < > | \' & \\ /',
        });        
        return;
      }
    }
    if(isLoading) return;
    this.setState({
      isLoading:true
    })
    itemData.push.apply(itemData,listData)
    this.props.callbackChgTabName(itemData)
    clearTimeout(myTimer)
    //ios终端
    if((currentIndex==2||currentIndex==3)&&isMobile){
      this.props.cbIsShowHeaderPage(true)
    }
    if(symbolDataAll){
      this.watchlistDataFun('symbolDataAll','symbolData',listData)
    }
    for(let i in listData){
      watchListData[i]['watchlist_name']=listData[i]['name']
      watchListData[i]['stock_id_list']=listData[i]['stock_id_list']
    }
    // 定位到对应的位置--index
    if(!indexData0123.includes(currentIndex)){
      for(let i in listData){
        let currentIndex=listData[i]['currentIndex']
        if(currentIndex){
          this.props.callbackTab(Number(i)+4)
        }
      }
    }
    this.setWatchListDataFun(watchListData)
  }
  setWatchListDataFun(data){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => {
      this.props.callbackLeftPageShow('list')
      this.props.cbOnLoadGetWatchlistFun()
      this.setState({
        isLoading:false
      })
    })
  }
  //交换位置后，重新整理 symbolDataAll 数据
  watchlistDataFun(name,dataName,listData){
    let allData=[[],[],[],[]],getData=[]
    for(let i in listData){
      getData[i]=listData[i][dataName]
    }
    allData.push.apply(allData,getData)
    localStorage.setItem(name,JSON.stringify(allData))
  }
  // 更改数据--tab
  onLoadataFun(){
    const {tabsData,watchListData,currentIndex}=this.props
    let symbolDataAll=localStorage.getItem('symbolDataAll')
    let newData=[]
    for(let i in tabsData){
      if(i>3){
        newData.push(tabsData[i])
      }
    }
    for(let i in newData){
      newData[i]['currentIndex']=null
      newData[i]['disabled']='disabled'
      newData[i]['isEdit']=false
      newData[i]['imgEdit']=editOffImg
      newData[i]['imgMove']=menuImg
      newData[i]['watchlist_id']=watchListData[i]['watchlist_id']
      newData[i]['stock_id_list']=watchListData[i]['stock_id_list']
      if(Number(currentIndex)-4==i){
        newData[i]['currentIndex']=currentIndex
      }
    }
    if(symbolDataAll){
      this.addWatchlistDataFun('symbolData',symbolDataAll,newData)
    }else{
      this.setState({
        listData:newData
      });
    }
  }
  //在列表添加watchlist，以对应到交换位置的数据
  addWatchlistDataFun(name,data,listData){
    let newsData=[]
    data=JSON.parse(data)
    for(let i in data){
      if(i>3){
        newsData.push(data[i])
      }
    }
    for(let i in listData){
      listData[i][name]=newsData[i]
    }
    this.setState({
      listData:listData
    });
  }
  //更改拖拽背景色
  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? this.props.ChgThemeData=='Light'?'#E0E0E0':'#000000' : 'none',
    ...draggableStyle
  })
  onFocus(){
    this.setState({
      isBottomShow:false
    })
  }
  onBlur(){
    const {listData}=this.state
    for(let i in listData){
      listData[i]['disabled']='disabled'
      listData[i]['isEdit']=false
      listData[i]['imgEdit']=editOffImg
    }
    this.setState({
      listData:listData,
      isBottomShow:true
    })
  }
  //初始化
  componentDidMount(){
    this.onLoadataFun()
    clearTimeout(myTimer)
  }
  render(){
    const {t,ChgThemeData,isLeftPageShow,isPC,isMobile} = this.props
    const {listData,isBottomShow,isLoading}=this.state
    return (
      <div 
        className={[
          APPstyles.flex_column,
          comStyles.list_con,
          isMobile&&isLeftPageShow=='edit'?APPstyles.position_fixed:'',
          isMobile&&isLeftPageShow=='edit'?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
        ].join(' ')}
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        <div 
        className={[
          APPstyles.flex_row,
          styles.Watchlist_title,
          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
        ].join(' ')}> 
          <img src={ChgThemeData=='Light'?leftLightImg:leftImg} className={APPstyles.img24} onClick={this.backClick.bind(this)} alt='back' />
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t('Manage Watchlist')}</span>
        </div>
        <div 
          className={[
            comStyles.history_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          {t('Rename Watchlist')}
        </div>
        {/* 列表---拖拽排序 */}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId='droppable' direction='vertical'>
            {(provided, snapshot) => (
              <div
                className={[
                  comStyles.table_body,
                  APPstyles.flex1_auto,
                  APPstyles.paddingBottom60
                ].join(' ')}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {listData.map((item, index) => (
                  <Draggable key={item['id']} draggableId={item['id']} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className={[
                          APPstyles.flex_row,
                          comStyles.body_one,
                          styles.watchlist_edit,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          ChgThemeData=='Light'?APPstyles.bg_event_light:''
                        ].join(' ')}
                      >
                        <input
                          id={'inputName'+index}
                          autoComplete='off'
                          value={t(item['name'])} 
                          name={index}
                          disabled={item['disabled']}
                          onChange={this.changeInputClick.bind(this)}
                          onFocus={this.onFocus.bind(this)}
                          onBlur={this.onBlur.bind(this)}
                          className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,styles.watchlist_name].join(' ')}
                        />
                        <img 
                          src={item['imgEdit']} 
                          alt='edit' 
                          className={isPC?APPstyles.img16:APPstyles.img24}
                          style={{
                            marginRight:20
                          }} 
                          onClick={this.editClick.bind(this,index)}
                          onMouseOver={()=>{
                            let imgurl=item['isEdit']?editOnImg:ChgThemeData=='Light'?editOnLightImg:editHoverImg
                            listData[index]['imgEdit']=imgurl
                            this.setState({
                              listData:listData
                            })
                          }} 
                          onMouseOut={()=>{
                            let imgurl=item['isEdit']?editOnImg:editOffImg
                            listData[index]['imgEdit']=imgurl
                            this.setState({
                              listData:listData
                            })
                          }}
                        />
                        <img 
                          src={item['imgMove']} 
                          alt='move'
                          className={isPC?APPstyles.img16:APPstyles.img24}  
                          onMouseOver={()=>{
                            let imgurl=ChgThemeData=='Light'?menuLightOnImg:menuHoverImg
                            listData[index]['imgMove']=imgurl
                            this.setState({
                              listData:listData
                            })
                          }} 
                          onMouseOut={()=>{
                            listData[index]['imgMove']=menuImg
                            this.setState({
                              listData:listData
                            })
                          }} 
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* footer */}
        <div 
          className={[
            APPstyles.flex_row,
            comStyles.footer_con,
            styles.watchlist_footer_edit,
            ChgThemeData=='Light'?comStyles.footer_con_light:'',
            isPC?'':comStyles.footer_env_mobile,
            // isPC?'':APPstyles.sticky_bottom_mobile
          ].join(' ')}
          style={{
            display:!isMobile?'':isBottomShow?'':'none'
          }}
        >
          <span className={APPstyles.color_blue} onClick={this.saveChangesClick.bind(this)}>{t('Save Changes')}</span>
        </div>
      </div>
  )}
}
export default withTranslation()(watchlistEdit)
import React from 'react'
import { withTranslation } from 'react-i18next'
import { 
  checkImg
} from '../../../../../asserts/imgs/common/index'
import APPstyles from '../../../../../App.less'
import styles from './index.less'

class Stauts extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      tableData:[
        {
          range:'0.01 - 0.25',
          unit:'0.001'
        },
        {
          range:'0.25 - 0.50',
          unit:'0.005'
        },
        {
          range:'0.50 - 10.00',
          unit:'0.010'
        },
        {
          range:'10.00 - 20.00',
          unit:'0.020'
        },
        {
          range:'20.00 - 100.00',
          unit:'0.050'
        },
        {
          range:'100.00 - 200.00',
          unit:'0.100'
        },
        {
          range:'200.00 - 500.00',
          unit:'0.200'
        },
        {
          range:'500.00 - 1,000.00',
          unit:'0.500'
        },
        {
          range:'1,000.00 - 2,000.00',
          unit:'1.000'
        },
        {
          range:'2,000.00 - 5,000.00',
          unit:'2.000'
        },
        {
          range:'5,000.00 - 9,995.00',
          unit:'5.000'
        }
      ]
    }
  }
  render(){
    const {t,ChgThemeData,ChgLangData} = this.props
    const {tableData} = this.state
    return (
      <div
        className={[
          APPstyles.flex1_auto,
          styles.body
        ].join(' ')}
      >
        <span 
          className={[
            APPstyles.fw700,
            styles.status_title,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
          ].join(' ')}
        >
          {t('Description of Common Error Messages')}
        </span>
        <span 
          className={[
            APPstyles.fw500,
            styles.status_sub_title,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
          ].join(' ')}
        >
          {t('1. Did not meet the transaction conditions and did not sign the risk notice')}
        </span>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_content_0
          ].join(' ')}
        >
          <label className={styles.status_circle}></label>
          {
            ChgLangData == 'zh-CN'?
              <span className={APPstyles.flex_1}>交易 <label className={APPstyles.fw700}>「商品型ETF」</label>須簽署最新版本的「複委託風險預告書」。</span>
            :
              <span className={APPstyles.flex_1}>To trade <label className={APPstyles.fw700}>"Commodity ETFs"</label>, the latest version of the "Re-entrustment Risk Notice" must be signed.</span>
          }
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_content_0
          ].join(' ')}
        >
          <label className={styles.status_circle}></label>
          {
            ChgLangData == 'zh-CN'?
              <span className={APPstyles.flex_1}>交易 <label className={APPstyles.fw700}>「香港創業板股票」</label>、<label className={APPstyles.fw700}>「封閉型基金(CEF)」</label> 或 <label className={APPstyles.fw700}>「槓桿型ETF」</label> 須<label style={{textDecoration:'underline'}}>同時符合</label> 以下資格條件：</span>
            :
              <span className={APPstyles.flex_1}>Trading <label className={APPstyles.fw700}>"GEM stocks"</label>, <label className={APPstyles.fw700}>"closed-end funds (CEF)"</label> or <label className={APPstyles.fw700}>"leveraged ETFs"</label> <label style={{textDecoration:'underline'}}>must meet</label> the following qualifications:</span>
          }
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_check
          ].join(' ')}
        >
          <img className={APPstyles.img18} src={checkImg} />
          {
            ChgLangData == 'zh-CN'?
              <span className={APPstyles.flex_1}>已簽署最新版本的「複委託風險預告書」。</span>
            :
              <span className={APPstyles.flex_1}>Have signed the latest version of the "Re-entrustment Risk Notice".</span>
          }
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_check
          ].join(' ')}
        >
          <img className={APPstyles.img18} src={checkImg} />
          <span className={APPstyles.flex_1}>{t('The client meets one of the following conditions:')}</span>
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_content_1
          ].join(' ')}
        >
          1.&nbsp;<span className={APPstyles.flex_1}>{t('Have opened a domestic credit transaction account.')}</span>
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_content_1
          ].join(' ')}
        >
          2.&nbsp;<span className={APPstyles.flex_1}>{t('In the last year, the entrusted trading of domestic or foreign subscription (put) warrants has reached ten (including) or more transactions.')}</span>
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_content_1
          ].join(' ')}
        >
          3.&nbsp;<span className={APPstyles.flex_1}>{t('The consignment transaction of domestic or foreign futures trading contracts has reached more than ten transactions (inclusive) within the most recent year.')}</span>
        </div>
        <div
          className={[
            APPstyles.flex_row,
            styles.status_content_1
          ].join(' ')}
          style={{
            marginBottom:16
          }}
        >
          4.&nbsp;<span className={APPstyles.flex_1}>{t('Transaction records of leveraged or short-selling ETFs listed on domestic or foreign stock exchanges.')}</span>
        </div>
        <span 
          className={[
            APPstyles.fw500,
            styles.status_sub_title,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
          ].join(' ')}
        >
          {t('2. The price range exceeds the limit range')}
        </span>
        <span style={{marginBottom:16,display:'block'}}>{t('The purchase price of Hong Kong stocks must be 9 gears higher and 24 gears lower than the current transaction price, and the selling price must be 24 gears higher and 9 gears lower than the current transaction price, otherwise the order will fail.')}</span>
        <span 
          className={[
            APPstyles.fw500,
            styles.status_sub_title,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
          ].join(' ')}
        >
          {t('3. Price ticking point error')}
        </span>
        <div style={{display:ChgLangData == 'zh-CN'?'':'none',marginBottom:8}}>{t('Orders for Hong Kong stocks must meet the jump point specification to be successfully entrusted')}</div>
        <span style={{marginBottom:16,display:'block'}}>{t('Orders for Hong Kong stocks must meet the jump point specification to be successfully entrusted Example: If the current intraday price of the target is 74.40 HKD, and the price range is between 20-100 HKD, the entrusted price must take HKD 0.05 as the ticking unit.')}</span>
        <div className={ChgThemeData=='Light'?APPstyles.border_light:APPstyles.border_dark}>
          <span 
            className={[
              APPstyles.flex_center,
              styles.status_table_oneHeader,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black,
              ChgThemeData=='Light'?styles.border_bottom_light:styles.border_bottom_dark
            ].join(' ')}
          >
            {t('Hong Kong Stock Price List')}
          </span>
          <div
            className={[
              APPstyles.flex_row,
              APPstyles.flex_center,
              styles.status_table_oneHeader,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black,
              ChgThemeData=='Light'?styles.border_bottom_light:styles.border_bottom_dark
            ].join(' ')}
          >
            <span 
              className={[
                APPstyles.wPer50,
                APPstyles.flex_row,
                APPstyles.flex_center,
                ChgThemeData=='Light'?styles.border_right_light:styles.border_right_dark
              ].join(' ')}
            >
              {t('Hong Kong Stock Price Range (HKD)')}
            </span>
            <span 
              className={[
                APPstyles.wPer50,
                APPstyles.flex_row,
                APPstyles.flex_center
              ].join(' ')}
            >
              {t('Beating Unit')}
            </span>
          </div>
          {tableData.map((item,index) => {
            return  <div
                      key={index}
                      className={[
                        APPstyles.flex_row,
                        APPstyles.flex_center,
                        styles.status_table_body,
                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                        tableData.length-1==index?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                      ].join(' ')}
                    >
                      <span 
                        className={[
                          APPstyles.wPer50,
                          APPstyles.flex_row,
                          APPstyles.flex_center,
                          ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark
                        ].join(' ')}
                      >
                        {item['range']}
                      </span>
                      <span 
                        className={[
                          APPstyles.wPer50,
                          APPstyles.flex_row,
                          APPstyles.flex_center
                        ].join(' ')}
                      >
                        {item['unit']}
                      </span>
                    </div>
          })}
        </div>
      </div>
  )}
}
export default withTranslation()(Stauts)
import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Switch extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgCloseImg:closeImg,
      chgDropDownImg:dropDownImg,
      isDropDownShow:false,
      chgAccount:'',
      accountData:[],
      isLoading:false,
      screenerId:'strategy1',
      strategyData:[
        {id:'strategy1',data:[]},
        {id:'strategy2',data:[]},
        {id:'strategy3',data:[]},
        {id:'strategy4',data:[]}
      ],
      dropDownIndex:0,
      getAid:''
    }
  }
  // 选中某个
  choiceOneClick(data,index,e){
    const {switchTag}=this.props
    e.stopPropagation()
    let silderData=data['BranchName']+' '+data['AID']+' '+data['Name']
    this.setState ({
      chgAccount:switchTag=='slider'?silderData:data['name'],
      screenerId:data['id'],
      isDropDownShow:false,
      dropDownIndex:index,
      getAid:data['AID']
    })
  }
  // 提交
  confirmClick(tag){
    const {switchTag}=this.props
    switchTag=='slider'?this.dealSwitchFun(tag):this.dealScreenerFun(tag)
  }
  // switch
  dealSwitchFun(tag){
    const {locationName,isSwitchModalShow,currentIndex}=this.props
    const {getAid,accountData}=this.state
    let pathName='/'+locationName[1]+'/Quote'
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let target=sessionStorage.getItem('target')
    let aidListId=sessionStorage.getItem('aidListId')
    sessionStorage.setItem('isSwitchFirstShow','N')
    if(tag=='Y'){
      this.setState ({
        isLoading:true
      });
      // 关闭trade页面
      if(navIdAPP=='quote'){
        this.props.cbCloseRightPageFun()
      }
      // 切换账号了
      if(accountData.length>1&&aidListId!==getAid){
        sessionStorage.setItem('aidListId',getAid)
        this.sessionStorageFun()
        this.getAccountIdTokenFun(pathName,navIdAPP,getAid,target)
        return
      }
      // 账号是默认的初始值
      this.gotQuotePageFun(pathName)
      return
    }
    // N
    let chgNavId=target=='trade'?'trade':navIdAPP;
    if((isSwitchModalShow&&navId=='trade')||(isSwitchModalShow&&navId=='quote'&&(currentIndex==2||currentIndex==3))){
      chgNavId='trade';
    }
    sessionStorage.setItem('navIdAPP',navIdAPP)
    sessionStorage.setItem('navId',chgNavId)
    this.props.callbackChgNavIndex(chgNavId)
    this.props.cbCloseModal();
  }
  // screener
  dealScreenerFun(tag){
    const {listData}=this.props
    const {screenerId,strategyData}=this.state
    this.props.cbCloseModal(tag)
    if(tag=='Y'){
      let item=strategyData.find(item=>item['id']==screenerId)
      item['data']=listData
      localStorage.setItem('strategyData',JSON.stringify(strategyData))
      return
    }
  }
  // 跳转到quote页面
  gotQuotePageFun(pathName){
    this.props.callbackPathnameApp(pathName)// 获取路由跳转--app.js
    this.sessionStorageFun()
    this.props.cbCloseModal()
    this.setState ({
      isLoading:false
    })
  }
  // 更改数据
  sessionStorageFun(){
    let target=sessionStorage.getItem('target')
    let chgNavId=target=='trade'?'trade':'quote'
    sessionStorage.setItem('navIdAPP','quote')
    sessionStorage.setItem('navId',chgNavId)
    this.props.callbackChgNavIndex(chgNavId)
  }
  getAccountIdTokenFun(pathName,navIdAPP,getAid,target){
    fetch(common.getHttpZs+'getAccountIdToken',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        account_id:getAid
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let token=res['data']['token'];
        sessionStorage.setItem('token',token);
        this.getCustomerCurrency(token);
        if(navIdAPP!=='quote'){
          this.props.callbackPathnameApp(pathName);// 获取路由跳转--app.js
        }
        // 切换账号更新list列表数据---navIdAPP=='quote'
        if(navIdAPP=='quote'){
          this.props.cbSwitchFun()
          this.props.cbCloseModal()
          if(target=='trade'){
            this.props.chgAccountFun()
          }
        }
        this.setState ({
          isLoading:false
        })
      }
    })
  }
  // 查詢下單時各個市場是否使用台幣交易
  getCustomerCurrency(token){
    fetch(common.getHttpZs+'getCustomerCurrency',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token,
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        sessionStorage.setItem('customerCurrency',JSON.stringify(res['data']));
      }else{
        sessionStorage.setItem('customerCurrency',JSON.stringify([]));
      }
    })
  }
  dropDownClick(e){
    e.stopPropagation()
    this.setState(prevState => ({
      isDropDownShow:!prevState.isDropDownShow,
    }))
  }
  //点击外部关闭下拉框--cur
  handleClick = e => {
    if(!this.testComponent.contains(e.target)){
      this.setState ({
        isDropDownShow:false,
        chgDropDownImg:dropDownImg
      })
    }
  }
  //更换数据
  chgDataFun(){
    const {switchTag,screenerData}=this.props
    const {accountData}=this.state
    // slider
    if(switchTag=='slider'){
      let aidListData=JSON.parse(sessionStorage.getItem('aidListData'))
      let aidListId=sessionStorage.getItem('aidListId')
      let getData=aidListData[aidListId]
      let aidData=Object.keys(aidListData)
      let newData=[]
      for(let i in aidData){
        newData.push(aidListData[aidData[i]])
      }
      let index=newData.findIndex((item) => item['AID'] == aidListId)
      this.setState ({
        chgAccount:getData['BranchName']+' '+getData['AID']+' '+getData['Name'],
        dropDownIndex:index,
        getAid:getData['AID']
      })
      if(!accountData.length){
        this.setState ({
          accountData:newData
        })
      }
      // screener
    }else{
      this.setState ({
        accountData:screenerData,
        chgAccount:'Strategy 1'
      })
    }
  }
  // 判断是否有数据
  chgStrategyDataFun(){
    let item=localStorage.getItem('strategyData')
    if(item!==null){
      item=JSON.parse(item)
      this.setState ({
        strategyData:item
      })
    }
  }
  //初始化
  componentDidMount(){
    this.chgDataFun()
    this.chgStrategyDataFun() 
    document.addEventListener('click', this.handleClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }
  render(){
    const {t,isMobile,isPC,ChgThemeData,cancelText,sureText,switchTitle,switchName,switchTag}=this.props
    const {chgCloseImg,chgDropDownImg,isDropDownShow,chgAccount,accountData,isLoading,dropDownIndex}=this.state
    return (
      <div className={styles.modal_fixed}>
        <div 
          className={[
            styles.modal_con,
            ChgThemeData=='Light'?styles.modal_con_light:''
          ].join(' ')} 
          style={{
            width:isMobile?'80%':528,
            height:'auto',
            minHeight:193
          }}
        >
          <div 
            className={[
              styles.title,
              APPstyles.flex_between,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <span>{t(switchTitle)}</span>
            <img 
              src={chgCloseImg}
              alt='close'
              className={APPstyles.img24} 
              onClick={this.confirmClick.bind(this,'N')}   
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  chgCloseImg:closeImg
                })
              }} 
            />
          </div>
          <div 
            className={[
              styles.switch_content,
              APPstyles.flex_topcenter,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <span className={[styles.switch_content_left,isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''].join(' ')}>{t(switchName)}</span>
            <div
              className={[styles.switch_content_right,APPstyles.flex_1,APPstyles.flex_between].join(' ')}
              onClick={this.dropDownClick.bind(this)}
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                this.setState({
                  chgDropDownImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  chgDropDownImg:dropDownImg
                })
              }} 
            >
              <span>{t(chgAccount)}</span>
              <img 
                src={chgDropDownImg} 
                alt='dropDown' 
                className={styles.img_dropDown} 
                style={{transform:!isDropDownShow?'rotate(0deg)':'rotate(-180deg)'}}
              />
              {/* 下拉框 */}
              <div 
                className={[
                  styles.dropsDown_con,
                  ChgThemeData=='Light'?styles.select_con_light:''
                ].join(' ')} 
                style={{display:isDropDownShow?'':'none'}} 
                ref = {testComponent => this.testComponent = testComponent}
              >
                {accountData.length&&accountData.map((item,index) => {
                  return  <span 
                            key={index}
                            className={[
                              APPstyles.flex_row,
                              APPstyles.flex_topcenter,
                              ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.btn_black,
                              dropDownIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_event_black:''
                            ].join(' ')}
                            onClick={this.choiceOneClick.bind(this,item,index)}
                          >
                            {switchTag=='slider'?item['BranchName']+' '+item['AID']+' '+item['Name']:t(item['name'])}
                          </span>
                })}
              </div>
            </div>
          </div>
          <div className={[styles.button_con,APPstyles.flex_row,!isMobile?'':styles.button_con_mobile].join(' ')}>
            <Button 
              type='default' 
              className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} 
              onClick={this.confirmClick.bind(this,'N')}
            >
              {t(cancelText)}
            </Button>
            <Button 
              // type='default'
              loading={isLoading} 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
              onClick={this.confirmClick.bind(this,'Y')}
            >
              {t(sureText)}
            </Button>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(Switch)
import React from 'react';
import {withTranslation} from 'react-i18next';
import common from '@/asserts/js/common';
import {
  fileExcelImg,
  fileExcelOnImg,
  fileExcelLightImg,
  fileExcelLightOnImg,
} from '@/asserts/imgs/common/index';
import DropDownSwitch from '@/components/dropDownSwitch/index';
import CalendarCon from '../Calendar/index';
import InputCon from '../InputCon/index';
import PaginationCon from '../Pagination/index';
import Mobile from './mobile/index';
import List from './list/index';
import APPstyles from '@/App.less';
import styles from './index.less';

let chgPage=1;

class StatementIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history:[],
      isLoading:true,
      page:1,
      stock_id:null,
      fromDate:'',
      toDate:'',
      sizeTotal:0,
      recperpage:20,
      loadMore:false,
      noMore:false,
      chgSort:'direction_desc',
      onLoad:true,
      isSortShow:false,
      closeCalendar:false,
      closeInput:false,
      dropDownL:0,
      dropDownR:'unset',
      dropDownT:38,
      dropDownW:'100%',
      minW500:500,
      minW960:960,
      isExport:false,
      excelImg:fileExcelImg,
      exportData:'',
      isLoopData:false,
    }
  }
  //排序
  sortClick(name,tag,sortName){
    const {sortData,isTablet,ChgThemeData}=this.props
    const {stock_id,page,history,fromDate,toDate,onLoad,chgSort,isLoading,isSortShow}=this.state
    if(history.length<=0||(!onLoad&&chgSort==tag)||isLoading) return
    this.setState({
      isLoading: true,
      chgSort:tag,
      onLoad:false,
      loadMore:false,
      noMore:false,
      isSortShow:!isSortShow
    })
    let newData=common.getSortDataFun(sortData,ChgThemeData,name,sortName)
    chgPage=1
    let chgPn=isTablet?1:page
    this.getTableData(stock_id,tag,chgPn,fromDate,toDate,[])
    this.props.cbChgSortDataFun(newData)
  }
  getTableData(stock_id,sort,page,fromDate,toDate,listsData) {
    const {recperpage}=this.state
    fetch(common.getHttpZs+'getStatement', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stock_id: stock_id,
        recperpage: recperpage,
        page: page,
        sort:sort, 
        from_date: Number(fromDate),
        to_date: Number(toDate),
        token: sessionStorage.getItem("token"),
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if(res['status']==0){
        if(JSON.stringify(res['data']) !== '{}'){
          let datalist=res['data']['datalist'];
          let size_total=res['data']['size_total'];
          if(datalist.length){
            this.commonDataFun(datalist,listsData,page,size_total);
            return
          }
          if(listsData.length){
            this.setState({
              isLoading:false,
              loadMore:false,
              noMore:true
            })
          }else{
            this.noDataFun()
          }
          return
        }
        this.noDataFun()
        return
      }
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err);
      this.setState({
        listData: [],
        isLoading: false,
      });
    })
  }
  commonDataFun(datalist,listsData,page,size_total){
    const {isTablet}=this.props
    let pn1=datalist.length?datalist.length>=10?false:true:false
    let pnother=listsData.length?datalist.length?false:true:true
    let noMoreData=page==1?pn1:pnother

    this.setState({
      history: isTablet?listsData.concat(datalist):datalist,
      sizeTotal:size_total,
      isLoading:false,
      loadMore:false,
      noMore:noMoreData
    })
  }
  noDataFun(){
    this.setState({
      history: [],
      sizeTotal:0,
      isLoading:false,
      loadMore:false,
      noMore:false
    })
  }
  //回传数据--input
  callbackInputData(data){
    const {fromDate,toDate,chgSort}=this.state
    this.setState({
      isLoading:true,
      stock_id:data[0],
      page:1
    })
    chgPage=1
    this.getTableData(data[0],chgSort,1,fromDate,toDate,[])
  }
  //回传数据--日期
  callbackDateData(data){
    const {stock_id,chgSort}=this.state
    this.setState({
      isLoading:true,
      fromDate:data[0],
      toDate:data[1],
      page:1
    })
    chgPage=1
    this.getTableData(stock_id,chgSort,1,data[0],data[1],[])
  }
  //回传数据--分页
  callbackPagekData(page){
    const {stock_id,fromDate,toDate,chgSort}=this.state
    this.setState({
      isLoading:true,
      page:page
    })
    this.getTableData(stock_id,chgSort,page,fromDate,toDate,[])
  }
  //滚动加载更多--isTablet/mobile
  cbChgDataFun(){
    const {loadMore,noMore,isLoading,stock_id,fromDate,toDate,history,chgSort}=this.state
    if(loadMore||noMore||isLoading) return
    chgPage++
    this.setState({
      page: chgPage,
      loadMore:true,
      noMore:false
    })
    this.getTableData(stock_id,chgSort,chgPage,fromDate,toDate,history)
  }
  //更改下拉框
  changeDropDownData(){
    const { isMobile, bodyW, isSearchPageShow } = this.props;
    const { minW500, minW960 } = this.state;
    const chgW0 = bodyW < minW960 && !isMobile && isSearchPageShow;
    const isNarrowScreen = bodyW <= minW500 || chgW0;
    this.setState({
      dropDownL: isNarrowScreen ? 'unset' : 0,
      dropDownR: isNarrowScreen ? 16 : 'unset',
      dropDownT: (isMobile && isNarrowScreen) ? 92 : (chgW0 ? 52 : 38),
      dropDownW: isNarrowScreen ? (chgW0 ? '65%' : '92%') : '100%',
    });
  }
  // 以excel的格式下载所有数据
  getExportData(page = 1, allData = []) {
    const { stock_id, fromDate, toDate, chgSort, recperpage, isExport } = this.state;
    fetch(common.getHttpZs+'getStatement', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stock_id: stock_id,
        recperpage: recperpage,
        page: page,
        sort: chgSort,
        from_date: Number(fromDate),
        to_date: Number(toDate),
        token: sessionStorage.getItem("token"),
      })
    })
    .then((res) => res.json())
    .then((res) => {
      if (res['status'] === 0 && JSON.stringify(res['data']) !== '{}' && res['data']['datalist'].length > 0) {
        // 有数据返回
        let datalist = res['data']['datalist'];
        let newData = allData.concat(datalist); // 合并旧数据与新数据
        this.setState({
          exportData: newData,
        });
        // 继续请求下一页
        this.getExportData(page + 1, newData);
      } else {
        this.setState({
          isExport: !isExport,
          isLoopData: false,
        });
        console.log("所有数据已加载完成或达到终止条件");
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  }
  // 以文档的形式导出table
  exportClick(){
    const {isExport,history,sizeTotal,recperpage, isLoopData}=this.state;
    if(history.length<=0 || isLoopData) return;
    let dataLen = Math.ceil(sizeTotal / recperpage);
    if(dataLen > 1){
      this.setState({
        isLoopData: true,
      });
      this.getExportData();
    }else{//api总共的数据只有recperpage:20，那么只需要执行这里
      this.setState({
        isExport: !isExport,
        exportData:history,
        isLoopData: false,
      });
    }
  }
  changeExcelImg(){
    const {ChgThemeData}=this.props;
    this.setState({ 
      excelImg: ChgThemeData === 'Light' ? fileExcelLightImg : fileExcelImg,
    });
  }
  handleMouse(isHover) {
    const { ChgThemeData,isPC } = this.props;
    const {history, isLoopData}=this.state;
    if (!isPC || history.length<=0 || isLoopData) return;
    let imgurl;
    if (isHover) {
      imgurl = ChgThemeData === 'Light' ? fileExcelLightOnImg : fileExcelOnImg;
    } else {
      imgurl = ChgThemeData === 'Light' ? fileExcelLightImg : fileExcelImg;
    }
    this.setState({ 
      excelImg: imgurl, 
    });
  }
  componentDidUpdate(newProps){
    const {bodyW,isSearchPageShow}=this.props;
    if(bodyW!==newProps['bodyW']||isSearchPageShow!==newProps['isSearchPageShow']){
      this.changeDropDownData();
    }
  }
  componentDidMount() {
    const {from_date,to_date}=this.props
    const {stock_id,page,chgSort}=this.state
    chgPage=1
    this.setState({
      fromDate: from_date,
      toDate: to_date,
      page: 1,
      loadMore:false,
      noMore:false
    });
    this.changeExcelImg();
    this.getTableData(stock_id,chgSort,page,from_date,to_date,[])
  }
  render() {
    const {t,ChgThemeData,isMobile,isPC,isTablet,isSearchPageShow,bodyW} =this.props;
    const {history,isLoading,sizeTotal,recperpage,page,loadMore,noMore,isSortShow,closeCalendar,closeInput,dropDownL,dropDownR,dropDownT,dropDownW,minW500,minW960,isExport,excelImg,exportData,isLoopData} = this.state;
    return (
      <div 
        className={[
          styles.StatementPage,
          isPC&&isSearchPageShow?'':APPstyles.flex1_auto,
          isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
          isPC&&isSearchPageShow||isTablet?APPstyles.hPer_overflowH:'',
          isPC&&isSearchPageShow?'':isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'',  
        ].join(' ')}
        style={{padding:isPC?'0 16px':0}}
      >
        <div 
          className={[styles.headerCon,!isMobile&&APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}
          style={{padding:isPC?0:16,minHeight:isMobile?102:59}}
        >
          {isMobile&&
            <DropDownSwitch {...this.props} sign='Statement' />
          }
          <div className={[APPstyles.flex_row,APPstyles.flex_1].join(' ')}>
            {!isMobile&&
              <DropDownSwitch {...this.props} sign='Statement' />
            }
            <div className={APPstyles.flex_topcenter}>
              <CalendarCon
                {...this.props}
                sign='Statement'
                closeCalendar={closeCalendar}
                callbackDateData={this.callbackDateData.bind(this)}
                cbCloseInput={()=>{
                  this.setState({
                    closeInput:!closeInput,
                  });
                }}
              />
            </div>
            <div className={[APPstyles.flex_topcenter,APPstyles.flex_1,isPC&&styles.inputCons].join(' ')} style={{position:bodyW<=minW500||(bodyW<minW960&&!isMobile&&isSearchPageShow)?'unset':'relative'}}>
              <InputCon
                {...this.props}
                closeInput={closeInput}
                dropDownL={dropDownL}
                dropDownR={dropDownR}
                dropDownT={dropDownT}
                dropDownW={dropDownW}
                callbackInputData={this.callbackInputData.bind(this)}
                cbCloseAll={()=>{
                  this.setState({
                    closeCalendar:!closeCalendar,
                  });
                }}
              />
            </div>
            {isPC&&
              <span 
                className={[
                  styles.btnWords,
                  APPstyles.flex_center,
                  ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black,
                ].join(' ')} 
                onClick={()=>{this.exportClick()}}
                onMouseOver={() => this.handleMouse(true)} 
                onMouseOut={() => this.handleMouse(false)}
                style={{cursor:isLoopData ? 'not-allowed':history.length>0?'pointer':'not-allowed'}}
              >
                <img src={excelImg} className={APPstyles.img24} style={{cursor:isLoopData ? 'not-allowed':history.length>0?'pointer':'not-allowed'}} />
              </span>
            }
          </div>
          <div className={APPstyles.flex_topcenter} style={{display:isPC?'':'none'}}>
            <PaginationCon
              {...this.props}
              sizeTotal={sizeTotal}
              recperpage={recperpage}
              history={history}
              page={page}
              isShow={false}
              callbackPagekData={this.callbackPagekData.bind(this)}
            />
          </div>
        </div>
        {isPC&&
          <List 
            {...this.props} 
            isLoading={isLoading}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            history={history}
            isExport={isExport}
            exportData={exportData}
            cbSortData={this.sortClick.bind(this)}
            cgChgSortDataFun={(data)=>{
              this.props.cgChgSortDataFun(data);
            }}
          />
        }
        {!isPC&&
          <Mobile 
            {...this.props}
            history={history}
            isLoading={isLoading}
            loadMore={loadMore}
            noMore={noMore}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            page={page}
            isShow={true}
            isSortShow={isSortShow}
            sortClick={this.sortClick.bind(this)}
            cbChgDataFun={this.cbChgDataFun.bind(this)}
          />
        }
        <div style={{display:isPC&&!isSearchPageShow?'':'none'}}>
          <PaginationCon
            {...this.props}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            page={page}
            isShow={true}
            history={history}
            callbackPagekData={this.callbackPagekData.bind(this)}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(StatementIndex);

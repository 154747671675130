/*
    Developer: Parker Suen
    This hook is for the alternative of setInterval in React
*/

import React, { useRef, useEffect } from "react";

// fn: () => void, timeInterval: number | null
const useInterval = (fn, timeInterval) => {
  const refFunction = useRef(fn);
  useEffect(() => {
    refFunction.current = fn;
  }, [fn]);

  useEffect(() => {
    if (timeInterval === null) {
      // console.log("timeInterval === null");
      return;
    }

    const interval = setInterval(() => refFunction.current(), timeInterval);

    return () => {
      // console.log("clearInterval(interval)");
      clearInterval(interval);
    };
  }, [timeInterval]);
};

export default useInterval;

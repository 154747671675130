import React from 'react'
import { withTranslation } from 'react-i18next'
import APPstyles from '../../../../App.less'
import styles from '../../../../pages/Account/index.less'

class TipsCon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    const { t,isPC,ChgThemeData } = this.props
    return (
      <div 
        className={[
          styles.account_tips,
          isPC?'':styles.account_tips_min,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
        ].join(' ')}
      >
        <div>
          {t("Disclaimer")}
          <br />
          {t(
            "If the customer uses the 'Taiwan dollar delivery' to use the reference exchange rate for Taiwan dollar conversion, if the customer uses the 'Taiwan dollar delivery' to use the actual exchange rate for Taiwan dollar conversion, the profit and loss of the conversion to the current Taiwan dollar may differ from the actual transaction profit and loss ratio due to the exchange rate difference. different. 'Taiwan Dollar Delivery' in this query function only has spread profit and loss. If the customer needs to declare all overseas, please refer to the overseas income information applied by the branch office."
          )}
        </div>
      </div>
    )
  }
}
export default withTranslation()(TipsCon)

import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../js/common'
HighchartsMore(Hightcharts)

class Pie extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
     
    }
  }
  onLoadData(){
    const {t,id,chartData,ChgThemeData,isTablet,isMobile,isSearchPageShow,isPC}=this.props
    Hightcharts.chart(id,{
      chart: {
        type: 'pie',
        height:isMobile?200:isPC?140:150,
        width:200,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        spacing:[0, 0, 0, 0],
        margin: [0, 0, 0, 0]
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      title: {
        floating:true,
        verticalAlign:'middle',
        text: t('Stock1')+'<br/>'+t('Allocation1')+'<br/>(%)',
        style: {
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontSize:'12px',
          fontWeight:700,
          fontFamily: 'Roboto',
          lineHeight: '14px'
        }
      },
      legend:{
        enabled: false
      },
      tooltip:{
        borderWidth:0,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        valueSuffix: '%',
        // hideDelay:50000,
        // padding:20,
        style:{
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '14px',
          whiteSpace: 'nowrap',
          width:'500px'
        },
        pointFormatter(){
          let chgY=Number(this['y'])==100?common.changeRate(common.keepNum(this['y'],0)):common.changeRate(common.keepNum(this['y'],2))
          let getY=this['name']=='no stock'?'0.00%':chgY
          let name=typeof(this['stock_name'])=='undefined'?'stock':this['stock_name']
          let str='<div style="widht:500px"><span style="color:'+this['color']+'">●</span><i style="opacity:0;color:none">.</i><span>'+name+' : '+getY+'</span></div>'
          return str
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth:1,
          animation: false,
          borderColor: ChgThemeData=='Light'?'#FFFFFF':'#222429',
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          innerSize: '60%',
          minSize:10,
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [{
        name: 'stock',
        colorByPoint: true,
        data: chartData
      }]
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,chartData,isSearchPageShow}=this.props
    if(
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      chartData!==newProps['chartData']||
      isSearchPageShow!==newProps['isSearchPageShow']
    ){
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(Pie)
import React from 'react'
import { message,Button } from 'antd'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg,
  leftImg,
  leftLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class OrderConfirmation extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      listData:[],
      isLoading:false,
      chgCloseImg:closeImg
    }
  }
  //Cancel/confirm
  orderComfirmClick(msg){
    const {sliderIndex,isMobile,isTablet,tradeOrderData} = this.props
    let getData=tradeOrderData[0]
    getData['isClickADR']=false
    this.props.calkbackOrderData([getData])
    
    if(msg=='confirm'){
      this.setState({
        isLoading:true
      })
      this.insertOrderFun()
    }else{
      // Cancel
      if(sliderIndex=='quote'){
        let data=isTablet&&!isMobile?[true,true,false,false]:[false,false,false,false]
        this.props.calkbackBtnStatus(data)
      }
      if(sliderIndex=='trade'||sliderIndex=='account'||sliderIndex=='news'){
        let data=[true,true,false,false]
        this.props.calkbackBtnStatus(data)
      }
    }
  }
  // 处理数据
  dealDataFun(){
    const {isMobile,currentIndex,isTablet} = this.props
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let tempData=''
    if(navId=='quote'){
      if(currentIndex==2||currentIndex==3||(isTablet&&!isMobile)){
        tempData=[true,true,false,false]
      }else{
        tempData=[false,false,false,false]
      }
    }else{
      tempData=[true,true,false,false]
    }
    this.setState({
      isLoading:false
    })
    this.props.calkbackBtnStatus(tempData)
    this.props.cbSubmitOrderFun()
    if(!isMobile){
      if(navIdAPP=='quote'&&navId=='trade') return
      sessionStorage.setItem('navId',navIdAPP)
    }
  }
  executeInsertOrder() {
    const {listData}=this.state
    let token=window.sessionStorage.getItem('token')

    if (common.enableWebca) {
      //SIGN WITH CERT WHEN CLICKED SIGN BUTTON
      const userid = window.sessionStorage.getItem('uid');
      const certFilter = "//S_CN=" + userid + "//";
      const signContent = (listData['buySellIndex']['key']) + "|" + listData['fbstockid'] + "|" + Number(listData['Quantity']) + "|" + listData['priceType']['key'] + "|" + Number(listData['price']) + "|" + Number(listData['priceType']['key1']) + "|" + listData['GoodUntil']['key'];

      if (window.inAppDetector.isInApp()) {
        //IF IT IS IN WEBVIEW, SIGN WITH CERT IN APP
        window.mtklib.sign({
          "idno": userid,
          "tosign": signContent,
          "method": "P1",
          "cb": (cbobj) => {
            if (cbobj.code == "0") {
              fetch(common.getHttpZs + 'insertOrder', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json,text/plain,*/*',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  token: token,
                  country_code: listData['countryCode'],
                  buysell: listData['buySellIndex']['key'],
                  stock_id: listData['fbstockid'],
                  qty: Number(listData['Quantity']),
                  price_type: listData['priceType']['key'],
                  price: Number(listData['price']),
                  sign_type: "pkcs1",
                  signature: cbobj.signed,
                  src: signContent,
                  sn: cbobj.casno,
                  stop_limit:Number(listData['priceType']['key1']),
                  gtd:listData['GoodUntil']['key']
                })
              })
                .then(res => res.json())
                    .then(res => {
                      //获取数据成功
                      if (res['status'] == 0) {
                        this.dealDataFun()
                        return
                      }
                      //获取失败
                      this.setState({
                        isLoading: false
                      })
                      let error = res['error'];
                      if (error) {
                        error = res['error']['code'] + " " + res['error']['msg']
                      } else if (res['data']) {
                        error = res['data']['status'] + " " + res['data']['msg']
                      } else {
                        error = res['message'];
                      }
                      message.warning({
                        content: error
                      })
                    }).catch(err => {
                      console.log(err)
                    })
            } else {
              //获取失败
              this.setState({
                isLoading: false
              })
              message.warning({
                content: cbobj.code + " " + cbobj.desc
              })
            }
          }
        })
      } else {
        //執行PKCS7簽章
        window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
          if (code === 0 && data) {
            var certData = JSON.parse(data);

            fetch(common.getHttpZs + 'insertOrder', {
              method: 'POST',
              headers: {
                'Accept': 'application/json,text/plain,*/*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                token: token,
                country_code: listData['countryCode'],
                buysell: listData['buySellIndex']['key'],
                stock_id: listData['fbstockid'],
                qty: Number(listData['Quantity']),
                price_type: listData['priceType']['key'],
                price: Number(listData['price']),
                signature: certData.signature,
                data: signContent,
                subject: certData.subdn,
                stop_limit:Number(listData['priceType']['key1']),
                gtd:listData['GoodUntil']['key']
              })
            })
              .then(res => res.json())
                  .then(res => {
                    //获取数据成功
                    if (res['status'] == 0) {
                      this.dealDataFun()
                      return
                    }
                    //获取失败
                    this.setState({
                      isLoading: false
                    })
                    let error = res['error'];
                    if (error) {
                      error = res['error']['code'] + " " + res['error']['msg']
                    } else if (res['data']) {
                      error = res['data']['status'] + " " + res['data']['msg']
                    } else {
                      error = res['message'];
                    }
                    message.warning({
                      content: error
                    })
                  }).catch(err => {
                    console.log(err)
                  })
          } else if (code === 5067 || code === "5067") {
            //憑證挑選
            window.twcaLib.SelectSignerFubon(userid, certFilter, "", "", "", 0x04 | 0x08, 0, null, (code, msg, t, data) => {
              if (code === 0) {
                //執行PKCS7簽章
                window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
                  if (code === 0 && data) {
                    var certData = JSON.parse(data);

                    fetch(common.getHttpZs + 'insertOrder', {
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json,text/plain,*/*',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        token: token,
                        country_code: listData['countryCode'],
                        buysell: listData['buySellIndex']['key'],
                        stock_id: listData['fbstockid'],
                        qty: Number(listData['Quantity']),
                        price_type: listData['priceType']['key'],
                        price: Number(listData['price']),
                        signature: certData.signature,
                        data: signContent,
                        subject: certData.subdn,
                        stop_limit:Number(listData['priceType']['key1']),
                        gtd:listData['GoodUntil']['key']
                      })
                    })
                      .then(res => res.json())
                      .then(res => {
                        //获取数据成功
                        if (res['status'] == 0) {
                          this.dealDataFun()
                          return
                        }
                        //获取失败
                        this.setState({
                          isLoading: false
                        })
                        let error = res['error'];
                        if (error) {
                          error = res['error']['code'] + " " + res['error']['msg']
                        } else if (res['data']) {
                          error = res['data']['status'] + " " + res['data']['msg']
                        } else {
                          error = res['message'];
                        }
                        message.warning({
                          content: error
                        })
                      }).catch(err => {
                        console.log(err)
                      })
                  } else {
                    this.setState({
                      isLoading: false
                    })
                    message.warning({
                      content: code + " " + msg
                    })
                  }
                });
              } else {
                this.setState({
                  isLoading: false
                })
                message.warning({
                  content: code + " " + msg
                })
              }
            });
          } else {
            this.setState({
              isLoading: false
            })
            message.warning({
              content: code + " " + msg
            })
          }
        });
      }
    } else {
      fetch(common.getHttpZs + 'insertOrder', {
        method: 'POST',
        headers: {
          'Accept': 'application/json,text/plain,*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          country_code: listData['countryCode'],
          buysell: listData['buySellIndex']['key'],
          stock_id: listData['fbstockid'],
          qty: Number(listData['Quantity']),
          price_type: listData['priceType']['key'],
          price: Number(listData['price']),
          signature: "dev",
          data: "dev",
          subject: "dev",
          stop_limit:Number(listData['priceType']['key1']),
          gtd:listData['GoodUntil']['key']
        })
      })
        .then(res => res.json())
        .then(res => {
          //获取数据成功
          if (res['status'] == 0) {
            this.dealDataFun()
            return
          }
          //获取失败
          this.setState({
            isLoading: false
          })
          let error = res['error'];
          if (error) {
            error = res['error']['code'] + " " + res['error']['msg']
          } else if (res['data']) {
            error = res['data']['status'] + " " + res['data']['msg']
          } else {
            error = res['message'];
          }
          message.warning({
            content: error
          })
        }).catch(err => {
          console.log(err)
        })
    }
  }
  //下单
  insertOrderFun(){
    //WebCA
    if (common.enableWebca && !window.inAppDetector.isInApp()) {
      const memberid = window.sessionStorage.getItem('uid');
      const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
      window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
        if (code === 0 && data) {
          let d = JSON.parse(data);
          let rafunc = d.suggestAction;

          if (rafunc !== "None") { // Need to apply/renew cert
            const webcaInfoRequestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ token: window.sessionStorage.getItem('token'), rafunc: rafunc })
            };

            //Get parameters for calling WebCAInvoke
            fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
              .then(response => response.json())
              .then(data => {
                if (data.status === 0) {
                  data = data.data;
                  // window.setWebCAinWebview(true);
                  window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                  //呼叫WebCA執行對應raFunc的憑證作業
                  window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                    if (code===0 || code==="0000" || code===7000) {
                      this.executeInsertOrder();
                    } else {
                      this.setState({
                        isLoading: false
                      })
                      message.warning({
                        content: code + " " + msg
                      })
                    }
                    // window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
                    //   d = JSON.parse(data);
                    //   rafunc = d.suggestAction;
                    //   if (rafunc === "None") {
                    //     this.executeInsertOrder();
                    //   } else {
                    //     this.setState({
                    //       isLoading: false
                    //     })
                    //     message.warning({
                    //       content: "WebCA failed"
                    //     })
                    //   }
                    // });
                  });
                } else {
                  this.setState({
                    isLoading: false
                  })
                  message.warning({
                    content: data.message
                  })
                }
              })
          } else {
            this.executeInsertOrder();
          }
        } else {
          this.setState({
            isLoading: false
          })
          message.warning({
            content: code + " " + msg
          })
        }
      });
    } else {
      this.executeInsertOrder();
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {tradeOrderData}=this.props
    if(tradeOrderData!==newProps['tradeOrderData']){
      if(tradeOrderData==null||tradeOrderData.length<=0) return
      this.setState({
        listData:tradeOrderData[0]
      })
    }
  }
  render(){
    const {t,ChgThemeData,isMobile} = this.props
    const {listData,isLoading,chgCloseImg}=this.state
    return (
      <div 
        className={[
          styles.orderComfirm,
          APPstyles.flex_column,
          ChgThemeData=='Light'?styles.orderComfirm_light:''
        ].join(' ')} 
      >
        <div 
          className={[
            isMobile?'':APPstyles.flex_between,
            isMobile?APPstyles.flex_row:'',
            styles.orderComfirm_title,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
          ].join(' ')}
        >
          <img 
            src={ChgThemeData=='Light'?leftLightImg:leftImg} 
            alt='back' 
            className={APPstyles.img24} 
            style={{
              display:isMobile?'':'none'
            }} 
            onClick={this.orderComfirmClick.bind(this,'Cancel')} 
          />
          <span>{t('Order Confirmation')}</span>
          <img 
            src={chgCloseImg}  
            alt='close'
            onClick={this.orderComfirmClick.bind(this,'Cancel')} 
            className={APPstyles.img24}  
            style={{
              display:isMobile?'none':''
            }}  
            onMouseOver={()=>{
              let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
              this.setState({
                chgCloseImg:imgurl
              })
            }} 
            onMouseOut={()=>{
              this.setState({
                chgCloseImg:closeImg
              })
            }}
          />
        </div>
        <div className={[APPstyles.flex1_auto,isMobile?ChgThemeData=='Dark'?styles.tradeflow_con_mobileD:styles.tradeflow_con_mobileW:''].join(' ')}>
          <div className={[APPstyles.flex_between,styles.tradeflow_con].join(' ')}>
            <span>{t('Direction')}</span>
            <span 
              className={[
                APPstyles.fw700,
                listData['buySellIndex']&&listData['buySellIndex']['key']=='B'?APPstyles.color_red:APPstyles.color_green
              ].join(' ')} 
            >
              {t(listData['buySellIndex']&&listData['buySellIndex']['name'])}
            </span>
          </div>
          <div className={[APPstyles.flex_between,styles.orderComfirm_name,styles.tradeflow_con].join(' ')}>
            <span>{t('Name')}</span>
            <div className={styles.orderComfirm_name_right}>
              <span className={[APPstyles.fw700,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{marginBottom:2}}>{listData&&listData['symbol']}</span>
              <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur}>{listData&&listData['name']}</span>
            </div>
          </div>
          <div 
            className={[APPstyles.flex_between,styles.tradeflow_con].join(' ')} 
            style={{
              display:(listData['priceType']&&listData['priceType']['name']=='Limit')?'none':''
            }}
          >
            <span>{t(listData['priceType']&&listData['priceType']['name']=='Stop Limit'?'Limit Price1':'Stop Price1')}</span>
            <span>{common.transThousands(common.filterErro(listData['priceType']&&listData['priceType']['key1']))}</span>
          </div>
          <div className={[APPstyles.flex_between,styles.tradeflow_con].join(' ')}>
            <span>{t('Price1')}</span>
            <span>{common.transThousands(common.filterErro(listData&&listData['price']))}</span>
          </div>
          <div className={[APPstyles.flex_between,styles.tradeflow_con].join(' ')}>
            <span>{t('Quantity')}</span>
            <span>{common.transThousands(common.keepNum(listData&&listData['Quantity'],0))}</span>
          </div>
          <div className={[APPstyles.flex_between,styles.tradeflow_con].join(' ')}>
            <span>{t('Order Type')}</span>
            <span>{t(listData['GoodUntil']&&listData['priceType']['name'])}</span>
          </div>
          <div className={[APPstyles.flex_between,styles.tradeflow_con].join(' ')}>
            <span>{t('Good Until')}</span>
            <span>{t(listData['GoodUntil']&&listData['GoodUntil']['name'])}</span>
          </div>
        </div>
        <div 
          className={[
            APPstyles.flex_row,
            APPstyles.place_button,
            ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark
          ].join(' ')}
          style={{
            paddingTop:16
          }}
        >
          <Button type='default' className={APPstyles.btn_gray} onClick={this.orderComfirmClick.bind(this,'Cancel')}>{t('Cancel')}</Button>
          <Button 
            type='default' 
            className={[
              listData['buySellIndex']&&listData['buySellIndex']['key']=='B'?APPstyles.btn_red:APPstyles.btn_green,
              listData['buySellIndex']&&listData['buySellIndex']['key']=='B'?APPstyles.bg_red:APPstyles.bg_green
            ].join(' ')}
            onClick={this.orderComfirmClick.bind(this,'confirm')} 
            loading={isLoading}
          >
            {t('Submit Order')}
          </Button>
        </div>
      </div>
  )}
}
export default withTranslation()(OrderConfirmation)
import React from 'react'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  searchImg,
  searchOnImg,
  searchOnLightImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg
} from '@/asserts/imgs/common/index'
import Header from '@/components/Navigation/Header/index'
import Slider from '@/components/Navigation/Slider/index'
import SlidingPanel from '@/components/Navigation/SlidingPanel/index'
import Tabs from '@/components/Tabs/index'
import Portfolio from '@/components/Account/Portfolio/index'
import TodayOrder from '@/components/Account/TodayOrder/index'
import TodayDeal from '@/components/Account/TodayDeal/index'
import RealisedPL from '@/components/Account/RealisedPL/index'
import OrderHistory from '@/components/Account/OrderHistory/index'
import DealHistory from '@/components/Account/DealHistory/index'
import DividendHistory from '@/components/Account/DividendHistory/index'
import OrderMask from '@/components/Trade/OrderMask/index'
import SearchCon from '@/components/Quote/Add/index'
import Detail from '@/components/Account/TodayOrder/Detail/index'
import PortfolioDetail from '@/components/Account/Portfolio/msg/Detail/index'
import AlertsCountButton from '@/components/Alerts/components/AlertsCountButton'
import Statement from '@/components/Account/Statement/index';
import BankBalance from '@/components/Account/BankBalance/index';
import OverseasIncome from '@/components/Account/OverseasIncome/index';
import APPstyles from '@/App.less'
import styles from './index.less'

class Acount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountIndex : 0,
      tabsData:[
        {
          name:'Portfolio',
          isShow:false,
          id:'Portfolio'
        },
        {
          name:'Today’s Order',
          isShow:false,
          id:'TodayOrder'
        },
        {
          name:'Today’s Deal',
          isShow:false,
          id:'TodayDeal'
        },
        {
          name:'Realised P/L',
          isShow:false,
          id:'Realised'
        },
        {
          name:'Order History',
          isShow:false,
          id:'OrderHistory'
        },
        {
          name:'Deal History',
          isShow:false,
          id:'DealHistory'
        },
        {
          name:'Dividend History',
          isShow:false,
          id:'Dividend'
        },
        {
          name:'Statement',
          isShow:false,
          id:'Statement',
        },
        {
          name:'Bank Balance',
          isShow:false,
          id:'Bank',
        },
        {
          name:'Overseas Income',
          isShow:false,
          id:'Overseas',
        }
      ],
      sliderIndex:'account',
      chgTabNumData:[0,0,0],
      from_date:'',
      to_date:'',
      ChgLangData:'zh-CN',
      ChgThemeData:'Dark',
      isSlidingPanelShow:false,
      tradeData:[false,false,false,false],
      tradeOrderData:[],
      accountTradeData:[],
      isTradeShow:true,
      IsSubmitOrder:false,
      isSearchPageShow:false,
      isDetailShow:false,
      chgDetailData:'',
      isOpenAlertModalInTabletMode: false,
      sortData:[
        {
          name:'time',
          upImg:dropUpImg,
          downImg:dropDownImg
        },
        {
          name:'direction',
          upImg:dropUpImg,
          downImg:dropDownImg
        },
        {
          name:'status',
          upImg:dropUpImg,
          downImg:dropDownImg
        },
        {
          name:'payable_date',
          upImg:dropUpImg,
          downImg:dropDownImg
        }
      ],
      chgSearchImg:searchImg,
      watchListData:[],
      isWatchlistShow:true,
      isPortDetailShow:false,
      todaysCur:'USD',
      todayCountryCode:'US',
      simplequoteData:'',
      isGotoPageShow:false,
      tradeName:'',
      isSwitchModalShow:false,
    }
    this.myHeaderRef = React.createRef()
  }
  // 获取路由跳转--app.js
  callbackPathnameApp(data){
    this.props.callbackPathnameApp(data)
  }
  //获取tab栏的num数据
  callbackTabNumData(data){
    this.setState({
      chgTabNumData:data
    })
  }
  //slider--更改navIndex
  callbackChgNavIndex(id,isClose){
    let tradeData=!isClose&&(id=='trade'||id=='alerts')?[true,true,false,false]:[false,false,false,false]
    if(!isClose&&id==='trade'){this.setState({isOpenAlertModalInTabletMode:false})}
    if(!isClose&&id==='alerts'){this.setState({isOpenAlertModalInTabletMode:true})}
    this.setState({
      sliderIndex:id,
      tradeData:tradeData
    })
  }
  //是否显示左侧的menu
  callbackShowSlidingPanel(data){
    this.setState({
      isSlidingPanelShow:data
    })
  }
  //trade--show--portfolio
  tradeShowClick(item){
    const {isTradeShow}=this.state
    this.setState({
      isTradeShow: !isTradeShow,
      accountTradeData:[item,isTradeShow],
      tradeData:[true,true,false,false],
      sliderIndex:'trade',
      isOpenAlertModalInTabletMode: false
    })
  }
  //alert--show--portfolio
  alertsShowClick(){
    this.setState({
      tradeData:[true,true,false,false],
      sliderIndex:'trade',
      isOpenAlertModalInTabletMode: true
    })
  }
  // 恢复原来的数据
  resetSortDataFun(){
    const {sortData}=this.state
    for(let i in sortData){
      sortData[i]['upImg']=dropUpImg 
      sortData[i]['downImg']=dropDownImg
      sortData[i]['sortName']=''
      sortData[i]['sortKey']='' 
    }
    this.setState({
      sortData:sortData
    })
  }
  // 更改对应排序的图片
  chgSortDataImgFun(theme){
    const {sortData}=this.state
    for(let i in sortData){
      if(sortData[i]['sortName']==sortData[i]['name']){
        let getUpImg=sortData[i]['sortKey']=='asc'?theme=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
        let getDownImg=sortData[i]['sortKey']=='desc'?theme=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
        sortData[i]['upImg']=getUpImg
        sortData[i]['downImg']=getDownImg
      }
    }
    this.setState({
      sortData:sortData
    })
  }
  // 动态添加/取消
  chgWatchListFun(ric,tag,id){
    const {watchListData}=this.state
    let item=watchListData.find(item=>item['watchlist_id']==id)
    let ricData=item['stock_id_list']
    // 添加
    if(tag=='add'){
      ricData.unshift(ric)
    // 删除
    }else{
      for(let i in ricData){
        if(ricData[i]==ric){
          ricData.splice(i,1)  
        }
      }
    }
    this.setWatchListDataFun(watchListData)
  }
  // watchlist---set 
  setWatchListDataFun(data){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        this.getWatchListFun()
      }
    })
  }
  //watchlist---get
  getWatchListFun(){
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'user-watchlist?token='+token,{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']
        this.setState({
          watchListData:data
        })
        sessionStorage.setItem('watchListData',JSON.stringify(data))
      }
    })
  }
  getWatchListClick(){
    const {isWatchlistShow}=this.state
    this.setState({
      isSearchPageShow:true
    })
    if(isWatchlistShow){
      this.setState({
        isWatchlistShow:false
      })
      this.getWatchListFun()
    }
  }
  // 到委托回报页面
  gotoPageFun(){
    const {isGotoPageShow,accountIndex}=this.state
    if(accountIndex==1) return
    this.setState({
      accountIndex:1,
      isGotoPageShow:!isGotoPageShow
    })
  }
  gotoPageOnloadFun(){
    let isGotoPage=sessionStorage.getItem('isGotoPage')
    if(isGotoPage=='Y'){
      sessionStorage.removeItem('isGotoPage')
      this.gotoPageFun()
    }
  }
  //初始化数据
  componentDidMount() {
    let newDate=new Date()
    let year=newDate.getFullYear()
    let month=newDate.getMonth()+1>9?newDate.getMonth()+1:'0'+String(newDate.getMonth()+1)
    let dates=newDate.getDate()>9?newDate.getDate():'0'+String(newDate.getDate())
    let nowDate=String(year)+String(month)+String(dates)
    //更改主题--setting
    let theme=localStorage.getItem('theme')
    let lang=localStorage.getItem('lang')
    let tradeName=localStorage.getItem('tradeName')
    this.setState({
      from_date:common.getLastMonthTime(),
      to_date:nowDate,
      ChgThemeData:theme?theme:'Dark',
      ChgLangData:lang?lang:'zh-CN',
      tradeName:tradeName?tradeName:'general'
    })
    this.gotoPageOnloadFun()
  }
  render() {
    const {isPC,isMobile,isTablet} = this.props
    const {accountIndex,tabsData,sliderIndex,ChgLangData,chgTabNumData,from_date,to_date,ChgThemeData,isSlidingPanelShow,tradeData,tradeOrderData,accountTradeData,IsSubmitOrder,isSearchPageShow,isDetailShow,chgDetailData,sortData,chgSearchImg,watchListData,isPortDetailShow,todaysCur,todayCountryCode,simplequoteData,isGotoPageShow,tradeName,isSwitchModalShow}=this.state
    return (
      <div 
        className={[
          APPstyles.flex_column,
          APPstyles.app_layout,
          ChgThemeData=='Light'?APPstyles.app_layout_light:'',
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
        ].join(' ')}
      >
        <div ref={this.myHeaderRef} className={isPC?'':APPstyles.sticky_top_mobile}>
          <Header 
            {...this.props}
            ChgThemeData={ChgThemeData}
            sliderIndex={sliderIndex}
            isShowHeaderPage={true}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          />
          {/* header--tab */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.header_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
              ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
            ].join(' ')}
            style={{
              marginLeft:isPC?72:0
            }}
          >
            <div className={[APPstyles.flex_between,APPstyles.wPer100].join(' ')}>
              <div className={[APPstyles.flex_row,styles.header_con_left,APPstyles.wPer100].join(' ')}>
                <img 
                  src={chgSearchImg} 
                  alt='search' 
                  className={APPstyles.img24} 
                  style={{
                    margin:'0 16px',
                    display:isMobile?'none':''
                  }}
                  onClick={this.getWatchListClick.bind(this)}
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?searchOnLightImg:searchOnImg
                    this.setState({
                      chgSearchImg:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    let imgurl=isSearchPageShow?ChgThemeData=='Light'?searchOnLightImg:searchOnImg:searchImg
                    this.setState({
                      chgSearchImg:imgurl
                    })
                  }}
                />
                <div 
                  className={[APPstyles.flex_row,APPstyles.flex_1,styles.header_tabs].join(' ')}
                  style={{
                    paddingLeft:isMobile?16:0,
                    paddingRight:isMobile?16:0
                  }}
                >
                  <Tabs 
                    {...this.props} 
                    tag='account'
                    tabData={tabsData}
                    chgTabNumData={chgTabNumData}
                    ChgThemeData={ChgThemeData}
                    isGotoPageShow={isGotoPageShow}
                    callbackTabIndex={(index)=>{
                      this.setState({
                        accountIndex:index,
                        isSearchPageShow:false,
                        chgSearchImg:searchImg,
                        todaysCur:'USD',
                        todayCountryCode:'US'
                      })
                      this.resetSortDataFun()
                    }}
                  />
                </div>
                <div className={[APPstyles.img_shadow,ChgThemeData=='Light'?APPstyles.img_shadow_light:''].join(' ')} style={{display:isMobile?'':'none'}}>
                  <img 
                    src={isMobile&&ChgThemeData=='Dark'?searchOnImg:chgSearchImg} 
                    alt='search' 
                    className={APPstyles.img24} 
                    style={{
                      margin:'0 16px'
                    }}
                    onClick={this.getWatchListClick.bind(this)}
                  />
                </div>
              </div>
              {
                isPC&&<AlertsCountButton {...this.props} ChgThemeData={ChgThemeData} callbackAlertsShow={this.alertsShowClick.bind(this)}/>
              }
            </div>
          </div>
        </div>
        <div
          className={[
            isPC?APPstyles.marginLeft72:APPstyles.marginLeft0,
            isSearchPageShow?APPstyles.flex_row:APPstyles.flex_column,
            isTablet?APPstyles.hPer_overflowH:APPstyles.flex1_hidden
          ].join(' ')}
        >
          {/* left */}
          <div
            className={[
              !isMobile&&isSearchPageShow?APPstyles.wPer30:'',
              !isSearchPageShow?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
              isMobile&&isSearchPageShow?APPstyles.position_fixed:'',
              isMobile&&isSearchPageShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
            ].join(' ')}
            style={{
              position:'relative'
            }}
          >
            {isSearchPageShow&&<SearchCon
              {...this.props}
              currentIndex={4}
              sliderIndex={'account'}
              ChgThemeData={ChgThemeData}
              isSearchPageShow={isSearchPageShow}
              watchListData={watchListData}
              cbSetWatchListFun={this.chgWatchListFun.bind(this)}
              cbCloseSearchPage={(data)=>{
                this.setState({
                  isSearchPageShow:data,
                  chgSearchImg:searchImg
                })
              }}
            />}
          </div>
          {/* right--content*/}
          <div 
            className={[
              !isMobile&&isSearchPageShow?APPstyles.wPer70:APPstyles.wPer100,
              APPstyles.HPer100
            ].join(' ')}
          >
            {accountIndex==0&&
              <Portfolio
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                ChgThemeData={ChgThemeData}
                sliderIndex={sliderIndex}
                isSearchPageShow={isSearchPageShow}
                callbackTabNumData={this.callbackTabNumData.bind(this)}
                tradeShowClick={this.tradeShowClick.bind(this)}
                cbPortDetailShow={(data)=>{
                  this.setState({
                    isPortDetailShow:data
                  })
                }}
                cbtodaysCurFun={(currency,countryCode)=>{
                  this.setState({
                    todaysCur:currency,
                    todayCountryCode:countryCode
                  })
                }}
                cbSimplequoteDataFun={(data)=>{
                  this.setState({
                    simplequoteData:data
                  })
                }}
              />
            }
            {accountIndex==1&&
              <TodayOrder
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                sortData={sortData}
                callbackTabNumData={this.callbackTabNumData.bind(this)}
                cghTodayOrderData={(data)=>{
                  this.setState({
                    isDetailShow:true,
                    chgDetailData:data
                  })
                }}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun()
                    return
                  }
                  this.setState({
                    sortData:data
                  })
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data
                  })
                }}
              />
            }
            {accountIndex==2&&
              <TodayDeal
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                sortData={sortData}
                callbackTabNumData={this.callbackTabNumData.bind(this)}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun()
                    return
                  }
                  this.setState({
                    sortData:data
                  })
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data
                  })
                }}
              />
            }
            {accountIndex==3&&
              <RealisedPL
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
              />
            }
            {accountIndex==4&&
              <OrderHistory
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                sortData={sortData}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun()
                    return
                  }
                  this.setState({
                    sortData:data
                  })
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data
                  })
                }}
              />
            }
            {accountIndex==5&&
              <DealHistory 
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                sortData={sortData}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun()
                    return
                  }
                  this.setState({
                    sortData:data
                  })
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data
                  })
                }}
              />
            }
            {accountIndex==6&&
              <DividendHistory
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                sortData={sortData}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun()
                    return
                  }
                  this.setState({
                    sortData:data
                  })
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data
                  })
                }}
              />
            }
            
            {accountIndex==7&&
              <Statement
                {...this.props}
                accountIndex={accountIndex}
                ChgLangData={ChgLangData}
                from_date={from_date}
                to_date={to_date}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                sortData={sortData}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun();
                    return;
                  }
                  this.setState({
                    sortData:data,
                  });
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data,
                  });
                }}
              />
            }
            {accountIndex==8&&
              <BankBalance
                {...this.props}
                accountIndex={accountIndex}
                sortData={sortData}
                from_date={from_date}
                to_date={to_date}
                ChgLangData={ChgLangData}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
                cbChgSortDataFun={(data)=>{
                  if(data.length==0){
                    this.resetSortDataFun();
                    return;
                  }
                  this.setState({
                    sortData:data,
                  });
                }}
                cgChgSortDataFun={(data)=>{
                  this.setState({
                    sortData:data,
                  });
                }}
              />
            }
            {accountIndex==9&&
              <OverseasIncome
                {...this.props}
                accountIndex={accountIndex}
                from_date={from_date}
                to_date={to_date}
                ChgLangData={ChgLangData}
                ChgThemeData={ChgThemeData}
                isSearchPageShow={isSearchPageShow}
              />
            }
          </div>
        </div>
        <Slider 
          {...this.props}
          sliderIndex={sliderIndex}
          ChgThemeData={ChgThemeData}
          isShowHeaderPage={true}
          isSwitchModalShow={isSwitchModalShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackShowSlidingPanel={this.callbackShowSlidingPanel.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackAlertsShow={this.alertsShowClick.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            let imgurl=isSearchPageShow?data[1]=='Light'?searchOnLightImg:searchOnImg:searchImg
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2],
              chgSearchImg:imgurl
            })
            this.chgSortDataImgFun(data[1])
          }}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
        <SlidingPanel 
          {...this.props}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          isSlidingPanelShow={isSlidingPanelShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            let imgurl=isSearchPageShow?data[1]=='Light'?searchOnLightImg:searchOnImg:searchImg
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2],
              chgSearchImg:imgurl
            })
            this.chgSortDataImgFun(data[1])
          }}
        />
        {/* 遮罩层 */}
        <OrderMask
          {...this.props} 
          tradeData={tradeData}
          tradeOrderData={tradeOrderData}
          accountTradeData={accountTradeData}
          IsSubmitOrder={IsSubmitOrder}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          sliderIndex={sliderIndex}
          tradeName={tradeName}
          isOpenAlertModalInTabletMode={this.state.isOpenAlertModalInTabletMode}
          alertsPage={2}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          calkbackOrderData={(data)=>{
            //提交订单的数据--trade
            this.setState({
              tradeOrderData:data
            })
          }}
          calkbackBtnStatus={(data)=>{
            //哪个页面显示
            this.setState({
              tradeData:data
            })
            if(!data[0]){
              this.setState({
                accountTradeData:[]
              })
            }
          }}
          cbSubmitOrderFun={()=>{
            this.setState({
              IsSubmitOrder:!IsSubmitOrder,
              sliderIndex:'account'
            })
          }}
          cbGotoPage_1={()=>{
            this.gotoPageFun()
          }}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
        {isDetailShow&&
          <Detail
            {...this.props} 
            ChgThemeData={ChgThemeData}
            ChgLangData={ChgLangData}
            orderData={chgDetailData}
            cbCloseDetail={()=>{
              this.setState({
                isDetailShow:false
              })
            }}
          />
        }
        {isPortDetailShow&&
          <PortfolioDetail
            {...this.props}
            ChgThemeData={ChgThemeData}
            ChgLangData={ChgLangData}
            todaysCur={todaysCur}
            todayCountryCode={todayCountryCode}
            simplequoteData={simplequoteData}
            cbCloseDetail={()=>{
              this.setState({
                isPortDetailShow:false
              })
            }}
          />
        }
      </div>
    )
  }
}
export default withTranslation()(Acount)
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const FKD = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [K, setK] = useState(14);
  const [color, setColor] = useState("009999");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [D, setD] = useState(9);
  const [color2, setColor2] = useState("BB1100");
  const [thickness2, setThickness2] = useState(2);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(true);

  const [buy, setBuy] = useState(25);
  const [sale, setSale] = useState(75);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(K + "," + D);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.FKD_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      props.onTAValueChange({
        ta: "FKD",
        name: `${t("chart5.TA.FKD")} (FKD)`,
        option: {
          K: localChartSetting.option.K,
          D: localChartSetting.option.D,
          color: localChartSetting.option.color,
          thickness: localChartSetting.option.thickness,
          style: localChartSetting.option.style,
          showTracker: localChartSetting.option.showTracker,
          color2: localChartSetting.option.color2,
          thickness2: localChartSetting.option.thickness2,
          style2: localChartSetting.option.style2,
          showTracker2: localChartSetting.option.showTracker2,
          buy: localChartSetting.option.buy,
          sale: localChartSetting.option.sale,
          height: localChartSetting.option.height,
        },
        value: localChartSetting.value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const FKD = props.localChartSetting.FKD_Option
    setK(parseInt(FKD.option.K) || K);
    setColor(FKD.option.color);
    setThickness(parseInt(FKD.option.thickness) || thickness);
    setStyle(FKD.option.style);
    setShowTracker(FKD.option.showTracker);

    setD(parseInt(FKD.option.D) || D);
    setColor2(FKD.option.color2);
    setThickness2(parseInt(FKD.option.thickness2) || thickness2);
    setStyle2(FKD.option.style2);
    setShowTracker2(FKD.option.showTracker2);
    setBuy(parseInt(FKD.option.buy) || buy);
    setSale(parseInt(FKD.option.sale) || sale);
    setHeight(FKD.option.height);
  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(K + "," + D);
    props.onTAValueChange({
      ta: "FKD",
      name: `${t("chart5.TA.FKD")} (FKD)`,
      option: {
        K: K,
        D: D,
        color: color,
        thickness: thickness,
        style: style,
        showTracker: showTracker,
        color2: color2,
        thickness2: thickness2,
        style2: style2,
        showTracker2: showTracker2,
        buy: buy,
        sale: sale,
        height: height,
      },
      value: K + "," + D,
    });
  }, [
    K,
    D,
    color,
    thickness,
    style,
    showTracker,
    color2,
    thickness2,
    style2,
    showTracker2,
    buy,
    sale,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const FKD = props.localChartSetting.FKD_Option
    setK(parseInt(FKD.option.K) || K);
    setColor(FKD.option.color);
    setThickness(parseInt(FKD.option.thickness) || thickness);
    setStyle(FKD.option.style);
    setShowTracker(FKD.option.showTracker);

    setD(parseInt(FKD.option.D) || D);
    setColor2(FKD.option.color2);
    setThickness2(parseInt(FKD.option.thickness2) || thickness2);
    setStyle2(FKD.option.style2);
    setShowTracker2(FKD.option.showTracker2);
    setBuy(parseInt(FKD.option.buy) || buy);
    setSale(parseInt(FKD.option.sale) || sale);
    setHeight(FKD.option.height);
  }, [props.isReset]);

  const resetALL = () => {
    setK(14);
    setColor("009999");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setD(9);
    setColor2("BB1100");
    setThickness2(2);
    setStyle2("s");
    setShowTracker2(true);
    setBuy(25);
    setSale(75);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "K") setK(e.target.value);
    else setD(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "K") setThickness(e.target.value);
    else setThickness2(e.target.value);
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "K") setStyle(e.target.value);
    else setStyle2(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // type: string
  const onShowTracker = (type) => {
    if (type === "K")
      if (showTracker) setShowTracker(false);
      else setShowTracker(true);
    else if (showTracker2) setShowTracker2(false);
    else setShowTracker2(true);
  };

  // e: any, type: string
  const onValueChange = (e, type) => {
    if (type === "buy") setBuy(e.target.value);
    else setSale(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "FKD-C1":
        setColor(props.selectedColor);
        break;
      case "FKD-C2":
        setColor2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("FKD")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.FKD")} (FKD) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("FKD")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-SKD">
          <div className="flexbox">
            <div className="taparamlbl">%K</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-UI-p0-period0"
                type="number"
                step="1"
                min="1"
                value={K}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "K")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "FKD-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "K")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "K")}>
              <select id="msi-ta-UI-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("K")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-SKD">
          <div className="flexbox">
            <div className="taparamlbl">%D</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-UI-p0-period0"
                type="number"
                step="1"
                min="1"
                value={D}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "D")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "FKD-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "D")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "D")}>
              <select id="msi-ta-UI-p0-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => onShowTracker("D")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="floatmsibox">
          <div className="menusubitem" id="msi-ta-SKD">
            <div className="taparamlbl">
              {t("chart5.buy")} {t("chart5.signal")}
            </div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-SKD-sb"
                type="number"
                step="1"
                min="0"
                max="100"
                value={buy}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onValueChange(e, "buy")}
              />
            </div>
          </div>
          <div className="menusubitem" id="msi-ta-SKD">
            <div className="taparamlbl">
              {t("chart5.sell")} {t("chart5.signal")}
            </div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-SKD-ss"
                type="number"
                step="1"
                min="0"
                max="100"
                value={sale}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onValueChange(e, "sale")}
              />
            </div>
          </div>
        </div>
        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                defaultValue={height}
                onChange={(e) => onHeightChange(e)}
                value={height}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

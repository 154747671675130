import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '../../../../../asserts/js/common'
import { 
    leftImg,
    leftLightImg
} from '../../../../../asserts/imgs/common/index'
import APPstyles from '../../../../../App.less'
import styles from './index.less'

class Details extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            typeKeyData:['EconomicEvents','ExDividends','StockSplits'] 
        }
    }
    render(){
        const {t,ChgThemeData,detailData} = this.props
        const {typeKeyData} = this.state
        let event_type=detailData&&detailData['event_type'] 
        return (
            <div 
                className={[
                    styles.right,
                    APPstyles.position_fixed,
                    ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
                ].join(' ')}
            >
                <div 
                    className={[
                        APPstyles.flex_row,
                        styles.right_header,
                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                    ].join(' ')}
                >
                    <img 
                        src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                        className={APPstyles.img24} 
                        onClick={()=>{
                            this.props.cbCloseDetailData(false) 
                        }} 
                        alt='back' 
                    />
                    {t('Street Event')}
                </div>
                <div 
                    className={[
                        styles.right_content,
                        APPstyles.flex1_auto
                    ].join(' ')}
                >
                    <span
                        className={[
                            styles.title,
                            ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1
                        ].join(' ')} 
                    >
                        {detailData['title']}
                    </span>
                    <div 
                        className={[
                            styles.content,
                            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')}
                    >
                        {detailData['event_name']}
                    </div>
                    <span className={styles.time}>
                        {common.chgEventDate(detailData['start_date'],'ymdhm')}
                    </span>
                    <div
                        style={{
                            display:typeKeyData.includes(event_type)?'':'none'
                        }}
                    >
                        <div
                            className={[
                                styles.table_con,
                                styles.table_header,
                                APPstyles.flex_row,
                                event_type=='EconomicEvents'?'':styles.table_con_1,
                                ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_grey1
                            ].join(' ')}
                        >
                            <span>{t(event_type=='EconomicEvents'?'Period':'Announce Date')}</span>
                            <span>{t(event_type=='EconomicEvents'?'Prior Value':event_type=='ExDividends'?'Dividend Amount':'Split Ratio')}</span>
                            <span>
                                {t(
                                    event_type=='EconomicEvents'?
                                        'Expected Value'
                                    :event_type=='ExDividends'?
                                        'Record Date'
                                    :
                                        'Record Date1'
                                )}
                                </span>
                            <span>
                                {t(
                                    event_type=='EconomicEvents'?
                                        'Actual Value'
                                    :event_type=='ExDividends'?
                                        'Ex-Div Date'
                                    :
                                        'Ex-Div Date1'
                                )}
                                </span>
                            <span style={{display:event_type=='EconomicEvents'?'none':''}}>
                                {t(event_type=='ExDividends'?'Payout Date':'Payout Date1')}
                            </span>
                        </div>
                        <div
                            className={[
                                styles.table_con,
                                APPstyles.flex_row,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                            ].join(' ')}
                        >
                            <span>
                                {
                                    event_type=='ExDividends'?
                                        common.chgEventDate(detailData['div_announcement'],'ymd')
                                    :event_type=='StockSplits'?
                                        common.chgEventDate(detailData['split_announcement'],'ymd')
                                    :
                                        common.chgMonthYear(detailData['period'])
                                }
                            </span>
                            <span>
                                {
                                    event_type=='ExDividends'?
                                        detailData['div_curr']+' '+common.transThousands(common.keepNum(detailData['div_amount'],2))
                                    :event_type=='StockSplits'?
                                        detailData['split_ratio']
                                    :
                                        common.transThousands(common.keepNum(detailData['prior_val'],2))
                                }
                                {event_type=='EconomicEvents'&&detailData['prior_val']?detailData['unit']:''}
                            </span>
                            <span>
                                {
                                    event_type=='ExDividends'?
                                        common.chgEventDate(detailData['div_recorddate'],'ymd')
                                    :event_type=='StockSplits'?
                                        common.chgEventDate(detailData['split_recorddate'],'ymd')
                                    :
                                        common.transThousands(common.keepNum(detailData['expected_val'],2))
                                }
                                {event_type=='EconomicEvents'&&detailData['expected_val']?detailData['unit']:''}
                            </span>
                            <span style={{display:event_type=='EconomicEvents'?'none':''}}>
                                {common.chgEventDate(detailData[event_type=='ExDividends'?'div_exdate':'split_exdate'],'ymd')}
                            </span>
                            <span>
                                {
                                    event_type=='ExDividends'?
                                        common.chgEventDate(detailData['div_paymentdate'],'ymd')
                                    :event_type=='StockSplits'?
                                        common.chgEventDate(detailData['split_paymentdate'],'ymd')
                                    :
                                        common.transThousands(common.keepNum(detailData['actual_val'],2))
                                }
                                {event_type=='EconomicEvents'&&detailData['actual_val']?detailData['unit']:''}
                            </span>
                        </div>
                    </div>
                </div> 
            </div>
        )}
}
export default withTranslation()(observer(Details))
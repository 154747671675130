import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '../../../asserts/js/common'
import { 
    refreshImg,
    refreshOnImg,
    refreshOnLightImg,
    leftImg,
    leftLightImg,
} from '../../../asserts/imgs/common/index'
import { 
    menu1Img,
    menu2Img,
    menu1OnLightImg,
    menu2OnLightImg,
    menu1OnImg,
    menu2OnImg
} from '../../../asserts/imgs/quote/index'
import Chart from './chart/index'
import MsgCon from './msg/index'
import QuoteDetail from '../QuoteDetail/index'
import Left from './left/index'
import Stocks from './stocks/index'
import Grid from './grid/index'
import AddCon from '../Add/index'
import WatchlistEdit from '../WatchlistEdit/index'
import Tabs from '../../Tabs2/index'
import APPstyles from '../../../App.less'
import styles from './index.less'

let page=1

class Sector extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            menuImg:menu1Img,
            chgRefreshImg:refreshImg,
            timeData:[
                {
                    name:'1D1',
                    isShow:false,
                    key:'PCTCHNG'
                },
                {
                    name:'1W1',
                    isShow:false,
                    key:'pricePctChg1W'
                },
                {
                    name:'1M1',
                    isShow:false,
                    key:'pricePctChg1M'
                },
                {
                    name:'3M1',
                    isShow:false,
                    key:'pricePctChg3M'
                },
                {
                    name:'1Y2',
                    isShow:false,
                    key:'pricePctChg1Y'
                },
                {
                    name:'5Y2',
                    isShow:false,
                    key:'pricePctChg5Y'
                }
            ],
            isMenuShow:true,
            menuTag:'grid',
            isRefresh:false,
            gridData:[],
            isLoading:false,
            msgData:'',
            monthsData:[
                {
                    val:'',
                    bgColor:'',
                    key:'pricePctChg1M'
                },
                {
                    val:'',
                    bgColor:'',
                    key:'pricePctChg3M'
                },
                {
                    val:'',
                    bgColor:'',
                    key:'pricePctChg6M'
                },
                {
                    val:'',
                    bgColor:'',
                    key:'pricePctChg1Y'
                }
            ],
            timeKey:'PCTCHNG',
            timeTitle:'1D',
            agg_code:'',
            isLeftPageShow:'',
            treemapData:[],
            sectorConH:'auto',
            ricData:{},
            counts:0,
            listData:[],
            pageno:1,
            recperpage:15,
            loadMore:false,
            noMore:false,
            isOnLoad:true,
            isMobileDetailShow:false,
            isMobileSectorShow:false,
            tagIshowHeader:'detail',
            isGridLoading:true,
            chgTabData:false
        }
        this.myRef = React.createRef()
        this.myRef_1 = React.createRef()
    }
    //菜单
    menuClick(){
        const {gridData}=this.state
        for(let i in gridData){
            gridData[i]['isChartShow']=false
        }
        this.setState(state => ({
            isMenuShow: !state.isMenuShow,
            menuImg:state.isMenuShow?menu2Img:menu1Img,
            menuTag:!state.isMenuShow?'grid':'list',
            gridData:gridData,
            isLeftPageShow:''
        }))
        this.props.callbackLeftPageShow('list')
    }
    //处理数据--sector
    chgSectorDataFun(data){
        const {monthsData}=this.state
        for(let i in monthsData){
            let key=data[monthsData[i]['key']]
            monthsData[i]['val']=key
            monthsData[i]['bgColor']=common.chgBgColorData(key)
        }
        this.setState({
            msgData:{
                name_cht:data['name_cht'],
                name_en:data['name_en'],
                TRDPRC_1:data['TRDPRC_1'],
                NETCHNG_1:data['NETCHNG_1'],
                PCTCHNG:data['PCTCHNG'],
                TRADE_DATE:data['TRADE_DATE'],
                ric:data['ric']
            },
            monthsData:monthsData,
            agg_code:data['agg_code']
        }) 
        this.dealStockFun(data['agg_code'])
    }
    //处理数据--grid
    chgGridDataFun(data,timeKey){
        let chartData=[]
        for(let i in data){
            let key=data[i][timeKey]
            data[i]['isChartShow']=false
            data[i]['index']=i
            data[i]['val']=key
            data[i]['bgColor']=common.chgBgColorData(key)
            let obj={
                name: data[i]['name_en'],
                color: data[i]['bgColor'],
                value: Number(data[i]['TURNOVER_USD']),
                tooltopValue:key,
                name_cht:data[i]['name_cht'],
                name_en:data[i]['name_en'],
                TRDPRC_1:data[i]['TRDPRC_1'],
                NETCHNG_1:data[i]['NETCHNG_1'],
                PCTCHNG:data[i]['PCTCHNG'],
                TRADE_DATE:data[i]['TRADE_DATE'],
                ric:data[i]['ric'],
                agg_code:data[i]['agg_code'],
                pricePctChg1M:data[i]['pricePctChg1M'],
                pricePctChg3M:data[i]['pricePctChg3M'],
                pricePctChg6M:data[i]['pricePctChg6M'],
                pricePctChg1Y:data[i]['pricePctChg1Y']
            }
            chartData.push(obj)
        }
        this.setState({
            gridData:data,
            treemapData:chartData,
            isGridLoading:false
        })
    }
    //获取数据
    getheatmap(){
        const {timeKey}=this.state
        let token=window.sessionStorage.getItem('token')
        fetch(common.getHttp+'heatmap',{
            method:'POST',
            headers:{
            'Accept':'application/json,text/plain,*/*',
            'Content-Type':'application/json'
            },
            body:JSON.stringify({
            token:token
            })
        })
        .then(res => res.json())
        .then(res => {
          //获取数据成功
            if(res['status']==0){
                let data=res['data']['datalist']
                if(data.length){
                    this.chgGridDataFun(data,timeKey)
                    this.chgSectorDataFun(data[0])
                    return
                }
                this.setState({
                    gridData:[],
                    isLoading:false,
                    isGridLoading:false
                })
                return
            }
            //获取失败
            this.setState({
                gridData:[],
                isLoading: false,
                isGridLoading:false
            })
        }).catch(err => {
            //获取失败
            this.setState({
                gridData:[],
                isLoading: false,
                isGridLoading:false
            })
        })
    }
    //获取高度---ios
    getContentHFun(){
        const {bodyH,isTablet}=this.props
        let getH=isTablet?bodyH-5-66-49-56:bodyH-10-80-49
        this.setState({
            sectorConH:getH
        })
    }
    dealStockFun(agg_code){
        const {isPC} = this.props
        page=1
        this.setState({
            isOnLoad:true,
            listData:[],
            loadMore:false,
            noMore:false,
            isLoading:true
        })
        let screenH=window.screen.height
        let getRecperpage=isPC&&Number(screenH)>1135?20:15
        this.backTopFun()
        this.getStock(agg_code,1,getRecperpage,true,[])
    }
    //获取数据
    getStock(agg_code,pageno,recperpage,boolean,listsData){
        let token=window.sessionStorage.getItem('token')
        fetch(common.getHttp+'heatmap/sectorstock',{
            method:'POST',
            headers:{
                'Accept':'application/json,text/plain,*/*',
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                token:token,
                code:Number(agg_code),
                pageno:pageno,
                recperpage:recperpage
            })
        })
        .then(res => res.json())
        .then(res => {
            //获取数据成功
            if(res['status']==0){
                let data=res['data']['datalist']
                let pn1=data.length?data.length>=recperpage?false:true:false
                let pnother=listsData.length?data.length?false:true:true
                let noMoreData=pageno==1?pn1:pnother
                this.setState({
                    listData:listsData.concat(data),
                    isLoading:false,
                    counts:res['data']['count']['count'],
                    loadMore:false,
                    noMore:noMoreData
                })

                let getRicData=[];
                let curlist;
                for (let i in curlist=listsData.concat(data)) {
                    getRicData.push(curlist[i]['ric']);
                }
                this.props.store.addSubscribeList(getRicData);
                this.props.store.setQuoteData(curlist);

                //初始化执行
                if(boolean){
                    this.setState({
                        isOnLoad:false,
                        ricData:{
                            name:data[0]['name'],
                            ric:data[0]['ric'],
                            symbol:data[0]['symbol']
                        }
                    })
                }
                return
            }
            //获取失败
            this.setState({
                counts:0,
                listData:[],
                ricData:{},
                isLoading: false
            })
        }).catch(err => {
            this.setState({
                counts:0,
                listData:[],
                ricData:{},
                isLoading: false
            })
        })
    }
    // 页面滚动触发的事件
    handleScroll(){
        const {isMobile} = this.props
        const {recperpage,loadMore,noMore,isLoading,listData,agg_code}=this.state
        let getRef=isMobile?this.myRef:this.myRef_1
        if(getRef.current.scrollHeight - getRef.current.scrollTop- getRef.current.clientHeight < 10){
            if(loadMore||noMore||isLoading) return
            page++
            this.setState({
                pn: page,
                loadMore:true,
                noMore:false
            })
            this.getStock(agg_code,page,recperpage,false,listData)
        }
    }
    //回到顶部
    backTopFun(){
        const {isMobile}=this.props
        let getId=isMobile?'centerId':'centerId_1'
        let id=document.getElementById(getId)
        if(id){
            setTimeout(() => id.scrollTop=0, 10)
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isLeftPageShow,bodyW,bodyH} = this.props
        if(isLeftPageShow!==newProps['isLeftPageShow']){
            this.setState({
                isLeftPageShow:isLeftPageShow
            })
        } 
        if(bodyW!==newProps['bodyW']||bodyH!==newProps['bodyH']){
            this.getContentHFun()
        }
    }
    //初始化
    componentDidMount(){
        this.setState({
            chgTabData:false
        })
        this.getheatmap()
        this.getContentHFun()
    }
    render(){
        const {t,ChgThemeData,isTablet,isMobile,isPC} = this.props
        const {menuImg,chgRefreshImg,timeData,isMenuShow,isRefresh,menuTag,gridData,msgData,monthsData,timeKey,timeTitle,agg_code,isLoading,treemapData,sectorConH,ricData,isLeftPageShow,counts,listData,loadMore,noMore,isMobileDetailShow,isMobileSectorShow,tagIshowHeader,isGridLoading,chgTabData}=this.state
        return (
            <div 
                className={[
                    APPstyles.flex_row,
                    styles.sector_con,
                    isTablet?APPstyles.marginLeft0:'',
                    isMobile?APPstyles.flex_column:'',
                    isMobile?APPstyles.flex1_auto:'',
                    isMobile?'':styles.sector_con_noMobile
                ].join(' ')}
                style={{
                    // height:sectorConH
                }}
            >
                <div 
                    className={[
                        isMobile?'':APPstyles.flex_column,
                        styles.sector_con_body,
                        APPstyles.wPer100
                    ].join(' ')}
                >
                    {/* header */}
                    <div 
                        className={[
                            APPstyles.flex_row,styles.header,
                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                        ].join(' ')}
                    >
                        <Tabs
                            {...this.props}
                            tabData={timeData}
                            isRefresh={isRefresh}
                            tag='sector'
                            callbackTabIndex={(index)=>{
                                let key=timeData[index]['key']
                                this.setState({
                                    timeKey:key,
                                    timeTitle:timeData[index]['name'],
                                    isLeftPageShow:''
                                })
                                this.chgGridDataFun(gridData,key)
                                this.props.callbackLeftPageShow('list')
                            }}
                        />
                        {/* <span className={[styles.header_line,ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black].join(' ')}></span> */}
                        <img 
                            src={chgRefreshImg} 
                            className={[
                                APPstyles.img16,
                                isRefresh?APPstyles.refresh_img:''
                            ].join(' ')} 
                            style={{
                                margin:isMobile?'0 11px':'0 8px'
                            }}
                            onClick={()=>{
                                if(timeTitle=='1D') return
                                //刷新
                                this.setState({
                                    isRefresh:true,
                                    timeKey:'PCTCHNG',
                                    timeTitle:'1D',
                                    chgRefreshImg:ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg,
                                    isLeftPageShow:''
                                })
                                setTimeout(() => {
                                    this.setState({
                                        isRefresh:false,
                                        chgRefreshImg:refreshImg
                                    })
                                }, 1000)
                                this.chgGridDataFun(gridData,'PCTCHNG')
                                this.props.callbackLeftPageShow('list')
                            }} 
                            alt='refresh'
                            onMouseOver={()=>{
                                if(!isPC) return
                                this.setState({
                                    chgRefreshImg:ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg
                                })
                            }} 
                            onMouseOut={()=>{
                                if(!isPC) return
                                let imgUrl=isRefresh?ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg:refreshImg
                                this.setState({
                                    chgRefreshImg:imgUrl
                                })
                            }} 
                        />
                        <img 
                            src={menuImg} 
                            className={APPstyles.img16} 
                            onClick={this.menuClick.bind(this)} 
                            alt='menu' 
                            onMouseOver={()=>{
                                if(!isPC) return
                                let list=ChgThemeData=='Light'?menu1OnLightImg:menu1OnImg
                                let grid=ChgThemeData=='Light'?menu2OnLightImg:menu2OnImg
                                this.setState({
                                    menuImg:isMenuShow?list:grid
                                })
                            }} 
                            onMouseOut={()=>{
                                if(!isPC) return
                                this.setState({
                                    menuImg:isMenuShow?menu1Img:menu2Img
                                })
                            }}
                        />
                    </div>
                    {/* body */}
                    <div 
                        className={[
                            styles.list_con,
                            (isMobile&&isMobileSectorShow)||(isTablet&&isMobileDetailShow)?styles.list_con_mobile:'',
                            (isMobile&&isMobileSectorShow)||(isTablet&&isMobileDetailShow)?APPstyles.flex_column:APPstyles.flex_row,
                            (isMobile&&isMobileSectorShow)||(isTablet&&isMobileDetailShow)?APPstyles.position_fixed:'',
                            (isMobile&&isMobileSectorShow)||(isTablet&&isMobileDetailShow)?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                        ].join(' ')}
                    >
                        <div 
                            className={[
                                APPstyles.flex_topcenter,
                                styles.list_con_header,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                            ].join(' ')}
                            style={{
                                display:isMobile&&isMobileSectorShow?'':'none'
                            }}
                            onClick={()=>{
                                this.setState({
                                    isMobileSectorShow:false
                                })
                                //ios终端
                                isMobile&&this.props.cbIsShowHeaderPage(true)
                            }} 
                        >
                            <img 
                                src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                                className={APPstyles.img24}  
                                alt='back' 
                            />
                            {t('Sector')}
                        </div>
                        <div 
                            className={[
                                styles.left,
                                isTablet&&!isMobile?isMenuShow?APPstyles.wPer100:APPstyles.wPer40:isMobile?APPstyles.wPer100:'',
                                isMobile?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
                                isTablet?APPstyles.flex1_hidden:'',
                                isMobile?APPstyles.flex_column:''
                            ].join(' ')}
                            style={{
                                display:(isMobile&&isMobileSectorShow)||(isTablet&&isMobileDetailShow)?'none':''
                            }}
                        >
                            {
                                isLeftPageShow=='add'?
                                    <AddCon  
                                        {...this.props}
                                        cbIsShowHeaderPage={(data)=>{
                                            this.props.cbIsShowHeaderPage(data)
                                        }} 
                                        cbTagIshowHeader={(data)=>{
                                            this.setState({
                                                tagIshowHeader:data
                                            })
                                        }}
                                        callbackChgRic={(data)=>{
                                            //选择某个ric
                                            this.setState({
                                                ricData:data,
                                                isMobileDetailShow:true,
                                                chgTabData:!chgTabData
                                            })
                                        }} 
                                        callbackLeftPageShow={(data)=>{
                                            this.setState({
                                                isLeftPageShow:''
                                            })
                                            this.props.callbackLeftPageShow(data)
                                        }}
                                    /> 
                                :isLeftPageShow=='edit'?
                                    <WatchlistEdit 
                                        {...this.props}
                                        callbackChgTabName={(data)=>{
                                            this.props.callbackChgTabName(data)
                                        }} 
                                        callbackLeftPageShow={(data)=>{
                                            this.setState({
                                                isLeftPageShow:''
                                            })
                                            this.props.callbackLeftPageShow(data)
                                        }}
                                        cbIsShowHeaderPage={(data)=>{
                                            this.props.cbIsShowHeaderPage(data)
                                        }}
                                        cbOnLoadGetWatchlistFun={()=>{
                                          this.props.cbOnLoadGetWatchlistFun()
                                        }}
                                    />
                                :menuTag=='list'?
                                    <Left 
                                        {...this.props}
                                        timeTitle={timeTitle}
                                        gridData={gridData}
                                        agg_code={agg_code}
                                        timeKey={timeKey}
                                        chgSectorData={(data)=>{
                                            //点击左边的列表更新sector
                                            this.chgSectorDataFun(data)
                                            //ios终端
                                            isMobile&&this.props.cbIsShowHeaderPage(false)

                                            if(isMobile){
                                                this.setState({
                                                    isMobileSectorShow:true
                                                })
                                            }
                                        }}
                                    />
                                :
                                    <Grid 
                                        {...this.props}
                                        isLoading={isGridLoading}
                                        gridData={gridData}
                                        treemapData={treemapData}
                                        isMobileDetailShow={isMobileDetailShow}
                                        chgSectorData={(data)=>{
                                            //点击左边的列表更新sector
                                            this.chgSectorDataFun(data)
                                            //ios终端
                                            isMobile&&this.props.cbIsShowHeaderPage(false)
                                            if(isMobile){
                                                this.setState({
                                                    isMobileSectorShow:true
                                                })
                                            }
                                        }}
                                        chgMenuTag={(data)=>{
                                            this.setState(state => ({
                                                isMenuShow: !state.isMenuShow,
                                                menuImg:state.isMenuShow?menu1Img:menu2Img,
                                                menuTag:data
                                            }))
                                        }}
                                    />
                        }  
                        </div>
                        <div
                            id='centerId'
                            ref={this.myRef} 
                            onScroll={() => isMobile?this.handleScroll():''}
                            className={[
                                styles.center,
                                !isMobile?'':APPstyles.flex1_auto,
                                !isMobile?APPstyles.flex_column:'',
                                !isMobile?APPstyles.hPer_overflowH:'',
                                (isMobile&&isMobileSectorShow)||(isTablet&&isMobileDetailShow)?APPstyles.wPer100:isTablet&&!isMobile?APPstyles.wPer60:'',
                                isMobile?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark
                            ].join(' ')}
                            style={{
                                display:isPC?''
                                        :isTablet&&!isMobile?
                                            menuTag=='list'?'':'none'
                                        :isMobileSectorShow?
                                            ''
                                            :'none'     
                            }}
                        >
                            <MsgCon 
                                {...this.props} 
                                msgData={msgData}
                            />
                            <div 
                                id='centerId_1'
                                ref={this.myRef_1} 
                                onScroll={() => isMobile?'':this.handleScroll()}
                                className={[!isMobile?APPstyles.flex1_auto:''].join(' ')}
                            >
                            <Chart 
                                {...this.props} 
                                id='list1'
                                msgData={msgData}
                                monthsData={monthsData}
                            />
                            <Stocks 
                                {...this.props} 
                                counts={counts}
                                isLoading={isLoading}
                                listData={listData}
                                loadMore={loadMore}
                                noMore={noMore}
                                cbCghRicData={(data)=>{
                                    this.setState({
                                        ricData:data,
                                        isMobileDetailShow:true,
                                        chgTabData:!chgTabData
                                    })
                                    //ios终端
                                    if(isMobile){
                                        this.setState({
                                            tagIshowHeader:'detail'
                                        })
                                    }
                                    //ios终端
                                    isTablet&&this.props.cbIsShowHeaderPage(false)
                                }}
                                chgListData={(data)=>{
                                    this.setState({
                                        listData:data
                                    })
                                }}
                                cbDetailPageShow={(data)=>{
                                    this.setState({
                                        isMobileDetailShow:data
                                    })
                                }}
                            />
                            </div>
                        </div>
                        <div 
                            className={[
                                isTablet&&isMobileDetailShow?APPstyles.position_fixed:'',
                                isTablet&&isMobileDetailShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                            ].join(' ')}
                            style={{
                                display:(isTablet&&isMobileDetailShow)||isPC?'':'none'
                            }}
                        >
                            <QuoteDetail 
                                {...this.props} 
                                isSector={true} // check if the quote page is in the sector page
                                ricData={ricData} 
                                tagIshowHeader={tagIshowHeader}
                                chgTabData={chgTabData}
                                callbackTradeShow={()=>{
                                    this.props.callbackTradeShow('tag',ricData)
                                }}
                                callbackFbstockid={(data)=>{
                                    // 判断fbstockid是否有值，没有则隐藏isTradeShow
                                    this.props.callbackFbstockid(data)
                                }}
                                callbackTab={(data)=>{
                                    //top--tab栏切换
                                    this.props.callbackTab(data)
                                }}
                                cbCloseDetailPage={(data)=>{
                                    this.setState({
                                        isMobileDetailShow:data
                                    })
                                    this.props.cbDetailPageShow(false)
                                }}
                                cbCghTradeData={(data)=>{
                                    this.props.cbCghTradeData(data)
                                }}
                                cbIsShowHeaderPage={(data)=>{
                                    this.props.cbIsShowHeaderPage(data)
                                }}
                                cbShowDetailData={(data)=>{
                                    this.props.cbShowDetailData(data)
                                }}
                                cbRightDetailShowFun={(data,loading)=>{
                                  this.props.cbRightDetailShowFun(data,loading)
                                }}
                                cbSetWatchListFun={(data,tag,id)=>{
                                  this.props.cbSetWatchListFun(data,tag,id)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(Sector))
import React from "react";
import { withTranslation } from "react-i18next"
import { message,Spin } from 'antd'
import common from '@/asserts/js/common'
import { 
  refreshImg,
  refreshOnImg,
  refreshOnLightImg,
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  closeOnLightImg,
  closeOnImg,
  closeImg,
  autoImg,
  autoLightImg
} from '@/asserts/imgs/common/index'
import PaginationCon from '../Pagination/index'
import Mobile from '../Mobile'
import InputCon from '../InputCon/index'
import TipsModal from '../../tipsModal/index'
import APPstyles from '@/App.less'
import styles from '@/pages/Account/index.less'
import myStyles from './index.less'

let leftConW=878,chgPage=1,timer=null,timerOnce=null,isEndLoad=false,isEndLoadOnce=false

class TodayOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history:[],
      isLoading:true,
      country_code:'US',
      stock_id:null,
      delData:[],
      sizeTotal:0,
      recperpage:20,
      page:1,
      isonLoadShow:true,
      isDelShow:false,
      statusData:[
        {
          status:10,
          content:'Sending order in progress'
        },
        {
          status:15,
          content:'Order received'
        },
        {
          status:16,
          content:'Order pending'
        },
        {
          status:30,
          content:'Order placement successful'
        },
        {
          status:50,
          content:'Cancelling order in progress'
        },
        {
          status:70,
          content:'Cancelling order in progress'
        },
        {
          status:75,
          content:'Cancellation order received'
        },
        {
          status:76,
          content:'Pending cancellation'
        },
        {
          status:96,
          content:'Order placement failed'
        },
        {
          status:97,
          content:'Partial transaction completed'
        },
        {
          status:98,
          content:'Transaction completed'
        },
        {
          status:99,
          content:'Order cancellation successful'
        },
        {
          status:0,
          content:'Order sent'
        }
      ],
      lastStatus22:'Order cancellation failed',
      isdelStatusShow:false,
      errMsgData:['H7','K7','LB','LC'],
      isRefresh:false,
      dropDownL:0,
      dropDownR:'unset',
      dropDownT:38,
      dropDownW:'100%',
      chgRefreshImg:refreshImg,
      loadMore:false,
      noMore:false,
      chgSort:'time_desc',
      onLoad:true,
      minW1600:1600,
      getorderSource:'',
      isSortShow:false,
      isDelData:'N',
      chgAutoImg:autoImg,
      isAutoShow:false,
      isRefreshImg:false
    }
    this.myTORef = React.createRef()
  }
  // detail---show
  detailClick(data) {
    this.props.cghTodayOrderData(data)
  }
  //modal--open--是否删除数据
  openModalClick(data,e) {
    //阻止点击事件冒泡
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      isDelShow:true,
      delData:data,
      getorderSource:data['order_source']
    })
  }
  //排序
  sortClick(name,tag,sortName){
    const {sortData,isTablet,ChgThemeData}=this.props
    const {country_code,stock_id,page,history,onLoad,chgSort,isLoading,isSortShow}=this.state
    if(history.length<=0||(!onLoad&&chgSort==tag)||isLoading) return
    this.setState({
      isLoading: true,
      chgSort:tag,
      onLoad:false,
      loadMore:false,
      noMore:false,
      isSortShow:!isSortShow
    })
    let newData=common.getSortDataFun(sortData,ChgThemeData,name,sortName)
    chgPage=1
    let chgPn=isTablet?1:page
    clearInterval(timer)
    clearInterval(timerOnce)
    this.getTableData(country_code,stock_id,tag,chgPn,[])
    this.props.cbChgSortDataFun(newData)
  }
  executeRemoveOrder() {
    const {isPC}=this.props
    const {delData,country_code,stock_id,chgSort,getorderSource,page}=this.state
    if(!isPC){
      chgPage=1
      this.setState({
        page:1
      })
    }
    if (common.enableWebca) {
      //SIGN WITH CERT WHEN CLICKED SIGN BUTTON
      const userid = window.sessionStorage.getItem('uid');
      const certFilter = "//S_CN=" + userid + "//";
      const signContent = "C|" + (getorderSource=='gtc_order'?delData['gtc_order_no']:delData['oid']) + "|" + delData['stock_id'] + "|" + delData['mapping_id'] + "|" + delData['direction'];

      if (window.inAppDetector.isInApp()) {
        //IF IT IS IN WEBVIEW, SIGN WITH CERT IN APP
        window.mtklib.sign({
          "idno": userid,
          "tosign": signContent,
          "method": "P1",
          "cb": (cbobj) => {
            if (cbobj.code == "0") {
              let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
              let obj=getorderSource=='gtc_order'?
                        {
                          country_code: delData['country'],
                          gtc_order_no: delData['gtc_order_no'],
                          mapping_id: delData['mapping_id'],
                          stock_id: delData['stock_id'],
                          buysell: delData['direction'],
                          token: window.sessionStorage.getItem("token"),
                          sign_type: "pkcs1",
                          signature: cbobj.signed,
                          src: signContent,
                          sn: cbobj.casno
                        }
                      :
                        {
                          country_code: delData['country'],
                          oid: delData['oid'],
                          stock_id: delData['stock_id'],
                          buysell: delData['direction'],
                          token: window.sessionStorage.getItem("token"),
                          sign_type: "pkcs1",
                          signature: cbobj.signed,
                          src: signContent,
                          sn: cbobj.casno
                        }
              
              fetch(common.getHttpZs + api, {
                method: "POST",
                headers: {
                  'Accept': 'application/json,text/plain,*/*',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
              })
                .then((res) => res.json())
                .then((result) => {
                  if (result['status'] == 0) {
                    clearInterval(timer)
                    clearInterval(timerOnce)
                    this.getTableData(country_code, stock_id, chgSort,isPC?page:1,[])
                    return
                  }
                  //获取失败
                  this.setState({
                    delData: [],
                    isLoading: false
                  })
                  let error = result['error'];
                  if (error) {
                    if (error['code']) {
                      error = error['code'] + " " + error['msg']
                    } else {
                      error = error['Status'] + " " + error['ErrMsg']
                    }
                  } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
                    error = result['data']['status'] + " " + result['data']['msg']
                  } else {
                    error = result['message'];
                  }
                  message.warning({
                    content: error
                  })
                });
            } else {
              //获取失败
              this.setState({
                delData: [],
                isLoading: false
              })
              message.warning({
                content: cbobj.code + " " + cbobj.desc
              })
            }
          }
        })
      } else {
        //執行PKCS7簽章
        window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
          if (code === 0 && data) {
            var certData = JSON.parse(data);
            let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
            let obj=getorderSource=='gtc_order'?
                      {
                        country_code: delData['country'],
                        gtc_order_no: delData['gtc_order_no'],
                        mapping_id: delData['mapping_id'],
                        stock_id: delData['stock_id'],
                        buysell: delData['direction'],
                        token: window.sessionStorage.getItem("token"),
                        signature: certData.signature,
                        data: signContent,
                        subject: certData.subdn
                      }
                    :
                      {
                        country_code: delData['country'],
                        oid: delData['oid'],
                        stock_id: delData['stock_id'],
                        buysell: delData['direction'],
                        token: window.sessionStorage.getItem("token"),
                        signature: certData.signature,
                        data: signContent,
                        subject: certData.subdn
                      }
            
            fetch(common.getHttpZs + api, {
              method: "POST",
              headers: {
                'Accept': 'application/json,text/plain,*/*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(obj)
            })
              .then((res) => res.json())
              .then((result) => {
                if (result['status'] == 0) {
                  clearInterval(timer)
                  clearInterval(timerOnce)
                  this.getTableData(country_code, stock_id, chgSort, isPC?page:1,[])
                  return
                }
                //获取失败
                this.setState({
                  delData: [],
                  isLoading: false
                })
                let error = result['error'];
                if (error) {
                  if (error['code']) {
                    error = error['code'] + " " + error['msg']
                  } else {
                    error = error['Status'] + " " + error['ErrMsg']
                  }
                } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
                  error = result['data']['status'] + " " + result['data']['msg']
                } else {
                  error = result['message'];
                }
                message.warning({
                  content: error
                })
              });
          } else if (code === 5067 || code === "5067") {
            //憑證挑選
            window.twcaLib.SelectSignerFubon(userid, certFilter, "", "", "", 0x04 | 0x08, 0, null, (code, msg, t, data) => {
              if (code === 0) {
                //執行PKCS7簽章
                window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, t, data) => {
                  if (code === 0 && data) {
                    var certData = JSON.parse(data);
                    let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
                    let obj=getorderSource=='gtc_order'?
                              {
                                country_code: delData['country'],
                                gtc_order_no: delData['gtc_order_no'],
                                mapping_id: delData['mapping_id'],
                                stock_id: delData['stock_id'],
                                buysell: delData['direction'],
                                token: window.sessionStorage.getItem("token"),
                                signature: certData.signature,
                                data: signContent,
                                subject: certData.subdn
                              }
                            :
                              {
                                country_code: delData['country'],
                                oid: delData['oid'],
                                stock_id: delData['stock_id'],
                                buysell: delData['direction'],
                                token: window.sessionStorage.getItem("token"),
                                signature: certData.signature,
                                data: signContent,
                                subject: certData.subdn
                              }
                    
                    fetch(common.getHttpZs + api, {
                      method: "POST",
                      headers: {
                        'Accept': 'application/json,text/plain,*/*',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(obj)
                    })
                      .then((res) => res.json())
                      .then((result) => {
                        if (result['status'] == 0) {
                          clearInterval(timer)
                          clearInterval(timerOnce)
                          this.getTableData(country_code, stock_id, chgSort, isPC?page:1,[])
                          return
                        }
                        //获取失败
                        this.setState({
                          delData: [],
                          isLoading: false
                        })
                        let error = result['error'];
                        if (error) {
                          if (error['code']) {
                            error = error['code'] + " " + error['msg']
                          } else {
                            error = error['Status'] + " " + error['ErrMsg']
                          }
                        } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
                          error = result['data']['status'] + " " + result['data']['msg']
                        } else {
                          error = result['message'];
                        }
                        message.warning({
                          content: error
                        })
                      });
                  } else {
                    // this.setState({
                    //   isLoading: false
                    // })
                    message.warning({
                      content: code + " " + msg
                    })
                  }
                });
              } else {
                // this.setState({
                //   isLoading: false
                // })
                message.warning({
                  content: code + " " + msg
                })
              }
            });
          } else {
            // this.setState({
            //   isLoading: false
            // })
            message.warning({
              content: code + " " + msg
            })
          }
        });
      }
    } else {
      let api=getorderSource=='gtc_order'?'removeGtcOrder':'removeOrder'
      let obj=getorderSource=='gtc_order'?
                {
                  country_code: delData['country'],
                  gtc_order_no: delData['gtc_order_no'],
                  mapping_id: delData['mapping_id'],
                  stock_id: delData['stock_id'],
                  buysell: delData['direction'],
                  token: window.sessionStorage.getItem("token"),
                  signature: "dev",
                  data: "dev",
                  subject: "dev"
                }
              :
                {
                  country_code: delData['country'],
                  oid: delData['oid'],
                  stock_id: delData['stock_id'],
                  buysell: delData['direction'],
                  token: window.sessionStorage.getItem("token"),
                  signature: "dev",
                  data: "dev",
                  subject: "dev"
                }
      
      fetch(common.getHttpZs + api, {
        method: "POST",
        headers: {
          'Accept': 'application/json,text/plain,*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      })
      .then((res) => res.json())
      .then((result) => {
        if(result['status']==0){
          clearInterval(timer)
          clearInterval(timerOnce)
          this.getTableData(country_code,stock_id,chgSort,isPC?page:1,[])
          return
        }
        //获取失败
        this.setState({
          delData: [],
          isLoading:false
        })
        let error = result['error'];
        if (error) {
          if (error['code']) {
            error = error['code'] + " " + error['msg']
          } else {
            error = error['Status'] + " " + error['ErrMsg']
          }
        } else if (result['data'] && result['data']['status'] && result['data']['msg']) {
          error = result['data']['status'] + " " + result['data']['msg']
        } else {
          error = result['message'];
        }
        message.warning({
          content: error
        })
      });
    }
  }
  //删除
  removeOrder() {
    //WebCA
    if (common.enableWebca && !window.inAppDetector.isInApp()) {
      const memberid = window.sessionStorage.getItem('uid');
      const filter = "//S_CN=" + memberid + "//"; //憑證挑選條件
      window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
        if (code === 0 && data) {
          let d = JSON.parse(data);
          let rafunc = d.suggestAction;

          if (rafunc !== "None") { // Need to apply/renew cert
            const webcaInfoRequestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ token: window.sessionStorage.getItem('token'), rafunc: rafunc })
            };

            //Get parameters for calling WebCAInvoke
            fetch(common.getHttp + 'getwebcainfo', webcaInfoRequestOptions)
              .then(response => response.json())
              .then(data => {
                if (data.status === 0) {
                  data = data.data;
                  // window.setWebCAinWebview(true);
                  window.setWebCAinWebview(window.inAppDetector.isInApp()); //確認執行環境是否在WebView
                  //呼叫WebCA執行對應raFunc的憑證作業
                  window.WebCAInvoke(data.BusinessNo, data.APIVersion, data.HashKeyNo, data.VerifyNo, data.ReturnParams, memberid, rafunc, data.IdentifyNo, process.env.PUBLIC_URL + "/WebCA/webcaFrm.html", common.getHttpWebca, (code, msg, token, data) => {
                    if (code===0 || code==="0000" || code===7000) {
                      this.executeRemoveOrder();
                    } else {
                      // this.setState({
                      //   isLoading: false
                      // })
                      message.warning({
                        content: code + " " + msg
                      })
                    }
                    // window.WebCAQueryCertState(memberid, filter, (code, msg, t, data) => {
                    //   d = JSON.parse(data);
                    //   rafunc = d.suggestAction;
                    //   if (rafunc === "None") {
                    //     this.executeRemoveOrder();
                    //   } else {
                    //     // this.setState({
                    //     //   isLoading: false
                    //     // })
                    //     message.warning({
                    //       content: "WebCA failed"
                    //     })
                    //   }
                    // });
                  });
                } else {
                  // this.setState({
                  //   isLoading: false
                  // })
                  message.warning({
                    content: data.message
                  })
                }
              })
          } else {
            this.executeRemoveOrder();
          }
        } else {
          // this.setState({
          //   isLoading: false
          // })
          message.warning({
            content: code + " " + msg
          })
        }
      });
    } else {
      this.executeRemoveOrder();
    }
  }
  //获取数据API
  getTableData(country_code,stock_id,sort,page,listsData) {
    const {isonLoadShow,recperpage}=this.state
    page=Number(page)
    fetch(common.getHttpZs+'getTodayOrder', {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_code: country_code,
        stock_id: stock_id,
        recperpage: recperpage,
        page: page,
        sort:sort,
        token: sessionStorage.getItem("token")
      })
    })
    .then((res) => res.json())
    .then((result) => {
      this.setState({
        isDelData: 'N'
      })
      if(result['status']==0){
        if(JSON.stringify(result['data']) !== '{}'){
          let size_total=result['data']['size_total']
          let datalist=result['data']['datalist']
          let getRicData=[]
          // 初始化执行
          if(isonLoadShow){
            this.setState({
              isonLoadShow: false
            })
            this.getTabNumData(size_total)
          }
          if(datalist.length){
            for(let i in datalist){
              getRicData.push(datalist[i]['stock_id'])
            }
            if(getRicData.length==datalist.length){
              getRicData= [...new Set(getRicData)]//去重
              this.getsimplequoteFun(getRicData,datalist,listsData,page,size_total)
            }
          }else{
            if(listsData.length){
              this.setState({
                isLoading:false,
                loadMore:false,
                noMore:true
              })
            }else{
              this.noDataFun()
            }
          }
        }else{
          this.noDataFun()
        }
      }else{
        this.noDataFun()
      }
      if (isEndLoad) {
        clearInterval(timerOnce)
        timer=setInterval(() => {
          this.intervalData()
        }, 10000)
      }else{
        clearInterval(timer)
      }
      if(isEndLoadOnce){
        isEndLoad=true
        isEndLoadOnce=false
        timerOnce=setInterval(() => {
          this.intervalData()
        }, 10000)
      }
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(ric,datalist,listsData,page,size_total){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      //获取数据成功
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        if(JSON.stringify(resData)!=='{}'){
          for(let i in datalist){
            for(let j in ric){
              if(datalist[i]['stock_id']==ric[j]){
                let data=resData[ric[j]]
                datalist[i]['stock_code']=data['symbol']
                datalist[i]['stock_name']=data['name']
                datalist[i]['country']=data['exchange_country_code']
              }
            }
          }
          this.commonDataFun(datalist,listsData,page,size_total)
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err)
      this.commonDataFun(datalist,listsData,page,size_total)
    })
  }
  commonDataFun(datalist,listsData,page,size_total){
    const {ChgLangData,isTablet}=this.props
    const {statusData,lastStatus22,errMsgData}=this.state
    let pn1=datalist.length?datalist.length>=10?false:true:false
    let pnother=listsData.length?datalist.length?false:true:true
    let noMoreData=page==1?pn1:pnother
    //更改status为对应的文字
    if(datalist.length){
      for(let i in datalist){
        datalist[i]['closeImg']=closeImg
        let error_msg=datalist[i]['error_msg']
        if(error_msg){
          let get_error_msg=error_msg.split(' ')
          let msgTag=get_error_msg[1].slice(4,6)

          if(errMsgData.includes(msgTag)){
            let datamsg1=error_msg.slice(17)
            let datamsg2=datamsg1.slice(0,-1)
            datalist[i]['new_error_msg']=datamsg2
          }else{
            let datamsg=error_msg.split(' ')[2]
            let datamsg1=datamsg.slice(9)
            let datamsg2=datamsg1.slice(0,-1)
            let datamsg3=datamsg.slice(4,8)
            datalist[i]['new_error_msg']=datamsg3+': '+datamsg2
          }
        }
        for(let j in statusData){
          if(Number(datalist[i]['status'])==statusData[j]['status']){

            datalist[i]['status_content']=Number(datalist[i]['status'])==30&&Number(datalist[i]['last_status'])==22?lastStatus22:statusData[j]['content']
            datalist[i]['status_content']=Number(datalist[i]['status'])==70?ChgLangData=='zh-CN'?'Cancelling order in progress1':statusData[j]['content']:statusData[j]['content']
          }
          //顯示刪單按鈕
          if(datalist[i]['can_cancel']=='Y'){
            this.setState({
              isdelStatusShow:true
            })
          }
        }
        let qty=datalist[i]['qty']
        let qty_filled=datalist[i]['qty_filled']
        if (qty&& qty!== '-') {
          datalist[i]['qty_len']=Number(String(qty).split('.')[0].length+2)
        }
        if (qty_filled&&qty_filled !== '-') {
          datalist[i]['qty_filled_len']=Number(String(qty_filled).split('.')[0].length+2)
        }
      }
    }
    this.setState({
      history: isTablet?listsData.concat(datalist):datalist,
      sizeTotal:size_total,
      isLoading:false,
      loadMore:false,
      noMore:noMoreData
    })
  }
  // 获取失败
  noDataFun(){
    this.setState({
      history: [],
      sizeTotal:0,
      isLoading:false,
      loadMore:false,
      noMore:false
    })
  }
  //获取数据API--显示在tab栏的数据
  getTabNumData(size_total) {
    fetch(common.getHttpZs+"getNavTotal", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.sessionStorage.getItem("token"),
        tab:2
      })
    })
    .then((res) => res.json())
    .then((result) => {
      if(result['status']==0){
        let portfolio=result['data']['total_count']['portfolio']
        let today_deal=result['data']['total_count']['today_deal']
        let data=[portfolio,size_total,today_deal]
        this.props.callbackTabNumData(data)
      }
    })
  }
  //回传数据--input
  callbackInputData(data){
    const {chgSort}=this.state
    this.setState({
      isLoading:true,
      stock_id:data[0],
      country_code:data[1],
      page:1
    })
    chgPage=1
    clearInterval(timer)
    clearInterval(timerOnce)
    this.getTableData(data[1],data[0],chgSort,1,[])
  }
  //回传数据--分页
  callbackPagekData(page){
    const {country_code,stock_id,chgSort}=this.state
    this.setState({
      isLoading:true,
      page:page
    })
    clearInterval(timer)
    clearInterval(timerOnce)
    this.getTableData(country_code,stock_id,chgSort,page,[])
  }
  //重新整理
  refreshClick(){
    const {ChgThemeData}=this.props
    const {isLoading}=this.state
    if(isLoading) return
    this.setState({
      isRefresh:true,
      chgRefreshImg:ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg
    })
    setTimeout(() => {
      this.setState({
        isRefresh:false,
        chgRefreshImg:refreshImg
      })
    }, 1000)
    this.setState({
      isLoading:true,
      country_code:'US',
      chgSort:'time_desc',
      stock_id:null,
      page:1
    })
    chgPage=1
    if(isEndLoad){
      isEndLoad=false
      isEndLoadOnce=true
      clearInterval(timerOnce)
    }
    clearInterval(timer)
    this.getTableData('US',null,'time_desc',1,[])
    this.props.cbChgSortDataFun([])
  }
  // auto--每隔10秒执行 api
  autoClick(){
    const {ChgThemeData}=this.props
    const {isAutoShow}=this.state
    this.setState({
      isAutoShow:!isAutoShow,
      isRefreshImg:!isAutoShow
    })
    if(isAutoShow){
      this.clearIntervalFun()
      this.setState({
        chgAutoImg:ChgThemeData=='Light'?autoLightImg:autoImg
      })
    }else{
      isEndLoad=true
      this.resetData()
      this.setState({
        chgAutoImg:autoLightImg
      })
    }
  }
  intervalData(){
    setTimeout(() => {
      this.setState({
        isRefresh:false,
        chgRefreshImg:refreshImg
      })
    }, 1000)
    clearInterval(timer)
    clearInterval(timerOnce)
    this.resetData()
  }
  resetData(){
    const {isRefreshImg}=this.state
    this.resetAutoImg()
    this.setState({
      isLoading:true,
      isRefreshImg:!isRefreshImg,
      country_code:'US',
      chgSort:'time_desc',
      stock_id:null,
      page:1
    })
    chgPage=1
    this.getTableData('US',null,'time_desc',1,[])
  }
  // 恢复为默认的，auto才会每隔10秒有动画效果
  resetAutoImg(){
    setTimeout(() => {
      this.setState({
        isRefreshImg:false
      })
    }, 1000)
  }
  //更改下拉框
  changeDropDownData(){
    const {isPC}=this.props
    // 分页
    let contentId=document.getElementById('contentId').clientWidth
    let pageW=document.getElementById('pageId').clientWidth
    leftConW=contentId-pageW>878?878:'auto'

    this.setState({
      dropDownT: 38,
      dropDownL: isPC?8:0,
      dropDownW: isPC?'98%':'100%'
    })
  }
  //滚动加载更多--isTablet/mobile
  cbChgDataFun(){
    const {loadMore,noMore,isLoading,country_code,stock_id,history,chgSort}=this.state
    if(loadMore||noMore||isLoading) return
    chgPage++
    this.setState({
      page: chgPage,
      loadMore:true,
      noMore:false
    })
    clearInterval(timer)
    clearInterval(timerOnce)
    this.getTableData(country_code,stock_id,chgSort,chgPage,history)
  }
  clearIntervalFun(){
    isEndLoad=false
    isEndLoadOnce=false
    clearInterval(timer)
    clearInterval(timerOnce)
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {bodyW,ChgLangData,isSearchPageShow,ChgThemeData}=this.props
    const {history,statusData,isAutoShow}=this.state
    //更改语言
    if(ChgLangData!==newProps['ChgLangData']){
      //更改status为对应的文字
      if(history.length){
        for(let i in history){
          for(let j in statusData){
            if(Number(history[i]['status'])==statusData[j]['status']){
              history[i]['status_content']=Number(history[i]['status'])==70?ChgLangData=='zh-CN'?'Cancelling order in progress1':statusData[j]['content']:statusData[j]['content']
            }
          }
        }
        this.setState({
          history: history
        })
      }
    }
    if(bodyW!==newProps['bodyW']||isSearchPageShow!==newProps['isSearchPageShow']){
      this.changeDropDownData()
    }
    if(ChgThemeData!==newProps['ChgThemeData']){
      this.setState({
        chgAutoImg:ChgThemeData=='Light'?autoLightImg:isAutoShow?autoLightImg:autoImg
      })
    }
  }
  //初始化
  componentDidMount() {
    const {ChgThemeData}=this.props
    const {country_code,stock_id,page,chgSort}=this.state
    chgPage=1
    this.setState({
      page: 1,
      loadMore:false,
      noMore:false,
      chgAutoImg:ChgThemeData=='Light'?autoLightImg:autoImg,
    })
    this.changeDropDownData()
    this.clearIntervalFun()
    this.getTableData(country_code,stock_id,chgSort,page,[])
  }
  //卸载
  componentWillUnmount() {
    this.clearIntervalFun()
  }
  render() {
    const {t,ChgThemeData,ChgLangData,isPC,isTablet,sortData,bodyW,isSearchPageShow} =this.props
    const {history,isLoading,sizeTotal,isdelStatusShow,page,recperpage,isRefresh,delData,isDelShow,dropDownL,dropDownR,dropDownT,dropDownW,chgAutoImg,chgRefreshImg,loadMore,noMore,minW1600,isSortShow,isDelData,isAutoShow,isRefreshImg} = this.state
    return (
      <div
        className={[
          styles.account_layout,
          isPC&&isSearchPageShow?'':APPstyles.flex1_auto,
          isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
          isPC&&isSearchPageShow||isTablet?APPstyles.hPer_overflowH:'',
          isPC&&isSearchPageShow?'':isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':''
        ].join(' ')}
        style={{
          position:'relative'
        }}
      >
        {/* Header */}
        <div 
          id='contentId'
          className={[
            myStyles.header_con,
            APPstyles.flex_row,APPstyles.flex_between,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')} 
          style={{
            margin:isPC?'0 16px':0,
            padding:isPC?0:'0 16px'
          }}
        >
          <div 
            className={[APPstyles.flex_1,APPstyles.flex_row].join(' ')} 
            style={{
              alignItems:'center',
              width:isPC?leftConW:'auto',
              maxWidth:isPC?leftConW:'unset',
              position:'relative'
            }}
          >
            <div 
              style={{
                padding:isPC?'0 8px':0,
                width:'100%'
              }}
            >
              <InputCon
                {...this.props}
                dropDownL={dropDownL}
                dropDownR={dropDownR}
                dropDownT={dropDownT}
                dropDownW={dropDownW}
                callbackInputData={this.callbackInputData.bind(this)}
              />
            </div>
            <img
              src={chgRefreshImg}
              className={[
                APPstyles.img24,
                isRefresh?APPstyles.refresh_img:''
              ].join(' ')} 
              style={{marginLeft:isPC?0:8,marginRight:8}}
              onClick={this.refreshClick.bind(this)} 
              alt='refresh'
              onMouseOver={()=>{
                if(!isPC) return
                this.setState({
                  chgRefreshImg:ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                let imgUrl=isRefresh?ChgThemeData=='Light'?refreshOnLightImg:refreshOnImg:refreshImg
                this.setState({
                  chgRefreshImg:imgUrl
                })
              }}
            />
            <div 
              className={[
                APPstyles.flex_center,
                myStyles.header_btn_auto,
                ChgThemeData=='Light'?myStyles.header_btn_auto_light:'',
                isAutoShow?'':ChgThemeData=='Light'?myStyles.header_auto_hover_light:myStyles.header_btn_auto_hover,
                isAutoShow?ChgThemeData=='Light'?myStyles.header_auto_active_light:myStyles.header_auto_active:'',
              ].join(' ')}
              onClick={this.autoClick.bind(this)} 
            >
              <img
                src={chgAutoImg}
                className={[APPstyles.img24,isRefreshImg?APPstyles.refresh_img:''].join(' ')} 
                alt='auto'
              />
              {t('Auto')}
            </div>
          </div>
          {/* 分页 */}
          <div id='pageId' style={{display:isPC?'':'none'}}>
            <PaginationCon
              {...this.props}
              sizeTotal={sizeTotal}
              recperpage={recperpage}
              isRefresh={isRefresh}
              page={page}
              isShow={false}
              history={history}
              callbackPagekData={this.callbackPagekData.bind(this)}
            />
          </div>
        </div>
        {
          isPC?
          <div 
            className={[
              isPC&&isSearchPageShow?APPstyles.flex1_auto:'',
              isPC&&isSearchPageShow?isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'':''
            ].join(' ')}
          >
            {/* table--header */}
            <div
              className={[
                APPstyles.flex_row,
                styles.account_header,
                styles.header_pc,
                styles.account_common,
                styles.account_common_todayOrder,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                ChgLangData=='zh-CN'?styles.account_common_zhcn:'',
                ChgLangData=='zh-CN'?APPstyles.fz12_zhcn:''
              ].join(" ")}
            >
              <div>
                {t("Time")}
                <div 
                  className={[
                    APPstyles.upDown_con,
                    APPstyles.flex_column
                  ].join(' ')}
                >
                  <div 
                    className={APPstyles.img_dropdown_up} 
                    onClick={()=>{this.sortClick('time','time_asc','asc')}}
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                      sortData[0]['upImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      let imgurl=sortData[0]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                      sortData[0]['upImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }}
                  >
                    <img src={sortData[0]['upImg']} />
                  </div>
                  <div 
                      className={APPstyles.img_dropdown_down} 
                    onClick={()=>{this.sortClick('time','time_desc','desc')}}
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                      sortData[0]['downImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      let imgurl=sortData[0]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                      sortData[0]['downImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }}
                  >
                    <img src={sortData[0]['downImg']} />
                  </div>
                </div>
              </div>
              <div style={{width:'6%'}}>
                {t("Direction")}
                <div 
                  className={[
                    APPstyles.upDown_con,
                    APPstyles.flex_column
                  ].join(' ')}
                >
                  <div 
                    className={APPstyles.img_dropdown_up} 
                    onClick={()=>{this.sortClick('direction','direction_asc','asc')}}
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                      sortData[1]['upImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      let imgurl=sortData[1]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                      sortData[1]['upImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }}
                  >
                    <img src={sortData[1]['upImg']} />
                  </div>
                  <div 
                    className={APPstyles.img_dropdown_down} 
                    onClick={()=>{this.sortClick('direction','direction_desc','desc')}}
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                      sortData[1]['downImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      let imgurl=sortData[1]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                      sortData[1]['downImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }}
                  >
                    <img src={sortData[1]['downImg']} />
                  </div>
                </div>
              </div>
              <div style={{width:'12%'}}>
                {t("Status")}
                <div 
                  className={[
                    APPstyles.upDown_con,
                    APPstyles.flex_column
                  ].join(' ')}
                >
                  <div 
                    className={APPstyles.img_dropdown_up} 
                    onClick={()=>{this.sortClick('status','status_asc','asc')}}
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                      sortData[2]['upImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      let imgurl=sortData[2]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                      sortData[2]['upImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }}
                  >
                    <img src={sortData[2]['upImg']} />
                  </div>
                  <div 
                      className={APPstyles.img_dropdown_down} 
                    onClick={()=>{this.sortClick('status','status_desc','desc')}}
                    onMouseOver={()=>{
                      if(!isPC) return
                      let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                      sortData[2]['downImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }} 
                    onMouseOut={()=>{
                      if(!isPC) return
                      let imgurl=sortData[2]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                      sortData[2]['downImg']=imgurl
                      this.props.cgChgSortDataFun(sortData)
                    }}
                  >
                    <img src={sortData[2]['downImg']} />
                  </div>
                </div>
              </div>
              <div style={{ width:'5%'}}>{t("Country")}</div>
              <div>{t("Code")}</div>
              <div
                className={styles.account_name}
                style={{
                  width:isdelStatusShow?
                    ChgLangData=='zh-CN'?isSearchPageShow?'23%':'26%':bodyW<=minW1600||isSearchPageShow?'21%':'23%'
                  :
                    ChgLangData=='zh-CN'?isSearchPageShow?'26%':'29%':bodyW<=minW1600||isSearchPageShow?'24%':'26%',
                  justifyContent: "flex-start"
                }}
              >
                {t(ChgLangData == "zh-CN" ? "name" : "Name")}
              </div>
              <div style={{width:'5%'}}>{t("Stop Price")} </div>
              <div>{t("Order Price")} </div>
              <div style={{width:'5%'}}>{t("QTY")}</div>
              <div style={{width:'5%'}}>{t("QTY Filled1")}</div>
              <div>
                <div className={styles.account_header_totalcost}>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Filled")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Avg Price")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Filled Avg Price")}
                  </span>
                </div> 
              </div>
              <div 
                style={{ 
                  paddingRight: 0,
                  width:ChgLangData=='zh-CN'?isSearchPageShow?'8%':'5%':bodyW<=minW1600||isSearchPageShow?'10%':'8%',
                  whiteSpace:'nowrap',
                  textAlign:'right'
                }}
              >
                {t("Order Type")}
              </div>
              <div
                style={{ 
                  display:isdelStatusShow?'':'none',
                  width: "3%" 
                }}
              >
                &nbsp;
              </div>
            </div>
            {/* table--body */}
            <div 
              className={history.length?'':APPstyles.minH300}
              style={{
                position:!history.length&&!isLoading?'relative':'unset'
              }}
            >
              {/* 加载中 */}
              <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
              {
                history.length?

                history.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={[
                          APPstyles.flex_row,
                          styles.account_body,
                          styles.account_common,
                          styles.account_common_todayOrder,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                          ChgThemeData=='Light'?styles.account_body_light:'',
                          ChgLangData=='zh-CN'?styles.account_common_zhcn:''
                        ].join(" ")}
                        style={{cursor:"pointer"}}
                        onClick={this.detailClick.bind(this,item)}
                        >
                        <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{flexWrap:'nowrap'}}>{common.changeOrderTime(item['time'])}</div>
                        <div 
                          style={{ width:"6%"}} 
                          className={item['direction']=='B'?APPstyles.color_red:APPstyles.color_green}
                        >
                          {t(common.charAtToUpperCase(item['direction']=='B'?'Buy':'Sell'))}
                        </div>
                        <div
                          className={APPstyles.flex_column}
                          style={{width:'12%'}}
                        >
                          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{display:'block',width:'100%'}}>{t(item['status_content'])}</span>
                        </div>
                        <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:'5%'}}>{t(common.valToUpperCase(item['country']))}</div>
                        <div className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,APPstyles.fw700].join(' ')}>
                          {common.valToUpperCase(item['stock_code'])}
                        </div>
                        <div
                          className={[styles.account_name,ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur].join(' ')}
                          style={{
                            width:isdelStatusShow?
                              ChgLangData=='zh-CN'?isSearchPageShow?'23%':'26%':bodyW<=minW1600||isSearchPageShow?'21%':'23%'
                            :
                              ChgLangData=='zh-CN'?isSearchPageShow?'26%':'29%':bodyW<=minW1600||isSearchPageShow?'24%':'26%',
                            justifyContent: "flex-start",
                          }}
                        >
                          {common.valToUpperCase(item['stock_name'])}
                        </div>
                        <div style={{width:'5%'}}>{Number(item['stop_loss_price'])==0?'-':common.transThousands(common.filterErro(item['stop_loss_price']))}</div>
                        <div>{common.transThousands(common.filterErro(item['order_price']))}</div>
                        <div style={{width:'5%'}}>
                          {common.transThousands(common.keepNum(item['qty'],0))}
                        </div>
                        <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:'5%'}}>
                          {common.transThousands(common.keepNum(item['qty_filled'],0))}
                        </div>
                        <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                          {common.transThousands(common.keepNum(item['filled_avg_price'],2))}
                        </div>
                        <div 
                          className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                          style={{
                            paddingRight: 0, 
                            width:ChgLangData=='zh-CN'?isSearchPageShow?'8%':'5%':bodyW<=minW1600||isSearchPageShow?'10%':'8%',
                            whiteSpace:'nowrap',
                            textAlign:'right'
                          }}
                        >
                          {t(
                              item['order_type']==0&&(item['gtc_date']=='-'||item['gtc_date']==''||!item['gtc_date'])?
                                'Limit Order'
                              :item['order_type']==50&&(item['gtc_date']=='-'||item['gtc_date']==''||!item['gtc_date'])?
                                'Stop Limit'
                              :item['order_type']==0&&(item['gtc_date']!=='-'||item['gtc_date']!==''||item['gtc_date'])?
                                'GTC Limit'
                              :item['order_type']==50&&(item['gtc_date']!=='-'||item['gtc_date']!==''||item['gtc_date'])?
                                'GTC Stop Limit'
                              :
                                ''
                            )}
                        </div>
                        <div
                          style={{
                            display:isdelStatusShow?'':'none',
                            width: "3%",
                            justifyContent: "flex-end"
                          }}
                        >
                          <img
                            src={item['closeImg']}
                            alt="del"  
                            className={APPstyles.img24}
                            style={{
                              display:item['can_cancel']=='Y'?'':'none'
                            }}
                            onClick={this.openModalClick.bind(this, item)} 
                            onMouseOver={()=>{
                              if(!isPC) return
                              let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                              history[index]['closeImg']=imgurl
                              this.setState({
                                history:history
                              })
                            }} 
                            onMouseOut={()=>{
                              if(!isPC) return
                              history[index]['closeImg']=closeImg
                              this.setState({
                                history:history
                              })
                            }}
                          />
                        </div>
                      </div>
                    );
                  })
                :
                <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
              }
            </div>
            {/* 分页--tips */}
            <div style={{display:isPC?isSearchPageShow?'':'none':'none'}}>
            <PaginationCon
              {...this.props}
              sizeTotal={sizeTotal}
              recperpage={recperpage}
              isRefresh={isRefresh}
              page={page}
              isShow={true}
              history={history}
              callbackPagekData={this.callbackPagekData.bind(this)}
            />
            </div>
          </div>
          :
          // 移动端
          <Mobile 
            {...this.props}
            history={history}
            changeName={ChgLangData == "zh-CN" ? "name" : "Name"}
            isLoading={isLoading}
            isdelStatusShow={isdelStatusShow}
            loadMore={loadMore}
            noMore={noMore}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            isRefresh={isRefresh}
            page={page}
            isShow={true}
            isSortShow={isSortShow}
            isDelData={isDelData}
            sortClick={this.sortClick.bind(this)}
            detailClick={this.detailClick.bind(this)}
            openModalClick={this.openModalClick.bind(this)}
            cbChgDataFun={this.cbChgDataFun.bind(this)}
          />
        }
        {/* 分页--tips */}
        <div style={{display:isPC&&!isSearchPageShow?'':'none'}}>
        <PaginationCon
          {...this.props}
          sizeTotal={sizeTotal}
          recperpage={recperpage}
          isRefresh={isRefresh}
          page={page}
          isShow={true}
          history={history}
          callbackPagekData={this.callbackPagekData.bind(this)}
        />
        </div>
        {
          isDelShow?
            <TipsModal
              {...this.props}
              delData={delData}
              tag={'todayorder'}
              cancelText={'Cancel'}
              sureText={'Confirm'} 
              cbCloseBtnFun={(data)=>{
                this.setState({
                  isDelShow:false,
                  isDelData:data
                })
                //删除某条数据
                if(data=='Y'){
                  this.setState({
                    isLoading: true
                  })
                  this.removeOrder()
                }
              }}
            />
          :
            ''
        }
      </div>
    );
  }
}
export default withTranslation()(TodayOrder)

import React from 'react';
import { withTranslation } from 'react-i18next';
import APPstyles from '@/App.less';
import styles from './index.less';

class DisclaimerIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    const {isPC}=this.props;
    return (
      <div className={[APPstyles.flex_column,styles.disclaimerCon,!isPC&&styles.disclaimerConMobile].join(' ')}>
        <span>活動注意事項</span>
        <span>本活動僅限自然人（個人戶）參加，非自然人（法人戶、非法人團體戶）不適用。</span>
        <span>抽獎機會限以每身份證字號(ID)的單一證券帳戶計算，同一人擁有之不同證券帳戶不可累加。（例如：一人擁有兩個證券帳戶，該兩帳戶的交易額不得加總；但若是兩個帳戶分別皆有達標，則可獲得２次抽獎機會。）</span>
        <span>【活動一　週週抽10,000元mo幣】以每身份證字號(ID)的單一證券帳戶計算，凡當週交易量美股達3萬美元(含)以上或日股達500萬日圓(含)以上，即可抽獎１次。以每身份證字號(ID)的單一證券帳戶計算，當週交易量可累加。（例如美股達6萬美元(含)以上或日股達1,000萬日圓(含)以上，則可獲抽獎機會２次，依此類推，每週上限10次。交易量越多，抽獎機會越多。）每週交易量重新計算，若每週達標，則可每週抽獎。</span>
        <span>【活動二　季末抽20萬元mo幣】以每身份證字號(ID)的單一證券帳戶計算7、8、9月單月交易量達標（10萬美元(含)以上或日股達1,200萬日圓(含)以上），方能於季末獲得1次抽獎機會，同一人擁有之不同證券帳戶不可累加。若三個月皆達標，則最多獲得3次抽獎機會。</span>
        <span>本頁面計算活動抽獎次數僅供參考，中獎名單依富邦證券所公告之名單為準。</span>
      </div> 
    )
  }
}
export default withTranslation()(DisclaimerIndex);
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const Vector = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [p1, setP1] = useState(14);
  const [p1Enable, setP1Enable] = useState(true);
  const [p1Color, setP1Color] = useState("AA2211");
  const [p1Thickness, setP1Thickness] = useState(2);
  const [p1Style, setP1Style] = useState("s");
  const [p1ShowTracker, setP1ShowTracker] = useState(true);

  const [p2, setP2] = useState(9);
  const [p2Enable, setP2Enable] = useState(true);
  const [p2Color, setP2Color] = useState("0080FF");
  const [p2Thickness, setP2Thickness] = useState(2);
  const [p2Style, setP2Style] = useState("s");
  const [p2ShowTracker, setP2ShowTracker] = useState(true);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(p1 + "," + p2);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.Vector_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const Vector = localChartSetting.option;

        setValue(Vector.p1 + "," + Vector.p2);
        props.onTAValueChange({
          ta: "Vector",
          name: `${t("chart5.TA.Vector")} (Vector)`,
          option: {
            p1: Vector.p1,
            p1Enable: Vector.p1Enable,
            p1Color: Vector.p1Color,
            p1Thickness: Vector.p1Thickness,
            p1Style: Vector.p1Style,
            p1ShowTracker: Vector.p1ShowTracker,
            p2: Vector.p2,
            p2Enable: Vector.p2Enable,
            p2Color: Vector.p2Color,
            p2Thickness: Vector.p2Thickness,
            p2Style: Vector.p2Style,
            p2ShowTracker: Vector.p2ShowTracker,
            height: Vector.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const Vector = props.localChartSetting.Vector_Option.option;

    setP1(parseInt(Vector.p1) || p1);
    setP1Enable(Vector.p1Enable);
    setP1Color(Vector.p1Color);
    setP1Thickness(parseInt(Vector.p1Thickness) || p1Thickness);
    setP1Style(Vector.p1Style);
    setP1ShowTracker(Vector.p1ShowTracker);

    setP2(parseInt(Vector.p2) || p2);
    setP2Enable(Vector.p2Enable);
    setP2Color(Vector.p2Color);
    setP2Thickness(parseInt(Vector.p2Thickness) || p2Thickness);
    setP2Style(Vector.p2Style);
    setP2ShowTracker(Vector.p2ShowTracker);
    setHeight(Vector.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(p1 + "," + p2);
    props.onTAValueChange({
      ta: "Vector",
      name: `${t("chart5.TA.Vector")} (Vector)`,
      option: {
        p1: p1,
        p1Enable: p1Enable,
        p1Color: p1Color,
        p1Thickness: p1Thickness,
        p1Style: p1Style,
        p1ShowTracker: p1ShowTracker,
        p2: p2,
        p2Enable: p2Enable,
        p2Color: p2Color,
        p2Thickness: p2Thickness,
        p2Style: p2Style,
        p2ShowTracker: p2ShowTracker,
        height: height,
      },
      value: value,
    });
  }, [
    p1,
    p1Enable,
    p1Color,
    p1Thickness,
    p1Style,
    p1ShowTracker,
    p2,
    p2Enable,
    p2Color,
    p2Thickness,
    p2Style,
    p2ShowTracker,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const Vector = props.localChartSetting.Vector_Option.option;

    setP1(parseInt(Vector.p1) || p1);
    setP1Enable(Vector.p1Enable);
    setP1Color(Vector.p1Color);
    setP1Thickness(parseInt(Vector.p1Thickness) || p1Thickness);
    setP1Style(Vector.p1Style);
    setP1ShowTracker(Vector.p1ShowTracker);

    setP2(parseInt(Vector.p2) || p2);
    setP2Enable(Vector.p2Enable);
    setP2Color(Vector.p2Color);
    setP2Thickness(parseInt(Vector.p2Thickness) || p2Thickness);
    setP2Style(Vector.p2Style);
    setP2ShowTracker(Vector.p2ShowTracker);
    setHeight(Vector.height);
  }, [props.isReset]);

  const resetALL = () => {
    setP1(14);
    setP1Enable(true);
    setP1Color("AA2211");
    setP1Thickness(2);
    setP1Style("s");
    setP1ShowTracker(true);

    setP2(9);
    setP2Enable(true);
    setP2Color("0080FF");
    setP2Thickness(2);
    setP2Style("s");
    setP2ShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // type: string
  const onEnableChange = (type) => {
    if (type === "p1")
      if (p1Enable) setP1Enable(false);
      else setP1Enable(true);
    else if (p2Enable) setP2Enable(false);
    else setP2Enable(true);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "p1") setP1(e.target.value);
    else setP2(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "p1") setP1Thickness(e.target.value);
    else setP2Thickness(e.target.value);
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "p1") setP1Style(e.target.value);
    else setP2Style(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // type: string
  const onShowTracker = (type) => {
    if (type === "p1")
      if (p1ShowTracker) setP1ShowTracker(false);
      else setP1ShowTracker(true);
    else if (p2ShowTracker) setP2ShowTracker(false);
    else setP2ShowTracker(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "Vector-C1":
        setP1Color(props.selectedColor);
        break;
      case "Vector-C2":
        setP2Color(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("Vector")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.Vector")} (Vector) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("Vector")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={p1Enable ? {} : { opacity: "0.5" }}
          id="msi-ta-Vector"
        >
          <div className="flexbox">
            <div
              id="msi-ta-Vector-p0-enabled"
              className={
                p1Enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("p1")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-Trix-ma-period0"
                type="number"
                step="1"
                min="1"
                value={p1}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={p1Color}
              data-colorpickerdialogtitle="表示設定"
              style={{ backgroundColor: "#" + p1Color }}
              onClick={() =>
                onColorClick(p1Color, "Vector-C1", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={p1Thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-UI-p0-style" value={p1Style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  p1ShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={p2Enable ? {} : { opacity: "0.5" }}
          id="msi-ta-Vector"
        >
          <div className="flexbox">
            <div
              id="msi-ta-Vector-ma-enabled"
              className={
                p2Enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("p2")}
            ></div>
            <div className="taparamlbl">{t("chart5.averagePeriod")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-Trix-s-period0"
                type="number"
                step="1"
                min="1"
                value={p2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={p2Color}
              data-colorpickerdialogtitle="表示設定"
              style={{ backgroundColor: "#" + p2Color }}
              onClick={() =>
                onColorClick(p2Color, "Vector-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={p2Thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-UI-p0-style" value={p2Style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  p2ShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                defaultValue="1"
                onChange={(e) => onHeightChange(e)}
                value={height}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

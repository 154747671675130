import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  minusImg,
  plusImg,
  minusOnLightImg,
  plusOnLightImg
} from '@/asserts/imgs/trade/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class NumberCon extends React.Component{
  //input值的变化
  changeNumClick(e){
    const {tag,cbInputValue} = this.props;
    let value = e.target.value;
    if(tag=='quantity'){
      // 仅允许正整数
      if (!/^\d*$/.test(value)) {
        return;
      }
      // 处理输入值，去除前导0，但允许单个0
      value = value.replace(/^0+/, '') || '0';
      cbInputValue({ name: tag, value });
    }else{
      // 如果输入为空或仅为0，允许并直接返回
      if (value === '' || value === '0') {
        cbInputValue({ name: tag, value });
        return;
      }
      // 确保输入是数字，允许小数点的存在
      if (!/^(\d+\.?\d*|\.\d+)$/.test(value)) {
        return;
      }
      // 格式化输入，确保小数点前至少有一位数字
      if (value.startsWith('.')) {
        value = '0' + value;
      }
      // 如果是整数，去除前导0
      if (/^0\d+$/.test(value)) {
        value = value.replace(/^0+/, '');
      }
      cbInputValue({ name: tag, value });
    }
  }
  // 按钮的变化
  btnClick(name){
    const {value,tag,share} = this.props
    if(value===''||(typeof(value)!=='number'&&isNaN(value))){
      this.props.cbNumberWarning()
      return
    }
    if(typeof(share)=='undefined'||!share||share==''){ 
      this.numsFun(name,value,tag)
      return
    }
    if(tag=='price') return
    this.getShareFun(name,Number(value),tag,Number(share))
  }
  // share有值时进行运算--value的倍数
  getShareFun(name,value,tag,share){
    let newVal=''
    if(name==='minus'){//mins
     if(value%share==0){
        newVal=value-share
      }else{
        newVal=value-(value%share)
      }
    }else{//puls
      newVal=value+share-(value%share)
    }
    let obj={
      name:tag,
      value:newVal
    }
    this.props.cbInputValue(obj)
  }
  //加/减--运算----Quantity/Price
  numsFun(name,getNum,tag){
    if(name==='minus'){//mins
      if(String(getNum).indexOf('.')===-1&&getNum>0){//整数--不能小于0
        getNum--
      }else{//小数
        if(getNum> 0){//不能小于0
          let len = getNum.toString().split('.')[1].length
          let num = '0.'
          for (let i = 0;i<len-1;i++){
            num += 0
          }
          num+='1'
          getNum=Number(getNum-num).toFixed(len)
        }
      }
    }else{//puls
      if(String(getNum).indexOf('.')===-1){//整数
        getNum++
      }else{//小数
        let len = getNum.toString().split('.')[1].length
        let num = '0.'
        for (let i = 0;i<len-1;i++){
          num += 0
        }
        num+='1'
        getNum=Number(Number(getNum)+Number(num)).toFixed(len)
      }
    }
    let obj={
      name:tag,
      value:getNum
    }
    this.props.cbInputValue(obj)
  }
  render(){
    const {t,ChgThemeData,placeholderName,value,tag,isTablet,isMobile,isHasBuySelll,minShare,priceLen,QtyHeld} = this.props
    return (
      <div 
        className={[
          APPstyles.flex_between,
          styles.number_con,
          value==''?styles.number_con_not:'',
          isTablet&&!isMobile?styles.number_con_minPC:'',
          isHasBuySelll?styles.number_con_has:'',
          ChgThemeData=='Light'&&value&&!isHasBuySelll?styles.number_con_light:''
        ].join(' ')}
      >
        <div className={[APPstyles.flex_topcenter,APPstyles.flex_1].join(' ')}>
          <input 
            autoComplete='off'
            type='number' 
            placeholder={t(placeholderName)}  
            value={value} 
            onChange={this.changeNumClick.bind(this)} 
            style={{
              fontSize:tag=='price'?isMobile?priceLen==6?18:priceLen==7?16:priceLen>=8?14:20:20:20
            }}
          />
          <span 
            className={[APPstyles.flex_center,styles.number_img,value?styles.linear_gradient_bg:ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.inputcon_light:APPstyles.bg_black].join(' ')}
            onClick={this.btnClick.bind(this,'minus')}   
          >
            <img 
              src={value?minusOnLightImg:minusImg} 
              alt='minus' 
              className={APPstyles.img24}  
            />
          </span>
          <span 
            className={[APPstyles.flex_center,styles.number_img,value?styles.linear_gradient_bg:ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.inputcon_light:APPstyles.bg_black].join(' ')} 
            style={{marginRight:0}}
            onClick={this.btnClick.bind(this,'plus')}
          >
            <img 
              src={value?plusOnLightImg:plusImg} 
              alt='plus' 
              className={APPstyles.img24} 
            />
          </span>
        </div>
        <div 
          className={[ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}
          style={{
            marginLeft:6,
            opacity:tag=='quantity'?1:0,
            position:'relative'
          }}
        >  
          <div className={[APPstyles.flex_column,APPstyles.flex_center,styles.number_min].join(' ')}>
            <span>{t('Min Unit')}</span>
            <span>{minShare}</span>
          </div>
          <div className={[APPstyles.flex_column,APPstyles.flex_center].join(' ')} style={{opacity:0}}>
            <span>{t('Qty Held1')}</span>
            <span>{QtyHeld?common.transThousands(QtyHeld):0}</span>
          </div>
        </div>
      </div>
    )}
}
export default withTranslation()(observer(NumberCon))
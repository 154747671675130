import { makeAutoObservable, action, autorun } from "mobx";

class DataStore {
    subscribeRicList = []
    subscribeFidList = [6, 11, 56,32,21,22,25,19,12,13,3404,16,3265,3266,3854,3372,1465]
    subscribeDetailRicList = []
    data = []
    subscribeRicListIsUpdated = false
    folioInfoData = []

    constructor() {
        makeAutoObservable(this)
    }
    setSubscribeRicListIsUpdated(status) {
        this.subscribeRicListIsUpdated = status
    }
    setStreamingData(data) {
        //check ric in data list
        for (let i in data) {
            let idx = this.data.findIndex((a) => a.ric === i)
            if (idx < 0) {
                this.data.push({ ric: i })
            }
            idx = this.data.findIndex((a) => a.ric === i)
        }
        let tmpData = JSON.parse(JSON.stringify(this.data))
        tmpData.map((val) => {
            if (data[val.ric]) {
                // console.log(data[val.ric])
                // TRDPRC_1,NETCHNG_1,PCTCHNG,ACVOL_1,HST_CLOSE
                val.TRDPRC_1 = data[val.ric]["6"] || (val.TRDPRC_1 || '-')
                val.NETCHNG_1 = data[val.ric]["11"] || (val.NETCHNG_1 || '-')
                val.PCTCHNG = data[val.ric]["56"] || (val.PCTCHNG || '-')
                val.ACVOL_1 = data[val.ric]["32"] || (val.ACVOL_1 || '-')
                val.HST_CLOSE = data[val.ric]["21"] || (val.HST_CLOSE || '-')

                val.BID = data[val.ric]["22"] || (val.BID || '-')
                val.ASK = data[val.ric]["25"] || (val.ASK || '-')
                val.OPEN_PRC = data[val.ric]["19"] || (val.OPEN_PRC || '-')
                val.HIGH_1 = data[val.ric]["12"] || (val.HIGH_1 || '-')
                val.LOW_1 = data[val.ric]["13"] || (val.LOW_1 || '-')
                val.VWAP = data[val.ric]["3404"] || (val.VWAP || '-')
                val.TRADE_DATE = data[val.ric]["16"] || (val.TRADE_DATE || '-')
                val.SALTIM_MS = data[val.ric]["3854"] || (val.SALTIM_MS || '-')
                val['52WK_HIGH'] = data[val.ric]["3265"] || (val['52WK_HIGH'] || '-')
                val['52WK_LOW'] = data[val.ric]["3266"] || (val['52WK_LOW'] || '-')
                //OFF_CLOSE
                val.OFF_CLOSE = data[val.ric]["3372"] || (val.OFF_CLOSE || '-')
                //ADJUST_CLS
                val.ADJUST_CLS = data[val.ric]["1465"] || (val.ADJUST_CLS || '-')
                if(val.OFF_CLOSE !== undefined && val.OFF_CLOSE !=="0" && val.OFF_CLOSE !== '-'){
                    val.TRDPRC_1 = val.OFF_CLOSE
                    // Use OFF_CLOSE and ADJUST_CLS to calculate NETCHNG_1 and PCTCHNG
                    if(val.ADJUST_CLS !== undefined && val.ADJUST_CLS !== "0" && val.ADJUST_CLS !== "-"){
                        val.NETCHNG_1 = val.OFF_CLOSE - val.ADJUST_CLS
                        val.PCTCHNG =  (val.NETCHNG_1 / val.ADJUST_CLS) * 100
                    } else {
                        val.NETCHNG_1 = 0
                        val.PCTCHNG = 0
                    }
                }
                //closing run
                if (val.TRDPRC_1 === "0" || val.TRDPRC_1 === "-") {
                    val.TRDPRC_1 = val.HST_CLOSE || '-'
                    val.NETCHNG_1 = '-'
                    val.PCTCHNG = '-'
                    val.ACVOL_1 = '-'
                }
                
            }
        })
        this.setData(tmpData)
        // this.data = { ...this.data, ...data };
    }
    setData(data) {
        // console.log(JSON.stringify(data))
        this.data = data
        // console.log(this.data)
    }
    setQuoteData(data){
        this.data= Object.assign(this.data, data)
    }
    getDataByRic(ric){
       return this.data.find((a)=>a.ric===ric)
    }
    addSubscribeRic(ric) {
        this.subscribeRicList.push(ric)
        this.subscribeRicList= [...new Set(this.subscribeRicList)];
        this.setSubscribeRicListIsUpdated(true)
    }
    addSubscribeList(riclist) {
        this.subscribeRicList = riclist;
        this.setSubscribeRicListIsUpdated(true)
    }
    addSubscribeDetailRic(ric) {
        this.subscribeDetailRicList.push(ric)
        this.setSubscribeRicListIsUpdated(true)
    }
    addSubscribeDetailList(riclist) {
        this.subscribeDetailRicList = riclist;
        this.setSubscribeRicListIsUpdated(true)
    }
    setFolioInfoData(data){
        this.folioInfoData = data
    }

}

export default DataStore;
import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '../../../../asserts/js/common'
import { 
    dropDownImg,
    dropDownOnImg,
    dropDownOnLightImg,
    leftImg,
    leftLightImg
} from '../../../../asserts/imgs/common/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'
import DropDown from './dropDown/index'

class StreetEvent extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isEventShow:false,
            event:'Select Events',
            time:'Any Time',
            isTimeShow:false,
            listIndex:0,
            isReset:false,
            isDetailShow:true,
            eventImg:dropDownImg,
            timeImg:dropDownImg,
            typeKeyData:['EconomicEvents','ExDividends','StockSplits'],
            minW510:510,
            minW450:450
        }
        this.myRef = React.createRef()
    }
    // Select Events
    cbEventTypeFun(data){
        const {ChgLangData,period,isMobile}=this.props
        if(isMobile){
            this.setState({
                isEventShow:false
            })
        }
        this.setState({
            event: !data[1]?'Select Events':ChgLangData=='zh-CN'?'選擇'+data[1]+'個項目':data[1]+' item(s) selected',
            listIndex:0
        })
        let types=!data[0].length||data[0]=='All'?['All']:data[0]
        let result={
            tag:'event',
            eventTypes:types,
            period:period
        }
        this.props.cbStreetEventData(result)
    }
    // Select Time
    cbEventTimeFun(data){
        const {eventTypes}=this.props
        this.setState({
            time: data['name'],
            isTimeShow:false,
            period:data['key'],
            listIndex:0,
            timeImg:dropDownImg
        })
        let result={
            tag:'time',
            period:data['key'],
            eventTypes:eventTypes
        }
        this.props.cbStreetEventData(result)
    }
    //reset
    resetClick(){
        this.props.cbResetData()
    }  
    //页面滚动触发的事件--pc
    handleScroll(){
        const {isPC}=this.props
        this.setState({
            isTimeShow:false,
            isEventShow:false
        })
        if(isPC&&(Number(this.myRef.current.scrollHeight - this.myRef.current.scrollTop- this.myRef.current.clientHeight) < 10)){
            this.props.cbScrollSEData()
        }
    }
    //点击外部关闭下拉框--event
    handleEventClick = e => {
        if(!this.testEventComponent.contains(e.target)) {
            this.setState({
                isEventShow:false
            })
        }
    }
    //点击外部关闭下拉框--time
    handleTimeClick = e => {
        if(!this.testTimeComponent.contains(e.target)) {
            this.setState({
                isTimeShow:false
            })
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {bodyW,isScrollShow,isRightPageShow,currentIndex,isPC,ricData,isResetShow,period,timeData,eventData,ChgLangData}=this.props 
        if(bodyW!==newProps['bodyW']){
            this.setState({
                isDetailShow:isPC?true:false
            })
        }
        if(isScrollShow!==newProps['isScrollShow']){
            this.setState({
                isTimeShow:false,
                isEventShow:false
            })
        }
        if(ChgLangData!==newProps['ChgLangData']){
            let eventKeyData=common.dealCheckedKeyData(eventData)
            let chgEventDatas=ChgLangData=='zh-CN'?'選擇'+eventKeyData.length+'個項目':eventKeyData.length+' item(s) selected'
            this.setState({
                event:chgEventDatas
            })
        }
        if(isRightPageShow!==newProps['isRightPageShow']){
            if(currentIndex==3) return
            this.setState({
                isDetailShow:isRightPageShow?false:true
            })
        }
        if(isResetShow!==newProps['isResetShow']){
            let eventKeyData=common.dealCheckedKeyData(eventData)
            let chgEventDatas=ChgLangData=='zh-CN'?'選擇'+eventKeyData.length+'個項目':eventKeyData.length+' item(s) selected'
            this.setState(state => ({
                isReset: !state.isReset,
                isEventShow: false,
                isTimeShow:false,
                event:chgEventDatas,
                time:'Any Time',
                listIndex:0
            }))
        }
        //更改数据 
        if((ricData!==newProps['ricData'])){
            if(ricData&&JSON.stringify(ricData) !== '{}'){
                let eventKeyData=common.dealCheckedKeyData(eventData)
                let chgEventDatas=ChgLangData=='zh-CN'?'選擇'+eventKeyData.length+'個項目':eventKeyData.length+' item(s) selected'
                let chgTime=timeData.find(item=>item['key']==period)
                this.setState(state => ({
                    isReset: !state.isReset,
                    isEventShow: false,
                    isTimeShow:false,
                    event:eventKeyData.length?chgEventDatas:'Select Events',
                    time:chgTime['name'],
                    listIndex:0
                }))
            }
        }
    }
    //初始化
    componentDidMount(){
        const {isRightPageShow,currentIndex,isPC,eventData,ChgLangData}=this.props
        let eventKeyData=common.dealCheckedKeyData(eventData)
        this.setState({
            isDetailShow:isPC&&isRightPageShow==''&&currentIndex!==3?true:false,
            event: ChgLangData=='zh-CN'?'選擇'+eventKeyData.length+'個項目':eventKeyData.length+' item(s) selected'
        })
        document.addEventListener('click', this.handleEventClick)
        document.addEventListener('click', this.handleTimeClick)
    }
    //卸载
    componentWillUnmount() {
        document.removeEventListener('click', this.handleEventClick)
        document.removeEventListener('click', this.handleTimeClick)
    }
    render(){
        const {t,ChgThemeData,isLoading,loadMore,noMore,listsData,eventData,timeData,isRightPageShow,currentIndex,isPC,isTablet,isMobile,bodyW} = this.props
        const {isEventShow,event,time,isTimeShow,isReset,listIndex,isDetailShow,eventImg,timeImg,typeKeyData,minW510,minW450}=this.state
        let resData=listsData&&listsData[listIndex]
        let event_type=resData&&resData['event_type'] 
        return (
            <div 
            className={[
                styles.streetev_con,
                APPstyles.flex_row,
                isPC&&(isRightPageShow==''||currentIndex==3)?APPstyles.flex1_hidden:'',
                isPC?styles.heightPer:''
            ].join(' ')}
            >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                <div 
                    className={[
                        styles.left,
                        APPstyles.flex_column,
                        isPC&&(isRightPageShow==''&&currentIndex!==3)?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
                        isPC&&(isRightPageShow==''&&currentIndex!==3)?APPstyles.wPer30:APPstyles.wPer100
                    ].join(' ')}
                >
                    <div 
                        className={[
                            styles.header,
                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                        ].join(' ')}
                    >
                        <div
                            className={[
                                styles.select_con,
                                APPstyles.flex_between,
                                ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.calendar_dark,
                                ChgThemeData=='Light'?APPstyles.app_layout_light:APPstyles.app_layout_back
                            ].join(' ')}
                            onClick={()=>{
                                this.setState(state => ({
                                    isEventShow: !state.isEventShow,
                                    isTimeShow:false
                                }))
                            }}
                            onMouseOver={()=>{
                                if(!isPC) return
                              let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                              this.setState({
                                eventImg:imgurl
                              })
                            }} 
                            onMouseOut={()=>{
                                if(!isPC) return
                              this.setState({
                                eventImg:dropDownImg
                              })
                            }} 
                            ref = {testComponent => this.testEventComponent = testComponent} 
                        >
                            <span style={{flex:1}}>{t(event)}</span>
                            <img
                                src={eventImg}
                                alt='dropdown'
                                className={[isEventShow ? styles.imgRotate : '',styles.select_img].join(' ')}
                            />
                            <DropDown 
                                {...this.props}
                                isDropDownShow={isEventShow}
                                dropDownData={eventData}
                                tag='event'
                                isReset={isReset}
                                cbSelectData={this.cbEventTypeFun.bind(this)}
                                cbDropDownHidden={(data)=>{
                                    this.setState({
                                        isEventShow:data
                                    })
                                }} 
                            />
                        </div>
                        <div
                            className={[
                                styles.select_con,
                                APPstyles.flex_between,
                                ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.calendar_dark,
                                ChgThemeData=='Light'?APPstyles.app_layout_light:APPstyles.app_layout_back
                            ].join(' ')}
                            onClick={()=>{
                                this.setState(state => ({
                                    isTimeShow: !state.isTimeShow,
                                    isEventShow:false
                                }))
                            }} 
                            onMouseOver={()=>{
                                if(!isPC) return
                              let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                              this.setState({
                                timeImg:imgurl
                              })
                            }} 
                            onMouseOut={()=>{
                                if(!isPC) return
                              this.setState({
                                timeImg:dropDownImg
                              })
                            }} 
                            ref = {testComponent => this.testTimeComponent = testComponent} 
                        >
                            <span style={{flex:1}}>{t(time)}</span>
                            <img
                                src={timeImg}
                                alt='dropdown' 
                                className={[isTimeShow ? styles.imgRotate : '',styles.select_img].join(' ')}
                            />
                            <DropDown 
                                {...this.props}
                                isDropDownShow={isTimeShow}
                                dropDownData={timeData}
                                tag='time'
                                isReset={isReset}
                                cbSelectData={this.cbEventTimeFun.bind(this)}
                                cbDropDownHidden={(data)=>{
                                    this.setState({
                                        isTimeShow:data
                                    })
                                }}
                            />
                        </div>
                        <div 
                            className={[styles.reset,ChgThemeData=='Light'?APPstyles.reset_light:''].join(' ')}
                            onClick={this.resetClick.bind(this)}
                        >
                            <span>{t('Reset All')}</span>
                        </div>
                    </div>
                    <div 
                        className={[
                            styles.list,
                            isPC?APPstyles.flex1_auto:''
                        ].join(' ')} 
                        ref={this.myRef} 
                        onScroll={() => this.handleScroll()}
                        style={{
                            minHeight:isTablet&&!listsData.length?100:'auto'
                        }}
                    >   
                        {
                            listsData.length>0?
                                listsData.map((item,index) => {
                                    return  <div 
                                                className={[
                                                    styles.list_item,
                                                    ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                                    ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.btn_black,
                                                    listIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_event_black:''
                                                ].join(' ')}
                                                key={index}
                                                onClick={()=>{
                                                    this.setState({
                                                        listIndex:index,
                                                        isDetailShow:true
                                                    })
                                                    this.props.cbBackTop(isDetailShow)
                                                    //minPC
                                                    if(isTablet&&!isMobile){
                                                        this.props.cbShowDetailData(listsData[index])
                                                    }
                                                }}
                                            >
                                                <span 
                                                    className={[
                                                        styles.title,
                                                        ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1
                                                    ].join(' ')}
                                                >
                                                    {item['title']}
                                                </span>
                                                <div 
                                                    className={[
                                                        styles.content,
                                                        APPstyles.ellipsis_more2,
                                                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                                    ].join(' ')}
                                                >
                                                    {item['event_name']}
                                                </div>  
                                                <span className={styles.time}>{common.chgEventDate(item['start_date'],'ymdhm')}</span> 
                                            </div>
                                    })
                            :
                                <div className={APPstyles.isLoading} style={{display:isLoading?'none':'',zIndex:1}}>{t('No datas')}</div>     
                        }
                        <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
                        <div className={APPstyles.loading_more} style={{display:noMore?'':'none'}}>- {t('No More Data Available')} -</div>
                    </div>
                </div>
                {
                    isDetailShow&&(isPC||isMobile)?      
                    <div 
                        className={[
                            styles.right,
                            APPstyles.flex_column,
                            isMobile?styles.right_mobile:'',
                            (isTablet||isPC&&(isRightPageShow!==''||currentIndex==3))&&isDetailShow?styles.right_min:'',
                            isDetailShow?isTablet?APPstyles.position_fixed:isPC&&(isRightPageShow!==''||currentIndex==3)?styles.position_absolute:'':'',
                            (isTablet||isPC&&(isRightPageShow!==''||currentIndex==3))&&isDetailShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:'',
                            isPC&&(isRightPageShow==''||currentIndex==3)?APPstyles.flex1_auto:''
                        ].join(' ')}
                    >
                        <div 
                            className={[
                                APPstyles.flex_row,
                                styles.right_header,
                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                            ].join(' ')}
                            style={{
                                display:(isTablet||isPC&&(isRightPageShow!==''||currentIndex==3))&&isDetailShow?'':'none'
                            }}
                        >
                            <img 
                                src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                                className={APPstyles.img24} 
                                onClick={()=>{
                                    this.setState({
                                        isDetailShow:false
                                    }) 
                                }} 
                                alt='back' 
                            />
                            {t('Street Event')}
                        </div>
                        {
                            listsData.length>0?
                                <div 
                                className={[
                                    styles.right_content,
                                    (isTablet||isPC&&(isRightPageShow!==''||currentIndex==3))&&isDetailShow?APPstyles.flex1_auto:''
                                ].join(' ')}
                                >
                                    <span
                                        className={[
                                            styles.title,
                                            ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1
                                        ].join(' ')} 
                                    >
                                        {resData['title']}
                                    </span>
                                    <div 
                                        className={[
                                            styles.content,
                                            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                        ].join(' ')}
                                    >
                                        {resData['event_name']}
                                    </div>
                                    <span className={styles.time}>
                                        {common.chgEventDate(resData['start_date'],'ymdhm')}
                                    </span>
                                    <div
                                        className={[
                                            styles.table_con,
                                            APPstyles.flex_row,
                                            isMobile?styles.table_con_moble:'',
                                            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_grey1
                                        ].join(' ')}
                                        style={{
                                            display:typeKeyData.includes(event_type)?'':'none'
                                        }}
                                    >
                                        {/* 第一列 */}
                                        <div
                                            className={[
                                                styles.table_con_one,
                                                APPstyles.flex_column,
                                                event_type=='EconomicEvents'?'':styles.table_con_one_1,
                                                bodyW<=minW510?styles.table_con_one_2:'',
                                                bodyW<=minW450?styles.table_con_one_3:''
                                            ].join(' ')}
                                        >
                                            <span 
                                                className={[
                                                    styles.table_con_header,
                                                    APPstyles.flex_topcenter,
                                                    ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black
                                                ].join(' ')}
                                            >
                                                {t(event_type=='EconomicEvents'?'Period':'Announce Date')}
                                            </span>
                                            <span className={APPstyles.flex_topcenter}>
                                                {
                                                  event_type=='EconomicEvents'?
                                                    common.chgMonthYear(resData['period'])
                                                  :event_type=='ExDividends'?
                                                    common.chgEventDate(resData['div_announcement'],'ymd')
                                                  :
                                                    common.chgEventDate(resData['split_announcement'],'ymd')
                                                }
                                            </span>
                                        </div>
                                        {/* 第二列 */}
                                        <div
                                            className={[
                                                styles.table_con_one,
                                                APPstyles.flex_column,
                                                event_type=='EconomicEvents'?'':styles.table_con_one_1,
                                                bodyW<=minW510?styles.table_con_one_2:'',
                                                bodyW<=minW450?styles.table_con_one_3:''
                                            ].join(' ')}
                                        >
                                            <span
                                                className={[
                                                    styles.table_con_header,
                                                    APPstyles.flex_topcenter,
                                                    ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black
                                                ].join(' ')}
                                            >
                                                {t(event_type=='EconomicEvents'?
                                                    'Prior Value'
                                                :event_type=='ExDividends'?
                                                    'Dividend Amount'
                                                :
                                                    'Split Ratio')}
                                            </span>
                                            <span className={APPstyles.flex_topcenter}>
                                                {
                                                  event_type=='EconomicEvents'?
                                                    common.transThousands(common.keepNum(resData['prior_val'],2))
                                                  :event_type=='ExDividends'?
                                                    resData['div_curr']+' '+common.transThousands(common.keepNum(resData['div_amount'],2))
                                                  :
                                                    resData['split_ratio']
                                                }
                                                {event_type=='EconomicEvents'&&resData['prior_val']?resData['unit']:''}
                                            </span>
                                        </div>
                                        {/* 第三列 */}
                                        <div
                                            className={[
                                                styles.table_con_one,
                                                APPstyles.flex_column,
                                                event_type=='EconomicEvents'?'':styles.table_con_one_1,
                                                bodyW<=minW510?styles.table_con_one_2:'',
                                                bodyW<=minW450?styles.table_con_one_3:''
                                            ].join(' ')}
                                        >
                                            <span
                                                className={[
                                                    styles.table_con_header,
                                                    APPstyles.flex_topcenter,
                                                    ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black
                                                ].join(' ')}
                                            >
                                                {t(
                                                    event_type=='EconomicEvents'?
                                                        'Expected Value'
                                                    :event_type=='ExDividends'?
                                                        'Record Date'
                                                    :
                                                        'Record Date1'
                                                )}
                                            </span>
                                            <span className={APPstyles.flex_topcenter}>
                                                {
                                                  event_type=='EconomicEvents'?
                                                    common.transThousands(common.keepNum(resData['expected_val'],2))
                                                  :event_type=='ExDividends'?
                                                    common.chgEventDate(resData['div_recorddate'],'ymd')
                                                  :
                                                    common.chgEventDate(resData['split_recorddate'],'ymd')
                                                }
                                                {event_type=='EconomicEvents'&&resData['expected_val']?resData['unit']:''}
                                            </span>
                                        </div>
                                        {/* 第四列 */}
                                        <div
                                            className={[
                                                styles.table_con_one,
                                                APPstyles.flex_column,
                                                event_type=='EconomicEvents'?'':styles.table_con_one_1,
                                                bodyW<=minW510?styles.table_con_one_2:'',
                                                bodyW<=minW450?styles.table_con_one_3:''
                                            ].join(' ')}
                                        >
                                            <span
                                                className={[
                                                    styles.table_con_header,
                                                    APPstyles.flex_topcenter,
                                                    ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black
                                                ].join(' ')}
                                            >
                                                {t(
                                                    event_type=='EconomicEvents'?
                                                        'Actual Value'
                                                    :event_type=='ExDividends'?
                                                        'Ex-Div Date'
                                                    :
                                                        'Ex-Div Date1'
                                                    )}
                                                </span>
                                            <span className={APPstyles.flex_topcenter}>
                                                {
                                                    event_type=='EconomicEvents'?
                                                        common.chgEventDate(resData['actual_val'],'ymd')
                                                    :event_type=='ExDividends'?
                                                        common.chgEventDate(resData['div_exdate'],'ymd') 
                                                    :
                                                        common.chgEventDate(resData['split_exdate'],'ymd') 
                                                }
                                            </span>
                                        </div>
                                        {/* 第五列 */}
                                        <div
                                            className={[
                                                styles.table_con_one,
                                                APPstyles.flex_column,
                                                event_type=='EconomicEvents'?'':styles.table_con_one_1,
                                                bodyW<=minW510?styles.table_con_one_2:'',
                                                bodyW<=minW450?styles.table_con_one_3:''
                                            ].join(' ')}
                                            style={{display:event_type=='EconomicEvents'?'none':''}}
                                        >
                                            <span 
                                                className={[
                                                    styles.table_con_header,
                                                    APPstyles.flex_topcenter,
                                                    ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black
                                                ].join(' ')}
                                            >
                                                {t(event_type=='ExDividends'?'Payout Date':'Payout Date1')}
                                            </span>
                                            <span className={APPstyles.flex_topcenter}>
                                                {
                                                  event_type=='EconomicEvents'?
                                                    common.transThousands(common.keepNum(resData['actual_val'],2))
                                                  :event_type=='ExDividends'?
                                                    common.chgEventDate(resData['div_paymentdate'],'ymd')
                                                  :
                                                    common.chgEventDate(resData['split_paymentdate'],'ymd')
                                                }
                                                {event_type=='EconomicEvents'&&resData['actual_val']?resData['unit']:''}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>  
                        } 
                    </div>
                    :
                        ''    
                }
            </div>
        )}
}
export default withTranslation()(observer(StreetEvent))
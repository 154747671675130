import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  warningImg
} from '../../../../asserts/imgs/common/index'
import APPstyles from '../../../../App.less'
import comStyles from '../../../Trade/PriceModal/index.less'
import styles from './index.less'

class PriceModal extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render(){
    const {t,bodyW,mobilebodyW,ChgThemeData,tag,tipsModalData,ChgLangData}=this.props
    return (
      <div className={APPstyles.mask_con_fixed}>
        <div 
          className={[
            comStyles.price_con,
            styles.price_con,
            APPstyles.mask_content,
            APPstyles.isLoading,
            ChgThemeData=='Light'?comStyles.price_con_light:''
          ].join(' ')} 
          style={{
            width:bodyW>mobilebodyW?528:'80%'
          }}
        >
          <div 
            className={[
              comStyles.price_title,
              styles.price_title,
              APPstyles.flex_row,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
            ].join(' ')}
            style={{
              alignItems:tag=='nodata'||(tag=='checkbox'&&bodyW>=480&&ChgLangData=='zh-CN')||tag=='news'?'center':'flex-start'
            }}
          >
            <img src={warningImg} className={APPstyles.img24} style={{cursor:'default'}} alt='warning' />
            <span style={{display:tag=='nodata'&&ChgLangData=='en-US'?'':'none'}}>
              Please enter a value in the {t(tipsModalData&&tipsModalData['name'])}
            </span>
            <span style={{display:tag=='nodata'&&ChgLangData=='zh-CN'?'':'none'}}>
              请在{t(tipsModalData&&tipsModalData['name'])}里输入值
            </span>
            <span style={{display:tag=='checkbox'?'':'none'}}>{t('You have reached the maximum criteria available. Delete an criteria and try again.')}</span>
            <span style={{display:tag=='news'?'':'none'}}>{t('Please select at least')}</span>
          </div>
          <Button 
            type='default' 
            className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
            onClick={()=>{
              this.props.cbCloseModal()
            }}
          >
            {t('OK')}
          </Button>
        </div>
      </div>
  )}
}
export default withTranslation()(PriceModal)
import React from 'react'
import styles from '@/App.less'
import common from '@/asserts/js/common'
import Header from '@/components/Navigation/Header/index'
import Slider from '@/components/Navigation/Slider/index'
import SlidingPanel from '@/components/Navigation/SlidingPanel/index'
import QuoteHeader from '@/components/Quote/Header/index'
import QuoteComponents from '@/components/Quote/index'
import OrderMask from '@/components/Trade/OrderMask/index'
import Screener from '@/components/Quote/Screener/index'
import Sector from '@/components/Quote/Sector/index'
import StreetEDetail from '@/components/Quote/QuoteDetail/StreetEvent/detail/index'
import NewsDetail from '@/pages/News/right/index'

let timer=null

class Quote extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      sliderIndex:'quote',
      tradeData:[false,false,false,false],
      tradeOrderData:[],
      isClearData:false,
      ChgLangData:'zh-CN',
      ChgThemeData:'Dark',
      IsSubmitOrder:false,
      currentIndex:0,
      isSwitchStar:true,
      ricData:{},
      isTradeMinbody:true,
      isSlidingPanelShow:false,
      isLeftPageShow:'list',
      isRightPageShow:'',
      isDetailPageShow:true,
      isCghTradeData:true,
      isOpenAlertModalInTabletMode: false,
      StreetEDetailShow:false,
      streetEData:'',
      isShowHeaderPage:true,
      isShowAlertsModal:false,
      alertsPage:0,
      isSwitchShow:false,
      closeRightPageShow:false,
      newsDetailShow:false,
      newsLoading:false,
      newRightData:'',
      currentData23:[2,3],
      watchlistId:'1',
      watchListData:[],
      tabsData:[
        {
          name:'Popular Stocks',
          disabled:'',
          isShow:false
        },
        {
          name:'Portfolio1',
          disabled:'',
          isShow:false
        },
        {
          name:'Screener',
          disabled:'',
          isShow:false
        },
        {
          name:'Sector1',
          disabled:'',
          isShow:false
        },
        {
          id: 'item-0',
          name:'Watchlist1',
          disabled:'disabled',
          isEdit:false,
          isShow:false
        },
        {
          id: 'item-1',
          name:'Watchlist2',
          disabled:'disabled',
          isEdit:false,
          isShow:false
        },
        {
          id: 'item-2',
          name:'Watchlist3',
          disabled:'disabled',
          isEdit:false,
          isShow:false
        },
        {
          id: 'item-3',
          name:'Watchlist4',
          disabled:'disabled',
          isEdit:false,
          isShow:false
        }
      ],
      isGetWatchListShow:false,
      isOnLoad:true,
      isReLoadOrder:false,
      tradeName:'',
      isSwitchModalShow:false,
    }
  }
  //slider--更改navIndex
  //added param isClose, using for close trade modal, slider nav icon rearranged and it conflict between old index and nex index
  callbackChgNavIndex(id,isClose){
    let tradeData=!isClose&&(id=='trade'||id=='alerts')?[true,true,false,false]:[false,false,false,false]
    if(!isClose&&id=='trade'){this.setState({isOpenAlertModalInTabletMode:false})}
    if(!isClose&&id=='alerts'){this.setState({isOpenAlertModalInTabletMode:true})}
    this.setState({
      sliderIndex:id,
      tradeData:tradeData
    })
  }
  //遮罩层--显示/隐藏--trade
  calkbackBtnStatus(data){
    const {ricData}=this.state
    this.setState({
      tradeData:data,
      ricData:data[0]?ricData:{}
    })
  }
  //提交订单的数据--trade
  calkbackOrderData(data){
    this.setState({
      tradeOrderData:data
    })
  }
  //提交订单成功--不关闭trade页面--移动端
  cbSubmitOrderFun(){
    const {IsSubmitOrder}=this.state
    this.setState({
      IsSubmitOrder:!IsSubmitOrder
    })
  }
  // 获取路由跳转--app.js
  callbackPathnameApp(data){
    this.props.callbackPathnameApp(data)
  }
  //top--tab栏切换
  callbackTab(index){
    this.setState({
      currentIndex:index,
      isSwitchStar:true
    })
    sessionStorage.removeItem('ChgAddData')
    sessionStorage.removeItem('CriteriaData')
  }
  //currentIndex==2/3
  cbChgTabIndex(index,data){
    this.setState({
      ricData:data,
      currentIndex:index,
      isSwitchStar:true
    })
  }
  //trade页面--显示/隐藏
  callbackTradeShow(tag,ricData){
    const {isMobile,isPC,isTablet}=this.props
    const {isRightPageShow,currentIndex}=this.state
    if(tag=='close'){
      this.setState({
        isRightPageShow:''
      })
      return
    }
    //PC端
    if(isPC){
      this.setState({
        isRightPageShow:'trade'
      })
    }
    //minpc/currentIndex==2/3
    if((isTablet&&!isMobile)||currentIndex==2||currentIndex==3){
      this.setState({
        sliderIndex:'trade',
        ricData:ricData,
        tradeData:[true,true,false,false]
      })
      this.setState(state => ({
        isTradeMinbody: !state.isTradeMinbody
      }))
      this.setState({
        isOpenAlertModalInTabletMode: false
      })
    }
    if(isMobile){
      // 移动端
      this.setState({
        isRightPageShow:isRightPageShow=='trade'?'':'trade'
      })
    }
  }
  // alarm page -- display/hidden
  callbackAlertsShow(tag,ricData,page,isModal){
    const {isMobile,isPC,isTablet}=this.props
    const {isRightPageShow,currentIndex}=this.state
    this.setState({alertsPage: page?page:0})
    if(tag=='close'){
      this.setState({
        isRightPageShow:''
      })
      return
    }
    //PC端
    if(isPC&&!isModal){
      this.setState({
        isRightPageShow:'alerts'
      })
    }
    //minpc/currentIndex==2/3
    if(((isTablet || isModal)&&!isMobile)||currentIndex==2||currentIndex==3){
      this.setState({
        sliderIndex:'trade',
        ricData:ricData,
        tradeData:[true,true,false,false]
      })
      this.setState(state => ({
        isTradeMinbody: !state.isTradeMinbody
      }))
      this.setState({
        isOpenAlertModalInTabletMode: true
      })
    }
    if(isMobile){
      // 移动端
      this.setState({
        isRightPageShow:isRightPageShow=='alerts'?'':'alerts'
      })
    }
  }
  // 判断fbstockid是否有值，没有则隐藏trade页面
  callbackFbstockid(fbstockid){
    if(typeof(fbstockid)!=='undefined') return
    this.setState({
      isRightPageShow:''
    })
  }
  //是否显示左侧的menu
  callbackShowSlidingPanel(data){
    this.setState({
      isSlidingPanelShow:data
    })
  }
  // switch between alert modal and trade modal
  callbackShowAlertModal(bool){
    this.setState({isOpenAlertModal: bool})
  }
  // Events tablet detail show
  cbShowDetailDataFun(data){
    this.setState({
      StreetEDetailShow: true,
      streetEData:data
    })
  }
  // newdata
  chgRightDetailData(data,loading){
    this.setState({
      newsDetailShow: true,
      newsLoading:loading,
      newRightData:data
    })
  }
  //watchlist---set
  setWatchListFun(wlData,watchListData,tabsData,isSetData){
    let tabDataAll=localStorage.getItem('tabDataAll')
    let indexData=[4,5,6,7]
    if(tabDataAll){
      tabDataAll=JSON.parse(tabDataAll)
      for(let i in tabDataAll){
        if(i>3){
          let id=tabDataAll[i]['id']
          id=id.split('-')[1]
          for(let j in indexData){
            if(id==j){
              indexData[id]=Number(i)
            }
          }
        }
      }
      watchListData=this.indexDataFun(indexData,wlData,watchListData)
      this.updateNameCommonFun_0(tabDataAll,watchListData,'Y',isSetData)
    }else{
      watchListData=this.indexDataFun(indexData,wlData,watchListData)
      this.updateNameCommonFun_1(tabsData,watchListData)
      this.setWatchListDataFun(watchListData,'Y','Y',isSetData)
    }
  }
  // 处理数据--限制长度为50
  indexDataFun(indexData,wlData,watchListData){
    for(let j in indexData){
      let data=wlData[Number(indexData[j])]
      if(data.length){
        for(let k in data){
          // 限制长度为50
          if(watchListData[j]['stock_id_list'].length<50){
            watchListData[j]['stock_id_list'].push(data[k]['ric'])
          }
        }
      }
    }
    return watchListData
  }
  // 动态添加/取消
  chgWatchListFun(ric,tag,id){
    const {watchListData}=this.state
    let item=watchListData.find(item=>item['watchlist_id']==id)
    let ricData=item['stock_id_list']
    // 添加
    if(tag=='add'){
      ricData.unshift(ric)
    // 删除
    }else{
      for(let i in ricData){
        if(ricData[i]==ric){
          ricData.splice(i,1)  
        }
      }
    }
    this.setWatchListDataFun(watchListData,'Y','N')
  }
  // watchlist---set 
  setWatchListDataFun(data,setTag,getTag,isSetData){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0&&!isSetData){
        setTag=='Y'&&this.getWatchListFun(getTag)
      }
    })
  }
  setWatchListDataFun1(data){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => { })
  }
  //watchlist---get
  getWatchListFun(tag){
    const {tabsData,isGetWatchListShow,isOnLoad}=this.state
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'user-watchlist?token='+token,{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        if(isOnLoad){
          this.setState({
            isOnLoad:false
          })
          sessionStorage.setItem('isLoginOnload','N')
        }
        let data=res['data'];
        this.setState({
          watchListData:data,
          isGetWatchListShow:!isGetWatchListShow
        })
        sessionStorage.setItem('watchListData',JSON.stringify(data))
        sessionStorage.setItem('tabData',JSON.stringify(tabsData))
        if(tag=='Y'){
          let wlData=localStorage.getItem('watchlistDataAll')
          localStorage.removeItem('watchTabDataAll')
          if(wlData){
            wlData=JSON.parse(wlData)
            this.setWatchListFun(wlData,data,tabsData)
            localStorage.removeItem('watchlistDataAll')
          }else{
            this.updateTabDataNameAllFun(data,tabsData)
          }
        }
      }else if(res['status']==404){
        fetch(common.getHttpZs+'getWatchList',{
          method:'POST',
          headers:{
            'Accept':'application/json,text/plain,*/*',
            'Content-Type':'application/json'
          },
          body:JSON.stringify({
            token:sessionStorage.getItem('token'),
          })
        })
        .then(res => res.json())
        .then(res => {
          if(res['status']==0){
            if(isOnLoad){
              this.setState({
                isOnLoad:false
              })
              sessionStorage.setItem('isLoginOnload','N')
            }
            let data=res['data']['datalist'];
            this.setState({
              watchListData:data,
              isGetWatchListShow:!isGetWatchListShow
            })
            sessionStorage.setItem('watchListData',JSON.stringify(data))
            sessionStorage.setItem('tabData',JSON.stringify(tabsData))
            if(tag=='Y'){
              let wlData=localStorage.getItem('watchlistDataAll')
              localStorage.removeItem('watchTabDataAll')
              if(wlData){
                wlData=JSON.parse(wlData)
                this.setWatchListFun(wlData,data,tabsData,true)
                localStorage.removeItem('watchlistDataAll')
              }else{
                this.updateTabDataNameAllFun(data,tabsData,true);
              }
            }else{
              this.setWatchListDataFun1(data);
            }
          }
        })
      }
    })
  }
  updateTabDataNameAllFun(watchListData,tabsData,isSetData){
    let tabDataAll=localStorage.getItem('tabDataAll')
    if(tabDataAll){
      tabDataAll=JSON.parse(tabDataAll)
      this.updateNameCommonFun_0(tabDataAll,watchListData,'N',isSetData)
    }else{
      this.updateNameCommonFun_1(tabsData,watchListData)
      if(isSetData){
        this.setWatchListDataFun1(watchListData);
      }
    }
  }
  // 存储的tabData有数据则更改watchListData
  updateNameCommonFun_0(tabsData,watchListData,tag,isSetData){
    let newData=[]
    for(let i in tabsData){
      if(i>3){
        let id=tabsData[i]['id']
        id=Number(id.split('-')[1])
        for(let j in watchListData){
          if(id+1==watchListData[j]['watchlist_id']){
            let obj={
              watchlist_name:tabsData[i]['name'],
              watchlist_id:watchListData[j]['watchlist_id'],
              stock_id_list:watchListData[j]['stock_id_list']
            }
            newData.push(obj)
          }
        }
      }
    }
    this.setState({
      watchListData:newData,
      tabsData:tabsData
    })
    this.setWatchListDataFun(newData,tag,'Y',isSetData)
    localStorage.removeItem('tabDataAll')
    sessionStorage.setItem('watchListData',JSON.stringify(newData))
    sessionStorage.setItem('tabData',JSON.stringify(tabsData))
    sessionStorage.setItem('newTabData',JSON.stringify(tabsData));
  }
  // 没有存储的tabData则更改tabData
  updateNameCommonFun_1(tabsData,watchListData){
    for(let i in tabsData){
      if(i>3){
        let id=tabsData[i]['id']
        id=id.split('-')[1]
        for(let j in watchListData){
          if(Number(id)+1==watchListData[j]['watchlist_id']){
            tabsData[i]['name']=watchListData[j]['watchlist_name']
          }
        }
      }
    }
    this.setState({
      tabsData:tabsData
    })
    sessionStorage.setItem('tabData',JSON.stringify(tabsData))
    sessionStorage.setItem('newTabData',JSON.stringify(tabsData));
  }
  // 是否显示trade页面
  getIsTradeShowFun(){
    let target=sessionStorage.getItem('target')
    if(target=='trade'){
      this.setState({
        sliderIndex:'trade',
        tradeData:[true,true,false,false]
      })
      sessionStorage.setItem('navId','trade')
    }
  }
  // 查詢下單時各個市場是否使用台幣交易
  getCustomerCurrency(){
    fetch(common.getHttpZs+'getCustomerCurrency',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        sessionStorage.setItem('customerCurrency',JSON.stringify(res['data']));
      }else{
        sessionStorage.setItem('customerCurrency',JSON.stringify([]));
      }
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,isMobile,isTablet}=this.props
    if(bodyW!==newProps['bodyW']&&isTablet){
      this.setState({
        isRightPageShow: ''
      })
    }
    if(bodyW!==newProps['bodyW']){
      this.setState({
        isDetailPageShow: !isMobile?true:false,
        StreetEDetailShow:false,
        isShowHeaderPage:true
      })
    }
  } 
  //初始化
  componentDidMount(){
    const {isMobile}=this.props
    //更改主题--setting
    let theme=localStorage.getItem('theme')
    let lang=localStorage.getItem('lang')
    let tradeName=localStorage.getItem('tradeName')
    this.setState({
      ChgThemeData:theme?theme:'Dark',
      ChgLangData:lang?lang:'zh-CN',
      isDetailPageShow: !isMobile?true:false,
      tradeName:tradeName?tradeName:'general'
    })
    this.getIsTradeShowFun();
    // ---------登录进来后只执行1次，其他页面切换不执行getWatchList---------
    let isLoginOnload=sessionStorage.getItem('isLoginOnload')
    if(isLoginOnload=='N'){
      let watchList=JSON.parse(sessionStorage.getItem('watchListData'))
      let tabData=JSON.parse(sessionStorage.getItem('tabData'))
      this.setState({
        watchListData:watchList,
        tabsData:tabData
      });
      sessionStorage.setItem('newTabData',JSON.stringify(tabData));
    }else{
      this.getWatchListFun('Y');
      this.getCustomerCurrency();
    }
  }
  //卸载
  componentWillUnmount() {
    clearInterval(timer)
  }
  render(){
    const {tradeData,sliderIndex,tradeOrderData,ChgThemeData,ChgLangData,IsSubmitOrder,currentIndex,isSwitchStar,ricData,isTradeMinbody,isSlidingPanelShow,isLeftPageShow,isRightPageShow,isDetailPageShow,isCghTradeData,StreetEDetailShow,streetEData,isShowHeaderPage,isSwitchShow,closeRightPageShow,newsDetailShow,newsLoading,newRightData,alertsPage,isOpenAlertModalInTabletMode,currentData23,watchlistId,watchListData,tabsData,isGetWatchListShow,isReLoadOrder,tradeName,isSwitchModalShow}=this.state
    return (
      <div 
        className={[
          styles.flex_column,
          styles.app_layout,
          ChgThemeData=='Light'?styles.app_layout_light:'',
          ChgThemeData=='Light'?styles.bg_light:styles.bg_page_black
        ].join(' ')}
      >
        <div className={styles.sticky_top_mobile}>
          <Header 
            {...this.props}
            ChgThemeData={ChgThemeData}
            isShowHeaderPage={isShowHeaderPage}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          />
          <QuoteHeader
            {...this.props}
            tabsData={tabsData}
            ChgThemeData={ChgThemeData}
            currentIndex={currentIndex}
            isShowHeaderPage={isShowHeaderPage}
            isLeftPageShow={isLeftPageShow}
            isDetailPageShow={isDetailPageShow}
            callbackTab={this.callbackTab.bind(this)}
            callbackAlertsShow={this.callbackAlertsShow.bind(this)}
            callbackLeftPageShow={(data)=>{
              this.setState({
                isLeftPageShow:data
              })
            }}
            cbIsShowHeaderPage={(data)=>{
              this.setState({
                isShowHeaderPage:data
              })
            }}
            cbWatchlistId={(id)=>{
              this.setState({
                watchlistId:id
              })
            }}
          />
        </div>
        {
          currentIndex==2?
            <Screener 
              {...this.props} 
              currentIndex={currentIndex}
              ChgThemeData={ChgThemeData}
              ChgLangData={ChgLangData}
              isLeftPageShow={isLeftPageShow}
              sliderIndex={sliderIndex}
              currentData23={currentData23}
              watchlistId={watchlistId}
              watchListData={watchListData}
              tabsData={tabsData}
              isGetWatchListShow={isGetWatchListShow}
              isDetailPageShow={isDetailPageShow}
              tradeName={tradeName}
              callbackTradeShow={this.callbackTradeShow.bind(this)}
              callbackFbstockid={this.callbackFbstockid.bind(this)}
              callbackTab={this.callbackTab.bind(this)}
              cbChgTabIndex={this.cbChgTabIndex.bind(this)}
              cbShowDetailData={this.cbShowDetailDataFun.bind(this)}
              cbRightDetailShowFun={this.chgRightDetailData.bind(this)}
              cbSetWatchListFun={this.chgWatchListFun.bind(this)}
              cbDetailPageShow={(data)=>{
                this.setState({
                  isDetailPageShow: data
                })
              }}
              cbCghTradeData={(data)=>{
                this.setState({
                  isCghTradeData:data
                })
              }}
              cbIsShowHeaderPage={(data)=>{
                this.setState({
                  isShowHeaderPage:data
                })
              }}
              callbackLeftPageShow={(data)=>{
                this.setState({
                  isLeftPageShow:data
                })
              }}
              callbackChgTabName={(data)=>{
                //QuoteWatchlistEdit---tab栏更改
                this.setState({
                  tabsData:data
                })
                sessionStorage.setItem('newTabData',JSON.stringify(data));
              }}
              cbChgWatchlistIdFun={(id)=>{
                this.setState({
                  watchlistId: id,
                  isRightPageShow:''
                })
              }}
              cbOnLoadGetWatchlistFun={()=>{
                this.getWatchListFun('N')
              }}
            />
          :currentIndex==3?
            <Sector 
              {...this.props}
              ChgThemeData={ChgThemeData}
              ChgLangData={ChgLangData}
              isLeftPageShow={isLeftPageShow}
              isRightPageShow={isRightPageShow} 
              currentIndex={currentIndex}
              sliderIndex={sliderIndex}
              currentData23={currentData23}
              watchlistId={watchlistId}
              watchListData={watchListData}
              tabsData={tabsData}
              isGetWatchListShow={isGetWatchListShow}
              isDetailPageShow={isDetailPageShow}
              tradeName={tradeName}
              callbackFbstockid={this.callbackFbstockid.bind(this)}
              callbackTab={this.callbackTab.bind(this)}
              callbackTradeShow={this.callbackTradeShow.bind(this)}
              cbChgTabIndex={this.cbChgTabIndex.bind(this)}
              callbackAlertsShow={this.callbackAlertsShow.bind(this)}
              cbShowDetailData={this.cbShowDetailDataFun.bind(this)}
              cbRightDetailShowFun={this.chgRightDetailData.bind(this)}
              cbSetWatchListFun={this.chgWatchListFun.bind(this)}
              callbackLeftPageShow={(data)=>{
                this.setState({
                  isLeftPageShow:data
                })
              }}
              cbCghTradeData={(data)=>{
                this.setState({
                  isCghTradeData:data
                })
              }} 
              callbackChgTabName={(data)=>{
                //QuoteWatchlistEdit---tab栏更改
                this.setState({
                  tabsData:data
                })
                sessionStorage.setItem('newTabData',JSON.stringify(data));
              }} 
              cbDetailPageShow={(data)=>{
                this.setState({
                  isDetailPageShow: data
                })
              }}
              cbIsShowHeaderPage={(data)=>{
                this.setState({
                  isShowHeaderPage:data
                })
              }}
              cbChgWatchlistIdFun={(id)=>{
                this.setState({
                  watchlistId: id,
                  isRightPageShow:''
                })
              }}
              cbOnLoadGetWatchlistFun={()=>{
                this.getWatchListFun('N')
              }}
            />
          :
          <QuoteComponents 
            {...this.props}
            sliderIndex={sliderIndex}
            ChgThemeData={ChgThemeData}
            ChgLangData={ChgLangData}
            ChgThemeDataTemp={ChgThemeData}
            ChgLangDataTemp={ChgLangData}
            tradeData={tradeData}
            IsSubmitOrder={IsSubmitOrder}
            currentIndex={currentIndex}
            isSwitchStar={isSwitchStar}
            isLeftPageShow={isLeftPageShow}
            isRightPageShow={isRightPageShow}
            isDetailPageShow={isDetailPageShow}
            ricData={ricData}
            alertsPage={alertsPage}
            isSwitchShow={isSwitchShow}
            closeRightPageShow={closeRightPageShow}
            currentData23={currentData23}
            watchlistId={watchlistId}
            watchListData={watchListData}
            tabsData={tabsData}
            isGetWatchListShow={isGetWatchListShow}
            tradeName={tradeName}
            calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
            calkbackOrderData={this.calkbackOrderData.bind(this)} 
            callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
            callbackTradeShow={this.callbackTradeShow.bind(this)}
            callbackAlertsShow={this.callbackAlertsShow.bind(this)}
            callbackFbstockid={this.callbackFbstockid.bind(this)}
            cbShowDetailData={this.cbShowDetailDataFun.bind(this)}
            cbRightDetailShowFun={this.chgRightDetailData.bind(this)}
            callbackTab={this.callbackTab.bind(this)}
            cbSetWatchListFun={this.chgWatchListFun.bind(this)}
            callbackChgTabName={(data)=>{
              //QuoteWatchlistEdit---tab栏更改
              this.setState({
                tabsData:data
              })
              sessionStorage.setItem('newTabData',JSON.stringify(data));
            }}
            callbackLeftPageShow={(data)=>{
              this.setState({
                isLeftPageShow:data
              })
            }}
            cbDetailPageShow={(data)=>{
              this.setState({
                isDetailPageShow: data
              })
            }}
            cbSwitchStar={(data)=>{
              this.setState({
                isSwitchStar: data
              })
            }}
            cbChgWatchlistIdFun={(id)=>{
              this.setState({
                watchlistId: id
              })
            }}
            cbIsShowHeaderPage={(data)=>{
              this.setState({
                isShowHeaderPage:data
              })
            }}
            cbOnLoadGetWatchlistFun={()=>{
              this.getWatchListFun('N')
            }}
            cbSwitchMoalShow={(data)=>{
              this.setState({
                isSwitchModalShow:data,
              });
            }}
          />
        }
        <Slider 
          {...this.props}
          sliderIndex={sliderIndex}
          currentIndex={currentIndex}
          ChgThemeData={ChgThemeData}
          isShowHeaderPage={isShowHeaderPage}
          isSwitchModalShow={isSwitchModalShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackShowSlidingPanel={this.callbackShowSlidingPanel.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackAlertsShow={this.callbackAlertsShow.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2]
            })
          }}
          cbSwitchFun={()=>{
            // 切换账号更新list列表数据---navIdAPP=='quote'
            this.setState({
              isSwitchShow:!isSwitchShow
            })
          }}
          cbCloseRightPageFun={()=>{
            // 切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
            this.setState({
              isRightPageShow:'',
              currentIndex:0,
              isLeftPageShow:'list',
              closeRightPageShow:!closeRightPageShow
            })
          }}
          chgAccountFun={()=>{
            // 切换账号成功则重新刷新trade页面里的order数据
            this.setState({
              isReLoadOrder:!isReLoadOrder
            })
          }}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
        {/* 遮罩层 */}
        <OrderMask 
          {...this.props}
          tradeData={tradeData}
          sliderIndex={sliderIndex} 
          tradeOrderData={tradeOrderData}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          IsSubmitOrder={IsSubmitOrder}
          currentIndex={currentIndex}
          ricData={ricData}
          isTradeMinbody={isTradeMinbody}
          isCghTradeData={isCghTradeData}
          isOpenAlertModalInTabletMode={isOpenAlertModalInTabletMode}
          alertsPage={alertsPage}
          isSwitchShow={isSwitchShow}
          isReLoadOrder={isReLoadOrder}
          tradeName={tradeName}
          calkbackBtnStatus={this.calkbackBtnStatus.bind(this)}
          calkbackOrderData={this.calkbackOrderData.bind(this)}
          cbSubmitOrderFun={this.cbSubmitOrderFun.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackTradeShow={this.callbackTradeShow.bind(this)}
          callbackAlertsShow={this.callbackAlertsShow.bind(this)}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
        <SlidingPanel 
          {...this.props}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          isSlidingPanelShow={isSlidingPanelShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2]
            })
          }}
          cbSwitchFun={()=>{
            // 切换账号更新list列表数据---navIdAPP=='quote'
            this.setState({
              isSwitchShow:!isSwitchShow
            })
          }}
          cbCloseRightPageFun={()=>{
           // 切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
            this.setState({
              isRightPageShow:'',
              currentIndex:0,
              isLeftPageShow:'list',
              closeRightPageShow:!closeRightPageShow
            })
          }}
        />
        {StreetEDetailShow&&<StreetEDetail 
          {...this.props}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          detailData={streetEData}
          cbCloseDetailData={(data)=>{
            this.setState({
              StreetEDetailShow: false
            })
          }}
        />}
        {newsDetailShow&&<NewsDetail 
          {...this.props}
          newTag='quote'
          ChgThemeData={ChgThemeData}
          isLoading={newsLoading}
          rightData={newRightData}
          currentIndex={currentIndex}
          currentData23={currentData23}
          cbDetailCloseFun={()=>{
            this.setState({
              newsDetailShow: false
            })
          }}
        />}
      </div>
    )}
}
export default Quote
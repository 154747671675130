import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react";
import APPstyles from '@/App.less'
import styles from './index.less'

class Tabs extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            tabIndex:0,
            tabsData:[]
        }
    }
    //处理数据
    onMouseFun(index,boolean){
        const {tabsData}=this.state
        for(let i in tabsData){
            if(i==index){
                tabsData[i]['isShow']=boolean
            }
        }
        this.setState({
            tabsData:tabsData
        })
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isRefresh}=this.props
        if(isRefresh!==newProps['isRefresh']){
            this.setState({
                tabIndex:0
            })
        }
        
    }
    //初始化
    componentDidMount(){
        const {tabData,index,tag}=this.props
        this.setState({
            tabsData:tabData
        })
        if(tag=='Fundamentals'){
            this.setState({
                tabIndex:index
            })
        }
    }
    render(){
        const {t,tag,isMobile,ChgThemeData,isPC,ChgLangData} = this.props
        const {tabsData,tabIndex}=this.state
        return (
            <div 
                className={[
                    APPstyles.flex_row,
                    styles.tab_con,
                    tag=='dividendHistory'||tag=='incomeStatement'||tag=='portfolio'||tag=='sector'||tag=='screener'?'':styles.margin8,
                    tag=='dividendHistory'||tag=='incomeStatement'||tag=='portfolio'||tag=='screener'?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
                    tag=='sector'&&isMobile?styles.tab_con_sector:'',
                    tag=='screener'?styles.tab_con_screener:'',
                    tag=='portfolio'?styles.tab_portfolio:'',
                    tag=='portfolio'&&isMobile?APPstyles.WHPer100:'',
                    tag=='portfolio'&&isMobile?APPstyles.flex_topcenter:'',
                    tag=='portfolio'&&isMobile?styles.tab_portfolio_mobile:'',
                    isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
                ].join(' ')}
                style={{
                    justifyContent:(tag=='portfolio'||tag=='sector')&&isMobile?'space-between':'unset',
                    marginRight:tag=='sector'&&isMobile?3:0
                }}
            >
                {tabsData.map((item,index) => {
                    return <span 
                                key={index}
                                className={[
                                    index===tabIndex||item['isShow']?styles.active:''
                                ].join(' ')}
                                style={{
                                    minWidth:tag=='sector'||tag=='portfolio'||tag=='incomeStatement'?ChgLangData=='zh-CN'?tag=='portfolio'?'auto':34:32:tag=='dividendHistory'?32:'auto'
                                }}
                                onClick={()=>{
                                    this.setState({
                                        tabIndex:index
                                    })
                                    this.props.callbackTabIndex(index)
                                }}
                                onMouseOver={()=>{
                                    if(!isPC) return
                                    this.onMouseFun(index,true)
                                }} 
                                onMouseOut={()=>{
                                    if(!isPC) return
                                    this.onMouseFun(index,false)
                                }}
                            >
                                {t(item['name'])}
                            </span>
                    })
                }
            </div>
        )}
}
export default withTranslation()(observer(Tabs))
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Button,Switch } from 'antd'
import i18n from '@/lang'
import { 
  leftImg,
  leftLightImg,
  closeImg,
  closeOnImg,
  closeOnLightImg,
  checkOffImg,
  settingOnImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class setting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      langData:[
        {
          name:'繁體中文',
          lang:'zh-CN'
        },
        {
          name:'English',
          lang:'en-US'
        }
      ],
      notificationsData:[
        {
          name:'Hide Alert Notifications',
          isShow:false
        },
        {
          name:'Notifications Sound',
          isShow:true
        }
      ],
      getTheme:'Dark',
      getLang:'zh-CN',
      langIndex:0,
      isDarkMode:true,
      isSettingPageShow:false,
      minH550:550,
      chgCloseImg:closeImg,
      simpleTradeData:{isShow:false}
    }
  }
  //语言设置页面--save
  saveClick(){
    const {getTheme,getLang,notificationsData,simpleTradeData}=this.state
    let locale=i18n.language=getLang
    let tradeName=simpleTradeData['isShow']?'simple':'general'
    i18n.changeLanguage(locale)
    localStorage.setItem('lang',locale)
    localStorage.setItem('theme',getTheme)
    localStorage.setItem("isNotification", notificationsData[0].isShow ? "false": "true")
    localStorage.setItem("isSound", notificationsData[1].isShow ? "true": "false")
    localStorage.setItem('tradeName',tradeName)
    this.props.callbackSetting([getLang,getTheme,tradeName])
    this.props.cbCloseSetting(false)
    this.chgNavIndexfun()
  }
  chgNavIndexfun(){
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    this.props.callbackChgNavIndex(navIdAPP, true)
    sessionStorage.setItem('navId',navIdAPP)
  }
  //初始化数据
  onLoadDataFun(){
    let lang=localStorage.getItem('lang')
    let theme=localStorage.getItem('theme')
    let isNotification=localStorage.getItem('isNotification')
    let isSound=localStorage.getItem('isSound')
    let tradeName=localStorage.getItem('tradeName')
    this.setState({
      getLang:!lang?'zh-CN':lang,
      langIndex:lang=='zh-CN'?0:1,
      getTheme:theme=='Light'?'Light':'Dark',
      isDarkMode:theme=='Light'?false:true,
      notificationsData:[
        {
          name:'Hide Alert Notifications',
          isShow:isNotification === "true" ? false : true
        },
        {
          name:'Notifications Sound',
          isShow:isSound === "true" ? true : false
        }
      ],
      simpleTradeData:{
        isShow:tradeName=='simple'?true:false
      }
    })
  }
  //初始化
  componentDidMount(){
    this.onLoadDataFun()
  }
  render() {
    const {t,bodyW,bodyH,mobilebodyW,ChgThemeData,isMobile} = this.props
    const {langData,langIndex,isDarkMode,notificationsData,minH550,chgCloseImg,simpleTradeData}=this.state
    return (
      <div className={styles.setting_fixed}>
        <div 
          className={[
            APPstyles.flex_column,
            styles.setting_con,
            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
          ].join(' ')}
          style={{
            height:bodyW>mobilebodyW?bodyH>minH550?'auto':'80%':'100%',
            minHeight:bodyW>mobilebodyW?bodyH>minH550?492:'80%':'100%',
            width:bodyW>mobilebodyW?522:'100%'
          }}
        >
          <div 
            className={[
              styles.header,
              styles.min48_con,
              bodyW>mobilebodyW?APPstyles.flex_between:APPstyles.flex_row,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              padding:bodyW>mobilebodyW?'0 16px':'0 8px'
            }}
          >
            <img 
              src={ChgThemeData=='Light'?leftLightImg:leftImg}  
              className={APPstyles.img24} 
              style={{
                display:bodyW>mobilebodyW?'none':'',
                marginRight:4
              }}
              alt='back' 
              onClick={()=>{
                this.chgNavIndexfun()
                this.props.cbCloseSetting(false)
              }} 
            />
            <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t('Settings')}</span>
            <img 
              src={chgCloseImg} 
              className={APPstyles.img24} 
              style={{
                display:bodyW>mobilebodyW?'':'none'
              }}
              alt='close' 
              onClick={()=>{
                this.chgNavIndexfun()
                this.props.cbCloseSetting(false)
              }}   
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                this.setState({
                  chgCloseImg:closeImg
                })
              }}  
            />
          </div>
          <div className={[styles.body,APPstyles.flex1_auto].join(' ')}>
            <span
              className={[
                styles.title,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1,
                isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
              ].join(' ')}
            >
              {t('Language')}
            </span>
            {
              langData.map((item,index) => {
              return  <div 
                        className={[
                          styles.min48_con,
                          styles.msg,
                          APPstyles.flex_between,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''
                        ].join(' ')}
                        key={index}
                      >
                        <span>{t(item['name'])}</span>
                        <img
                          className={APPstyles.img24} 
                          src={langIndex==index?settingOnImg:checkOffImg} 
                          alt='radio' 
                          onClick={()=>{
                            //选择语言
                            this.setState({
                              langIndex:index,
                              getLang:index==0?'zh-CN':'en-US'
                            })
                          }}
                        />
                      </div>
              })
            }
            <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.line_dark].join(' ')}></div>
            <span
              className={[
                styles.title,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1,
                isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
              ].join(' ')}
            >
              {t('Appearance')}
            </span>
            <div 
              className={[
                styles.min48_con,
                styles.msg,
                APPstyles.flex_between,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''
              ].join(' ')}
            >
              <span>{t('Dark Mode')}</span>
              <Switch 
                checked={isDarkMode} 
                onChange={(disabled )=>{
                  this.setState({
                    isDarkMode:disabled,
                    getTheme:disabled?'Dark':'Light'
                  })
                }} 
              />
            </div>
            <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.line_dark].join(' ')}></div>
            <span
              className={[
                styles.title,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1,
                isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
              ].join(' ')}
            >
              {t('Notifications')}
            </span>
            {notificationsData.map((item,index) => {
              return  <div 
                        className={[
                          styles.min48_con,
                          styles.msg,
                          APPstyles.flex_between,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''
                        ].join(' ')}
                        key={index}
                      >
                        <span>{t(item['name'])}</span>
                        <Switch 
                          checked={item['isShow']} 
                          onChange={(disabled )=>{
                            notificationsData[index]['isShow']=disabled
                            this.setState({
                              notificationsData:notificationsData
                            })
                          }} 
                        />
                      </div>
              })
            }
            <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.line_dark].join(' ')}></div>
            <span
              className={[
                styles.title,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1,
                isMobile?ChgThemeData=='Dark'?APPstyles.fontSize13_colorWhite:APPstyles.fontSize13_colorDark:''
              ].join(' ')}
            >
              {t('Trade')}
            </span>
            <div 
              className={[
                styles.min48_con,
                styles.msg,
                APPstyles.flex_between,
                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''
              ].join(' ')}
            >
              <span>{t('Simple Page')}</span>
              <Switch 
                checked={simpleTradeData['isShow']} 
                onChange={(disabled )=>{
                  simpleTradeData['isShow']=disabled
                  this.setState({
                    simpleTradeData:simpleTradeData
                  })
                }} 
              />
            </div>
            <div className={[styles.line,ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.line_dark].join(' ')}></div>
          </div>
          <div
            className={[
              styles.footer,
              ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark
            ].join(' ')}
          >
            <Button 
              type='default' 
              className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} 
              onClick={()=>{
                this.chgNavIndexfun()
                this.props.cbCloseSetting(false)
              }}
            >
              {t('Cancel')}
            </Button>
            <Button 
              type='default' 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')}
              onClick={this.saveClick.bind(this)} 
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(setting)
import React from 'react'
import { 
  logoDarkImg,
  logoLightImg
} from '../../../asserts/imgs/common/index'
import APPstyles from '../../../App.less'
import styles from './index.less'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      loginName:''
    }
  }
  //点击logo,跳转到quote页面
  toQuoteClick(){
    const {locationName,sliderIndex}=this.props
    let pathName='/'+locationName[1]+'/Quote'
    if(sliderIndex=='account'){
      this.props.callbackPathnameApp(pathName)// 获取路由跳转--app.js
      sessionStorage.setItem('navId','quote')
      sessionStorage.setItem('navIdAPP','quote')
    }
  }
  //初始化
  componentDidMount(){
    const loginName=sessionStorage.getItem('loginName')
    this.setState({
      loginName:loginName
    })
  }
  render(){
    const {ChgThemeData,isShowHeaderPage,isPC,isMobile,isTablet}=this.props
    const {loginName}=this.state
    return(   
      <div className={isShowHeaderPage?'':APPstyles.display_none}>
        <div 
          className={styles.header_line} 
          style={{
            height:isPC?10:5
          }}
        >
        </div>
        <div 
          className={[
            APPstyles.flex_row,
            styles.header_con,
            isTablet?styles.header_con_min:'',
            ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
          style={{
            display:loginName=='notFound'&&isMobile?'none':'',
            height:isPC?79:66
          }}
        >
          <img 
            src={ChgThemeData=='Light'?logoLightImg:logoDarkImg} 
            onClick={this.toQuoteClick.bind(this)} 
            alt='logo' 
          />
        </div>
      </div> 
  )}
}
export default Header
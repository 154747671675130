import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import commonChart from '@/asserts/js/commonChart'
import TechnicalChart from '@/components/Chart/TechnicalChart/TechnicalChart'
import APPstyles from '@/App.less'
import styles from './index.less'

class Quote extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isLoadingChart:false
        }
    }
    //更改图表样式
    chgThemeChartData(ChgThemeData){
        // 更改颜色
        let changeChartData=commonChart.candlestickData['series']
        changeChartData[0]['color']=ChgThemeData=='Light'?'#FFFFFF':'#39C46E'
        changeChartData[0]['lineColor']=ChgThemeData=='Light'?'#333333':'#39C46E'
        changeChartData[0]['upColor']=ChgThemeData=='Light'?'#333333':'#F5475B'
        changeChartData[0]['upLineColor']=ChgThemeData=='Light'?'#333333':'#F5475B'
        // 更改背景色
        let chart=commonChart.candlestickData['chart']
        chart['backgroundColor']=ChgThemeData=='Light'?'#FCFCFC':'#000000'
        // 更改x轴颜色
        let xAxis=commonChart.candlestickData['xAxis']
        xAxis['gridLineColor']=ChgThemeData=='Light'?'#F3F3F3':'#1C1C1C'
        xAxis['labels']['style']['color']=ChgThemeData=='Light'?'#333333':'#FFFFFF'
        xAxis['crosshair']['color']=ChgThemeData=='Light'?'#E0E0E0':'#1C1C1C'
        // 更改y轴颜色
        let yAxis=commonChart.candlestickData['yAxis']
        yAxis['gridLineColor']=ChgThemeData=='Light'?'#F3F3F3':'#1C1C1C'
        yAxis['lineColor']=ChgThemeData=='Light'?'#F3F3F3':'#1C1C1C'
        yAxis['labels']['style']['color']=ChgThemeData=='Light'?'#333333':'#FFFFFF'
        // 更改tooltip颜色
        let tooltip=commonChart.candlestickData['tooltip']
        tooltip['backgroundColor']=ChgThemeData=='Light'?'#FFFFFF':'#222429'
        tooltip['style']['color']=ChgThemeData=='Light'?'#333333':'#FFFFFF'

        this.setState({
            isLoadingChart:false
        })
    }
    //初始化
    onLoadDataFun(){
        const {data}=this.props
        let dataLen=sessionStorage.getItem('hasQuoteDataLen')
        //更改主题--setting
        this.setState({
            isLoadingChart:true
        })
        let theme=localStorage.getItem('theme')
        this.chgThemeChartData(theme)
        //数据为空
        let changeChartData=commonChart.candlestickData['series']
        changeChartData[0]['data']=[]
        changeChartData[1]['data']=[]
        changeChartData[2]['data']=[]
        //切换tab
        this.setState({
            isLoadingChart: dataLen>0?data.length>0||JSON.stringify(data)!=='{}'|JSON.stringify(data)!=='[]'||data!=='[]'?true:false:false
        })
    }
    onFinishLoading(){
        this.setState({isLoadingChart: false})
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {ricData,ChgThemeData,isMobile,bodyW}=this.props

        if(bodyW!==newProps['bodyW']){
            commonChart.candlestickData['chart']['height']=!isMobile?287:180
        }
        //更改主题--setting
        if(ChgThemeData!==newProps['ChgThemeData']){
            this.setState({
                isLoadingChart:true
            })
            this.chgThemeChartData(ChgThemeData)
        }
        //更改数据
        if(ricData!==newProps['ricData']){
            this.setState({
                isLoadingChart: ricData&&JSON.stringify(ricData)!=='{}'?true:false
            })
        }
    }
    //初始化
    componentDidMount(){
        this.onLoadDataFun()
    }
    render(){
        const {t,bodyW,minbodyW,mobilebodyW,store,ChgThemeData,ChgLangData,data,isLoading,isRightPageShow,currentIndex, triggerCloseChartZoom, isSector,isTablet,isMobile,isPC} = this.props
        const {isLoadingChart}=this.state
        let streamingData=data&&store.getDataByRic(data['ric'])
        return (
            <>
            <div>
            <div 
                className={[
                    styles.stocks_quote_chart,
                    ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_dark
                ].join(' ')}
            >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoadingChart} /></div>
                {data && <TechnicalChart 
                    store={store}
                    data={data}
                    ChgThemeData={ChgThemeData}
                    ChgLangData={ChgLangData}
                    isTablet={bodyW<=minbodyW}
                    isMobile={bodyW<=mobilebodyW}
                    triggerCloseChartZoom={triggerCloseChartZoom}
                    onFinishLoading={this.onFinishLoading.bind(this)}
                    isSector={isSector || isRightPageShow === "trade" || isRightPageShow === "alerts"} // chart switched to mobile mode when isSector === true 
                    // isClickZoom={this.props.isClickZoom}
                    // onClickZoom={this.props.onClickZoom}
                />}
            </div>
            </div>
            <div className={[styles.stocks_quote,(isTablet&&(currentIndex==2||currentIndex==3))||isMobile?styles.stocks_quoteMobile:''].join(' ')}>
                <div 
                    className={[
                        APPstyles.flex_row,
                        styles.stocksquote_tradeinfo,
                        isRightPageShow||isTablet||data['ESGScoreGrade']==null||(currentIndex==2||currentIndex==3)?styles.stocksquote_tradeinfoList:'',
                        ChgThemeData=='Light'?styles.stocksquote_tradeinfo_light:'',
                        isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
                    ].join(' ')}
                    style={{
                        paddingBottom:data['ESGScoreGrade']==null||(isPC&&!isRightPageShow)?isPC&&(currentIndex==2||currentIndex==3)?0:30:0
                    }}
                >
                    {/* 加载中 */}
                    <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                    <div className={styles.stocksquote_tradeinfo_oneleft}>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span className={APPstyles.color_red}>{t('Bid')}</span>
                            <span className={APPstyles.color_red}>{common.transThousands(common.keepNum(streamingData&&streamingData['BID'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Open')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['OPEN_PRC'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('High')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['HIGH_1'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Low')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['LOW_1'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Prev. Cls')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['HST_CLOSE'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('VWAP')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['VWAP'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Volume')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['ACVOL_1'],0))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('52week high')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['52WK_HIGH'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')} style={{marginBottom:0}}>
                            <span>{t('52week low')}</span>
                            <span>{common.transThousands(common.keepNum(streamingData&&streamingData['52WK_LOW'],2))}</span>
                        </div>
                    </div>
                    <div className={styles.stocksquote_ask_con}>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span className={APPstyles.color_green}>{t('Ask')}</span>
                            <span className={APPstyles.color_green}>{common.transThousands(common.keepNum(streamingData&&streamingData['ASK'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Market Cap')}</span>
                            <span>{common.addNumM(common.transThousands(common.keepNum(data['MKTCAP_USD'],0)))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('P/E')} ({'TTM'})</span>
                            <span>{common.transThousands(common.keepNum(data['P2EPSExX_CurTTM'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('EPS')}</span>
                            <span>{common.transThousands(common.keepNum(data['TTMEPSXCLX'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Div Amount')}</span>
                            <span>{common.transThousands(common.keepNum(data['DIVNQ'],2))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Div Yield')}</span>
                            <span>{common.changeRate(common.transThousands(common.keepNum(data['DivYield_CurTTM'],2)))}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Ex-Div Date')}</span>
                            <span>{common.onlyFormatDate(data['EXDIVDATE'])}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')}>
                            <span>{t('Payout Date')}</span>
                            <span>{common.onlyFormatDate(data['DIVPAYDATE'])}</span>
                        </div>
                        <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_one].join(' ')} style={{marginBottom:0}}>
                            <span>{t('Company Score2')}</span>
                            <span>{common.transThousands(common.keepNum(data['AVG_SCORE'],0))}</span>
                        </div>
                    </div>
                    {
                        data['ESGScoreGrade']?
                    
                            <div 
                                className={[
                                    styles.stocksquote_tradeinfo_sqaure_out,
                                    APPstyles.color_white
                                ].join(' ')} 
                                style={{
                                    marginBottom:isTablet||isRightPageShow||(currentIndex==2||currentIndex==3)?30:0,
                                    marginLeft:isRightPageShow||isTablet||(currentIndex==2||currentIndex==3)?0:13
                                }}
                            >
                                <div className={[APPstyles.flex_row,styles.stocksquote_tradeinfo_sqaure].join(' ')}>
                                    <div style={{marginBottom:1}}>
                                        <span>{data['ESGScoreGrade']}</span>
                                        <span>ESG</span>
                                        <span>{t('Score')}</span>
                                    </div>
                                    <div style={{background: '#5A975A',marginBottom:1}}>
                                        <span>{data['ESGCControversiesScoreGrade']}</span>
                                        <span>{t('ESG Controversies')}</span>
                                        <span>{t('Score')}</span>
                                    </div>
                                    <div style={{background: '#70A455',marginBottom:1}}>
                                        <span>{data['ESGCombinedScoreGrade']}</span>
                                        <span>{t('ESG Combined')}</span>
                                        <span>{t('Score')}</span>
                                    </div>
                                    <div style={{background: '#B06242'}}>
                                        <span>{data['EnvironmentPillarScoreGrade']}</span>
                                        <span>{t('Environmental')}</span>
                                        <span>{t('Score')}</span>
                                    </div>
                                    <div style={{background: '#BEB44D'}}>
                                        <span>{data['SocialPillarScoreGrade']}</span>
                                        <span>{t('Social')}</span>
                                        <span>{t('Score')}</span>
                                    </div>
                                    <div style={{background: '#99BC4D'}}>
                                        <span>{data['GovernancePillarScoreGrade']}</span>
                                        <span>{t('Governance')}</span>
                                        <span>{t('Score')}</span>
                                    </div>
                                </div>
                            </div>
                        :''    
                    }
                </div>
            </div>
            </>
    )}
}
export default withTranslation()(observer(Quote))

import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../../../../asserts/js/common'
HighchartsMore(Hightcharts)

class ScoreArea extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }
  onLoadData(){
    const {t,id,ChgThemeData,allChartData,bodyW,mobilebodyW,data,isPC}=this.props
    let symbolName=data&&data['symbol']?data['symbol']:'Stock'
    Hightcharts.chart(id,{
      chart: {
        type: "area",
        polar: true,
        width: bodyW < mobilebodyW ? Number(bodyW) - 32 : undefined,
        height: 230,
        backgroundColor: "rgba(0,0,0,0)",
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false
      },
      pane: {
        size: bodyW<=350?bodyW<=322?'60%':'66%':isPC&&bodyW<=1500?'65%':'80%',
      },
      tooltip:{
        borderWidth:0,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        style:{
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '14px'
        },
        valueDecimals: 2
      },
      colors: ["#008FC7", "#009E9C"],
      xAxis: {
        categories: [t('Earnings'),t('Fundamental'),t('Relative Valuation'),t('Risk'),t('Price Momentum')],
        tickmarkPlacement: "on",
        lineWidth: 0,
        gridLineWidth: 2,
        gridLineColor: ChgThemeData === "Light" ? "#E0E0E0" : "#717171",
        labels: {
          // x:-5,
          style: {
            color: ChgThemeData === "Light" ? "#333333" : "#FFFFFF",
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
          }
        }
      },
      yAxis: {
        gridLineInterpolation: "polygon",
        gridLineColor: ChgThemeData === "Light" ? "#E0E0E0" : "#717171",
        lineWidth: 0,
        min: 0,
        max: 10,
        tickAmount: 5,
        // tickInterval: 2,
        labels: {
          enabled: false,
        },
        plotBands: [
          {
            color: "rgba(0,0,0,0)",
            from: 0,
            to: 10,
          },
        ],
        plotLines: [
          {
            color: ChgThemeData === "Light" ? "#E0E0E0" : "#717171",
            from: 9.7,
            to: 10,
          },
        ],
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        y: 0,
        layout: "horizontal",
        squareSymbol: true,
        symbolHeight: 6,
        symbolWidth: 10,
        symbolRadius: 0,
        margin: 0,
        // padding: 10,
        itemMarginTop: 10,
        itemStyle: {
          color: ChgThemeData=='Light'?'#828282':'#E0E0E0',
          fontWeight: '400',
          fontSize: '12px',
          fontFamily: 'Roboto'
        },
        itemHoverStyle: {
          color: ChgThemeData=='Light'?'#373737':'#828282'
        }
      },
      series: [
        {
          name: symbolName,
          data: allChartData['ScoreData']['StockData'],
          pointPlacement: "off",
        },
        {
          name: t('Sector'),
          data: allChartData['ScoreData']['SectorData'],
          pointPlacement: "off",
        }
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 280,
            },
            chartOptions: {
              pane: {
                size: "75%",
              },
            },
          },
          {
            condition: {
              maxWidth: 270,
            },
            chartOptions: {
              pane: {
                size: "65%",
              },
            },
          },
          {
            condition: {
              maxWidth: 260,
            },
            chartOptions: {
              pane: {
                size: "55%",
              },
            },
          },
          {
            condition: {
              maxWidth: 250,
            },
            chartOptions: {
              pane: {
                size: "35%",
              },
            },
          },
          {
            condition: {
              maxWidth: 240,
            },
            chartOptions: {
              pane: {
                size: "25%",
              },
            },
          },
          {
            condition: {
              maxWidth: 200,
            },
            chartOptions: {
              pane: {
                size: "15%",
              },
            },
          },
        ],
      },
      credits: {
        enabled: false
      }
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,isRightPageShow,isToggleOn,chartsData}=this.props
    if(
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      isRightPageShow!==newProps['isRightPageShow']||
      isToggleOn!==newProps['isToggleOn']||
      chartsData!==newProps['chartsData']
    ){
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(ScoreArea)